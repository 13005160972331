// Base64 Encoder
export const base64Encode = (str) => {
  return btoa(str);
};

// Base64 Decoder
export const base64Decode = (encodedStr) => {
  try {
    const decodedString = atob(encodedStr);
    return decodedString;
  } catch {
    return false;
    // Handle the error, perhaps inform the user or fallback to a default value
  }
};
