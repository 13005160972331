import React, { useState, useEffect, useRef, useMemo } from "react";
// import ActiveLastBreadcrumb from "./breadcrumb.component";
import axios from "axios";
import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import Dropdown from "../../share/components/dropdown";
import { Grid, Button, IconButton, Typography } from "@mui/material";
import ToolTip from "../../share/components/tooltip";
import PopOver from "../../share/components/popover.component";
import CloseIcon from "@mui/icons-material/Close";
import "../../css/backlink-checker.scss";
import { Link } from "react-router-dom";
import CustomButtonStyle from "../../share/components/button.component";
import logo from "../../Assets/seoimg/logo.png";
import OpenInNewIcon from "@mui/icons-material/OpenInNew";
import CircularProgress from "@mui/material/CircularProgress";
import backlinkImg from "../../Assets/backlink/backlink-tool.png";
import { ReCaptcha } from "../../share/components/googleRecaptacha";
import { useNavigate, useLocation } from "react-router-dom";
import ToolNav from "../../share/components/tool-nav";
import "./Tools.scss";
import SEC1 from "./Assests/BackLink/sec1.png";
import SEC2 from "./Assests/BackLink/sec2.png";
import SEC3 from "./Assests/Rank/sec3.png";
import { FooterTool } from "./Footer/Footer";
import { domainRegex, formatNumberWithSymbols } from "../../../helperJs/helper";
import useIpAddress from "../../../helperJs/useIpAddress";

function BacklinkChecker() {
  const [toolSelected, setToolSelected] = useState(0);
  const [Data, setData] = useState([]);
  const navigate = useNavigate();
  const [domain, setDomain] = useState("Domain");
  const [path, setPath] = useState("Backlink Checker");
  const [keyword, setKeyword] = useState("");
  const [domainAuthority, setDomainAuthority] = useState("");
  const [Error, setError] = useState();
  const [loader, setLoader] = useState(false);
  const [anchorEl, setAnchorEl] = React.useState(false);
  const ipAddress = useIpAddress();

  const get_backlink_data = (config) =>
    `https://app.rankspro.io/api/user/backlinkdataget/?domain=${config.domain}&include_subdomains=${config.include_subdomains}`;
  const post_backlink_data = (config) =>
    `https://app.rankspro.io/api/user/backlinkdata/?domain=${config.domain}&include_subdomains=${config.include_subdomains}`;
  const get_domainauthority = (domain) =>
    `https://app.rankspro.io/api/user/domainauthorityget/?domain=${domain}`;
  const post_freebacklinkdata = () =>
    `https://app.rankspro.io/api/user/freebacklinkdata/`;
  const email = "";

  const tool = [
    {
      title: "Backlink Checker",
      desc: " Try the free version of Backlink Checker. Get a glimpse into the power of our premium tool.",
      api: "",
      searchOption: true,
      image: backlinkImg,
    },
    {
      title: "Website “Authority” Checker",
      desc: "Check the “authority” of your domain.",
      api: "",
      searchOption: false,
      image: backlinkImg,
    },
    {
      title: "Broken Link Checker",
      desc: "Check your site for broken inbound and outbound links in seconds.",
      api: "",
      searchOption: false,
      image: backlinkImg,
    },
  ];

  const freeBacklinkData = (data) => {
    const allData = data?.data?.[0];
    const history_list = allData?.history_list
      ? allData?.history_list
      : allData?.data?.[0].history_list;

    const config = {
      domain_name: keyword,
      IP: ipAddress,
      backlinks: allData?.total_backlinks || 0,
      ref_domain:
        history_list?.[history_list?.length - 1]?.referring_domains || 0,
    };

    axios({
      method: "post",
      url: post_freebacklinkdata(),
      headers: {},
      data: config,
    });
  };

  const handleUserBacklinkData = (config) => {
    axios.get(get_backlink_data(config)).then((response) => {
      if (response.data?.length > 0) {
        const data = response.data?.[0];
        freeBacklinkData(data);
        setTimeout(() => {
          setData(data);
        }, 1000);
        setAnchorEl(true);
        setLoader(false);
      } else {
        axios
          .post(post_backlink_data(config))
          .then((response) => {
            if (response.data?.length === 0) {
              setLoader(false);
              setData([]);
              setError("Sorry, No Data Found For This Domain.");
            } else {
              freeBacklinkData(response.data[0]);
              setTimeout(() => {
                setData(response.data[0]);
              }, 1000);
              setAnchorEl(true);
              setLoader(false);
            }
          })
          .catch(() => {
            setLoader(false);
            setData([]);
            setError("Sorry, No Data Found For This Domain.");
          });
      }
      axios.get(get_domainauthority(config.domain)).then((response) => {
        setDomainAuthority(response.data);
      });
    });
  };

  const keywordClickHandler = (e) => {
    e.preventDefault();
    if (keyword === "") {
      setError("Please enter domain");
    } else if (!domainRegex.test(keyword)) {
      setError("Please enter valid domain");
    } else {
      setLoader(true);
      const config = {
        email: email,
        domain: keyword,
        include_subdomains: true,
      };
      handleUserBacklinkData(config);
    }
  };

  const keywordChangeHandler = (target) => {
    setError("");
    if (domain == "URL") {
      setKeyword(target.value);
    } else {
      const url = target.value;
      const match = url.match(/^(?:https?:\/\/)?(?:www\.)?([^/]+)/i);
      setKeyword(match ? match[1].replace(/\s/g, "") : "");
    }
  };

  const domain_data = [{ language: "Domain" }, { language: "URL" }];

  const domainHandler = (e) => {
    if (e.language == "URL") {
      setKeyword(keyword);
    } else {
      const match = keyword.match(/^(?:https?:\/\/)?(?:www\.)?([^/]+)/i);
      setKeyword(match ? match[1].replace(/\s/g, "") : "");
    }
    setDomain(e.language);
  };

  const Sppiner = () => {
    return (
      <Box
        sx={{ display: "flex", minHeight: 350, minWidth: 350 }}
        className="w-100 h-100 cad"
      >
        <div
          className="spinner-border"
          role="status"
          style={{ color: "#365486", width: "3rem", height: "3rem" }}
        >
          <span className="sr-only"></span>
        </div>
      </Box>
    );
  };

  function handleClose() {
    setAnchorEl(false);
    setData([]);
    setKeyword("");
  }

  const allData = Data?.data?.[0];
  const history_list = allData?.history_list
    ? allData?.history_list
    : allData?.data?.[0].history_list;

  const currentPageTableData = useMemo(() => {
    const tableData = Data?.data?.[0]?.data || [];
    const array = tableData?.slice(0, 100);
    const sortedData = [...array].sort(
      (a, b) => b.domain_authority - a.domain_authority
    );
    return sortedData;
  }, [Data]);

  function kFormatter(num) {
    return Math.abs(num) > 999
      ? Math.sign(num) * (Math.abs(num) / 1000).toFixed(1) + "K"
      : Math.sign(num) * Math.abs(num);
  }

  const pathChangeHandler = (e) => {
    setPath(e.language);
    navigate(e.path);
  };
  const Section1Ul = [
    "Number of referring domains",
    "Number of backlinks",
    "Domain Rank (DR)",
    "Anchor Links",
    "And more",
  ];
  const Section2Ul = [
    "Perform a backlink audit with ease",
    "Analyze your competitors’ backlink profiles and uncover their backlink strategies",
    "Analyze anchor texts in a backlink profile and build sound SEO strategy",
    "Discover the DoFollow Vs. NoFollow link rate",
  ];
  return (
    <div style={{ overflow: "auto", height: "100vh" }}>
      <div className="backlinks-checker-container">
        <ToolNav />
        <div className="Tool-App">
          <div className="backlinks-container">
            <div>
              <h4 className="heading-medium font-regular">Free SEO Tools /</h4>
              <h1 className="heading-xlarge font-bold">
                {tool[toolSelected]?.title}
              </h1>
              <p className="heading-medium font-regular">
                {tool[toolSelected]?.desc}
              </p>
              {/* <div>
        <ActiveLastBreadcrumb
          selectTool={setToolSelected}
          activeTool={toolSelected}
        />
      </div> */}
              {tool[toolSelected]?.searchOption ? (
                <div className="search-container">
                  <Grid container spacing={2}>
                    <Grid item xs={8}>
                      <form onSubmit={(e) => keywordClickHandler(e)}>
                        <input
                          type="text"
                          className="input-search"
                          placeholder="Enter domain name"
                          value={keyword}
                          style={{ height: "50px" }}
                          onChange={(e) => keywordChangeHandler(e.target)}
                        />
                        {Error && (
                          <span className="msg-error heading-xs font-regular">
                            {Error}
                          </span>
                        )}
                      </form>
                    </Grid>
                    <Grid item xs={4}>
                      <Dropdown
                        title={domain}
                        callback={domainHandler}
                        data={domain_data}
                        style={{
                          width: "100%",
                          height: "50px",
                          textTransform: "none",
                          backgroundColor: "transparent",
                          boxShadow: "none",
                          borderRadius: "5px",
                          border: "1px solid #0000004D",
                        }}
                      />
                    </Grid>
                  </Grid>
                  <Button
                    variant="outlined"
                    className="btn-backlink"
                    onClick={(e) => keywordClickHandler(e)}
                  >
                    Submit
                  </Button>
                </div>
              ) : (
                <div style={{ width: "60%" }} className="search-container">
                  <p className="heading-xs font-bold">coming soon...</p>
                </div>
              )}
            </div>
            <img
              src={backlinkImg}
              alt=""
              className="backlink-img flex-none-sm"
            />
          </div>
        </div>

        <PopOver anchorEl={anchorEl} setAnchorEl={() => setAnchorEl(false)}>
          <Box
            sx={{
              bgcolor: "rgba(255, 255, 255, 1)",
              minWidth: { md: 700 },
            }}
            className=""
          >
            {Data?.length === 0 ? (
              <Sppiner />
            ) : (
              <Box sx={{ width: "100%", cursor: "default" }}>
                <div className="w-100 px-4 pt-2">
                  <Typography
                    component="h1"
                    id="select-google-property"
                    className="heading-large font-bold popover-list-header py-2 px-2"
                  >
                    Backlink Profile For :{" "}
                    <span style={{ color: "#e95655" }}>{keyword}</span>
                    <ToolTip
                      title={`Backlink data search for ${keyword} domian`}
                    />
                    <IconButton
                      className="popover-close-icon"
                      onClick={handleClose}
                      sx={{
                        "&:hover": {
                          backgroundColor: "rgba(240, 242, 255, 1)",
                        },
                      }}
                    >
                      <CloseIcon sx={{ color: "black" }} fontSize="large" />
                    </IconButton>
                  </Typography>
                  <div className="row mb-2 px-2">
                    <div className="col-12 col-md-4">
                      <div className="d-flex align-items-center">
                        <Box className="d-flex">
                          {/* <CircularProgress
                        variant="determinate"
                        value={domainAuthority || 100}
                      /> */}
                        </Box>
                        <span className="heading-medium font-regular me-2 text-nowrap">
                          DOMAIN AUTHORITY :{" "}
                        </span>
                        <span
                          className="heading-xlarge font-bold"
                          style={{ color: "#e95655" }}
                        >
                          {domainAuthority || 0}
                        </span>
                      </div>
                    </div>
                    <div className="col-12 col-md-4">
                      <div className="d-flex align-items-center">
                        <span className="heading-medium font-regular me-2 text-nowrap">
                          REFERRING DOMAINS :{" "}
                        </span>
                        <span
                          className="heading-xlarge font-bold"
                          style={{ color: "#e95655" }}
                        >
                          {formatNumberWithSymbols(
                            history_list?.[history_list?.length - 1]
                              ?.referring_domains
                          ) || 0}
                        </span>
                      </div>
                    </div>
                    <div className="col-12 col-md-4">
                      <div className="d-flex align-items-center">
                        <span className="heading-medium font-regular me-2 text-nowrap">
                          BACKLINKS :{" "}
                        </span>
                        <span
                          className="heading-xlarge font-bold"
                          style={{ color: "#e95655" }}
                        >
                          {formatNumberWithSymbols(allData?.total_backlinks) ||
                            0}
                        </span>
                      </div>
                    </div>
                  </div>
                  <div
                    style={{
                      overflow: "scroll",
                      maxHeight: "420px",
                      border: "1px solid gray",
                    }}
                    className="mb-2"
                  >
                    <table className="table backlink-list-table mt-0">
                      <thead>
                        <tr>
                          <th scope="col" className="">
                            DR
                            <ToolTip title="Domain Rank: A metric that denotes the domain rank, referring to the target page or domain, calculated by summing each page’s rank value, narrowed down to a range on the scale. 25-35 range means new domain with limited backlinks, 200-300 range means older domains with higher quality backlinks, 500+ scale means huge domains with significant authority backlinks." />
                          </th>
                          <th scope="col">
                            Source page title & url | target page
                            <ToolTip
                              title="SEO Title / URL - The title that shows up in
                          a Google search for any given URL, followed
                          by the page URL itself."
                            />
                          </th>
                          <th scope="col" className="">
                            anchor text
                            <ToolTip
                              title="  Anchor Text - The Anchor text is the
                                  visible, clickable text in a hyperlink."
                            />
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        {currentPageTableData?.length === 0 ? (
                          <tr>
                            <td className="text-center" colSpan={3}>
                              "No Data found"
                            </td>
                          </tr>
                        ) : (
                          currentPageTableData &&
                          currentPageTableData?.map((item, index) => {
                            return (
                              <tr
                                key={`blacklink-table-list-${index}-${item?.page_from_title}`}
                              >
                                <td className="table-cell-value vert-alg ">
                                  <span className="table-cell-value ">
                                    {item?.domain_authority / 10 < 5
                                      ? 0
                                      : item?.domain_authority / 10 || 0}
                                  </span>
                                </td>
                                <td className="t_left table_cell">
                                  <div
                                    style={{
                                      display: "flex",
                                      flexDirection: "column",
                                      height: "65px",
                                    }}
                                    className="ellipsis"
                                  >
                                    <span className="mt-0 blacklink-table-span">
                                      <a
                                        href={item?.url_from}
                                        className=""
                                        target="_blank"
                                        style={{
                                          color: "#475EE5",
                                          paddingLeft: "0px",
                                        }}
                                        rel="noopener noreferrer"
                                      >
                                        {item?.page_from_title}
                                      </a>
                                    </span>
                                    <span className="mt-0 blacklink-table-span">
                                      <b>Source:</b>
                                      <a
                                        href={item?.url_from}
                                        target="_blank"
                                        className=""
                                        rel="noopener noreferrer"
                                      >
                                        {item?.url_from}
                                        <OpenInNewIcon
                                          sx={{
                                            fontSize: "13px",
                                            marginLeft: "6px",
                                          }}
                                        />
                                      </a>
                                    </span>
                                    <span className="mt-0 blacklink-table-span ">
                                      <b>Target:</b>
                                      <a
                                        href={item?.url_to}
                                        target="_blank"
                                        className=""
                                        rel="noopener noreferrer"
                                      >
                                        {item?.url_to}
                                        <OpenInNewIcon
                                          sx={{
                                            fontSize: "13px",
                                            marginLeft: "6px",
                                          }}
                                        />
                                      </a>
                                    </span>
                                  </div>
                                </td>
                                <td className="table_cell vert-alg ">
                                  <span className="table-cell-value ">
                                    {item?.anchor}
                                  </span>
                                </td>
                              </tr>
                            );
                          })
                        )}
                      </tbody>
                    </table>
                  </div>
                </div>
                <div
                  className="row p-3"
                  style={{
                    backgroundColor: "#FFE6BF",
                  }}
                >
                  <div className="col-8">
                    <div>
                      <h1 className="heading-medium font-bold mb-1">
                        {`Want to see all ${
                          kFormatter(allData?.total_backlinks) || 0
                        } backlinks?`}
                      </h1>
                      <p className="heading-small font-regular m-0">
                        Sign up for RanksPro.io to check backlink profile of any
                        website or URL.
                      </p>
                    </div>
                  </div>
                  <div className="col-4 p-sm-0 d-flex align-items-center justify-content-center">
                    <a target="blank" href="/register">
                      <CustomButtonStyle
                        name="Start 7-Day Free Trial"
                        hover="#365486"
                        bgColor="#365486"
                        variant="contained"
                        className="font-regular heading-small"
                      />
                    </a>
                  </div>
                </div>
              </Box>
            )}
          </Box>
        </PopOver>
        <ReCaptcha />
      </div>
      {/* Body Start */}
      <div className="BackLinkBody">
        <div className="BackBox">
          <h5>
            Discover all of the backlinks to your website and your competitors'
            websites
          </h5>
          <p>
            Backlink signifies the credibility of your site is in the eyes of
            users and search engines. The quality and quantity of backlinks on
            your site have huge impact upon the ranking of your website. Our
            free backlink checker will make it quick and convenient to help you
            know who links to you and your competition.
          </p>
          <p>
            Unlike other rank tracking tools, our ranking tool also offers AI
            writing assistance, which generates trendy topics based on
            competitive keyword writers to let you pick the "topic keyword" or
            "article" from the title list.
          </p>
          <h5>How to use Free Backlink Checker?</h5>
          <p>
            Free backlink checker shows you the backlink profile of any site on
            the web. Discover your competitor’s most valuable backlinks and
            analyze their backlink profile to understand patterns and spot
            opportunities. Our free version of backlink checker is very simple
            to use. Just enter your domain name into the tool and it will show
            you the following
          </p>
          <div className="IMGbox">
            <img src={SEC1} alt="secOne " />
          </div>
          <ul className="ms-4 mt-4 ">
            {Section1Ul?.map((Li) => (
              <li key={Li}>{Li}</li>
            ))}
          </ul>
          <h5>How backlink checker is beneficial for your SEO strategy?</h5>

          <div className="IMGbox">
            <img src={SEC2} alt="secOne " className="mt-3" />
          </div>
          <ul className="ms-4 mt-4 ">
            {Section2Ul?.map((Li) => (
              <li key={Li}>{Li}</li>
            ))}
          </ul>
          <br />
          <hr />
          <div className="secEnd">
            <div className="row">
              <div className="col-md-9 Leftb">
                <h3>
                  Want to see how your backlink profile compares to your
                  competitors'? Sign up for a free backlink check today and see
                  who's beating you to the first page of Google!
                </h3>
              </div>
              <div className="col-md-3 Rightb">
                <a href="https://app.rankspro.io/register" target="_blank">
                  <button className="btnNormal">Sign up for a free</button>
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
      <FooterTool />
    </div>
  );
}

export default BacklinkChecker;
