// components/SocialJuice.js
import { useEffect } from "react";

const SocialJuice = ({ id }) => {
  useEffect(() => {
    // Create a script element
    const script = document.createElement("script");
    script.src = `https://widget.senja.io/widget/${id}/platform.js`;
    script.async = true;
    document.body.appendChild(script);
    // Cleanup script when component unmounts
    return () => {
      document.body.removeChild(script);
    };
  }, []);

  return (
    <div
      className="senja-embed"
      data-id={id}
      data-mode="shadow"
      data-lazyload="false"
      style={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
      }}
    ></div>
  );
};

export default SocialJuice;
