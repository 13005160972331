import React from "react";
import { useNavigate } from "react-router-dom";
import { Player } from "@lottiefiles/react-lottie-player";
import upgradeAnimation from "../../dataFiles/animation/upgrade.json";

const PlUpgrade = () => {
  const navigate = useNavigate();

  const gotologin = () => {
    navigate("/upgrade");
  };
  return (
    <div className="pllogin-div">
      <div className="add-new-project">
        <div className="project-img">
          <Player
            src={upgradeAnimation}
            className="player"
            loop
            autoplay
            speed={1}
          />
        </div>
        <div className="pl-con">
          <div>
            <h5>
              <b>We Are Sorry...</b>
            </h5>
            <p className="mb-0">You can't access this section Please </p>
            <p>Select a Plan</p>
            <button className="upgrade-plan-btn" onClick={gotologin}>
              Upgrade
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PlUpgrade;
