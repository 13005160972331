import React, { useEffect } from "react";
import { useState } from "react";
import ProjectContent from "./projectContent";
import { ScrollToView } from "../../share/helper-js/scroll-into-view";
import axios from "axios";
import { blogdelete } from "../../../services/constants";
import PopOver from "../../share/components/popover.component";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import IMG from "./Assets/img.png";
import deleteImg from "../../Assets/areusure.png";
import backIcon from "../../Assets/common/back.svg";
import deleteIcon from "../../Assets/rank-tracking-assets/delete.svg";

const ProjectDetails = ({ data, UpdateData, EmptyData }) => {
  const [keywordContent, setKeywordContent] = useState([]);
  const email = localStorage.getItem("admin_mail");
  const [PoperStatus, setPoperStatus] = useState(false);
  const [deleteArticleData, setDeleteArticleData] = useState({
    popup: false,
    data: null,
  });

  const handleDeleteArticle = () => {
    const config = {
      email: email,
      keyword: deleteArticleData?.data?.keyword,
      date: deleteArticleData?.data?.date,
      month: deleteArticleData?.data?.month,
      year: deleteArticleData?.data?.year,
    };

    axios
      .post(blogdelete(config))
      .then(() => {
        UpdateData(data.websiteurl);
      })
      .catch((error) => {
        console.log(error, "error");
      });
  };

  useEffect(() => {
    if (data?.data?.length === 0) {
      setPoperStatus(true);
    } else {
      setPoperStatus(false);
    }
  }, []);

  function handleClose() {
    EmptyData([]);
    setPoperStatus(false);
  }

  function handleEditoerClose() {
    setKeywordContent([]);
    setPoperStatus(false);
  }

  const getPTagContent = (htmlString) => {
    const regex = /<p>(.*?)<\/p>/g;
    const matches = htmlString.match(regex);

    const paragraphs = matches
      ? matches.map((match, index) => (
          <p key={index} dangerouslySetInnerHTML={{ __html: match }} />
        ))
      : [];
    return paragraphs[0]?.props?.dangerouslySetInnerHTML?.__html.replace(
      /<\/?p>/g,
      ""
    );
  };

  return (
    <div className="">
      <PopOver anchorEl={deleteArticleData?.popup}>
        <div className="AiBoxPop " style={{ cursor: "default" }}>
          <div className="PopImg">
            <img src={deleteImg} alt="" />
          </div>
          <div className="font-bold popover-list-header">
            <h4 className="m-0">Delete Article</h4>
            <IconButton
              className="popover-close-icon"
              onClick={handleClose}
              sx={{
                "&:hover": {
                  backgroundColor: "rgba(240, 242, 255, 1)",
                },
              }}
            >
              <CloseIcon sx={{ color: "black" }} fontSize="large" />
            </IconButton>
          </div>

          <p className="mb-0 mt-3  font-regular">
            Are you sure you want to permanently remove this article ?
          </p>
          <div className="mt-3 cmd">
            <button
              className="btn_new_tr_bg"
              style={{ padding: "10px 20px" }}
              onClick={() =>
                setDeleteArticleData({
                  popup: false,
                  data: null,
                })
              }
            >
              Cancel
            </button>
            <button
              className="btn_new_bg"
              style={{ padding: "10px 20px" }}
              onClick={() => handleDeleteArticle()}
            >
              Delete
            </button>
          </div>
        </div>
      </PopOver>
      <PopOver anchorEl={PoperStatus}>
        <div className="AiBoxPop " style={{ cursor: "default" }}>
          <div className="PopImg">
            <img src={IMG} alt="" />
          </div>
          <div className="font-bold popover-list-header">
            <h4 className=" mb-0">NO DOCUMENTS YET</h4>
            <IconButton
              className="popover-close-icon"
              onClick={handleClose}
              sx={{
                "&:hover": {
                  backgroundColor: "rgba(240, 242, 255, 1)",
                },
              }}
            >
              <CloseIcon sx={{ color: "black" }} fontSize="large" />
            </IconButton>
          </div>

          <p className="mb-0 mt-4  font-regular">
            Create a Document to Access This Area
          </p>
        </div>
      </PopOver>
      {data?.data?.length === 0 ? null : (
        <div>
          {keywordContent.length !== 0 ? (
            <ProjectContent
              data={keywordContent}
              userproject={data?.websiteurl}
              handleClose={handleEditoerClose}
            />
          ) : (
            <>
              <button
                className="icon-btn large-btn"
                onClick={() => handleClose()}
              >
                <img src={backIcon} alt="" />
              </button>
              <div className="row ">
                {data.data?.map((res, index) => {
                  return (
                    <div
                      className="col-xl-12 col-xxl-12 col-lg-12 col-md-12 my-4"
                      key={`ai-card-article-${index}`}
                    >
                      <h6 className="heading-medium font-bold ps-1">
                        {res.title}
                      </h6>

                      <div className="AiBox mb-3">
                        <div
                          style={{
                            display: "flex",
                            flexDirection: "column",
                            justifyContent: "space-between",
                            cursor: "pointer",
                          }}
                          onClick={() => {
                            ScrollToView("ai-project-container");
                            setKeywordContent(res);
                          }}
                        >
                          <div className="h-100 ">
                            <div
                              className="contentSubBox"
                              style={{
                                borderRadius: "5px",
                                padding: "1rem 1.5rem",
                                marginBottom: "1rem",
                              }}
                            >
                              <p className="heading-medium font-light-bold mb-1">
                                Target Keyword
                              </p>
                              <p
                                style={{
                                  display: "inline-block",
                                }}
                                className="heading-small font-light-bold mb-0 py-2 px-3"
                              >
                                {res.keyword}
                              </p>
                            </div>
                            <div
                              style={{
                                padding: "1.5rem 1.5rem",
                                height: "calc(100% - 200px)",
                              }}
                              className="contentSubBoxDes"
                            >
                              <p className="heading-medium font-light-bold">
                                Description
                              </p>
                              <span className="heading-xs ai-card-des mt-1 text-dark">
                                {getPTagContent(res.content?.[0]?.content)}{" "}
                                <span
                                  style={{ color: "#365486" }}
                                  className="heading-small font-light-bold"
                                >
                                  <u>More...</u>
                                </span>
                              </span>
                            </div>
                          </div>
                        </div>
                        <div
                          className="cmd mt-auto p-4"
                          style={{ cursor: "pointer" }}
                        >
                          <div className="">
                            <span className="font-bold">Last Updated : </span>
                            <span className="font-light-bold">
                              {`${res.date}-${res.month}-${res.year}`}
                            </span>
                          </div>
                          <button
                            className="icon-btn large-btn"
                            onClick={() =>
                              setDeleteArticleData({
                                popup: true,
                                data: res,
                              })
                            }
                          >
                            <img src={deleteIcon} alt="delete-article" />
                          </button>
                        </div>
                      </div>
                    </div>
                  );
                })}
              </div>
            </>
          )}
        </div>
      )}
    </div>
  );
};

export default ProjectDetails;
