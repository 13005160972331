import React, { useState, useEffect, useMemo } from "react";
import "./performance.scss";
import LineChart from "../../components/charts/line-chart";
import BarChart from "../../components/charts/bar-chart";
import { useSelector } from "react-redux";
import axios from "axios";
import { GET_CONVERSION, GET_AFFILIATE_REWARD } from "../../utils/Api";
import { DottLoader } from "../../components/Loader";

import Box from "@mui/material/Box";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";

const DropDown = ({ year, updateYear, yearData }) => {
  return (
    <Box sx={{ minWidth: 120 }}>
      <FormControl fullWidth>
        <Select
          labelId="demo-simple-select-label"
          id="demo-simple-select"
          value={year}
          // label="Age"
          onChange={updateYear}
        >
          {yearData?.map((year, index) => {
            return (
              <MenuItem value={index} key={`dropdown-option-${index}`}>
                {year}
              </MenuItem>
            );
          })}
        </Select>
      </FormControl>
    </Box>
  );
};
function Performance() {
  const [Count, setCount] = useState(0);
  const [Data, setData] = useState([]);
  const user_profile = useSelector((state) => state.profile);
  const currentDate = new Date();
  const currentYear = new Date().getFullYear();
  // const currentMonth = currentDate.getMonth();
  const [CurrentYear, setCurrentYear] = useState("");

  const onChangeYearlyHandler = (e) => {
    setCurrentYear(e);
  };

  useEffect(() => {
    if (user_profile?.length != 0 && user_profile?.profile2?.length != 0) {
      var data = {
        click: 0,
        conversion: [],
        earning: 0,
      };
      axios
        .get(
          `https://app.rankspro.io/api/user/affclickget/?aff_id=${user_profile?.profile2.aff_id}`
        )
        .then((response) => (data.click = response?.data));

      axios
        .get(GET_CONVERSION(user_profile?.profile2?.email))
        .then((response) => {
          data.conversion = response.data[0].user;
          axios.get(GET_AFFILIATE_REWARD()).then((resp) => {
            const user = response.data[0].user;
            const rewordArr = user?.map((plan) => {
              return (plan.price * Number(resp.data[0])) / 100;
            });
            let sum = 0;
            for (let i of rewordArr) {
              sum += i;
            }
            data.earning = sum;
            setTimeout(() => {
              setData(data);
            }, 200);
          });
        });
    }
  }, [user_profile]);

  const COLOR = useMemo(() => {
    if (document.getElementById("single-line-chart") !== null) {
      var ctx = document.getElementById("single-line-chart").getContext("2d");
      var gradient = ctx.createLinearGradient(0, 50, 0, 150);
      gradient.addColorStop(0, "rgba(69, 112, 151, 1)");
      gradient.addColorStop(1, "rgba(69, 81, 151, .3)");
      return gradient;
    }
  }, [Count]);

  useEffect(() => {
    setTimeout(() => {
      setCount(Count + 1);
    }, 1000);
  }, []);

  var allMonths = [
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "May",
    "Jun",
    "Jul",
    "Aug",
    "Sep",
    "Oct",
    "Nov",
    "Dec",
  ];

  const handleYearwiseDataClick = (data) => {
    var yearWiseData = {};
    data?.forEach(function (entry) {
      var year = entry.year;
      if (!yearWiseData[year]) {
        yearWiseData[year] = [];
      }
      yearWiseData[year].push({
        month: entry.month,
        click: entry.click,
      });
    });

    for (var year in yearWiseData) {
      var monthsWithData = yearWiseData[year].map((entry) => entry.month);

      allMonths.forEach(function (month) {
        if (!monthsWithData.includes(month)) {
          yearWiseData[year].push({ month: month, click: "0" });
        }
      });

      yearWiseData[year].sort(function (a, b) {
        return allMonths.indexOf(a.month) - allMonths.indexOf(b.month);
      });
    }

    var yearWiseArray = Object.keys(yearWiseData).map(function (year) {
      return {
        year: parseInt(year),
        data: yearWiseData[year],
      };
    });
    return yearWiseArray;
  };

  const handleYearwiseDataConversion = (array) => {
    const data = array;

    const formattedData = [];
    const groupedByYear = {};
    data?.forEach((entry) => {
      const { year, month } = entry;

      if (!groupedByYear[year]) {
        groupedByYear[year] = {};
      }

      if (!groupedByYear[year][month]) {
        groupedByYear[year][month] = [];
      }

      groupedByYear[year][month].push({
        email: entry.email,
        name: entry.name,
        plan: entry.plan,
        price: entry.price,
        month: entry.month,
        year: entry.year,
      });
    });

    Object.keys(groupedByYear).forEach((year) => {
      const yearData = {
        year: parseInt(year),
        month: allMonths.map((month) => ({
          name: month,
          data: groupedByYear[year][month] || [],
        })),
      };

      formattedData.push(yearData);
    });
    return formattedData;
  };

  const click_current_data = handleYearwiseDataClick(Data?.click);
  const click_current_year_data = click_current_data?.filter(
    (entry) => entry.year === currentYear
  );

  const conversion_current_data = handleYearwiseDataConversion(
    Data?.conversion
  );

  const conversion_current_year_data = conversion_current_data?.filter(
    (entry) => entry.year === currentYear
  );

  var click_data = click_current_year_data?.[0]?.data?.map((item) => {
    return item.click;
  });

  var bar_data = conversion_current_year_data?.[0]?.month?.map((item) => {
    return item?.data?.length;
  });

  const chart_data = {
    label: "",
    labels: [
      "Jan",
      "Feb",
      "Mar",
      "Apr",
      "May",
      "Jun",
      "Jul",
      "Aug",
      "Sep",
      "Oct",
      "Nov",
      "Dec",
    ],
    barData: bar_data,
    dataSet: [
      {
        label: "Total Clicks",
        data: click_data,
        borderColor: "rgba(141, 160, 255, 1)",
        fill: "start",
        backgroundColor: COLOR,
        borderWidth: 2,
        tension: 0.1,
        yAxisID: "y",
        pointRadius: 3,
        pointHoverRadius: 5,
        pointBorderColor: "rgba(141, 160, 255, 1)",
        pointBackgroundColor: "#FFFFFF",
      },
    ],
  };

  const yearData1 = [2023, 2024];

  return (
    <>
      {user_profile?.profile2.length === 0 ? (
        <div className="affiliate-inprogress">
          <h1>Your Affiliate registration is in progress</h1>
          <p>Thank you for your patience....</p>
        </div>
      ) : (
        <div className="performance-container">
          <div className="main-header">
            <h1>Affiliate Module</h1>
          </div>
          {Data?.length === 0 ? (
            <div className="content-loader">
              <DottLoader />
            </div>
          ) : (
            <>
              <div className="section-1">
                <div className="chart-1">
                  <div className="header">
                    <h1>Clicks</h1>
                    {/* <FiAlertCircle /> */}
                  </div>
                  <div className="content">
                    <LineChart data={chart_data} />
                  </div>
                </div>
              </div>

              <div className="section-1">
                <div className="chart-1">
                  <div className="header">
                    <h1>Conversion Rates</h1>
                    {/* <DropDown
                      year={currentYear}
                      updateYear={onChangeYearlyHandler}
                      yearData={yearData1}
                    /> */}
                    {/* <FiAlertCircle /> */}
                  </div>
                  <div className="content">
                    <BarChart data={chart_data} />
                  </div>
                </div>
              </div>

              {/* <div className="section-2">
                <div className="chart-2">
                  <div className="header">
                    <div>
                      <h1>Conversion Rates</h1>
                      <p>(+43%) than last year</p>
                    </div>
                    <FiAlertCircle />
                  </div>
                  <div className="content">
                    <BarChart data={chart_data} />
                  </div>
                </div>
              </div> */}

              <div className="section-3">
                <div className="chart-3">
                  <div className="header">
                    <h1>Earnings</h1>
                  </div>
                  <div className="content">
                    <div className="fst-content">
                      <div className="d-flex align-items-center mb-3">
                        <h1>{`$${parseFloat(Data?.earning?.toFixed(2))}`}</h1>
                        <span>
                          {/* <BiSolidUpArrow className="ms-3 me-1" /> 10% */}
                        </span>
                      </div>
                      {/* <p className="mb-3">Compared to $84,325 last year</p> */}
                    </div>
                    <div className="earning-list">
                      <div className="earning-item">
                        <div className="fst">
                          <span style={{ color: "rgba(41, 41, 41, 1)" }}>
                            Present Month Earning
                          </span>
                          {/* <span style={{ color: "rgba(133, 133, 133, 1)" }}>
                        <BsArrowUp color="#05E87B" /> 253k
                      </span> */}
                        </div>
                        <div className="snd">
                          <span>{`$${parseFloat(
                            Data?.earning?.toFixed(2)
                          )}`}</span>
                          {/* <span>
                            <div className="progress">
                              <div
                                className="progress-bar w-75"
                                style={{
                                  backgroundColor: "rgba(69, 81, 151, 1)",
                                }}
                                role="progressbar"
                                aria-valuenow="75"
                                aria-valuemin="0"
                                aria-valuemax="100"
                              ></div>
                            </div>
                          </span> */}
                        </div>
                      </div>
                      <div className="earning-item">
                        <div className="fst">
                          <span style={{ color: "rgba(41, 41, 41, 1)" }}>
                            Last Month Earning
                          </span>
                          {/* <span style={{ color: "rgba(133, 133, 133, 1)" }}>
                        <BsArrowDown color="#E82E05" /> 124k
                      </span> */}
                        </div>
                        <div className="snd">
                          <span>{`$${parseFloat(
                            Data?.earning?.toFixed(2)
                          )}`}</span>
                          {/* <span>
                            <div className="progress">
                              <div
                                className="progress-bar w-75"
                                style={{
                                  backgroundColor: "rgba(255, 226, 209, 1)",
                                }}
                                role="progressbar"
                                aria-valuenow="75"
                                aria-valuemin="0"
                                aria-valuemax="100"
                              ></div>
                            </div>
                          </span> */}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </>
          )}
        </div>
      )}
    </>
  );
}

export default Performance;
