import * as React from "react";
import { styled } from "@mui/material/styles";
import Tooltip, { tooltipClasses } from "@mui/material/Tooltip";

const HtmlTooltip = styled(({ className, ...props }) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.arrow}`]: {
    color: "rgba(0, 0, 0, 0.8)",
    "&::before": {
      backgroundColor: "#ffd7df",
      border: "1px solid #e95655",
    },
  },
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: "#ffd7df",
    color: "rgba(0, 0, 0, 0.8)",
    maxWidth: 220,
    fontSize: theme.typography.pxToRem(12),
    border: "1px solid #e95655",
  },
}));

export default function CustomizedTooltips({ alert, children }) {
  return (
    <div>
      <HtmlTooltip
        placement="right"
        arrow
        PopperProps={{
          disablePortal: true,
        }}
        open={alert?.show}
        disableFocusListener
        disableHoverListener
        disableTouchListener
        title={
          <React.Fragment>
            <p className="my-2">{alert?.msg}</p>
          </React.Fragment>
        }
      >
        {children}
      </HtmlTooltip>
    </div>
  );
}
