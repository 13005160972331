export const today = new Date();

export function formatDateToYYYYMMDD(date) {
  const year = date?.getFullYear();
  const month = String(date?.getMonth() + 1).padStart(2, "0"); // Months are zero-based
  const day = String(date?.getDate()).padStart(2, "0");
  return `${year}-${month}-${day}`;
}

// backlink
export function getDaysDifference(targetDate) {
  var target = new Date(targetDate);
  var currentDate = new Date();
  var differenceInMilliseconds = target - currentDate;
  var differenceInDays = Math.floor(
    differenceInMilliseconds / (1000 * 60 * 60 * 24)
  );
  return differenceInDays > 0 ? differenceInDays + 1 : 1;
}

export function generateDate(days) {
  var currentDate = new Date();
  if (days) {
    currentDate.setDate(currentDate.getDate() + days);
  }
  var year = currentDate.getFullYear();
  var month = (currentDate.getMonth() + 1).toString().padStart(2, "0"); // Adding 1 because months are zero-based
  var day = currentDate.getDate().toString().padStart(2, "0");
  return year + "-" + month + "-" + day;
}

export function convertTo24HourFormat(hour, period) {
  if (period.toUpperCase() === "PM" && hour !== 12) {
    hour += 12;
  } else if (period.toUpperCase() === "AM" && hour === 12) {
    hour = 0;
  }
  return hour;
}

export function convertTo12HourFormat(twentyFourHour) {
  let period = "AM";
  let twelveHour = twentyFourHour;

  if (twentyFourHour >= 12) {
    period = "PM";
  }

  if (twelveHour > 12) {
    twelveHour -= 12;
  }
  return `${twelveHour} ${period}`;
}
