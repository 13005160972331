import React, { useState, useEffect, useMemo, useRef } from "react";
import "../../css/backlink.scss";
import { Link, useNavigate, useLocation } from "react-router-dom";
import Dropdown from "../../share/components/dropdown";
import CardOverviewValue from "../../share/card-layout/card-overview-value";
import SingleLineChart from "../../share/charts/line-chart";
import BarChart from "../../share/charts/bar-chart";
import axios from "axios";
import { CSVLink } from "react-csv";
import { curday, formatDate } from "../../share/upDater/constant";
import { DottLoader, DottLoader1 } from "../../share/loader";
import OpenInNewIcon from "@mui/icons-material/OpenInNew";
import { useSelector, useDispatch } from "react-redux";
import CloseIcon from "@mui/icons-material/Close";
import searchArrow from "../../Assets/keyword/search-arrow.svg";
import { useClickAway } from "react-use";
import { motion, AnimatePresence } from "framer-motion";
import { FiDownload } from "react-icons/fi";
import {
  get_backlink_data,
  get_domainauthority,
  post_backlink_data,
  get_user_backlinklimit,
  post_user_backlinklimit,
  update_user_backlinklimit,
  post_morebacklink,
  get_backlinktotallmtGet,
  post_backlinktotallmt,
  update_backlinktotallmtupdate,
  GET_ALLLIST_BACKLINKS,
  POST_NEWLIST_BACKLINKS,
  DELETE_LIST_BACKLINKS,
  PUT_ADDBACKLINKS,
  USER_BACKLINK_LIST_URLEML,
  USER_BACKLINK_LIST_URL,
} from "../../../services/constants";
import { ScrollToView } from "../../share/helper-js/scroll-into-view";
import ToolTip from "../../share/components/tooltip";
import {
  useMediaQuery,
  Box,
  Grid,
  IconButton,
  Typography,
} from "@mui/material";
import { getCurrentYear } from "../../share/helper-js/current-year";
import CustomPagination from "../../share/components/pagination.component";
import { Delete } from "@mui/icons-material";
import AlertPopUp from "../../share/components/alert.component";
import { ViewAllListBackLink } from "../../../Navigation/constant";
import PopOver from "../../share/components/popover.component";
import addIcon from "../../Assets/rank-tracking-assets/add.svg";
import exportIcon from "../../Assets/rank-tracking-assets/export-file.svg";
import searchIcon from "../../Assets/rank-tracking-assets/search.svg";
import filterIcon from "../../Assets/backlink/filter.svg";
import backlinkIcon from "../../Assets/backlink/backlink.svg";
import refferingDomainIcon from "../../Assets/backlink/reffering-domain.svg";
import domainRankIcon from "../../Assets/backlink/domain-rank.svg";
import LineAlert from "../../share/components/lineAlert";
import backIcon from "../../Assets/common/back.svg";
import { CiCircleList } from "react-icons/ci";
import { domainRegex, formatNumberWithSymbols } from "../../../helperJs/helper";
import Tooltip from "../../share/components/tooltip";
import { ReactComponent as UnsortIcon } from "../../Assets/rank-tracking-assets/unshort.svg";
import { dynamicSort } from "../../../helperJs/helper";
import { ZustandStore } from "../../../store/zustand-store";
import jsPDF from "jspdf";
import html2canvas from "html2canvas";
import RanksProLogo from "../../../Mycomponent/Assets/site-audit/RanksProLogo.png";
import BackLinksPdfModal from "../Modals/PdfModals/BackLinksPdfModal";
import AnchorTextBacklinkPdfModal from "../Modals/PdfModals/AnchorTextBacklinkPdfModal";
import {
  Page,
  Text,
  View,
  Document,
  StyleSheet,
  Image,
  PDFDownloadLink,
} from "@react-pdf/renderer";

const styles = StyleSheet.create({
  page: {
    paddingHorizontal: 20,
    flexDirection: "column",
    paddingTop: 30,
    paddingBottom: 80, // Set vertical padding to 30 pixels
    borderStyle: "solid",
  },
  header: {
    paddingBottom:20
  },
  footer: {
    fontSize: 12,
    textAlign: "center",
    color: "grey",
    position: "absolute",
    bottom: 20,
    left: 0,
    right: 0,
  },
  logo: {
    width: 250,
  },
  title: {
    fontSize: 24,
    textAlign: "center",
    marginBottom: 20,
  },

 
  tableContainer: {
    marginBottom: 20,
  },

  keywordLoc: {
    flex: 1, // Each cell should take equal space within the row
    padding: 5,
    borderRightWidth: 1,
    borderColor: "#000",
    justifyContent: "start",
    minHeight: 40,
    height: "100%",
    alignItems: "start",
    flexDirection: "column",
  },
 
  fixedWidthCell: {
    maxWidth: 80, // Disable flex for fixed width cells
  },
  cardContainer: {
    flexDirection: "row",
    flexWrap: "wrap", // Enable flex wrapping
    justifyContent: "space-between", // Arrange cards with equal spacing
    marginTop: 10,
  },
  card: {
    flexDirection: "row",
    justifyContent: "space-between",
    width: 180,
    backgroundColor: "#FFFFFF",
    padding: 10,
    borderRadius: 5,
    marginBottom: 20,
  },
  image: {
    width: 30,
    height: 40,
  },
  title: {
    fontSize: 11,
    fontWeight: "bold",
    marginBottom: 5,
    color: "#6c757d",
  },
  description: {
    fontSize: 16,
  },
  imageContainer: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  arrowIcon: {
    width: 20,
    height: 20,
    objectFit: "contain",
  },
  view_button: {
    borderWidth: 1,
    borderColor: "#365486",
    padding: 5,
    borderRadius: 7,
    color: "#365486",
    fontSize: 11,
  },
  keyword_bold: {
    fontSize: 13,
    fontWeight: "bold",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  keyword: {
    fontSize: 11,
    color: "#6c757d",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  rank: {
    fontSize: 11,
    color: "#6c757d",
    textAlign: "center",
    marginRight: 10,
  },
  
 
  pageNumber: {
    position: "absolute",
    fontSize: 11,
    bottom: 45,
    left: 0,
    right: 0,
    textAlign: "center",
    color: "grey",
  },
  link_text: {
    fontSize: 7,
    textDecoration: "none",
    textOverflow: "hidden",
    textAlign: "start",
  },
  logoHead: {
    width: 125,
  },
});






export const PdfFile = ({pdfNewData}) => {
  
  const DashImage = sessionStorage.getItem("dashboardImage");
  const Data = JSON.parse(sessionStorage.getItem("pdfSessionData"));
  const Web_url = Data?.Keyword
  const image_logo = pdfNewData
    ? pdfNewData?.reset
      ? RanksProLogo
      : pdfNewData?.logo?.src
    : RanksProLogo;
  return(
  <Document>
    <Page size={"A4"} style={styles.page}>
      <View
        style={{
          alignItems: "center",
          display: "flex",
          height: "82vh",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <Image style={styles.logo} src={image_logo} />
        <Text style={{ marginBottom: 20 }}>Organic Search</Text>
        <Text style={styles.title}>Domain: {Web_url}</Text>
      </View>


</Page>
<Page size={"A4"} style={styles.page}>
  
<View
          style={{
            ...styles.header,
            alignItems: pdfNewData?.reset
              ? "flex-start"
              : pdfNewData?.logo.position,
          }}
          fixed
        >
          <Image style={styles.logoHead} src={image_logo} />
        </View>

      <View style={{ ...styles.tableContainer }}>
        <View>
       <Image src={DashImage}/>
        </View>
      </View>

      <Text style={styles.footer} fixed>
      {pdfNewData?.reset
            ? `Copyright ${getCurrentYear} eSearch Logix | Prepared using RanksPro.io`
            : pdfNewData?.footer}
      </Text>

     
    </Page>
  </Document>
);}
























const framerFilterEffect = {
  initial: { display: "none", transformOrigin: "left" },
  animate: { display: "flex", transformOrigin: "left" },
  exit: {
    display: "none",
    transition: { delay: 0.2 },
    transformOrigin: "left",
  },
  transition: { duration: 0.3 },
};

const framerInputEffect = {
  initial: { transform: "scale(0) ", transformOrigin: "right" },
  animate: { transform: "scale(1) ", transformOrigin: "right" },
  exit: {
    transform: "scale(0) ",
    transition: { delay: 0.2 },
    transformOrigin: "right",
  },
  transition: { duration: 0.3 },
};

























const BackLinks = () => {
  const searchRef = useRef(null);
  const filterRef = useRef(null);
  const websiteurl = useSelector((state) => state.websiteurl);
  const dispatch = useDispatch();
  const [anchorEl, setAnchorEl] = useState(false);
  const [CreateList, setCreateList] = useState(false);
  const [ViewAllLists, setViewAllLists] = useState([]);
  const [ListValue, setListValue] = useState("");
  const [alertMsg, setalertMsg] = useState(false);
  const [ListChooseErr, setListChooseErr] = useState(false);
  const [inputEmptyErr, setinputEmptyErr] = useState(false);
  const [DeleteLoading, setDeleteLoading] = useState(false);
  const [ListName, setListName] = useState("");
  const [isOpen, setIsOpen] = useState(false);
  const [isFilterOpen, setIsFilterOpen] = useState(false);
  const [limitTableData, setLimitTableData] = useState([]);
  const [limitShow, setLimitShow] = useState(1);
  const data_len = 200;
  const totalShowTable = data_len * limitShow;
  const { backlink_analysis, get_domain_authority } = ZustandStore(
    (state) => state
  );

  useClickAway(searchRef, () => {
    setIsOpen(false);
  });

  useClickAway(filterRef, () => {
    setIsFilterOpen(false);
  });

  const handleFilterToggle = () => {
    setIsFilterOpen(!isFilterOpen);
  };

  const handleToggle = () => {
    setIsOpen(!isOpen);
  };

  function handleClose() {
    setAnchorEl(false);
    setListValue("");
    setalertMsg(false);
    setinputEmptyErr(false);
    setTimeout(() => {
      setCreateList(false);
    }, 500);
    setListName("");
  }

  const HandleOpenAddList = () => {
    setCreateList(true);
  };

  const HandleOpen = () => {
    setAnchorEl(true);
  };

  const [Keyword, setKeyword] = useState("");
  const [Domain, setDomain] = useState("Domain");
  const [domainAuthority, setDomainAuthority] = useState("");
  const [loader, setLoader] = useState(false);
  const [ExcludeSubdomains, setExcludeSubdomains] = useState(false);
  const backlinkDomain = useSelector((state) => state.backlinkDomain);
  const backlinklimits = useSelector((state) => state.backlinkLimit);
  const userRowLmt = backlinklimits?.backlinks_rows_limit;
  const EMAIL = useSelector((state) => state.email);

  const navigate = useNavigate();
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const email = localStorage.getItem("admin_mail");

  const handleBacklinkDataUpdate = (data, type) => {
    if (data?.data?.length === 0) {
      ZustandStore.setState({
        backlink_analysis: { data: [], type: "" },
      });
      setData([]);
      setTableData([]);
      setLimitTableData([]);
      setError(`Sorry, No Data for this domain. Please check the domain`);
      return;
    }
    setData(data);
    const backlink_data = type === "get" ? data?.data?.[0]?.data : data?.data;
    const token =
      type === "get"
        ? data.data?.[0]?.search_after_token
        : data?.search_after_token;
    sessionStorage.setItem("search_after_token", token || "");
    setLimitTableData(backlink_data);
    setTableData(backlink_data?.slice(0, data_len));
    loadCSVData(backlink_data);
    dispatch({ type: "BACKLINKDOMAIN", payload: null });
  };

  const handleUserBacklinkHistoryData = (config) => {
    axios.get(get_backlink_data(config)).then((response) => {
      if (response.data?.length > 0) {
        const data = response.data?.[0];
        ZustandStore.setState({
          backlink_analysis: { data, type: "get" },
        });
        handleBacklinkDataUpdate(data, "get");
        axios
          .get(get_domainauthority(config.domain))
          .then((response) => {
            setDomainAuthority(response.data);
            setLoader(false);
            ZustandStore.setState({
              get_domain_authority: response.data,
            });
          })
          .catch((error) => {
            setDomainAuthority(0);
            ZustandStore.setState({
              get_domain_authority: 0,
            });
            setLoader(false);
          });
      } else {
        setKeyword("");
        setLoader(false);
        sessionStorage.removeItem("lastbacklinkdomain");
        removeQueryParamToURL();
      }
    });
  };

  const backlinkGetData = async (api) => {
    return await axios.get(api).then((response) => {
      return response;
    });
  };

  const handleUserBacklinkLimitOnRefresh = (config) => {
    setLimitAlert("");
    setData([]);
    setTableData([]);
    setLimitTableData([]);
    handleUserBacklinkHistoryData(config);
  };

  const domainName = sessionStorage.getItem("lastbacklinkdomain");

  useEffect(() => {
    const email = localStorage.getItem("admin_mail");
    const tabOption = sessionStorage.getItem("backlinkTab");
    if (tabOption != null) {
      setSelectedTab(Number(tabOption) || 1);
    }
    if (backlinkDomain != null) {
      setKeyword(backlinkDomain);
      setLoader(true);
      sessionStorage.setItem("lastbacklinkdomain", backlinkDomain);
      const config = {
        email: email,
        domain: backlinkDomain,
        include_subdomains: !ExcludeSubdomains,
      };

      backlinklimits?.backlinks_domain_lmt && handleUserBacklinkData(config);
    } else if (domainName != null) {
      setKeyword(domainName);
      setLoader(true);
      const config = {
        email: email,
        domain: domainName,
        include_subdomains: !ExcludeSubdomains,
      };
      addQueryParamToURL(domainName);
      if (
        get_domain_authority?.length === 0 &&
        backlink_analysis?.data?.length === 0
      ) {
        backlinklimits?.backlinks_domain_lmt &&
          handleUserBacklinkLimitOnRefresh(config);
      } else {
        setDomainAuthority(get_domain_authority);
        handleBacklinkDataUpdate(
          backlink_analysis.data,
          backlink_analysis.type
        );
        setLoader(false);
      }
    }
  }, [
    backlinklimits,
    backlinkDomain,
    domainName,
    backlink_analysis,
    get_domain_authority,
  ]);

  const [SelectOption, setSelectOption] = useState("All Links");
  const [Error, setError] = useState();
  const [Data, setData] = useState([]);

  const [detailsCSV, setDetailsCSV] = useState([]);

  // anchor list
  const [anchorList, setAnchorList] = useState(false);
  const [anchorPage, setAnchorPage] = useState(1);
  const [anchorLastPage, setAnchorLastPage] = useState(1);

  // table state
  const [tableData, setTableData] = useState([]);
  const [tableMoreData, setTableMoreData] = useState([]);
  const [tablePage, setTablePage] = useState(1);
  const [tabValue, setTabValue] = useState("0");
  const [searchQuery, setSearchQuery] = useState("");
  const [tableMessage, setTableMessage] = useState("");
  const [tableLoader, setTableLoader] = useState(false);
  const [selectAll, setSelectAll] = useState(false);
  const [selectedData, setSelectedData] = useState([]);

  const [selectedTab, setSelectedTab] = useState(1);
  const [limitAlert, setLimitAlert] = useState("");

  const [sortDirection, setSortDirection] = useState(true);
  const [anchorTable, setAnchorTable] = useState([]);

  const headers = [
    {
      label: "Page Title",
      key: "page_title",
    },
    {
      label: "Source Url",
      key: "Source",
    },
    {
      label: "Target Url",
      key: "Target",
    },
    { label: "Domain Authority", key: "domain_authority" },
    { label: "Page Authority", key: "Page_authority" },
    { label: "Spam Score", key: "spam_score" },
    { label: "Anchor Text", key: "anchor_text" },
    { label: "First Seen", key: "first_seen" },
    { label: "Last Seen", key: "last_seen" },
  ];

  const removeQueryParamToURL = () => {
    const parametersToRemove = ["domain", "exclude-subdomain", "type"];
    const searchParams = new URLSearchParams(location.search);
    parametersToRemove.forEach((param) => {
      searchParams.delete(param);
    });

    const newSearch = searchParams.toString();
    const newUrl = `${location.pathname}${newSearch ? `?${newSearch}` : ""}`;
    navigate(newUrl);
  };

  const addQueryParamToURL = (name) => {
    if (Keyword || name) {
      queryParams.set("domain", Keyword || name);
      queryParams.set("exclude-subdomain", !ExcludeSubdomains);
      queryParams.set("type", Domain || "Domain");
    } else {
      queryParams.delete("domainName");
      queryParams.delete("Exclude-subdomain");
      queryParams.delete("Type");
    }
    const newSearch = queryParams.toString();
    navigate({ search: `?${newSearch}` });
  };

  const loadCSVData = (data) => {
    const CSV_Array = [];
    data &&
      data?.map((res) => {
        CSV_Array.push({
          page_title: res?.page_from_title,
          Source: res?.url_from,
          Target: res?.url_to,
          domain_authority: res?.domain_authority,
          Page_authority: res?.page_authority,
          spam_score: `${res?.backlink_spam_score}%`,
          anchor_text: res?.anchor,
          first_seen: formatDate(res?.first_seen),
          last_seen: formatDate(res?.last_seen),
        });
      });
    setDetailsCSV(CSV_Array);
  };

  function filterDuplicates(arr) {
    const uniqueNames = {};
    const result = [];
    for (const item of arr) {
      if (item.domain_from && !uniqueNames[item.domain_from]) {
        uniqueNames[item.domain_from] = true;
        result.push(item);
      }
    }
    return result;
  }

  const search_after_token = sessionStorage.getItem("search_after_token");
  const history_list = Data?.history_list
    ? Data?.history_list
    : Data?.data?.[0]?.history_list;
  const anchor_list = Data?.anchor_list
    ? Data?.anchor_list
    : Data?.data?.[0]?.anchor_list;
  const total_backlinks = Data?.total_backlinks
    ? Data?.total_backlinks
    : Data?.data?.[0]?.total_backlinks;
  const anchor_list_data = anchor_list?.filter((anchor) => {
    return isURL(anchor?.anchor) || anchor?.anchor === null ? null : anchor;
  });

  // achor list pagination
  const anchorItemsPerPage = 25;
  const anchorStartIndex = (anchorPage - 1) * anchorItemsPerPage;
  const anchorEndIndex = anchorStartIndex + anchorItemsPerPage;
  const anchorTotalPages = Math.ceil(
    anchor_list_data?.length / anchorItemsPerPage
  );

  const anchorListData = useMemo(
    () => anchor_list_data?.slice(anchorStartIndex, anchorEndIndex),
    [anchor_list_data, anchorStartIndex, anchorEndIndex]
  );

  useEffect(() => {
    if (anchorListData?.length > 0 && anchorTable?.length === 0) {
      setAnchorTable(anchorListData);
    }
  }, [anchorListData, anchorTable]);

  const anchor_list_slice = anchor_list_data?.map((item) => {
    return isURL(item?.anchor) || item?.anchor === null ? null : item;
  });

  const handleAnchorListBack = () => {
    setSortDirection(true);
    setAnchorList(false);
    setTimeout(() => {
      ScrollToView("anchor-list-table");
    }, 100);
    setAnchorPage(1);
    setAnchorLastPage(1);
  };

  const handleAnchorLoadMore = (value) => {
    if (anchorTotalPages < value) return;
    setAnchorLastPage(value);
  };

  const handleAnchorPaginationChange = (value) => {
    setTimeout(() => {
      ScrollToView("backlink_page");
    }, 100);
    setAnchorPage(value);
  };

  // check for url
  function isURL(str) {
    var urlPattern = /(\.com|https|www|http)/gi;
    return urlPattern.test(str);
  }

  const postBacklinkLimit = (data) => {
    let post_config = {
      email: email,
      domain_name: Keyword,
      bakclinklmt: data?.length,
    };
    const rowLmt = backlinklimits?.backlinks_rows_limit;
    axios.post(post_user_backlinklimit(), post_config);
    axios.get(get_backlinktotallmtGet(email)).then((response) => {
      if (response.data?.length > 0) {
        axios.put(
          update_backlinktotallmtupdate(
            response?.data[response?.data?.length - 1].id
          ),
          {
            email: email,
            backlinktotallmt:
              response?.data[response?.data?.length - 1].backlinktotallmt -
              data?.length,
          }
        );
      } else if (response.data?.length === 0) {
        axios.post(post_backlinktotallmt(), {
          email: email,
          backlinktotallmt: rowLmt - data?.length,
        });
      }
    });
  };

  const handleUserBacklinkData = async (config) => {
    addQueryParamToURL(config.domain);
    setData([]);
    setTableData([]);
    setLimitTableData([]);
    axios.get(get_backlink_data(config)).then((response) => {
      if (response.data?.length > 0) {
        const data = response.data?.[0];
        ZustandStore.setState({
          backlink_analysis: { data, type: "get" },
        });
        handleBacklinkDataUpdate(data, "get");
      } else {
        axios.get(get_backlinktotallmtGet(email)).then((response) => {
          const userLmt =
            response.data[response.data?.length - 1]?.backlinktotallmt;
          if (response.data?.length > 0) {
            if (userLmt <= 0) {
              setLimitAlert(
                "Your limit for Backlinks search have been exceeded. Please Upgrade"
              );
              ZustandStore.setState({
                backlink_analysis: { data: [], type: "" },
              });
              setLoader(false);
              setData([]);
              setTableData([]);
              setLimitTableData([]);
              return;
            }
          }
          axios
            .post(post_backlink_data(config))
            .then((response) => {
              if (response.data?.length === 0) {
                ZustandStore.setState({
                  backlink_analysis: { data: [], type: "" },
                });
                setData([]);
                setTableData([]);
                setLimitTableData([]);
                setError(
                  `Sorry, No Data for this domain. Please check the domain`
                );
              } else {
                const backlink_data = response.data[0]?.data;
                postBacklinkLimit(backlink_data, response.data);
                handleBacklinkDataUpdate(response.data[0], "post");
                ZustandStore.setState({
                  backlink_analysis: { data: response.data[0], type: "post" },
                });
              }
            })
            .catch(() => {
              ZustandStore.setState({
                backlink_analysis: { data: [], type: "" },
              });
              setData([]);
              setTableData([]);
              setLimitTableData([]);
              setError(
                `Sorry, No Data for this domain. Please check the domain`
              );
            });
        });
      }
      axios
        .get(get_domainauthority(config.domain))
        .then((response) => {
          setLoader(false);
          setDomainAuthority(response.data);
          ZustandStore.setState({
            get_domain_authority: response?.data,
          });
        })
        .catch(() => {
          setLoader(false);
          setDomainAuthority(0);
          ZustandStore.setState({
            get_domain_authority: 0,
          });
        });
    });
  };

  const keywordClickHandler = (e) => {
    e.preventDefault();
    if (Keyword === "") {
      setError("Please enter domain");
    } else if (!domainRegex.test(Keyword) && Domain === "Domain") {
      setError("Please enter valid domain");
      setData([]);
      setTableData([]);
      setLimitTableData([]);
    } else {
      setLoader(true);
      sessionStorage.setItem("lastbacklinkdomain", Keyword);
      const config = {
        email: email,
        domain: Keyword,
        include_subdomains: !ExcludeSubdomains,
      };
      setLimitAlert("");
      handleUserBacklinkData(config);
    }
  };

  const keywordChangeHandler = (target) => {
    setError("");
    if (Domain == "URL") {
      setKeyword(target.value);
    } else {
      const url = target.value;
      const match = url.match(/^(?:https?:\/\/)?(?:www\.)?([^/]+)/i);
      setKeyword(match ? match[1].replace(/\s/g, "") : "");
    }
  };

  const domain_data = [{ language: "Domain" }, { language: "URL" }];

  const domainHandler = (e) => {
    if (e.language == "URL") {
      setKeyword(Keyword);
    } else {
      const match = Keyword.match(/^(?:https?:\/\/)?(?:www\.)?([^/]+)/i);
      setKeyword(match ? match[1].replace(/\s/g, "") : "");
    }
    setDomain(e.language);
  };

  const cardValue = [
    {
      id: 1,
      title: "DOMAIN RANK",
      toolkit:
        "Domain Rank: A metric that denotes the domain rank, referring to the target page or domain, calculated by summing each page’s rank value, narrowed down to a range on the scale. 0 range means new domain with limited backlinks, 5-50 range means older domains with higher quality backlinks, 50+ scale means huge domains with significant authority backlinks.",
      rank: domainAuthority || 0,
      Src: domainRankIcon,
      color: "backlink-card-background-3",
      bg: "#365486",
    },
    {
      id: 2,
      title: "REFERRING DOMAINS",
      toolkit:
        "Referring Domains  - How many unique domains are linking back to this website.",
      rank: history_list?.[history_list?.length - 1]?.referring_domains || 0,
      Src: refferingDomainIcon,
      color: "backlink-card-background-4",
      bg: "#365486",
    },
    {
      id: 3,
      title: "BACKLINKS",
      toolkit:
        "Backlinks  - How many incoming hyperlinks from other websites to this domain.",
      rank: total_backlinks || 0,
      Src: backlinkIcon,
      color: "backlink-card-background-3",
      bg: "#365486",
    },
  ];

  // New & Lost Referring Domains
  const bar_stacked_labels = history_list?.map((label) => {
    return label.month;
  });
  const bar_stacked_new = history_list?.map((label) => {
    return label.new_refeering_domains;
  });
  const bar_stacked_lost = history_list?.map((label) => {
    return `-${label.lost_referring_domains}`;
  });
  const bar_stacked_backlinks = history_list?.map((label) => {
    return label.backlinks;
  });
  const bar_stacked_referring_domains = history_list?.map((label) => {
    return label.referring_domains;
  });

  const newLostReferringDomains = history_list?.map((label) => {
    return {
      label: label.month,
      lost: `-${label.lost_referring_domains}`,
      new: label.new_refeering_domains,
    };
  });

  const backlinksOverTime = history_list?.map((label) => {
    return {
      label: label.month,
      backlink: label.backlinks,
      referring_domains: label.referring_domains,
    };
  });

  if (document.getElementById("single-line-chart") !== null) {
    var ctx = document.getElementById("single-line-chart").getContext("2d");
    var gradient1 = ctx.createLinearGradient(0, 0, 0, 300);
    var gradient = ctx.createLinearGradient(0, 0, 0, 300);
    gradient1.addColorStop(1, "rgb(255,176,50,0.2)");
    gradient1.addColorStop(0, "rgb(255,176,50,0)");
    gradient.addColorStop(1, "rgba(69,81,151,0.2)");
    gradient.addColorStop(0, "rgba(69,81,151,0)");
  }

  const chart_data = {
    label: Keyword,
    labels: bar_stacked_labels,
    title: [
      { left: "Backlinks", color: "#2E4161" },
      { right: "Referring Domains", color: "#B04656" },
    ],
    dataSet: [
      {
        label: "Backlinks",
        data: bar_stacked_backlinks,
        borderColor: "#2E4161",
        fill: "start",
        backgroundColor: "#365486B2",
        yAxisID: "y",
        pointRadius: 0,
        pointHoverRadius: 7,
        pointBorderColor: "#2E4161",
        pointBackgroundColor: "#2E4161",
      },
      {
        label: "Referring Domains",
        data: bar_stacked_referring_domains,
        borderColor: "#B04656",
        fill: "start",
        backgroundColor: "#E956558C",
        yAxisID: "y1",
        pointRadius: 0,
        pointHoverRadius: 7,
        pointBorderColor: "#E956558C",
        pointBackgroundColor: "#E956558C",
      },
    ],
  };

  const bar_stacked_chart_data = {
    label: Keyword,
    labels: bar_stacked_labels,
    dataSet: [
      {
        label: "New",
        data: bar_stacked_new,
      },
      {
        label: "Lost",
        data: bar_stacked_lost,
      },
    ],
  };

  function createArrayOfObjectsWithId(dataArray) {
    const newArray = [];
    for (let i = 0; i < dataArray?.length; i++) {
      const obj = dataArray[i];
      const id = `unique_id-${tablePage}${i}`;
      obj["sid"] = id;
      newArray.push(obj);
    }
    return newArray;
  }

  const tabOptionFiltered = (data) => {
    setTableLoader(true);
    if (SelectOption === "One per Domain") {
      const filteredArray = filterDuplicates(data);
      if (tabValue === "1") {
        const filtered_data = filteredArray?.filter((item) => {
          const query = item?.dofollow;
          return query ? item : null;
        });
        setTableData(filtered_data);
      } else if (tabValue === "2") {
        const filtered_data = filteredArray?.filter((item) => {
          const query = item?.dofollow;
          return !query ? item : null;
        });
        setTableData(filtered_data);
      } else {
        setTableData(filteredArray);
      }
    } else if (tabValue === "1") {
      const filtered_data = data?.filter((item) => {
        const query = item?.dofollow;
        return query ? item : null;
      });
      setTableData(filtered_data);
    } else if (tabValue === "2") {
      const filtered_data = data?.filter((item) => {
        const query = item?.dofollow;
        return !query ? item : null;
      });
      setTableData(filtered_data);
    } else {
      setTableData(data);
    }
    setTimeout(() => {
      setTableLoader(false);
    }, 200);
  };

  // table paginaton
  const itemsPerPage = 100;
  const startIndex = (tablePage - 1) * itemsPerPage;
  const endIndex = startIndex + itemsPerPage;
  const totalPages = Math.ceil(tableData?.length / itemsPerPage);
  const sortedData = tableData;

  const currentPageTableData = useMemo(() => {
    const array = sortedData?.slice(startIndex, endIndex);
    const change = createArrayOfObjectsWithId(array);
    return change;
  }, [sortedData, startIndex, endIndex]);

  const handlePaginationChange = (value) => {
    ScrollToView("backlink-table");
    setTableMessage("");
    setTablePage(value);
  };

  // table search handler
  const handleSearchChange = (value) => {
    setSearchQuery(value);
  };

  useEffect(() => {
    const debounce = setTimeout(() => {
      const backlink_table_data = Data?.data?.[0].data
        ? Data?.data?.[0].data || []
        : Data?.data || [];
      const backlink_data = backlink_table_data?.slice(0, data_len);
      if (backlink_data?.length > 0) {
        setTablePage(1);
        const combinedArray = [...backlink_data, ...tableMoreData];
        if (searchQuery?.length == 0) {
          tabOptionFiltered(combinedArray);
        } else {
          const filtered_data = combinedArray?.filter((item) => {
            const query = item?.url_from;
            return query?.toLowerCase().includes(searchQuery.toLowerCase())
              ? item
              : null;
          });
          tabOptionFiltered(filtered_data);
        }
      }
    }, 500);

    return () => {
      clearTimeout(debounce);
    };
  }, [searchQuery, tabValue, SelectOption]);

  const handleChangeTab = (value) => {
    if (value?.language === "Follow") {
      setTabValue("1");
    } else if (value?.language === "No Follow") {
      setTabValue("2");
    } else {
      setTabValue("0");
    }
  };

  const select_data = [
    { language: "All Links" },
    { language: "One per Domain" },
  ];

  const select_filter_data = [
    { language: "All Links" },
    { language: "Follow" },
    { language: "No Follow" },
  ];

  const selectDataHandler = (e) => {
    setSelectOption(e.language);
  };

  const backlinkTotalLmtUpdate = async (
    backlinkLmtGet,
    backlinkTotalLmtGet
  ) => {
    let data_limit_len = 200; // show table data length
    let post_config = {
      email: email,
      domain_name: Keyword,
      bakclinklmt:
        backlinkLmtGet.data?.[backlinkLmtGet.data?.length - 1]?.limit +
        data_limit_len,
    };
    let post_config2 = {
      email: email,
      backlinktotallmt:
        backlinkTotalLmtGet.data?.[backlinkTotalLmtGet?.data?.length - 1]
          ?.backlinktotallmt - data_limit_len,
    };
    if (backlinkLmtGet.data?.[backlinkLmtGet?.data?.length - 1] < userRowLmt) {
      axios.put(
        update_user_backlinklimit(
          backlinkLmtGet.data?.[backlinkLmtGet.data?.length - 1]?.id
        ),
        post_config
      );
    }

    if (
      backlinkTotalLmtGet?.data[backlinkTotalLmtGet?.data?.length - 1]
        ?.backlinktotallmt <= 0
    ) {
      return;
    }
    axios.put(
      update_backlinktotallmtupdate(
        backlinkTotalLmtGet.data?.[backlinkTotalLmtGet?.data?.length - 1]?.id
      ),
      post_config2
    );
  };

  const handleTableLoadMore = async () => {
    setLimitAlert("");
    const config = {
      domain: Keyword,
      include_subdomains: !ExcludeSubdomains,
      searchaftertoken: search_after_token,
      email: email,
    };
    const backlink_table_data = Data?.data?.[0].data
      ? Data?.data?.[0].data || []
      : Data?.data || [];

    const backlink_data =
      limitTableData?.length === tableData?.length
        ? backlink_table_data
        : tableData;

    // const shown_table_row = backlink_data?.length + tableMoreData?.length;
    const shown_table_row = limitTableData?.length;

    const backlinkLmtGet = await backlinkGetData(
      get_user_backlinklimit(config)
    );
    const backlinkTotalLmtGet = await backlinkGetData(
      get_backlinktotallmtGet(email)
    );

    if (
      backlinkTotalLmtGet?.data[backlinkTotalLmtGet?.data?.length - 1]
        ?.backlinktotallmt <= 0
    ) {
      setLimitAlert("Your limit for rows have been exceeded. Please Upgrade");
      return;
    } else if (shown_table_row >= total_backlinks) {
      setTableMessage(`No more data`);
      setTimeout(() => {
        setTableMessage("");
      }, 1000);
      return;
    }

    if (limitTableData?.length > totalShowTable) {
      setTableMessage("Loading more data");
      const limit_show_change = totalShowTable + data_len;
      setLimitShow(limit_show_change / data_len);
      backlinkTotalLmtUpdate(backlinkLmtGet, backlinkTotalLmtGet);
      const newData = limitTableData?.slice(0, totalShowTable + data_len);
      tabOptionFiltered(newData);
      setTimeout(() => {
        setTableMessage("");
      }, 1000);
      return;
    }

    setTableMessage(`Loading more data please wait`);
    axios.post(post_morebacklink(config)).then((response) => {
      if (response.data?.length === 0) {
        setTableMoreData(tableMoreData);
        tabOptionFiltered(tableData);
        setTableMessage("No more data");
        setTimeout(() => {
          setTableMessage("");
        }, 1000);
      } else {
        backlinkTotalLmtUpdate(backlinkLmtGet, backlinkTotalLmtGet);
        const new_data = response.data?.[0];
        const combinedArray = [...tableMoreData, ...new_data?.data];
        setTableMoreData(combinedArray);
        const limit_show_change = totalShowTable + data_len;
        setLimitShow(limit_show_change / data_len);
        const limitCombinedArray = [...new_data?.data, ...limitTableData];
        setLimitTableData(limitCombinedArray);
        const new_limit_data = new_data?.data?.slice(0, data_len);
        const newData = [...tableData, ...new_limit_data];
        tabOptionFiltered(newData);
        sessionStorage.setItem(
          "search_after_token",
          new_data?.search_after_token || ""
        );
        setTableMessage("");
      }
    });
  };

  const handleTableSelectedData = (value, id) => {
    const index = selectedData.indexOf(value);
    value["sid"] = `unique_id-${tablePage}${id}`;
    const newObj = value;
    if (index === -1) {
      setSelectedData([...selectedData, newObj]);
    } else {
      const updatedValues = [...selectedData];
      updatedValues.splice(index, 1);
      setSelectedData(updatedValues);
    }
  };

  const selectedFilterData = useMemo(() => {
    const array = filterDuplicates(selectedData);
    const arr = array?.map((item) => {
      return item.url_from;
    });
    return arr;
  }, [selectedData]);

  const isCheckboxChecked = (index) => {
    const id = `unique_id-${tablePage}${index}`;
    return selectedData.some((item) => item.sid === id);
  };

  // List Api
  const [Alert, setAlert] = useState({
    type: "",
    message: "",
    show: false,
  });

  const FetchLISTING = () => {
    axios.get(GET_ALLLIST_BACKLINKS(EMAIL)).then((Result) => {
      if (Result?.data) {
        setViewAllLists(Result?.data);
        setSelectedData([]);
        setSelectAll(false);
      }
    });
  };

  useEffect(() => {
    FetchLISTING();
  }, []);

  const PAYLOAD = {
    email: EMAIL,
    back_list_name: ListValue,
    backlink_list: selectedFilterData,
  };

  const handleUserAddList = () => {
    const config = {
      email: EMAIL,
      url: selectedFilterData,
    };

    axios.post(USER_BACKLINK_LIST_URLEML(), config);
    axios.post(USER_BACKLINK_LIST_URL(), {
      url: selectedFilterData,
    });
  };

  const HandleCreateBackLIST = () => {
    setalertMsg(false);
    if (ListValue) {
      const Output = ViewAllLists.map((result) => {
        return result.back_list_name === ListValue;
      });
      const ReturnData = Output.includes(true);
      if (ReturnData) {
        setalertMsg(true);
        setTimeout(() => {
          setalertMsg(false);
        }, 3000);
      } else {
        handleUserAddList();
        axios.post(POST_NEWLIST_BACKLINKS(), PAYLOAD).then((res) => {
          setAlert({
            type: "success",
            message: "Created Successfully",
            show: true,
          });
          setSelectedData([]);
          setListValue("");
          FetchLISTING();
          setAnchorEl(false);
          setCreateList(false);
          setinputEmptyErr(false);
        });
      }
    } else {
      setinputEmptyErr(true);
    }
  };

  const handleGetListData = (listData) => {
    setListChooseErr(false);
    setListName(listData?.back_list_name);
  };

  const handleAddBackLink = () => {
    let obj = {
      data: [],
    };
    ViewAllLists?.map((all) => {
      if (all.back_list_name === ListName) {
        const BackLink_list = all.backlink_list;
        const namesInArray1 = new Set(BackLink_list.map((item) => item));
        const array = selectedFilterData.filter(
          (item) => !namesInArray1.has(item)
        );
        obj.data.push(array);
      }
    });
    if (ListName) {
      axios.put(PUT_ADDBACKLINKS(EMAIL, ListName, ...obj.data)).then((res) => {
        setAlert({
          type: "success",
          message: "Added Successfully",
          show: true,
        });
        setListName("");
        setSelectedData([]);
        FetchLISTING();
        setAnchorEl(false);
        setCreateList(false);
      });
    } else {
      setListChooseErr(true);
    }
  };

  const HandleDeleteList = (LIST) => {
    setDeleteLoading(true);
    axios.delete(DELETE_LIST_BACKLINKS(EMAIL, LIST)).then((res) => {
      setAlert({
        type: "success",
        message: "Deleted",
        show: true,
      });
      FetchLISTING();
      setTimeout(() => {
        setDeleteLoading(false);
      }, 500);
    });
  };

  const AddColor = (id) => {
    const Elem = document.getElementsByClassName("ListName");
    const Target = document.getElementById(id);
    for (let index = 0; index < Elem.length; index++) {
      Elem[index].classList.remove("SHOWBg");
    }
    document.getElementById(id).classList.add("SHOWBg");
  };
  //  List End

  const selectAllData = () => {
    if (!selectAll) {
      setSelectedData(currentPageTableData);
    } else {
      setSelectedData([]);
    }
    setSelectAll(!selectAll);
  };

  const handleTabOption = (value) => {
    sessionStorage.setItem("backlinkTab", value);
    setSelectedTab(value);
  };

  const handleAnchorSortClick = (field) => {
    const data = anchorTable;
    setSortDirection((pre) => !pre);
    // Ascending Order
    const sortedByAsc = dynamicSort([...data], field, "asc");
    // Descending Order
    const sortedByDesc = dynamicSort([...data], field, "desc");
    setAnchorTable(sortDirection ? sortedByAsc : sortedByDesc);
  };

  const handleBacklinkSortClick = (field) => {
    const data = tableData;
    // Ascending Order
    const sortedByAsc = dynamicSort([...data], field, "asc");
    // Descending Order
    const sortedByDesc = dynamicSort([...data], field, "desc");
    setTableData(sortDirection ? sortedByAsc : sortedByDesc);
    setSortDirection((pre) => !pre);
  };

  const diffStyle = (val) => {
    if (val <= 30) {
      return {
        backgroundColor: "rgb(46, 108, 45)",
      };
    } else if (30 < val && val <= 60) {
      return {
        backgroundColor: "rgb(255, 142, 59)",
      };
    } else {
      return {
        backgroundColor: "rgb(233, 86, 85)",
      };
    }
  };

  // print backlinks pdf

  const targetBacklinksTable = useRef();
  const targetBacklinks = useRef();


  const handleRoute =()=>{
    const element = document.getElementById("backL");
   
    if (element) {
      html2canvas(element, {
        useCORS: true, // If your container has external images, this helps to prevent cross-origin issues.
        backgroundColor: null, // Set to null if you want a transparent background.
      }).then((canvas) => {
        // Convert the canvas to a data URL in JPG format
        const imgData = canvas.toDataURL("image/jpeg");
  
        // Save the image data to sessionStorage
        sessionStorage.setItem("dashboardImage", imgData);
      }).catch((error) => {
        console.error("Error capturing the dashboard as an image:", error);
      });
    }

    sessionStorage.setItem('pdfSessionData',JSON.stringify({Keyword:Keyword,id:12}))
    setTimeout(() => {
      navigate("/CrawledPageEditor");
    }, 500)
     
    }








  const GenerateBackLinksPDF = () => {
    const input = targetBacklinks.current;
    const pdf = new jsPDF("p", "mm", "a4");
    const margin = 10; // Set the margin size
    const pageWidth = pdf.internal.pageSize.getWidth();
    const pageHeight = pdf.internal.pageSize.getHeight();

    // Add cover page
    pdf.setFontSize(20);
    pdf.setFont("helvetica");
    pdf.text(
      "BackLinks Analysis",
      pageWidth / 2,
      pageHeight / 3 + 60,
      {
        align: "center",
      }
    );

    pdf.setFontSize(16);
    pdf.setFont("helvetica", "normal");
    pdf.setTextColor("gray");
    pdf.text(`Domain:${Keyword}`, pageWidth / 2, pageHeight / 3 + 70, {
      align: "center",
    });

    // Add your image to the cover page
    const imageURL = RanksProLogo; // Replace with your image URL or data URI
    const imageWidth = 74; // Adjust width as needed
    const imageHeight = 32; // Adjust height as needed
    pdf.addImage(
      imageURL,
      "PNG",
      (pageWidth - imageWidth) / 2,
      pageHeight / 3 - imageHeight + 60-5,
      imageWidth,
      imageHeight
    );

    pdf.addPage(); // Add a new page for the actual content

    // Capture and add the main content
    html2canvas(input, { scale: 2 }).then((canvas) => {
      const imgData = canvas.toDataURL("image/png");
      const imgWidth = pageWidth - 2 * margin;
      const imgHeight = (canvas.height * imgWidth) / canvas.width;

      let position = margin;

      // Add logo to the top-left corner of the second page
      pdf.addImage(RanksProLogo, "PNG", margin, margin, 40, 17); // Adjust size as needed

      pdf.addImage(imgData, "PNG", margin, position + 20, imgWidth, imgHeight);

      // Add additional pages if content exceeds one page
      while (position + imgHeight > pageHeight - margin) {
        position -= pageHeight - 2 * margin;
        pdf.addPage();
        pdf.addImage(RanksProLogo, "PNG", margin, margin, 30, 20); // Add logo on each new page
        pdf.addImage(
          imgData,
          "PNG",
          margin,
          position + 20,
          imgWidth,
          imgHeight
        );
      }

      pdf.save("BackLinks.pdf");
    });
  };

  return (
    <div>
      <AlertPopUp config={Alert} handleClose={setAlert} />
      <div className="w-100 main-pd" id="backlink_page">
        {limitAlert ? <LineAlert data={limitAlert} /> : null}
        {anchorList ? (
          <div className="">
            <div className="d-flex justify-content-between">
              <button
                className="icon-btn"
                onClick={() => handleAnchorListBack()}
              >
                <img src={backIcon} alt="backIcon" />
              </button>

              <FiDownload
                class="down"
                data-bs-toggle="modal"
                data-bs-target="#staticBackdrop"
                title="download 
                            long description pdf"
              />
              <div
                class="modal fade"
                id="staticBackdrop"
                data-bs-backdrop="static"
                data-bs-keyboard="false"
                tabindex="-1"
                aria-labelledby="staticBackdropLabel"
                aria-hidden="true"
              >
                <div class="modal-dialog">
                  <div class="modal-content">
                    <div class="modal-header" style={{
                                    zIndex:"100",
                                    borderBottom: "none",
    position: "absolute",
    right: 0,
    padding: "36px",
}}>
                      
                      <button
                        type="button"
                        class="btn-close"
                        data-bs-dismiss="modal"
                        aria-label="Close"
                      ></button>
                    </div>
                    <div class="modal-body">
                      <AnchorTextBacklinkPdfModal
                        anchorTable={anchorTable}
                        isURL={isURL}
                        formatNumberWithSymbols={formatNumberWithSymbols}
                        domainName={domainName}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="backlink-anchor-list mb-3" id="anchorlist">
              <div
                className="backlink-bar-chart"
                style={{ overflow: "hidden" }}
              >
                <div
                  className="custom-table-container"
                  style={{
                    borderBottomLeftRadius: "0px",
                    borderBottomRightRadius: "0px",
                  }}
                >
                  <table
                    className="table blacklink_table"
                    style={{ borderCollapse: "collapse" }}
                  >
                    <thead>
                      <tr>
                        <th
                          scope="col"
                          style={{
                            paddingLeft: "40px",
                            backgroundColor: "#dcdfe4 !important",
                          }}
                        >
                          <span
                            className="point"
                            onClick={() => handleAnchorSortClick("anchor")}
                          >
                            Anchor Text
                            <span className="ms-2">
                              <UnsortIcon />
                            </span>
                            <ToolTip
                              title="Anchor Text - The Anchor text is the
                                  visible, clickable text in a hyperlink."
                            />
                          </span>
                        </th>
                        <th
                          scope="col"
                          className="text-center"
                          style={{
                            backgroundColor: "#dcdfe4 !important",
                          }}
                        >
                          <span
                            className="point"
                            onClick={() => handleAnchorSortClick("backlinks")}
                          >
                            Backlinks
                            <span className="ms-2">
                              <UnsortIcon />
                            </span>
                            <ToolTip title="A hyperlink from one website to another, also known as an inbound, incoming, or external link" />
                          </span>
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      {anchorTable?.map((item, index) => {
                        return isURL(item?.anchor) ||
                          item?.anchor === null ? null : (
                          <tr key={`anchorlist-${index}`}>
                            <td className="t_left table-iem">
                              <div
                                className="table-cell-value ps-4"
                                style={{ justifyContent: "flex-start" }}
                              >
                                <span className="table-cell-value">
                                  {item?.anchor}
                                </span>
                              </div>
                            </td>
                            <td className=" table-iem">
                              <span className="table-cell-value">
                                {formatNumberWithSymbols(item?.backlinks)}
                              </span>
                            </td>
                          </tr>
                        );
                      })}
                    </tbody>
                  </table>
                </div>
                <div className="w-100 pb-4 d-flex mt-4 ms-4 blacklink-paggination">
                  {anchorLastPage === "1" ? null : (
                    <CustomPagination
                      page={anchorPage}
                      totalPage={anchorLastPage}
                      onPageChange={handleAnchorPaginationChange}
                    />
                  )}
                  {anchorTotalPages < anchorPage + 1
                    ? null
                    : anchorPage === anchorLastPage && (
                        <button
                          style={{ fontSize: "1em", borderRadius: "5px" }}
                          className={`btn_new_bg px-3 py-2 ms-4 `}
                          onClick={() => handleAnchorLoadMore(anchorPage + 1)}
                        >
                          Load more 25 results
                        </button>
                      )}
                </div>
              </div>
            </div>
          </div>
        ) : (
          <>
            <div className="keyword2 pb-5">
              <div className="w-100">
                <h5 className="">Backlinks</h5>
                <p className="keyword-p">
                  Evaluate the link profile of a site or page URL
                </p>
                <form
                  onSubmit={(e) => {
                    keywordClickHandler(e);
                  }}
                >
                  <div className="overview-search-contain d-flex justify-content-between">
                    <div className="d-flex align-items-center justify-content-start flex-wrap gap-2">
                    <input
                      className="keyword-input font-regular heading-small"
                      placeholder="Enter your domain"
                      value={Keyword || ""}
                      onChange={(e) => keywordChangeHandler(e.target)}
                    />

                    <Dropdown
                      data={domain_data}
                      title={Domain || "Domain"}
                      style={{
                        border: "1px solid #0000004D",
                        textTransform: "none",
                        boxShadow: "none",
                        padding: "1.1em 1em",
                        borderRadius: "5px",
                        color: "#000000",
                      }}
                      callback={domainHandler}
                    />

                    <div className="backlink-checkbox my-3 ">
                      <input
                        type="checkbox"
                        checked={ExcludeSubdomains}
                        onChange={(e) =>
                          setExcludeSubdomains(!ExcludeSubdomains)
                        }
                      />
                      <label
                        style={{ textWrap: "nowrap" }}
                        className="heading-small"
                      >
                        Exclude Subdomains
                      </label>
                    </div>

                    <button
                      className="icon-btn"
                      onClick={(e) => keywordClickHandler(e)}
                    >
                      <img src={searchArrow} alt="" />
                    </button>
                    </div>
                    {Data?.length !== 0 ?  <div className="d-flex justify-content-center align-item-center g-2">

                    <FiDownload
                      class="down"
                      style={{ display: selectedTab !== 1 ? "none" : "flex" }}
                      onClick={handleRoute}
                      title="download BackLinks Overview PDF"
                      />

                      </div>
                      
                      :null}
                  </div>
                  {Error ? (
                    <p className="vl-msd-line mt-1 ms-1">
                      <span>{Error}</span>
                    </p>
                  ) : null}
                </form>
              </div>
            </div>

            {loader ? (
              <DottLoader />
            ) : Data?.length === 0 ? null : (
              <>
                <section className="page-tab-container">
                  <nav className="page-tab-nav">
                    <ul>
                      <li
                        className={selectedTab === 1 ? "selected" : ""}
                        onClick={() => handleTabOption(1)}
                      >
                        Overview
                      </li>
                      <li
                        className={selectedTab === 2 ? "selected" : ""}
                        onClick={() => handleTabOption(2)}
                      >
                        Backlinks
                      </li>
                    </ul>
                  </nav>
                  <AnimatePresence mode="wait">
                    <motion.div
                      key={selectedTab ? selectedTab : "empty"}
                      initial={{ y: 10, opacity: 0 }}
                      animate={{ y: 0, opacity: 1 }}
                      exit={{ y: -10, opacity: 0 }}
                      transition={{ duration: 0.2 }}
                    >
                      {selectedTab === 1 ? (
                        <div className="row g-4 mb-4" ref={targetBacklinks} id="backL" style={{backgroundColor:"#fffbf5"}}>
                          <div className="col-12 col-lg-3 col-xl-2">
                            <div className="row h-100" style={{ gap: "25px" }}>
                              {cardValue?.map((card, index) => {
                                return (
                                  <div
                                    className="col-12 col-md-6 col-lg-12 card-overview-value"
                                    key={`backlink-card-${index}`}
                                  >
                                    <CardOverviewValue
                                      data={card}
                                      id={`backlinkBox${index}`}
                                    />
                                  </div>
                                );
                              })}
                            </div>
                          </div>
                          <div className="col-12 col-lg-9 col-xl-7">
                            <div className="backlink-line-chart">
                              <div className="backlink-line-chart-contain cmd p-3 pb-0">
                                <h1 className="mb-0 line-chart-heading">
                                  Backlinks Over Time
                                </h1>
                                <div className="d-flex">
                                  <div
                                    className="d-flex"
                                    style={{ alignItems: "center" }}
                                  >
                                    <span
                                      className="line-chart-new-reffer-domain"
                                      style={{ backgroundColor: "#5C749C" }}
                                    ></span>
                                    <label className="heading-xs font-light-bold">
                                      Backlinks
                                    </label>
                                  </div>
                                  <div
                                    className="d-flex"
                                    style={{
                                      marginLeft: "20px",
                                      alignItems: "center",
                                    }}
                                  >
                                    <span
                                      className="line-chart-old-backlinks"
                                      style={{ backgroundColor: "#B8616E" }}
                                    ></span>
                                    <label className="heading-xs font-light-bold">
                                      Referring Domains
                                    </label>
                                  </div>
                                </div>
                              </div>
                              <div className="overAuto w-100 app-scrollbar">
                                <div className="single-backlink-chart p-3">
                                  <SingleLineChart data={chart_data} />
                                </div>
                              </div>
                            </div>

                            <div className="backlink-line-chart mt-3">
                              <div className="backlink-line-chart-contain cmd p-3 mb-3 pb-0">
                                <h1 className="line-chart-heading mb-0">
                                  New & Lost Referring Domains
                                </h1>
                                <div className="d-flex">
                                  <div
                                    className="d-flex"
                                    style={{ alignItems: "center" }}
                                  >
                                    <span
                                      className="line-chart-new-reffer-domain"
                                      style={{ backgroundColor: "#365486" }}
                                    ></span>
                                    <label className="heading-xs font-light-bold">
                                      New
                                    </label>
                                  </div>
                                  <div
                                    className="d-flex"
                                    style={{
                                      marginLeft: "20px",
                                      alignItems: "center",
                                    }}
                                  >
                                    <span
                                      className="line-chart-old-backlinks"
                                      style={{ backgroundColor: "#E95655" }}
                                    ></span>
                                    <label className="heading-xs font-light-bold">
                                      Lost
                                    </label>
                                  </div>
                                </div>
                              </div>
                              <div className="overAuto w-100 app-scrollbar">
                                <div className="single-backlink-chart p-3 pt-0">
                                  <BarChart data={bar_stacked_chart_data} />
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="col-12 col-lg-12 col-xl-3">
                            <div
                              className="backlink-bar-chart"
                              style={{ overflow: "hidden" }}
                            >
                              <table
                                className="table blacklink_table anchor-table-lm"
                                id="backlink_showMore"
                                style={{
                                  marginBottom: "12px",
                                  tableLayout: "fixed",
                                }}
                              >
                                <thead>
                                  <tr>
                                    <th scope="col" className="w-50">
                                      <h1
                                        className="mb-0 py-2"
                                        style={{ paddingLeft: "30px" }}
                                      >
                                        Anchor Text
                                      </h1>
                                    </th>
                                    <th scope="col" className="w-50">
                                      <h1
                                        className="mb-0 py-2"
                                        style={{ paddingLeft: "30px" }}
                                      >
                                        Backlinks
                                      </h1>
                                    </th>
                                  </tr>
                                </thead>
                                <tbody>
                                  {anchor_list_data
                                    ?.slice(0, 11)
                                    ?.map((item, index) => {
                                      return isURL(item?.anchor) ||
                                        item?.anchor === null ? null : (
                                        <tr
                                          key={`anchorlist-${index}`}
                                          className="w-100"
                                        >
                                          <td className="t_left cell-start table-iem w-50">
                                            <div
                                              className="table-cell-value"
                                              style={{
                                                paddingLeft: "30px",
                                                width: "100%",
                                              }}
                                            >
                                              <span className="table-cell-value">
                                                {item?.anchor}
                                              </span>
                                            </div>
                                          </td>
                                          <td className="vert-alg table-iem w-50">
                                            <span
                                              className="table-cell-value"
                                              style={{ paddingLeft: "30px" }}
                                            >
                                              {formatNumberWithSymbols(
                                                item?.backlinks
                                              )}
                                            </span>
                                          </td>
                                        </tr>
                                      );
                                    })}
                                </tbody>
                              </table>
                              <div
                                className="w-100 cad justify-content-end pe-4"
                                style={{ marginBottom: "12px" }}
                              >
                                <button
                                  className="btn-sm"
                                  onClick={() => {
                                    ScrollToView("backlink_page");
                                    setAnchorList(true);
                                  }}
                                >
                                  View All
                                </button>
                              </div>
                            </div>
                          </div>
                        </div>
                      ) : selectedTab === 2 ? (
                        <section className="backlink-data-report">
                          {/* table */}
                          <div className="chceck">
                            <div className="mt-4">
                              <div className="row gy-3 table-btn-coll">
                                <div
                                  className="col-auto d-flex align-items-center backlink-filter ps-0"
                                  ref={filterRef}
                                >
                                  <button
                                    className="icon-btn large-btn"
                                    onClick={handleFilterToggle}
                                    title="Search"
                                  >
                                    <img src={filterIcon} alt="filter icon" />
                                  </button>

                                  {isFilterOpen && (
                                    <AnimatePresence>
                                      <motion.div {...framerFilterEffect}>
                                        <div className="d-flex">
                                          <Dropdown
                                            data={select_data}
                                            title={"Domain"}
                                            searchType="true"
                                            style={{
                                              width: "95px",
                                              borderRadius: "5px",
                                              padding: ".5rem",
                                              marginLeft: "20px",
                                              fontSize: "1em",
                                              color: "#000000",
                                              border: "none",
                                              boxShadow: "none",
                                              textTransform: "none",
                                              background: "transparent",
                                              fontFamily: "Work Sans",
                                              fontWeight: "400",
                                            }}
                                            callback={selectDataHandler}
                                          />
                                          <Dropdown
                                            data={select_filter_data}
                                            searchType="true"
                                            title="Follow Status"
                                            style={{
                                              width: "135px",
                                              textTransform: "none",
                                              boxShadow: "none",
                                              border: "none",
                                              fontSize: "1em",
                                              fontFamily: "Work Sans",
                                              fontWeight: "400",
                                              background: "transparent",
                                              padding: ".5rem",
                                              marginLeft: "20px",
                                              color: "#000000",
                                            }}
                                            callback={handleChangeTab}
                                          />
                                        </div>
                                      </motion.div>
                                    </AnimatePresence>
                                  )}
                                </div>
                                <div className="col-auto d-flex align-items-center">
                                  <p className="mb-0 ttl-bklnk">
                                    Total Backlinks
                                    <Tooltip
                                      title={
                                        "Total Backlinks shown / Total Backlinks of Domain"
                                      }
                                    ></Tooltip>{" "}
                                    :{" "}
                                    <span>
                                      {tableData?.length}
                                      {""}/{""}
                                      {formatNumberWithSymbols(total_backlinks)}
                                    </span>
                                  </p>
                                </div>
                                <div className="col">
                                  <div
                                    className="d-flex align-items-center justify-content-end"
                                    style={{ width: "100%", gap: "10px" }}
                                  >
                                    {selectedFilterData.length > 0 && (
                                      <div>
                                        <button
                                          className="icon-btn large-btn"
                                          onClick={HandleOpen}
                                          title="Add domains to List"
                                        >
                                          <img src={addIcon} alt="" />
                                        </button>
                                      </div>
                                    )}

                                    {isOpen ? (
                                      <AnimatePresence>
                                        <motion.div
                                          {...framerInputEffect}
                                          className="search-box"
                                          ref={searchRef}
                                        >
                                          <div className="backlink-search-filed">
                                            <i className="fa-solid fa-magnifying-glass icon"></i>
                                            <input
                                              className="input-field heading-small"
                                              type="text"
                                              placeholder="Search here"
                                              value={searchQuery || ""}
                                              onChange={(e) =>
                                                handleSearchChange(
                                                  e.target.value
                                                )
                                              }
                                            />
                                          </div>
                                        </motion.div>
                                      </AnimatePresence>
                                    ) : (
                                      <button
                                        className="icon-btn large-btn"
                                        ref={searchRef}
                                        onClick={handleToggle}
                                      >
                                        <img
                                          src={searchIcon}
                                          alt="searchIcon"
                                        />
                                      </button>
                                    )}
                                    <div>
                                      <CSVLink
                                        style={{
                                          textDecoration: "none",
                                          minWidth: "200px",
                                          color: "red",
                                        }}
                                        filename={
                                          curday() + " " + Keyword + ".csv"
                                        }
                                        headers={headers}
                                        data={detailsCSV ? detailsCSV : "null"}
                                      >
                                        <button
                                          className="icon-btn large-btn"
                                          title="Export File"
                                          disabled={
                                            currentPageTableData?.length === 0
                                          }
                                        >
                                          <img src={exportIcon} alt="" />
                                        </button>
                                      </CSVLink>
                                    </div>

                                    <div>
                                      <Link to={`../${ViewAllListBackLink}`}>
                                        <button
                                          className="icon-btn large-btn view-icon"
                                          onClick={HandleOpen}
                                          title="View Domain List"
                                        >
                                          <CiCircleList />
                                        </button>
                                      </Link>
                                    </div>

                                    <FiDownload
                                      class="down"
                                      data-bs-toggle="modal"
                                      data-bs-target="#staticBackdrop"
                                      title="download 
                            long description pdf"
                                    />
                                    <div
                                      class="modal fade"
                                      id="staticBackdrop"
                                      data-bs-backdrop="static"
                                      data-bs-keyboard="false"
                                      tabindex="-1"
                                      aria-labelledby="staticBackdropLabel"
                                      aria-hidden="true"
                                    >
                                      <div class="modal-dialog">
                                        <div class="modal-content">
                                          <div class="modal-header" style={{
                                    zIndex:"100",
                                    borderBottom: "none",
    position: "absolute",
    right: 0,
    padding: "36px",
}}>
                                            
                                            <button
                                              type="button"
                                              class="btn-close"
                                              data-bs-dismiss="modal"
                                              aria-label="Close"
                                            ></button>
                                          </div>
                                          <div class="modal-body">
                                            <BackLinksPdfModal
                                              currentPageTableData={
                                                currentPageTableData
                                              }
                                              targetBacklinksTable={
                                                targetBacklinksTable
                                              }
                                              isFilterOpen={isFilterOpen}
                                              diffStyle={diffStyle}
                                              domainName={domainName}
                                            />
                                          </div>
                                        </div>
                                      </div>
                                    </div>

                                    <div></div>
                                  </div>
                                </div>
                              </div>

                              <div className="mt-3" id="backlink-table">
                                <div className="mt-3 custom-table-container">
                                  <table
                                    className="backlink-table"
                                    ref={targetBacklinksTable}
                                  >
                                    <thead>
                                      <tr
                                        className="table-sticky"
                                        style={{
                                          position: isFilterOpen
                                            ? "static"
                                            : "sticky",
                                        }}
                                      >
                                        <th
                                          scope="col"
                                          className="check-box-input"
                                          onClick={() => selectAllData()}
                                        >
                                          <input
                                            type="checkbox"
                                            checked={selectAll}
                                            readOnly
                                          />
                                        </th>

                                        <th
                                          scope="col"
                                          style={{ width: "120px" }}
                                        >
                                          <span
                                            className="point"
                                            onClick={() =>
                                              handleBacklinkSortClick(
                                                "domain_authority"
                                              )
                                            }
                                          >
                                            DR
                                            <span className="ms-2">
                                              <UnsortIcon />
                                            </span>
                                          </span>
                                          <ToolTip title="Domain Rank: A metric that denotes the domain rank, referring to the target page or domain, calculated by summing each page’s rank value, narrowed down to a range on the scale. 0 range means new domain with limited backlinks, 5-50 range means older domains with higher quality backlinks, 50+ scale means huge domains with significant authority backlinks." />
                                        </th>
                                        <th scope="col" className="t_left ">
                                          Source page title & url | target page
                                          <ToolTip
                                            title="SEO Title / URL - The title that shows up in
                                a Google search for any given URL, followed
                                by the page URL itself."
                                          />
                                        </th>

                                        <th
                                          scope="col"
                                          style={{ width: "120px" }}
                                        >
                                          <span
                                            className="point"
                                            onClick={() =>
                                              handleBacklinkSortClick(
                                                "page_authority"
                                              )
                                            }
                                          >
                                            PR
                                            <span className="ms-2">
                                              <UnsortIcon />
                                            </span>
                                          </span>
                                          <ToolTip title="Page Rank: Rank of a page referring to the specified domain or target page. It is calculated based on number of inbound linking pages, their PageRank, number of outbound links from these pages, and damping factor – determined by user bahavior navigating the web clicking on these links." />
                                        </th>
                                        <th
                                          scope="col"
                                          style={{ width: "120px" }}
                                        >
                                          <span
                                            className="point"
                                            onClick={() =>
                                              handleBacklinkSortClick(
                                                "backlink_spam_score"
                                              )
                                            }
                                          >
                                            ss
                                            <span className="ms-2">
                                              <UnsortIcon />
                                            </span>
                                          </span>
                                          <ToolTip title="Spam Score: Metric that designates the spam level of a page, pages or an entire domain, with corresponding percentage score from 0% to 100% (higher percentage means higher spam probability). It is impacted by 18 signals (in positive and negative way), including domain name length, site safety protocol, contact info on page, internal and external links, and many more." />
                                        </th>
                                        <th
                                          scope="col"
                                          style={{ width: "230px" }}
                                        >
                                          anchor text
                                          <ToolTip
                                            title="Anchor Text - The Anchor text is the
                                  visible, clickable text in a hyperlink."
                                          />
                                        </th>
                                        <th
                                          scope="col"
                                          style={{ width: "170px" }}
                                        >
                                          <span
                                            className="point"
                                            onClick={() =>
                                              handleBacklinkSortClick(
                                                "first_seen"
                                              )
                                            }
                                          >
                                            first seen
                                            <span className="ms-2">
                                              <UnsortIcon />
                                            </span>
                                          </span>
                                          <ToolTip
                                            title=" First Seen - The first time when our robots
                                  were able to identify this backlink."
                                          />
                                        </th>
                                        <th
                                          scope="col"
                                          style={{ width: "170px" }}
                                        >
                                          <span
                                            className="point"
                                            onClick={() =>
                                              handleBacklinkSortClick(
                                                "last_seen"
                                              )
                                            }
                                          >
                                            last seen
                                            <span className="ms-2">
                                              <UnsortIcon />
                                            </span>
                                          </span>
                                          <ToolTip
                                            title="Last Seen - The last time our robots were
                                  able to confirm the existence of this
                                  backlink."
                                          />
                                        </th>
                                      </tr>
                                    </thead>
                                    <tbody>
                                      {tableLoader ? (
                                        <tr>
                                          <td
                                            className="text-center"
                                            colSpan={8}
                                          >
                                            <DottLoader1 />
                                          </td>
                                        </tr>
                                      ) : currentPageTableData?.length === 0 ? (
                                        <tr>
                                          <td
                                            className="text-center"
                                            colSpan={8}
                                          >
                                            {searchQuery?.length === 0
                                              ? "Don't have any data !"
                                              : "No search result found"}
                                          </td>
                                        </tr>
                                      ) : (
                                        currentPageTableData &&
                                        currentPageTableData?.map(
                                          (item, index) => {
                                            return (
                                              <tr
                                                key={`blacklink-table-list-${index}-${item?.page_from_title}`}
                                              >
                                                <td
                                                  onClick={() =>
                                                    handleTableSelectedData(
                                                      item,
                                                      index
                                                    )
                                                  }
                                                  className="check-box-input"
                                                >
                                                  <input
                                                    type="checkbox"
                                                    checked={isCheckboxChecked(
                                                      index
                                                    )}
                                                    readOnly
                                                  />
                                                </td>

                                                <td>
                                                  {Math.round(
                                                    item?.domain_authority / 10
                                                  ) < 5
                                                    ? 0
                                                    : Math.round(
                                                        item?.domain_authority /
                                                          10
                                                      ) || 0}
                                                </td>

                                                <td className="t_left table_cell">
                                                  <div
                                                    style={{
                                                      display: "flex",
                                                      flexDirection: "column",
                                                      height: "90px",
                                                    }}
                                                    className="ellipsis justify-content-center"
                                                  >
                                                    <span className="blacklink-table-span">
                                                      <a
                                                        href={item?.url_from}
                                                        className="w-100"
                                                        target="_blank"
                                                        style={{
                                                          color: "#000000E5",
                                                          paddingLeft: "0px",
                                                        }}
                                                        rel="noopener noreferrer"
                                                      >
                                                        {item?.page_from_title}
                                                      </a>
                                                    </span>
                                                    <span className="blacklink-table-span">
                                                      <span className="backlink-table-head mt-0">
                                                        Source:
                                                      </span>
                                                      <a
                                                        href={item?.url_from}
                                                        target="_blank"
                                                        className=""
                                                        rel="noopener noreferrer"
                                                      >
                                                        <span>
                                                          {item?.url_from}
                                                        </span>
                                                        <OpenInNewIcon
                                                          sx={{
                                                            fontSize: "13px",
                                                            marginLeft: "6px",
                                                          }}
                                                        />
                                                      </a>
                                                    </span>

                                                    <span className="blacklink-table-span">
                                                      <span className="backlink-table-head mt-0">
                                                        Target:
                                                      </span>
                                                      <a
                                                        href={item?.url_to}
                                                        target="_blank"
                                                        className=""
                                                        rel="noopener noreferrer"
                                                      >
                                                        <span>
                                                          {item?.url_to}
                                                        </span>
                                                        <OpenInNewIcon
                                                          sx={{
                                                            fontSize: "13px",
                                                            marginLeft: "6px",
                                                          }}
                                                        />
                                                      </a>
                                                    </span>
                                                  </div>
                                                </td>

                                                <td>
                                                  {Math.round(
                                                    item?.page_authority / 10
                                                  ) < 5
                                                    ? 0
                                                    : Math.round(
                                                        item?.page_authority /
                                                          10
                                                      ) || 0}
                                                </td>
                                                <td className="table_cell vert-alg ">
                                                  <span
                                                    className="spamscores-table"
                                                    style={diffStyle(
                                                      item?.backlink_spam_score
                                                    )}
                                                  >
                                                    {`${item?.backlink_spam_score}%`}
                                                  </span>
                                                </td>
                                                <td className="table_cell vert-alg ">
                                                  <div className="d-flex flex-column">
                                                    <span
                                                      className="table-cell-value "
                                                      style={{
                                                        width: "fit-content",
                                                      }}
                                                    >
                                                      {item?.anchor}
                                                    </span>
                                                    <span
                                                      className={
                                                        item.dofollow
                                                          ? "follow-btn py-1 px-3"
                                                          : "do-follow-btn py-1 px-3"
                                                      }
                                                    >
                                                      {item.dofollow
                                                        ? "Follow"
                                                        : "No Follow"}
                                                    </span>
                                                  </div>
                                                </td>
                                                <td>
                                                  {item?.first_seen.split(
                                                    " ",
                                                    1
                                                  )}
                                                </td>
                                                <td>
                                                  {item?.last_seen.split(
                                                    " ",
                                                    1
                                                  )}
                                                </td>
                                              </tr>
                                            );
                                          }
                                        )
                                      )}
                                    </tbody>
                                  </table>
                                </div>

                                <div className="d-flex mt-3 blacklink-paggination">
                                  <CustomPagination
                                    page={tablePage}
                                    totalPage={totalPages}
                                    onPageChange={handlePaginationChange}
                                  />

                                  {tablePage === totalPages &&
                                  searchQuery?.length === 0 ? (
                                    <button
                                      className={`cm-btn_2 ms-4 `}
                                      onClick={() => handleTableLoadMore()}
                                      style={{ fontSize: "1em !important" }}
                                    >
                                      Load more results
                                    </button>
                                  ) : null}
                                  {tableMessage ? (
                                    <p className="vl-msd-line mt-1 ms-4 mb-0 ">
                                      {tableMessage}
                                    </p>
                                  ) : null}
                                </div>
                              </div>
                            </div>
                          </div>
                        </section>
                      ) : null}
                    </motion.div>
                  </AnimatePresence>
                </section>
              </>
            )}
          </>
        )}
      </div>
      {/* <UnderConstruction /> */}
      <PopOver anchorEl={anchorEl} setAnchorEl={() => setAnchorEl(false)}>
        {CreateList ? (
          <>
            <Box sx={{ width: "100%" }} padding={"1rem"}>
              <Typography
                component="h1"
                id="select-google-property"
                className="heading-large font-bold popover-list-header py-2 px-2"
              >
                Create Backlink List
                <IconButton
                  className="popover-close-icon"
                  onClick={handleClose}
                  sx={{
                    marginRight: "10px",
                    marginTop: "13px",
                    "&:hover": {
                      backgroundColor: "rgba(240, 242, 255, 1)",
                    },
                  }}
                >
                  <CloseIcon sx={{ color: "black" }} fontSize="medium" />
                </IconButton>
              </Typography>
              <Grid
                padding={"0"}
                container
                spacing={2}
                columns={12}
                className="mb-2"
                sx={{ maxWidth: { md: 500 }, minWidth: { md: 500 } }}
              >
                <div className="row w-100">
                  <div className="col-md-12 col-12 col-lg-12">
                    <div className="AddListBox p-0">
                      <div
                        className="add-pr-url"
                        style={{ padding: "1rem 0rem 1rem 1.5rem" }}
                      >
                        <input
                          type="text"
                          autoComplete="true"
                          placeholder="BACKLINK LIST NAME"
                          onKeyUp={(e) => {
                            if (e.keyCode === 13) {
                              HandleCreateBackLIST();
                            }
                          }}
                          onChange={(e) => {
                            setListValue(e.target.value);
                            setinputEmptyErr(false);
                          }}
                        ></input>
                        {alertMsg && (
                          <h6
                            style={{
                              marginTop: "10px",
                              textAlign: "left",
                              fontSize: "12px",
                              color: "red",
                              width: "100%",
                            }}
                          >
                            {" "}
                            list name already exist
                          </h6>
                        )}

                        {inputEmptyErr && (
                          <h6
                            style={{
                              marginTop: "10px",
                              textAlign: "left",
                              fontSize: "12px",
                              color: "red",
                              width: "100%",
                            }}
                          >
                            {" "}
                            Please Type Something .
                          </h6>
                        )}

                        <div className="cmd mt-4  w-100">
                          <button
                            className="cm-btn-b"
                            onClick={() => {
                              handleClose();
                            }}
                          >
                            Cancel
                          </button>
                          <button
                            className="cm-btn"
                            onClick={HandleCreateBackLIST}
                          >
                            Submit
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </Grid>
            </Box>
          </>
        ) : (
          <Box
            sx={{
              bgcolor: "rgba(255, 255, 255, 1)",
            }}
            className="popover-cont"
          >
            {/* {data?.length > 0 ? ( */}
            <Box sx={{ width: "100%" }}>
              <Typography
                component="h1"
                fontWeight={400}
                id="select-google-property"
                className="heading-large font-bold popover-list-header py-2 px-2"
              >
                Select List
                <IconButton
                  className="popover-close-icon"
                  onClick={handleClose}
                  sx={{
                    marginRight: "5px",
                    marginTop: "8px",
                    "&:hover": {
                      backgroundColor: "rgba(240, 242, 255, 1)",
                    },
                  }}
                >
                  <CloseIcon sx={{ color: "black" }} fontSize="mediun" />
                </IconButton>
              </Typography>
              <Grid
                container
                spacing={2}
                columns={12}
                className="mb-2"
                sx={{
                  maxWidth: { md: 500 },
                  minWidth: { md: 500 },
                  marginLeft: "-7px",
                  overflow: "hidden",
                }}
              >
                {ViewAllLists?.length > 0 ? (
                  <>
                    <ul className="listOfName w-100 mt-3 mx-3">
                      {DeleteLoading ? (
                        <div>
                          <div
                            className="d-flex justify-content-center align-items-center "
                            style={{ height: "35vh" }}
                          >
                            <div className="spinner-border" role="status">
                              <span className="visually-hidden"></span>
                            </div>
                          </div>
                        </div>
                      ) : (
                        ViewAllLists?.map((listItem, index) => {
                          return (
                            <li
                              className="ListName d-flex justify-content-between"
                              id={index}
                              key={index}
                              onClick={() => {
                                AddColor(index);
                                handleGetListData(listItem);
                              }}
                            >
                              <p className="m-0 p-1">
                                {" "}
                                {listItem.back_list_name}
                              </p>
                              <Delete
                                style={{ cursor: "pointer" }}
                                onClick={() =>
                                  HandleDeleteList(listItem.back_list_name)
                                }
                              />
                            </li>
                          );
                        })
                      )}
                    </ul>
                    <h4 className="fs-6 mt-2  text-center w-100">
                      <b>OR</b>
                    </h4>
                  </>
                ) : (
                  <></>
                )}
                <div
                  style={{
                    border:
                      !ViewAllLists.length > 0 ? "1px solid #a4a9af9c" : "none",
                    height: !ViewAllLists.length > 0 ? "11vh" : "auto",
                    width: !ViewAllLists.length > 0 ? "95%" : "auto",
                    display: !ViewAllLists.length > 0 ? "flex" : "auto",
                    justifyContent:
                      !ViewAllLists.length > 0 ? "center" : "auto",
                    alignItems: !ViewAllLists.length > 0 ? "center" : "auto",
                    margin:
                      !ViewAllLists.length > 0
                        ? "20px auto 10px auto"
                        : "8px auto 0px auto",
                  }}
                >
                  <button
                    className="AddListName mb-1"
                    onClick={HandleOpenAddList}
                  >
                    Create List
                  </button>
                </div>
                <div className="errorList m-0 w-100">
                  {ListChooseErr && (
                    <h6
                      style={{
                        marginTop: "10px",
                        marginLeft: "10px",
                        textAlign: "left",
                        fontSize: "12px",
                        color: "red",
                      }}
                    >
                      {" "}
                      Please Select One List.
                    </h6>
                  )}
                  {/* {ListLimitError && (
                      <h6
                        style={{
                          marginTop: "10px",
                          textAlign: "left",
                          fontSize: "12px",
                          color: "red",
                        }}
                      >
                        {" "}
                        Your Plan Limit has been exceed .Please Upgrade Your Plan.
                      </h6>
                    )} */}
                </div>
                <div className="cmd mt-2 ms-2 me-2 w-100 px-2">
                  <button
                    className="cm-btn-b"
                    onClick={() => {
                      handleClose();
                    }}
                  >
                    Cancel
                  </button>
                  <button className="cm-btn" onClick={handleAddBackLink}>
                    Submit
                  </button>
                </div>
              </Grid>
            </Box>
            {/* ) : (
            "Loading..."
            // <Sppiner />
          )} */}
          </Box>
        )}
      </PopOver>
    </div>
  );
};

export default BackLinks;
