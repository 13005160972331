import React from "react";
import SideBar from "./share/sideBar";
import "./css/admin.css";
import "./css/all-style.scss";
import TopBar from "./share/topBar";
import { Outlet } from "react-router-dom";
import Login from "../Mycomponent/pages/login/login";
import { useDispatch } from "react-redux";
import MANAGEDATA_CONFIGURE from "../confrigration/constants";

const Admin = () => {
  const dispatch = useDispatch();
  dispatch({ type: "SHOWMENU", payload: false });
  const isAdmin = localStorage.getItem("isAdmin");
  const user_type = localStorage.getItem("user_type");

  return (
    <>
      {isAdmin === "true" && user_type === "individual" ? (
        <div className="d-flex flex-column" style={{ height: "100vh" }}>
          <div className="admin-body d-flex w-100">
            <SideBar type="admin" />
            <div className="w-100" style={{ overflow: "auto" }}>
              <TopBar />
              <div
                className="admin-ground"
                onClick={() => {
                  dispatch({ type: "SHOWMENU", payload: false });
                }}
              >
                <Outlet />
              </div>
            </div>
          </div>
        </div>
      ) : (
        <Login />
      )}
      <MANAGEDATA_CONFIGURE admin={true} />
    </>
  );
};

export default Admin;
