import LogimImg from "../Mycomponent/Assets/login.jpg";
import noPage from "../Mycomponent/Assets/seoimg/nopage.png";
import backlinkImg from "../Mycomponent/Assets/backlink/backlink-tool.png";
import seoImpactImg from "../Mycomponent/Assets/seo-impact/seo-insights-tool.png";
import rankChekerImg from "../Mycomponent/Assets/rank-tranking/19196970-removebg-preview.png";
import siteOverviewImg from "../Mycomponent/Assets/keyword-subfiles/7591474-removebg.png";
import homePageImg from "./Assets/home-og-image.png";
import rankTrackingImg from "./Assets/rank-tracking-og-image.png";
import siteAuditImg from "./Assets/site-audit-og-image.png";
import keywordImg from "./Assets/keyword-og-image.png";
import domainOverviewImg from "./Assets/site-overview-og-image.png";
import backlinksImg from "./Assets/backlink-og-image.png";
import upgradeImg from "./Assets/upgrade-og-image.png";
import accountImg from "./Assets/account-og-image.png";

/* <title>Free SEO Tool - Free SEO Rank Tracking Tool India - RanksPro.io</title>
  <meta name="description" content="RanksPro.io is the top-notch SEO Rank-Tracking and Backlink Analysis tool in India. Monitor keyword rankings, analyze competition, and drive organic traffic. Get started for Free!"/>
     <meta
      property="og:title"
      content="RanksPro.io: Rank Tracking & SEO Analysis"
    />
    <meta
      property="og:description"
      content="Unlock the true potential of your website with RanksPro.io - the most advanced SEO rank tracking and analysis tool. Boost your search rankings, outsmart competitors, and drive organic traffic like never before."
    />
  <meta property="og:image" content="https://www.RanksPro.io.com/wp-content/uploads/2023/09/RanksPro.io-Ranks-Pro-Main-Banner-1-1024x453.png" />
  <meta property="og:image:secure_url" content="https://www.RanksPro.io.com/wp-content/uploads/2023/09/RanksPro.io-Ranks-Pro-Main-Banner-1-1024x453.png" /> */

const defaultTitle = "RanksPro.io: Rank Tracking & SEO Analysis";
const defaultDescription =
  "Unlock the true potential of your website with RanksPro.io - the most advanced SEO rank tracking and analysis tool. Boost your search rankings, outsmart competitors, and drive organic traffic like never before.";

const seoMetaData = {
  page_not_found: {
    title: "404 - Page not found",
    meta: [
      {
        attribute: "name",
        value: "description",
        content: defaultDescription,
      },
      {
        attribute: "property",
        value: "og:image",
        content: noPage,
      },
      {
        attribute: "property",
        value: "og:image:secure_url",
        content: noPage,
      },
      {
        attribute: "property",
        value: "og:title",
        content: "404 - Page not found",
      },
      {
        attribute: "property",
        value: "og:description",
        content: defaultDescription,
      },
    ],
  },
  login: {
    title: "RanksPro.io for Powerful Rank Tracking and SEO Analysis ",
    meta: [
      {
        attribute: "name",
        value: "description",
        content:
          "Seamlessly log in to RanksPro.io, your go-to platform for robust rank tracking and SEO analysis. Stay ahead with user-friendly tools for optimal performance",
      },
      {
        attribute: "property",
        value: "og:image",
        content: LogimImg,
      },
      {
        attribute: "property",
        value: "og:image:secure_url",
        content: LogimImg,
      },
      {
        attribute: "property",
        value: "og:title",
        content: "RanksPro.io for Powerful Rank Tracking and SEO Analysis ",
      },
      {
        attribute: "property",
        value: "og:description",
        content:
          "Seamlessly log in to RanksPro.io, your go-to platform for robust rank tracking and SEO analysis. Stay ahead with user-friendly tools for optimal performance",
      },
    ],
  },
  register: {
    title: "Register with RanksPro.io: Key of Rank Tracking & SEO Analysis",
    meta: [
      {
        attribute: "name",
        value: "description",
        content:
          "Ultimate rank tracking and SEO analysis tool. Elevate your website's performance, outsmart competitors, and lead search results. Register now for SEO success ",
      },
      {
        attribute: "property",
        value: "og:image",
        content: LogimImg,
      },
      {
        attribute: "property",
        value: "og:image:secure_url",
        content: LogimImg,
      },
      {
        attribute: "property",
        value: "og:title",
        content:
          "Register with RanksPro.io: Key of Rank Tracking & SEO Analysis",
      },
      {
        attribute: "property",
        value: "og:description",
        content:
          "Ultimate rank tracking and SEO analysis tool. Elevate your website's performance, outsmart competitors, and lead search results. Register now for SEO success ",
      },
    ],
  },
  free_site_overview: {
    meta: [
      {
        attribute: "name",
        value: "description",
        content: defaultDescription,
      },
      {
        attribute: "property",
        value: "og:image",
        content: siteOverviewImg,
      },
      {
        attribute: "property",
        value: "og:image:secure_url",
        content: siteOverviewImg,
      },
      {
        attribute: "property",
        value: "og:title",
        content: defaultTitle,
      },
      {
        attribute: "property",
        value: "og:description",
        content: defaultDescription,
      },
    ],
  },
  backlink_checker: {
    title:
      "Free Backlink Checker Tool - Backlink Analysis Tool | RanksPro.io",
    meta: [
      {
        attribute: "name",
        value: "description",
        content:
          "Check backlinks for Free with RanksPro.io's Free Backlink Checker Tool. Check any Website's Backlink Profile just in seconds.",
      },
      {
        attribute: "property",
        value: "og:image",
        content: backlinkImg,
      },
      {
        attribute: "property",
        value: "og:image:secure_url",
        content: backlinkImg,
      },
      {
        attribute: "property",
        value: "og:title",
        content:
          "Free Backlink Checker Tool - Backlink Analysis Tool | RanksPro.io",
      },
      {
        attribute: "property",
        value: "og:description",
        content:
          "Check backlinks for Free with RanksPro.io's Free Backlink Checker Tool. Check any Website's Backlink Profile just in seconds.",
      },
    ],
  },
  seo_insight: {
    title:
      "Free SEO Insights Checker Tool - Check Content Score and Sentiments | RanksPro.io",
    meta: [
      {
        attribute: "name",
        value: "description",
        content:
          "Free forever SEO Tool - Check SEO Insights of any Webpage quickly along with the Meta tags, Keyword repetition, content sentiments, Content readability score for free.",
      },
      {
        attribute: "property",
        value: "og:image",
        content: seoImpactImg,
      },
      {
        attribute: "property",
        value: "og:image:secure_url",
        content: seoImpactImg,
      },
      {
        attribute: "property",
        value: "og:title",
        content:
          "Free SEO Insights Checker Tool - Check Content Score and Sentiments | RanksPro.io",
      },
      {
        attribute: "property",
        value: "og:description",
        content:
          "Free forever SEO Tool - Check SEO Insights of any Webpage quickly along with the Meta tags, Keyword repetition, content sentiments, Content readability score for free.",
      },
    ],
  },
  rank_checker: {
    title:
      "Free Keyword Rank Checker Tool - Google Rank Checker Tool | RanksPro.io",
    meta: [
      {
        attribute: "name",
        value: "description",
        content:
          "Access Our Free Keyword Rank Checker Tool - Instant Google Rank Checker. Check your keyword ranking in 200+ countries for Free. ",
      },
      {
        attribute: "property",
        value: "og:image",
        content: rankChekerImg,
      },
      {
        attribute: "property",
        value: "og:image:secure_url",
        content: rankChekerImg,
      },
      {
        attribute: "property",
        value: "og:title",
        content:
          "Free Keyword Rank Checker Tool - Google Rank Checker Tool | RanksPro.io",
      },
      {
        attribute: "property",
        value: "og:description",
        content:
          "Access Our Free Keyword Rank Checker Tool - Instant Google Rank Checker. Check your keyword ranking in 200+ countries for Free. ",
      },
    ],
  },
  ai_caption: {
    title:
      "Free Instagram Caption Generator - Instagram Captions Instantly | RanksPro.io",
    meta: [
      {
        attribute: "name",
        value: "description",
        content:
          "RanksPro.io's AI based 100% Free Instagram Caption Generator. Generate creative captions for your Instagram posts in seconds for Free. No account signup/login required!",
      },
      {
        attribute: "property",
        value: "og:image",
        content: "",
      },
      {
        attribute: "property",
        value: "og:image:secure_url",
        content: "",
      },
      {
        attribute: "property",
        value: "og:title",
        content:
          "Free Instagram Caption Generator - Instagram Captions Instantly | RanksPro.io",
      },
      {
        attribute: "property",
        value: "og:description",
        content:
          "RanksPro.io's AI based 100% Free Instagram Caption Generator. Generate creative captions for your Instagram posts in seconds for Free. No account signup/login required!",
      },
    ],
  },
  rank_tracking: {
    meta: [
      {
        attribute: "property",
        value: "og:image",
        content: rankTrackingImg,
      },
      {
        attribute: "property",
        value: "og:image:secure_url",
        content: rankTrackingImg,
      },
    ],
  },
  site_audit: {
    meta: [
      {
        attribute: "property",
        value: "og:image",
        content: siteAuditImg,
      },
      {
        attribute: "property",
        value: "og:image:secure_url",
        content: siteAuditImg,
      },
    ],
  },
  home: {
    meta: [
      {
        attribute: "property",
        value: "og:image",
        content: homePageImg,
      },
      {
        attribute: "property",
        value: "og:image:secure_url",
        content: homePageImg,
      },
    ],
  },
  keyword: {
    meta: [
      {
        attribute: "property",
        value: "og:image",
        content: keywordImg,
      },
      {
        attribute: "property",
        value: "og:image:secure_url",
        content: keywordImg,
      },
    ],
  },
  backlink: {
    meta: [
      {
        attribute: "property",
        value: "og:image",
        content: backlinksImg,
      },
      {
        attribute: "property",
        value: "og:image:secure_url",
        content: backlinksImg,
      },
    ],
  },
  site_overview: {
    meta: [
      {
        attribute: "property",
        value: "og:image",
        content: domainOverviewImg,
      },
      {
        attribute: "property",
        value: "og:image:secure_url",
        content: domainOverviewImg,
      },
    ],
  },
  account: {
    meta: [
      {
        attribute: "property",
        value: "og:image",
        content: accountImg,
      },
      {
        attribute: "property",
        value: "og:image:secure_url",
        content: accountImg,
      },
    ],
  },
  upgrade: {
    title: "Upgrade Your RanksPro.io Membership Today",
    meta: [
      {
        attribute: "name",
        value: "description",
        content:
          "Get full access and extra benefits with an upgrade. Elevate your SEO game and enjoy exclusive perks. Upgrade now for a boosted experience.",
      },
      {
        attribute: "property",
        value: "og:title",
        content: "Upgrade Your RanksPro.io Membership Today",
      },
      {
        attribute: "property",
        value: "og:description",
        content:
          "Get full access and extra benefits with an upgrade. Elevate your SEO game and enjoy exclusive perks. Upgrade now for a boosted experience.",
      },
      {
        attribute: "property",
        value: "og:image",
        content: upgradeImg,
      },
      {
        attribute: "property",
        value: "og:image:secure_url",
        content: upgradeImg,
      },
    ],
  },
  term_and_condition: {
    title: "Terms & Conditions  - Guide to Understand Before Use",
    meta: [
      {
        attribute: "name",
        value: "description",
        content:
          "Read RanksPro.io's customer terms and conditions to easily understand before using powerful rank tracking, SEO analysis tools, and affiliate programs.",
      },
      {
        attribute: "property",
        value: "og:title",
        content:
          "Terms & Conditions RanksPro.io - Guide to Understand Before Use",
      },
      {
        attribute: "property",
        value: "og:description",
        content:
          "Read RanksPro.io's customer terms and conditions to easily understand before using powerful rank tracking, SEO analysis tools, and affiliate programs.",
      },
    ],
  },
  forgot: {
    title: "Forgot RanksPro.io Password? Simple Steps to Recover",
    meta: [
      {
        attribute: "name",
        value: "description",
        content:
          "Lost RanksPro.io password? No worries! Follow simple steps to regain access and continue optimizing your website's performance with advanced rank tracking and SEO analysis.",
      },
      {
        attribute: "property",
        value: "og:title",
        content: "Forgot RanksPro.io Password? Simple Steps to Recover",
      },
      {
        attribute: "property",
        value: "og:description",
        content:
          "Lost RanksPro.io password? No worries! Follow simple steps to regain access and continue optimizing your website's performance with advanced rank tracking and SEO analysis.",
      },
    ],
  },
  aff_login: {
    title: "Login to Access RanksPro.io Affiliate Account with a Click",
    meta: [
      {
        attribute: "name",
        value: "description",
        content:
          "Log in effortlessly to the RanksPro.io Affiliate account. Manage earnings, track progress, and unlock a world of opportunities in just a few simple steps.",
      },
      {
        attribute: "property",
        value: "og:title",
        content:
          "Login to Access RanksPro.io Affiliate Account with a Click",
      },
      {
        attribute: "property",
        value: "og:description",
        content:
          "Log in effortlessly to the RanksPro.io Affiliate account. Manage earnings, track progress, and unlock a world of opportunities in just a few simple steps.",
      },
    ],
  },
  aff_signup: {
    title: "Supercharge Your Earnings - Sign Up RanksPro.io Affiliate",
    meta: [
      {
        attribute: "name",
        value: "description",
        content:
          "Ready to boost your income? Sign up for the RanksPro.io Affiliate Program now. Quick and easy registration to unlock earning potential. Let's get you started now.",
      },
      {
        attribute: "property",
        value: "og:title",
        content: "Supercharge Your Earnings - Sign Up RanksPro.io Affiliate",
      },
      {
        attribute: "property",
        value: "og:description",
        content:
          "Ready to boost your income? Sign up for the RanksPro.io Affiliate Program now. Quick and easy registration to unlock earning potential. Let's get you started now.",
      },
    ],
  },
  aff_forgot: {
    title: "Reset Your RanksPro.io Affiliate Password with Ease",
    meta: [
      {
        attribute: "name",
        value: "description",
        content:
          "Forgot your password? No worries! Quickly reset it for your RanksPro.io Affiliate account. Simple steps to get you back on track and earning in no time.",
      },
      {
        attribute: "property",
        value: "og:title",
        content: "Reset Your RanksPro.io Affiliate Password with Ease",
      },
      {
        attribute: "property",
        value: "og:description",
        content:
          "Forgot your password? No worries! Quickly reset it for your RanksPro.io Affiliate account. Simple steps to get you back on track and earning in no time.",
      },
    ],
  },
  affiliate: {
    title: "RanksPro.io Affiliate Program for Maximize Your Earnings",
    meta: [
      {
        attribute: "name",
        value: "description",
        content:
          "Join the RanksPro.io Affiliate Program to boost your revenue effortlessly. Seize the chance to earn more while promoting top-notch RanksPro.io resources. Start earning now!",
      },
      {
        attribute: "property",
        value: "og:title",
        content: "RanksPro.io Affiliate Program for Maximize Your Earnings",
      },
      {
        attribute: "property",
        value: "og:description",
        content:
          "Join the RanksPro.io Affiliate Program to boost your revenue effortlessly. Seize the chance to earn more while promoting top-notch RanksPro.io resources. Start earning now!",
      },
    ],
  },
};

export default seoMetaData;
