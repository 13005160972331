import React from 'react'
import RanksProLogo from "../../../../Mycomponent/Assets/site-audit/RanksProLogo.png";
import {
  Page,
  Text,
  View,
  Document,
  StyleSheet,
  Image,
  Link,
  PDFDownloadLink,
} from "@react-pdf/renderer";
import { FiDownload } from "react-icons/fi";
import { useNavigate } from 'react-router-dom';
import { getCurrentYear } from '../../../share/helper-js/current-year';


const styles = StyleSheet.create({
  page: {
    paddingHorizontal: 20,
    flexDirection: "column",
    paddingTop: 30,
    paddingBottom: 80, // Set vertical padding to 30 pixels
    borderStyle: "solid",
  },
  header: {
   paddingBottom:20
  },
  footer: {
    fontSize: 12,
    textAlign: "center",
    color: "grey",
    position: "absolute",
    bottom: 20,
    left: 0,
    right: 0,
  },
  logo: {
    width: 250,
  },
  title: {
    fontSize: 24,
    textAlign: "center",
    marginBottom: 20,
  },

  tableContainer: {
    marginBottom: 20,
  },
  table: {
    width: "100%",
    borderStyle: "solid",
    borderWidth: 1,
    display: "flex", // Use flex to create a flexible row layout
    flexDirection: "column", // Each table row is a flex column
  },
  tableRow: {
    display: "flex",
    flexDirection: "row", // Each cell is a flex item in a row
    borderBottomWidth: 1,
    borderColor: "#000",
    alignItems: "center",
  },
  keywordLoc: {
    flex: 1, // Each cell should take equal space within the row
    padding: 5,
    borderRightWidth: 1,
    borderColor: "#000",
    justifyContent: "start",
    minHeight: 40,
    height: "100%",
    alignItems: "start",
    flexDirection: "column",
  },
  tableCell: {
    flex: 1, // Each cell should take equal space within the row
    padding: 5,
    borderRightWidth: 1,
    borderColor: "rgb(221, 221, 221)",
    justifyContent: "center",
    alignItems: "center",
    flexDirection: "row",
  },
  fixedWidthCell: {
    maxWidth: 80, // Disable flex for fixed width cells
  },
  cardContainer: {
    flexDirection: "row",
    flexWrap: "wrap", // Enable flex wrapping
    justifyContent: "space-between", // Arrange cards with equal spacing
    marginTop: 10,
  },
  card: {
    flexDirection: "row",
    justifyContent: "space-between",
    width: 180,
    backgroundColor: "#FFFFFF",
    padding: 10,
    borderRadius: 5,
    marginBottom: 20,
  },
  image: {
    width: 30,
    height: 40,
  },
  title: {
    fontSize: 11,
    fontWeight: "bold",
    marginBottom: 5,
    color: "#6c757d",
  },
  description: {
    fontSize: 16,
  },
  imageContainer: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  arrowIcon: {
    width: 20,
    height: 20,
    objectFit: "contain",
  },
  view_button: {
    borderWidth: 1,
    borderColor: "#365486",
    padding: 5,
    borderRadius: 7,
    color: "#365486",
    fontSize: 11,
  },
  keyword_bold: {
    fontSize: 13,
    fontWeight: "bold",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  keyword: {
    fontSize: 11,
    color: "#6c757d",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  rank: {
    fontSize: 11,
    color: "#6c757d",
    textAlign: "center",
    marginRight: 10,
  },
  table_cell: {
    fontSize: 7,
    color: "#6c757d",
    textAlign: "center",
    marginVertical: 5,
  },
  table_cell_url: {
    fontSize: 7,
    color: "blue",
    textAlign: "center",
    marginVertical: 5,
    overflow: "hidden",
    textDecoration:"none"
  },
  table_header: {
    fontSize: 9,
    textAlign: "center",
  },
  pageNumber: {
    position: "absolute",
    fontSize: 11,
    bottom: 45,
    left: 0,
    right: 0,
    textAlign: "center",
    color: "grey",
  },
  link_text: {
    fontSize: 7,
    textDecoration: "none",
    textOverflow: "hidden",
    textAlign: "start",
  },
  logoHead: {
    width: 125,
  },
  
});

export const PdfFile = ({domain,sortTableData,pdfNewData}) => {
  
  const Data = JSON.parse(sessionStorage.getItem("pdfSessionData"));
  const Web_url = Data ? Data?.domain : domain;
  const sorted_Table_Data = Data ? Data?.sortTableData : sortTableData;
  const image_logo = pdfNewData
    ? pdfNewData?.reset
      ? RanksProLogo
      : pdfNewData?.logo?.src
    : RanksProLogo;
  return(
  <Document>
    <Page size={"A4"} style={styles.page}>
      <View
        style={{
          alignItems: "center",
          display: "flex",
          height: "100%",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <Image style={styles.logo} src={image_logo} />
        <Text style={{ marginBottom: 20 }}>
        Backlinks Gap Analysis
        </Text>
        <Text style={styles.title}>Your Domain: {sessionStorage.getItem("yourlastbacklinkdomain") ||
          Web_url.you ||
          "Your domain"}</Text>
        <Text style={styles.title}>Competitors Domain: {sessionStorage.getItem("competitorlastbacklinkdomain") ||
        Web_url.competitor_1 ||
        "Your domain"}</Text>

      </View>
</Page>
<Page size={"A4"} style={styles.page}>
  

<View
          style={{
            ...styles.header,
            alignItems: pdfNewData?.reset
              ? "flex-start"
              : pdfNewData?.logo.position,
          }}
          fixed
        >
          <Image style={styles.logoHead} src={image_logo} />
        </View>
  
      <View style={{ ...styles.tableContainer }}>
        <View style={styles.table}>
          <View style={styles.tableRow}>
            <View style={{ ...styles.tableCell, backgroundColor: "#f4f4f4" }}>
              <Text style={styles.table_header}>Referring Domains</Text>
            </View>
            <View style={{ ...styles.tableCell, backgroundColor: "#f4f4f4" }}>
              <Text style={styles.table_header}>DR</Text>
            </View>
            <View style={{ ...styles.tableCell, backgroundColor: "#f4f4f4" }}>
              <Text style={styles.table_header}>{sessionStorage.getItem("yourlastbacklinkdomain") ||
                  domain.you ||
                  "Your domain"}</Text>
            </View>
            <View style={{ ...styles.tableCell, backgroundColor: "#f4f4f4" }}>
              <Text style={styles.table_header}>{sessionStorage.getItem("competitorlastbacklinkdomain") ||
        domain.competitor_1 ||
        "Your domain"}</Text>
            </View>
          </View>
          <View>
            {sorted_Table_Data &&
  sorted_Table_Data?.map((item, index) => (
              <View style={styles.tableRow} key={`table-row-${index}`}>
                <View style={styles.tableCell}>
                  <Link src={item?.data?.[0]?.url_from} style={styles.table_cell_url}>{item?.data?.[0]?.domain_from}</Link>
                </View>

                <View style={styles.tableCell}>
                  <Text style={styles.table_cell}>
                  {Math.round(
              item?.data?.[0]?.domain_authority / 10
            ) < 5
              ? 0
              : Math.round(
                  item?.data?.[0]?.domain_authority / 10
                ) || 0}
                  </Text>
                </View>

                <View style={styles.tableCell}>
                  <Text style={styles.table_cell}>
                  0
                  </Text>
                </View>
                <View style={styles.tableCell}>
                  <Text style={styles.table_cell}>
                  {item?.data?.length}
                  </Text>
                </View>
              </View>
            ))}
          </View>
        </View>
      </View>

      <Text style={styles.footer} fixed>
      {pdfNewData?.reset
            ? `Copyright ${getCurrentYear} eSearch Logix | Prepared using RanksPro.io`
            : pdfNewData?.footer}
      </Text>

      
    </Page>
  </Document>
)};


const DomainRankPdfModal = ({sortTableData,domain }) => {

 


  const navigate = useNavigate()


  const handleRoute =()=>{

    sessionStorage.setItem('pdfSessionData',JSON.stringify({sortTableData:sortTableData,domain:domain,id:8}))
    setTimeout(() => {
      navigate("/CrawledPageEditor");
    }, 500)
     
    }



  return (
    <div>
      <div className="d-flex justify-content-end" style={{    padding: "5px 100px"}}>
      <FiDownload
          onClick={handleRoute}
          className="down"
          title="DomainRanking PDF"
          aria-label="Close" data-bs-dismiss="modal"
          />
        

          </div>
          
        <div
        className="custom-table-container"
        style={{
          borderBottomLeftRadius: "0px",
          borderBottomRightRadius: "0px",
        }}
      >

        <table className="gap-backlink-table">
    <thead>
      <tr className="table-sticky">
        
        <th scope="col" className="t_left "    style={{
              width: 150,
              padding: 5,
              borderRightWidth: 1,
              justifyContent: "center",
              alignItems: "center",
              flexDirection: "row",
            }}>
          <span
            className="point d-flex justify-content-center"
         
          >
            Referring Domains
           </span>
        </th>

        <th
          scope="col"
          style={{ width: "120px" }}
          className="text-center"
        >
          <span
            className="point"
          >
            DR
            
          </span>
         </th>
         <th
          scope="col"
          style={{ width: "70px" }}
          className="text-center"
        >

{sessionStorage.getItem("yourlastbacklinkdomain") ||
                        domain.you ||
                        "Your domain"}
        </th>
        
        <th
          scope="col"
          style={{ width: "300px" }}
          className="text-center"
        >
         

          <span
            className="point"
          >
            {sessionStorage.getItem("competitorlastbacklinkdomain") ||
              domain.competitor_1 ||
              "Your domain"}
            
          </span>
          
        </th>
        
      </tr>
    </thead>
    <tbody>
      {
        sortTableData &&
        sortTableData?.map((item, index) => {
          return (
            <React.Fragment
              key={`blacklink-table-list-${index}-${item?.domain}-${item?.data?.[0]?.url_from}`}
            >
              <tr>
               
                <td
                  className="t_left table_cell"
                  style={{ cursor: "pointer" }}
                >
                  <div
                   
                    
                    className="ellipsis d-flex justify-content-center"
                  >
                    <span className="blacklink-table-span mt-0 d-flex justify-content-center">
                      <span className="backlink-table-head mt-0 d-flex justify-content-center">
                        <a
                          href={item?.data?.[0]?.url_from}
                          target="_blank"
                          className="d-flex justify-content-center"
                        >
                          <span style={{ fontSize: "1.2em" }}>
                            {item?.data?.[0]?.domain_from}
                          </span>
                        </a>
                      </span>
                    </span>
                  </div>
                </td>
                <td
                  className="text-center"
                  style={{ cursor: "pointer" }}
                >
                  {Math.round(
                    item?.data?.[0]?.domain_authority / 10
                  ) < 5
                    ? 0
                    : Math.round(
                        item?.data?.[0]?.domain_authority / 10
                      ) || 0}
                </td>
                <td
                  className="text-center"
                  style={{ cursor: "pointer" }}
                >
                  0
                </td>
                <td
                  className="text-center"
                  style={{ cursor: "pointer" }}
                >
                  {item?.data?.length}
                </td>
              </tr>
             
            </React.Fragment>
          );
        })
      }
    </tbody>
  </table>
  
  
  </div>
  </div>
  )
}

export default DomainRankPdfModal