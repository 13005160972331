import Highlight from "@tiptap/extension-highlight";
import TaskItem from "@tiptap/extension-task-item";
import TaskList from "@tiptap/extension-task-list";
import { EditorContent, useEditor } from "@tiptap/react";
import StarterKit from "@tiptap/starter-kit";
import React from "react";
import MenuBar from "./MenuBar.jsx";
import "./tiptop.scss";

const TiptopEditor = ({ content, onchangeHandler }) => {
  const editor = useEditor({
    extensions: [
      StarterKit.configure({
        history: true,
      }),
      Highlight,
      TaskList,
      TaskItem,
    ],
    content: content,
    onUpdate({ editor }) {
      onchangeHandler(editor.getHTML());
    },
  });

  return (
    <div className="editor">
      {editor && <MenuBar editor={editor} />}
      <EditorContent
        className="editor__content"
        editor={editor}
        id="editor__content"
      />
    </div>
  );
};

export default TiptopEditor;
