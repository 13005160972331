import React from "react";
import { useSelector } from "react-redux";
import SelectCustomDate from "../searchBox/selectCustomDate";
import { curday } from "../upDater/constant";
import useMediaQuery from "@mui/material/useMediaQuery";
import { getOldestObject } from "../../../helperJs/helper";

const LastUpdate = () => {
  const matches = useMediaQuery("(min-width:600px)");
  const previousallolddata = useSelector((state) => state.previousallolddata);
  const selectedprojectnewdata = useSelector(
    (state) => state.selectedprojectnewdata
  );
  const selectcustomdate = useSelector((state) => state.selectcustomdate);
  const lastDate =
    previousallolddata?.length < selectcustomdate?.day
      ? previousallolddata?.length
      : selectcustomdate?.day;

  const FromDetails = () => {
    return selectedprojectnewdata[0]?.month
      ? selectedprojectnewdata[0].month +
          " " +
          selectedprojectnewdata[0].date +
          ", " +
          selectedprojectnewdata[0].year
      : false;
  };

  const previousfirstdata =
    previousallolddata &&
    previousallolddata?.map((item) => {
      if (item.data?.length === 0) return [];
      let data = item?.data?.[0];
      data["location"] = item?.location;
      return data;
    });

  const oldestObject = getOldestObject(previousfirstdata);

  const ToDetails = () => {
    return oldestObject
      ? oldestObject?.month +
          " " +
          oldestObject?.date +
          ", " +
          oldestObject?.year +
          " - "
      : false;
  };

  const lastUpdatedTime =
    selectedprojectnewdata[0]?.month +
    " " +
    selectedprojectnewdata[0]?.date +
    ", " +
    selectedprojectnewdata[0]?.year +
    " " +
    selectedprojectnewdata[0]?.time +
    "AM";

  const dataLoadedFrom = `${ToDetails() || ""} ${FromDetails() || curday()}`;
  sessionStorage.setItem("LstUpdtRnkTckgTbl", FromDetails());
  sessionStorage.setItem("DtUpdtRnkTckgTbl", dataLoadedFrom);

  return (
    <div className="last-update-container">
      <SelectCustomDate />
      {previousallolddata?.length > 0 ? <span>{dataLoadedFrom}</span> : null}
    </div>
  );
};

export default LastUpdate;
