import axios from "axios";
import React, { useRef, useState } from "react";
import { useEffect } from "react";
import {
  DELETE_CODE,
  POST_CODES,
  Reffral_Get,
  USEDCODES_GET,
} from "../../services/constants";
import { DottLoader } from "../../Mycomponent/share/loader";
import { CSVLink } from "react-csv";
import { curday } from "../../Mycomponent/share/upDater/constant";
import CustomTable from "../../Mycomponent/share/customTable";
import useMediaQuery from "@mui/material/useMediaQuery";
import { Box, Button } from "@mui/material";
import MenuListDropdown from "../../Mycomponent/share/components/menulist.component";
import { CODEVALID_GET } from "../../services/constants";
import useLoader from "../../Mycomponent/share/components/useLoader.component";

const Codes = () => {
  // aleter
  const [ShowAlert, setShowAlert] = useState(false);
  const [usedcodeAlert, setUsedCodeAlert] = useState(false);
  const matches = useMediaQuery("(min-width:600px)");
  const [isLoading, showLoader] = useLoader();

  const [page, setPage] = React.useState(1);
  // input const
  const [name, setName] = useState("alpha");
  const [genratelmt, setGenratelmt] = useState(0);
  const [validityFor, setValidityFor] = useState("7");

  // state vairables
  const [detailsCSV, setDetailsCSV] = useState([]);
  const [codesData, setCodesData] = useState([]);
  const [usedCodesData, setUsedCodesData] = useState([]);
  const [indexlimit, setIndexlimit] = useState(0);
  const [prevlimit, setPrevlimit] = useState(100);
  const [loading, setLoading] = useState(true);
  const [allCodes, setAllCodes] = useState([]);
  const code = useRef("");
  const [allUsedCode, setAllUsedCode] = useState([]);

  const fatchData = () =>
    axios.get(Reffral_Get()).then((res) => {
      const arry = [];
      setAllCodes(res.data.data);
      res.data.data &&
        res.data.data.map((res) => {
          res.codes.map((ress) => {
            arry.push({
              id: res.id,
              validity: res.validity,
              code: ress,
              plan_name: res.plan_name,
            });
          });
        });
      setCodesData(arry);
      setTimeout(() => {
        setLoading(false);
      }, 300);
    });

  const fatchUsedCodes = () =>
    axios.get(CODEVALID_GET()).then((res) => {
      setUsedCodesData(res.data);
    });

  useEffect(() => {
    fatchData();
    fatchUsedCodes();
  }, []);

  useEffect(() => {
    const CSV_Details = [];
    codesData &&
      codesData.map((res, key) => {
        return CSV_Details.push({
          Code: res.code,
          PlanName: res.plan_name,
          Validity: res.validity,
        });
      });
    setDetailsCSV(CSV_Details);
  }, [codesData]);

  const CodesHandler = () => {
    const characters = "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz";
    const numCharacters = "0123456789";

    let newCodes = [];
    for (let i = 0; i < genratelmt; i++) {
      // Assuming 'genratelmt' is defined somewhere
      let precode = "";
      let hasNumber = false;
      let hasString = false;

      for (let j = 0; j < 10; j++) {
        // Generating a 10-character code
        const randomIndex = Math.floor(Math.random() * characters.length);

        // Alternate between adding a string character and a number character
        if (j % 2 === 0) {
          precode += characters[randomIndex];
          hasString = true;
        } else {
          precode += numCharacters[randomIndex % numCharacters.length];
          hasNumber = true;
        }
      }

      // Ensure that the code contains both string and number characters
      if (hasString && hasNumber) {
        newCodes.push(precode);
      } else {
        // Regenerate the code if it doesn't contain both types of characters
        i--;
      }
    }

    code.current = newCodes; // Assuming 'code' is a ref or state variable

    // Checking if there are any codes to send
    if (code.current.length !== 0) {
      const data = {
        plan_name: name,
        codes: code.current,
        validity: validityFor,
      };
      axios
        .post(POST_CODES(), data)
        .then(() => {
          setShowAlert(false);
          fatchData();
        })
        .catch(() => {});
    }
  };

  const deleteCode = (res, code) => {
    const propItem = {
      id: res.id,
      code: code,
    };
    axios
      .put(DELETE_CODE(propItem))
      .then(() => {
        fatchData();
      })
      .catch(() => {});
  };

  const usedCodeTableHeader = [
    {
      label: "E-MAIL",
      key: "email",
      minWidth: 120,
      align: "left",
      width: "25%",
    },
    {
      label: "CODES",
      key: "codes",
      minWidth: 120,
      align: "left",
      width: "25%",
    },
    {
      label: "PLAN",
      key: "plan",
      minWidth: 120,
      width: "25%",
      align: "center",
    },
    {
      label: "VALIDITY",
      key: "validity",
      minWidth: 120,
      width: "50%",
      align: "center",
    },
  ];

  const tableHeader = [
    {
      label: "CODES",
      key: "codes",
      minWidth: 120,
      align: "left",
      width: "25%",
    },
    {
      label: "PLAN",
      key: "plan",
      minWidth: 120,
      width: "25%",
      align: "center",
    },
    {
      label: "VALIDITY",
      key: "validity",
      minWidth: 120,
      width: "50%",
      align: "center",
    },
    {
      label: "",
      key: "space",
      minWidth: 100,
      width: "50%",
      align: "center",
    },
  ];

  function createData(codes, plan, validity, space) {
    return {
      codes,
      plan,
      validity,
      space,
    };
  }

  function usedCodeCreatData(email, codes, plan, validity) {
    return {
      email,
      codes,
      plan,
      validity,
    };
  }

  const usedCodeRows = usedCodesData?.map((code) => {
    return usedCodeCreatData(
      code?.email,
      code?.code_valid,
      "Appsumo Lifetime Plan",
      code?.valid
    );
  });

  const rows = codesData?.map((code) => {
    return createData(
      code?.code,
      code?.plan_name,
      code?.validity,
      <div
        onClick={() => {
          deleteCode(code, code?.code);
        }}
      >
        <i
          className="fa-solid fa-trash"
          style={{
            color: "rgb(162, 68, 68)",
            fontSize: "18px",
          }}
        ></i>
      </div>
    );
  });

  const loadLatestCSVData = () => {
    if (allCodes?.length === 0) return [];
    const latets_code =
      allCodes?.length > 0 ? allCodes?.[allCodes?.length - 1] : [];

    return latets_code?.codes?.map((code) => {
      return {
        plan: latets_code.plan_name,
        validity: latets_code.validity,
        code: code,
      };
    });
  };

  const dataList = {
    title: "Export CSV",
    list: [
      <CSVLink data={loadLatestCSVData()} filename={"latest_codes.csv"}>
        <Button
          className="btn_new_tr rounded-5 px-3 py-2"
          sx={{
            borderColor: "#365486 !important",
            color: "#365486 !important",
            height: "45px",
          }}
        >
          <i
            className="fa-regular fa-file-lines"
            style={{ paddingRight: "10px" }}
          ></i>
          Latest Code
        </Button>
      </CSVLink>,
      <CSVLink
        data={detailsCSV ? detailsCSV : "null"}
        filename={`${curday()} all_codes.csv`}
        className="w-100"
      >
        <Button
          className="btn_new_tr rounded-5 px-3 py-2 w-100"
          sx={{
            borderColor: "#365486 !important",
            color: "#365486 !important",
            height: "45px",
          }}
        >
          <i
            className="fa-regular fa-file-lines"
            style={{ paddingRight: "10px" }}
          ></i>
          All Code
        </Button>
      </CSVLink>,
    ],
  };

  if (loading) {
    return (
      <div>
        <h2 className="table_title heading-large font-bold mb-4">Codes</h2>
        <DottLoader />
      </div>
    );
  }

  return (
    <>
      {usedcodeAlert ? (
        <CustomTable
          showSearchBar={true}
          tableTitle={"Used Codes"}
          downloadCSVFile={
            <>
              <Button
                className="btn_1 mx-2"
                onClick={() => {
                  showLoader();
                  setUsedCodeAlert(!usedcodeAlert);
                }}
              >
                Back
              </Button>{" "}
              <MenuListDropdown data={dataList} cusClass="btn_1 code_btn" />
              <Button className="btn_1 ms-2" onClick={() => setShowAlert(true)}>
                {" "}
                Genrate Code
              </Button>
            </>
          }
          dataLoader={isLoading}
          tableHeader={usedCodeTableHeader}
          rows={usedCodeRows}
          page={page}
          setPage={setPage}
        />
      ) : (
        <CustomTable
          dataLoader={isLoading}
          showSearchBar={true}
          tableTitle={"Codes"}
          downloadCSVFile={
            <>
              <Button
                className="btn_1 mx-2"
                onClick={() => {
                  showLoader();
                  setUsedCodeAlert(!usedcodeAlert);
                }}
              >
                Used Code
              </Button>{" "}
              <MenuListDropdown data={dataList} cusClass="btn_1 code_btn" />
              <Button className="btn_1 ms-2" onClick={() => setShowAlert(true)}>
                {" "}
                Genrate Code
              </Button>
            </>
          }
          tableHeader={tableHeader}
          rows={rows}
          page={page}
          setPage={setPage}
        />
      )}

      {ShowAlert ? (
        <div className="pop ">
          <div className="popBody">
            {/* <div className='exeMark'><h1>?</h1> </div> */}
            <h3>Genrate Codes </h3>
            {/* <form> */}
            <div className="pop-form">
              <div style={{ textAlign: "left" }}>
                <div className="pop-select">
                  <select onChange={(e) => setName(e.target.value)}>
                    <option value="alpha">alpha</option>
                    <option value="starter">starter</option>
                    <option value="pro">pro</option>
                    <option value="elite">elite</option>
                    <option value="ultimate">ultimate</option>
                    {/* <option value='lifetime'>lifetime</option> */}
                  </select>
                  <label id="lb"> Plan Name </label>
                </div>

                <input
                  type="number"
                  placeholder="Genrate limit - 10 , 20 "
                  onChange={(e) => setGenratelmt(e.target.value)}
                ></input>
                <label id="lb">Genrate lmt </label>

                <div className="pop-select">
                  <select onChange={(e) => setValidityFor(e.target.value)}>
                    <option value="7">7 Days</option>
                    <option value="15">15 Days</option>
                    <option value="30">30 Days</option>
                    <option value="lifetime">Lifetime</option>
                  </select>
                  <label id="lb">Validity For </label>
                </div>
              </div>
            </div>

            <div className="cmd">
              <button className="cm-btn-b" onClick={() => setShowAlert(false)}>
                {" "}
                Cancel
              </button>
              <button className="cm-btn" onClick={() => CodesHandler()}>
                Submit
              </button>
            </div>
            {/* </form> */}
          </div>
        </div>
      ) : null}
    </>
  );
};

export default Codes;
