import React from "react";
import { useDispatch, useSelector } from "react-redux";
import Dropdown from "../components/dropdown";
import useMediaQuery from "@mui/material/useMediaQuery";
import { ZustandStore } from "../../../store/zustand-store";

const SelectCustomDate = () => {
  const customDate = useSelector((state) => state.selectcustomdate);
  const dispatch = useDispatch();
  const matches = useMediaQuery("(min-width:600px)");
  const { emptyKeywordData, emptyAllLocData } = ZustandStore((state) => state);
  const selectDay = [
    { title: "week", day: 7, meta_des: "1" },
    { title: "days", day: 30, meta_des: "30" },
    { title: "month", day: 90, meta_des: "3" },
    { title: "month", day: 180, meta_des: "6" },
    { title: "year", day: 365, meta_des: "1" },
  ];

  const handelDate = (res) => {
    if (res?.day === customDate?.day) return;
    emptyAllLocData();
    emptyKeywordData();
    dispatch({ type: "SELECTCUSTOMDATE", payload: res });
  };

  const title = "Last " + customDate?.meta_des + " " + customDate?.title;
  sessionStorage.setItem("pieCrtDte", title);
  return (
    <div className="pe-3">
      <Dropdown
        callback={handelDate}
        data={selectDay}
        title={title}
        searchType={true}
        style={{
          width: matches ? "200px" : "130px",
          maxWidth: "200px",
          height: "37px",
          textTransform: "capitalize",
          boxShadow: "none",
          border: "1px solid #000000E5",
          borderRadius: "5px",
          padding: "1rem 1rem",
          color: "#000000CC",
        }}
      />
    </div>
  );
};

export default SelectCustomDate;
