import React, { useState, useRef } from "react";
import Chip from "@mui/material/Chip";
import { Box, IconButton, TextField } from "@mui/material";
import Stack from "@mui/material/Stack";
import {
  updateKeywordsTag,
  postKeywordsTag,
  deleteKeywordsTag,
} from "./tag-operations";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import { useClickAway } from "react-use";
import CustomizedTooltips from "./tag-tooltip";
import useDebouncedFunction from "../../../../helperJs/debounce";
import { uniqueByTextAndKeyword } from "../../../../helperJs/helper";

const CustomTag = React.memo(({ tag, onDelete }) => {
  return (
    <Chip
      label={tag.text}
      variant="outlined"
      onDelete={() => onDelete(tag)}
      sx={{
        height: "22px",
        minWidth: "85px",
        "& .MuiChip-label": {
          fontSize: "12px",
          color: "rgba(0, 0, 0, 0.87)",
          fontWeight: "500",
        },
        "& .MuiChip-deleteIcon": {
          fontSize: "18px",
        },
      }}
    />
  );
});

function TagComponent({ list, keyword, config, location }) {
  const inputRef = useRef(null);
  const tagLimit = 3;
  const [alert, setAlert] = React.useState({
    show: false,
    msg: "",
  });
  const [inputTag, setInputTag] = useState({
    showInput: true,
    text: "",
  });
  const selected_keyword = list?.filter((item) => {
    return (
      item?.keyword_list?.includes(keyword) && item.location_name == location
    );
  });

  const int_tags =
    selected_keyword?.map((item, index) => {
      return {
        id: index + 1,
        text: item.tag_name,
        keyword: item.keyword_list,
      };
    }) || [];

  const uniqueData = uniqueByTextAndKeyword(int_tags);
  const [tags, setTags] = useState(uniqueData);

  const handleDelete = (tagToDelete) => {
    if (tagToDelete?.text === "" || tagToDelete.text == undefined) return;
    let data = {
      email: config?.email,
      tag_name: tagToDelete?.text,
      keyword_list: [keyword],
      tag_type: config?.tag_type,
      proj_name: config?.proj_name,
      location_name: location,
      language_name: config?.language_name || "0",
      all_location_list:
        config?.tag_type === "rank desktop" ||
        config?.tag_type === "rank mobile"
          ? config?.location_name
          : location,
    };
    deleteKeywordsTag(data);
    setTags((prevTags) => prevTags.filter((tag) => tag.id !== tagToDelete.id));
  };

  const handleAddition = () => {
    if (inputTag?.text === "" || inputTag.text == undefined) return;
    // Trim the input tag text to remove leading and trailing spaces
    let newValue = inputTag?.text?.trim();

    // Check if the new value already exists in tags (case insensitive)
    const isDuplicate = tags.some(
      (tag) => tag.text.toLowerCase() === newValue.toLowerCase()
    );

    if (isDuplicate) {
      setAlert({
        show: true,
        msg: "Duplicate tag not allowed!",
      });
      return;
    }

    if (newValue.trim().length === 0 && newValue.length > 0) {
      setAlert({
        show: true,
        msg: "Tag cannot be empty!",
      });
      return;
    }

    if (newValue?.trim() !== "" && tags.length < tagLimit) {
      const newTag = { id: tags.length + 1, text: newValue.trim() };
      const tag_keyword = list?.filter((item) => {
        return item?.tag_name === newValue && item.location_name == location;
      });

      const keyword_list =
        tag_keyword?.length === 0
          ? [keyword]
          : [...tag_keyword?.[0]?.keyword_list, keyword];

      let data = {
        email: config?.email,
        tag_name: newValue,
        keyword_list: keyword_list,
        tag_type: config?.tag_type,
        proj_name: config?.proj_name,
        location_name: location,
        language_name: config?.language_name || "0",
        all_location_list:
          config?.tag_type === "rank desktop" ||
          config?.tag_type === "rank mobile"
            ? config?.location_name
            : location,
      };

      if (tag_keyword?.length > 0) {
        updateKeywordsTag(data);
      } else {
        postKeywordsTag(data);
      }
      setTags((prevTags) => [...prevTags, newTag]);
      setInputTag({
        text: "",
        showInput: true,
      });
    }
  };

  const debouncedFunction = useDebouncedFunction(() => {
    handleAddition();
  }, 100);

  const handleKeyPress = (event) => {
    if (event.key === "Tab") {
      setInputTag({
        showInput: true,
        text: "",
      });
    } else if (event.key === "Enter") {
      debouncedFunction();
    }
  };

  const tag = {
    id: "0",
    text: "Add tag",
  };

  useClickAway(inputRef, () => {
    setInputTag({
      showInput: true,
      text: "",
    });
  });

  const handleTagTextChange = (e) => {
    let newValue = e.target.value;
    if (newValue.length > 50) {
      setAlert({
        show: true,
        msg: "Text cannot exceed 50 characters!",
      });
      return;
    }
    // Regular expression to allow only letters, numbers, spaces, and periods
    const allowedCharsRegex = /^[a-zA-Z0-9.\s]+$/;

    // Replace multiple spaces with a single space
    newValue = newValue.replace(/\s+/g, " ");
    if (!allowedCharsRegex.test(newValue) && newValue?.length > 0) {
      setAlert({
        show: true,
        msg: "Text can only contain letters, numbers and periods!",
      });
      return;
    }

    setAlert({ show: false, msg: "" });
    setInputTag((pre) => ({
      ...pre,
      text: newValue,
    }));
  };

  return (
    <Box className="react-tag-container">
      <Stack spacing={1} direction="row" useFlexGap flexWrap="wrap">
        {tags?.map((tag) => {
          return (
            <CustomTag
              onDelete={(e) => handleDelete(e, tag.id)}
              tag={tag}
              key={tag.text}
            />
          );
        })}
        {tags?.length >= tagLimit ? null : inputTag.showInput ? (
          <Box
            className="tag-add-input point"
            sx={{ display: "flex", alignItems: "center" }}
            onClick={() => {
              setInputTag((pre) => ({
                ...pre,
                showInput: false,
              }));
            }}
          >
            <span className="ps-2">Add Tag</span>
            <IconButton
              sx={{
                padding: "5px",
              }}
              disableRipple={true}
            >
              <AddCircleIcon
                fontSize="small"
                sx={{
                  color: "#bdbdbd",
                  "& .MuiSvgIcon-root": {
                    width: "19px",
                    height: "19px",
                  },
                }}
              />
            </IconButton>
          </Box>
        ) : (
          <CustomizedTooltips alert={alert}>
            <Box
              className="tag-add-input"
              ref={inputRef}
              sx={{ display: "flex", alignItems: "center" }}
            >
              <TextField
                variant="outlined"
                size="small"
                placeholder="Add tag"
                value={inputTag.text}
                onChange={handleTagTextChange}
                onKeyDown={handleKeyPress}
                sx={{
                  width: "150px",
                  "& .Mui-focused": {
                    border: "none",
                  },
                  "& .MuiInputBase-input": {
                    paddingRight: "0px !important",
                  },
                  "& .MuiOutlinedInput-notchedOutline": {
                    border: "none",
                    borderColor: "transparent",
                    borderWidth: "0px !important",
                  },
                }}
              />
              <IconButton
                disableRipple={true}
                sx={{
                  padding: "5px",
                }}
              >
                <AddCircleIcon
                  fontSize="small"
                  sx={{
                    color: "#bdbdbd",
                    "& .MuiSvgIcon-root": {
                      width: "19px",
                      height: "19px",
                    },
                  }}
                />
              </IconButton>
            </Box>
          </CustomizedTooltips>
        )}
      </Stack>
    </Box>
  );
}

export default React.memo(TagComponent);
