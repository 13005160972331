import React, {
  useState,
  useEffect,
  Suspense,
  lazy,
  useRef,
  useMemo,
} from "react";
import "../css/rankTracking.css";
import { Link } from "react-router-dom";
import ProjectList from "../share/searchBox/ProjectList";
import LastUpdate from "../share/components/lastUpdate";
import { useSelector, useDispatch } from "react-redux";
import ProjectLocation from "../share/searchBox/ProjectLocation";
import RankTablewithSorting from "../share/tables/RankTablewithSorting";
import { useNavigate, useLocation } from "react-router-dom";
import Spinner from "../share/components/spinner";
import { ScrollToView } from "../share/helper-js/scroll-into-view";
// icons
import addIcon from "../Assets/rank-tracking-assets/add.svg";
import { ZustandStore } from "../../store/zustand-store";
import useQuery from "../share/custom-hook/useQuery";
import countryList from "../dataFiles/country";
import CopyLinkDialog from "../../Layout/GuestLink/component/CopyLinkDialog.jsx";
import { Button } from "@mui/material";

const AverageChart = lazy(() => import("../share/charts/AverageRank"));

const RankTracking = () => {
  const navigate = useNavigate();
  const location_path = useLocation();
  const dispatch = useDispatch();
  const websiteurl = useSelector((state) => state.websiteurl);
  const showLoader = useSelector((state) => state.showloader);
  const selectCustomDate = useSelector((state) => state.selectcustomdate);
  const topRankData = useSelector((state) => state.toprankdata);
  const chartImprData = useSelector((state) => state.chartimprdata);
  const {
    rank_keyword,
    active_tab_rank,
    improved,
    declined,
    keyword_latest_data,
    keyword_old_data,
  } = ZustandStore((state) => state);
  const { setQueryParams, removeQueryParams } = useQuery();
  const [anchorEl, setAnchorEl] = React.useState(false);

  const oldAverageRank = useRef([]);
  const [oldAverageLabel, setOldAverageLabel] = useState([]);
  const [companyRank, setCompanyRank] = useState({
    rank: 0,
    keywords: 0,
  });

  const [rankGroups, setRankGroups] = useState({
    top3: [],
    top10: [],
    top100: [],
    noRank: [],
  });

  const { top3, top10, top100, noRank } = rankGroups;

  const getCurrentCountryDetails = (loc) => {
    const data = countryList?.filter((item) => {
      return item.location_name == loc;
    });
    return data?.[0]?.location_flag;
  };

  const [location, setLocation] = useState("Select Location");

  const AllLocation = useMemo(() => {
    const data = keyword_latest_data?.map((loc) => {
      return {
        location_name: loc.LOCATION,
        location_flag: getCurrentCountryDetails(loc.LOCATION),
      };
    });
    setLocation(data?.[0]?.location_name || "Select Location");
    return data;
  }, [keyword_latest_data]);

  const languageHandler = (e) => {
    setLocation(e.location_name);
  };

  const latestData = useMemo(() => {
    if (location === undefined || location === "Select Location") {
      const data = keyword_latest_data?.filter(
        (item) => item?.LOCATION == AllLocation?.[0]?.location_name
      );
      return data?.[0];
    } else {
      const data = keyword_latest_data?.filter(
        (item) => item?.LOCATION == location
      );
      return data?.[0];
    }
  }, [location, AllLocation]);

  const oldData = useMemo(() => {
    if (location === undefined || location === "Select Location") {
      const data = keyword_old_data?.filter(
        (item) => item?.LOCATION == AllLocation?.[0]?.location_name
      );
      return data?.[0];
    } else {
      const data = keyword_old_data?.filter(
        (item) => item?.LOCATION == location
      );
      return data?.[0];
    }
  }, [location, AllLocation]);

  const AllPreviousData = oldData?.ALLOLDKEYWORDDATA;
  const keyworddata = latestData?.KEYWORDDATA;

  const filtered_data = useMemo(() => {
    if (
      !topRankData?.top_rank &&
      chartImprData?.data?.length === 0 &&
      !chartImprData?.chart_show
    )
      return AllPreviousData;
    const rank_groups =
      chartImprData?.data?.length > 0 ? chartImprData?.data : topRankData?.data;
    const filtered =
      Array.isArray(AllPreviousData) &&
      AllPreviousData?.map((item) => {
        const data = item.data;
        const filteredData = data.filter((item) =>
          rank_groups?.includes(item.rank_group)
        );
        if (filteredData.length > 0) {
          return {
            data: filteredData,
            date: item?.date,
            month: item?.month,
            year: item?.year,
          };
        } else {
          return null;
        }
      });

    return (
      Array.isArray(filtered) &&
      filtered?.filter((item) => item?.data?.length > 0 && item)
    );
  }, [AllPreviousData, topRankData, chartImprData]);

  const lastDate =
    filtered_data?.length < selectCustomDate?.day
      ? filtered_data?.length
      : selectCustomDate?.day;

  const selectedprojectnewdata = useSelector(
    (state) => state.selectedprojectnewdata
  );

  const keywordAverage = () => {
    if (
      !topRankData?.top_rank &&
      chartImprData?.data?.length === 0 &&
      !chartImprData?.chart_show
    ) {
      const sum =
        keyworddata?.reduce((accumulator, res) => {
          if (res.rank_group !== "No Rank") {
            return accumulator + res.rank_group;
          }
          return accumulator;
        }, 0) || 0;
      ZustandStore.setState({
        average_rank:
          sum > keyworddata?.length
            ? Number(sum) / Number(keyworddata?.length)
            : 0,
      });
      setCompanyRank({ rank: sum, keywords: keyworddata?.length });
    } else {
      const rank_groups =
        chartImprData?.data?.length > 0
          ? chartImprData?.data
          : topRankData?.data;

      const sum =
        rank_groups?.reduce((accumulator, currentValue) => {
          const valueToAdd =
            currentValue === "No Rank" ? 0 : parseFloat(currentValue);
          return accumulator + valueToAdd;
        }, 0) || 0;
      ZustandStore.setState({
        average_rank:
          sum > rank_groups?.length
            ? Number(sum) / Number(rank_groups?.length)
            : 0,
      });
      setCompanyRank({ rank: sum, keywords: rank_groups?.length });
    }
  };

  useEffect(() => {
    keywordAverage();
  }, [keyworddata, topRankData, chartImprData]);

  useEffect(() => {
    setOldAverageLabel([]);
    oldAverageRank.current = []; // Clear the array
    filtered_data &&
      filtered_data
        .slice(filtered_data?.length - lastDate, filtered_data?.length)
        .forEach((res) => {
          let oldCompanyRankValue = 0;
          if (res?.data?.length > 0) {
            res?.data &&
              res?.data?.forEach((item) => {
                if (item?.rank_group !== "No Rank") {
                  oldCompanyRankValue += item?.rank_group;
                }
              });
            oldAverageRank.current.push(oldCompanyRankValue / res.data.length);
            setOldAverageLabel((obj) => [...obj, res.date + " " + res.month]);
          }
        });
  }, [filtered_data, selectCustomDate]);

  const last_data_date =
    selectedprojectnewdata[0]?.date + " " + selectedprojectnewdata[0]?.month;

  const labels = oldAverageLabel.concat(last_data_date);
  sessionStorage.setItem("rankTrackingLabel", JSON.stringify(labels));
  const chart_rank_data =
    companyRank?.rank >= companyRank?.keywords
      ? oldAverageRank?.current?.concat(
          Number(companyRank?.rank) / Number(companyRank?.keywords)
        )
      : oldAverageRank?.current;
  // Filter out NaN values
  let dataData = chart_rank_data?.filter((value) => !isNaN(value));
  sessionStorage.setItem("rankTrackingData", JSON.stringify(dataData));

  let chart_data = {
    data: dataData,
    location: location,
    all_loc: AllLocation,
    labels: labels,
    loc_src: getCurrentCountryDetails(location),
  };

  const handleAddQuery = () => {
    setQueryParams({ domain: websiteurl });
  };

  const handleRemoveQuery = () => {
    removeQueryParams(["domain"]);
  };

  useEffect(() => {
    if (websiteurl) {
      handleAddQuery();
    } else {
      handleRemoveQuery();
    }
  }, [websiteurl]);

  const getDoughnutChartData = () => {
    // setActiveTab("")
    setRankGroups({
      top3: [],
      top10: [],
      top100: [],
      noRank: [],
    });
    rank_keyword?.forEach((res) => {
      if (res.rank === "No Rank") {
        setRankGroups((prev) => ({
          ...prev,
          noRank: [...prev.noRank, res.rank],
        }));
      }
      if (res.rank <= 3) {
        setRankGroups((prev) => ({
          ...prev,
          top3: [...prev.top3, res.rank],
        }));
      }
      if (res.rank <= 10) {
        setRankGroups((prev) => ({
          ...prev,
          top10: [...prev.top10, res.rank],
        }));
      }
      if (res.rank <= 100) {
        setRankGroups((prev) => ({
          ...prev,
          top100: [...prev.top100, res.rank],
        }));
      }
    });
  };

  useEffect(() => {
    getDoughnutChartData();
  }, [rank_keyword]);

  const resetRankFilteredData = () => {
    dispatch({
      type: "CHARTIMPRDATA",
      payload: { data: [], chart_show: false },
    });
    dispatch({
      type: "TOPRANKDATA",
      payload: { data: [], top_rank: false, type: "", competitors: false },
    });
    dispatch({
      type: "SHOWLOADER",
      payload: true,
    });
    setTimeout(() => {
      dispatch({
        type: "SHOWLOADER",
        payload: false,
      });
    }, 1000);
  };

  const handleRankStatus = (status) => {
    if (active_tab_rank != "" && active_tab_rank === status) {
      ZustandStore.setState({ active_tab_rank: "" });
      resetRankFilteredData();
      return;
    }
    ZustandStore.setState({ active_tab_rank: status });

    dispatch({
      type: "CHARTIMPRDATA",
      payload: { data: [], chart_show: true },
    });
    dispatch({
      type: "TOPRANKDATA",
      payload: { data: [], top_rank: false, type: status, competitors: false },
    });
    dispatch({
      type: "SHOWLOADER",
      payload: true,
    });
    setTimeout(() => {
      dispatch({
        type: "SHOWLOADER",
        payload: false,
      });
    }, 1000);
    setTimeout(() => {
      ScrollToView("rank-tracking-table");
    }, 500);
    if (
      location_path.pathname === "/rank-tracker" ||
      location_path.pathname === "/guest/rank-tracker"
    )
      return;
    navigate("/rank-tracker");
  };

  const lastUpdatedTime =
    selectedprojectnewdata[0]?.month + " " + selectedprojectnewdata[0]?.date;

  const handleTopKeywordRanks = (ranks, type) => {
    if (active_tab_rank != "" && active_tab_rank === type) {
      ZustandStore.setState({ active_tab_rank: "" });
      resetRankFilteredData();
      return;
    }
    ZustandStore.setState({ active_tab_rank: type });
    dispatch({
      type: "CHARTIMPRDATA",
      payload: { data: [], chart_show: false },
    });
    dispatch({
      type: "TOPRANKDATA",
      payload: { data: ranks, top_rank: true, type: "", competitors: false },
    });
    dispatch({
      type: "SHOWLOADER",
      payload: true,
    });
    setTimeout(() => {
      dispatch({
        type: "SHOWLOADER",
        payload: false,
      });
    }, 1000);
    setTimeout(() => {
      ScrollToView("rank-tracking-table");
    }, 500);
    if (
      location_path.pathname === "/rank-tracker" ||
      location_path.pathname === "/guest/rank-tracker"
    )
      return;
    navigate("/rank-tracker");
  };

  return (
    <main className="rank-tracking-container main-pd">
      <section className="project-selection">
        <div className="row p-0">
          <div className="col-lg-12 col-md-12">
            <div className="project-container">
              <div className="project-header">
                <h1 className="">Show Ranking For</h1>
                {selectedprojectnewdata.length > 0 ? (
                  <div className="project-last-updated">
                    Last Updated :<span>{lastUpdatedTime}</span>
                  </div>
                ) : null}
              </div>
              <p>Select to get the detailed Rank Tracking of your project</p>
              <div className="row">
                <div
                  className="col-lg-8 col-md-10"
                  style={{ maxWidth: "870px" }}
                >
                  <div className="project-drop-down">
                    <ProjectList
                      style={{
                        width: "57%",
                        maxWidth: "620px",
                        height: "50px",
                        border: "1px solid #0000004D",
                        textTransform: "none",
                        boxShadow: "none",
                        padding: "1.1em 1em",
                        borderRadius: "5px",
                        color: "#000000",
                      }}
                    />
                    <ProjectLocation
                      style={{
                        width: "40%",
                        maxWidth: "350px",
                        height: "50px",
                        border: "1px solid #0000004D",
                        textTransform: "none",
                        boxShadow: "none",
                        padding: "1.1em 1em",
                        borderRadius: "5px",
                        color: "#000000",
                      }}
                    />
                  </div>
                </div>
                <div className="col-auto mt-3 mt-md-0 mt-lg-0 ps-md-0">
                  {location_path.pathname === "/rank-tracker" ? (
                    <div className="d-flex align-items-center h-100">
                      <Link to="/addpr">
                        <button
                          className="icon-btn"
                          title="Add Project"
                          onClick={() => {
                            localStorage.removeItem("ProjectsDetail");
                            localStorage.removeItem("Locationdetails");
                            localStorage.removeItem("CompetitorsDetails");
                          }}
                        >
                          <img src={addIcon} alt="add-project" />
                        </button>
                      </Link>
                      <Button
                        variant="contained"
                        className="ms-3"
                        title="Share Link"
                        onClick={() => setAnchorEl(true)}
                      >
                        Guest Link
                      </Button>
                    </div>
                  ) : null}
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <>
        {showLoader ? (
          <div className="rank-loader">
            <Spinner />
          </div>
        ) : (
          <>
            <section className="row rank-selection gy-2">
              <div className="col-auto col-xl-7 ps-0">
                <div className="top-rank-btn">
                  <button
                    className={
                      active_tab_rank === "Top 3"
                        ? "bg-btn"
                        : "transparent-btn-2"
                    }
                    onClick={() => handleTopKeywordRanks(top3, "Top 3")}
                  >
                    Top 3 <span className="ms-1">({top3?.length || 0})</span>
                  </button>
                  <button
                    className={
                      active_tab_rank === "Top 10"
                        ? "bg-btn"
                        : "transparent-btn-2"
                    }
                    onClick={() => handleTopKeywordRanks(top10, "Top 10")}
                  >
                    Top 10 <span className="ms-1">({top10?.length || 0})</span>
                  </button>
                  <button
                    className={
                      active_tab_rank === "Top 100"
                        ? "bg-btn"
                        : "transparent-btn-2"
                    }
                    onClick={() => handleTopKeywordRanks(top100, "Top 100")}
                  >
                    Top 100{" "}
                    <span className="ms-1">({top100?.length || 0})</span>
                  </button>
                  <button
                    className={
                      active_tab_rank === "No Rank"
                        ? "bg-btn"
                        : "transparent-btn-2"
                    }
                    onClick={() => handleTopKeywordRanks(noRank, "No Rank")}
                  >
                    No Rank{" "}
                    <span className="ms-1">({noRank?.length || 0})</span>
                  </button>
                  <button
                    className={
                      active_tab_rank === "Improved"
                        ? "bg-btn"
                        : "transparent-btn-2"
                    }
                    onClick={() => handleRankStatus("Improved")}
                  >
                    Improved{" "}
                    <span className="ms-1" style={{ color: "#238654" }}>
                      ({improved?.length || 0})
                    </span>
                  </button>
                  <button
                    className={
                      active_tab_rank === "Declined"
                        ? "bg-btn"
                        : "transparent-btn-2"
                    }
                    onClick={() => handleRankStatus("Declined")}
                  >
                    Declined{" "}
                    <span className="ms-1" style={{ color: "red" }}>
                      ({declined?.length || 0})
                    </span>
                  </button>
                </div>
              </div>
              <div className="col-auto col-xl-5 pe-0 ps-0 ps-lg-3">
                <LastUpdate />
              </div>
            </section>

            <Suspense fallback={null}>
              <AverageChart
                chart_data={chart_data}
                handleChangeLocation={languageHandler}
              />
            </Suspense>
            <RankTablewithSorting />
          </>
        )}
      </>
      <CopyLinkDialog
        {...{
          anchorEl,
          setAnchorEl,
        }}
      />
    </main>
  );
};

export default RankTracking;
