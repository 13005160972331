import React, { useState } from "react";
import "../../css/login.css";
import logi from "../../Assets/loginSignup.png";
import logo from "../../Assets/seoimg/logo.png";
import { Link, useNavigate } from "react-router-dom";
import {
  forgot_withApi,
  reset_password_withApi,
} from "../../../services/constants";
import axios from "axios";
import { useEffect } from "react";
import { baseUrlPath } from "../../../Navigation/constant";
import { ReCaptcha } from "../../share/components/googleRecaptacha";
import Footer from "../../share/footer";
import "./login.scss";
import { Player } from "@lottiefiles/react-lottie-player";
import animationData from "../../Assets/login-animated.json";
import { useLocation } from "react-router-dom";
import { validatePassword } from "../../../helperJs/helper";
import closeEyeIcon from "../../Assets/common/close-eye.svg";
import openEyeIcon from "../../Assets/common/open-eye.svg";

const Forgot = () => {
  // redux navigator
  const location = useLocation();
  const navigate = useNavigate();
  const pathname = location?.pathname;
  // hooks variables
  const [PassSecure, setPassSecure] = useState(false);
  const [ConfirmPassSecure, setConfirmPassSecure] = useState(false);
  const [mydata, setMydata] = useState("");
  const [success, setSuccess] = useState("");
  const [Email, setEmail] = useState("");
  const [password, setPassword] = useState(null);
  const [coPassword, setCoPassword] = useState(null);
  const [varifyByMail, setVarifybymail] = useState(false);

  useEffect(() => {
    if (pathname !== "/forgot") {
      setVarifybymail(true);
    }
  }, [pathname]);

  const Forgotpass = (e) => {
    e.preventDefault();
    if (varifyByMail) {
      if (password === coPassword && password !== null && coPassword !== null) {
        const passwordError = validatePassword(password);
        if (passwordError) {
          setMydata(validatePassword(password));
          return;
        }

        const resetString = pathname?.split("/api/user/reset/")?.[1];
        const [UID, UToken] = resetString?.split("/");

        let item = {
          password: password,
          password2: coPassword,
        };

        axios
          .post(`${reset_password_withApi()}${UID}/${UToken}/`, item)
          .then(() => {
            navigate("/login");
          })
          .catch((error) => {
            console.error(error);
            setMydata(
              error?.response?.data?.non_field_errors?.[0] ||
                "Please Try After Sometimes"
            );
          });
      } else {
        setMydata("password & confirm did not match");
      }
    } else {
      if (Email === "") {
        setMydata("Please enter a correct email");
      } else {
        let item = {
          email: Email,
        };
        setMydata(false);
        axios.post(forgot_withApi(), item).then((res) => {
          setSuccess(res.data.msg);
        });
      }
    }
  };

  return (
    <>
      <div className="loginBox">
        <div className="row">
          <div
            className="col-12 col-md-6 d-none d-md-block d-lg-block"
            style={{ background: "#365486" }}
          >
            <div className="loginLeft">
              <div className="imgLogin">
                <Player
                  src={animationData}
                  className="player"
                  loop
                  autoplay
                  speed={1}
                />
              </div>
            </div>
          </div>
          <div className="col-12 col-md-6 ">
            <div className="loginRight">
              <div className="FormBox FOGOTANDRESET">
                <img className="Loginlogo" src={logo} alt="login img"></img>
                <form>
                  <div className="formLogin ">
                    <h1 className="text-left mb-4">
                      {varifyByMail === null
                        ? "Forgot Password"
                        : "Reset Your Password"}
                    </h1>
                    {varifyByMail ? (
                      <div>
                        <label id="lb">Password</label>
                        <div className="password-type">
                          <input
                            type={PassSecure ? "text" : "password"}
                            placeholder="Password"
                            onChange={(e) => setPassword(e.target.value)}
                            autoComplete="true"
                          ></input>
                          {PassSecure ? (
                            <img
                              src={closeEyeIcon}
                              alt=""
                              onClick={() => setPassSecure(false)}
                            />
                          ) : (
                            <img
                              src={openEyeIcon}
                              alt=""
                              onClick={() => setPassSecure(true)}
                            />
                          )}
                        </div>
                        <label id="lb">Confirm Password</label>
                        <div className="password-type">
                          <input
                            type={ConfirmPassSecure ? "text" : "password"}
                            placeholder="Confirm Password"
                            onChange={(e) => setCoPassword(e.target.value)}
                            autoComplete="true"
                          ></input>
                          {ConfirmPassSecure ? (
                            <img
                              src={closeEyeIcon}
                              alt=""
                              onClick={() => setConfirmPassSecure(false)}
                            />
                          ) : (
                            <img
                              src={openEyeIcon}
                              alt=""
                              onClick={() => setConfirmPassSecure(true)}
                            />
                          )}
                        </div>
                        {mydata ? (
                          <p className="vl-msd-line-login mb-0">{mydata}</p>
                        ) : (
                          ""
                        )}
                      </div>
                    ) : (
                      <div>
                        {" "}
                        <label id="lb">Email</label>
                        <input
                          type="text"
                          placeholder="Email"
                          onChange={(e) => setEmail(e.target.value)}
                        ></input>
                        {mydata ? (
                          <p className="vl-msd-line-login mb-0">{mydata}</p>
                        ) : success ? (
                          <p className="msg-success mb-0">{success}</p>
                        ) : (
                          ""
                        )}
                      </div>
                    )}
                    <div>
                      <button
                        type="submit"
                        className="login_btn  w-100 FOGOTANDRESETSUBMIT"
                        onClick={Forgotpass}
                      >
                        Submit
                      </button>
                      <h6 className="rs-now font-regular">
                        Don't have an account ?{" "}
                        <Link
                          to="/register"
                          style={{ color: "#E95655" }}
                          className="ms-1"
                        >
                          {" "}
                          Register Now
                        </Link>
                      </h6>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
        <Footer />
      </div>
      <ReCaptcha />
    </>
  );
};

export default Forgot;
