import DashboardIcon from "@mui/icons-material/Dashboard";
import GroupsIcon from "@mui/icons-material/Groups";
import SearchIcon from "@mui/icons-material/Search";
import LocalOfferIcon from "@mui/icons-material/LocalOffer";
import QuestionAnswerIcon from "@mui/icons-material/QuestionAnswer";
import MailIcon from "@mui/icons-material/Mail";
import { MdOutlineAttachMoney, MdOutlineDataUsage } from "react-icons/md";
import { GiDigitalTrace } from "react-icons/gi";
import { BiMoneyWithdraw } from "react-icons/bi";
import { FaUserTie } from "react-icons/fa";
import ControlPointDuplicateIcon from "@mui/icons-material/ControlPointDuplicate";

import projectSvg from "../sidebar/project.svg";
import dashboardSvg from "../sidebar/dashboard.svg";
import siteAuditSvg from "../sidebar/site-audit.svg";
import rankTrackingSvg from "../sidebar/rank.svg";
import keywordSvg from "../sidebar/keyword.svg";
import siteOverviewSvg from "../sidebar/site-overview.svg";
import aiWriterSvg from "../sidebar/ai-writer.svg";
import backlinkSvg from "../sidebar/backlink.svg";

export const adminNavItem = [
  {
    id: "1",
    name: "Dashboard",
    url: "../admin",
    path: ["/admin/"],
    icon: <DashboardIcon />,
  },
  {
    id: "2",
    name: "Users",
    url: "users",
    path: ["/admin/users"],
    icon: <GroupsIcon />,
  },
  {
    id: "3",
    url: "adminkeywords",
    name: "Keywords",
    path: ["/admin/adminkeywords"],
    icon: <SearchIcon />,
  },
  {
    id: "4",
    url: "plans",
    path: ["/admin/plans"],
    name: "Plans",
    icon: <i className="fa-brands fa-ideal heading-medium" />,
  },
  {
    id: "4",
    url: "addon-plans",
    path: ["/admin/addon-plans"],
    name: "Addon",
    icon: <ControlPointDuplicateIcon />,
  },
  {
    id: "5",
    url: "codes",
    path: ["/admin/codes"],
    name: "Codes",
    icon: <LocalOfferIcon />,
  },
  {
    id: "6",
    url: "mail",
    name: "Mail",
    path: ["/admin/mail"],
    icon: <MailIcon />,
  },
  {
    id: "7",
    url: "invite-mail",
    path: ["/admin/invite-mail"],
    name: "Invite",
    icon: <i className="fa-solid fa-link heading-medium" />,
  },
  {
    id: "8",
    url: "feedback",
    name: "Feedback",
    path: ["/admin/feedback"],
    icon: <QuestionAnswerIcon />,
  },
  {
    id: "9",
    url: "free-tool-usage",
    name: "Tool Usage",
    path: [
      "/admin/free-tool-usage",
      "/admin/free-tool-rank-tracker",
      "/admin/free-tool-page-vital",
      "/admin/free-tool-backlink",
    ],
    icon: <MdOutlineDataUsage />,
  },
  {
    id: "10",
    url: "affiliate-list",
    name: "Affilates",
    path: ["/admin/affiliate-list"],
    icon: <FaUserTie />,
  },
  {
    id: "11",
    url: "affiliate-reward",
    name: "Rewards",
    path: ["/admin/affiliate-reward"],
    icon: <MdOutlineAttachMoney />,
  },
  {
    id: "12",
    url: "affiliate-withdrawal-request",
    path: ["/admin/affiliate-withdrawal-request"],
    name: "Withdrawal Request",
    icon: <BiMoneyWithdraw />,
  },
  {
    id: "13",
    url: "digital-agency",
    path: ["/admin/digital-agency", "/admin/details", "/admin/reviews"],
    name: "Digital Agency",
    icon: <GiDigitalTrace />,
  },
];

export const userNavItem = [
  {
    url: "/dashboard",
    path: [
      "/dashboard",
      "/addpr",
      "/addpr/addcountry",
      "/addpr/addcompetitors",
      "/addpr/addkeyword",
    ],
    name: "Dashboard",
    icon: dashboardSvg,
  },
  {
    url: "/projects",
    path: ["/projects"],
    name: "Projects",
    icon: projectSvg,
  },
  {
    url: "rank-tracker",
    path: ["/rank-tracker"],
    name: "Rank Tracking",
    icon: rankTrackingSvg,
  },
  {
    url: "site-audit",
    path: ["/site-audit"],
    name: "Site Audit",
    icon: siteAuditSvg,
  },
  {
    name: "Keyword Research",
    path: ["/keyword-research", "/view-lists"],
    url: "/keyword-research",
    icon: keywordSvg,
  },
  {
    name: "Site Overview",
    url: "/site-overview",
    path: ["/site-overview"],
    icon: siteOverviewSvg,
  },
  {
    name: "Backlinks",
    url: "#",
    path: ["/backlinks", "/backlink-gap"],
    sub_list: [
      {
        name: "Backlink Analysis",
        url: "/backlinks",
        path: ["/backlinks"],
      },
      {
        name: "Backlink Gap",
        url: "/backlink-gap",
        path: ["/backlink-gap"],
      },
    ],
    icon: backlinkSvg,
  },
  {
    name: "AI Writers",
    url: "/ai/content/projects",
    path: ["/ai/content/projects"],
    icon: aiWriterSvg,
  },
];
