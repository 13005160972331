import React from "react";
import {
  Page,
  Document,
  Image,
  View,
  Text,
  Link,
  StyleSheet,
  Font,
} from "@react-pdf/renderer";
import Table from "./table";
import defaultLogo from "../../Assets/seoimg/logo.png";
import { getCurrentYear } from "../helper-js/current-year";
import { LuCopyright } from "react-icons/lu";
import upArrowIcon from "../../Assets/pdf/green.png";
import downArrowIcon from "../../Assets/pdf/red.png";
import noArrowIcon from "../../Assets/pdf/gray.png";
import fontSrc from "../../font/Roboto/Roboto-Regular.ttf";
import fontSrc2 from "../../font/Roboto/Roboto-Light.ttf";
import fontSrc3 from "../../font/Roboto/Roboto-Medium.ttf";
import fontSrc4 from "../../font/Roboto/Roboto-Bold.ttf";

Font.register({
  family: "Roboto",
  fonts: [
    { src: fontSrc, fontStyle: "normal", fontWeight: 400 },
    { src: fontSrc2, fontStyle: "normal", fontWeight: 300 },
    { src: fontSrc3, fontStyle: "normal", fontWeight: 500 },
    { src: fontSrc4, fontStyle: "normal", fontWeight: 700 },
  ],
});

const styles = StyleSheet.create({
  page: {
    flexDirection: "column",
    backgroundColor: "#FFFFFF",
    paddingTop: 35,
    paddingBottom: 65,
    paddingHorizontal: 35,
  },
  header: {
    color: "#365486",
    fontWeight: "bold",
  },
  tableContainer: {
    marginBottom: 20,
  },
  table: {
    width: "100%",
    borderStyle: "solid",
    borderWidth: 1,
    display: "flex", // Use flex to create a flexible row layout
    flexDirection: "column", // Each table row is a flex column
  },
  tableRow: {
    display: "flex",
    flexDirection: "row", // Each cell is a flex item in a row
    borderBottomWidth: 1,
    borderColor: "#000",
    alignItems: "center",
  },
  keywordLoc: {
    flex: 1, // Each cell should take equal space within the row
    padding: 5,
    borderRightWidth: 1,
    borderColor: "#000",
    justifyContent: "start",
    minHeight: 40,
    height: "100%",
    alignItems: "start",
    flexDirection: "column",
  },
  tableCell: {
    flex: 1, // Each cell should take equal space within the row
    padding: 5,
    borderRightWidth: 1,
    borderColor: "#000",
    justifyContent: "center",
    minHeight: 40,
    height: "100%",
    alignItems: "center",
    flexDirection: "row",
  },
  fixedWidthCell: {
    maxWidth: 80, // Disable flex for fixed width cells
  },
  cardContainer: {
    flexDirection: "row",
    flexWrap: "wrap", // Enable flex wrapping
    justifyContent: "space-between", // Arrange cards with equal spacing
    marginTop: 10,
  },
  card: {
    flexDirection: "row",
    justifyContent: "space-between",
    width: 180,
    backgroundColor: "#FFFFFF",
    padding: 10,
    borderRadius: 5,
    marginBottom: 20,
  },
  image: {
    width: 30,
    height: 40,
  },
  title: {
    fontFamily: "Roboto",
    fontSize: 11,
    fontWeight: "bold",
    marginBottom: 5,
    color: "#6c757d",
  },
  description: {
    fontFamily: "Roboto",
    fontSize: 16,
  },
  imageContainer: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  arrowIcon: {
    width: 20,
    height: 20,
    objectFit: "contain",
  },
  view_button: {
    fontFamily: "Roboto",
    borderWidth: 1,
    borderColor: "#365486",
    padding: 5,
    borderRadius: 7,
    color: "#365486",
    fontSize: 11,
  },
  keyword_bold: {
    fontFamily: "Roboto",
    fontSize: 13,
    fontWeight: "bold",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  keyword: {
    fontFamily: "Roboto",
    fontSize: 11,
    color: "#6c757d",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  rank: {
    fontFamily: "Roboto",
    fontSize: 11,
    color: "#6c757d",
    textAlign: "center",
    marginRight: 10,
  },
  table_cell: {
    fontFamily: "Roboto",
    fontSize: 11,
    color: "#6c757d",
    textAlign: "center",
  },
  table_header: {
    fontFamily: "Roboto",
    fontSize: 11,
    textAlign: "center",
  },
  footer: {
    fontFamily: "Roboto",
    fontSize: 11,
    textAlign: "center",
    color: "grey",
    position: "absolute",
    bottom: 20,
    left: 0,
    right: 0,
  },
  pageNumber: {
    position: "absolute",
    fontFamily: "Roboto",
    fontSize: 11,
    bottom: 45,
    left: 0,
    right: 0,
    textAlign: "center",
    color: "grey",
  },
  link_text: {
    fontFamily: "Roboto",
    fontSize: 9,
    textDecoration: "none",
    textOverflow: "hidden",
    textAlign: "start",
  },
  bold: {
    fontFamily: "Roboto",
    fontSize: 12,
    fontStyle: "normal",
    fontWeight: 500,
  },
  table: {
    width: "100%",
    borderStyle: "solid",
    borderWidth: 1,
    borderColor: "#eaeaea",
    borderRadius: 8, // Added border-radius to the entire table
    overflow: "hidden", // Ensure border-radius is applied correctly
  },
  tableRow: {
    display: "flex",
    flexDirection: "row",
    borderBottomWidth: 1,
    borderColor: "#eaeaea",
    alignItems: "center",
    // borderRadius: 8, // Apply border-radius to each row
  },
  tableCell: {
    flex: 1,
    padding: 8,
    borderRightWidth: 1,
    borderColor: "#eaeaea",
    justifyContent: "center",
    minHeight: 40,
    alignItems: "center",
    flexDirection: "row",
    // borderRadius: 8, // Apply border-radius to each cell
  },
  tableHeader: {
    fontFamily: "Roboto",
    fontSize: 12,
    textAlign: "center",
    fontWeight: "bold",
    color: "#333",
    // backgroundColor: "#f8f8f8",
    borderTopLeftRadius: 8, // Added border-radius to top left corner
    borderTopRightRadius: 8, // Added border-radius to top right corner
  },
  tableCellText: {
    fontFamily: "Roboto",
    fontSize: 11,
    textAlign: "center",
    color: "#333",
  },
  linkText: {
    color: "#1a73e8",
    textDecoration: "none",
    fontSize: 11,
    fontFamily: "Roboto",
  },
  positionContainer: {
    flexDirection: "row",
    alignItems: "center",
  },
  positionText: {
    fontFamily: "Roboto",
    marginLeft: 4,
    fontSize: 11,
  },
});

function RankTrackingPdf({ table_data, data }) {
  const headers = ["Keywords", "Positions", "Previous", "Vol", "URL"];
  const combinedData = [...table_data?.sorted, ...table_data?.norank];
  const rows =
    combinedData?.map((row) => [
      <Text>
        <Text style={styles.bold}>{row?.keyword}</Text> ({row?.location})
      </Text>,
      {
        text: row.rank,
        // icon:
        //   row.color === "#000000CC"
        //     ? null
        //     : row.color === "#F42C2C"
        //     ? downArrowIcon
        //     : upArrowIcon,
      },
      row.oldrank,
      row.serachvolume,
      row.url,
    ]) || [];

  const rankHeader = ["Top 3", "Top 10", "Top 100", "No Rank"];
  const PieChtDtArr = JSON.parse(sessionStorage.getItem("PieChtDtArr"));
  const rankRows = PieChtDtArr || [];
  const lastUpdatedTime = sessionStorage.getItem("LstUpdtRnkTckgTbl");

  return (
    <Document>
      <Page size="A4" style={styles.page}>
        <View
          style={{
            height: "100%",
            margin: "auto",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <View
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              marginBottom: 15,
            }}
          >
            <Image
              src={data?.reset ? defaultLogo : data?.logo?.src}
              style={{ width: 250, height: "auto", objectFit: "contain" }}
            />
          </View>

          <Text
            style={{
              marginBottom: 5,
              fontFamily: "Roboto",
              textAlign: "center",
              fontSize: 35,
              fontWeight: 400,
            }}
          >
            Rank Tracking Report
          </Text>
        </View>
      </Page>
      <Page size="A4" style={styles.page}>
        <View
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: data?.reset ? "flex-start" : data?.logo.position,
            marginBottom: 20,
          }}
        >
          <Image
            src={data?.reset ? defaultLogo : data?.logo?.src}
            style={{ width: 170, height: "auto", objectFit: "contain" }}
          />
        </View>
        <Text
          style={{
            marginBottom: 50,
            fontFamily: "Roboto",
            textAlign: "start",
            fontSize: 18,
            fontWeight: 500,
          }}
        >
          Rank Tracking Report
        </Text>

        <View
          style={{
            flexDirection: "row",
            marginBottom: 10,
            justifyContent: "space-between",
          }}
        >
          <View style={{ flexDirection: "row" }}>
            <Text
              style={{
                fontSize: 12,
                color: "#000000E5",
                fontWeight: 400,
                fontFamily: "Roboto",
              }}
            >
              Project Name :{" "}
            </Text>
            <Text
              style={{
                fontSize: 12,
                color: "#000000",
                fontWeight: 500,
                fontFamily: "Roboto",
              }}
            >
              {table_data?.project}
            </Text>
          </View>
          <View style={{ flexDirection: "row" }}>
            <Text
              style={{
                fontSize: 12,
                color: "#000000E5",
                fontWeight: 400,
                fontFamily: "Roboto",
              }}
            >
              Date :{" "}
            </Text>
            <Text
              style={{
                fontSize: 12,
                color: "#000000",
                fontWeight: 500,
                fontFamily: "Roboto",
              }}
            >
              {lastUpdatedTime}
            </Text>
          </View>
        </View>

        <View style={{ flexDirection: "row", marginBottom: 10 }}>
          <Text
            style={{
              fontSize: 12,
              color: "#000000E5",
              fontWeight: 400,
              fontFamily: "Roboto",
            }}
          >
            Project Location :{" "}
          </Text>
          <Text
            style={{
              fontSize: 12,
              color: "#000000",
              marginLeft: 5,
              fontWeight: 500,
              fontFamily: "Roboto",
            }}
          >
            {table_data?.location?.map((location, index) => {
              return (
                <>
                  {index !== 0 ? ", " : ""}
                  {location}
                </>
              );
            })}
          </Text>
        </View>

        <View style={{ flexDirection: "row", marginBottom: 30 }}>
          <Text
            style={{
              fontSize: 12,
              color: "#000000E5",
              fontWeight: 400,
              fontFamily: "Roboto",
            }}
          >
            Device Type :{" "}
          </Text>
          <Text
            style={{
              fontSize: 12,
              color: "#000000",
              marginLeft: 5,
              textTransform: "capitalize",
              fontWeight: 500,
              fontFamily: "Roboto",
            }}
          >
            {table_data?.deviceType}
          </Text>
        </View>

        <Text style={{ fontSize: 13, marginBottom: 10, fontWeight: 700 }}>
          Top Keywords:{" "}
        </Text>
        <View style={styles.tableContainer}>
          <View style={styles.table}>
            <View style={styles.tableRow}>
              {rankHeader.map((header, index) => (
                <View
                  key={index}
                  style={[styles.tableCell, { backgroundColor: "#f8f8f8" }]}
                >
                  <Text style={styles.tableHeader}>{header}</Text>
                </View>
              ))}
            </View>
            <View style={styles.tableRow}>
              {rankRows?.map((row, rowIndex) => (
                <View key={rowIndex} style={styles.tableCell}>
                  <Text style={styles.tableCellText}>{row}</Text>
                </View>
              ))}
            </View>
          </View>
        </View>

        <Text style={{ fontSize: 13, marginBottom: 10 }}>
          Keyword Rank Table :
        </Text>
        <View style={styles.tableContainer}>
          <Table headers={headers} rows={rows} />
        </View>
        <Text style={styles.footer} fixed>
          <LuCopyright className="me-1 heading-medium" />
          {data?.reset
            ? `Copyright ${getCurrentYear} eSearch Logix | Prepared using RanksPro.io`
            : data?.footer}
        </Text>
      </Page>
    </Document>
  );
}

export default RankTrackingPdf;
