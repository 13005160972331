import React, { useState, useRef, useEffect } from "react";
import { NavLink, Link, useLocation } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { Logout } from "../../Mycomponent/share/upDater/constant";
import { useClickAway } from "react-use";
import { AnimatePresence, motion } from "framer-motion";
import { ReactComponent as AffiliateIcon } from "../assets/affiliate-icon.svg";
import CustomButtonStyle from "../../Mycomponent/share/components/button.component";
import { adminNavItem, userNavItem } from "../assets/Data/sidebar-menu-option";

// Icons
import Logo from "../assets/logo-white.png";
import logoIcon from "../assets/logo-icon.png";
import logoutSvg from "../assets/sidebar/logout.svg";
import toggleSvg from "../assets/sidebar/toggle.svg";
import { IoIosArrowDown } from "react-icons/io";
import CustomizedTooltips from "./sidebar-tooltip";
import betaImg from "../assets/sidebar/beta.png";

const SidebarMob = ({ type }) => {
  const ref = useRef(null);
  const { pathname } = useLocation();
  const dispatch = useDispatch();
  const show = useSelector((state) => state.showmenu);
  const [myArray, setMyArray] = useState([""]);

  function checkIfAdmin() {
    const email = localStorage.getItem("admin_mail");
    const isAdmin = ["info@esearchlogix.com", "singhashish1.sh@gmail.com"];
    return isAdmin.includes(email);
  }

  const addUniqueStringToArray = (menu) => {
    if (menu?.sub_list?.length === 0) return;
    let newString = menu.name;
    if (!myArray.includes(newString)) {
      setMyArray([...myArray, newString]);
    } else {
      const updatedArray = myArray.filter((item) => item !== newString);
      setMyArray(updatedArray);
    }
  };

  useClickAway(ref, () => {
    setMyArray([""]);
    dispatch({ type: "SHOWMENU", payload: false });
  });

  return (
    <AnimatePresence>
      <div className="side-bar-mobile">
        <motion.div
          {...framerSidebarBackground}
          aria-hidden="true"
          className="setBackRop"
        ></motion.div>
        <motion.div
          {...framerSidebarPanel}
          className="childBack sideBar_list"
          ref={ref}
          aria-label="Sidebar"
        >
          <div className="nv-logo" style={{ height: "12%" }}>
            <Link to="/dashboard">
              <img src={Logo} alt="img" />
            </Link>
          </div>
          <ul>
            {type === "admin"
              ? adminNavItem?.map((item, index) => {
                  const isActive = item?.path?.includes(pathname);

                  return (
                    <li
                      className="heading-small font-regular"
                      key={`admin-sidebar-item-${item.name}-sm`}
                    >
                      {item?.name === "Dashboard" ? (
                        <Link
                          to={item.url}
                          className={pathname === "/admin" ? "active" : ""}
                        >
                          <motion.div {...framerIcon} className="list-icon">
                            {/* <img src={item?.icon} alt={item.name} /> */}
                            {item?.icon}
                          </motion.div>

                          <motion.span
                            {...framerText(index)}
                            className="sidebar-list-item"
                          >
                            {item.name}
                          </motion.span>
                        </Link>
                      ) : (
                        <NavLink
                          to={item.url}
                          className={isActive ? "active" : ""}
                        >
                          <motion.div {...framerIcon} className="list-icon">
                            {/* <img src={item?.icon} alt={item.name} /> */}
                            {item?.icon}
                          </motion.div>

                          <motion.span
                            {...framerText(index)}
                            className="sidebar-list-item"
                          >
                            {item.name}
                          </motion.span>
                        </NavLink>
                      )}
                    </li>
                  );
                })
              : userNavItem?.map((item, index) => {
                  if (item?.name === "AI Writers" && !checkIfAdmin())
                    return null;
                  const isActive = item?.path?.includes(pathname);
                  const hasSubList = item?.sub_list?.length > 0;
                  const isSubListOpen = myArray?.includes(item.name);

                  return (
                    <li key={`user-nav-sidebar-item-${item.name}-sm`}>
                      {hasSubList ? (
                        <div
                          className={
                            isActive ? "active anchor-elem" : "anchor-elem"
                          }
                          onClick={() => addUniqueStringToArray(item)}
                          key={`user-nav-sidebar-item-${item.name}-sm`}
                        >
                          <motion.div {...framerIcon} className="list-icon">
                            <img src={item?.icon} alt={item.name} />
                          </motion.div>

                          <motion.span
                            {...framerText(index)}
                            className="sidebar-list-item"
                          >
                            {item.name}
                            {hasSubList && (
                              <IoIosArrowDown
                                style={{
                                  rotate: isSubListOpen ? "180deg" : "0deg",
                                  marginLeft: "5px",
                                  width: "18px",
                                  height: "auto",
                                }}
                              />
                            )}
                          </motion.span>
                        </div>
                      ) : (
                        <NavLink
                          to={item.url}
                          className={isActive ? "active" : ""}
                          key={`user-nav-sidebar-item-${item.name}-sm`}
                        >
                          <motion.div {...framerIcon} className="list-icon">
                            <img src={item?.icon} alt={item.name} />
                          </motion.div>

                          <motion.span
                            {...framerText(index)}
                            className="sidebar-list-item"
                          >
                            {item.name}
                            {item.name === "Site Audit" ? (
                              <img
                                src={betaImg}
                                alt="beta"
                                className="ms-1"
                                style={{
                                  width: "40px",
                                  objectFit: "contain",
                                }}
                              />
                            ) : null}
                            {hasSubList && (
                              <IoIosArrowDown
                                style={{
                                  rotate: isSubListOpen ? "180deg" : "0deg",
                                  marginLeft: "5px",
                                  width: "18px",
                                  height: "auto",
                                }}
                              />
                            )}
                          </motion.span>
                        </NavLink>
                      )}
                      {hasSubList && isSubListOpen ? (
                        <ul className="sidebar_menu">
                          {item?.sub_list?.map((item) => {
                            return (
                              <li key={item.name}>
                                <NavLink
                                  to={item.url}
                                  className={isActive ? "active" : ""}
                                >
                                  <motion.span
                                    initial={{
                                      opacity: 0,
                                      x: -50,
                                      display: "none",
                                    }}
                                    animate={{
                                      opacity: 1,
                                      x: 0,
                                      display: show ? "flex" : "none",
                                    }}
                                    transition={{
                                      delay: 0.5,
                                    }}
                                    className="sidebar-list-item"
                                  >
                                    {item.name}
                                  </motion.span>
                                </NavLink>
                              </li>
                            );
                          })}
                        </ul>
                      ) : null}
                    </li>
                  );
                })}
          </ul>
          <Link to={"https://app.rankspro.io/affiliate"}>
            <CustomButtonStyle
              className="sidebar_aff"
              color="#FFFFFF"
              borderColor="#e95655"
              name={
                <span
                  style={{
                    marginLeft: "10px",
                  }}
                >
                  Become An Affiliate
                </span>
              }
              bgColor="#e95655"
              hover="#e95655"
              variant="contained"
              hoverColor="white"
              startIcon={<AffiliateIcon />}
            ></CustomButtonStyle>
          </Link>
          <div className="sidebar-logout">
            <div className="log_out" onClick={Logout}>
              <motion.div {...framerIcon} className="list-icon">
                <img src={logoutSvg} alt={`logout-icon`} />
              </motion.div>
              <motion.span {...framerText(4)} className="sidebar-list-item">
                Logout
              </motion.span>
            </div>
          </div>
        </motion.div>
      </div>
    </AnimatePresence>
  );
};

const framerSidebarBackground = {
  initial: { transform: "scale(0) ", transformOrigin: "left" },
  animate: { transform: "scale(1) ", transformOrigin: "left" },
  exit: {
    transform: "scale(0) ",
    transition: { delay: 0.2 },
    transformOrigin: "left",
  },
  transition: { duration: 0.3 },
};

const framerSidebarPanel = {
  initial: { transform: "scale(0) ", transformOrigin: "left" },
  animate: { transform: "scale(1) ", transformOrigin: "left" },
  exit: { transform: "scale(0) ", transformOrigin: "left" },
  transition: { duration: 0.3 },
};

const framerText = (delay) => {
  return {
    initial: { opacity: 0, x: -50, display: "none" },
    animate: { opacity: 1, x: 0, display: "flex" },
    transition: {
      delay: 0.5 + delay / 10,
    },
  };
};

const framerIcon = {
  initial: { scale: 0 },
  animate: { scale: 1 },
  transition: {
    type: "spring",
    stiffness: 260,
    damping: 20,
    delay: 1.5,
  },
};

const SideBar = ({ type }) => {
  const show = useSelector((state) => state.showmenu);
  const { pathname } = useLocation();
  const [toggle, setToggle] = useState(false);
  const [hover, setHover] = useState(false);
  const [myArray, setMyArray] = useState([""]);

  function checkIfAdmin() {
    const email = localStorage.getItem("admin_mail");
    const isAdmin = ["info@esearchlogix.com", "singhashish1.sh@gmail.com"];
    return isAdmin.includes(email);
  }

  const addUniqueStringToArray = (e, menu) => {
    if (menu?.sub_list?.length === 0) return;
    let newString = menu.name;
    if (!myArray.includes(newString)) {
      setMyArray([...myArray, newString]);
    } else {
      const updatedArray = myArray.filter((item) => item !== newString);
      setMyArray(updatedArray);
    }
  };

  useEffect(() => {
    if (show) {
      document.body.style.overflow = "hidden";
    } else {
      document.body.style.overflow = "";
    }
  }, [show]);

  return (
    <>
      <AnimatePresence>
        <div className="sidebar-container">
          <motion.div
            aria-hidden="true"
            className="side-bar"
            animate={{ width: toggle ? "220px" : "80px" }}
            transition={{ type: "spring", duration: 0.7 }}
          >
            <motion.div
              className="sideBar_list"
              animate={{ width: hover ? "220px" : "100%" }}
              transition={{ type: "spring", duration: 0.5 }}
            >
              <div className="menu-toggle-btn">
                <motion.img
                  src={toggleSvg}
                  alt="toggleSvg"
                  style={{ rotate: toggle || hover ? "180deg" : "0deg" }}
                  className="close-toggle"
                  onClick={() => {
                    setMyArray([""]);
                    setToggle(!toggle);
                  }}
                />
              </div>
              <div className={"toogle-sidebar"}>
                {toggle || hover ? (
                  <div className="nv-logo">
                    <Link to="/dashboard">
                      <motion.img
                        src={Logo}
                        alt="RanksPro.io"
                        {...framerSidebarPanel}
                      />
                    </Link>
                  </div>
                ) : (
                  <div className="nv-logo-sm">
                    <Link to="/dashboard">
                      <motion.img
                        src={logoIcon}
                        alt="RanksPro.io"
                        {...framerSidebarPanel}
                      />
                    </Link>
                  </div>
                )}
              </div>
              <div
                style={{
                  padding: "1em 0",
                  height: "85%",
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "space-between",
                }}
                onMouseEnter={() => setHover(true)}
                onMouseLeave={() => {
                  !toggle && setMyArray([""]);
                  setHover(false);
                }}
              >
                <ul>
                  {type === "admin"
                    ? adminNavItem?.map((item, index) => {
                        const isActive = item?.path?.includes(pathname);

                        return (
                          <li
                            className="heading-small font-regular"
                            key={`admin-sidebar-item-${item.name}`}
                          >
                            {item?.name === "Dashboard" ? (
                              <Link
                                to={item.url}
                                className={
                                  pathname === "/admin" ? "active" : ""
                                }
                              >
                                <motion.div
                                  {...framerIcon}
                                  className="list-icon"
                                >
                                  {item?.icon}
                                </motion.div>

                                <motion.span
                                  {...framerText(index)}
                                  className="sidebar-list-item"
                                >
                                  {toggle || hover ? item.name : null}
                                </motion.span>
                              </Link>
                            ) : (
                              <NavLink
                                to={item.url}
                                className={isActive ? "active" : ""}
                              >
                                <motion.div
                                  {...framerIcon}
                                  className="list-icon"
                                >
                                  {item?.icon}
                                </motion.div>
                                <motion.span
                                  {...framerText(index)}
                                  className="sidebar-list-item"
                                >
                                  {toggle || hover ? item.name : null}
                                </motion.span>
                              </NavLink>
                            )}
                          </li>
                        );
                      })
                    : userNavItem?.map((item, index) => {
                        if (item?.name === "AI Writers" && !checkIfAdmin())
                          return null;
                        const isActive = item?.path?.includes(pathname);
                        const hasSubList = item?.sub_list?.length > 0;
                        const isSubListOpen = myArray?.includes(item.name);

                        return (
                          <React.Fragment
                            key={`user_nav-sidebar-fragment-${item.name}`}
                          >
                            {hasSubList ? (
                              <li
                                key={`user_nav-sidebar-item-${item.name}`}
                                className="sidebar_menu_lg"
                              >
                                <div
                                  className={
                                    isActive
                                      ? "active anchor-elem"
                                      : "anchor-elem"
                                  }
                                  style={{ cursor: "pointer" }}
                                  onClick={(e) =>
                                    addUniqueStringToArray(e, item)
                                  }
                                >
                                  <motion.div
                                    {...framerIcon}
                                    className="list-icon"
                                  >
                                    <img src={item?.icon} alt={item.name} />
                                  </motion.div>
                                  <motion.span
                                    {...framerText(index)}
                                    className="sidebar-list-item"
                                  >
                                    {toggle || hover ? item.name : null}
                                  </motion.span>
                                  {item?.sub_list?.length > 0 && (
                                    <IoIosArrowDown
                                      style={{
                                        rotate: myArray?.includes(item.name)
                                          ? "180deg"
                                          : "0deg",
                                        marginLeft: "5px",
                                        width: "18px",
                                        height: "auto",
                                      }}
                                    />
                                  )}
                                </div>
                                {item?.sub_list?.length > 0 &&
                                myArray?.includes(item.name) ? (
                                  <ul className="sidebar_menu">
                                    {item?.sub_list?.map((item) => {
                                      return (
                                        <li key={item.name}>
                                          <NavLink
                                            to={item.url}
                                            className={
                                              item?.path?.includes(pathname)
                                                ? "active"
                                                : ""
                                            }
                                          >
                                            <span className="sidebar-list-item">
                                              {item.name}
                                            </span>
                                          </NavLink>
                                        </li>
                                      );
                                    })}
                                  </ul>
                                ) : null}
                              </li>
                            ) : (
                              <li
                                key={`user_nav-sidebar-item-${item.name}`}
                                className="sidebar_menu_lg"
                              >
                                <NavLink
                                  to={item.url}
                                  className={isActive ? "active" : ""}
                                  onClick={() => setMyArray([""])}
                                >
                                  <motion.div
                                    {...framerIcon}
                                    className="list-icon"
                                  >
                                    <img src={item?.icon} alt={item.name} />
                                  </motion.div>
                                  <motion.span
                                    {...framerText(index)}
                                    className="sidebar-list-item"
                                  >
                                    {toggle || hover ? (
                                      <>
                                        {item.name}
                                        {item.name === "Site Audit" ? (
                                          <img
                                            src={betaImg}
                                            alt="beta"
                                            className="ms-1"
                                            style={{
                                              width: "40px",
                                              objectFit: "contain",
                                            }}
                                          />
                                        ) : null}
                                      </>
                                    ) : null}
                                  </motion.span>
                                </NavLink>
                              </li>
                            )}
                          </React.Fragment>
                        );
                      })}
                </ul>
                <Link
                  to={"https://app.rankspro.io/affiliate"}
                  style={{
                    padding: "0 1em",
                  }}
                >
                  <CustomButtonStyle
                    className="sidebar_aff"
                    color="#FFFFFF"
                    borderColor="#e95655"
                    name={
                      toggle || hover ? (
                        <span
                          className={"animated-text"}
                          style={{
                            marginLeft: "10px",
                          }}
                        >
                          Become An Affiliate
                        </span>
                      ) : null
                    }
                    bgColor="#e95655"
                    hover="#e95655"
                    variant="contained"
                    hoverColor="white"
                    startIcon={<AffiliateIcon />}
                  ></CustomButtonStyle>
                </Link>
                <div className="sidebar-logout">
                  <div className="log_out" onClick={Logout}>
                    <motion.div {...framerIcon} className="list-icon">
                      <img src={logoutSvg} alt={`logout-icon`} />
                    </motion.div>
                    <motion.span
                      {...framerText(4)}
                      className="sidebar-list-item"
                    >
                      {toggle || hover ? "Logout" : null}
                    </motion.span>
                  </div>
                </div>
              </div>
            </motion.div>
          </motion.div>
        </div>
      </AnimatePresence>

      {show && (
        <div className="side-bar-mobile-container">
          <SidebarMob type={type} />
        </div>
      )}
    </>
  );
};

export default React.memo(SideBar);
