import React from "react";
import { Delete } from "@mui/icons-material";

export const AddListModal = ({
  setKeyWordLenErr,
  keyWordLenErr,
  DeleteLoading,
  HandleDeleteList,
  ListLimitError,
  ListError,
  HandleOpenModalAddList,
  HandleAddKeywordList,
  handleGetListData,
  addListModal,
  ViewAllLists,
  setaddlistModal,
  addNewListModal,
}) => {
  const AddColor = (id) => {
    const Elem = document.getElementsByClassName("ListName");
    for (let index = 0; index < Elem.length; index++) {
      Elem[index].classList.remove("SHOWBg");
    }
    document.getElementById(id).classList.add("SHOWBg");
  };
  return (
    <>
      {addListModal ? (
        <div
          className="pop "
          style={{ display: addNewListModal ? "none" : "flex" }}
        >
          <div className="popBody">
            <h4 style={{ color: "#000000" }}>All Lists</h4>
            <div className="pop-form">
              <div style={{ minWidth: "20vw" }}>
                <div className="container-fluid">
                  <div className="row">
                    <div className="col-md-12 col-12">
                      <div
                        className=" d-flex  flex-column  align-items-center"
                        style={{
                          minHeight: "11vh",
                          justifyContent:
                            ViewAllLists.length > 0 ? "flex-start" : "center",
                        }}
                      >
                        {ViewAllLists.length > 0 ? (
                          <>
                            <ul className="listOfName w-100">
                              {DeleteLoading ? (
                                <div>
                                  <div
                                    className="d-flex justify-content-center align-items-center "
                                    style={{ height: "35vh" }}
                                  >
                                    <div
                                      className="spinner-border"
                                      role="status"
                                    >
                                      <span className="visually-hidden"></span>
                                    </div>
                                  </div>
                                </div>
                              ) : (
                                ViewAllLists?.map((listItem, index) => {
                                  return (
                                    <li
                                      className="ListName d-flex justify-content-between"
                                      id={index}
                                      key={index}
                                      onClick={() => {
                                        AddColor(index);
                                        handleGetListData(listItem);
                                      }}
                                    >
                                      <p className="m-0 p-1">
                                        {" "}
                                        {listItem.list_name}
                                      </p>
                                      <Delete
                                        style={{ cursor: "pointer" }}
                                        onClick={() =>
                                          HandleDeleteList(listItem)
                                        }
                                      />
                                    </li>
                                  );
                                })
                              )}
                            </ul>
                            <h4 className="fs-6 mt-2 mb-2">
                              <b>OR</b>
                            </h4>
                          </>
                        ) : (
                          <></>
                        )}
                        <div
                          style={{
                            border:
                              !ViewAllLists.length > 0
                                ? "1px solid #a4a9af9c"
                                : "none",
                            height: !ViewAllLists.length > 0 ? "11vh" : "auto",
                            width: !ViewAllLists.length > 0 ? "100%" : "auto",
                            display: !ViewAllLists.length > 0 ? "flex" : "auto",
                            justifyContent:
                              !ViewAllLists.length > 0 ? "center" : "auto",
                            alignItems:
                              !ViewAllLists.length > 0 ? "center" : "auto",
                          }}
                        >
                          <button
                            className="AddListName mb-2"
                            onClick={HandleOpenModalAddList}
                          >
                            Create List
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="errorList">
                {ListError && (
                  <h6
                    style={{
                      marginTop: "10px",
                      marginLeft: "10px",
                      textAlign: "left",
                      fontSize: "12px",
                      color: "red",
                    }}
                  >
                    {" "}
                    Please Select One List.
                  </h6>
                )}
                {ListLimitError && (
                  <h6
                    style={{
                      marginTop: "10px",
                      textAlign: "left",
                      fontSize: "12px",
                      color: "red",
                    }}
                  >
                    {" "}
                    Your Plan Limit has been exceed .Please Upgrade Your Plan.
                  </h6>
                )}
                {keyWordLenErr && (
                  <h6
                    style={{
                      marginTop: "10px",
                      textAlign: "left",
                      fontSize: "12px",
                      color: "red",
                    }}
                  >
                    {" "}
                    You Can Only Add 100 Keywords in Single List.
                  </h6>
                )}
              </div>
              <div className="cmd  mt-3">
                <button
                  className="cm-btn-b"
                  onClick={() => {
                    setaddlistModal(false);
                    setKeyWordLenErr(false);
                  }}
                >
                  Cancel
                </button>

                <button
                  className="cm-btn"
                  onClick={HandleAddKeywordList}
                  disabled={ViewAllLists.length === 0 || keyWordLenErr}
                >
                  Submit
                </button>
              </div>
            </div>
          </div>
        </div>
      ) : null}
    </>
  );
};
