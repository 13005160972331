import React, { useEffect, useState } from "react";
import "./Profile.scss";
import PROFILE from "../../assets/images/profile/profileIcon.png";
import TRI1 from "../../assets/images/profile/tri1.png";
import TRI2 from "../../assets/images/profile/tri2.png";
import TRI3 from "../../assets/images/profile/tri3.png";
import Pop from "../../assets/images/profile/popup.png";
import { Header } from "../HeadFoot/header/Header";
import { Footer } from "../HeadFoot/footer/Footer";
import { ProfilePop } from "./ProfilePop";
import { IoCloseCircleOutline } from "react-icons/io5";
import axios from "axios";
import { GET_PROFILE, GET_PROFILE1 } from "../../utils/Api";
import { useSelector } from "react-redux";
import { DottLoader } from "../../components/Loader";

export const AffiliateProfile = () => {
  const [Data, setData] = useState([]);
  const isMobile = window.innerWidth < 600;
  const TOKEN = localStorage.getItem("AFFTOKEN");
  const EMAIL = localStorage.getItem("EMAIL");
  const [Open, setOpen] = useState();
  const user_conversion_data = useSelector((state) => state.clickconversion);

  const HandleDelete = () => {
    setOpen(!Open);
  };

  const handleGetProfile = () => {
    let Headers = {
      headers: {
        Authorization: "Bearer " + TOKEN,
      },
    };
    if (EMAIL) {
      axios.get(GET_PROFILE(EMAIL)).then(({ data }) => {
        if (data[0].email) {
          axios.get(GET_PROFILE1(data[0].email)).then((res) => {
            setData({ Data1: data[0], Data2: res?.data[0] });
          });
        }
      });
    }
  };

  useEffect(() => {
    handleGetProfile();
  }, [0]);

  return (
    <>
      <ProfilePop {...{ Open, setOpen }}>
        <div className="modalBody">
          <IoCloseCircleOutline onClick={() => setOpen(false)} />
          <img src={Pop} alt="" />
          <h2>
            Are Your Sure You Want to Delete {!isMobile && <br />}
            Your Account
          </h2>
          <div className="buttons">
            <button onClick={() => setOpen(false)}>Cancel</button>
            <button>Delete </button>
          </div>
        </div>
      </ProfilePop>
      <div className="ProfileContainer">
        <div className="Pr_Box">
          <div className="Pr_head"></div>
          <div className="Pr_Body border">
            {Data?.length === 0 ? (
              <div className="content-loader">
                <DottLoader />
              </div>
            ) : (
              <>
                <div className="ImageRow">
                  <img src={PROFILE} alt="" />
                  <div className="col">
                    <div className="name">{Data?.Data1?.name}</div>
                    <div className="email">{Data?.Data1?.email}</div>
                  </div>
                </div>
                <div className="ProfileRow mt-5">
                  <div className="firstBg">
                    <img src={TRI2} alt="" />
                    <img src={TRI1} alt="" />
                  </div>
                  <h1>Profle</h1>
                  <div className="INPUTS">
                    <div className="Pr_title">Name</div>
                    <div className="Pr_value">{Data?.Data1?.name}</div>
                  </div>
                  <div className="INPUTS">
                    <div className="Pr_title">Email</div>
                    <div className="Pr_value">{Data?.Data1?.email}</div>
                  </div>
                  <div className="INPUTS">
                    <div className="Pr_title">Password</div>
                    <div className="Pr_value">************</div>
                  </div>
                  <div className="INPUTS">
                    <div className="Pr_title">Contact</div>
                    <div className="Pr_value">{Data?.Data1?.contact}</div>
                  </div>
                  <div className="INPUTS">
                    <div className="Pr_title">Address</div>
                    <div className="Pr_value">{Data?.Data1?.address}</div>
                  </div>
                  <div className="INPUTS">
                    <div className="Pr_title">Country</div>
                    <div className="Pr_value">{Data?.Data1?.country}</div>
                  </div>
                </div>
                {Data?.Data2?.aff_id ? (
                  <>
                    <div className="ProfileRow">
                      <div className="SecondBg">
                        <img src={TRI3} alt="" />
                      </div>
                      <h1>Affiliate</h1>
                      <div className="INPUTS">
                        <div className="Pr_title">Affiliate ID</div>
                        <div className="Pr_value">{Data?.Data2?.aff_id}</div>
                      </div>
                      <div className="INPUTS">
                        <div className="Pr_title">Affiliate Link</div>
                        <div className="Pr_value">{Data?.Data2?.aff_link}</div>
                      </div>
                    </div>
                    <div className="ProfileRow ">
                      <h1>User Registered Using Your Link</h1>
                      <div className="INPUTS">
                        <div className="Pr_title">No. Of Users</div>
                        <div className="Pr_value">
                          {user_conversion_data?.conversion?.length || 0}
                        </div>
                      </div>
                    </div>
                  </>
                ) : (
                  <div className="affiliate-inprogress">
                    {/* <div className="content"> */}
                    <h1>Your Affiliate registration is in progress</h1>
                    <p>Thank you for your patience....</p>
                  </div>
                  // </div>
                )}
                <div className="dl_btn">
                  <button onClick={HandleDelete}>Delete Account</button>
                </div>
              </>
            )}
          </div>
        </div>
      </div>
    </>
  );
};
