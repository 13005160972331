import axios from "axios";
import { useEffect } from "react";
import {
  GET_USER_LAST_LOGIN,
  INVITE_AUTH,
  POST_USER_LAST_LOGIN,
  UPDATE_USER_LAST_LOGIN,
  profile_withApi,
} from "../services/constants";
import { useDispatch } from "react-redux";
import CountryLanguage from "../helperJs/country-language";
import { ZustandStore } from "../store/zustand-store";
import { checkIfAdmin } from "../helperJs/helper";

const GetProfile = () => {
  const token = localStorage.getItem("token");
  const dispatch = useDispatch();
  CountryLanguage();

  const updateDataAndTime = (email) => {
    const curr_date = new Date();
    let payload = {
      email: email,
      date: curr_date,
    };
    axios.get(GET_USER_LAST_LOGIN(email)).then((response) => {
      const data = response?.data;
      if (data?.length === 0) {
        axios.post(POST_USER_LAST_LOGIN(), payload);
      } else {
        const existingDate = new Date(data?.[0]?.date);
        // Check if the time difference is greater than 1 hour
        const timeDifference = Math.abs(curr_date - existingDate) / 36e5; // Convert milliseconds to hours
        if (timeDifference >= 1) {
          // Update date and time when the difference is at least 1 hour
          axios.put(UPDATE_USER_LAST_LOGIN(data?.[0]?.id), payload);
          // alert("Date and Time Updated");
        }
      }
    });
  };

  function setIntercomSettings(user) {
    window.intercomSettings = {
      api_base: "https://api-iam.intercom.io",
      app_id: "yxcglqza",
      name: user?.name, // Full name
      user_id: user?.id, // a UUID for your user
      email: user?.email, // the email for your user
      created_at: user?.created_at, // Signup date as a Unix timestamp
    };
  }

  useEffect(() => {
    dispatch({ type: "LOADING" });
    axios
      .get(profile_withApi(), {
        headers: {
          Authorization: "Bearer " + token,
        },
      })
      .then((res) => {
        ZustandStore.setState({
          user_profile: res.data,
        });
        const isAdminEmail = checkIfAdmin(res.data.main_user_mail);
        const authEMail = res.data.email;
        setIntercomSettings(res.data);
        updateDataAndTime(authEMail);
        axios.get(INVITE_AUTH(authEMail)).then((res) => {
          dispatch({ type: "SUBUSERINVITELENGTH", payload: res.data });
        });
        dispatch({ type: "USER", payload: true });
        // user name
        dispatch({ type: "NAME", payload: res.data.name });
        // user mail
        dispatch({ type: "EMAIL", payload: res.data.email });
        // main user mail for getting data
        localStorage.setItem("admin_mail", res.data.main_user_mail);
        localStorage.setItem("isAdmin", isAdminEmail);
        // email for checking admin in admin panel
        localStorage.setItem("admin_auth", res.data.email);
        localStorage.setItem("user_type", res.data.user_type);
        localStorage.setItem("user_id", res.data.id);
        localStorage.setItem("affliate_id", res.data.affiliate_id);
      })
      .catch(() => {
        localStorage.clear();
        window.location.href = "https://app.rankspro.io/login";
      });
  }, [token]);

  return null;
};

export default GetProfile;
