import React from "react";
import Popover from "@mui/material/Popover";

function PopOver({ anchorEl, setAnchorEl, children }) {
  function handleClose() {
    setAnchorEl(false);
  }
  const id = anchorEl ? "simple-popover" : "undefined";
  return (
    <Popover
      id={id}
      open={anchorEl} // anchorEl={anchorEl}
      disableScrollLock={false}
      anchorOrigin={{
        vertical: "center",
        horizontal: "center",
      }}
      transformOrigin={{
        vertical: "center",
        horizontal: "center",
      }}
      // onClose={handleClose}
      disableRestoreFocus
      disablePortal={false}
      sx={{
        "& .MuiBackdrop-root": {
          backgroundColor: "rgb(12, 12, 33, 0.5)",
        },
        "& .MuiPopover-paper": {
          borderRadius: "15px",
          backgroundColor: "rgba(255, 255, 255, 1)",
        },
      }}
      // modifiers={[
      //   {
      //     name: "flip",
      //     enabled: true,
      //     options: {
      //       altBoundary: true,
      //       rootBoundary: "document",
      //       padding: 8,
      //     },
      //   },
      //   {
      //     name: "preventOverflow",
      //     enabled: true,
      //     options: {
      //       altAxis: true,
      //       altBoundary: true,
      //       tether: true,
      //       rootBoundary: "document",
      //       padding: 8,
      //     },
      //   },
      //   {
      //     name: "arrow",
      //     enabled: false,
      //     options: {
      //       element: anchorEl,
      //     },
      //   },
      // ]}
    >
      {children}
    </Popover>
  );
}

export default PopOver;
