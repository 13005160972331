import React from "react";
import { CircularProgressbar } from "react-circular-progressbar";
import "react-circular-progressbar/dist/styles.css";
import { FaCalendarDays } from "react-icons/fa6";
import "./payment.scss";
import { useSelector } from "react-redux";
import HeaderComp from "../HeadFoot/header-comp/header";

const AffComItem = ({ data }) => {
  return (
    <div className="aff-com-item">
      <div className="d-flex align-items-center">
        <span
          className="com-box"
          style={{ backgroundColor: data?.color }}
        ></span>
        <span>{data?.name}</span>
      </div>
      <div className="aff-value">
        <span>{data?.earn}</span>
        {/* <span style={{ color: "#838383" }}>{data?.grow}</span> */}
      </div>
    </div>
  );
};
function PaymentAndEarning() {
  const user_profile = useSelector((state) => state.profile);
  const user_click_earns = useSelector((state) => state.clickconversion);

  const percentage = 0;

  const commission_data = [
    {
      name: "Affiliate Commission",
      color: "#b8c1f4",
      earn: `$${parseFloat(user_click_earns?.earning?.toFixed(2))}`,
      grow: `$${parseFloat(user_click_earns?.earning?.toFixed(2))}`,
    },
  ];

  const transaction_data = [];

  return (
    <>
      <div className="payment-container">
        <div className="section-1">
          <HeaderComp />
        </div>
        {user_profile?.profile2.length === 0 ? (
          <div className="affiliate-inprogress">
            {/* <div className="content"> */}
            <h1>Your Affiliate registration is in progress</h1>
            <p>Thank you for your patience....</p>
          </div>
        ) : (
          <>
            <div className="section-2">
              <h1>Commission Info</h1>
              <div className="row gy-3 gx-3">
                <div className="col-12 col-md-6">
                  {true ? null : (
                    <div className="calender">
                      <FaCalendarDays color="#8B8B8B" />
                      <span>Jul 9, 2023 - Aug 7, 2023</span>
                    </div>
                  )}
                </div>
                <div className="col-md-6"></div>
                <div className="col-12 col-md-5">
                  <div className="progress-contain">
                    <CircularProgressbar
                      value={percentage}
                      text={`${percentage}%`}
                      background="#B8C1F4"
                      strokeWidth={15}
                      counterClockwise={true}
                    />
                  </div>
                </div>
                <div className="col-12 col-md-6">
                  <div className="affiliate-commission-list">
                    {commission_data?.map((item, index) => {
                      return (
                        <AffComItem data={item} key={`affcomitem-${index}`} />
                      );
                    })}
                  </div>
                </div>
              </div>
            </div>
            <div className="section-3">
              <h1>Transaction History</h1>
              <div className="table-container add-scrollbar">
                <table className="table mt-0">
                  <thead className="head">
                    <tr>
                      <th className="text-start">Date</th>
                      <th className="text-center">Amount</th>
                      <th className="text-end">Status</th>
                    </tr>
                  </thead>
                  <tbody>
                    {transaction_data?.length === 0 ? (
                      <tr>
                        <td
                          colSpan={3}
                          className=""
                          style={{ textAlign: "center" }}
                        >
                          {" "}
                          No Transaction history
                        </td>
                      </tr>
                    ) : (
                      transaction_data?.map((item, index) => {
                        return (
                          <tr className="msg-item" key={`transcation-${index}`}>
                            <td scope="row" className="fst">
                              {item?.date}
                            </td>
                            <td className="snd">{item?.amount}</td>
                            <td className="trd">
                              <div
                                className="status-box"
                                style={{
                                  backgroundColor:
                                    item?.status === "Successful"
                                      ? "#457097"
                                      : "#F96C1D",
                                }}
                              >
                                {item?.status}
                              </div>
                            </td>
                          </tr>
                        );
                      })
                    )}
                  </tbody>
                </table>
              </div>
            </div>
          </>
        )}
      </div>
    </>
  );
}

export default PaymentAndEarning;
