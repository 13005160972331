import React from "react";
import { styled } from "@mui/material/styles";
import Switch from "@mui/material/Switch";

const Android12Switch = styled(Switch)(({ theme }) => ({
  padding: 8,
  width: "75px",
  height: "42px",
  "& .MuiSwitch-track": {
    borderRadius: 30,
    backgroundColor: "transparent !important",
    border: "1px solid #E95655",
    "&::before, &::after": {
      content: '""',
      position: "absolute",
      top: "50%",
      transform: "translateY(-50%)",
      width: 16,
      height: 16,
    },
  },
  "& .MuiSwitch-thumb": {
    boxShadow: "none",
    width: 22,
    height: 22,
    margin: 1,
    // backgroundColor: "#E42D2DCC",
    backgroundImage: `url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" height="16" width="16" viewBox="0 0 24 24"><path fill="${encodeURIComponent(
      theme.palette.getContrastText(theme.palette.primary.main)
    )}" d="M21,7L9,19L3.5,13.5L4.91,12.09L9,16.17L19.59,5.59L21,7Z"/></svg>')`,
    backgroundPosition: "center",
    backgroundRepeat: "no-repeat",
  },
  "& .Mui-checked": {
    color: "#E42D2DCC !important",
    transform: "translateX(33px) !important",
  },
}));

const SwitchComponent = (props) => {
  return <Android12Switch {...props} />;
};

export default SwitchComponent;

const AntSwitch = styled(Switch)(({ theme }) => ({
  width: 28,
  height: 16,
  padding: 0,
  display: "flex",
  "&:active": {
    "& .MuiSwitch-thumb": {
      width: 15,
    },
    "& .MuiSwitch-switchBase.Mui-checked": {
      transform: "translateX(9px)",
    },
  },
  "& .MuiSwitch-switchBase": {
    padding: 2,
    "&.Mui-checked": {
      transform: "translateX(12px)",
      color: "#fff",
      "& + .MuiSwitch-track": {
        opacity: 1,
        backgroundColor: "#365486",
      },
    },
  },
  "& .MuiSwitch-thumb": {
    boxShadow: "0 2px 4px 0 rgb(0 35 11 / 20%)",
    width: 12,
    height: 12,
    borderRadius: 6,
    transition: theme.transitions.create(["width"], {
      duration: 200,
    }),
  },
  "& .MuiSwitch-track": {
    borderRadius: 16 / 2,
    opacity: 1,
    backgroundColor: "#757171",
    boxSizing: "border-box",
  },
}));

export function CustomizedSwitches(props) {
  return <AntSwitch defaultChecked {...props} />;
}
