import React from "react";
import "./affiliate-notification.scss";
import imgSrc from "../../assets/images/message/msg-img.svg";
import noImgSrc from "../../assets/images/message/msg-no-img.svg";
import { BiTimeFive } from "react-icons/bi";
import { useSelector } from "react-redux";

function AffiliateNotification() {
  const user_profile = useSelector((state) => state.profile);

  const data = [];
  return (
    <>
      {user_profile?.profile2.length === 0 ? (
        <div className="affiliate-inprogress">
          {/* <div className="content"> */}
          <h1>Your Affiliate registration is in progress</h1>
          <p>Thank you for your patience....</p>
        </div>
      ) : (
        <div className="message-container">
          <div>
            <div className="msg-header">
              <h1>Message & Notification</h1>
            </div>
            <div className="table-container add-scrollbar">
              <table className="table table-striped msg-list mt-0">
                <tbody>
                  {data?.length === 0 ? (
                    <tr>
                      <td colSpan={2} style={{ textAlign: "center" }}>
                        No Message
                      </td>
                    </tr>
                  ) : (
                    data?.map((item, index) => {
                      return (
                        <tr className="msg-item" key={`notification-${index}`}>
                          <td scope="row" className="fst">
                            <img src={item?.imgSrc} alt={item?.name} />
                            <div className="msg-box">
                              <span>{item?.name}</span>
                              <p>{item?.msg}</p>
                            </div>
                          </td>
                          <td className="snd">
                            {" "}
                            <BiTimeFive />
                            <span>{item?.time}</span>
                          </td>
                        </tr>
                      );
                    })
                  )}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      )}
    </>
  );
}

export default AffiliateNotification;
