import axios from "axios";
import React from "react";
import { useEffect } from "react";
import { SENDINGMAIL_GET, SENDINGMAIL_UPDATE } from "../../services/constants";
import { useState } from "react";

const Mail = () => {
  const [mailCont, setMailContent] = useState(0);
  const [otpEmail, setOtpMail] = useState(0);
  const [newRegister, setNewRegister] = useState(0);
  const [invitationEmail, setInvitationEmail] = useState(0);
  const [forgotPass, setForgotPass] = useState(0);
  const [subsEmail, setSubsEmail] = useState(0);

  const fatch_sendingmailGet = () => {
    axios.get(SENDINGMAIL_GET()).then((res) => {
      setMailContent(res.data[0]);
      setOtpMail(res.data[0].OTP);
      setNewRegister(res.data[0].new_user_register);
      setInvitationEmail(res.data[0].invitation_mail);
      setForgotPass(res.data[0].forget_password);
      setSubsEmail(res.data[0].subscription_mail);
    });
  };
  useEffect(() => {
    fatch_sendingmailGet();
  }, []);

  const UpdateContent_Handler = () => {
    const put_JSON = {
      id: mailCont.id,
      data: {
        OTP: otpEmail,
        new_user_register: newRegister,
        invitation_mail: invitationEmail,
        forget_password: forgotPass,
        subscription_mail: subsEmail,
      },
    };
    axios.put(SENDINGMAIL_UPDATE(put_JSON), put_JSON.data).then(() => {
      fatch_sendingmailGet();
    });
  };

  return (
    <>
      <h2 className="table_title heading-large font-bold mb-4">Mail</h2>
      <div className="Box">
        <div className="cmd">
          <div>
            {" "}
            <h2 className="table_title heading-large font-bold">
              Sending Email Text
            </h2>
          </div>
          <div>
            {" "}
            <button className="btn_1" onClick={() => UpdateContent_Handler()}>
              Update
            </button>
          </div>
        </div>
        <div className="pop-form mt-3">
          <form>
            <div className="">
              <div className="row">
                <div className="col-md-4">
                  <input
                    type="text"
                    placeholder="OTP Email Text"
                    className="heading-small"
                    value={otpEmail}
                    onChange={(e) => {
                      setOtpMail(e.target.value);
                    }}
                  ></input>
                  <label id="lb">OTP Email Text</label>
                </div>
                <div className="col-md-4">
                  <input
                    type="text"
                    placeholder="New User Register Email Text"
                    value={newRegister}
                    className="heading-small"
                    onChange={(e) => {
                      setNewRegister(e.target.value);
                    }}
                  ></input>
                  <label id="lb">New User Register Email Text</label>
                </div>
                <div className="col-md-4">
                  <input
                    type="text"
                    placeholder="Invitation Email Text"
                    value={invitationEmail}
                    onChange={(e) => {
                      setInvitationEmail(e.target.value);
                    }}
                  ></input>
                  <label id="lb">Invitation Email Text</label>
                </div>
                <div className="col-md-4">
                  <input
                    type="text"
                    placeholder="Forget Password Text"
                    value={forgotPass}
                    onChange={(e) => {
                      setForgotPass(e.target.value);
                    }}
                  ></input>
                  <label id="lb">Forget Password Text</label>
                </div>
                <div className="col-md-4">
                  <input
                    type="text"
                    placeholder="Subscription Email Text"
                    value={subsEmail}
                    onChange={(e) => {
                      setSubsEmail(e.target.value);
                    }}
                  ></input>
                  <label id="lb">Subscription Email Text</label>
                </div>
              </div>
            </div>
          </form>
        </div>
      </div>
    </>
  );
};

export default Mail;
