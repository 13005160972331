export const languages = [
  {
    language: "Afrikaans",
    languagecode: "af",
  },
  {
    language: "Akan",
    languagecode: "ak",
  },
  {
    language: "Albanian",
    languagecode: "sq",
  },
  {
    language: "Amharic",
    languagecode: "am",
  },
  {
    language: "Arabic",
    languagecode: "ar",
  },
  {
    language: "Armenian",
    languagecode: "hy",
  },
  {
    language: "Azeri",
    languagecode: "az",
  },
  {
    language: "Balinese",
    languagecode: "ban",
  },
  {
    language: "Basque",
    languagecode: "eu",
  },
  {
    language: "Belarusian",
    languagecode: "be",
  },
  {
    language: "Bengali",
    languagecode: "bn",
  },
  {
    language: "Bosnian",
    languagecode: "bs",
  },
  {
    language: "Bulgarian",
    languagecode: "bg",
  },
  {
    language: "Burmese",
    languagecode: "my",
  },
  {
    language: "Catalan",
    languagecode: "ca",
  },
  {
    language: "Cebuano",
    languagecode: "ceb",
  },
  {
    language: "Chichewa",
    languagecode: "ny",
  },
  {
    language: "Chinese (Simplified)",
    languagecode: "zh-CN",
  },
  {
    language: "Chinese (Traditional)",
    languagecode: "zh-TW",
  },
  {
    language: "Croatian",
    languagecode: "hr",
  },
  {
    language: "Czech",
    languagecode: "cs",
  },
  {
    language: "Danish",
    languagecode: "da",
  },
  {
    language: "Dutch",
    languagecode: "nl",
  },
  {
    language: "English",
    languagecode: "en",
  },
  {
    language: "Espanol (Latinoamerica)",
    languagecode: "es-419",
  },
  {
    language: "Estonian",
    languagecode: "et",
  },
  {
    language: "Ewe",
    languagecode: "ee",
  },
  {
    language: "Faroese",
    languagecode: "fo",
  },
  {
    language: "Farsi",
    languagecode: "fa",
  },
  {
    language: "Filipino",
    languagecode: "fil",
  },
  {
    language: "Finnish",
    languagecode: "fi",
  },
  {
    language: "French",
    languagecode: "fr",
  },
  {
    language: "Frisian",
    languagecode: "fy",
  },
  {
    language: "Ga",
    languagecode: "gaa",
  },
  {
    language: "Galician",
    languagecode: "gl",
  },
  {
    language: "Ganda",
    languagecode: "lg",
  },
  {
    language: "Georgian",
    languagecode: "ka",
  },
  {
    language: "German",
    languagecode: "de",
  },
  {
    language: "Greek",
    languagecode: "el",
  },
  {
    language: "Gujarati",
    languagecode: "gu",
  },
  {
    language: "Haitian",
    languagecode: "ht",
  },
  {
    language: "Hausa",
    languagecode: "ha",
  },
  {
    language: "Hebrew",
    languagecode: "he",
  },
  {
    language: "Hebrew (old)",
    languagecode: "iw",
  },
  {
    language: "Hindi",
    languagecode: "hi",
  },
  {
    language: "Hungarian",
    languagecode: "hu",
  },
  {
    language: "Icelandic",
    languagecode: "is",
  },
  {
    language: "IciBemba",
    languagecode: "bem",
  },
  {
    language: "Igbo",
    languagecode: "ig",
  },
  {
    language: "Indonesian",
    languagecode: "id",
  },
  {
    language: "Irish",
    languagecode: "ga",
  },
  {
    language: "Italian",
    languagecode: "it",
  },
  {
    language: "Japanese",
    languagecode: "ja",
  },
  {
    language: "Kannada",
    languagecode: "kn",
  },
  {
    language: "Kazakh",
    languagecode: "kk",
  },
  {
    language: "Khmer",
    languagecode: "km",
  },
  {
    language: "Kinyarwanda",
    languagecode: "rw",
  },
  {
    language: "Kirundi",
    languagecode: "rn",
  },
  {
    language: "Kongo",
    languagecode: "kg",
  },
  {
    language: "Korean",
    languagecode: "ko",
  },
  {
    language: "Kreol morisien",
    languagecode: "mfe",
  },
  {
    language: "Kreol Seselwa",
    languagecode: "crs",
  },
  {
    language: "Krio",
    languagecode: "kri",
  },
  {
    language: "Kurdish",
    languagecode: "ckb",
  },
  {
    language: "Kyrgyz",
    languagecode: "ky",
  },
  {
    language: "Lao",
    languagecode: "lo",
  },
  {
    language: "Latvian",
    languagecode: "lv",
  },
  {
    language: "Lingala",
    languagecode: "ln",
  },
  {
    language: "Lithuanian",
    languagecode: "lt",
  },
  {
    language: "Luo",
    languagecode: "ach",
  },
  {
    language: "Macedonian",
    languagecode: "mk",
  },
  {
    language: "Malagasy",
    languagecode: "mg",
  },
  {
    language: "Malay",
    languagecode: "ms",
  },
  {
    language: "Malayam",
    languagecode: "ml",
  },
  {
    language: "Maltese",
    languagecode: "mt",
  },
  {
    language: "Maori",
    languagecode: "mi",
  },
  {
    language: "Marathi",
    languagecode: "mr",
  },
  {
    language: "Mongolian",
    languagecode: "mn",
  },
  {
    language: "Montenegro",
    languagecode: "sr-ME",
  },
  {
    language: "Nepali",
    languagecode: "ne",
  },
  {
    language: "Northern Sotho",
    languagecode: "nso",
  },
  {
    language: "Norwegian",
    languagecode: "no",
  },
  {
    language: "Nyankole",
    languagecode: "nyn",
  },
  {
    language: "Oromo",
    languagecode: "om",
  },
  {
    language: "Pashto",
    languagecode: "ps",
  },
  {
    language: "Pidgin",
    languagecode: "pcm",
  },
  {
    language: "Polish",
    languagecode: "pl",
  },
  {
    language: "Portuguese",
    languagecode: "pt",
  },
  {
    language: "Portuguese (Brazil)",
    languagecode: "pt-BR",
  },
  {
    language: "Portuguese (Portugal)",
    languagecode: "pt-PT",
  },
  {
    language: "Punjabi",
    languagecode: "pa",
  },
  {
    language: "Quechua",
    languagecode: "qu",
  },
  {
    language: "Romanian",
    languagecode: "ro",
  },
  {
    language: "Romansh",
    languagecode: "rm",
  },
  {
    language: "Russian",
    languagecode: "ru",
  },
  {
    language: "Serbian",
    languagecode: "sr",
  },
  {
    language: "Serbian (Latin)",
    languagecode: "sr-Latn",
  },
  {
    language: "Sesotho",
    languagecode: "st",
  },
  {
    language: "Shona",
    languagecode: "sn",
  },
  {
    language: "Silozi",
    languagecode: "loz",
  },
  {
    language: "Sindhi",
    languagecode: "sd",
  },
  {
    language: "Sinhalese",
    languagecode: "si",
  },
  {
    language: "Slovak",
    languagecode: "sk",
  },
  {
    language: "Slovenian",
    languagecode: "sl",
  },
  {
    language: "Somali",
    languagecode: "so",
  },
  {
    language: "Spanish",
    languagecode: "es",
  },
  {
    language: "Swahili",
    languagecode: "sw",
  },
  {
    language: "Swedish",
    languagecode: "sv",
  },
  {
    language: "Tagalog",
    languagecode: "tl",
  },
  {
    language: "Tajik",
    languagecode: "tg",
  },
  {
    language: "Tamil",
    languagecode: "ta",
  },
  {
    language: "Telugu",
    languagecode: "te",
  },
  {
    language: "Thai",
    languagecode: "th",
  },
  {
    language: "Tigrinya",
    languagecode: "ti",
  },
  {
    language: "Tonga (Tonga Islands)",
    languagecode: "to",
  },
  {
    language: "Tshiluba",
    languagecode: "lua",
  },
  {
    language: "Tswana",
    languagecode: "tn",
  },
  {
    language: "Tumbuka",
    languagecode: "tum",
  },
  {
    language: "Turkish",
    languagecode: "tr",
  },
  {
    language: "Turkmen",
    languagecode: "tk",
  },
  {
    language: "Ukrainian",
    languagecode: "uk",
  },
  {
    language: "Urdu",
    languagecode: "ur",
  },
  {
    language: "Uzbek",
    languagecode: "uz",
  },
  {
    language: "Vietnamese",
    languagecode: "vi",
  },
  {
    language: "Wolof",
    languagecode: "wo",
  },
  {
    language: "Xhosa",
    languagecode: "xh",
  },
  {
    language: "Yoruba",
    languagecode: "yo",
  },
  {
    language: "Zulu",
    languagecode: "zu",
  },
];
