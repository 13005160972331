import React, { useState } from "react";
import RIGHT from "../../Assets/site-audit/right.png";
import WRONG from "../../Assets/site-audit/wrong2.png";
import ToolTip from "../components/tooltip";
import OpenInNewIcon from "@mui/icons-material/OpenInNew";
import { getStatusCodeColor } from "../../../helperJs/helper.js";

function AuditTable({ tableHeader, tableData, type, showTitle }) {
  const [showTable, setShowTable] = useState([]);
  // const hiddenHeader = "Status"
  const showTableHandler = (e) => {
    if (typeof e !== "string") {
      if (showTable?.length === 0 || e !== showTable) {
        setShowTable(e);
      } else {
        setShowTable([]);
      }
    }
  };

  return (
    <div className="custom-table-container mt-4">
      <table className="audit-table">
        <thead>
          <tr className="table-sticky">
            {tableHeader?.map((header, index) => {
              return (
                <th
                  key={`table-header-${index}`}
                  className={`${
                    header === "Description" || header === "Title"
                      ? "text-start"
                      : ""
                  }${header === "HTTP Status" ? "p-0" : ""}`}
                  style={{
                    width: [
                      "Characters Check",
                      "Keyword Check",
                      "Dynamic Check",
                    ]?.includes(header)
                      ? "200px"
                      : ["HTTP Status", "Length"]?.includes(header) &&
                        tableHeader?.length > 2
                      ? "160px"
                      : "auto",
                  }}
                >
                  {header}
                </th>
              );
            })}
          </tr>
        </thead>
        <tbody>
          {tableData?.length == 0 ? (
            <td className="text-center" colSpan={tableHeader?.length}>
              You don't have any data !
            </td>
          ) : (
            tableData?.map((item, index) => {
              return (
                <React.Fragment key={`table-row-${index}`}>
                  <tr
                    key={index}
                    style={{
                      cursor: "pointer",
                    }}
                    onClick={item?.td2?.length > 0 ? item.td3 : () => {}}
                  >
                    {item?.title && showTitle && !item?.status_code ? (
                      <td className="text-start">{item?.title}</td>
                    ) : null}
                    {item?.url ? (
                      <td
                        onClick={() => showTableHandler(item.url)}
                        className={
                          type === "poorly_formatted" ? "length-url-lmt" : ""
                        }
                      >
                        {typeof item.url === "string" ? (
                          <div className="text-max-lmt">
                            {!showTitle && (
                              <h1 className="heading-small font-regular mb-0">
                                {item?.title}
                              </h1>
                            )}
                            <span className="d-flex align-items-center">
                              <a
                                href={item.url === "undefined" ? "#" : item.url}
                                target="_blank"
                                rel="noreferrer"
                                style={{
                                  alignItems: "center",
                                  width: "100%",
                                  // maxWidth: "500px",
                                  color: !showTitle
                                    ? "#365486"
                                    : "rgba(0, 0, 0, 0.8)",
                                }}
                                className={
                                  type === "poorly_formatted"
                                    ? `d-inline-flex ${
                                        !showTitle ? "text-max-lmt" : ""
                                      }`
                                    : `${!showTitle ? "text-max-lmt" : ""}`
                                }
                              >
                                {" "}
                                {type === "poorly_formatted" ? (
                                  <ToolTip title={item?.url}>
                                    <span
                                      className="length-url-lmt"
                                      style={{
                                        color: "#365486",
                                        maxWidth: "95%",
                                      }}
                                    >
                                      {item?.url}
                                    </span>
                                  </ToolTip>
                                ) : (
                                  item?.url
                                )}
                                <OpenInNewIcon
                                  sx={{
                                    fontSize: "13px",
                                    marginLeft: "2px",
                                    color: "#365486 !important",
                                  }}
                                />
                              </a>
                            </span>
                          </div>
                        ) : item.url?.length === 1 ? (
                          <a
                            href={item.url === "undefined" ? "#" : item.url}
                            target="_blank"
                            rel="noreferrer"
                            style={{
                              color: !showTitle
                                ? "#365486"
                                : "rgba(0, 0, 0, 0.8)",
                            }}
                            className={
                              type === "poorly_formatted"
                                ? "w-100 d-inline-flex"
                                : ""
                            }
                          >
                            {" "}
                            {type === "poorly_formatted" ? (
                              <ToolTip title={item?.url[0]}>
                                <span
                                  className="length-url-lmt"
                                  style={{ maxWidth: "100%" }}
                                >
                                  {item?.url[0]}{" "}
                                </span>
                              </ToolTip>
                            ) : (
                              item?.url[0]
                            )}
                          </a>
                        ) : (
                          <button
                            className="site-audit-url py-1 px-2"
                            style={{
                              background: "#fff",
                              color: "rgb(233, 86, 85)",
                              width: "100px",
                            }}
                          >
                            {item.url.length}{" "}
                            <span className="mx-1">pages</span>
                            <i
                              className="fa-solid fa-angle-down ms-2"
                              title="download Pdf"
                            ></i>
                          </button>
                        )}
                      </td>
                    ) : null}
                    {item?.count ? (
                      <td className="" style={{ textTransform: "capitalize" }}>
                        {item.count}
                      </td>
                    ) : null}
                    {item?.td1 ? <td>{item.td1}</td> : null}
                    {item?.td2 ? <td>{item?.td2?.length} Pages</td> : null}
                    {item?.status_code ? (
                      <td className="text-center">
                        <span
                          className="table-status-code"
                          style={{
                            color: getStatusCodeColor(item.status_code)?.color,
                            backgroundColor: getStatusCodeColor(
                              item.status_code
                            )?.backgroundColor,
                          }}
                        >
                          {item.status_code}
                        </span>
                      </td>
                    ) : null}
                    {item?.h1_tags ? <td>{item.h1_tags}</td> : null}
                    {item?.desc ? (
                      <td className="text-start">{item.desc}</td>
                    ) : null}
                    {item?.title && showTitle && item?.status_code ? (
                      <td className="text-start">{item.title}</td>
                    ) : null}

                    {item?.no_of_h1_tags ? (
                      <td className="text-center">{item.no_of_h1_tags}</td>
                    ) : null}
                    {item?.length ? (
                      <td className="text-center">{item.length}</td>
                    ) : null}
                    {item?.td3 ? (
                      <td
                        className="v_All"
                        onClick={item?.td2?.length > 0 ? item.td3 : () => {}}
                      >
                        <span
                          className="site-audit-url"
                          style={{
                            opacity: item?.td2?.length > 0 ? "1" : "0.5",
                          }}
                        >
                          View
                        </span>
                      </td>
                    ) : null}

                    {item?.character_check ? (
                      <td>{item.character_check}</td>
                    ) : null}
                    {item?.characters ||
                    typeof item.characters === "boolean" ||
                    item?.characters === "undefined" ? (
                      <td>
                        {item.characters === true ? (
                          <>
                            <img
                              src={RIGHT}
                              className="RIGHTIMG"
                              alt="right-tick-icon"
                            />
                          </>
                        ) : (
                          <>
                            <img
                              src={WRONG}
                              className="WRONGIMG"
                              alt="wrong-tick-icon"
                            />
                          </>
                        )}
                      </td>
                    ) : null}
                    {item?.dynamic ||
                    typeof item.dynamic === "boolean" ||
                    item?.dynamic === "undefined" ? (
                      <td>
                        {item.dynamic === true ? (
                          <>
                            <img
                              src={RIGHT}
                              className="RIGHTIMG"
                              alt="right-tick-icon"
                            />
                          </>
                        ) : (
                          <>
                            <img
                              src={WRONG}
                              className="WRONGIMG"
                              alt="wrong-tick-icon"
                            />
                          </>
                        )}
                      </td>
                    ) : null}
                    {item?.keywords ||
                    typeof item?.keywords === "boolean" ||
                    item?.keywords === "undefined" ? (
                      <td>
                        {item.keywords === true ? (
                          <>
                            <img
                              src={RIGHT}
                              className="RIGHTIMG"
                              alt="right-tick-icon"
                            />
                          </>
                        ) : (
                          <>
                            <img
                              src={WRONG}
                              className="WRONGIMG"
                              alt="wrong-tick-icon"
                            />
                          </>
                        )}
                      </td>
                    ) : null}
                    {item?.time ? <td>{item.time}</td> : null}
                  </tr>
                  {item?.url === showTable
                    ? showTable?.length > 0 &&
                      showTable?.map((res, ind) => {
                        return (
                          <tr key={ind}>
                            <td colSpan={2}>
                              <div className="text-max-lmt w-100">
                                {item?.title ? (
                                  <span>{item?.title}</span>
                                ) : null}
                                <span className="d-flex align-items-center">
                                  <a
                                    href={res || "#"}
                                    target="_blank"
                                    rel="noreferrer"
                                    style={{
                                      alignItems: "center",
                                      color: "#365486",
                                    }}
                                    className={
                                      "d-inline-flex text-max-lmt w-100"
                                    }
                                  >
                                    <ToolTip title={res || ""}>
                                      <span
                                        className="length-url-lmt"
                                        style={{
                                          color: "#365486",
                                          maxWidth: "95%",
                                        }}
                                      >
                                        {res || ""}
                                      </span>
                                    </ToolTip>
                                    <OpenInNewIcon
                                      sx={{
                                        fontSize: "13px",
                                        marginLeft: "2px",
                                        color: "#365486 !important",
                                      }}
                                    />
                                  </a>
                                </span>
                              </div>
                            </td>
                          </tr>
                        );
                      })
                    : false}
                </React.Fragment>
              );
            })
          )}
        </tbody>
      </table>
    </div>
  );
}

export default AuditTable;
