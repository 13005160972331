import React from "react";
import Logo from "./FooetrImg/logo.png";
import "./Footer.scss";
import LINKDIN from "./FooetrImg/link.png";
import FB from "./FooetrImg/fb.png";
import INSTA from "./FooetrImg/insta.png";
import TWITTER from "./FooetrImg/twitter.png";
import { LuCopyright } from "react-icons/lu";

export const FooterTool = () => {
  function getCurrentYear() {
    const currentYear = new Date().getFullYear();
    return currentYear;
  }
  const currentYear = getCurrentYear();
  return (
    <div className="footer">
      <div className="footerContent">
        <div className="row m-0 p-0">
          <div className="col-lg-4 col-md-4 col-12">
            <div className="foot1">
              <img src={Logo} alt="" />
              <p>
                RanksPro.io's team of professionals has years of experience in
                the SEO domain. We have used that rich experience to create a
                tool that is easy to operate, accurate, and full-on packed with
                features. We remain deeply committed to our customers so that
                they can achieve their SEO targets. We promise you that we shall
                do whatever is needed to make you succeed.
              </p>
              <div className="socials">
                <a
                  href="https://www.linkedin.com/company/rankspro-io"
                  target="_blank"
                >
                  <img src={LINKDIN} alt="" />
                </a>
                <a href="https://www.facebook.com/eslrankspro" target="_blank">
                  {" "}
                  <img src={FB} alt="" />
                </a>
                <a href="https://x.com/rankspro_io" target="_blank">
                  <img src={TWITTER} alt="" />
                </a>
                <a
                  href="https://www.instagram.com/rankspro.io/"
                  target="_blank"
                >
                  <img src={INSTA} alt="" />
                </a>
              </div>
            </div>
          </div>
          <div className="col-lg-2 col-md-2 col-12">
            <div className="foot2">
              <h4>Useful</h4>
              <ul>
                <li>
                  <a href="https://rankspro.io/terms-and-conditions">
                    Term and Conditions
                  </a>
                </li>
                <li>
                  {" "}
                  <a href="https://rankspro.io/privacy-policy" target="_blank">
                    Privacy Policy
                  </a>
                </li>
                <li>
                  {" "}
                  <a href="https://rankspro.io/site-audit/" target="_blank">
                    Site Audit
                  </a>
                </li>
                <li>
                  {" "}
                  <a href="https://rankspro.io/features/" target="_blank">
                    Features
                  </a>
                </li>
                <li>
                  {" "}
                  <a href="https://rankspro.io/testimonial-2/" target="_blank">
                    Testimonial
                  </a>
                </li>
                <li>
                  {" "}
                  <a href="https://rankspro.io/pricing/" target="_blank">
                    Pricing
                  </a>
                </li>
              </ul>
            </div>
          </div>
          <div className="col-lg-3 col-md-3 col-12">
            <div className="foot3">
              <h4>Features</h4>
              <ul>
                <li>
                  {" "}
                  <a href="https://rankspro.io/rank-tracking/" target="_blank">
                    Rank Tracking
                  </a>
                </li>
                <li>
                  {" "}
                  <a href="https://rankspro.io/site-audit/" target="_blank">
                    Site Audit
                  </a>
                </li>
                <li>
                  {" "}
                  <a
                    href="https://rankspro.io/keyword-research/"
                    target="_blank"
                  >
                    Keyword Research
                  </a>
                </li>
                <li>
                  {" "}
                  <a
                    href="https://rankspro.io/competitive-research/"
                    target="_blank"
                  >
                    Competitive Research
                  </a>
                </li>
              </ul>
            </div>
          </div>
          <div className="col-lg-3 col-md-3 col-12">
            <div className="foot4">
              <h4>Newsletter</h4>
              <p>
                Subscribe to our newsletter to know more about the SEO strategy
                and insights. We promise we won’t spam you and you can
                unsubscribe anytime you want.
              </p>
            </div>
          </div>
        </div>
      </div>
      <hr style={{ color: "white" }} />
      <div className="copyRight">
        <div className="FootList">
          <p>
            <a href="https://rankspro.io/backlink-checker/">Backlink Checker</a>
          </p>
          <p>
            <a href="https://rankspro.io/blog/">Blog</a>
          </p>
          <p>
            <a href="https://rankspro.io/competitive-research/">
              Competitive Research
            </a>
          </p>
          <p>
            <a href="https://rankspro.io/features/">Features</a>
          </p>
          <p>
            <a href="https://rankspro.io/">Home</a>
          </p>
        </div>
        <h6>
          <LuCopyright className="me-1 heading-medium" />
          Copyright {currentYear} RanksPro.io | Developed by{" "}
          <a href="https://www.esearchlogix.com/">eSearch Logix</a>
        </h6>
      </div>
    </div>
  );
};
