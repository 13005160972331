import React from "react";
import imgSrc from "../../../assets/images/Global/affi-header.png";
import "./header.scss";

function HeaderComp() {
  return (
    <div className="page-header-container">
      <div className="fst">
        <h1>ESL Affiliate Module</h1>
      </div>
      <div className="snd">
        <img src={imgSrc} alt="ESL Affiliate Module" />
      </div>
    </div>
  );
}

export default HeaderComp;
