import React, { useState, useEffect, useRef } from "react";
import { ReactComponent as DropArrow } from "../../Assets/common/droparrow.svg";

const CountryBasedOnLanuage = (props) => {
  const [clickDrop, setClickDrop] = useState(false);
  const [searched, setSearched] = useState([]);
  const [inputFocused, setInputFocused] = useState(false); // Track input focus
  const dropdownRef = useRef(null);
  const inputRef = useRef(null);

  const data = props.data?.data;

  const handleClick = (res) => {
    props.callback(res);
    setClickDrop(false);
    setSearched([]);
  };

  const searchCountry = (text) => {
    if (!text) {
      setSearched([]);
    } else {
      let matches = data.filter((prname) => {
        const regex = new RegExp(`${text}`, "gi");
        return prname.title
          ? prname.title.match(regex)
          : prname.title
          ? prname.title.match(regex) || prname.title.match(regex)
          : prname.match(regex);
      });
      setSearched(matches);
    }
  };

  const handleClickOutside = (event) => {
    if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
      setClickDrop(false);
    }
  };

  useEffect(() => {
    window.addEventListener("click", handleClickOutside);

    return () => {
      window.removeEventListener("click", handleClickOutside);
    };
  }, []);

  useEffect(() => {
    if (inputFocused && inputRef.current) {
      inputRef.current.focus();
    }
  }, [inputFocused]);

  return (
    <>
      <div className={"prLocation"} style={props?.style} ref={dropdownRef}>
        <div
          style={{
            overflow: "hidden",
            display: "flex",
            alignItems: "center",
            width: "100%",
          }}
          onClick={() => {
            setInputFocused(true); // Focus the input on click
            setClickDrop(!clickDrop);
          }}
        >
          {props?.img && (
            <img
              className="me-2"
              src={props?.img}
              alt="selected-icon"
              style={{ width: "20px", objectFit: "contain" }}
            />
          )}
          <p
            className="heading-small"
            style={{ fontSize: props?.style?.fontSize }}
          >
            {props?.title}
          </p>
        </div>

        <div
          style={{ background: "transparent" }}
          onClick={() => {
            setInputFocused(true); // Focus the input on click
            setClickDrop(!clickDrop);
          }}
        >
          <DropArrow
            style={{
              width: "22px",
              height: "22px",
              color: "#000000",
              transform: clickDrop ? "rotateX(180deg)" : "rotateX(0deg)",
            }}
          />
        </div>

        {clickDrop ? (
          <div
            className="prloc_drop"
            style={{
              borderRadius: props?.style?.borderRadius || "5px",
            }}
          >
            <div className="prloc_drop_scroll">
              <ul style={{ position: "relative" }}>
                {props?.searchType ? null : (
                  <li
                    className="p-0 mb-2"
                    style={{
                      top: "0",
                      position: "sticky",
                      background: "white !important",
                    }}
                  >
                    <div className="">
                      <div>
                        <input
                          type="search"
                          ref={inputRef}
                          className="focus-on-click heading-small"
                          onFocus={() => setInputFocused(true)}
                          onBlur={() => setInputFocused(false)}
                          onChange={(e) => searchCountry(e.target.value)}
                          placeholder="search here.."
                        ></input>
                      </div>
                    </div>
                  </li>
                )}

                {searched.length === 0
                  ? props?.data?.data &&
                    props?.data?.data?.map((res, index) => (
                      <li
                        key={`dropdown-option-${index}`}
                        onClick={() => handleClick(res)}
                      >
                        {res?.flag && (
                          <img
                            className="me-2"
                            src={res.flag}
                            alt={res?.location_name}
                            style={{ width: "20px", objectFit: "contain" }}
                          />
                        )}
                        {res?.title}
                      </li>
                    ))
                  : searched.map((res, index) => (
                      <li
                        key={`search-dropdown-option-${index}`}
                        onClick={() => handleClick(res)}
                      >
                        {res.flag && (
                          <img
                            className="me-2"
                            src={res?.flag}
                            alt={res?.location_name}
                            style={{ width: "20px", objectFit: "contain" }}
                          />
                        )}
                        {res?.title}
                      </li>
                    ))}
              </ul>
            </div>
          </div>
        ) : null}
      </div>
    </>
  );
};

export default React.memo(CountryBasedOnLanuage);
