import React from "react";

var monthNames = [
  "Jan",
  "Feb",
  "Mar",
  "Apr",
  "May",
  "Jun",
  "Jul",
  "Aug",
  "Sep",
  "Oct",
  "Nov",
  "Dec",
];

export const TableHandler = ({ callback, limit, last, index, length }) => {
  const valueHandler = (isNext) => {
    if (isNext) {
      if (length > last) {
        callback({ index: last, last: last + limit });
      }
    } else {
      if (index > 0) {
        callback({ index: index - limit, last: index });
      }
    }
  };

  const totalPages = Math.ceil(length / limit);

  return (
    <>
      <div className="keyword-pagination">
        <button
          className={"cm-btn_2 me-3 "}
          onClick={() => valueHandler(false)}
        >
          Prev
        </button>
        <span className="me-3">
          Page {Math.ceil(index / limit) + 1} of {totalPages}
        </span>
        <button
          className={`cm-btn_2 ${length <= last ? "disabled" : ""}`}
          onClick={() => valueHandler(true)}
          disabled={length <= last}
        >
          Next
        </button>
      </div>
    </>
  );
};

export const curday = function (sp) {
  const today = new Date();
  var dd = today.getDate();
  var monthNames = [
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "May",
    "Jun",
    "Jul",
    "Aug",
    "Sep",
    "Oct",
    "Nov",
    "Dec",
  ];

  var mm = today.getMonth() + 1; //As January is 0.
  var yyyy = today.getFullYear();
  if (dd < 10) dd = "0" + dd;
  return monthNames[today.getMonth()] + " " + dd + " , " + yyyy;
};

export const perday = function (sp) {
  const today = new Date();
  var dd = today.getDate();
  var monthNames = [
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "May",
    "Jun",
    "Jul",
    "Aug",
    "Sep",
    "Oct",
    "Nov",
    "Dec",
  ];
  var yyyy = today.getFullYear();

  if (dd < 10) dd = "0" + dd;
  return monthNames[today.getMonth() + 1] + " " + dd + " , " + yyyy;
};

export const Logout = () => {
  localStorage.clear();
  sessionStorage.clear();
  window.location.href = "/login";
};

export function getMonthName(num) {
  return monthNames[num - 1];
}

export function formatDate(date) {
  const today = new Date(date);
  var dd = today.getDate();
  var monthNames = [
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "May",
    "Jun",
    "Jul",
    "Aug",
    "Sep",
    "Oct",
    "Nov",
    "Dec",
  ];
  var yyyy = today.getFullYear();

  if (dd < 10) dd = "0" + dd;
  return monthNames[today.getMonth()] + " " + dd + " , " + yyyy;
}
