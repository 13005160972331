import React, { useState } from "react";
import axios from "axios";
import { useDispatch, useSelector } from "react-redux";
import {
  INVITE_AUTH,
  InviteUserBy_Mail,
  delete_User,
} from "../../../services/constants";
import addUserIcon from "../../Assets/Profile/add-user.svg";
import noUserImg from "../../Assets/Profile/no-Img.svg";
import Avtar from "./avtar.component";
import { Tooltip } from "@mui/material";
import PopOver from "./popover.component";
import { ReactComponent as CrossIcon } from "../../Assets/rank-tracking-assets/cross.svg";

const SubUserDetails = () => {
  const dispatch = useDispatch();
  const [showDetail, setShowDetail] = useState(false);
  //user invite state
  const adminMail = localStorage.getItem("admin_mail");
  const [showInvite, setShowInvite] = useState(false);
  const [inviteMail, setInviteMail] = useState("");
  const [inviteRes, setInviteRes] = useState(false);
  const [SuccessRes, setSuccessRes] = useState(false);
  const [UserId, setUserId] = useState(null);
  const email = useSelector((state) => state.email);

  // const invited_user = adminMail === email;
  const invited_user =
    adminMail?.toLocaleLowerCase() === email?.toLocaleLowerCase();
  const userName = useSelector((state) => state.name);
  const subuserinvitelength = useSelector((state) => state.subuserinvitelength);
  const subuserinvitelimit = useSelector((state) => state.subuserinvitelimit);

  const deleteUser = () => {
    axios.delete(delete_User(UserId)).then(() => {
      const authEMail = email;
      axios.get(INVITE_AUTH(authEMail)).then((res) => {
        dispatch({ type: "SUBUSERINVITELENGTH", payload: res.data });
      });
      setUserId(null);
    });
  };

  const GenrateInviteLink = (e) => {
    e.preventDefault();
    if (subuserinvitelength.length >= subuserinvitelimit) {
      setInviteRes("Invite limit exceeded");
    } else {
      const invite_limit = subuserinvitelimit - subuserinvitelength?.length;
      setInviteRes(false);
      const inviteData = {
        email: email,
        name: userName,
        sending_mail: inviteMail,
      };
      axios
        .post(InviteUserBy_Mail(invite_limit), inviteData)
        .then((res) => {
          setSuccessRes(res.data.msg);
          setInviteMail("");
        })
        .catch((res) => {
          setInviteRes(res.response.data.sending_mail);
        });
    }
  };

  return (
    <>
      {showDetail && (
        <div className="pop ">
          <div
            className="popBodySeoImpactTool"
            style={{ minWidth: "300px", transform: "scale(.9)" }}
          >
            <div className="SeoImpactHeadTool">
              <h4 className="mb-0 heading-large font-bold">Sub-user List</h4>
              <CrossIcon
                style={{
                  width: "30px",
                  height: "30px",
                  cursor: "pointer",
                  float: "right",
                  cursor: "pointer",
                  top: "15px",
                }}
                onClick={() => setShowDetail(false)}
              />
            </div>

            <div className="pop-form py-2 py-lg-4">
              <div className="listModalHeightTool list-scroll">
                <div className="profile-table-container">
                  <table className="table mb-0 profile-table mt-0">
                    <thead>
                      <tr>
                        <th scope="col">Name</th>
                        <th scope="col">Email</th>
                        <th scope="col"></th>
                      </tr>
                    </thead>
                    <tbody>
                      {subuserinvitelength.length !== 0 ? (
                        subuserinvitelength.map((res, key) => (
                          <tr key={res.name}>
                            <td>{res.name}</td>
                            <td>{res.email}</td>
                            <td className="text-end">
                              {res.id === UserId ? (
                                <div>
                                  <span onClick={() => setUserId(null)}>
                                    <i className="fa-solid fa-xmark text-success"></i>
                                  </span>
                                  <span
                                    className="ms-3"
                                    onClick={() => deleteUser()}
                                  >
                                    <i className="fa-solid fa-check text-danger"></i>
                                  </span>
                                </div>
                              ) : (
                                <span onClick={() => setUserId(res.id)}>
                                  <i className="fa-solid fa-trash text-danger"></i>
                                </span>
                              )}
                            </td>
                          </tr>
                        ))
                      ) : (
                        <tr>
                          <td colSpan={3} className="text-center">
                            No Sub Users
                          </td>
                        </tr>
                      )}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}

      <div className="w-100 user-list-info">
        <div>
          <h4 className="">Sub Users</h4>
          {showInvite ? null : (
            <div className="user-image">
              <img
                src={addUserIcon}
                alt="add-user"
                className="add-user"
                onClick={() => {
                  invited_user && setShowInvite(true);
                }}
              />
              {subuserinvitelength?.map((user, index) => {
                return (
                  <Tooltip title={user?.email} key={user.name}>
                    <div
                      key={user?.name}
                      style={{
                        zIndex: index === 0 ? "1" : "2",
                        right: `8*${index}px`,
                        width: "55px",
                        height: "55px",
                      }}
                    >
                      <Avtar user_name={user?.name} />
                    </div>
                  </Tooltip>
                );
              })}
            </div>
          )}
        </div>
        {!invited_user ? (
          <div className="d-flex flex-column align-items-cnter">
            <p className="msg-error text-start mb-0">
              You cannot invite User, Contact account owner -{" "}
            </p>
            <span style={{ color: "black" }}>{adminMail}</span>
          </div>
        ) : showInvite ? (
          <div>
            <form
              onSubmit={(e) => GenrateInviteLink(e)}
              onChange={() => {
                setInviteRes(false);
                setSuccessRes(false);
              }}
            >
              <div className="prefix_Form inviteForm">
                <div className="mb-3">
                  <input
                    type="email"
                    placeholder="Enter User Email"
                    value={inviteMail}
                    onChange={(e) => {
                      setInviteMail(e.target.value);
                    }}
                  />
                </div>
                <p className="vl-msd-line">
                  {" "}
                  {SuccessRes ? (
                    <span
                      style={{
                        color: "green",
                      }}
                    >
                      {SuccessRes}
                    </span>
                  ) : inviteRes ? (
                    <span
                      style={{
                        color: "red",
                      }}
                    >
                      {inviteRes}
                    </span>
                  ) : null}
                  <span></span>{" "}
                </p>

                <div className="cmd mt-3 p-0">
                  <button
                    className="cm-btn-b p-3 py-2"
                    type="button"
                    onClick={() => {
                      setShowInvite(false);
                      setInviteRes(false);
                      setSuccessRes(false);
                    }}
                  >
                    Close
                  </button>
                  <button
                    className="cm-btn p-3 py-2"
                    onClick={(e) => GenrateInviteLink(e)}
                  >
                    Send Invite
                  </button>
                </div>
              </div>
            </form>
          </div>
        ) : (
          <div className="cmd">
            {subuserinvitelength?.length === 0 ? (
              <div>
                <button
                  className="Pr-btn ms-1"
                  style={{ height: "40px", minWidth: "100px" }}
                  onClick={() => setShowInvite(true)}
                  disabled={!invited_user}
                >
                  Invite User
                </button>
              </div>
            ) : (
              <button
                className="Pr-btn"
                style={{ height: "40px", minWidth: "100px" }}
                onClick={() => setShowDetail(true)}
              >
                View Details
              </button>
            )}
          </div>
        )}
      </div>
    </>
  );
};

export default SubUserDetails;
