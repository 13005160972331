import React, { useState, useEffect, useMemo } from "react";
import axios from "axios";
import { GET_ADD_ON_PLAN, UPDATE_ADDON } from "../../../services/constants";
import { DottLoader } from "../../../Mycomponent/share/loader";
import CustomTable from "../../../Mycomponent/share/customTable";
import AddLimits from "./add-limits";
import AlertPopUp from "../../../Mycomponent/share/components/alert.component";

const AddOnPlan = () => {
  const [SelectedUser, setSelectedUser] = useState([]);
  const [AddOnData, setAddOnData] = useState({
    loading: true,
    data: [],
    error: false,
  });
  const [Alert, setAlert] = useState({
    type: "",
    message: "",
    show: false,
  });
  const [page, setPage] = React.useState(1);

  const getAddOnPlans = async () => {
    try {
      const response = await axios.get(GET_ADD_ON_PLAN());
      return response.data;
    } catch (error) {
      console.error("GET request failed:", error);
      throw error;
    }
  };

  const fetchData = async () => {
    try {
      const result = await getAddOnPlans();
      setAddOnData({
        loading: false,
        data: result.data,
        error: false,
      });
    } catch (error) {
      setAddOnData({
        loading: false,
        data: [],
        error: true,
      });
      console.error("Error fetching data:", error);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  const tableHeader = [
    {
      label: "EMAIL",
      key: "email",
      minWidth: 150,
      align: "left",
    },
    {
      label: "PROJECTS",
      key: "projects",
      minWidth: 120,
      align: "center",
    },
    {
      label: "TRACK KEYWORD",
      key: "track_key",
      minWidth: 150,
      align: "center",
    },
    {
      label: "SITE-AUDIT",
      key: "site_audit",
      minWidth: 170,
      align: "center",
    },
    {
      label: "DOMAIN-OVERVIEW",
      key: "domain_overview",
      minWidth: 100,
      align: "center",
    },
    {
      label: "KEYWORD RESEARCH",
      key: "keyword_research",
      minWidth: 150,
      align: "center",
    },
    {
      label: "BACKLINKS",
      key: "backlinks",
      minWidth: 180,
      align: "center",
    },
    {
      label: "PDF",
      key: "pdf",
      minWidth: 130,
      align: "center",
    },
    {
      label: "",
      key: "action",
      minWidth: 50,
      align: "center",
    },
  ];

  function createData(
    email,
    domain_overview,
    backlinks,
    keyword_research,
    pdf,
    projects,
    site_audit,
    track_key,
    action
  ) {
    return {
      email,
      domain_overview,
      backlinks,
      keyword_research,
      pdf,
      projects,
      site_audit,
      track_key,
      action,
    };
  }

  const loadCSVData = () => {
    if (AddOnData?.data?.length === 0) return [];
    return AddOnData?.data?.map((item) => {
      return createData(
        item.email,
        item.dom_over,
        item.backlinks,
        item.key_res,
        item.pdf,
        item.project,
        item.site_audit,
        item.track_key
      );
    });
  };

  const rows = useMemo(() => {
    if (AddOnData?.data?.length === 0) return [];
    return AddOnData?.data?.map((item) => {
      return createData(
        item.email,
        item.dom_over,
        item.backlinks,
        item.key_res,
        item.pdf,
        item.project,
        item.site_audit,
        item.track_key,
        <span
          style={{ color: "rgb(162, 68, 68)", fontSize: "18px" }}
          onClick={() => setSelectedUser(item)}
        >
          <i className="fa-solid fa-edit"></i>
        </span>
      );
    });
  }, [AddOnData]);

  const handleAddLimits = (addressData) => {
    if (!SelectedUser?.id) return;
    axios
      .put(UPDATE_ADDON(SelectedUser?.id), addressData)
      .then(() => {
        setAlert({
          type: "success",
          message: "AddOn Limits Updated Successfully",
          show: true,
        });
        fetchData();
        setSelectedUser([]);
      })
      .catch(() => {
        setAlert({
          type: "error",
          message: "AddOn Limits Updation Failed",
          show: true,
        });
      });
  };

  if (AddOnData.loading) {
    return (
      <div>
        <h1 className="table_title heading-large font-bold mb-4">
          AddOn Plans
        </h1>
        <DottLoader />
      </div>
    );
  }

  return (
    <>
      <AlertPopUp config={Alert} handleClose={setAlert} />
      <CustomTable
        showSearchBar={true}
        tableTitle="AddOn Plans"
        tableHeader={tableHeader}
        rows={rows}
        page={page}
        setPage={setPage}
      />
      {SelectedUser?.length === 0 ? null : (
        <AddLimits
          {...{
            SelectedUser,
            setSelectedUser,
            handleAddLimits,
          }}
        />
      )}
    </>
  );
};

export default AddOnPlan;
