import axios from "axios";
import React from "react";
import { useNavigate } from "react-router-dom";

const GrowTraffic = () => {
  const navigate = useNavigate();
  const Traffic = () => {
    navigate("/rank-tracker");
    window.location.reload(false);
  };
  return (
    <div className="cmd-b">
      <pre className="mb-0 pb-0">
        <h6 className="heading-large font-bold mb-0">
          Thanks to choosing and Grow Traffic
        </h6>
      </pre>
      <button className="cm-btn" onClick={Traffic} style={{ height: "40px" }}>
        Done & Go to Home
      </button>
    </div>
  );
};

export default GrowTraffic;
