import React from "react";
import { useNavigate } from "react-router-dom";
import addProjIcon from "../../Assets/seoimg/add-proj.png";
import { Player } from "@lottiefiles/react-lottie-player";
import spinLoaderAnimation from "../../dataFiles/animation/add-proj.json";

const PlAdd = () => {
  const navigate = useNavigate();

  const gotologin = () => {
    localStorage.removeItem("ProjectsDetail");
    localStorage.removeItem("Locationdetails");
    localStorage.removeItem("CompetitorsDetails");
    navigate("/addpr");
  };

  return (
    <div className="pllogin-div">
      <div className="add-new-project">
        <div className="project-img">
          <img src={addProjIcon} alt="add-project" />
          <div className="add-proj-btn" onClick={gotologin}>
            <Player
              src={spinLoaderAnimation}
              className="player"
              loop
              autoplay
              speed={1}
              style={{ width: "80px", cursor: "pointer" }}
            />
          </div>
        </div>
        <div className="pl-con">
          <div>
            <h5>Please Add Your First Project</h5>
            <p>
              Add a project so you can track and <br></br>
              improve your SEO traffic.
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PlAdd;
