import React, { useRef, useState } from "react";
import "./AiGenerate.scss";
import { Link } from "react-router-dom";
import CustomButtonStyle from "../Mycomponent/share/components/button.component";
import logo from "./Assets/logo.png";
import { FormControl, MenuItem, Select, useMediaQuery } from "@mui/material";
import axios from "axios";
import { DottLoader1 } from "../Mycomponent/share/loader";
import { AiOutlineCopy } from "react-icons/ai";
import ToolNav from "../Mycomponent/share/components/tool-nav";
import { FooterTool } from "../Mycomponent/pages/Tools/Footer/Footer";
import SEC1 from "./Assets/sec1.png";

const AiBody = ({
  sm,
  Vibe,
  handleChange,
  handleSubmit,
  value,
  setValue,
  alert,
  setalert,
}) => {
  return (
    <div className="w-100">
      <div className="Aibody">
        <h3>Free Instagram Caption Generator</h3>
        <p>Generate AI-powered Instagram captions to boost your engagement.</p>
      </div>
      <div className="AiGenerate">
        <label htmlFor="">Description</label>{" "}
        {!sm && <span>Enter your topic(s) or keyword(s).</span>}
        <textarea
          name=""
          id=""
          cols="30"
          rows="6"
          placeholder="Type Something...."
          value={value}
          style={{ resize: "none" }}
          onChange={(e) => {
            setValue(e.target.value);
            value?.length > 0 && setalert(false);
          }}
        />
        <label htmlFor="" className="mt-3">
          Select Your Vibe
        </label>
        <FormControl sx={{ m: 1, maxWidth: "98%", minWidth: "98%" }}>
          <Select
            className="SetOverFLow"
            value={Vibe}
            onChange={handleChange}
            displayEmpty
            inputProps={{ "aria-label": "Without label" }}
          >
            <MenuItem value="" disabled selected>
              None
            </MenuItem>
            <MenuItem value={"fun"}>Fun</MenuItem>
            <MenuItem value={"funny"}>Funny</MenuItem>
            <MenuItem value={"happy"}>Happy</MenuItem>
            <MenuItem value={"serious"}>Serious</MenuItem>
            <MenuItem value={"sad"}>Sad</MenuItem>
            <MenuItem value={"angry"}>Angry</MenuItem>
            <MenuItem value={"ecstatic"}>Ecstatic</MenuItem>
            <MenuItem value={"curious"}>Curious</MenuItem>
            <MenuItem value={"informative"}>Informative</MenuItem>
            <MenuItem value={"cute"}>Cute</MenuItem>
            <MenuItem value={"cool"}>Cool</MenuItem>
            <MenuItem value={"controversial"}>Controversial</MenuItem>
          </Select>
        </FormControl>
        {alert && (
          <p
            style={{
              margin: "0 10px",
              color: "red",
              fontSize: ".9em",
              fontWeight: 600,
              marginTop: ".5em",
            }}
          >
            {" "}
            All Fields are Mandatory.
          </p>
        )}
        <button onClick={handleSubmit}>Generate Caption</button>
      </div>
    </div>
  );
};

const AiGenerateCaption = () => {
  const sm = useMediaQuery("(max-width:600px)");
  const [Vibe, setVibe] = useState("");
  const [value, setValue] = useState("");
  const [Show, setShow] = useState(false);
  const [alert, setalert] = useState(false);
  const [isLoading, setisLoading] = useState(false);
  const [Copy, setCopy] = useState("");
  const [Data, setData] = useState([]);
  const Api = `https://app.rankspro.io/api/user/instacaption/?description=${value}&vibe=${Vibe}`;

  const handleChange = (event) => {
    setVibe(event.target.value);
  };

  const handleSubmit = () => {
    setShow(false);
    if (value && Vibe) {
      setisLoading(true);
      setalert(false);
      axios.post(Api).then((response) => {
        setData(response.data);
        setShow(true);
        setisLoading(false);
      });
    } else {
      setShow(false);
      setalert(true);
    }
  };

  const HandleCopied = (value, index) => {
    navigator.clipboard.writeText(value);
    setCopy(index);
    setTimeout(() => {
      setCopy(100);
    }, 2000);
  };

  const Section1Ul = [
    "Enter your topic or keywords such as food delivery, education, hotel, home loan, etc.",
    "Choose your vibe such as funny, happy, serious, sad, etc.",
  ];

  return (
    <>
      <div className="AiContainerCap">
        <ToolNav />
        <div className="AiChild">
          <AiBody
            {...{
              sm,
              handleChange,
              handleSubmit,
              value,
              setValue,
              alert,
              setalert,
              Vibe,
              setVibe,
            }}
          />
        </div>
        {isLoading ? (
          <div className="d-flex justify-content-center align-items-center">
            <DottLoader1 />
          </div>
        ) : (
          Show &&
          Data?.map((val, index) => {
            return (
              <div className="AiOutput">
                <div className="row">
                  <div className=" col-lg-10 col-md-9  d-flex justify-content-start align-items-center">
                    <p>{val}</p>
                  </div>
                  <div className="col-lg-2 col-md-3">
                    <button
                      onClick={() => HandleCopied(val, index)}
                      id={index}
                      style={{
                        background: Copy === index ? "Green" : "#365486",
                      }}
                    >
                      {" "}
                      <AiOutlineCopy className="me-2" />
                      {Copy === index ? "Copied" : "Copy"}
                    </button>
                  </div>
                </div>
              </div>
            );
          })
        )}
        {/* Body Start */}
        <div className="BackLinkBody">
          <div className="BackBox">
            <h5>
              Free Instagram Caption Generator: Your Shortcut to Get Creative
              and Engaging Instagram Captions
            </h5>
            <p>
              Your Instagram captions can either turn your visitors into your
              followers or make them skip your Insta post and move on to the
              next. Our Free Instagram Caption Generator is an AI tool that can
              help you come up with creative and attention-grabbing captions for
              Instagram posts and stories. It takes just a few seconds to
              generate captions that prompt audience engagement and interaction.
            </p>

            <h5>How to use it?</h5>
            <ul className="ms-4 mt-4 ">
              {Section1Ul?.map((Li) => (
                <li key={Li}>{Li}</li>
              ))}
            </ul>
            <div className="IMGbox">
              <img src={SEC1} alt="secOne " />
            </div>

            <p>
              The tool will come up with a list of innovative ideas in just a
              few seconds, so you can choose the best caption to suit your
              theme.
            </p>
            <br />
            <hr />
            <div className="secEnd">
              <div className="row">
                <div className="col-md-9 Leftb">
                  <h3>
                    Try our free Instagram generator today to get your creative
                    juices flowing!
                  </h3>
                </div>
                {/* <div className="col-md-3 Rightb">
                                    <a href="https://app.rankspro.io/register" target="_blank">
                                        <button className="btnNormal">
                                            Sign up for a free
                                        </button>
                                    </a>
                                </div> */}
              </div>
            </div>
          </div>
        </div>
        <FooterTool />
      </div>
    </>
  );
};

export default AiGenerateCaption;
