import axios from "axios";
import React, { useEffect, useState } from "react";
import { DIGITAL_AGENCY_GET } from "../../Affiliate/utils/constant";
import { FaCircleCheck, FaRegCircleCheck } from "react-icons/fa6";
import { AiOutlineDelete } from "react-icons/ai";
import { RxCrossCircled } from "react-icons/rx";
import { HiOutlineBackward } from "react-icons/hi2";
import { useNavigate } from "react-router-dom";
import { PUT_UPDATE_REVIEW } from "../../services/constants";
import AlertPopUp from "../../Mycomponent/share/components/alert.component";

const Reviews = () => {
  const nav = useNavigate();
  const ID = localStorage.getItem("ACCESS_REVIEW_FROM_PANEL");
  const [Alert, setAlert] = useState({
    type: "",
    message: "",
    show: false,
  });
  const [allAgencyData, setallAgencyData] = useState([]);
  const [SingleData, setSingleData] = useState({});
  useEffect(() => {
    axios.get(DIGITAL_AGENCY_GET()).then(({ data }) => {
      if (data) {
        const Filter = data?.filter((FilterData) => ID == FilterData.id);
        setallAgencyData([]);
        if (Filter) setallAgencyData(Filter[0]?.review);
        setSingleData(Filter[0]);
      }
    });
  }, []);

  const UpdateReview = (email, type) => {
    const PAYLOAD = { ...SingleData };
    const reviewArr = SingleData?.review;
    const newArr = reviewArr?.map((item) => {
      if (item.email === email) {
        item.approve = type === "Approve" ? true : false;
        return item;
      } else {
        return item;
      }
    });
    PAYLOAD.review = newArr;
    axios.put(PUT_UPDATE_REVIEW(ID), PAYLOAD).then((output) => {
      setAlert({
        type: "success",
        message: type,
        show: true,
      });
    });
    setSingleData(PAYLOAD);
  };

  return (
    <>
      <AlertPopUp config={Alert} handleClose={setAlert} />
      <button className="btn_1 mb-3" onClick={() => nav(-1)}>
        <span className="me-2">
          <HiOutlineBackward />
        </span>
        Back
      </button>
      <table className="table m-0">
        <thead>
          <tr>
            <th className="col ">EMAIL</th>
            <th className="col">Reviews</th>
            <th className="col ">Rating</th>
            <th className="col ">Actions</th>
          </tr>
        </thead>
        <tbody>
          <React.Fragment>
            {SingleData?.review?.length === 0 ? (
              <tr>
                <td colSpan={5}>No Data Found </td>
              </tr>
            ) : (
              SingleData?.review?.map((Elem, index) => {
                return (
                  <tr key={index}>
                    <td className="p-3">{Elem?.email}</td>
                    <td className="p-3">
                      {`${Elem?.review.substring(0, 70)}...`}
                    </td>
                    <td className="p-3">{Elem?.rating}</td>
                    <td className="">
                      <div className="BUTTONS_DIGITAL">
                        {Elem?.show === false && (
                          <abbr title="Approve">
                            <button className="me-2 green">
                              <span>
                                {" "}
                                <FaCircleCheck />
                              </span>
                            </button>
                          </abbr>
                        )}
                        {!Elem?.approve ? (
                          <abbr title="Approve">
                            <button
                              onClick={() =>
                                UpdateReview(Elem.email, "Approve")
                              }
                              className="me-2"
                              style={{ backgroundColor: "green" }}
                            >
                              <span className="me-1">
                                <FaRegCircleCheck />
                              </span>
                              Approve
                            </button>
                          </abbr>
                        ) : (
                          <abbr title="Reject">
                            <button
                              onClick={() => UpdateReview(Elem.email, "Reject")}
                              className="me-2"
                              style={{ backgroundColor: "orange" }}
                            >
                              <span className="me-1">
                                <RxCrossCircled />
                              </span>
                              Reject
                            </button>
                          </abbr>
                        )}
                      </div>
                    </td>
                  </tr>
                );
              })
            )}
          </React.Fragment>
        </tbody>
      </table>
    </>
  );
};

export default Reviews;
