import axios from "axios";
import React, { useEffect, useState } from "react";
import {
  ADMINUSER_DETAIL,
  ADMIN_PASSWORD_CHANGE,
  ADMIN_USERS,
  CODEVALID_GET,
  DELETE_REFERRALCODE,
  GET_ALL_USER_LAST_LOGIN_LIST,
  GET_USER_ACC_USAGE,
  POST_CODEVALID,
  POST_USEDCODES,
  POSt_STRIPE_UPDATE,
  PROJECT_GET,
  Reffral_Get,
  USERPROJECT_GET,
  codevalidupdate,
  delete_User,
  get_Plans_Details,
} from "../../services/constants";
import { DottLoader } from "../../Mycomponent/share/loader";
import AreYousure from "../../Mycomponent/share/card-layout/AreYousure";
import { AddBox, Email } from "@mui/icons-material";
import CustomTable from "../../Mycomponent/share/customTable";
import DeleteIcon from "@mui/icons-material/Delete";
import { GoDash } from "react-icons/go";
import ToolTip from "../../Mycomponent/share/components/tooltip";
import { Box, Button } from "@mui/material";
import { CSVLink } from "react-csv";
import { referral_plan } from "../../Mycomponent/dataFiles/referral-plan";

const User = () => {
  const [Users, setUsers] = useState([]);
  const [UserId, setUserId] = useState(null);
  const [ShowAlert, setShowAlert] = useState(false);
  const [infoAlert, setInfoAlert] = useState(false);
  const [passwordAlert, setPasswordAlert] = useState(false);
  const [password, setPassword] = useState("");
  const [userDetails, setUserDetails] = useState([]);
  const [cusKeyCount, setCusKeycount] = useState(0);
  const [cusAllPrName, setCusAllPrName] = useState([]);
  const [accUsage, setAccUsage] = useState([]);
  const [loading, setLoading] = useState(true);
  const [userLoading, setUserLoader] = useState(true);
  const [page, setPage] = React.useState(1);

  // user plan change based on type
  const [name, setName] = useState("alpha");
  const [validityFor, setValidityFor] = useState("month");
  const [priceId, setPriceId] = useState(null);
  const [userPlanType, setPlanType] = useState(false);

  const [allplans, setAllplnas] = useState([]);
  const [allCodes, setAllcodes] = useState([]);
  const [selectedCodes, setSelectedCodes] = useState([]);

  const [lastLoginList, setLastLoginList] = useState([]);
  const [allProjectList, setAllProjectList] = useState([]);
  const [showCSVBtn, setShowCSVBtn] = useState(false);

  const getUserAccountUsgae = (email) =>
    axios.get(GET_USER_ACC_USAGE(email)).then((res) => {
      const data = res.data;
      setAccUsage(data);
    });

  const getLastLoginAllUsersList = () =>
    axios.get(GET_ALL_USER_LAST_LOGIN_LIST()).then((res) => {
      const data = res.data;
      setLastLoginList(data);
    });

  const fatchAdmin_users = () =>
    axios.get(ADMIN_USERS()).then((res) => {
      const data = res.data;
      setUsers(data.reverse());
      setUserLoader(false);
    });

  const fatch_Plan_Details = () => {
    axios.get(get_Plans_Details()).then((res) => {
      setAllplnas(res.data.data);
    });
  };

  const fatch_Codes = () => {
    axios.get(Reffral_Get()).then((res) => {
      let arr = [];
      res.data.data.map((res) => {
        arr.push(res.codes);
      });
      setAllcodes(arr.flat());
      setLoading(false);
    });
  };

  useEffect(() => {
    const fatch_AllProject_Data = () =>
      axios.get("https://app.rankspro.io/api/user/adminuser/").then((res) => {
        const projectDatalist = res.data;
        setAllProjectList(projectDatalist);
        setShowCSVBtn(true);
      });
    fatch_AllProject_Data();
  }, []);

  const getProject = (email) => {
    const data = allProjectList?.filter((item) => {
      return item.email === email;
    });
    return data?.[0];
  };

  const deleteUser = (res) => {
    if (res) {
      axios.delete(delete_User(UserId)).then(() => {
        setShowAlert(false);
        setInfoAlert(false);
        setUserId(null);
        fatchAdmin_users();
      });
    } else {
      setShowAlert(false);
    }
  };

  useEffect(() => {
    fatchAdmin_users();
    fatch_Plan_Details();
    fatch_Codes();
    getLastLoginAllUsersList();
  }, []);

  const showAlert = (e) => {
    setShowAlert(true);
    setUserId(e);
  };

  function isEsearchlogixEmail(email) {
    // Regular expression to match the domain part of the email
    const domainRegex =
      /@[a-zA-Z0-9._-]+\.([a-zA-Z]{2,}|[a-zA-Z]{2}\.[a-zA-Z]{2})$/;
    // Check if the email matches the domain
    return (
      domainRegex.test(email) &&
      (email.includes("@esearchlogix.com") ||
        email.includes("@esearchlogix.in"))
    );
  }

  const infoHandler = (user) => {
    setUserDetails([]);
    setCusKeycount(0);
    setCusAllPrName("NO Projects");
    setInfoAlert(true);
    const email = user.email;
    getUserAccountUsgae(email);
    axios.get(USERPROJECT_GET(email)).then((usPrRes) => {
      const userAllProjectDetails = usPrRes.data.data;
      const userAllProject = userAllProjectDetails
        .map((res) => res.Project)
        .flat();
      if (userAllProject.length !== 0) {
        // --------------------------Start Getting User project length--------------------------------//
        const uniqueWebURLs = [
          ...new Set(userAllProject.map((item) => item.weburl)),
        ];

        if (isEsearchlogixEmail(email)) {
          setCusAllPrName("Inhouse Project");
        } else {
          setCusAllPrName(JSON.stringify(uniqueWebURLs));
        }
        // --------------------------END Getting User project length--------------------------------//

        // --------------------------Start Getting project keyword length--------------------------------//
        let totalKeywordCount = 0;
        userAllProject?.reduce((acc, item) => {
          const key = `${item.weburl}_${item.deviceType}_${item.location_code}`;
          if (!acc[key]) {
            acc[key] = {
              count: 0,
            };
          }
          const uniqueKeywords = new Set(item.keyword); // Use a Set to remove duplicate keywords
          const keywordCount = uniqueKeywords.size; // Count the unique keywords
          acc[key].count += keywordCount;
          totalKeywordCount += keywordCount;
          return acc;
        }, {});
        setCusKeycount(totalKeywordCount);
        // --------------------------End Getting project keyword length--------------------------------//
      }

      axios.get(ADMINUSER_DETAIL(user)).then((res) => {
        setUserDetails(res.data[0]);
      });
    });
  };

  const tableHeader = [
    {
      label: "NAME",
      key: "name",
      minWidth: 100,
      maxWidth: 150,
      align: "left",
    },
    {
      label: "E-MAIL",
      key: "email",
      minWidth: 150,
      width: "25%",
      align: "left",
    },
    {
      label: "CREATED AT",
      key: "created_at",
      minWidth: 150,
      align: "center",
      width: "25%",
    },
    {
      label: "LAST LOGIN",
      key: "last_login",
      minWidth: 150,
      align: "center",
      width: "25%",
    },
    {
      label: "PROJECTS",
      key: "no_of_projects",
      minWidth: 151,
      align: "center",
      width: "25%",
    },
    {
      label: "KEYWORDS",
      key: "no_of_keywords",
      minWidth: 155,
      align: "center",
      width: "25%",
    },
    {
      label: "PAID",
      key: "paid",
      minWidth: 150,
      align: "center",
      width: "25%",
    },
    {
      label: "PLAN",
      key: "subscription",
      minWidth: 150,
      align: "center",
      width: "25%",
    },
    {
      label: "",
      key: "space",
      minWidth: 100,
      align: "center",
      width: "25%",
    },
  ];

  function createData(
    id,
    name,
    email,
    created_at,
    last_login,
    no_of_projects,
    no_of_keywords,
    paid,
    subscription,
    space,
    action
  ) {
    return {
      id,
      name,
      email,
      created_at,
      last_login,
      no_of_projects,
      no_of_keywords,
      paid,
      subscription,
      space,
      action,
    };
  }

  const formatDate = (date) => {
    const dateObject = new Date(date);
    // Extract date components
    const year = dateObject.getFullYear();
    const month = dateObject.getMonth() + 1; // Months are zero-indexed
    const day = dateObject.getDate();
    // Extract time components
    const hours = dateObject.getHours();
    const minutes = dateObject.getMinutes();

    // Determine AM/PM
    const ampm = hours >= 12 ? "PM" : "AM";

    return `${year}-${month < 10 ? "0" : ""}${month}-${
      day < 10 ? "0" : ""
    }${day}, ${hours}:${minutes < 10 ? "0" : ""}${minutes} ${ampm}`;
  };

  function getDateByEmail(email) {
    const matchingUser = Users?.find((user) => user.email === email);
    if (matchingUser) {
      const matchingLoginUser = lastLoginList?.find(
        (user) => user.email === email
      );
      return matchingLoginUser ? (
        formatDate(matchingLoginUser?.date)
      ) : (
        <GoDash style={{ color: "black" }} />
      );
    }
  }

  const rows = Users?.map((item) => {
    return createData(
      item?.id,
      item?.name,
      item?.email,
      item?.created_at,
      getDateByEmail(item?.email),
      getProject(item?.email)?.Project || 0,
      getProject(item?.email)?.keyword || 0,
      getProject(item?.email)?.paid === "Referral"
        ? getProject(item?.email)?.paid
        : getProject(item?.email)?.paid > 0
        ? `$${getProject(item?.email)?.paid / 100}`
        : "$0",
      getProject(item?.email)?.subscription || 0,
      <span onClick={() => showAlert(item?.id)}>
        <DeleteIcon sx={{ color: "rgb(162, 68, 68)" }} />
      </span>,
      () => infoHandler(item)
    );
  });

  const csv_header = [
    {
      label: "NAME",
      key: "name",
    },
    {
      label: "E-MAIL",
      key: "email",
    },
    {
      label: "CREATED AT",
      key: "created_at",
    },
    {
      label: "LAST LOGIN",
      key: "last_login",
    },
    {
      label: "NO. OF PROJECTS",
      key: "no_of_projects",
    },
    {
      label: "NO. OF KEYWORDS",
      key: "no_of_keywords",
    },
    {
      label: "PAID",
      key: "paid",
    },
    {
      label: "SUBSCRIPTION",
      key: "subscription",
    },
  ];

  const loadCSVData = () => {
    if (rows?.length === 0) return [];
    return Users?.map((item) => {
      const obj = {
        name: item?.name || "",
        email: item?.email || "",
        created_at: item?.created_at || "",
        last_login: getDateByEmail(item?.email) || "",
        no_of_projects: getProject(item?.email)?.Project || 0,
        no_of_keywords: getProject(item?.email)?.keyword || 0,
        paid:
          getProject(item?.email)?.paid === "Referral"
            ? getProject(item?.email)?.paid
            : getProject(item?.email)?.paid > 0
            ? `$${getProject(item?.email)?.paid / 100}`
            : "$0",
        subscription: getProject(item?.email)?.subscription || 0,
      };
      return obj;
    });
  };

  const passwordHandler = () => {
    if (password === "") {
      setPasswordAlert("Please Enter Passsword");
    } else {
      const item = {
        email: userDetails?.email,
        password: password,
      };
      axios.post(ADMIN_PASSWORD_CHANGE(item)).then(() => {
        setPasswordAlert(false);
        setPassword("");
      });
    }
  };

  useEffect(() => {
    setPriceId(null);
    if (userPlanType === "Plan") {
      if (name === "pro") {
        setSelectedCodes(allCodes.slice(0, 1));
      } else if (name === "elite") {
        setSelectedCodes(allCodes.slice(0, 2));
      } else if (name === "ultimate") {
        setSelectedCodes(allCodes.slice(0, 3));
      } else {
        setSelectedCodes([]);
      }
    } else {
      const plancheck = allplans.filter(
        (res) => res.name === name && res.validity === validityFor
      );
      setPriceId(plancheck[0]?.price_id);
    }
  }, [name, validityFor, userDetails]);

  const planHandler = () => {
    if (userPlanType === "Plan") {
      axios.get(CODEVALID_GET()).then((res) => {
        const referralUser = res.data.find(
          (user) => user.email === userDetails.email
        );
        // getting future date for plan expiry .
        const currentDate = new Date();
        const futureDate = new Date();
        futureDate.setDate(
          currentDate.getDate() + Number(validityFor === "month" ? 30 : 30)
        );
        const futureDay = futureDate.getDate();

        const reffralItems = {
          email: userDetails.email,
          code_valid: selectedCodes,
          valid: validityFor,
          code_name: name,
          expiry_date: validityFor === "lifetime" ? 100 : futureDay,
        };
        const usedCodes_Json = {
          plan_name: reffralItems.code_name,
          codes: reffralItems.code_valid,
          validity: reffralItems.valid,
        };
        const json_DELETE = JSON.stringify(reffralItems.code_valid);

        if (referralUser) {
          axios.put(codevalidupdate(referralUser.id), reffralItems).then(() => {
            setPlanType(false);
            setInfoAlert(false);

            axios.post(POST_USEDCODES(), usedCodes_Json);
            axios.put(DELETE_REFERRALCODE(json_DELETE)).then(() => {
              // this function reset the data of referal_get variable
            });
          });
        } else {
          axios.post(POST_CODEVALID(), reffralItems).then(() => {
            setPlanType(false);
            setInfoAlert(false);

            axios.post(POST_USEDCODES(), usedCodes_Json);
            axios.put(DELETE_REFERRALCODE(json_DELETE)).then(() => {
              // this function reset the data of referal_get variable
            });
          });
        }
      });
    } else {
      const item = {
        email: userDetails?.email,
        priceId: priceId,
      };
      axios
        .put(POSt_STRIPE_UPDATE(item))
        .then(() => {
          setPlanType(false);
          setInfoAlert(false);
        })
        .catch(() => {
          setPriceId("User not Registered in Stripe");
        });
    }
  };

  const userData = [
    {
      title: "Email Address",
      data: userDetails?.email,
    },
    {
      title: "Full Name",
      data: userDetails?.name,
    },
    {
      title: "Created",
      data: userDetails?.created_at,
    },
    {
      title: "Last Login",
      data: <span>{getDateByEmail(userDetails?.email)}</span>,
    },
    {
      title: "Code",
      data: userDetails?.code,
    },
    {
      title: "Validity",
      data: userDetails?.validity,
    },
    {
      title: "Plan",
      data: userDetails?.plan_name,
    },
    {
      title: "Subscription",
      data: userDetails?.Subscription,
    },
    {
      title: "Projects",
      data: cusAllPrName,
    },
    {
      title: "Keywords",
      data: cusKeyCount,
    },
  ];

  function checkIfAdmin() {
    const isAdmin = ["info@esearchlogix.com", "singhashish1.sh@gmail.com"];
    return isAdmin.includes(userDetails?.email);
  }

  const admin_limit = {
    id: 108,
    proj_len: "100",
    keyword_len: "100000",
    sub_users_limit: "100000",
    competitors_limit: 6,
    site_overview_limit: 100000,
    site_audit_limit: 20000,
    crawl_domain_limit: 15,
    key_limit: 500,
    key_list_lmt: 20,
    backlinks_domain_lmt: 1000000,
    backlinks_rows_limit: 1000000,
  };

  const codeValid =
    (userDetails?.code &&
      userDetails?.code != "No Code" &&
      userDetails?.code) ||
    [];
  const codeValidJsonString =
    (userDetails?.code &&
      userDetails?.code != "No Code" &&
      codeValid?.replace(/'/g, '"')) ||
    [];
  const codeValidJsonStringParsedArray =
    (userDetails?.code &&
      userDetails?.code != "No Code" &&
      codeValidJsonString &&
      JSON.parse(codeValidJsonString)) ||
    [];

  const getCurrPlanLimit = () => {
    const isAdmin = checkIfAdmin();
    const free_plan = allplans?.filter((res) => {
      return res.name === "alpha";
    });
    const user_plan = allplans?.filter((res) => {
      return (
        res.name === userDetails?.plan_name && res.validity === validityFor
      );
    });

    const curr_plan_detail =
      userDetails?.plan_name === "No Plan" ? free_plan?.[0] : user_plan?.[0];

    const subsrc = userDetails?.Subscription?.split(" ");
    const part = subsrc?.[0]?.toLowerCase();
    const subscription_plan = allplans?.filter((res) => {
      return res.name === part;
    });

    return isAdmin
      ? admin_limit
      : codeValidJsonStringParsedArray?.length > 0
      ? referral_plan?.[codeValidJsonStringParsedArray?.length - 1]
      : userDetails?.Subscription === "Free"
      ? curr_plan_detail
      : subscription_plan?.[0];
  };

  const curr_plan = getCurrPlanLimit() || [];

  if (loading) {
    return (
      <>
        <h1 className="table_title font-bold heading-large mb-4">Users</h1>
        <DottLoader />
      </>
    );
  }

  return (
    <div>
      {infoAlert ? (
        userDetails.length !== 0 ? (
          <div className="Box UserDetails">
            <div>
              <div className="cmd">
                <div>
                  {" "}
                  <h2 className="table_title">User Details</h2>
                </div>
                <button className="cm-btn" onClick={() => setInfoAlert(false)}>
                  Back
                </button>
              </div>
              {userData?.map((res, index) => {
                return (
                  <div
                    key={index}
                    className="userDetail_Admin"
                    onClick={() => {
                      setPlanType(res.title);
                    }}
                  >
                    {res.title === "Subscription" || res.title === "Plan" ? (
                      <>
                        <p>
                          {res.title}
                          <i
                            style={{ cursor: "pointer" }}
                            className="fa-solid fa-edit ms-1"
                          ></i>
                        </p>
                        <h6>
                          {codeValidJsonStringParsedArray?.length > 0 &&
                          res.title === "Plan"
                            ? "Appsumo Lifetime Plan"
                            : res.data}
                        </h6>
                      </>
                    ) : (
                      <>
                        <p>{res.title}*</p>
                        <h6>{res.data}</h6>
                      </>
                    )}
                  </div>
                );
              })}
              <div className="userDetail_Admin">
                <p>Account Usage*</p>
                <div className="d-flex acc-usage-container">
                  <div className="userDetail_Admin acc-elem">
                    <p>
                      Total Backlink Limit
                      <ToolTip title="Total Backlink Limit utilized" />
                    </p>
                    <h6>
                      {Number(accUsage["total_backlink_lmt"]) ||
                      accUsage["total_backlink_lmt"] === 0
                        ? accUsage["total_backlink_lmt"]
                        : accUsage["total_calc_backlink_lmt"] >
                            curr_plan?.backlinks_rows_limit ||
                          accUsage["total_calc_backlink_lmt"] < 0
                        ? curr_plan?.backlinks_rows_limit
                        : curr_plan?.backlinks_rows_limit -
                          accUsage["total_calc_backlink_lmt"]}{" "}
                      / {curr_plan?.backlinks_rows_limit}
                    </h6>
                  </div>
                  <div className="userDetail_Admin acc-elem">
                    <p>
                      Total Site-Audit
                      <ToolTip title="Total Site-Audit utilized" />
                    </p>
                    <h6>
                      {curr_plan?.site_audit_limit *
                        accUsage?.total_domain_crawled >=
                      accUsage?.total_siteaudit
                        ? curr_plan?.site_audit_limit *
                            accUsage?.total_domain_crawled -
                          accUsage?.total_siteaudit
                        : curr_plan?.site_audit_limit}
                      /{" "}
                      {curr_plan?.site_audit_limit *
                        curr_plan?.crawl_domain_limit}
                    </h6>
                  </div>
                  <div className="userDetail_Admin acc-elem">
                    <p>
                      Total Site-Audit Domain
                      <ToolTip title="Total Site-Audit Domain utilized" />
                    </p>
                    <h6>
                      {accUsage?.total_domain_crawled} /{" "}
                      {curr_plan?.crawl_domain_limit}
                    </h6>
                  </div>
                  <div className="userDetail_Admin acc-elem">
                    <p>
                      Total Keyword Limit
                      <ToolTip title="Total Keyword Research Limit utilized" />
                    </p>
                    <h6>
                      {accUsage?.total_keyword_limit} / {curr_plan?.key_limit}
                    </h6>
                  </div>
                  <div className="userDetail_Admin acc-elem">
                    <p>
                      Total Keyword Tracking
                      <ToolTip title="Total Keyword Tracked utilized" />
                    </p>
                    <h6>
                      {accUsage?.total_keyword_tracking} /{" "}
                      {curr_plan?.keyword_len}
                    </h6>
                  </div>

                  <div className="userDetail_Admin acc-elem">
                    <p>
                      Total Overview Limit
                      <ToolTip title="Total Site Overview Limit utilized" />
                    </p>
                    <h6>
                      {accUsage?.total_overview_limit} /{" "}
                      {curr_plan?.site_overview_limit}
                    </h6>
                  </div>
                </div>
              </div>

              <div className="mt-4 ">
                <button
                  className="cm-btn me-3"
                  onClick={() => setPasswordAlert(true)}
                >
                  Change Password
                </button>
                <button
                  className="cm-btn me-3 "
                  onClick={() => showAlert(userDetails.id)}
                >
                  Delete User
                </button>
                {/* <button className='cm-btn' onClick={() => setEditAlert(true)}>Edit User</button> */}
              </div>
            </div>
          </div>
        ) : (
          <DottLoader />
        )
      ) : (
        <>
          <CustomTable
            showSearchBar={true}
            type="user_list"
            rows={rows}
            page={page}
            setPage={setPage}
            tableHeader={tableHeader}
            dataLoader={userLoading}
            tableTitle="Users"
            downloadCSVFile={
              showCSVBtn && (
                <CSVLink
                  headers={csv_header}
                  data={loadCSVData()}
                  filename={"user.csv"}
                >
                  <Button className="btn_1 ms-2">Download CSV</Button>
                </CSVLink>
              )
            }
          />
        </>
      )}

      {ShowAlert ? (
        <AreYousure
          callback={deleteUser}
          title={"you will not able to recover this User"}
        />
      ) : null}

      {passwordAlert ? (
        <div className="pop" onClick={() => setShowAlert(false)}>
          <div className="popBody">
            <h3 className="mb-3">Change Password</h3>
            <input
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              type="password"
              placeholder="Enter Password"
            ></input>
            <p className="vl-msd-line  m-0">
              {" "}
              <span>{passwordAlert}</span>
            </p>
            <div className="cmd">
              <button
                onClick={() => {
                  setPasswordAlert(false);
                  setPassword("");
                }}
                className="cm-btn-b"
              >
                {" "}
                Cancel
              </button>
              <button onClick={() => passwordHandler()} className="cm-btn">
                {" "}
                Save
              </button>
            </div>
          </div>
        </div>
      ) : null}

      {userPlanType === "Subscription" || userPlanType === "Plan" ? (
        <div className="pop" onClick={() => setShowAlert(false)}>
          <div className="popBody">
            <h3 className="mb-3">Change Plan</h3>
            <div className="pop-form " style={{ textAlign: "left" }}>
              <div className="pop-select">
                <select value={name} onChange={(e) => setName(e.target.value)}>
                  <option value="starter">starter</option>
                  <option value="pro">pro</option>
                  <option value="elite">elite</option>
                  <option value="ultimate">ultimate</option>
                </select>
                <label id="lb"> Plan Name </label>
              </div>
              <div className="pop-select">
                <select
                  value={validityFor}
                  onChange={(e) => setValidityFor(e.target.value)}
                >
                  <option value="month">Month</option>
                  <option value="lifetime">lifetime</option>
                </select>
                <label id="lb">Validity For </label>
              </div>
              <p className="vl-msd-line ">
                {" "}
                <span>
                  {priceId === "User not Registered in Stripe"
                    ? priceId
                    : false}
                </span>
              </p>
            </div>

            <div className="cmd">
              <button
                onClick={() => {
                  setPlanType(false);
                }}
                className="cm-btn-b"
              >
                {" "}
                Cancel
              </button>
              <button onClick={() => planHandler()} className="cm-btn">
                {" "}
                Save
              </button>
            </div>
          </div>
        </div>
      ) : null}
    </div>
  );
};

export default User;
