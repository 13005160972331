import React from "react";
import { useDispatch, useSelector } from "react-redux";
import Dropdown from "../components/dropdown";
import { ZustandStore } from "../../../store/zustand-store";
import { useLocation } from "react-router-dom";

const ProjectList = (props) => {
  const location = useLocation();
  const dispatch = useDispatch();
  const { emptyKeywordData, emptyAllLocData, emptyTagData } = ZustandStore(
    (state) => state
  );
  const websiteurl = useSelector((state) => state.websiteurl);
  const userallprojectname = useSelector((state) => state.userallprojectname);

  const SelectPrName = (e) => {
    if (e === websiteurl) return;
    sessionStorage.setItem("LAST_PRPJECT_SELECTED", e);
    emptyTagData();
    emptyAllLocData();
    emptyKeywordData();
    dispatch({
      type: "USERCURRENTPROJECTLOCATION",
      payload: [],
    });
    dispatch({ type: "WEBSITEURL", payload: e });
    dispatch({ type: "CURRENTLOCATIONCODE", payload: null });
    dispatch({ type: "CURRENTLOCATION", payload: null });
    dispatch({ type: "LOADING" });
  };

  return (
    <>
      {location?.pathname === "/guest/rank-tracker" ? (
        <Dropdown
          data={[]}
          title={websiteurl || "Add Project"}
          style={
            props.style
              ? props.style
              : {
                  width: "70%",
                  textTransform: "none",
                  backgroundColor: "#fff",
                  boxShadow: "none",
                  borderRadius: "10px",
                }
          }
          callback={() => {}}
        />
      ) : (
        <Dropdown
          data={userallprojectname}
          title={websiteurl || "Add Project"}
          style={
            props.style
              ? props.style
              : {
                  width: "70%",
                  textTransform: "none",
                  backgroundColor: "#fff",
                  boxShadow: "none",
                  borderRadius: "10px",
                }
          }
          callback={SelectPrName}
        />
      )}
    </>
  );
};

export default React.memo(ProjectList);
