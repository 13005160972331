import React, { useState, useMemo, useEffect } from "react";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Filler,
  Legend,
} from "chart.js";
import { Line } from "react-chartjs-2";

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Filler,
  Legend
);

const CommonLineChart = ({ label, chartdata, chartlabels }) => {
  const [gradient, setGradient] = useState("");
  const options = {
    maintainAspectRatio: false,
    responsive: true,
    interaction: { mode: "index", intersect: false },
    plugins: {
      legend: {
        position: "top",
        display: false,
      },
      title: {
        display: false,
      },
      tooltip: {
        displayColors: false,
        callbacks: {
          labelPointStyle: (context) => {
            return {
              pointStyle: "rect",
              backgroundColor: "white",
              color: "white", // Set the background color to white
            };
          },
        },
        boxPadding: 5,
        cornerRadius: 10,
        caretSize: 10,
        padding: 10,
        margin: 10,
        boxHeight: 30,
        borderWidth: 1,
        borderColor: "#00000033",
        boxWidth: 4,
        titleColor: "black",
        bodyColor: "black",
        bodyFont: {
          size: 14,
          weight: 500,
          font: "Lato",
        },
        bodySpacing: 10,
        usePointStyle: true,
        backgroundColor: "rgba(255, 255, 255, 0.9)",
        titleFont: {
          weight: 600,
          size: 13,
          font: "Lato",
        },
      },
    },
    scales: {
      y: {
        position: "left",
        reverse: false,
        ticks: {
          display: true,
          min: 0,
          max: 100,
          stepSize: 10,
          maxTicksLimit: 7,
          beginAtZero: true,
          size: 16,
          color: "black",
          weight: 500,
          font: "Lato",
        },
        grid: { display: false, color: "#b8b0b0b8" },
      },
      x: {
        color: "black",
        ticks: {
          display: false,
        },
        grid: {
          display: false,
          color: "#dcdcdc",
        },
        ticks: {
          display: false,
          size: 16,
          weight: 500,
          font: "Lato",
        },
      },
    },
  };

  const labels = chartlabels;

  useEffect(() => {
    var gradient1;
    if (document.getElementById("line-chart") !== null) {
      var ctx = document.getElementById("line-chart").getContext("2d");
      gradient1 = ctx.createLinearGradient(100, 80, 100, 200);
      gradient1.addColorStop(0, "rgba(59, 161, 197, 0.7)");
      gradient1.addColorStop(1, "rgba(59, 161, 197, 0.1)");
      setGradient(gradient1);
    }
  }, [chartdata, label, chartlabels]);

  const data = {
    labels,
    datasets: [
      {
        fill: "start",
        backgroundColor: gradient,
        label: label,
        data: chartdata,
        borderColor: "#3BA1C5",
        borderWidth: 2,
        tension: 0.1,
        pointRadius: chartdata?.length > 2 ? 0 : 3,
        pointHoverRadius: chartdata?.length > 90 ? 0 : 6,
        pointBackgroundColor: "#3BA1C5",
        pointBorderColor: "#3BA1C5",
      },
    ],
  };

  return (
    <div className="vertical-bar">
      <Line options={options} data={data} id="line-chart" />
    </div>
  );
};

export default CommonLineChart;
