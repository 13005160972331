import {
  PDFDownloadLink,
  Document,
  Page,
  Text,
  View,
  Image,
  StyleSheet,
  Link,
} from "@react-pdf/renderer";
import RanksProLogo from "../../../../Mycomponent/Assets/site-audit/RanksProLogo.png";
import { useSelector } from "react-redux";
import React, { useRef, useState } from "react";
import RIGHT from "../../../Assets/site-audit/right.png";
import WRONG from "../../../Assets/site-audit/wrong2.png";
import { Grid } from "react-virtualized";
import { FiDownload } from "react-icons/fi";
import { useNavigate } from "react-router-dom";
import { getCurrentYear } from "../../../share/helper-js/current-year";
const styles = StyleSheet.create({
  page: {
    paddingHorizontal: 20,
    flexDirection: "column",
    paddingTop: 30,
    paddingBottom: 80, // Set vertical padding to 30 pixels
    borderStyle: "solid",
  },
  header: {
    // position: "absolute",
    // top: 20,
    // left: 15,
    // right: 0,
    paddingBottom:20
  },
  footer: {
    fontSize: 12,
    textAlign: "center",
    color: "grey",
    position: "absolute",
    bottom: 20,
    left: 0,
    right: 0,
  },
  logo: {
    width: 250,
  },
  title: {
    fontSize: 24,
    textAlign: "center",
    marginBottom: 20,
  },

  tableContainer: {
    marginBottom: 20,
  },
  table: {
    width: "100%",
    borderStyle: "solid",
    borderWidth: 1,
    display: "flex", // Use flex to create a flexible row layout
    flexDirection: "column", // Each table row is a flex column

    overflow: "hidden",
  },
  tableRow: {
    display: "flex",
    flexDirection: "row", // Each cell is a flex item in a row
    borderBottomWidth: 1,
    borderColor: "#000",
    alignItems: "center",
  },
  keywordLoc: {
    flex: 1, // Each cell should take equal space within the row
    padding: 5,
    borderRightWidth: 1,
    borderColor: "#000",
    justifyContent: "start",
    minHeight: 40,
    height: "100%",
    alignItems: "start",
    flexDirection: "column",
  },
  tableCell: {
    flex: 1, // Each cell should take equal space within the row
    padding: 5,
    borderRightWidth: 1,
    borderColor: "rgb(221, 221, 221)",
    justifyContent: "center",
    alignItems: "center",
    flexDirection: "row",
    // border:"1px solid rgb(221, 221, 221)"
  },
  fixedWidthCell: {
    maxWidth: 80, // Disable flex for fixed width cells
  },
  cardContainer: {
    flexDirection: "row",
    flexWrap: "wrap", // Enable flex wrapping
    justifyContent: "space-between", // Arrange cards with equal spacing
    marginTop: 10,
  },
  card: {
    flexDirection: "row",
    justifyContent: "space-between",
    width: 180,
    backgroundColor: "#FFFFFF",
    padding: 10,
    borderRadius: 5,
    marginBottom: 20,
  },
  image: {
    width: 30,
    height: 40,
  },
  title: {
    fontSize: 11,
    fontWeight: "bold",
    marginBottom: 5,
    color: "#6c757d",
  },
  description: {
    fontSize: 16,
  },
  imageContainer: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  arrowIcon: {
    width: 20,
    height: 20,
    objectFit: "contain",
  },
  view_button: {
    borderWidth: 1,
    borderColor: "#365486",
    padding: 5,
    borderRadius: 7,
    color: "#365486",
    fontSize: 11,
  },
  keyword_bold: {
    fontSize: 13,
    fontWeight: "bold",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  keyword: {
    fontSize: 11,
    color: "#6c757d",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  rank: {
    fontSize: 11,
    color: "#6c757d",
    textAlign: "center",
    marginRight: 10,
  },
  table_cell: {
    fontSize: 7,
    color: "#6c757d",
    textAlign: "center",
    // marginVertical:5
  },
  table_cell_url: {
    fontSize: 7,
    textAlign: "center",
    // marginVertical:5,
    color: "blue",
    overflow: "hidden",        // Ensure content is clipped to fit the container
    textOverflow: "ellipsis",  // Show ellipsis for overflowing text
    whiteSpace: "nowrap",
    textDecoration: 'none'
  },
  table_header: {
    fontSize: 9,
    textAlign: "center",
    fontWeight: "bold",
  },
  pageNumber: {
    position: "absolute",
    fontSize: 11,
    bottom: 45,
    left: 0,
    right: 0,
    textAlign: "center",
    color: "grey",
  },
  link_text: {
    fontSize: 7,
    textDecoration: "none",
    textOverflow: "hidden",
    textAlign: "start",
  },
  logoHead: {
    width: 125,
  },
});








export const PdfDocument = ({tableHeader,sortedTableData,websiteurl,showTitle,pdfNewData}) => {


  const Data = JSON.parse(sessionStorage.getItem("pdfSessionData"));

  const truncateText = (text, maxLength) => {
    return text.length > maxLength ? `...${text.substring(maxLength,60)}` : text;
  }
  
  const Web_url = Data ? Data?.websiteurl : websiteurl;
  const sorted_Table_Data = Data ? Data?.sortedTableData: sortedTableData;
  const sorted_Table_Header = Data ? Data?.tableHeader : tableHeader;
  const show_Title = Data ? Data?.showTitle : showTitle;
  const image_logo = pdfNewData
  ? pdfNewData?.reset
    ? RanksProLogo
    : pdfNewData?.logo?.src
  : RanksProLogo;

  // console.log(Data,tableHeader)
  return (
    <Document>
    <Page size={"A4"} style={styles.page}>
      <View
        style={{
          alignItems: "center",
          display: "flex",
          height: "82vh",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <Image style={styles.logo} src={image_logo} />
        <Text style={{ marginBottom: 20 }}>Site Audit</Text>
        <Text style={styles.title}>Domain: {Web_url}</Text>
      </View>
</Page>

<Page size={"A4"} style={styles.page}>
  

<View
          style={{
            ...styles.header,
            alignItems: pdfNewData?.reset
              ? "flex-start"
              : pdfNewData?.logo.position,
          }}
          fixed
        >
          <Image style={styles.logoHead} src={image_logo} />
        </View>


      <View style={{ ...styles.tableContainer }}>
        <View style={styles.table}>
          <View style={{...styles.tableRow,backgroundColor: "#f4f4f4"}}>
            {sorted_Table_Header.map((header, index) => {
              return (
                <View
                key={index}
                  style={{
                    ...styles.tableCell,
                    backgroundColor: "#f4f4f4",
                    display: header === "Status" ? "none" : "flex",
                    color: header === "Status" ? "transparent" : "black",
                  }}
                >
                  <Text style={{ ...styles.table_header }}>{header}</Text>
                </View>
              );
            })}
          </View>
          <View>
            {sorted_Table_Data?.map((item, index) => (
              <View style={styles.tableRow} key={`table-row-${index}`}>
                {item?.title && (
                  <View style={styles.tableCell}>
                    <View
                      style={{
                        ...styles.tableCell,
                        border: "none",
                        display: "flex",
                        flexFlow: "column",
                        flexDirection: "column",
                      }}
                    >
                      <Text style={styles.table_cell}>{item?.title}</Text>
                      <Link src={item?.url}
                        style={{
                          ...styles.table_cell_url
                        }}
                      >
                         {truncateText(item?.url, `https://www.${websiteurl}`.length)}
                      </Link>
                    </View>
                  </View>
                )}

                {item?.count && (
                  <View style={styles.tableCell}>
                    <Text style={styles.table_cell}>{item?.count}</Text>
                  </View>
                )}

                {item?.td1 && (
                  <View style={styles.tableCell}>
                    <Text style={styles.table_cell}>{item?.td1}</Text>
                  </View>
                )}
                {item?.td2 && (
                  <View style={styles.tableCell}>
                    <Text style={styles.table_cell}>
                      {item?.td2.length} Pages
                    </Text>
                  </View>
                )}
                {item?.status_code && (
                  <View style={styles.tableCell}>
                    <Text style={styles.table_cell}>
                      {item?.status_code}{" "}
                    </Text>
                  </View>
                )}
                {item?.h1_tags && (
                  <View style={styles.tableCell}>
                    <Text style={styles.table_cell}>{item?.h1_tags} </Text>
                  </View>
                )}
                {item?.desc && (
                  <View style={styles.tableCell}>
                    <Text style={styles.table_cell}>{item?.desc} </Text>
                  </View>
                )}
                {item?.title && show_Title && item?.status_code && (
                  <View style={styles.tableCell}>
                    <Text style={styles.table_cell}>{item?.title} </Text>
                  </View>
                )}
                {item?.no_of_h1_tags && (
                  <View style={styles.tableCell}>
                    <Text style={styles.table_cell}>
                      {item?.no_of_h1_tags}{" "}
                    </Text>
                  </View>
                )}
                {item?.length && (
                  <View style={styles.tableCell}>
                    <Text style={styles.table_cell}>{item?.length} </Text>
                  </View>
                )}
                {item?.time && (
                  <View style={styles.tableCell}>
                    <Text style={styles.table_cell}>{item?.time} </Text>
                  </View>
                )}

                {item?.character_check ? (
                  <View>{item.character_check}</View>
                ) : null}

                {item?.characters ||
                typeof item.characters === "boolean" ||
                item?.characters === "undefined" ? (
                  <View style={styles.tableCell}>
                    {item.characters === true ? (
                      <>
                        <Image
                          src={RIGHT}
                          className="RIGHTIMG"
                          alt="right-tick-icon"
                          style={{ ...styles.table_cell, width:"10px" }}
                        />
                      </>
                    ) : (
                      <>
                        <Image
                          src={WRONG}
                          className="WRONGIMG"
                          alt="wrong-tick-icon"
                          style={{ ...styles.table_cell, width:"10px" }}
                        />
                      </>
                    )}
                  </View>
                ) : null}


                {item?.dynamic ||
                typeof item.dynamic === "boolean" ||
                item?.dynamic === "undefined" ? (
                  <View style={styles.tableCell}>
                    {item.dynamic === true ? (
                      <>
                        <Image
                          src={RIGHT}
                          className="RIGHTIMG"
                          alt="right-tick-icon"
                          style={{ ...styles.table_cell,width:"10px"}}
                        />
                      </>
                    ) : (
                      <>
                        <Image
                          src={WRONG}
                          className="WRONGIMG"
                          alt="wrong-tick-icon"
                          style={{ ...styles.table_cell,width:"10px" }}
                        />
                      </>
                    )}
                  </View>
                ) : null}


                {item?.keywords ||
                typeof item?.keywords === "boolean" ||
                item?.keywords === "undefined" ? (
                  <View style={styles.tableCell}>
                    {item.keywords === true ? (
                      <>
                        <Image
                          src={RIGHT}
                          className="RIGHTIMG"
                          alt="right-tick-icon"
                          style={{ ...styles.table_cell,width:"10px"}}
                        />
                      </>
                    ) : (
                      <>
                        <Image
                          src={WRONG}
                          className="WRONGIMG"
                          alt="wrong-tick-icon"
                          style={{ ...styles.table_cell,width:"10px" }}
                        />
                      </>
                    )}
                  </View>
                ) : null}
              </View>
            ))}
          </View>
        </View>
      </View>

      <Text style={styles.footer} fixed>
      {pdfNewData?.reset
            ? `Copyright ${getCurrentYear} eSearch Logix | Prepared using RanksPro.io`
            : pdfNewData?.footer}
      </Text>

     
    </Page>
  </Document>
  )

  
};












function PdfModal({ tableHeader, tableData, type, showTitle, tag }) {
  const websiteurl = useSelector((state) => state.websiteurl);
  const [showTable, setShowTable] = useState([]);
  const navigate = useNavigate()

  const showTableHandler = (e) => {
    if (typeof e !== "string") {
      if (showTable?.length === 0 || e !== showTable) {
        setShowTable(e);
      } else {
        setShowTable([]);
      }
    }
  };

  // for selecting the tags
  const TagsArry = [
    "Duplicate Meta Description",
    "Poorly formatted URL for SEO",
    "With Title tag that is too short",
    "Duplicate H1 and Title Tag",
    "URLs are too long",
    "seoIssuesPdf",
    "Duplicate Meta Description",
    "Have a Low Word Count",
    "Without H1 Heading",
    "Without Meta Description",
    "With Title tag that is too long",
    "Long Loading Time",
    "With Description too long",
    "With Description too short",
    "Multiple H1 tag",
    "No canonical tag",
    "No favicon",
    "No image alt",
    "No image title",
  ];

  const TagName = TagsArry.find((elm) => elm === tag);

  // for downloading pdf files
  const targetSeoIssues = useRef();

  const handleRoute =()=>{

    sessionStorage.setItem('pdfSessionData',JSON.stringify({tableHeader:tableHeader,sortedTableData:tableData,websiteurl:websiteurl,showTitle:showTitle,id:2}))
    setTimeout(() => {
      navigate("/CrawledPageEditor");
    }, 500)
     
    }

  return (
    <div>
      <div className="d-flex justify-content-end" style={{padding: "5px 100px"}}>
        <FiDownload className="down" onClick={handleRoute} title="Download PDF" aria-label="Close" data-bs-dismiss="modal"/>
    
      </div>
      <div className="custom-table-container mt-4" ref={targetSeoIssues}>
        <div
          style={{ width: "100%", height: "20vh" }}
          className="pg-2 d-flex justify-content-center align-items-center flex-column"
        >
          <img src={RanksProLogo} alt="" style={{ width: "300px" }} />
        </div>
        <table className="audit-table">
          <thead>
            <tr className="table-sticky">
              {tableHeader?.map((header, index) => {
                return (
                  <th
                    key={`table-header-${index}`}
                    className={`${
                      header === "Description" || header === "Title"
                        ? "text-start"
                        : ""
                    }${header === "HTTP Status" ? "p-0" : ""}`}
                    style={{
                      width: [
                        "Characters Check",
                        "Keyword Check",
                        "Dynamic Check",
                      ].includes(header)
                        ? "200px"
                        : ["HTTP Status", "Length"].includes(header) &&
                          tableHeader?.length > 2
                        ? "160px"
                        : "auto",
                      display: header === "Status" ? "none" : "table-cell",
                    }}
                  >
                    {header}
                  </th>
                );
              })}
            </tr>
          </thead>
          <tbody>
            {tableData?.length == 0 ? (
              <td className="text-center" colSpan={tableHeader?.length}>
                You don't have any data!
              </td>
            ) : (
              tableData?.map((item, index) => {
                return (
                  <React.Fragment key={`table-row-${index}`}>
                    <tr
                      key={index}
                      style={{
                        cursor: "pointer",
                      }}
                      onClick={item?.td2?.length > 0 ? item.td3 : () => {}}
                    >
                      {item?.title && showTitle && !item?.status_code ? (
                        <td className="text-start">{item?.title}</td>
                      ) : null}
                      {item?.url ? (
                        <td
                          onClick={() => showTableHandler(item.url)}
                          className={
                            type === "poorly_formatted_title" ||
                            type === "title_too_long"
                              ? ""
                              : type === "title_too_short" ||
                                type === "meta_title_too_short"
                              ? "text-start"
                              : "text-break"
                          }
                        >
                          {item?.title}
                          <br />
                          <span
                            title={item?.url}
                            className="w-100"
                            style={{
                              // whiteSpace: "nowrap",
                              overflow: "hidden",
                              textOverflow: "ellipsis",
                              color: "blue",
                            }}
                          >
                            {item.url}
                          </span>
                        </td>
                      ) : null}
                      {item?.count ? (
                        <td
                          style={{
                            display: tag ? "table-cell" : "none",
                          }}
                        >
                          {item.count}
                        </td>
                      ) : null}
                      {item?.td1 ? (
                        <td
                          style={{
                            display: tag ? "table-cell" : "none",
                          }}
                        >
                          {item?.td1}
                        </td>
                      ) : null}
                      {item?.td2 ? (
                        <td className="text-center">
                          {item?.td2.length} Pages
                        </td>
                      ) : null}
                      {item?.status_code ? (
                        <td
                          className="p-0 text-center"
                          style={{
                            display: item?.status_code ? "table-cell" : "none",
                          }}
                        >
                          {item?.status_code}
                        </td>
                      ) : null}
                      {item?.h1_tags ? (
                        <td className="text-center">{item.h1_tags}</td>
                      ) : null}
                      {item?.desc ? (
                        <td className="text-start">{item.desc}</td>
                      ) : null}
                      {item?.title && showTitle && item?.status_code ? (
                        <td className="text-start">{item.title}</td>
                      ) : null}
                      {item?.no_of_h1_tags ? (
                        <td className="text-center">{item.no_of_h1_tags}</td>
                      ) : null}
                      {item?.length ? (
                        <td className="text-center">{item.length}</td>
                      ) : null}
                      {item?.time ? <td>{item.time}</td> : null}
                      {item?.character_check ? (
                        <td>{item.character_check}</td>
                      ) : null}
                      {item?.characters ||
                      typeof item.characters === "boolean" ||
                      item?.characters === "undefined" ? (
                        <td>
                          {item.characters === true ? (
                            <>
                              <img
                                src={RIGHT}
                                className="RIGHTIMG"
                                alt="right-tick-icon"
                              />
                            </>
                          ) : (
                            <>
                              <img
                                src={WRONG}
                                className="WRONGIMG"
                                alt="wrong-tick-icon"
                              />
                            </>
                          )}
                        </td>
                      ) : null}
                      {item?.dynamic ||
                      typeof item.dynamic === "boolean" ||
                      item?.dynamic === "undefined" ? (
                        <td>
                          {item.dynamic === true ? (
                            <>
                              <img
                                src={RIGHT}
                                className="RIGHTIMG"
                                alt="right-tick-icon"
                              />
                            </>
                          ) : (
                            <>
                              <img
                                src={WRONG}
                                className="WRONGIMG"
                                alt="wrong-tick-icon"
                              />
                            </>
                          )}
                        </td>
                      ) : null}
                      {item?.keywords ||
                      typeof item?.keywords === "boolean" ||
                      item?.keywords === "undefined" ? (
                        <td>
                          {item.keywords === true ? (
                            <>
                              <img
                                src={RIGHT}
                                className="RIGHTIMG"
                                alt="right-tick-icon"
                              />
                            </>
                          ) : (
                            <>
                              <img
                                src={WRONG}
                                className="WRONGIMG"
                                alt="wrong-tick-icon"
                              />
                            </>
                          )}
                        </td>
                      ) : null}
                    </tr>

                    <tr
                      key={`show-${index}`}
                      style={{
                        display: item.url === showTable ? "table-row" : "none",
                        cursor: "pointer",
                      }}
                      onClick={() => showTableHandler(item.url)}
                    >
                      <td colSpan={tableHeader?.length}>
                        {item?.title && !showTitle ? (
                          <div className="d-flex flex-wrap p-3">
                            <div className="me-5">
                              <span className="fw-bold me-2">Title:</span>
                              {item.title}
                            </div>
                            {item.desc && (
                              <div className="me-5">
                                <span className="fw-bold me-2">
                                  Description:
                                </span>
                                {item.desc}
                              </div>
                            )}
                            {item.h1_tags && (
                              <div className="me-5">
                                <span className="fw-bold me-2">H1 Tags:</span>
                                {item.h1_tags}
                              </div>
                            )}
                          </div>
                        ) : null}
                        {item.td2 &&
                          item?.td2?.map((data, dataIndex) => (
                            <div key={dataIndex} className="bg-light p-3">
                              {type !== "meta_title_too_long" &&
                              type !== "meta_title_too_short" &&
                              type !== "meta_title_duplicate" &&
                              type !== "poorly_formatted_title" &&
                              type !== "title_too_long" &&
                              type !== "title_too_short" ? (
                                <>
                                  <div className="row w-100">
                                    <span className="col-md-2 fw-bold">
                                      URL:
                                    </span>
                                    <span
                                      className="col-md-8"
                                      style={{
                                        whiteSpace: "nowrap",
                                        overflow: "hidden",
                                        textOverflow: "ellipsis",
                                      }}
                                    >
                                      {data.url}
                                    </span>
                                  </div>
                                  <div className="row w-100">
                                    <span className="col-md-2 fw-bold">
                                      Title:
                                    </span>
                                    <span className="col-md-8">
                                      {data.title}
                                    </span>
                                  </div>
                                </>
                              ) : null}

                              {type === "meta_title_too_long" ||
                              type === "meta_title_too_short" ||
                              type === "meta_title_duplicate" ||
                              type === "poorly_formatted_title" ||
                              type === "title_too_long" ||
                              type === "title_too_short" ? (
                                <>
                                  <div className="row w-100">
                                    <span className="col-md-2 fw-bold">
                                      URL:
                                    </span>
                                    <span className="col-md-8">{data}</span>
                                  </div>
                                </>
                              ) : null}
                            </div>
                          ))}
                      </td>
                    </tr>
                  </React.Fragment>
                );
              })
            )}
          </tbody>
        </table>
      </div>
    </div>
  );
}

export default PdfModal;
