import React from "react";
import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";
import { Box } from "@mui/material";
import StepLabel from "@mui/material/StepLabel";

const steps = ["", "", "", ""];
const ProjectStepper = ({ activeStep }) => {
  return (
    <Box sx={{ width: "80%" }}>
      <Stepper activeStep={activeStep} alternativeLabel>
        {steps.map((label, index) => (
          <Step
            key={index}
            sx={{
              "& .MuiStepConnector-root": {
                top: "18px",
              },
              "& .MuiSvgIcon-root": {
                width: "1.5em",
                height: "1.5em",
              },
              "& .MuiStepIcon-root.Mui-active": {
                color: "#365486",
              },
              "& .MuiSvgIcon-root.Mui-completed": {
                color: "#365486",
              },
              "& .MuiStepConnector-line": {
                color: "#365486 !important",
              },
            }}
          >
            <StepLabel>{label}</StepLabel>
          </Step>
        ))}
      </Stepper>
    </Box>
  );
};

export default ProjectStepper;
