import React from "react";
import { styled } from "@mui/material/styles";
import Button from "@mui/material/Button";
import Tooltip, { tooltipClasses } from "@mui/material/Tooltip";

const HtmlTooltip = styled(({ className, ...props }) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(() => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: "transparent !important",
    maxWidth: "350px !important",
    fontSize: "1em !important",
    fontFamily: "Lato !important",
    margin: "30px",
  },
}));

const CardTooltip = ({ children, element }) => {
  return (
    <HtmlTooltip
      placement="bottom"
      title={<React.Fragment>{children}</React.Fragment>}
    >
      <Button
        sx={{
          minWidth: "40px !important",
          backgroundColor: "transparent !important",
        }}
      >
        {element}
      </Button>
    </HtmlTooltip>
  );
};

export default CardTooltip;
