import React, { useState, useMemo } from "react";
import PopOver from "../../../Mycomponent/share/components/popover.component";
import { Box, IconButton, Typography } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import "./component.style.scss";
import { base64Encode } from "../../../helperJs/encoderanddecoder";

import Paper from "@mui/material/Paper";
import InputBase from "@mui/material/InputBase";
import Divider from "@mui/material/Divider";
import { useSelector } from "react-redux";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";

import Switch from "@mui/material/Switch";
import FormGroup from "@mui/material/FormGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormControl from "@mui/material/FormControl";
import { POST_GUEST_LINK } from "../assets/services";
import axios from "axios";
import { getDayAfter7Days } from "../../../helperJs/helper";
import useDebouncedFunction from "../../../helperJs/debounce";

const CopyLinkDialog = ({ anchorEl, setAnchorEl }) => {
  const [copyMsg, setCopyMsg] = useState("");
  const [urlConfig, SetUrlConfig] = useState({
    url: "",
    competitors: false,
    expired: false,
  });
  const websiteurl = useSelector((state) => state.websiteurl);
  const dateAfter7Days = getDayAfter7Days();
  const email = localStorage.getItem("admin_mail");

  const urlToCopy = useMemo(() => {
    const domain = window.location.origin;
    const encodedEmail = base64Encode(email);
    const encodedCompetitor = base64Encode(urlConfig?.competitors);
    return `${domain}/guest/rank-tracker?code=${encodedEmail}&domain=${websiteurl}&competitors=${encodedCompetitor}`;
  }, [websiteurl, email, urlConfig]);

  const handleGenerateUrlLink = () => {
    axios
      .post(POST_GUEST_LINK(), {
        link: urlToCopy,
        date: urlConfig?.expired ? dateAfter7Days : "100",
      })
      .then((response) => {
        // console.log(response, "response link ssaved");
      })
      .catch((error) => {
        // console.log(error, "error link");
      });
  };

  const debouncedSaveGuestLink = useDebouncedFunction(() => {
    handleGenerateUrlLink();
  }, 200);

  const handleLinkCopyMsg = () => {
    setTimeout(() => {
      setCopyMsg(false);
    }, 2000);
  };

  const handleCopyLink = () => {
    navigator.clipboard
      .writeText(urlToCopy)
      .then(() => {
        debouncedSaveGuestLink();
        setCopyMsg("URL copied!");
        handleLinkCopyMsg();
      })
      .catch((err) => {
        setCopyMsg("Failed to copy!");
        handleLinkCopyMsg();
      });
  };

  return (
    <PopOver anchorEl={anchorEl}>
      <Box className="guest-link-dialog">
        <Typography
          component="h1"
          className="heading-large font-bold popover-list-header py-2 px-2"
        >
          Share Link
          <IconButton
            className="popover-close-icon"
            onClick={() => setAnchorEl(false)}
            sx={{
              "&:hover": {
                backgroundColor: "rgba(240, 242, 255, 1)",
              },
            }}
          >
            <CloseIcon sx={{ color: "black" }} fontSize="small" />
          </IconButton>
        </Typography>

        <Paper
          component="form"
          className="my-3"
          sx={{
            p: "2px 4px",
            display: "flex",
            alignItems: "center",
          }}
        >
          <InputBase sx={{ ml: 1, flex: 1 }} placeholder={urlToCopy} />
          <Divider sx={{ height: 28, m: 0.5 }} orientation="vertical" />
          <IconButton
            color="primary"
            sx={{ p: "10px" }}
            aria-label="directions"
            onClick={handleCopyLink}
          >
            <ContentCopyIcon />
          </IconButton>
        </Paper>

        {copyMsg && (
          <p
            className="mb-0 mt-2"
            style={{ color: "#365486", fontWeight: "500" }}
          >
            {copyMsg}
          </p>
        )}

        <FormControl component="fieldset">
          <FormGroup aria-label="position">
            <FormControlLabel
              onChange={() =>
                SetUrlConfig((pre) => ({
                  ...pre,
                  competitors: !pre.competitors,
                }))
              }
              value={urlConfig?.competitors}
              control={<Switch color="primary" />}
              label="Competitor View (Want to show Competitor to Guest)"
              labelPlacement="start"
              className="flex-row"
            />

            <FormControlLabel
              onChange={() =>
                SetUrlConfig((pre) => ({
                  ...pre,
                  expired: !pre.expired,
                }))
              }
              value={urlConfig?.expired}
              control={<Switch color="primary" />}
              label="Expire Link"
              labelPlacement="start"
              className="flex-row"
            />
          </FormGroup>
        </FormControl>
        {urlConfig?.expired && (
          <p
            className="mb-0 mt-2"
            style={{ color: "#365486", fontWeight: "500" }}
          >
            This guest link will expire in 7 days.
          </p>
        )}
      </Box>
    </PopOver>
  );
};

export default CopyLinkDialog;
