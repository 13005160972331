import { useState } from "react";

const useLoader = (initialState = false) => {
  const [isLoading, setIsLoading] = useState(initialState);

  const showLoader = () => {
    setIsLoading(true);
    setTimeout(() => {
      setIsLoading(false);
    }, 1000);
  };

  return [isLoading, showLoader];
};

export default useLoader;
