import React, { useEffect, useRef, useState } from "react";
import RESET from "../../assets/images/Login/signup.png";
import LOGO from "../../assets/images/Global/logo.png";
import { Field, Form, Formik, ErrorMessage } from "formik";
import { YupSchema } from "./YupSchema";
import { AFFILIATE, POST_OTP, REGISTER } from "../../utils/Api";
import axios from "axios";
import "react-toastify/dist/ReactToastify.css";
import { ToastContainer, toast } from "react-toastify";
import { Link, useNavigate } from "react-router-dom";
import { GET_COUNTRY } from "../../utils/getCountry";
import { Dropdown } from "../../components/Dropdown";
import FLAG from "../../assets/images/Login/flag.png";
import "react-phone-number-input/style.css";
import PhoneInput from "react-phone-number-input";
import { Store } from "../../utils/STORE";
import { IoEyeOff, IoEye } from "react-icons/io5";

export const Signup = () => {
  const { RegisterData } = Store((state) => state);
  const [value, setValue] = useState();
  const DROPREF = useRef(null);
  const DROPREF2 = useRef(null);
  const nav = useNavigate();
  const [ErrorName, setErrorName] = useState("Please Enter Your Number");
  const [numError, setnumError] = useState(false);
  const [VALUE, setVALUE] = useState({ img: FLAG, name: "United States" });
  const [Show, setShow] = useState(false);
  const [OTP, setOTP] = useState("");
  const [PassSecure, setPassSecure] = useState(false);
  const [PassSecure2, setPassSecure2] = useState(false);

  useEffect(() => {
    if (value?.length >= 12 && value.length <= 14) {
      setnumError(false);
    } else if (value?.length >= 15) {
      setnumError(true);
      setErrorName("Number Should be less than 15");
    }
  }, [value]);

  // OTP VERIFICATION

  const CheckNUmber = () => {
    if (value?.length < 12 || value === undefined) {
      setnumError(true);
      setErrorName("Please Enter Your Number");
      return false;
    } else if (value?.length >= 12 && value.length <= 14) {
      setnumError(false);
      return true;
    } else if (value?.length >= 15) {
      setErrorName("Number Should be less than 15");
      setnumError(true);
      return false;
    }
  };

  const VERIFY = (values) => {
    const PAYLOAD = {
      email: values.email,
    };
    axios.post(POST_OTP(), PAYLOAD).then(({ data }) => {
      if (data?.otp) {
        setOTP(data?.otp);
        Store.setState({ RegisterData: values, OTP: data.otp, phone: value });
        nav("/affiliate/verify");
      }
    });
  };
  // END OTP

  const handleClickOuteSide = (e) => {
    if (
      DROPREF.current &&
      !DROPREF2.current.contains(e.target) &&
      !DROPREF.current.contains(e.target)
    )
      setShow(false);
  };
  useEffect(() => {
    window.addEventListener("click", handleClickOuteSide);
    return () => {
      window.removeEventListener("click", handleClickOuteSide);
    };
  }, []);

  return (
    <div className="LoginContainer SignupContainer ForgotContainer Register">
      <ToastContainer />
      <div className="Login">
        <div className="row">
          <div className="col-md-6 col-lg-6 d-none d-md-block d-lg-block">
            <div className="log_left">
              <img src={RESET} alt="Login " />
            </div>
          </div>
          <div className="col-md-6 col-lg-6">
            <div className="log_Right">
              <div className="log_content Register_content">
                <div className="logo">
                  <img src={LOGO} alt="" />
                </div>
                <h1>
                  Register as an <span>Affiliate ?</span>
                </h1>
                <Formik
                  validationSchema={YupSchema}
                  initialValues={{
                    email: "",
                    name: "",
                    address: "",
                    phone: "",
                    password: "",
                    confirmPassword: "",
                    country: "",
                  }}
                  onSubmit={(values, { resetForm }) => {
                    if (CheckNUmber()) {
                      VERIFY(values);
                    }
                    // HandleSubmit(values, resetForm);
                  }}
                >
                  {({ isSubmitting, errors }) => (
                    <Form autoComplete="off">
                      <div className="Feilds ">
                        <div className="row">
                          <div className="col-lg-6 col-md-6">
                            <div className="input_group">
                              <Field
                                type="text"
                                name="name"
                                autoComplete="off"
                                placeholder="Enter Your Name"
                                // id="myInput"
                              />
                              <ErrorMessage
                                name="name"
                                component="div"
                                className="error"
                              />
                            </div>
                          </div>
                          <div className="col-lg-6 col-md-6">
                            <div className="input_group">
                              <Field
                                type="email"
                                name="email"
                                autoComplete="off"
                                placeholder="Enter Your Email"
                                // id="myInput"
                              />
                              <ErrorMessage
                                name="email"
                                component="div"
                                className="error"
                              />
                            </div>
                          </div>
                          <div className="input_group">
                            <Field
                              type="text"
                              name="address"
                              autoComplete="off"
                              placeholder="Enter Your Address"
                              // id="myInput"
                            />
                            <ErrorMessage
                              name="address"
                              component="div"
                              className="error"
                            />
                          </div>
                          <div className="input_group">
                            <Dropdown
                              Ref={DROPREF}
                              Ref2={DROPREF2}
                              {...{ VALUE, setVALUE, Show, setShow }}
                              data={GET_COUNTRY}
                            />
                          </div>
                          <div className="input_group1">
                            {/* <input
                              ref={inputRef}
                              type="tel"
                              placeholder="Phone Number"
                              id="phoneNumber"
                            /> */}
                            <PhoneInput
                              international
                              defaultCountry="US"
                              countryCallingCodeEditable={false}
                              placeholder="Enter phone number"
                              value={value}
                              onChange={setValue}
                              // value?.length >= 10 && setnumError(false)
                            />
                            {/* ) */}
                            {/* <Field
                              type="text"
                              // name="phone"
                              value={number}
                              onChange={handleInputChange}
                              pattern="\d*"
                              autoComplete="off"
                              placeholder="Enter Your Mobile Number"
                              // id="myInput"
                            /> */}
                            {/* {number.length <= 0 && ( */}
                            {numError && (
                              <div
                                name="name"
                                component="div"
                                className="error"
                              >
                                {ErrorName}
                              </div>
                            )}
                            {/* )} */}
                          </div>
                          <div className="col-lg-6 col-md-6">
                            <div className="input_group">
                              <div className="aff-signup-inp-pass">
                                <Field
                                  type={PassSecure ? "text" : "password"}
                                  name="password"
                                  autoComplete="new-password"
                                  placeholder="Enter Your Password"
                                  style={{
                                    paddingLeft: "20px !important",
                                  }}
                                />
                                {PassSecure ? (
                                  <IoEye
                                    style={{
                                      position: "absolute",
                                      right: "10px",
                                      color: "white",
                                      width: "25px",
                                      height: "20px",
                                      top: "13px",
                                    }}
                                    onClick={() => setPassSecure(false)}
                                  />
                                ) : (
                                  <IoEyeOff
                                    style={{
                                      position: "absolute",
                                      right: "10px",
                                      color: "white",
                                      width: "25px",
                                      height: "20px",
                                      top: "13px",
                                    }}
                                    onClick={() => setPassSecure(true)}
                                  />
                                )}
                              </div>
                              <ErrorMessage
                                name="password"
                                component="div"
                                className="error"
                              />
                            </div>
                          </div>
                          <div className="col-lg-6 col-md-6">
                            <div className="input_group">
                              <div className="aff-signup-inp-pass">
                                <Field
                                  type={PassSecure2 ? "text" : "password"}
                                  name="confirmPassword"
                                  autoComplete="new-password"
                                  placeholder="Confirm Your Password"
                                  style={{
                                    paddingLeft: "20px !important",
                                  }}
                                  className="pe-3"
                                />
                                {PassSecure2 ? (
                                  <IoEye
                                    style={{
                                      position: "absolute",
                                      right: "10px",
                                      color: "white",
                                      width: "25px",
                                      height: "20px",
                                      top: "13px",
                                    }}
                                    onClick={() => setPassSecure2(false)}
                                  />
                                ) : (
                                  <IoEyeOff
                                    style={{
                                      position: "absolute",
                                      right: "10px",
                                      color: "white",
                                      width: "25px",
                                      height: "20px",
                                      top: "13px",
                                    }}
                                    onClick={() => setPassSecure2(true)}
                                  />
                                )}
                              </div>
                              <ErrorMessage
                                name="confirmPassword"
                                component="div"
                                className="error"
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="FlexBtn mt-4">
                        <button
                          type="submit"
                          onClick={CheckNUmber}
                          // disabled={numError && isSubmitting}
                        >
                          Register
                          {/* {!numError && isSubmitting ? "Submitting..." : "Register"} */}
                        </button>
                      </div>
                    </Form>
                  )}
                </Formik>
                <div className="OR">
                  <div className="line1"></div>
                  <span>OR</span>
                  <div className="line1"></div>
                </div>
                <div className="RegisterLinks text-center text-white mt-3">
                  <p>
                    Already have an account?{" "}
                    <Link to={"/affiliate/login"}>Log In</Link>
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
