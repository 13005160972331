import React, { useState, useEffect, useMemo, useCallback } from "react";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import { Box, TextField } from "@mui/material";
import { DottLoader1 } from "./loader";
import CustomPagination from "../share/components/pagination.component";

const CustomTable = ({
  rows,
  page,
  type,
  update,
  setPage,
  dataLoader,
  tableTitle = "Custom",
  tableHeader,
  rowsPerPage = 100, // Default value for rowsPerPage
  downloadCSVFile,
  showSearchBar = false,
}) => {
  const [tableLoader, setTableLoader] = useState(false);
  const [searchTerm, setSearchTerm] = useState("");
  const totalPages = Math.ceil(rows?.length / rowsPerPage);
  const startIndex = (page - 1) * rowsPerPage;
  const endIndex = startIndex + rowsPerPage;

  const filteredRows = useMemo(() => {
    if (!searchTerm) return rows;
    return rows?.filter((row) =>
      tableHeader.some((column) => {
        const value = row[column.key];
        return value
          ?.toString()
          .toLowerCase()
          .includes(searchTerm.toLowerCase());
      })
    );
  }, [rows, searchTerm, tableHeader]);

  const currentRows = useMemo(
    () => filteredRows?.slice(startIndex, endIndex),
    [filteredRows, startIndex, endIndex]
  );

  useEffect(() => {
    const obj = {
      page,
      rowsCount:
        currentRows?.length > rowsPerPage
          ? rowsPerPage - 1
          : currentRows?.length - 1,
      currentRows_length: currentRows?.length,
    };
    update && update(obj);
  }, [page, currentRows, rowsPerPage, update]);

  const handlePaginationChange = useCallback(
    (value) => {
      setTableLoader(true);
      setTimeout(() => {
        setTableLoader(false);
      }, 500);
      setPage(value);
    },
    [setPage]
  );

  const handleSearchChange = (event) => {
    setSearchTerm(event.target.value);
    setPage(1); // Reset to the first page on new search
  };

  return (
    <>
      {showSearchBar && (
        <Box className="mb-2 d-flex flex-wrap align-items-center">
          <h2 className="table_title font-bold heading-large mb-0">
            {tableTitle}
          </h2>
          <TextField
            label="Search"
            variant="outlined"
            fullWidth
            value={searchTerm}
            size="small"
            onChange={handleSearchChange}
            sx={{
              maxWidth: "230px",
              marginLeft: "auto",
              "& .MuiInputBase-input": {
                padding: "14px",
                height: "15px",
              },
            }}
          />
          {downloadCSVFile}
        </Box>
      )}

      <Paper sx={{ width: "100%", overflow: "hidden" }}>
        {tableLoader || dataLoader ? (
          <Box
            className="d-flex justify-content-center align-items-center"
            sx={{ height: "calc(100vh - 245px)" }}
          >
            <DottLoader1 />
          </Box>
        ) : (
          <>
            <TableContainer
              sx={{ maxHeight: "calc(100vh - 230px)" }}
              className="app-scrollbar"
            >
              <Table stickyHeader aria-label="sticky table" id="table-to-excel">
                <TableHead>
                  <TableRow>
                    {tableHeader?.map((column, index) => (
                      <TableCell
                        key={`table-${column.label}-${index}`}
                        align={column.align || "left"}
                        style={{
                          minWidth: column.minWidth,
                          width: column.width, // Limiting email column width
                          backgroundColor: "#F8F8F8",
                          fontSize: "1em",
                        }}
                      >
                        {column.label}
                      </TableCell>
                    ))}
                  </TableRow>
                </TableHead>
                <TableBody>
                  {currentRows?.length === 0 ? (
                    <TableRow hover tabIndex={-1}>
                      <TableCell
                        key={"no_data"}
                        colSpan={tableHeader.length}
                        align={"center"}
                      >
                        Don't have any data!
                      </TableCell>
                    </TableRow>
                  ) : (
                    currentRows?.map((row, rowIndex) => (
                      <TableRow
                        hover
                        role="checkbox"
                        tabIndex={-1}
                        key={`tableRow-${rowIndex}`}
                      >
                        {tableHeader?.map((column, colIndex) => {
                          const value = row[column.key];
                          return (
                            <TableCell
                              key={column.key}
                              align={column.align || "left"}
                              style={{
                                fontSize: "1em",
                                maxWidth:
                                  tableTitle === "Digital Agency"
                                    ? 250
                                    : "auto",
                                wordBreak:
                                  column.key === "email"
                                    ? "break-all"
                                    : "normal",
                              }} // Limiting email column width
                              onClick={
                                type === "user_list" && column.key !== "space"
                                  ? row["action"]
                                  : column.key === "feedback"
                                  ? row["action"]
                                  : () => {}
                              }
                              className={
                                column.key === "feedback" ||
                                column.key === "payment_link"
                                  ? "tb-link-lmt"
                                  : ""
                              }
                            >
                              {column.key === "location_name" ? (
                                <div className="d-flex align-items-center justify-content-center">
                                  <h4 className="heading-small mb-0 me-3">
                                    {row["location_name"]}
                                  </h4>
                                  {row["location_flag"] && (
                                    <img src={row["location_flag"]} alt="" />
                                  )}
                                </div>
                              ) : (
                                value
                              )}
                            </TableCell>
                          );
                        })}
                      </TableRow>
                    ))
                  )}
                </TableBody>
              </Table>
            </TableContainer>

            {filteredRows?.length > rowsPerPage && (
              <Box className="my-3 px-4 d-flex align-items-center justify-content-start">
                <CustomPagination
                  page={page}
                  totalPage={totalPages}
                  onPageChange={handlePaginationChange}
                />
              </Box>
            )}
          </>
        )}
      </Paper>
    </>
  );
};

export default CustomTable;
