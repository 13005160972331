import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { DIGITAL_AGENCY_GET_SINGLE } from "../../Affiliate/utils/constant";
import axios from "axios";
import { IoIosStar } from "react-icons/io";
import { IoLocationOutline } from "react-icons/io5";
import { FiUser } from "react-icons/fi";
import { FaBookmark, FaRegBookmark } from "react-icons/fa6";
import "../css/Digital.scss";
import noImg from "../assets/no-photo.png";
import { useNavigate } from "react-router-dom";
import { Box, Button } from "@mui/material";
import Loader from "../share/loader";

export const Single_agency = () => {
  let isSave;
  const location = useLocation();
  const navigate = useNavigate();
  const [data, setData] = useState(null);
  const [loaded, setLoaded] = useState(false);
  const [errorSrc, setErrorSrc] = useState(noImg);

  const ID = location?.state?.toLowerCase();
  const agency_email = data?.email;
  const agency_website = data?.website?.[0];

  const fatch_Digital_GEt_SINGLE = () => {
    axios.get(DIGITAL_AGENCY_GET_SINGLE(ID)).then((response) => {
      if (response.data?.length === 0) {
        setData(null);
        setLoaded(true);
      } else {
        const data = response?.data;
        setData(data[0]);
        setErrorSrc(data[0]?.logo);
        setLoaded(true);
      }
    });
  };

  const HandleReviwGet = () => {
    return data?.review?.length;
  };

  const handleReviewAndRating = () => {
    const reviews = data?.review;
    const approvedReviews = reviews?.filter(
      (review) => review.approve === true
    );
    // Ensure there are approved reviews
    if (approvedReviews?.length === 0) {
      return { rating: 0, review: 0 }; // Return 0 if there are no approved reviews
    }
    // Calculate the sum of ratings for approved reviews
    const ratingSum = approvedReviews?.reduce(
      (sum, review) => sum + Number(review.rating),
      0
    );
    // Calculate the average rating for approved reviews
    const averageRating = ratingSum / approvedReviews?.length;
    return {
      rating: averageRating?.toFixed(1),
      review: approvedReviews?.length,
    };
  };

  const HanldeRatingGet = () => {
    if (data?.review?.length == 1) {
      return data?.review[0]?.rating / data?.review?.length;
    } else if (data?.review?.length == 0) {
      return 0;
    } else {
      return data?.review?.reduce((acc, res) => {
        return (acc.rating + res.rating) / data?.review?.length;
      });
    }
  };

  useEffect(() => {
    fatch_Digital_GEt_SINGLE();
  }, [ID]);

  const handleNavigate = () => {
    const queryParams = new URLSearchParams({
      name: data?.name,
    }).toString();
    navigate(`/admin/contact-agency?${queryParams}`, {
      state: { email: agency_email, website: agency_website },
    });
  };

  if (!data && loaded) {
    return (
      <>
        <Button className="btn_1 mb-3" onClick={() => navigate(-1)}>
          Back
        </Button>
        <div className="no-data-found">
          <h4>No Data Found</h4>
        </div>
      </>
    );
  }

  if (!data) {
    return <Loader />;
  }

  return (
    <>
      <div className="d-flex align-items-center justify-content-between">
        <Button
          className="btn_1 mb-3"
          onClick={() => navigate("/admin/digital-agency")}
        >
          Back
        </Button>
        <Box>
          <Button
            className="btn_1 mb-3 me-3"
            onClick={() => handleNavigate("/admin/contact-agency")}
          >
            Contact Agency
          </Button>
          <Button
            className="btn_1 mb-3"
            onClick={() =>
              navigate(
                `/admin/detail/update-agency?name=${
                  data?.name?.replace(" ", "-") || data?.name
                }`,
                {
                  state: data?.name,
                }
              )
            }
          >
            Edit Agency
          </Button>
        </Box>
      </div>
      <div className="d-flex flex-column main_digi admin-agency-sg-container position-relative">
        <div className="digi_box">
          <div className="row w-100 m-0 ">
            <div className="col-12 col-md-9 col-lg-9 lftCol">
              <div className="cml h-100 margin-top-sm">
                <div className="px-2 singleImgBoz">
                  <img
                    src={errorSrc}
                    alt={data?.name}
                    className="cardtheme-b p-3 mt-0 w-100 h-100"
                    style={{
                      backgroundColor: "white",
                      objectFit: "contain",
                    }}
                    onError={() => setErrorSrc(noImg)}
                  />
                </div>
                <div className="px-2">
                  <div className="agency-seo-head">
                    <h6 className="mb-1">
                      <b>{data?.name}</b>
                    </h6>
                    <p className="mb-1">
                      <small>{data?.tag_line}</small>
                    </p>
                    <div className="mt-2">
                      <span
                        className="px-2 py-1 rounded-3 text-dark"
                        style={{ backgroundColor: "#FFF1F0" }}
                      >
                        <IoIosStar
                          className="fa-solid fa-star"
                          style={{ color: "#ffca28" }}
                        ></IoIosStar>{" "}
                        <small>{handleReviewAndRating()?.rating}</small>
                      </span>
                      <span style={{ paddingLeft: "10px" }}>
                        <small>{handleReviewAndRating()?.review} Reviews</small>
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-12 col-md-3 col-lg-3  RightCol">
              <div className="seo-card-etc single w-100">
                <div className="d-flex flex-md-column row my-3">
                  <span className="col-4 col-md-6 col-lg-12 text-nowrap">
                    <small style={{ cursor: "pointer" }}>
                      <span>
                        {!isSave ? <FaRegBookmark /> : <FaBookmark />}
                      </span>
                      {data?.budget && data?.budget[0]}
                    </small>
                  </span>
                  {/* <span className="col-6 col-lg-12 text-nowrap">
                <small>
                  {" "}
                  <span>
                    <MdAccessTime />
                  </span>{" "}
                  50%-99%
                </small>
              </span>{" "} */}
                  <span className="col-4 col-md-6 col-lg-12 text-nowrap ">
                    <small>
                      <span>
                        <FiUser />
                      </span>
                      {data?.employee}
                    </small>
                  </span>
                  {/* <span className="col-6 col-lg-12 text-nowrap">
                <i
                  className="fa-solid fa-star"
                  style={{ paddingRight: "10px", color: "yellow" }}
                ></i>
                <small>{data.review[0]?.rating || 0}</small>
              </span> */}
                  <span className="col-4 col-md-6 col-lg-12 text-nowrap ">
                    {data?.location?.map((res) => {
                      return (
                        <small className="">
                          <span>
                            <IoLocationOutline />
                          </span>
                          {res.location}
                        </small>
                      );
                    })}
                  </span>
                  {/* <span className="col-6 col-lg-12 text-nowrap">
                <i
                  className="fa-solid fa-location-dot"
                  style={{ paddingRight: "10px", color: "#7E86B7" }}
                ></i>
                <small>{data?.geographic_focus[0]}</small>
              </span> */}
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="seo-service-detail">
          <p className="seo-agency-des-admin">{data?.detailed_description}</p>
          <hr />

          <div className="seo-srv-borders industry">
            <div>
              <h4>
                <b>Services</b>
              </h4>
              <div className="outputss">
                {data?.services?.map((languages) => {
                  return (
                    <span className="span-ad-ag-selected" key={languages}>
                      {languages}
                    </span>
                  );
                })}
              </div>
            </div>
          </div>
          <hr />

          <div className="seo-srv-borders industry">
            <div>
              <h4>
                <b>Industries</b>
              </h4>
              <div className="outputss">
                {data?.industry?.map((industry) => {
                  return (
                    <>
                      <span className="span-hlt-text" key={industry}>
                        {industry}
                      </span>
                    </>
                  );
                })}
              </div>
            </div>
          </div>
          <hr />
          <div className="seo-srv-borders industry">
            <div>
              <h4>
                <b>Languages</b>
              </h4>
              <div>
                {data?.languages?.map((languages) => {
                  return (
                    <span className="span-hlt-text" key={languages}>
                      {languages}
                    </span>
                  );
                })}
              </div>
            </div>
          </div>
          <hr />
          <div className="seo-srv-borders industry">
            <div>
              <h4>
                <b>Geographic Focus</b>
              </h4>
              <div className="outputss">
                {data?.geographic_focus?.map((languages) => {
                  return (
                    <span className="span-hlt-text" key={languages}>
                      {languages}
                    </span>
                  );
                })}
              </div>
            </div>
          </div>
          {/* <hr /> */}
        </div>
      </div>
    </>
  );
};
