import React from "react";
import { Link } from "react-router-dom";
import logo from "../../Mycomponent/Assets/seoimg/logo.png";
import "../../Mycomponent/css/header.css";

const Header = () => {

  return (
    <>
      <header className="navBar header-container">
        <div className="d-flex w-100 h-100 container">
          <img src={logo} alt="RanksPro.io" className="header-logo" />
          {/* <p className="mb-0 header-guest-note">
            "This page provides a preview of the data. For access to the full
            dataset, please visit our tool."
          </p> */}
          <Link to={"/register"}>
            <button className="header-btn btn_new_bg">
              Start a Free Trial
            </button>
          </Link>
        </div>
      </header>
      <div className="header-guest-note-sm">
        {/* <p className="mb-0 container">
          "This page provides a preview of the data. For access to the full
          dataset, please visit our tool."
        </p> */}
      </div>
    </>
  );
};

export default Header;
