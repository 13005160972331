import React from "react";
import deleteImg from "../../Assets/areusure.png";
import { Player } from "@lottiefiles/react-lottie-player";
import upgradeAnimation from "../../dataFiles/animation/delete-animation.json";

const AreYousure = (props) => {
  const callBackHandler = (res) => {
    props.callback(res);
  };

  return (
    <div>
      <div className="pop">
        <div
          className="popBody delete-popup"
          style={{ borderRadius: "25px", overflow: "hidden" }}
        >
          <div className="project-img">
            <Player
              src={upgradeAnimation}
              className="player"
              loop
              autoplay
              speed={1}
            />
          </div>
          <div className="pl-con ps-0">
            <h5 className="heading-xlarge font-regular font-light-bold">
              Are You Sure ?
            </h5>
            <p className="mb-0 heading-medium">{props.title}</p>
            <div className="mt-3 delete-popup-btn">
              <button
                onClick={() => callBackHandler(false)}
                className="upgrade-plan-btn"
              >
                Cancel
              </button>
              <button
                onClick={() => callBackHandler(true)}
                className="upgrade-plan-btn"
              >
                Delete
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AreYousure;
