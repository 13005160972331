// React Imports
import { useState, useMemo } from "react";
import { useSelector } from "react-redux";
import axios from "axios";
import { FaChevronLeft, FaChevronRight, FaCheck } from "react-icons/fa";
import { KEYWORD_POST, UPDATE_KEYWORD } from "../../../../services/constants";
import { useNavigate } from "react-router-dom";

// MUI Imports
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import Step from "@mui/material/Step";
import StepLabel from "@mui/material/StepLabel";
import Stepper from "@mui/material/Stepper";
import Avatar from "@mui/material/Avatar";
import Radio from "@mui/material/Radio";
import Button from "@mui/material/Button";
import Checkbox from "@mui/material/Checkbox";
// Component Imports
import CustomAvatar from "./Avatar";

// images
import SubmitImage_2 from "./icons/submit-img.png";
import ProjectImage from "./icons/project-1.png";
import ProjectImage_2 from "./icons/project_2.png";
import LocationImage from "./icons/location.png";
import LocationImage_2 from "./icons/location_2.png";
import MobileIcon from "./icons/mobile.png";
import DesktopIcon from "./icons/desktop.png";
import DeviceImage from "./icons/devices.png";
import SubmitImage from "./icons/submit.png";
import crossIcon from "../../../Assets/rank-tracking-assets/cross.svg";
import backIcon from "../../../Assets/common/back.svg";

// Styled Component Imports
import StepperWrapper from "./Stepper";
import { getCurrentCountryFlag } from "../../../../helperJs/getCountryFlag";

const steps = [
  {
    icon: ProjectImage,
    title: "Projects",
    subtitle: "Select Project",
  },
  {
    icon: LocationImage,
    title: "Locations",
    subtitle: "Select Location",
    active: true,
  },
  {
    icon: DeviceImage,
    title: "Devices",
    subtitle: "Select Device",
  },
  {
    icon: SubmitImage,
    title: "Submit",
    subtitle: "Submit",
  },
];

const AddKeywordResearch = ({
  showAddKeyword,
  setShowAddKeyword,
  selectedKeywords,
}) => {
  const navigate = useNavigate();
  // States
  const [Project, setProject] = useState();
  const [Location, setLocation] = useState([]);
  const [Device, setDeviceType] = useState([]);
  const [activeStep, setActiveStep] = useState(0);
  const [message, setMessage] = useState("");
  // Vars
  const isLastStep = activeStep === steps.length - 1;

  const handleStep = (step) => () => {
    if (activeStep === 0 && !Project)
      return setMessage("Please select Project");
    if (activeStep === 1 && Location?.length === 0)
      return setMessage("Please select Locations");
    if (activeStep === 2 && Device?.length === 0)
      return setMessage("Please Select Devices");
    setActiveStep(step);
  };

  const userallprojectdetails = useSelector(
    (state) => state.userallprojectdetails
  );
  const selectedProject =
    Project &&
    userallprojectdetails.find((res) => res.Project[0].weburl === Project);
  const addprojectlocation = useSelector((state) => state.addprojectlocation);
  const userallprojectname = useSelector((state) => state.userallprojectname);
  const userKeywordlimit = useSelector((state) => state.userkeywordlimit);
  const UserKeywordLength = useSelector((state) => state.userkeywordlength);

  const project_location = useMemo(() => {
    if (!Project) return [];
    const selected_project = userallprojectdetails?.find(
      (project) => project?.Project?.[0]?.weburl === Project
    )?.Project;
    // Create a Map to keep track of unique location_name
    const uniqueLocations = new Map();
    // Iterate over the array and store unique location_name entries
    selected_project?.forEach((item) => {
      if (!uniqueLocations.has(item.location_name)) {
        uniqueLocations.set(item.location_name, item);
      }
    });
    const uniqueLocationArray = Array.from(uniqueLocations.values());
    return uniqueLocationArray;
  }, [Project, userallprojectdetails]);

  const handleDeviceType = (device) => {
    setMessage("");
    setDeviceType((obj) => {
      if (obj.includes(device)) {
        const filteredObj = obj.filter((o) => o !== device);
        return filteredObj;
      } else {
        // Add the new object to the array and return the updated array
        return [...obj, device];
      }
    });
  };

  const handleLocation = (e) => {
    setMessage("");
    setLocation((obj) => {
      // Filter out any existing object with the same location_code
      const existingObj = obj.find((o) => o.location_code === e.location_code);

      if (existingObj) {
        const filteredObj = obj.filter(
          (o) => o.location_code !== e.location_code
        );
        return filteredObj;
      } else {
        // Add the new object to the array and return the updated array
        return [
          ...obj,
          { location_code: e.location_code, location_name: e.location_name },
        ];
      }
    });
  };

  const selected_location = Location?.map((item) => {
    return item?.location_name;
  });

  const handleAddKeyword = async () => {
    const itemData = JSON.stringify(selectedKeywords);
    const deviceTypeData = JSON.stringify(Device);
    const all_location_code = Location?.map((item) => {
      return item.location_code;
    });
    const locations = JSON.stringify(all_location_code);

    const dataTwo = all_location_code?.map((location_code) => {
      return Device?.map((device) => {
        return selectedKeywords?.map((keyword) => {
          return {
            keyword,
            language_code: "en",
            location_code,
            device,
          };
        });
      });
    });

    const datatwoHandler = { data: dataTwo.flat() };
    const updatekeyword_Json = {
      id: selectedProject?.id,
      key: itemData,
      device: deviceTypeData,
      location: locations,
    };

    try {
      await Promise.all([
        axios.post(KEYWORD_POST(), datatwoHandler),
        axios.put(UPDATE_KEYWORD(updatekeyword_Json)),
      ]);
      setTimeout(() => {
        navigate("/rank-tracker");
        window.location.reload(false);
      }, 100);
    } catch (error) {
      console.error("Error in API calls:", error);
    }
  };

  const handleClose = () => {
    setShowAddKeyword(false);
    setActiveStep(0);
  };

  const handlePrev = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleNext = () => {
    if (!isLastStep) {
      if (activeStep === 0 && !Project)
        return setMessage("Please select Project");
      if (activeStep === 1 && Location?.length === 0)
        return setMessage("Please select Locations");
      if (activeStep === 2 && Device?.length === 0)
        return setMessage("Please Select Devices");

      setActiveStep((prevActiveStep) => prevActiveStep + 1);
    } else if (
      selectedKeywords?.length * Device?.length * Location?.length +
        Number(UserKeywordLength) >=
      Number(userKeywordlimit)
    ) {
      setMessage(
        `You have reached your maximum limit of ${userKeywordlimit} keywords`
      );
    } else {
      handleClose();
      handleAddKeyword();
    }
  };

  return (
    <Dialog
      fullWidth
      maxWidth="md"
      open={showAddKeyword}
      onClose={handleClose}
      scroll="body"
      className="add-keyword-research"
    >
      <DialogTitle
        variant="h5"
        className="d-flex flex-column text-center gap-2 pb-sm-0 pl-sm-5 dialog-title "
      >
        Add Keyword
        <Typography
          component="span"
          className="d-flex flex-column text-center dialog-sub-title"
        >
          Provide data with this form to create your app.
        </Typography>
      </DialogTitle>
      <DialogContent className="p-sm-5 pb-sm-5">
        <IconButton
          onClick={handleClose}
          className="position-absolute top-0 end-0 mt-2 me-2"
        >
          <img src={crossIcon} alt="cancel" width={25} height={25} />
        </IconButton>
        <div className="d-flex flex-column flex-md-row gap-5">
          <StepperWrapper>
            <Stepper
              nonLinear
              activeStep={activeStep}
              orientation="vertical"
              connector={<></>}
              className="d-flex flex-column gap-3 min-vh-220"
            >
              {steps.map((label, index) => {
                return (
                  <Step key={index} onClick={handleStep(index)}>
                    <StepLabel icon={<></>} className="p-0 cursor-pointer">
                      <div className="d-flex gap-2">
                        <Avatar
                          variant="rounded"
                          className={`${
                            activeStep === index
                              ? "bg-primary text-white shadow-sm"
                              : activeStep > index
                              ? "bg-primaryLight text-primary"
                              : ""
                          }`}
                        >
                          <img
                            src={label.icon}
                            alt={label.title}
                            style={{ width: "30px", height: "30px" }}
                          />
                        </Avatar>
                        <div className="d-flex flex-column">
                          <Typography
                            className="text-uppercase font-weight-medium"
                            color="text.primary"
                            style={{ lineHeight: "1.2" }}
                          >
                            {label.title}
                          </Typography>
                          <Typography variant="body2">
                            {label.subtitle}
                          </Typography>
                        </div>
                      </div>
                    </StepLabel>
                  </Step>
                );
              })}
            </Stepper>
          </StepperWrapper>
          <div className="" style={{ flex: "auto" }}>
            <div className="d-flex flex-column gap-4">
              {activeStep === 0 ? (
                <div className="d-flex flex-column gap-1">
                  <Typography variant="h6" className="dialog-sub-heading">
                    Select Project
                  </Typography>
                  <div className="add-keyword-research-list list-scroll">
                    {userallprojectname &&
                      userallprojectname?.map((project) => {
                        return (
                          <>
                            <div
                              onClick={() => {
                                setMessage("");
                                setProject(project);
                              }}
                              className="d-flex align-items-center justify-content-between cursor-pointer gap-3"
                            >
                              <div className="d-flex align-items-center gap-2">
                                <Radio
                                  value={project}
                                  onChange={(e) => {
                                    setMessage("");
                                    setProject(e.target.value);
                                  }}
                                  checked={project === Project}
                                />
                                <div className="d-flex flex-column gap-1">
                                  <Typography
                                    className="font-weight-medium point"
                                    color="text.primary"
                                  >
                                    {project}
                                  </Typography>
                                </div>
                              </div>
                            </div>
                          </>
                        );
                      })}
                  </div>
                </div>
              ) : activeStep === 1 ? (
                <div className="d-flex flex-column gap-1">
                  <Typography variant="h6" className="dialog-sub-heading">
                    Select Location
                  </Typography>
                  <div className="add-keyword-research-list list-scroll">
                    {project_location &&
                      project_location?.map((location) => {
                        return (
                          <>
                            <div
                              onClick={() => handleLocation(location)}
                              className="d-flex align-items-center justify-content-between cursor-pointer gap-3"
                            >
                              <div className="d-flex align-items-center gap-3">
                                <img
                                  src={getCurrentCountryFlag(
                                    location?.location_name
                                  )}
                                  alt={location?.location_name}
                                  style={{ objectFit: "contain" }}
                                  height={30}
                                  width={30}
                                />
                                <div className="d-flex flex-column gap-1">
                                  <Typography
                                    className="font-weight-medium"
                                    color="text.primary"
                                  >
                                    {location?.location_name}
                                  </Typography>
                                </div>
                              </div>
                              <Checkbox
                                checked={selected_location?.includes(
                                  location?.location_name
                                )}
                                readOnly
                              />
                            </div>
                          </>
                        );
                      })}
                  </div>
                </div>
              ) : activeStep === 2 ? (
                <div className="d-flex flex-column gap-1">
                  <Typography variant="h6" className="dialog-sub-heading">
                    Select Device
                  </Typography>
                  <div className="add-keyword-research-list list-scroll">
                    <div
                      onClick={() => handleDeviceType("desktop")}
                      className="d-flex align-items-center justify-content-between cursor-pointer gap-3"
                    >
                      <div className="d-flex align-items-center gap-3">
                        <img
                          src={DesktopIcon}
                          alt="desktop"
                          height={40}
                          width={40}
                        />
                        <div className="d-flex flex-column gap-1">
                          <Typography
                            className="font-weight-medium"
                            color="text.primary"
                          >
                            Desktop
                          </Typography>
                        </div>
                      </div>
                      <Checkbox
                        checked={Device?.includes("desktop")}
                        readOnly
                      />
                    </div>
                    <div
                      onClick={() => handleDeviceType("mobile")}
                      className="d-flex align-items-center justify-content-between cursor-pointer gap-3"
                    >
                      <div className="d-flex align-items-center gap-3">
                        <img
                          src={MobileIcon}
                          alt="mobile"
                          height={40}
                          width={40}
                        />
                        <div className="d-flex flex-column gap-1">
                          <Typography
                            className="font-weight-medium"
                            color="text.primary"
                          >
                            Mobile
                          </Typography>
                        </div>
                      </div>
                      <Checkbox checked={Device?.includes("mobile")} readOnly />
                    </div>
                  </div>
                </div>
              ) : (
                <div className="d-flex flex-column align-items-center gap-3">
                  <div className="d-flex flex-column align-items-center gap-1">
                    <Typography variant="h5" className="dialog-sub-heading">
                      Submit 🥳
                    </Typography>
                    <Typography variant="body2">
                      Submit to kickstart your project.
                    </Typography>
                  </div>
                  <img alt="submit-img" src={SubmitImage_2} height={174} />
                </div>
              )}
              {message && <span className="msg-error">{message}</span>}
              <div className="d-flex align-items-center justify-content-between">
                <Button
                  variant="outlined"
                  color="secondary"
                  disabled={activeStep === 0}
                  onClick={handlePrev}
                  startIcon={<FaChevronLeft className="icon-type-svg" />}
                >
                  Previous
                </Button>
                <Button
                  variant="contained"
                  color={isLastStep ? "success" : "primary"}
                  onClick={handleNext}
                  disabled={
                    isLastStep
                      ? !Project ||
                        Location?.length === 0 ||
                        Device?.length === 0
                      : false
                  }
                  endIcon={
                    isLastStep ? (
                      <FaCheck className="icon-type-svg" />
                    ) : (
                      <FaChevronRight className="icon-type-svg" />
                    )
                  }
                >
                  {isLastStep ? "Submit" : "Next"}
                </Button>
              </div>
            </div>
          </div>
        </div>
      </DialogContent>
    </Dialog>
  );
};

export default AddKeywordResearch;
