import React from "react";
import logo from "../assets/images/Global/logoMain.png";
import "./component.scss";
function Loader() {
  return (
    <div className="Loader">
      <div
        className="cmc"
        style={{
          position: "fixed",
          top: "0",
          left: "0",
          height: "100vh",
          width: "100vw",
          background: "#fff",
        }}
      >
        <div role="status" style={{ position: "relative" }}>
          {" "}
          <img
            style={{
              width: "250px",
              animation: "loading 1s infinite alternate",
              marginTop: "-5rem",
            }}
            src={logo}
            alt="Loading-image"
          ></img>
        </div>
      </div>
      <DottLoader />
    </div>
  );
}

export default Loader;

export const DottLoader = () => {
  return (
    <>
      <div
        style={{
          color: "#365486",
          width: "0.8rem",
          height: "0.8rem",
          animation: " .70s linear infinite spinner-grow",
        }}
        className="spinner-grow"
        role="status"
      ></div>
      <div
        style={{
          color: "#F6BB26",
          width: "0.9rem",
          height: "0.9rem",
          animation: " .75s linear infinite spinner-grow",
        }}
        className="spinner-grow"
        role="status"
      ></div>
      <div
        style={{
          color: "#EE2E2D",
          width: "1rem",
          height: "1rem",
          animation: " .80s linear infinite spinner-grow",
        }}
        className="spinner-grow"
        role="status"
      ></div>
    </>
  );
};
export const DottLoaderFixed = () => {
  return (
    <>
      <div
        className="cmc"
        style={{
          position: "fixed",
          top: "0",
          left: "0",
          height: "100vh",
          width: "100vw",
        }}
      >
        <DottLoader />
      </div>
    </>
  );
};
