import axios from "axios";
import React, { useEffect, useState } from "react";
import "./ToolsUsage.scss";
import KEYIMG from "../../assets/KeyImg.png";
import { useNavigate } from "react-router-dom";
import { VscGraph } from "react-icons/vsc";
import { FaPager } from "react-icons/fa";
import {
  freeTool_TablePageVital,
  freeTool_TableRankTracker,
  freeTool_TablebackLink,
} from "../../../Navigation/constant";
import { BiLink } from "react-icons/bi";
import { DottLoader } from "../../../Mycomponent/share/loader";
const ToolUsage = () => {
  const [isLoading, setisLoading] = useState(false);
  const nav = useNavigate();
  const [AllLists, setAllLists] = useState([]);
  const RANKTRACKER = axios.get(
    "https://app.rankspro.io/api/user/freerankusage/"
  );
  const PAGEVITALS = axios.get(
    "https://app.rankspro.io/api/user/freepagevitaldata/"
  );
  const BACKLINKS = axios.get(
    "https://app.rankspro.io/api/user/freeusagebacklink/"
  );
  const listNames = [
    "Free Rank Tracker",
    "Free Page Vitals",
    "Free  Backlinks Tracker ",
  ];
  const TotalkeyNames = [
    "Total Rank Tracked",
    "Total  Page Vitals",
    "Total Backlinks ",
  ];
  const Router = [
    freeTool_TableRankTracker,
    freeTool_TablePageVital,
    freeTool_TablebackLink,
  ];
  const SubImg = [<VscGraph />, <FaPager />, <BiLink />];

  useEffect(() => {
    setisLoading(true);
    setAllLists([]);
    Promise.all([RANKTRACKER, PAGEVITALS, BACKLINKS]).then((Response) => {
      Response?.forEach((item, index) => {
        item["list_name"] = listNames[index];
        item["TotalKeyName"] = TotalkeyNames[index];
        item["Routes"] = Router[index];
        item["img"] = SubImg[index];
        setAllLists(Response);
      });
    });
    setTimeout(() => {
      setisLoading(false);
    }, 1000);
  }, [0]);

  return (
    <>
      <h2 className="table_title heading-large font-bold mb-4">Tool Usage</h2>
      <div className="row">
        {isLoading ? (
          <DottLoader />
        ) : (
          AllLists?.map((val, index) => {
            return (
              <div className="col-md-4 col-lg-4 col-12" key={index}>
                <div
                  className="ViewToolsListBox"
                  onClick={() =>
                    nav(`../${val.Routes}`, { state: val.data.reverse() })
                  }
                >
                  <div className="d-flex justify-content-between align-items-center">
                    <div className="key-nameTool font-bold text-center">
                      {val.list_name}
                    </div>
                    <div className="keyIconTool">
                      <img src={KEYIMG} alt={val.list_name} />
                    </div>
                  </div>
                  <hr />
                  <div className="d-flex justify-content-between">
                    <div className="d-flex">
                      <div className="keyIconSub">{val.img}</div>
                      <div className="descript ms-2">
                        {val.TotalKeyName}: <strong>{val?.data.length}</strong>
                      </div>
                    </div>
                    <div className="deleteBt">
                      {/* <Delete
                                            style={{ cursor: "pointer" }}
                                            onClick={() => {
                                                handleOpen(val.list_name);
                                            }}
                                        /> */}
                    </div>
                  </div>
                </div>
              </div>
            );
          })
        )}
      </div>
    </>
  );
};

export default ToolUsage;
