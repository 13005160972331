import React from "react";
import { Header } from "../HeadFoot/header/Header";
import { Footer } from "../HeadFoot/footer/Footer";
import "./promotional.scss";
import imgSrc1 from "../../assets/images/promotional/promo-1.png";
import imgSrc2 from "../../assets/images/promotional/promo-2.png";
import imgSrc3 from "../../assets/images/promotional/promo-3.png";
import imgSrc4 from "../../assets/images/promotional/promo-4.png";
import imgSrc5 from "../../assets/images/promotional/promo-5.png";
import { FaDownload } from "react-icons/fa";
import { useSelector } from "react-redux";

function Card({ data }) {
  const downloadImage = (image) => {
    // Create a Blob from the imported image
    fetch(image)
      .then((response) => response.blob())
      .then((blob) => {
        // Create a temporary URL for the Blob
        const url = URL.createObjectURL(blob);
        // Create a hidden anchor element
        const anchor = document.createElement("a");
        anchor.href = url;
        anchor.download = "RanksPro.io-Promotion-Image.jpg"; // Specify the desired filename
        // Trigger a click on the anchor element to initiate the download
        document.body.appendChild(anchor);
        anchor.click();
        document.body.removeChild(anchor);
        // Revoke the temporary URL to free up resources
        URL.revokeObjectURL(url);
      })
      .catch((error) => console.error("Error downloading image:", error));
  };

  return (
    <div className="cont">
      <div className="Card">
        <img src={data?.imgSrc} alt="" className="card-img" />
      </div>
      <button onClick={() => downloadImage(data?.imgSrc)}>
        Download
        <FaDownload color="#365486" className="ms-2 dnl-icon" />
      </button>
    </div>
  );
}
function Promotional() {
  const user_profile = useSelector((state) => state.profile);

  const data = [
    {
      name: "name-1",
      link: "",
      imgSrc: imgSrc1,
    },
    {
      name: "name-2",
      link: "",
      imgSrc: imgSrc2,
    },
    {
      name: "name-3",
      link: "",
      imgSrc: imgSrc3,
    },
    {
      name: "name-4",
      link: "",
      imgSrc: imgSrc4,
    },
    {
      name: "name-5",
      link: "",
      imgSrc: imgSrc5,
    },
  ];

  return (
    <>
      {/* <Header /> */}
      {user_profile?.profile2.length === 0 ? (
        <div className="affiliate-inprogress">
          {/* <div className="content"> */}
          <h1>Your Affiliate registration is in progress</h1>
          <p>Thank you for your patience....</p>
        </div>
      ) : (
        <div className="promotional-container">
          <div className="section-1">
            <div className="card-cont">
              {data?.map((item, index) => {
                return <Card data={item} key={`promotional-card-${index}`} />;
              })}
            </div>
          </div>
        </div>
      )}
      {/* <Footer /> */}
    </>
  );
}

export default Promotional;
