import React from "react";
import { Link } from "react-router-dom";

const BlurCard = ({ redirectPath, useBtn, onActionCall }) => {
  return (
    <div className="blur-card-wth-btn">
      {useBtn ? (
        <button
          className="Pr-btn px-3 py-1 mt-0"
          style={{ height: "35px" }}
          onClick={onActionCall}
        >
          View
        </button>
      ) : (
        <Link to={redirectPath}>
          <button className="Pr-btn px-3 py-1 mt-0" style={{ height: "35px" }}>
            View
          </button>
        </Link>
      )}
    </div>
  );
};

export default BlurCard;
