import React, { useEffect, useState } from "react";
import Logo from "./Images/logo.png";
import "./SeoImpact.scss";
import { BiLogInCircle, BiSearchAlt2 } from "react-icons/bi";
import { FaUserCheck } from "react-icons/fa6";
import { Link } from "react-router-dom";
import { SeoImpactModal } from "./modal/SeoImpactToolMd";
import axios from "axios";
import { ReCaptcha } from "../../Mycomponent/share/components/googleRecaptacha";
import ToolNav from "../../Mycomponent/share/components/tool-nav";
import seoImpactImg from "../../Mycomponent/Assets/seo-impact/seo-insights-tool.png";
import SEC1 from "./Images/sec1.png";
import { FooterTool } from "../../Mycomponent/pages/Tools/Footer/Footer";

function SeoImpact() {
  const [values, setvalues] = useState({
    keyword: "",
    url: "",
  });
  // Seo impact  function
  //   const [seoImpactAlert, setseoImpactAlert] = useState(false);
  const [seoImpactLoader, setseoImpactLoader] = useState(false);
  const [EmptyErr, setEmptyErr] = useState(false);
  const [ImpactData, setImpactData] = useState([]);
  const [seoImpactAlert, setseoImpactAlert] = useState(false);
  const [isValidUrl, setIsValidUrl] = useState(true);

  const HandleOnchange = (event) => {
    const name = event.target.name;
    setvalues(() => {
      return { ...values, [name]: event.target.value };
    });
    // Regular expression for URL validation
  };

  useEffect(() => {
    if (values.url) {
      const urlPattern = /^(ftp|http|https):\/\/[^ "]+$/;
      setIsValidUrl(urlPattern.test(values.url));
      setEmptyErr(false);
    } else {
      setIsValidUrl(true);
      // setEmptyErr(false);
    }
  }, [HandleOnchange]);

  const postFreePageApi = "https://app.rankspro.io/api/user/freepagevitals/";

  const freePageVitals = () => {
    const config = {
      keyword: values?.keyword || "no keyword",
      url: values?.url,
    };
    axios({
      method: "post",
      url: postFreePageApi,
      headers: {},
      data: config,
    });
  };

  const SeoImpact = () => {
    if (values.url) {
      if (isValidUrl) {
        setseoImpactLoader(true);
        setImpactData([]);
        setseoImpactAlert(true);
        const API = `https://app.rankspro.io/api/user/seoimpact/?url=${values?.url}&target=${values?.keyword}`;
        try {
          axios.post(API).then((res) => {
            freePageVitals();
            setImpactData(res?.data);
            setseoImpactLoader(false);
          });
        } catch (error) {
          setImpactData([]);
          setseoImpactLoader(false);
          setImpactData([]);
        }
      } else {
        setIsValidUrl(false);
      }
    } else {
      setEmptyErr(true);
    }
  };

  const Section1Ul = [
    "Scan your website instantly to identify and fix on-page SEO issues",
    "Get valuable insights into your website's content, structure, and SEO impact to improve the quality of your content, such as its readability score and overall sentiment. ",
    "Benchmark your SEO performance against your competitors",
    "Track your SEO progress over time to stay on top of your competitors",
    "Get detailed and easy to follow PDF report    ",
  ];

  return (
    <>
      <div style={{ overflow: "auto", height: "100vh" }}>
        <SeoImpactModal
          {...{
            values,
            seoImpactAlert,
            setseoImpactAlert,
            ImpactData,
            seoImpactLoader,
          }}
        />

        <div className="SEO_cont">
          <ToolNav />
          <div className="Seo_Sec2 d-flex">
            <div className="seo-impact-container">
              <h4 className="heading-medium font-regular">Free SEO Tools /</h4>
              <h1 className="heading-xlarge font-bold">RanksPro.io</h1>
              <p className="heading-medium font-regular">
                Try the free version of RanksPro.io SEO Insights Checker. Get a
                glimpse into the power of our premium tool.{" "}
              </p>
              <div className="Seo_search">
                <div className="row gy-2">
                  <div className="col-12 col-md-6 col-lg-6">
                    <input
                      type="search"
                      placeholder="Enter Keyword (OPTIONAL)"
                      name="keyword"
                      onChange={HandleOnchange}
                      onKeyUp={(e) => e.key === "Enter" && SeoImpact()}
                      value={values.keyword}
                      autoComplete="off"
                    />
                  </div>
                  <div className="col-12 col-md-6 col-lg-6">
                    <input
                      type="search"
                      placeholder="Enter URL (MANDATORY)"
                      name="url"
                      onChange={HandleOnchange}
                      onKeyUp={(e) => e.key === "Enter" && SeoImpact()}
                      value={values.url}
                      autoComplete="off"
                    />
                    {!isValidUrl && (
                      <p
                        style={{
                          margin: 0,
                          color: "red",
                          fontSize: "1em",
                          fontWeight: 600,
                          marginTop: ".5em",
                        }}
                        className="error"
                      >
                        Invalid URL
                      </p>
                    )}
                    {EmptyErr && (
                      <p
                        style={{
                          margin: 0,
                          color: "red",
                          fontSize: "1em",
                          fontWeight: 600,
                          marginTop: ".5em",
                        }}
                      >
                        URL is Mandatory.
                      </p>
                    )}
                  </div>
                  <div className="col-12">
                    <button className="btn-SeoIMpact" onClick={SeoImpact}>
                      Submit
                    </button>
                  </div>
                </div>
              </div>
            </div>
            <img
              src={seoImpactImg}
              alt="RanksPro.io SEO Insights Checker"
              className="SeoIMoact-img"
            />
          </div>
        </div>

        <ReCaptcha />
        {/* Body Start */}
        <div className="SeoImpactBody">
          <div className="SeoImpactBox">
            <h5>Get on-page SEO analysis with free SEO Insight Checker</h5>
            <p>
              Analyze your website with the free version of RanksPro.io Insights
              Checker and gain insights to simplify getting traffic. The tool
              helps in identify any on-page SEO issues and address ranking
              factors.
            </p>

            <h5>How to use SEO Insights Checker?</h5>
            <p>
              SEO Insights Checker makes it easy to analyze your website with
              SEO insight. Simply enter any domain and click on submit and it
              will crawl your website and check it against various parameters
              such as meta information, page quality, anchor text, overall
              sentiment, readability score, overall SEO impact and more
            </p>
            <div className="IMGbox">
              <img src={SEC1} alt="SEO Insights Checker" />
            </div>

            <h5>What more do you get with the free rank tracking tool? </h5>
            <p>
              RanksPro.io Free SEO Insight Checker is a real-time cloud-based
              crawler that crawls through the technical issues of a website and
              helps you identify and fix the errors in the on-page optimization.
              Here are just a few of the benefits of using the SEO Insights
              Checker:
            </p>
            <ul className="ms-4 mt-4 ">
              {Section1Ul?.map((Li) => (
                <li key={Li}>{Li}</li>
              ))}
            </ul>
            <br />
            <hr />
            <div className="secEnd">
              <div className="row">
                <div className="col-md-9 col-12 Leftb">
                  <h3>
                    Sign up for a free SEO insight check today and perform SEO
                    site analysis to better optimize your site.
                  </h3>
                </div>
                <div className="col-md-3 Rightb">
                  <a href="https://app.rankspro.io/register" target="_blank">
                    <button className="btnNormal">Sign up for a free</button>
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
        <FooterTool />
      </div>
    </>
  );
}

export default SeoImpact;
