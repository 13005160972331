import { useCallback } from "react";
import { useSelector } from "react-redux";
import { ZustandStore } from "../store/zustand-store";

// Function to get current date in 'YYYY-MM-DD' format
const getCurrentDate = () => {
  const date = new Date();
  const year = date.getFullYear();
  const month = String(date.getMonth() + 1).padStart(2, "0");
  const day = String(date.getDate()).padStart(2, "0");
  return `${year}-${month}-${day}`;
};

// Function to get current time in 'HH:MM' format
const getCurrentTime = () => {
  const date = new Date();
  const hours = String(date.getHours()).padStart(2, "0");
  const minutes = String(date.getMinutes()).padStart(2, "0");
  return `${hours}:${minutes}`;
};

const useWebHook = () => {
  const EMAIL = useSelector((state) => state?.email);
  const isAdmin = localStorage.getItem("isAdmin");
  const plan_name = localStorage.getItem("plan_name");
  const name = useSelector((state) => state.name);
  const user_type = localStorage.getItem("user_type");
  const { plan_details } = ZustandStore((state) => state);

  const fetchData = useCallback(
    async (data) => {
      const web_hook_url = "https://app.rankspro.io/api/user/userwebhook/";
      const site_audit_click = [
        //site audit data
        {
          email: EMAIL,
          name: name,
          sitesudit: data?.domain,
          time: getCurrentTime(),
          date: getCurrentDate(),
        },
        {
          email: EMAIL,
          name: name,
          plan_name:
            isAdmin === "true"
              ? "admin"
              : plan_details?.referral_user
              ? "Appsumo Lifetime Plan"
              : plan_details?.plan_name || plan_name,
          time: getCurrentTime(),
          date: getCurrentDate(),
        },
        // upgrade
        {
          email: EMAIL,
          name: name,
          try_upgrade: data?.try_upgrade,
          time: getCurrentTime(),
          date: getCurrentDate(),
        },
        // user
        {
          email: EMAIL,
          name: name,
          user: plan_details?.user_type || user_type,
          time: getCurrentTime(),
          date: getCurrentDate(),
        },
      ];

      try {
        const response = await fetch(web_hook_url, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({ details: site_audit_click }),
        });
        if (!response.ok) {
          throw new Error(`Error: ${response.statusText}`);
        }
      } catch (err) {
        console.log(err.message);
      }
    },
    [EMAIL, isAdmin, plan_name, user_type, plan_details]
  );
  return { fetchData };
};

export default useWebHook;
