import React, { useEffect, useState } from "react";
import { ToastContainer, toast } from "react-toastify";
import LOGIN from "../../assets/images/Login/verify.png";
import LOGO from "../../assets/images/Global/logo.png";
import { Link } from "react-router-dom";
import { Store } from "../../utils/STORE";
import axios from "axios";
import {
  AFFILIATE,
  POST_OTP,
  REGISTER,
  AFF_NEWUSER_REGISTER,
} from "../../utils/Api";
import { useNavigate } from "react-router-dom";

export const Verify = () => {
  const nav = useNavigate();
  const [OTPvalue, setOTPvalue] = useState("");
  const { OTP, RegisterData, locationName, phone, Resend, email } = Store(
    (state) => state
  );
  const [Error, setError] = useState("");
  const [Count, setCount] = useState(0);
  const [CountValid, setCountValid] = useState(30);
  const [isDisable, setisDisable] = useState(false);

  const HandleSubmit = () => {
    const { email, name, password, confirmPassword, address } = RegisterData;
    const PAYLOAD = {
      email,
      name,
      password,
      password2: confirmPassword,
      tc: "True",
      country: locationName,
      phone,
      address,
    };
    // if (!numError) {
    axios
      .post(REGISTER, PAYLOAD)
      .then(({ data }) => {
        if (data) {
          toast.info(data?.msg, {
            position: "top-right",
            autoClose: 2000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "light",
          });
          axios.post(AFF_NEWUSER_REGISTER(), {
            email,
            name,
            country: locationName,
            phone,
            address,
          });
          axios.post(AFFILIATE(email)).then((res) => {
            //   resetForm();
            setTimeout(() => nav("../affiliate/login"), 2000);
          });
        }
      })
      .catch((err) => {
        toast.info("User Already Registered", {
          position: "top-right",
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        });
        //   resetForm();
        setTimeout(() => nav("../affiliate/signup"), 2000);
      });
    // }
  };

  const SUBMIT = () => {
    if ((OTP, RegisterData)) {
      if (OTP == OTPvalue) {
        HandleSubmit();
        setError("");
      } else {
        setError("OTP is not valid");
      }
    }
  };

  const VERIFY = () => {
    const PAYLOAD = {
      email: RegisterData?.email,
    };
    axios.post(POST_OTP(), PAYLOAD).then(({ data }) => {
      if (data) {
        Store.setState({ OTP: data.otp });
      }
    });
  };

  const HandleResend = () => {
    VERIFY();
    setisDisable(true);
    Store.setState({ Resend: true });
    setCount(30);
  };

  useEffect(() => {
    let interval;
    if (Count > 0) {
      interval = setInterval(() => {
        setCount((prevTimer) => prevTimer - 1);
      }, 1000);
    } else {
      Store.setState({ Resend: false });
      setisDisable(false);
    }

    return () => clearInterval(interval);
  }, [Count]);

  useEffect(() => {
    let interval;
    if (CountValid > 0) {
      interval = setInterval(() => {
        setCountValid((prevTimer) => prevTimer - 1);
      }, 1000);
    }
    return () => clearInterval(interval);
  }, [CountValid]);

  return (
    <div className="VerifyContainer">
      <div className="LoginContainer ForgotContainer Register">
        <ToastContainer />
        <div className="Login">
          <div className="row">
            <div className="col-md-6 col-lg-6 d-none d-md-block d-lg-block">
              <div className="log_left">
                <img src={LOGIN} alt="Login " />
              </div>
            </div>
            <div className="col-md-6 col-lg-6">
              <div className="log_Right">
                <div className="log_content">
                  <div className="logo">
                    <img src={LOGO} alt="" />
                  </div>
                  <h1>Verify your Account</h1>
                  <div className="Feilds">
                    <div className="INPUTPR">
                      <div className="input_group">
                        <input
                          type="email"
                          name="email"
                          autoComplete="off"
                          placeholder="Enter Your OTP"
                          id="myInput"
                          onChange={(e) => {
                            setOTPvalue(e.target.value);
                            setError("");
                          }}
                        />
                      </div>
                      <div name="email" component="div" className="error">
                        {Error}{" "}
                      </div>
                    </div>
                  </div>

                  <div className="FlexBtn">
                    <button onClick={SUBMIT}>Verify</button>
                  </div>
                  <div className="resend">
                    <div className="otp" style={{ flexDirection: "row" }}>
                      {isDisable && <p>{Count}</p>}
                      <button
                        onClick={HandleResend}
                        disabled={isDisable || CountValid > 1}
                        style={{
                          opacity: isDisable || CountValid > 1 ? 0.5 : 1,
                        }}
                      >
                        Resend Code
                      </button>
                    </div>
                  </div>
                  <div className="OR">
                    <div className="line1"></div>
                    <span>OR</span>
                    <div className="line1"></div>
                  </div>
                  <div className="RegisterLink">
                    <p>
                      Already have an account?{" "}
                      <Link to={"/affiliate/login"}>Log In</Link>
                    </p>
                  </div>
                  {/* </Form>
                </Formik> */}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
