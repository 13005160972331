import React, { useEffect, useState, useMemo, useRef } from "react";
import "../css/domainOverview.scss";
import TrafficChart from "../share/charts/TrafficChart";
import axios from "axios";
import {
  DOMAINOVERVIEW_GET,
  get_backlink_data,
  post_backlink_data,
} from "../../services/constants";
import { DottLoader } from "../share/loader";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import StackedBarChartMulti from "../share/charts/stackbarChatMulti";
import searchArrow from "../Assets/keyword/search-arrow.svg";
import { getMonthName } from "../share/upDater/constant";
import { ReactComponent as RightArrowIcon } from "../Assets/overview-img/right-arrow.svg";
import { IoArrowUpSharp, IoArrowDownSharp } from "react-icons/io5";
import {
  domainRegex,
  formatNumberWithSymbols,
  removeUndefinedAndNaN,
} from "../../helperJs/helper";
import { FiDownload } from "react-icons/fi";
import CountryBasedOnLanuage from "../share/components/CountryBasedOnLanuage";
import { ZustandStore } from "../../store/zustand-store";
import Spinner from "../share/components/spinner";
import jsPDF from "jspdf";
import html2canvas from "html2canvas";
import RanksProLogo from "../../Mycomponent/Assets/site-audit/RanksProLogo.png"
import {
  Page,
  Text,
  View,
  Document,
  StyleSheet,
  Image,
  PDFDownloadLink,
} from "@react-pdf/renderer";
import { getCurrentYear } from "../share/helper-js/current-year";

const styles = StyleSheet.create({
  page: {
    paddingHorizontal: 20,
    flexDirection: "column",
    paddingTop: 30,
    paddingBottom: 80, // Set vertical padding to 30 pixels
    borderStyle: "solid",
  },
  header: {
    paddingBottom:20
  },
  footer: {
    fontSize: 12,
    textAlign: "center",
    color: "grey",
    position: "absolute",
    bottom: 20,
    left: 0,
    right: 0,
  },
  logo: {
    width: 250,
  },
  title: {
    fontSize: 24,
    textAlign: "center",
    marginBottom: 20,
  },

 
  tableContainer: {
    marginBottom: 20,
  },

  keywordLoc: {
    flex: 1, // Each cell should take equal space within the row
    padding: 5,
    borderRightWidth: 1,
    borderColor: "#000",
    justifyContent: "start",
    minHeight: 40,
    height: "100%",
    alignItems: "start",
    flexDirection: "column",
  },
 
  fixedWidthCell: {
    maxWidth: 80, // Disable flex for fixed width cells
  },
  cardContainer: {
    flexDirection: "row",
    flexWrap: "wrap", // Enable flex wrapping
    justifyContent: "space-between", // Arrange cards with equal spacing
    marginTop: 10,
  },
  card: {
    flexDirection: "row",
    justifyContent: "space-between",
    width: 180,
    backgroundColor: "#FFFFFF",
    padding: 10,
    borderRadius: 5,
    marginBottom: 20,
  },
  image: {
    width: 30,
    height: 40,
  },
  title: {
    fontSize: 11,
    fontWeight: "bold",
    marginBottom: 5,
    color: "#6c757d",
  },
  description: {
    fontSize: 16,
  },
  imageContainer: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  arrowIcon: {
    width: 20,
    height: 20,
    objectFit: "contain",
  },
  view_button: {
    borderWidth: 1,
    borderColor: "#365486",
    padding: 5,
    borderRadius: 7,
    color: "#365486",
    fontSize: 11,
  },
  keyword_bold: {
    fontSize: 13,
    fontWeight: "bold",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  keyword: {
    fontSize: 11,
    color: "#6c757d",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  rank: {
    fontSize: 11,
    color: "#6c757d",
    textAlign: "center",
    marginRight: 10,
  },
  
 
  pageNumber: {
    position: "absolute",
    fontSize: 11,
    bottom: 45,
    left: 0,
    right: 0,
    textAlign: "center",
    color: "grey",
  },
  link_text: {
    fontSize: 7,
    textDecoration: "none",
    textOverflow: "hidden",
    textAlign: "start",
  },
  logoHead: {
    width: 125,
  },
});







export const PdfFile = ({pdfNewData}) => {
  
  const DashImage = sessionStorage.getItem("dashboardImage");
  const Data = JSON.parse(sessionStorage.getItem("pdfSessionData"));
  const Web_url = Data.domainName
  const image_logo = pdfNewData
    ? pdfNewData?.reset
      ? RanksProLogo
      : pdfNewData?.logo?.src
    : RanksProLogo;
  return(
  <Document>
    <Page size={"A4"} style={styles.page}>
      <View
        style={{
          alignItems: "center",
          display: "flex",
          height: "82vh",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <Image style={styles.logo} src={image_logo} />
        <Text style={{ marginBottom: 20 }}>Organic Search</Text>
        <Text style={styles.title}>Domain: {Web_url}</Text>
      </View>


</Page>
<Page size={"A4"} style={styles.page}>
  
<View
          style={{
            ...styles.header,
            alignItems: pdfNewData?.reset
              ? "flex-start"
              : pdfNewData?.logo.position,
          }}
          fixed
        >
          <Image style={styles.logoHead} src={image_logo} />
        </View>

      <View style={{ ...styles.tableContainer }}>
        <View>
       <Image src={DashImage}/>
        </View>
      </View>

      <Text style={styles.footer} fixed>
      {pdfNewData?.reset
            ? `Copyright ${getCurrentYear} eSearch Logix | Prepared using RanksPro.io`
            : pdfNewData?.footer}
      </Text>

     
    </Page>
  </Document>
);}









const DomainOverview = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const location = useLocation();
  const {
    country_Language_Data,
    site_overview_page_data,
    site_overview_backlink_data,
  } = ZustandStore((state) => state);
  const websiteurl = useSelector((state) => state.websiteurl);
  const country = useSelector((state) => state.getcountry);
  const queryParams = new URLSearchParams(location.search);
  const siteDomain = useSelector((state) => state.siteDomain);
  const email = localStorage.getItem("admin_mail");
  const domainviewlimit = useSelector((state) => state.domainviewlimit);

  const [dataLoaded, setDataLoaded] = useState(false);
  const [domainName, setDomainName] = useState("");
  const [domainLocation, setDomainLocation] = useState({
    location_name: "United States",
    location_code: 2840,
    location_flag: "https://flagsapi.com/US/flat/24.png",
    language_code: "en",
    language_name: "English",
  });

  const [valAlert, setValAlert] = useState(false);
  const [loading, setLoading] = useState(false);

  const [domainviewData, setDomainViewDate] = useState([]);
  const [etv, setEtv] = useState([]);
  const [etvlabel, setEtvLabel] = useState([]);
  const [etvAllData, setEtvAllData] = useState([]);
  const [pos1to3, setPos1to3] = useState([]);
  const [pos4to10, setPos4to10] = useState([]);
  const [pos11to50, setPos11to50] = useState([]);
  const [pos51to100, setPos51to100] = useState([]);
  const [domainviewlmtget, setDomainviewlmtget] = useState([]);
  const [backlinkData, setBacklinkData] = useState([]);

  const fatch_domainviewlmtget = () => {
    axios
      .get(`https://app.rankspro.io/api/user/domainviewlmtget/?email=${email}`)
      .then((res) => {
        setDomainviewlmtget(res.data);
      });
  };

  const months = [
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "May",
    "Jun",
    "Jul",
    "Aug",
    "Sep",
    "Oct",
    "Nov",
    "Dec",
  ];

  const handleUserBacklinkData = async (config) => {
    axios.get(get_backlink_data(config)).then((response) => {
      if (response.data?.length > 0) {
        const data = response.data?.[0];
        ZustandStore.setState({
          site_overview_backlink_data: data?.data?.[0]?.history_list,
        });
        setBacklinkData(data?.data?.[0]?.history_list);
      } else {
        axios
          .post(post_backlink_data(config))
          .then((response) => {
            if (response?.data?.length === 0) {
              setBacklinkData([]);
              ZustandStore.setState({
                site_overview_backlink_data: [],
              });
            } else {
              const data = response.data?.[0];
              setBacklinkData(data?.history_list);
              ZustandStore.setState({
                site_overview_backlink_data: data?.data?.[0]?.history_list,
              });
            }
          })
          .catch(() => {
            ZustandStore.setState({
              site_overview_backlink_data: [],
            });
            setBacklinkData([]);
          });
      }
    });
  };

  const handleSiteOverviewDataUpdate = (data) => {
    setDomainViewDate(data);
    dispatch({ type: "SEOKEYWORD", payload: data });
    const etvArray = [];
    const etvLabel = [];
    const etvAll = [];
    const P1to3 = [];
    const P4to10 = [];
    const P11to50 = [];
    const P51to100 = [];

    data?.metrics &&
      data?.metrics?.map((res) => {
        etvLabel.push(months[res.month - 1] + " " + res.year);
        etvArray.push(res.metrics.organic?.etv);
        etvAll.push(res.metrics.paid?.etv);

        P1to3.push(res.metrics.organic?.pos_1 + res.metrics.organic?.pos_2_3);
        P4to10.push(res.metrics.organic?.pos_4_10);
        P11to50.push(
          res.metrics.organic?.pos_11_20 +
            res.metrics.organic?.pos_21_30 +
            res.metrics.organic?.pos_31_40 +
            res.metrics.organic?.pos_41_50
        );
        P51to100.push(
          res.metrics.organic?.pos_51_60 +
            res.metrics.organic?.pos_61_70 +
            res.metrics.organic?.pos_71_80 +
            res.metrics.organic?.pos_81_90 +
            res.metrics.organic?.pos_91_100
        );
      });

    setEtv(etvArray);
    setEtvLabel(etvLabel);
    setEtvAllData(etvAll);

    setPos1to3(P1to3);
    setPos4to10(P4to10);
    setPos11to50(P11to50);
    setPos51to100(P51to100);

    setTimeout(() => {
      setLoading(false);
    }, 500);
  };

  useEffect(() => {
    fatch_domainviewlmtget();
    const country = JSON.parse(sessionStorage.getItem("domainCountry"));
    const domainName = sessionStorage.getItem("lastdomainname");
    if (siteDomain != null) {
      setDomainName(siteDomain?.domain);
      setDomainLocation({
        location_name: siteDomain?.loc_name,
        location_code: siteDomain?.loc_code,
        location_flag: siteDomain?.loc_flag,
        language_code: siteDomain?.language_code || "en",
        language_name: siteDomain?.language_name || "English",
      });
      // addQueryParamToURL(siteDomain?.domain);
      // timeoutId = setTimeout(() => {
      //   handleSiteOverviewData(siteDomain?.domain);
      // }, 200);
    } else if (domainName != null && !dataLoaded) {
      setDomainName(domainName);
      if (country != null) {
        setDomainLocation({
          location_name: country?.location_name,
          location_code: country?.location_code,
          location_flag: country?.location_flag,
          language_code: country?.language_code,
          language_name: country?.language_name,
        });
      }
      addQueryParamToURL(domainName);
      if (
        site_overview_backlink_data?.length === 0 &&
        site_overview_page_data?.metrics?.length === 0
      ) {
        handleSiteOverviewData(domainName);
      } else {
        setBacklinkData([]);
        setLoading(true);
        setValAlert(false);
        setBacklinkData(site_overview_backlink_data);
        handleSiteOverviewDataUpdate(site_overview_page_data);
      }
    }
  }, [siteDomain, site_overview_backlink_data, site_overview_page_data]);

  const handleSiteOverviewData = () => {
    const sessionDomainName = sessionStorage.getItem("lastdomainname");
    var domainNameValue = "";
    if (sessionDomainName != null) {
      domainNameValue = sessionDomainName;
    } else {
      domainNameValue = domainName;
    }

    if (domainNameValue === "") {
      setValAlert("Please enter domain");
    } else if (!domainRegex.test(domainNameValue)) {
      setValAlert("Please Enter Valid Domain.");
      setDomainViewDate([]);
      ZustandStore.setState({
        site_overview_page_data: [],
        site_overview_backlink_data: [],
      });
      setBacklinkData([]);
    } else if (
      domainviewlmtget[0]?.limit >= domainviewlimit &&
      domainviewlmtget.length !== 0
    ) {
      setValAlert(
        "Your credits are used, please subscribe to the plan for increased limit."
      );
      setDomainViewDate([]);
      ZustandStore.setState({
        site_overview_page_data: [],
        site_overview_backlink_data: [],
      });
      setBacklinkData([]);
    } else {
      setDataLoaded(true);
      setLoading(true);
      setValAlert(false);
      setBacklinkData([]);
      const config = {
        email: email,
        domain: domainNameValue,
        include_subdomains: true,
      };
      handleUserBacklinkData(config);
      sessionStorage.setItem("lastdomainname", domainNameValue);

      const domainviewlimitHandler = () => {
        addQueryParamToURL(domainNameValue);
        if (domainviewlmtget.length === 0) {
          const json_domainviewlmt = {
            email: email,
            domainoverviewlmt: 1,
          };
          axios
            .post(
              `https://app.rankspro.io/api/user/domainviewlmt/`,
              json_domainviewlmt
            )
            .then((res) => {
              fatch_domainviewlmtget();
            });
        } else {
          const json_updatedomainviewlmt = {
            email: email,
            domainoverviewlmt: domainviewlmtget[0].limit + 1,
          };
          axios
            .put(
              `https://app.rankspro.io/api/user/domainviewlmtupdate/${domainviewlmtget[0].id}/`,
              json_updatedomainviewlmt
            )
            .then((res) => {
              fatch_domainviewlmtget();
            });
        }
      };

      const country = JSON.parse(sessionStorage.getItem("domainCountry"));
      var countryName = null;
      if (country != null) {
        countryName = country?.location_name;
      } else {
        countryName = domainLocation?.location_name;
      }
      var domainLanuage = null;
      if (country != null) {
        domainLanuage = country?.language_name;
      } else {
        domainLanuage = domainLocation?.language_name;
      }

      const json_getDomain = {
        domainName: domainNameValue,
        domainCountry: countryName,
        domainLanuage: domainLanuage,
      };

      dispatch({
        type: "SITEDOMAIN",
        payload: null,
      });

      axios(DOMAINOVERVIEW_GET(json_getDomain)).then((res) => {
        if (res.data.length !== 0) {
          const data = res.data[0];
          domainviewlimitHandler();
          ZustandStore.setState({
            site_overview_page_data: data,
          });
          handleSiteOverviewDataUpdate(data);
        } else {
          domainviewlimitHandler();
          const today = new Date();
          var dd = today.getDate();
          var monthNames = [
            "Jan",
            "Feb",
            "Mar",
            "Apr",
            "May",
            "Jun",
            "Jul",
            "Aug",
            "Sep",
            "Oct",
            "Nov",
            "Dec",
          ];
          var yyyy = today.getFullYear();
          if (dd < 10) dd = "0" + dd;
          let aobj = {
            backlink: 0,
            keyword: [],
            metrics: [],
            Month: monthNames[today.getMonth() + 1],
            Year: yyyy,
            last_updated: dd,
          };

          const backlinkPromise = axios.get(
            `https://app.rankspro.io/api/user/backlink/?domain_name=${domainNameValue}&location_code=${domainLocation?.location_code}&language_code=en&location_name=${countryName}&language_name=${domainLanuage}`
          );

          const keywordPromise = axios.get(
            `https://app.rankspro.io/api/user/domainkeyword/?domain_name=${domainNameValue}&location_code=${domainLocation?.location_code}&language_code=en&location_name=${countryName}&language_name=${domainLanuage}`
          );
          const metricsPromise = axios.get(
            `https://app.rankspro.io/api/user/domainoverview/?domain_name=${domainNameValue}&location_code=${domainLocation?.location_code}&language_code=en&location_name=${countryName}&language_name=${domainLanuage}`
          );

          Promise.all([backlinkPromise, keywordPromise, metricsPromise]).then(
            (data) => {
              const first = data[0];
              const second = data[1];
              const third = data[2];
              aobj.backlink = first.data[0]?.backlink;
              aobj.keyword = second.data;
              aobj.metrics = third.data;

              if (first?.data?.length === 0 && second.data?.length === 0) {
                setDomainViewDate([]);
                ZustandStore.setState({
                  site_overview_page_data: [],
                  site_overview_backlink_data: [],
                });
                setValAlert(
                  "Sorry, No Data Found For This Domain. Please Check The Domain."
                );
              } else {
                setValAlert("");
                ZustandStore.setState({
                  site_overview_page_data: aobj,
                });
                handleSiteOverviewDataUpdate(aobj);
              }
            }
          );
        }
      });
    }
  };

  const fatch_DOMAINOVERVIEW_GET = (e) => {
    e.preventDefault();
    if (domainName === "") {
      setValAlert("Please enter domain");
      return;
    }
    sessionStorage.setItem("domainCountry", JSON.stringify(domainLocation));
    handleSiteOverviewData();
  };

  const addQueryParamToURL = (name) => {
    const sessionDomainName = sessionStorage.getItem("lastdomainname");
    var domainNameValue = null;
    if (sessionDomainName != null) {
      domainNameValue = sessionDomainName;
    } else {
      domainNameValue = domainName;
    }

    const country = JSON.parse(sessionStorage.getItem("domainCountry"));
    var countryName = null;
    if (country != null) {
      countryName = country?.location_name;
    } else {
      countryName = domainLocation?.location_name;
    }

    if (domainNameValue) {
      queryParams.set("domain", domainNameValue);
      queryParams.set("country", countryName);
    } else {
      queryParams.delete("domain");
      queryParams.delete("country");
    }
    const newSearch = queryParams.toString();
    window.history.pushState({}, "", `?${newSearch}`);
  };

  const countryDetailHandler = (e) => {
    // sessionStorage.setItem("domainCountry", JSON.stringify(e));
    setDomainLocation({
      location_name: e?.location_name,
      location_code: e?.location_code,
      location_flag: e?.flag,
      language_code: e?.language_code,
      language_name: e?.language_name,
    });
  };

  function getResultColorAndCount(value1, value2) {
    const result = value1 - value2;
    return {
      increase: result < 0 ? true : false,
      number: formatNumberWithSymbols(Math.abs(result)),
    };
  }

  const checkPos1to3 = removeUndefinedAndNaN(pos1to3);
  const checkPos4to10 = removeUndefinedAndNaN(pos4to10);
  const checkPos11to50 = removeUndefinedAndNaN(pos11to50);
  const checkPos51to100 = removeUndefinedAndNaN(pos51to100);
  const checkEtvAllData = removeUndefinedAndNaN(etvAllData);
  const checkEtv = removeUndefinedAndNaN(etv);

  const handleTabChange = () => {
    ZustandStore.setState({
      tag_keywords: [],
      filter_tag: [],
      keyword_tag_data: {
        loaded: false,
        error: false,
        data: [],
      },
    });
    
    navigate("seo-keyword");
  };

  const backlink_histort = useMemo(() => {
    return backlinkData?.slice(-3) || [];
  }, [backlinkData]);



// for printing the pdf logic



const targetOverView = useRef()

const handleRoute =()=>{
  const element = document.getElementById("overveiw");

  if (element) {
    html2canvas(element, {
      useCORS: true, // If your container has external images, this helps to prevent cross-origin issues.
      backgroundColor: null, // Set to null if you want a transparent background.
    }).then((canvas) => {
      // Convert the canvas to a data URL in JPG format
      const imgData = canvas.toDataURL("image/jpeg");

      // Save the image data to sessionStorage
      sessionStorage.setItem("dashboardImage", imgData);
    }).catch((error) => {
      console.error("Error capturing the dashboard as an image:", error);
    });
  }

  sessionStorage.setItem('pdfSessionData',JSON.stringify({domainName:domainName,id:11}))
  setTimeout(() => {
    navigate("/CrawledPageEditor");
  }, 500)
   
  }




const GenerateOverviewPDF = () => {
  const input = targetOverView.current;
  const pdf = new jsPDF('p', 'mm', 'a4');
  const margin = 10; // Set the margin size
  const pageWidth = pdf.internal.pageSize.getWidth();
  const pageHeight = pdf.internal.pageSize.getHeight();

  // Add cover page
  pdf.setFontSize(20);
  pdf.setFont('helvetica');
  pdf.text('Site Overview', pageWidth / 2, pageHeight / 3 + 60, {
    align: 'center',
  });

  pdf.setFontSize(16);
  pdf.setFont('helvetica', 'normal');
  pdf.setTextColor("gray")
  pdf.text(`Domain: ${domainName}`, pageWidth / 2, pageHeight / 3 + 70, {
    align: 'center',
  });

  // Add your image to the cover page
  const imageURL = RanksProLogo; // Replace with your image URL or data URI
  const imageWidth = 70; // Adjust width as needed
  const imageHeight = 32; // Adjust height as needed
  pdf.addImage(
    imageURL,
    'PNG',
    (pageWidth - imageWidth) / 2,
    pageHeight / 3 - imageHeight + 60-5,
    imageWidth,
    imageHeight
  );

  pdf.addPage(); // Add a new page for the actual content

  // Capture and add the main content
  html2canvas(input, { scale: 2 }).then((canvas) => {
    const imgData = canvas.toDataURL('image/png');
    const imgWidth = pageWidth - 2 * margin;
    const imgHeight = (canvas.height * imgWidth) / canvas.width;

    let position = margin;

    // Add logo to the top-left corner of the second page
    pdf.addImage(RanksProLogo, 'PNG', margin, margin, 40, 17); // Adjust size as needed

    pdf.addImage(imgData, 'PNG', margin, position + 20, imgWidth, imgHeight);

    // Add additional pages if content exceeds one page
    while (position + imgHeight > pageHeight - margin) {
      position -= pageHeight - 2 * margin;
      pdf.addPage();
      pdf.addImage(RanksProLogo, 'PNG', margin, margin, 30, 20); // Add logo on each new page
      pdf.addImage(imgData, 'PNG', margin, position + 20, imgWidth, imgHeight);
    }

    pdf.save('SiteOverview.pdf');
  });
};





  return (
    <main className="main-pd">
      <div className="w-100">
        <div className="keyword2 rounded-5 pb-0">
          <div className="w-100">
            <h5>Competitive Research</h5>
            <p className="keyword-p">Get a detailed SEO overview of a domain</p>
            <form
              onSubmit={(e) => {
                fatch_DOMAINOVERVIEW_GET(e);
              }}
            >
              <div className="overview-search-contain d-flex justify-content-between">
                <div className="d-flex align-items-center justify-content-start flex-wrap gap-2">
                <input
                  className="keyword-input"
                  placeholder="Enter a domain"
                  value={domainName}
                  onChange={(e) => {
                    sessionStorage.removeItem("lastdomainname");
                    const url = e.target.value;
                    const match = url.match(
                      /^(?:https?:\/\/)?(?:www\.)?([^/]+)/i
                    );
                    setDomainName(match ? match[1].replace(/\s/g, "") : "");
                    setValAlert(false);
                  }}
                />

                <CountryBasedOnLanuage
                  data={country_Language_Data}
                  img={domainLocation?.location_flag}
                  title={`${domainLocation?.language_name} / ${domainLocation?.location_name}`}
                  style={{
                    border: "1px solid rgba(0, 0, 0, 0.3)",
                    textTransform: "none",
                    boxShadow: "none",
                    padding: "0.65rem",
                    minWidth: "300px",
                  }}
                  callback={countryDetailHandler}
                />

                <button
                  className="icon-btn"
                  onClick={(e) => fatch_DOMAINOVERVIEW_GET(e)}
                >
                  <img src={searchArrow} alt="" />
                </button>
                </div>
                {domainviewData.length !== 0 ? 
                <div className="d-flex justify-content-between align-item-center">

                <FiDownload  className="down" onClick={handleRoute} title="download SiteOverview PDF" />
                </div>
                :null }
              </div>
            </form>
            <p className="heading-medium font-bold vl-msd-line mt-3 ms-1">
              <span>{valAlert}</span>{" "}
            </p>
          </div>
        </div>

        {domainviewData?.Month &&
        domainviewData?.keyword.length > 0 &&
        loading ? (
          <DottLoader />
        ) : domainviewData.length !== 0 ? (
          <>
            {checkEtv?.length === 0 &&
            checkEtvAllData?.length === 0 &&
            checkPos1to3?.length === 0 &&
            checkPos4to10?.length === 0 &&
            checkPos11to50?.length === 0 &&
            checkPos51to100?.length === 0 ? (
              <p className="heading-medium font-bold vl-msd-line ms-1">
                No Data!
              </p>
            ) : (
              <div ref={targetOverView} style={{backgroundColor:"#fffbf5"}} id="overveiw">
                <div className="row gy-3">
                  <div className="col-12 col-lg-6 col-xl-4">
                    {checkEtv?.length !== 0 && backlinkData?.length !== 0 ? (
                      <div className="row" style={{ gap: "25px" }}>
                        {/* Organic Keywords Card */}
                        <div className="col-12">
                          <div className="cir-card-wth-rank">
                            <h1>Total Organic Keywords</h1>
                            <div className="cont-card">
                              {[2, 1, 0].map((index) => (
                                <React.Fragment key={index}>
                                  <div className="circle">
                                    <span className="cir-mon">
                                      {getMonthName(
                                        domainviewData?.metrics?.[index]?.month
                                      )}
                                    </span>
                                    <span className="cir-val">
                                      {formatNumberWithSymbols(
                                        domainviewData?.metrics?.[index]
                                          ?.metrics?.organic?.count
                                      )}
                                    </span>
                                  </div>
                                  {index > 0 && (
                                    <div className="cal-cont">
                                      <span
                                        className="cal-val px-2 py-1"
                                        style={{
                                          backgroundColor:
                                            getResultColorAndCount(
                                              Math.round(
                                                domainviewData?.metrics?.[index]
                                                  ?.metrics?.organic?.count
                                              ),
                                              Math.round(
                                                domainviewData?.metrics?.[
                                                  index - 1
                                                ]?.metrics?.organic?.count
                                              )
                                            ).increase
                                              ? "#B3FECC"
                                              : "#F42C2C4D",
                                          color: getResultColorAndCount(
                                            Math.round(
                                              domainviewData?.metrics?.[index]
                                                ?.metrics?.organic?.count
                                            ),
                                            Math.round(
                                              domainviewData?.metrics?.[
                                                index - 1
                                              ]?.metrics?.organic?.count
                                            )
                                          ).increase
                                            ? "#238654"
                                            : "#F42C2C",
                                        }}
                                      >
                                        {
                                          getResultColorAndCount(
                                            Math.round(
                                              domainviewData?.metrics?.[index]
                                                ?.metrics?.organic?.count
                                            ),
                                            Math.round(
                                              domainviewData?.metrics?.[
                                                index - 1
                                              ]?.metrics?.organic?.count
                                            )
                                          ).number
                                        }
                                        {getResultColorAndCount(
                                          Math.round(
                                            domainviewData?.metrics?.[index]
                                              ?.metrics?.organic?.count
                                          ),
                                          Math.round(
                                            domainviewData?.metrics?.[index - 1]
                                              ?.metrics?.organic?.count
                                          )
                                        ).increase ? (
                                          <IoArrowUpSharp />
                                        ) : (
                                          <IoArrowDownSharp />
                                        )}
                                      </span>
                                      <span className="mt-1">
                                        <RightArrowIcon
                                          style={{
                                            stroke: getResultColorAndCount(
                                              Math.round(
                                                domainviewData?.metrics?.[index]
                                                  ?.metrics?.organic?.count
                                              ),
                                              Math.round(
                                                domainviewData?.metrics?.[
                                                  index - 1
                                                ]?.metrics?.organic?.count
                                              )
                                            ).increase
                                              ? "#238654"
                                              : "#F42C2C",
                                          }}
                                        />
                                      </span>
                                    </div>
                                  )}
                                </React.Fragment>
                              ))}
                            </div>
                          </div>
                        </div>

                        {/* Organic Monthly Traffic Card */}
                        <div className="col-12">
                          <div className="cir-card-wth-rank">
                            <h1>Organic Monthly Traffic</h1>
                            <div className="cont-card">
                              {[2, 1, 0].map((index) => (
                                <React.Fragment key={index}>
                                  <div className="circle">
                                    <span className="cir-mon">
                                      {getMonthName(
                                        domainviewData?.metrics?.[index]?.month
                                      )}
                                    </span>
                                    <span className="cir-val">
                                      {formatNumberWithSymbols(
                                        Math.round(
                                          domainviewData?.metrics?.[index]
                                            ?.metrics?.organic?.etv
                                        )
                                      )}
                                    </span>
                                  </div>
                                  {index > 0 && (
                                    <div className="cal-cont">
                                      <span
                                        className="cal-val px-2 py-1"
                                        style={{
                                          backgroundColor:
                                            getResultColorAndCount(
                                              Math.round(
                                                domainviewData?.metrics?.[index]
                                                  ?.metrics?.organic?.etv
                                              ),
                                              Math.round(
                                                domainviewData?.metrics?.[
                                                  index - 1
                                                ]?.metrics?.organic?.etv
                                              )
                                            ).increase
                                              ? "#B3FECC"
                                              : "#F42C2C4D",
                                          color: getResultColorAndCount(
                                            Math.round(
                                              domainviewData?.metrics?.[index]
                                                ?.metrics?.organic?.etv
                                            ),
                                            Math.round(
                                              domainviewData?.metrics?.[
                                                index - 1
                                              ]?.metrics?.organic?.etv
                                            )
                                          ).increase
                                            ? "#238654"
                                            : "#F42C2C",
                                        }}
                                      >
                                        {
                                          getResultColorAndCount(
                                            Math.round(
                                              domainviewData?.metrics?.[index]
                                                ?.metrics?.organic?.etv
                                            ),
                                            Math.round(
                                              domainviewData?.metrics?.[
                                                index - 1
                                              ]?.metrics?.organic?.etv
                                            )
                                          ).number
                                        }
                                        {getResultColorAndCount(
                                          Math.round(
                                            domainviewData?.metrics?.[index]
                                              ?.metrics?.organic?.etv
                                          ),
                                          Math.round(
                                            domainviewData?.metrics?.[index - 1]
                                              ?.metrics?.organic?.etv
                                          )
                                        ).increase ? (
                                          <IoArrowUpSharp />
                                        ) : (
                                          <IoArrowDownSharp />
                                        )}
                                      </span>
                                      <span className="mt-1">
                                        <RightArrowIcon
                                          style={{
                                            stroke: getResultColorAndCount(
                                              Math.round(
                                                domainviewData?.metrics?.[index]
                                                  ?.metrics?.organic?.etv
                                              ),
                                              Math.round(
                                                domainviewData?.metrics?.[
                                                  index - 1
                                                ]?.metrics?.organic?.etv
                                              )
                                            ).increase
                                              ? "#238654"
                                              : "#F42C2C",
                                          }}
                                        />
                                      </span>
                                    </div>
                                  )}
                                </React.Fragment>
                              ))}
                            </div>
                          </div>
                        </div>

                        {/* Backlinks Card */}
                        <div className="col-12">
                          <div className="cir-card-wth-rank">
                            <h1>Backlinks</h1>
                            <div className="cont-card">
                              {backlink_histort?.map((data, index, arr) => (
                                <React.Fragment key={index}>
                                  <div className="circle">
                                    <span className="cir-mon">
                                      {data?.month?.slice(0, 3)}
                                    </span>
                                    <span className="cir-val">
                                      {formatNumberWithSymbols(data?.backlinks)}
                                    </span>
                                  </div>
                                  {index < arr.length - 1 && (
                                    <div className="cal-cont">
                                      <span
                                        className="cal-val px-2 py-1"
                                        style={{
                                          backgroundColor:
                                            getResultColorAndCount(
                                              Math.round(data?.backlinks),
                                              Math.round(
                                                arr[index + 1]?.backlinks
                                              )
                                            ).increase
                                              ? "#B3FECC"
                                              : "#F42C2C4D",
                                          color: getResultColorAndCount(
                                            Math.round(data?.backlinks),
                                            Math.round(
                                              arr[index + 1]?.backlinks
                                            )
                                          ).increase
                                            ? "#238654"
                                            : "#F42C2C",
                                        }}
                                      >
                                        {
                                          getResultColorAndCount(
                                            Math.round(data?.backlinks),
                                            Math.round(
                                              arr[index + 1]?.backlinks
                                            )
                                          ).number
                                        }
                                        {getResultColorAndCount(
                                          Math.round(data?.backlinks),
                                          Math.round(arr[index + 1]?.backlinks)
                                        ).increase ? (
                                          <IoArrowUpSharp />
                                        ) : (
                                          <IoArrowDownSharp />
                                        )}
                                      </span>
                                      <span className="mt-1">
                                        <RightArrowIcon
                                          style={{
                                            stroke: getResultColorAndCount(
                                              Math.round(data?.backlinks),
                                              Math.round(
                                                arr[index + 1]?.backlinks
                                              )
                                            ).increase
                                              ? "#238654"
                                              : "#F42C2C",
                                          }}
                                        />
                                      </span>
                                    </div>
                                  )}
                                </React.Fragment>
                              ))}
                            </div>
                          </div>
                        </div>
                      </div>
                    ) : (
                      <div className="h-100">
                        <Spinner />
                      </div>
                    )}
                  </div>
                  <div className="col-12 col-lg-6 col-xl-8">
                    {etvAllData?.length === 0 ||
                    checkEtvAllData?.length === 0 ? null : (
                      <div className="overview-chart-card mt-0 h-100">
                        <div className="w-100">
                          <div className="cmd mb-2">
                            <h6 className="chart-header">Total Traffic</h6>
                            <div>
                              <div className="d-flex chart-type">
                                <div
                                  className="m-0 rankLebelOver"
                                  style={{ top: "0px" }}
                                >
                                  <span
                                    style={{ background: "#3BA1C5" }}
                                  ></span>{" "}
                                  Organic
                                </div>
                                <div
                                  className="rankLebelOver"
                                  style={{
                                    marginLeft: "10px",
                                  }}
                                >
                                  <span
                                    style={{
                                      background: "#E95655",
                                    }}
                                  ></span>{" "}
                                  Paid{" "}
                                </div>
                              </div>
                            </div>
                          </div>
                          <TrafficChart
                            etv={etv}
                            websiteurl={domainName}
                            etvlabel={etvlabel}
                            etvAllData={etvAllData}
                          />
                        </div>
                      </div>
                    )}
                  </div>
                </div>
                <div className="mt-md-5 mt-3">
                  <div className="row gy-3">
                    <div className="col-12 col-lg-6">
                      {domainviewData?.keyword?.length === 0 ? null : (
                        <div
                          className="overview-table"
                          style={{ overflow: "hidden" }}
                        >
                          <table className="">
                            <thead>
                              <tr className="">
                                <th scope="col" className="ps-3 p-2">
                                  Keywords
                                </th>
                                <th
                                  scope="col"
                                  className="p-2 text-center"
                                  style={{ width: "120px" }}
                                >
                                  Volume
                                </th>
                                <th
                                  scope="col"
                                  className="p-2 text-center"
                                  style={{ width: "120px" }}
                                >
                                  Position
                                </th>
                              </tr>
                            </thead>
                            <tbody>
                              {domainviewData?.keyword &&
                                domainviewData?.keyword
                                  ?.slice(0, 5)
                                  ?.map((res, index) => {
                                    return (
                                      <React.Fragment
                                        key={`domianviewdata-${index}`}
                                      >
                                        <tr key={`${index}-${res.rank_group}`}>
                                          <td className="t_left ps-3 keyword-name">
                                            {res.keyword}
                                          </td>
                                          <td className="text-center">
                                            {res.search_volume}
                                          </td>
                                          <td className="text-center">
                                            {res.rank_group}
                                          </td>
                                        </tr>
                                      </React.Fragment>
                                    );
                                  })}
                            </tbody>
                          </table>
                          {domainviewData?.keyword?.length !== 0 &&
                            domainviewData?.length !== 0 && (
                              <div
                                className="see-more-link"
                                style={{ textDecoration: "auto" }}
                              >
                                <button
                                  className={`btn-sm float-right`}
                                  style={{ fontSize: "1em !important" }}
                                  onClick={() => handleTabChange()}
                                >
                                  View All
                                </button>
                              </div>
                            )}
                        </div>
                      )}
                    </div>
                    <div className="col-12 col-lg-6">
                      {checkPos1to3?.length === 0 &&
                      checkPos4to10?.length === 0 &&
                      checkPos11to50?.length === 0 &&
                      checkPos51to100?.length === 0 ? null : (
                        <div
                          className="cmd-b overview-chart-card mt-0"
                          style={{
                            padding: "1rem",
                            backgroundColor: "#F3F1F166",
                          }}
                        >
                          <div className="w-100">
                            <div className="BarMultiLine">
                              <h6 className="mb-3">
                                SEO Keywords Distribution
                              </h6>
                              <div className="RankOverBar">
                                <div
                                  className="rankLebelOverBar m-0"
                                  style={{ top: "0px" }}
                                >
                                  <span
                                    style={{ background: "#F3A344" }}
                                  ></span>{" "}
                                  POS 1-3
                                </div>
                                <div
                                  className="rankLebelOverBar"
                                  style={{
                                    top: "0px",
                                  }}
                                >
                                  <span
                                    style={{
                                      background: "#3BA1C5",
                                    }}
                                  ></span>{" "}
                                  POS 4-10{" "}
                                </div>
                                <div
                                  className="rankLebelOverBar"
                                  style={{
                                    top: "0px",
                                  }}
                                >
                                  <span
                                    style={{
                                      background: "#365486",
                                    }}
                                  ></span>{" "}
                                  POS 11-50
                                </div>
                                <div
                                  className="rankLebelOverBar"
                                  style={{
                                    top: "0px",
                                  }}
                                >
                                  <span
                                    style={{
                                      background: "#E95655",
                                    }}
                                  ></span>{" "}
                                  POS 51-100
                                </div>
                              </div>
                            </div>
                            <div
                              style={{ overflow: "auto" }}
                              className="app-scrollbar"
                            >
                              <div className="barMulti">
                                <StackedBarChartMulti
                                  pos1to3={pos1to3}
                                  pos4to10={pos4to10}
                                  pos11to50={pos11to50}
                                  pos51to100={pos51to100}
                                  etvlabel={etvlabel}
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            )}
          </>
        ) : null}
      </div>
    </main>
  );
};

export default DomainOverview;
