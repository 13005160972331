import axios from "axios";
import React, { useEffect, useState, useMemo } from "react";
import {
  ADMIN_USERS,
  Admin_Keyword_Get,
  get_Plans_Details,
} from "../../services/constants";
import { Doughnut } from "react-chartjs-2";
import NorthEastIcon from "@mui/icons-material/NorthEast";
import { useNavigate } from "react-router-dom";
import country from "../../Mycomponent/dataFiles/countryList";
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from "chart.js";
import { Box } from "@mui/material";
import keywordImg from "../assets/keywords.png";
import userImg from "../assets/users.png";
import activeImg from "../assets/active.png";
import keywordChartImg from "../assets/keywords (2).png";
import useMediaQuery from "@mui/material/useMediaQuery";
import Loader from "../share/loader";

ChartJS.register(ArcElement, Tooltip, Legend);

const DoughnutCharttwo = ({ value }) => {
  const filteredChartValue = value?.filter((item) => item.value !== 0);
  const values = filteredChartValue.map((item) => item.value);
  const colors = filteredChartValue.map((item) => item.color);
  const names = filteredChartValue.map((item) => item.name);

  const data = {
    labels: names,
    datasets: [
      {
        label: "",
        data: values,
        backgroundColor: colors,
        hoverBackgroundColor: colors,
        borderColor: colors,
        borderWidth: 0,
      },
    ],
  };

  const options = {
    // maintainAspectRatio: false,
    // responsive: true,
    // interaction: { mode: "index", intersect: false },
    plugins: {
      legend: {
        display: false,
        position: "right",
      },
      tooltip: {
        boxPadding: 5,
        cornerRadius: 10,
        caretSize: 10,
        padding: 10,
        margin: 10,
        boxHeight: 30,
        borderWidth: 4,
        boxWidth: 4,
        titleColor: "black",
        bodyColor: "black",
        bodyFont: {
          size: 16,
        },
        bodySpacing: 10,
        usePointStyle: true,
        backgroundColor: "#F0F0F0",
        titleFont: {
          weight: "bold",
          size: 15,
        },
      },
    },
  };

  return (
    <div className="dough-one" style={{ height: "200px", maxWidth: "200px" }}>
      <Doughnut data={data} options={options} />
    </div>
  );
};

const Card = ({ title, value, Src, action }) => {
  return (
    <div
      className="cmd-b admin-card text-start fst-normal"
      onClick={action}
      style={{
        minHeight: "0",
        minWidth: "320px",
        maxHeight: "136px",
        cursor: "pointer",
      }}
    >
      <div className="w-100">
        <div className="cmd">
          <div>
            <h6 className="mb-0 text-uppercase heading-medium font-bold mb-4">
              {title}
            </h6>
            <h1 className="text-dark mb-0 font-bold">{value}</h1>
          </div>
          <div className="d-flex align-items-center justify-content-center">
            <img
              src={Src}
              alt={title}
              className="blacklink-card-value-img object-fit-contain"
            />
          </div>
        </div>
      </div>
    </div>
  );
};

const Dashboard = () => {
  const [users, setUsers] = useState(0);
  const [keywords, setKeywords] = useState([]);
  const [allUser, setAllUser] = useState([]);
  const [planDetails, setPlanDetails] = useState(0);
  const nav = useNavigate();
  const matches = useMediaQuery("(min-width:480px)");

  useEffect(() => {
    axios.get(ADMIN_USERS()).then((res) => {
      const data = res.data;
      setUsers(data);
    });

    axios
      .get("https://app.rankspro.io/api/user/showuserdetails/")
      .then((response) => {
        setAllUser(response.data);
      });

    axios.get(get_Plans_Details()).then((res) => {
      setPlanDetails(res.data.data);
    });

    axios.get(Admin_Keyword_Get()).then((res) => {
      const data = res.data;
      setKeywords(data);
    });
  }, []);

  const filterKeywordBasedOnCountry = (array) => {
    const arr2 = [];
    array?.forEach((item) => {
      const { location_code, device, keyword } = item;
      let matchingObject = arr2.find(
        (obj) => obj.location_code === location_code
      );
      if (!matchingObject) {
        matchingObject = {
          location_code,
          mobile: [],
          desktop: [],
          location_flag: "",
          location_name: "",
        };
        arr2.push(matchingObject);
        const locationInfo = country.find(
          (info) => info.location_code == location_code
        );
        if (locationInfo) {
          matchingObject.location_flag = locationInfo.location_flag;
          matchingObject.location_name = locationInfo.location_name;
        }
      }
      if (device === "mobile") {
        matchingObject.mobile.push(keyword);
      } else if (device === "desktop") {
        matchingObject.desktop.push(keyword);
      }
    });
    const filteredArray = arr2.filter((item) => item.location_code !== "");
    filteredArray.sort(
      (a, b) =>
        b.mobile.length +
        b.desktop.length -
        (a.mobile.length + a.desktop.length)
    );

    return filteredArray;
  };

  const filterKeywordArray = useMemo(() => {
    if (keywords?.length === 0) return [];
    const array = filterKeywordBasedOnCountry(keywords);
    return array;
  }, [keywords]);

  const userValue = {
    total_user: users?.length,
    subscribe_user: allUser?.subscribed_user,
    refreal_user: allUser?.refferal_user,
    free_user:
      users?.length - (allUser?.subscribed_user + allUser?.refferal_user),
  };

  const chart_value = [
    {
      name: "Subscribe Users",
      value: userValue?.subscribe_user,
      color: "rgb(35, 124, 255)",
    },
    {
      name: "referral User",
      value: userValue?.refreal_user,
      color: "#F4A21F",
    },
    {
      name: "Free User",
      value: userValue?.free_user,
      color: "#7479E4",
    },
  ];

  return (
    <div>
      <div>
        <h1 className="table_title mb-4 heading-large font-bold">Dashboard</h1>
        <div className="">
          <div className="row gy-3">
            <div className="col-12 col-lg-6">
              <div className="cmd-b admin-card mt-0 h-100">
                <div
                  className="d-flex w-100 justify-content-between h-100"
                  style={{ maxWidth: "700px" }}
                >
                  {allUser?.length === 0 ? (
                    <Loader />
                  ) : (
                    <>
                      <div className={matches ? "w-50" : "d-none w-50"}>
                        <h1 className="heading-large font-bold">Users</h1>
                        <DoughnutCharttwo value={chart_value} />
                      </div>
                      <div
                        className={`h-100 mb-auto d-flex flex-column justify-content-center ${
                          matches ? "w-50" : "w-100"
                        }`}
                      >
                        <ul className="h-75">
                          <li
                            className="d-flex align-items-center text-nowrap heading-small font-bold"
                            style={{
                              minHeight: "30px",
                            }}
                          >
                            {" "}
                            <span
                              className="plan-box"
                              style={{ backgroundColor: "#49C354" }}
                            ></span>
                            Total Users
                            <span className="heading-small font-regular ms-auto ">
                              {userValue?.total_user || 0}
                            </span>
                          </li>
                          <li
                            className="d-flex align-items-center text-nowrap heading-small font-bold"
                            style={{
                              minHeight: "30px",
                            }}
                          >
                            {" "}
                            <span
                              className="plan-box"
                              style={{ backgroundColor: "rgb(35, 124, 255)" }}
                            ></span>
                            Subscribed Users
                            <span className="heading-small font-regular ms-auto ">
                              {userValue?.subscribe_user || 0}
                            </span>
                          </li>
                          <li
                            className="d-flex align-items-center text-nowrap heading-small font-bold"
                            style={{
                              minHeight: "30px",
                            }}
                          >
                            <span
                              className="plan-box"
                              style={{ backgroundColor: "#F4A21F" }}
                            ></span>
                            referral Users
                            <span className="heading-small font-regular ms-auto">
                              {userValue?.refreal_user || 0}
                            </span>
                          </li>
                          <li
                            className="d-flex align-items-center text-nowrap heading-small font-bold"
                            style={{
                              minHeight: "30px",
                            }}
                          >
                            <span
                              className="plan-box"
                              style={{ backgroundColor: "#7479E4" }}
                            ></span>
                            Free Users
                            <span className="heading-small font-regular ms-auto">
                              {users?.length -
                                (userValue?.refreal_user +
                                  userValue?.subscribe_user) || 0}
                            </span>
                          </li>
                        </ul>
                        <div className="h-25 d-flex align-items-end justify-content-end">
                          <button
                            className="view-more-admin px-3 py-1"
                            onClick={() => nav("/admin/users")}
                            style={{
                              marginTop: "auto",
                              marginLeft: "auto",
                            }}
                          >
                            View
                            <NorthEastIcon
                              fontSize="small"
                              sx={{ color: "#24948ccc" }}
                            />
                          </button>
                        </div>
                      </div>
                    </>
                  )}
                </div>
              </div>
            </div>
            <div className="col-12 col-lg-6">
              <div
                className="cmd-b admin-card mt-0 h-100"
                style={{ alignItems: "flex-start" }}
              >
                <div
                  className={
                    matches
                      ? "d-flex w-100 justify-content-between h-100"
                      : "d-flex flex-column w-100 justify-content-between h-100"
                  }
                  style={{ maxWidth: "700px" }}
                >
                  <div
                    className={
                      matches
                        ? "d-flex flex-column justify-content-between"
                        : "d-flex justify-content-between"
                    }
                  >
                    <h1 className="heading-large font-bold">Keywords</h1>
                    <h4 className="heading-large font-bold">
                      {keywords?.length}
                    </h4>
                    <img
                      src={keywordChartImg}
                      alt="Keywords-chart-icons"
                      className={matches ? "" : "d-none"}
                    />
                  </div>
                  <div className={matches ? "vertical ms-2" : "d-none"}></div>
                  <div
                    className={
                      matches ? "ms-4 d-flex flex-column" : "d-flex flex-column"
                    }
                    style={{ width: matches ? "60%" : "100%" }}
                  >
                    <ul>
                      {filterKeywordArray?.slice(0, 5)?.map((country) => {
                        return (
                          <li
                            key={country?.location_code}
                            className="d-flex align-items-center"
                            style={{
                              minHeight: "30px",
                            }}
                          >
                            <img
                              src={country?.location_flag}
                              alt={country?.location_name}
                              className="me-2"
                            />
                            <span className="heading-small font-bold">
                              {country?.location_name}
                            </span>

                            <span className="heading-small font-regular ms-auto">
                              {country?.mobile?.length +
                                country?.desktop?.length || 0}
                            </span>
                          </li>
                        );
                      })}
                    </ul>
                    <button
                      className="view-more-admin px-3 py-1"
                      style={{
                        marginLeft: "auto",
                        marginTop: "auto",
                      }}
                      onClick={() => nav("/admin/adminkeywords")}
                    >
                      View
                      <NorthEastIcon
                        fontSize="small"
                        sx={{ color: "#24948ccc" }}
                      />
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="row mt-3">
            <div className="col-12 col-md-6 col-lg-auto col-xl-4">
              <Card
                action={() => nav("/admin/users")}
                value={userValue?.subscribe_user || 0}
                title="Subscribed Users"
                Src={userImg}
              />
            </div>
            <div className="col-12 col-md-6 col-lg-auto col-xl-4">
              <Card
                action={() => nav("/admin/plans")}
                value={planDetails?.length}
                title="Active Plans"
                Src={activeImg}
              />
            </div>
            <div className="col-12 col-md-6 col-lg-auto col-xl-4">
              <Card
                title="Total Amount Spent Today"
                value={"$" + `${(keywords?.length * 0.0006 + 0.55).toFixed(2)}`}
                Src={keywordImg}
              />
            </div>
          </div>
        </div>
        {/* <div className='hm-total-keyword d-flex '>
          <div className='col-md-7 hm-an-con d-flex flex-column'>
            <h3 style={{ fontStyle: "italic" , fontWeight :500 }}>
               Analyze your competitors SEO and get new opportinities to increase SEO Score
              Total Number of Amount For keywords
            </h3>
          </div>
          <div className='ap-d-total'>
            <p>Amount - ${(keywords * 0.0006).toFixed(2)}</p>
          </div>
          <div className='col-md-5 hm-ana-img'>
            <img src={total} alt="analyze img" />
          </div>
        </div> */}
      </div>
    </div>
  );
};

export default Dashboard;
