import React, { useState, useEffect } from "react";
import RESET from "../../assets/images/Login/reset.png";
import LOGO from "../../assets/images/Global/logo.png";
import { Link, useNavigate } from "react-router-dom";
import { MdOutlineKeyboardBackspace } from "react-icons/md";
import PASS from "../../assets/images/Login/lock.png";
import MAIL from "../../assets/images/Login/sms.png";
import axios from "axios";
import { POST_NEW_PASS, POST_OTP_FORGOT } from "../../utils/constant";
import { Store } from "../../utils/STORE";
import { ToastContainer, toast } from "react-toastify";
export const Forget = () => {
  const { ForgotOTP } = Store((state) => state);
  const nav = useNavigate();
  const [Show, setShow] = useState("email");
  const [Error, setError] = useState("");
  const [SendData, setSendData] = useState({
    email: "",
  });
  const [OTP, setOTP] = useState(null);
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [passwordsMatch, setPasswordsMatch] = useState(true);
  const [validPasswordLength, setValidPasswordLength] = useState(true);
  const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

  const [Count, setCount] = useState(0);
  const [isDisable, setisDisable] = useState(false);
  const [CountValid, setCountValid] = useState(30);

  useEffect(() => {
    let interval;
    if (CountValid > 0) {
      interval = setInterval(() => {
        setCountValid((prevTimer) => prevTimer - 1);
      }, 1000);
    }
    return () => clearInterval(interval);
  }, [CountValid]);

  useEffect(() => {
    let interval;
    if (Count > 0) {
      interval = setInterval(() => {
        setCount((prevTimer) => prevTimer - 1);
      }, 1000);
    } else {
      setisDisable(false);
    }
    return () => clearInterval(interval);
  }, [Count]);

  const HandleOTP = () => {
    if (SendData?.email && emailRegex.test(SendData?.email)) {
      setError("");
      axios.post(POST_OTP_FORGOT(SendData?.email)).then(({ data }) => {
        if (data?.OTP) {
          Store.setState({ ForgotOTP: data?.OTP });
          setError("Email send successfully");
          setShow("OTP");
        } else {
          setError(data?.msg);
        }
      });
    } else {
      setError("Please Enter Valid Email");
    }
  };

  const HandleResend = () => {
    setisDisable(true);
    HandleOTP();
    setCount(30);
  };

  const HandleRun = (e) => {
    if (e.keyCode === 13) {
      e.preventDefault();
      HandleOTP();
    }
  };

  // Verify OTP
  const HandleOTPverify = () => {
    if (ForgotOTP) {
      if (ForgotOTP === parseInt(OTP)) {
        setShow("NewPassord");
      } else {
        setError("Please Enter Valid OTP");
      }
    }
  };
  const VerifyAutoSearch = (e) => {
    if (e.keyCode === 13) {
      e.preventDefault();
      HandleOTPverify();
    }
  };

  //  NEW PASSWORD
  const HandleMewPass = () => {
    axios.post(POST_NEW_PASS(SendData.email, password)).then(({ data }) => {
      toast.info(data?.msg, {
        position: "top-right",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
      setTimeout(() => nav("../affiliate/login"), 1000);
    });
  };

  const NewPassAutoSearch = (e) => {
    if (e.keyCode === 13) {
      e.preventDefault();
      HandleMewPass();
    }
  };

  const handlePasswordChange = (event) => {
    const newPassword = event.target.value;
    setPassword(newPassword);
    setValidPasswordLength(newPassword.length >= 8);
    setPasswordsMatch(newPassword === confirmPassword);
  };

  const handleConfirmPasswordChange = (event) => {
    const newConfirmPassword = event.target.value;
    setConfirmPassword(newConfirmPassword);
    setPasswordsMatch(password === newConfirmPassword);
  };

  return (
    // ForgotContainer
    <div className="LoginContainer SignupContainer ">
      <ToastContainer />
      <div className="Login">
        <div className="row">
          <div className="col-md-6 col-lg-6 d-none d-md-block d-lg-block">
            <div className="log_left">
              <img src={RESET} alt="Login" />
            </div>
          </div>
          <div className="col-md-6 col-lg-6">
            <div className="log_Right">
              <div className="log_content Register_content">
                <div className="logo">
                  <img src={LOGO} alt="" />
                </div>
                <h1>Password Reset</h1>

                {Show == "email" ? (
                  <form
                    autoComplete="off"
                    onSubmit={(e) => e.preventDefault()}
                    onChange={() => setError("")}
                  >
                    <div className="Feilds">
                      <div className="INPUTPR">
                        <div className="input_group forgotInput">
                          <img src={MAIL} alt="" />{" "}
                          <input
                            type="email"
                            name="email"
                            autoComplete="off"
                            placeholder="Enter Your Email"
                            id="myInput"
                            onKeyDown={HandleRun}
                            onChange={(e) => {
                              setSendData(() => {
                                return { ...SendData, email: e.target.value };
                              });
                            }}
                          />
                        </div>
                        <div className="ERR">{Error}</div>
                      </div>
                    </div>

                    <div className="FlexBtn mt-4">
                      <button onClick={HandleOTP}>Get OTP</button>
                    </div>
                  </form>
                ) : Show !== "NewPassord" ? (
                  <form
                    autoComplete="off"
                    onSubmit={(e) => e.preventDefault()}
                    onChange={() => setError("")}
                  >
                    <div className="Feilds">
                      <div className="INPUTPR">
                        <div className="input_group forgotInput">
                          <img src={PASS} alt="" />{" "}
                          <input
                            type="number"
                            name="OTP"
                            autoComplete="off"
                            placeholder="Enter Your OTP"
                            onChange={(e) => setOTP(e.target.value)}
                            onKeyDown={VerifyAutoSearch}
                          />
                        </div>
                        <div className="resend">
                          <div className="otp">
                            {isDisable && <p>{Count}</p>}
                            <button
                              onClick={HandleResend}
                              disabled={isDisable || CountValid > 1}
                              style={{
                                opacity: isDisable || CountValid > 1 ? 0.5 : 1,
                              }}
                            >
                              Resend Code
                            </button>
                          </div>
                        </div>
                        <div className="ERR">{Error}</div>
                      </div>
                    </div>

                    <div className="FlexBtn mt-4">
                      <button type="submit" onClick={HandleOTPverify}>
                        Verify OTP
                      </button>
                    </div>
                  </form>
                ) : (
                  <form autoComplete="off" onSubmit={(e) => e.preventDefault()}>
                    <div className="Feilds">
                      <div className="INPUTPR">
                        <div className="input_group forgotInput">
                          <img src={PASS} alt="" />{" "}
                          <input
                            type="password"
                            name="username"
                            autoComplete="off"
                            value={password}
                            onChange={handlePasswordChange}
                            placeholder="Enter New Password"
                            id="myInput"
                            style={{
                              borderColor: passwordsMatch ? "initial" : "red",
                            }}
                          />
                        </div>
                        {!validPasswordLength && (
                          <p style={{ color: "yellow", marginBottom: "-5px" }}>
                            Password must be at least 8 characters long
                          </p>
                        )}
                      </div>
                      <div className="INPUTPR">
                        <div className="input_group forgotInput">
                          <img src={PASS} alt="" />{" "}
                          <input
                            type="password"
                            value={confirmPassword}
                            name="username"
                            onChange={handleConfirmPasswordChange}
                            onKeyDown={NewPassAutoSearch}
                            autoComplete="off"
                            placeholder="Confirm Password"
                            id="myInput"
                            style={{
                              borderColor: passwordsMatch ? "initial" : "red",
                            }}
                          />
                        </div>
                        {!passwordsMatch && (
                          <p style={{ color: "yellow" }}>
                            Passwords do not match
                          </p>
                        )}
                      </div>
                    </div>

                    <div className="FlexBtn mt-4">
                      <button onClick={HandleMewPass}>Change Password</button>
                    </div>
                  </form>
                )}
                <div>
                  <div className="OR">
                    <div className="line1"></div>
                    <span>OR</span>
                    <div className="line1"></div>
                  </div>
                  <div className="RegisterLinks">
                    <p>
                      <MdOutlineKeyboardBackspace className="me-2" />
                      <Link to="/affiliate/login"> Back to Log In</Link>
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
