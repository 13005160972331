import React, { useEffect, useState } from "react";
import { Link, useLocation } from "react-router-dom";
import { DottLoader } from "../../../../Mycomponent/share/loader";
import CustomTable from "../../../../Mycomponent/share/customTable";
import { GoDash } from "react-icons/go";
import { Box, Button } from "@mui/material";
import { CSVLink } from "react-csv";

const BackLinkToolTable = () => {
  const [isLoading, setisLoading] = useState(false);
  const [page, setPage] = React.useState(1);

  useEffect(() => {
    setisLoading(true);
    setTimeout(() => setisLoading(false), 1000);
  }, [0]);

  const { state } = useLocation();

  const tableHeader = [
    {
      label: "USERS",
      key: "users",
      minWidth: 170,
      align: "left",
      width: "25%",
    },
    {
      label: "DOMAIN",
      key: "domain",
      minWidth: 170,
      width: "25%",
      align: "left",
    },
    {
      label: "Backlinks",
      key: "backlinks",
      minWidth: 170,
      width: "25%",
      align: "center",
    },
    {
      label: "Reffering Domain",
      key: "ref_domain",
      minWidth: 170,
      width: "25%",
      align: "center",
    },
    {
      label: "Ip Address",
      key: "ip_address",
      minWidth: 170,
      width: "25%",
      align: "center",
    },
  ];

  function createData(users, domain, backlinks, ref_domain, ip_address) {
    return {
      users,
      domain,
      backlinks,
      ref_domain,
      ip_address,
    };
  }

  const rows = state?.map((item) => {
    return createData(
      item?.user,
      item?.domain,
      item?.backlinks || <GoDash />,
      item?.ref_domain || <GoDash />,
      item?.IP || <GoDash />
    );
  });

  const loadCSVData = () => {
    if (state?.length === 0) return [];
    return state?.reverse()?.map((item) => {
      const obj = {
        user: item?.user,
        domain: item?.domain,
        backlinks: item?.backlinks || "",
        ref_domain: item?.ref_domain || "",
        ip_address: item?.IP || 0,
      };
      return obj;
    });
  };

  return (
    <>
      <Link to={-1} style={{ display: "inline-block" }}>
        <button className="AddListName d-flex align-items-center">
          <i
            className="fa-solid fa-backward"
            style={{ paddingRight: "10px" }}
          ></i>

          <p className="m-0 p-0">Back</p>
        </button>
      </Link>
      <Box className="mb-2 d-flex justify-content-end">
        <CSVLink data={loadCSVData()} filename={"backlinks_tool_usage.csv"}>
          <Button className="btn_1">Download CSV</Button>
        </CSVLink>
      </Box>
      {isLoading ? (
        <DottLoader />
      ) : (
        <CustomTable
          tableHeader={tableHeader}
          rows={rows}
          page={page}
          setPage={setPage}
        />
      )}
    </>
  );
};

export default BackLinkToolTable;
