import React, { useEffect, useState } from "react";
import "../../css/ViewList.scss";
import KEY from "../../Assets/keyword-subfiles/key.png";
import KEYIMG from "../../Assets/keyword-subfiles/KeyImg.png";
import {
  DELETE_LIST,
  DELETE_LIST_BACKLINKS,
  DELETE_VIEWLISTBACKLINK,
  DELETE_VIEWLISTKEYWORD,
  GET_ALLLIST_BACKLINKS,
  GET_allList,
  USER_SMTP_CHECK,
  USER_SMTP_ADD,
} from "../../../services/constants";
import { useSelector, useDispatch } from "react-redux";
import deleteIcon from "../../Assets/rank-tracking-assets/delete.svg";
import axios from "axios";
import { Delete } from "@mui/icons-material";
import AreYousure from "../../share/card-layout/AreYousure";
import { ToastContainer, toast } from "react-toastify";
import Toast from "../../share/components/Toast";
import { Link, useNavigate } from "react-router-dom";
import PopOver from "../../share/components/popover.component";
import { TextField, Box } from "@mui/material";
import ToolTip from "../../share/components/tooltip";
import { ReactComponent as UnsortIcon } from "../../Assets/rank-tracking-assets/unshort.svg";

import backIcon from "../../Assets/common/back.svg";
import { FiKey } from "react-icons/fi";
import { CiCircleList } from "react-icons/ci";
import { dynamicSort } from "../../../helperJs/helper";

const ViewListBackLinks = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [AllLists, setAllLists] = useState([]);
  const [Namae, setNamae] = useState("");
  const [selectedKeywords, setselectedKeywords] = useState([]);
  const [SingleListView, setSingleListView] = useState(false);
  const [userList, setUserList] = useState([]);
  const [anchorEl, setAnchorEl] = useState(false);
  const [error, setError] = useState("");
  const [Loader, setLoader] = useState(true);
  const [formData, setFormData] = useState({
    email: "",
    password: "",
  });
  const [tableData, setTableData] = useState([]);
  const [direction, setDirection] = useState(true);
  // MODAL MUI FOR DELETE
  const [open, setOpen] = useState(false);
  const handleOpen = (name) => {
    setNamae(name);
    setOpen(true);
  };
  const regex = /^(?:https?:\/\/)?(?:www\.)?([^/]+)/i;

  const checkDomain = (firstUrl, secUrl) => {
    const url1 = firstUrl?.match(regex);
    const url2 = secUrl?.match(regex);
    return url1?.[1] === url2?.[1];
  };
  // UseSelector
  const EMAIL = useSelector((state) => state?.email);

  useEffect(() => {
    const single_list = AllLists?.filter(
      (name) => Namae === name.back_list_name
    );
    const singleArrayList = single_list?.[0]?.backlink_list?.map((item) => {
      const matchedURL = userList?.find((contact) => {
        return checkDomain(contact.url, item);
      });
      if (matchedURL) {
        return {
          url: item,
          email: matchedURL?.email,
        };
      } else {
        return {
          url: item,
          email: "No Contact Information Found",
        };
      }
    });
    setTableData(singleArrayList);
    // if(single_list)
  }, [Namae, AllLists]);

  // Gte List
  const FetchLISTING = () => {
    setLoader(true);
    axios.get(GET_ALLLIST_BACKLINKS(EMAIL)).then((Result) => {
      if (Result?.data) {
        setAllLists(Result?.data);
        setTimeout(() => {
          setLoader(false);
        }, 200);
      }
    });
  };

  async function handlebacklinkList() {
    await axios
      .get("https://app.rankspro.io/api/user/getbacklinkemaildata/")
      .then((response) => {
        // const combinedArray = response.data.flat(1);
        const uniqueArray = [
          ...new Set(response.data.flat(1).map((obj) => obj.url)),
        ].map((url) => {
          return response.data.flat(1).find((obj) => obj.url === url);
        });
        setUserList(uniqueArray);
      });
  }

  useEffect(() => {
    FetchLISTING();
    handlebacklinkList();
  }, [EMAIL]);

  const HandleDeleteList = (RES) => {
    if (RES) {
      axios.delete(DELETE_LIST_BACKLINKS(EMAIL, Namae)).then((res) => {
        if (res?.data?.msg === "List Deleted Successfully") {
          <Toast msg={"Succss"} />;
          setOpen(false);
          FetchLISTING();
        }
      });
    } else {
      setOpen(false);
    }
  };

  // Table
  const single_list = AllLists?.filter((name) => Namae === name.back_list_name);

  const handleCheckboxChange = (keyword, res) => {
    setselectedKeywords((prevSelectedKeywords) => {
      if (prevSelectedKeywords.includes(keyword)) {
        const updatedKeywords = prevSelectedKeywords.filter(
          (selectedKeyword) => selectedKeyword !== keyword
        );
        return updatedKeywords;
      } else {
        return [...prevSelectedKeywords, keyword];
      }
    });
  };

  const handleSingleShow = (res) => {
    setNamae(res.back_list_name);
    setSingleListView(true);
  };

  const HandleDeleteSingle = () => {
    axios
      .delete(DELETE_VIEWLISTBACKLINK(EMAIL, Namae, selectedKeywords))
      .then(({ data }) => {
        if (data?.msg === "Keyword Deleted Successfully") {
          <Toast msg={"Deleted Successfully"} />;
          FetchLISTING();
          setselectedKeywords([]);
        }
      });
  };

  const handleContactSourcer = (url, email) => {
    dispatch({
      type: "SMTPUSER",
      payload: { url, email },
    });

    axios.get(USER_SMTP_CHECK(EMAIL)).then((response) => {
      if (response?.data?.length > 0) {
        navigate("/backlink/contact");
      } else {
        setAnchorEl(true);
      }
    });
  };

  const validateEmail = (email) => {
    // Regular expression for basic email validation
    const re = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return re.test(String(email).toLowerCase());
  };

  const submitSmtpPassword = () => {
    if (formData?.email === "" && formData?.password === "") {
      setError("Please enter a SMTP details");
    } else if (formData?.email === "") {
      setError("Please enter email address");
    } else if (!validateEmail(formData?.email)) {
      setError("Please enter valid email address");
    } else if (formData?.password === "") {
      setError("Please enter password");
    } else {
      axios
        .post(USER_SMTP_ADD(), {
          email: EMAIL,
          send_email: formData?.email,
          smtp: formData?.password,
        })
        .then((response) => {
          if (response.data.status === 200) {
            setAnchorEl(false);
            navigate("/backlink/contact");
          } else {
            setError(
              `${response?.data?.msg || "Please check your credentials."}`
            );
          }
        })
        .catch((error) => {
          setAnchorEl(false);
        });
    }
  };

  const Sppiner = () => {
    return (
      <Box
        sx={{ display: "flex", minHeight: 350, minWidth: 350 }}
        className="w-100 h-100 cad"
      >
        <div
          className="spinner-border"
          role="status"
          style={{ width: "3rem", height: "3rem", color: "#365486" }}
        >
          <span className="sr-only"></span>
        </div>
      </Box>
    );
  };

  const handleBackBtn = () => {
    const check = sessionStorage.getItem("backlinkgapback");
    if (check != null && check) {
      sessionStorage.removeItem("backlinkgapback");
      navigate("/backlink-gap");
    } else {
      sessionStorage.removeItem("backlinkgapback");
      navigate("/backlinks");
    }
  };

  const handleChange = (e) => {
    setError("");
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  function extractDomain(url) {
    const domainRegex = /^(?:https?:\/\/)?(?:[^@\n]+@)?(?:www\.)?([^:/\n?]+)/;
    const matches = url?.match(domainRegex);
    return matches && matches[1] ? matches[1] : null;
  }

  function sortByContactAscending() {
    return tableData?.sort((a, b) => {
      const hasContactA = Array.isArray(a.email) && a.email.length > 0;
      const hasContactB = Array.isArray(b.email) && b.email.length > 0;

      if (hasContactA && !hasContactB) {
        return -1; // a comes before b (a has contact, b does not)
      } else if (!hasContactA && hasContactB) {
        return 1; // b comes before a (b has contact, a does not)
      } else {
        return 0; // both have contact or both do not have contact, maintain current order
      }
    });
  }

  function sortByContactDescending() {
    return tableData?.sort((a, b) => {
      const hasContactA = Array.isArray(a.email) && a.email.length > 0;
      const hasContactB = Array.isArray(b.email) && b.email.length > 0;

      if (!hasContactA && hasContactB) {
        return -1; // b comes before a (b has contact, a does not)
      } else if (hasContactA && !hasContactB) {
        return 1; // a comes before b (a has contact, b does not)
      } else {
        return 0; // both have contact or both do not have contact, maintain current order
      }
    });
  }

  const handleSorting = (field) => {
    setDirection((pre) => !pre);
    if (field === "url") {
      const data = tableData?.sort((a, b) => {
        const domainA = extractDomain(a[field]);
        const domainB = extractDomain(b[field]);

        if (!domainA && !domainB) {
          return 0;
        } else if (!domainA) {
          return direction ? 1 : -1;
        } else if (!domainB) {
          return direction ? -1 : 1;
        } else {
          return direction
            ? domainA.localeCompare(domainB)
            : domainB.localeCompare(domainA);
        }
      });
      setTableData(data);
    } else {
      setTableData(
        direction ? sortByContactAscending() : sortByContactDescending()
      );
    }
  };

  return (
    <>
      <PopOver anchorEl={anchorEl}>
        <div className="backlink-modal">
          <h1 className="heading-medium font-bold">Enter SMTP Info</h1>
          <TextField
            fullWidth
            id="outlined-email"
            type="email"
            label="Email"
            variant="outlined"
            className="mt-3"
            name="email"
            value={formData.email}
            onChange={handleChange}
          />
          <TextField
            fullWidth
            id="outlined-password"
            type="password"
            label="Password"
            variant="outlined"
            className="mt-3"
            name="password"
            value={formData.password}
            onChange={handleChange}
          />

          {error && <span className="msg-error mt-1">{error}</span>}
          <div className="backlink-modal-btn cmd mt-4">
            <button
              className="btn_2"
              style={{ height: "45px", width: "90px" }}
              onClick={() => {
                setError("");
                setAnchorEl(false);
              }}
            >
              Cancel
            </button>
            <button
              className="btn_1"
              style={{ padding: "0.5rem 0.75rem !important" }}
              onClick={() => submitSmtpPassword()}
            >
              Submit
            </button>
          </div>
        </div>
      </PopOver>
      <div className="Domain_view">
        {open && (
          <AreYousure
            callback={HandleDeleteList}
            title={"You will not able to recover this List !"}
          />
        )}
        {!SingleListView && (
          <div className="mt-3">
            {" "}
            <button
              className="icon-btn large-btn"
              onClick={() => handleBackBtn()}
            >
              <img src={backIcon} alt="" />
            </button>
            <div className="row viewList ">
              {Loader ? (
                <Sppiner />
              ) : AllLists.length === 0 ? (
                <div className="EmptyBox">
                  <h3 className="text-danger">No List Found</h3>
                </div>
              ) : (
                AllLists?.map((val, index) => {
                  return (
                    <div className="col-md-4 col-lg-4 col-12" key={index}>
                      <div className="ViewListBox p-0 point">
                        <div
                          className="d-flex justify-content-between pb-0"
                          onClick={() => handleSingleShow(val)}
                          style={{ padding: "1rem" }}
                        >
                          <div className="key-name font-bold">
                            {val.back_list_name}
                          </div>
                          <div className="keyIcon">
                            <CiCircleList className="new-list-icon" />
                          </div>
                        </div>
                        <hr />
                        <div
                          className="d-flex justify-content-between pt-0"
                          style={{ padding: "1rem" }}
                        >
                          <div
                            className="d-flex"
                            onClick={() => handleSingleShow(val)}
                          >
                            <div className="keyIconSub">
                              <FiKey
                                style={{
                                  color: "#365486",
                                  width: "25px",
                                  height: "25px",
                                }}
                              />
                            </div>
                            <div className="descript ms-3">
                              Total Keywords :{" "}
                              <strong>{val.backlink_list.length}</strong>
                            </div>
                          </div>
                          <div className="deleteBt">
                            <Delete
                              style={{ cursor: "pointer", color: "#365486" }}
                              onClick={() => {
                                handleOpen(val.back_list_name);
                              }}
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  );
                })
              )}
            </div>
          </div>
        )}
        {SingleListView && (
          <div className="ViewSingleList mt-3">
            <div className="d-flex justify-content-between mt-2">
              <div className="d-flex align-items-center">
                <button
                  className="icon-btn large-btn"
                  onClick={() => {
                    setSingleListView(false);
                  }}
                >
                  <img src={backIcon} alt="" />
                </button>
                <h2
                  className="ms-5 mb-0"
                  style={{ textTransform: "capitalize" }}
                >
                  {Namae}
                </h2>
              </div>

              <button
                disabled={selectedKeywords?.length === 0}
                className="icon-btn large-btn"
                title="Delete"
                onClick={() => HandleDeleteSingle()}
              >
                <img src={deleteIcon} alt="rank-delete" />
              </button>
            </div>
            <div className="custom-table-container mt-4">
              <table className="">
                <thead>
                  <tr className="table-sticky">
                    <th scope="col" className="check-box-input"></th>
                    <th className="col">
                      <span
                        className="point"
                        onClick={() => handleSorting("url")}
                      >
                        BACKLINKS
                        <span className="ms-2">
                          <UnsortIcon />
                        </span>
                      </span>
                      <ToolTip title="Backlinks" />
                    </th>
                    <th className="col">
                      <span
                        className="point"
                        onClick={() => handleSorting("email")}
                      >
                        CONTACT
                        <span className="ms-2">
                          <UnsortIcon />
                        </span>
                      </span>
                      <ToolTip title="Contact Details" />
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {single_list?.length === 0 ? (
                    <tr>
                      <td colSpan={6}>No data found !</td>
                    </tr>
                  ) : (
                    tableData?.map((res, index) => {
                      return (
                        <tr key={index}>
                          <td className="check-box-input">
                            <input
                              type="checkbox"
                              checked={selectedKeywords.includes(res?.url)}
                              value={res?.url}
                              onChange={(e) =>
                                handleCheckboxChange(e.target.value, res?.url)
                              }
                            />
                          </td>
                          <td
                            className="table-max-content"
                            style={{ display: "table-cell" }}
                          >
                            {res?.url}
                          </td>
                          <td>
                            {typeof res?.email === "string" ? (
                              <span style={{ cursor: "pointer" }}>
                                {res?.email}
                              </span>
                            ) : (
                              <span
                                style={{
                                  cursor: "pointer",
                                  textDecoration: "underline",
                                }}
                                onClick={() =>
                                  handleContactSourcer(res?.url, res?.email)
                                }
                              >
                                {res?.email?.length} Contact Found
                              </span>
                            )}
                          </td>
                        </tr>
                      );
                    })
                  )}
                </tbody>
              </table>
            </div>
          </div>
        )}
      </div>
    </>
  );
};

export default ViewListBackLinks;
