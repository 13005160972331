import React, { useEffect } from "react";
import axios from "axios";
import { ZustandStore } from "../store/zustand-store";
import countryList from "../Mycomponent/dataFiles/country";

const CountryLanguage = () => {
  const { country_Language_Data } = ZustandStore((state) => state);

  useEffect(() => {
    const getCurrentCountryDetails = (location) => {
      const data = countryList?.filter((item) => {
        return item.location_name === location?.location_name;
      });
      if (data) {
        location.flag = data?.[0]?.location_flag;
      }

      return location.available_languages.map((lang) => {
        return {
          title: `${lang.language_name} / ${location.location_name}`,
          ...location,
          ...lang,
        };
      });
    };

    const fetchData = async () => {
      try {
        let config = {
          method: "get",
          maxBodyLength: Infinity,
          url: "https://api.dataforseo.com/v3/dataforseo_labs/locations_and_languages",
          headers: {
            Authorization:
              "Basic aW5mb0Blc2VhcmNobG9naXguY29tOmZmOWZiMjY4NDZhMTYwZGI=",
          },
        };

        const response = await axios.request(config);
        const data = response?.data?.tasks?.[0]?.result
          ?.filter((item) => {
            return item?.location_type === "Country";
          })
          .map((location) => {
            return getCurrentCountryDetails(location);
          })
          .flat();

        ZustandStore.setState({
          country_Language_Data: {
            data: data,
            error: false,
            loading: false,
          },
        });
      } catch (error) {
        ZustandStore.setState({
          country_Language_Data: {
            data: [],
            error: true,
            loading: false,
          },
        });
      }
    };

    if (
      country_Language_Data?.data?.length === 0 &&
      country_Language_Data?.loading
    ) {
      fetchData();
    }
  }, [country_Language_Data, countryList]);
  return null;
};

export default CountryLanguage;
