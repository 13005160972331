import React, { useState, useEffect, useMemo, useRef } from "react";
import "../../css/ai-article.scss";
import axios from "axios";
import html2pdf from "html2pdf.js";
import { Box, Button } from "@mui/material";
import { useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import aiArt3 from "../../Assets/ai-article/ai-3.png";
import aiArt4 from "../../Assets/ai-article/ai-4.png";
import AlertPopUp from "./alert.component";
import { saveAs } from "file-saver";
import htmlDocx from "html-docx-js/dist/html-docx";
import MenuListDropdown from "./menulist.component";
import bgAIImage from "../../Assets/ai-article/bg-ai-article.png";
import addProjectImage from "../../Assets/ai-article/ai-add-project.png";
import TiptopEditor from "../../share/components/Tiptop Editor/tiptop-editor";
import backIcon from "../../Assets/common/back.svg";
import useDebouncedFunction from "../../../helperJs/debounce";
import { ai_title, subheading, content } from "../../dataFiles/ai.data";
import { POST_SAVEBLOG, AIPROJECT_GET } from "../../../services/constants";
import { generateText } from "./AI-Writer/openaiService";
import ChatGPTTypingAnimation from "./AI-Writer/ChatGPTTypingAnimation";
import { Player } from "@lottiefiles/react-lottie-player";
import AIRoboAni from "../../Assets/ai-writer-robo-ani.json";
import saveIcon from "../../Assets/ai-article/save.svg";
import { ReactComponent as SaveIcon } from "../../Assets/ai-article/save.svg";

function BannerHeader() {
  return (
    <div className="add-project-box">
      <img src={bgAIImage} alt="ai_add_project" className="add-project-bg" />
      <div className="box-contain">
        <img src={addProjectImage} alt="add_project" />
        <button className="btn_new_bg CreateAi mt-3">Article Writer</button>
      </div>
    </div>
  );
}

function AIArticle() {
  const navigate = useNavigate();
  const responsesRef = useRef([]);
  const cancelTokenSource = useRef(null);
  const admin_mail = localStorage.getItem("admin_mail");
  const websiteurl = useSelector((state) => state.websiteurl);
  const topicKeywords = useSelector((state) => state.topicKeyword);
  const [count, setCount] = useState();
  const [fixedTitles, setFixedTitles] = useState(5);
  const [keyword, setKeyword] = useState(topicKeywords);
  const [viewPort, setViewPort] = useState("KEYWORD");
  const [selectedTopicKeyword, setSelectedTopicKeyword] = useState();
  const [seoKeywordData, setSeoKeywordData] = useState([]);
  const [generatedData, setGeneratedData] = useState([]);
  const [TextEditerValue, setTextEditerValue] = useState("");
  const [Error, setError] = useState("");
  const [Alert, setAlert] = useState({
    type: "",
    message: "",
    show: false,
  });

  const [aiArticle, setAIArticle] = useState([]);
  const ai_article_limit = 25;
  const user_article_limit_used = aiArticle?.length;

  const fetchAiData = async () => {
    if (admin_mail == null) return;
    return await axios
      .get(AIPROJECT_GET(admin_mail))
      .then((res) => {
        setAIArticle(res.data);
      })
      .catch(() => {
        setAIArticle([]);
      });
  };

  useEffect(() => {
    if (!topicKeywords) {
      navigate("/rank-tracker");
    }
  }, [topicKeywords]);

  useEffect(() => {
    fetchAiData();
  }, [admin_mail]);

  const handleInputChange = useDebouncedFunction((e) => {
    setError("");
    setKeyword(e.target.value);
  }, 300);

  const htmlString = useMemo(() => {
    return responsesRef.current.join(" ");
  }, [responsesRef.current]);

  const onCreateTopicKeywordHandler = () => {
    if (!keyword) {
      setError("Please, Enter Keyword");
      return;
    } else if (ai_article_limit <= user_article_limit_used) {
      setError(
        "Article Limit Exceeded, You have already generated 20 articles"
      );
      return;
    }
    responsesRef.current = [];
    setSeoKeywordData([]);
    scrollIntoView("breadcrumb-ai-editer");
    // setSeoKeywordData([...new Set(ai_title.titles)]);
    setViewPort("KEYWORDTOPIC");

    axios
      .post(
        "https://app.rankspro.io/api/user/keytitle/?title=" +
          keyword +
          "&num_titles=" +
          fixedTitles
      )
      .then((response) => {
        const uniqueSubheadings = [...new Set(response.data.titles)];
        setSeoKeywordData(uniqueSubheadings);
        // setViewPort("KEYWORDTOPIC");
      })
      .catch(() => {
        setAlert({
          type: "error",
          message: "Keyword Topic generation failed",
          show: true,
        });
        setViewPort("KEYWORD");
      });
  };

  const onStartTopicGeneration = () => {
    if (!selectedTopicKeyword) {
      setError("Please, select title");
      return;
    }
    setError("");
    setGeneratedData([]);
    responsesRef.current = [];
    setViewPort("KEYWORDTOPICGENERATION");
    // setGeneratedData([...new Set(subheading.subheadings)]);
    scrollIntoView("breadcrumb-ai-editer");

    axios
      .post(
        `https://app.rankspro.io/api/user/generatetitle/?title=${selectedTopicKeyword}`
      )
      .then((response) => {
        const data = response.data?.subheadings?.filter((headingArr) => {
          if (headingArr?.length > 0) return headingArr;
        });
        const uniqueSubheadings = [...new Set(data)];
        setGeneratedData(uniqueSubheadings);
      })
      .catch((error) => console.log("error generatetitle", error));
  };

  const onRegenerateTopicKeywords = () => {
    onStartTopicGeneration();
  };

  const Array = generatedData?.map((item) => item.replace(/^\d+\.\s+/, ""));
  // added title query,

  useEffect(() => {
    const generateNextArticle = async (index) => {
      if (index >= Array.length) return; // Base case: Stop when all items are processed
      const item = Array[index];
      const detailedPrompt = `
       Write a detailed, 80 to 120-word search engine optimized blog post in English for the ${item}. Ensure the content is formatted using HTML tags: <h2>, <h3>, <ul>, <li>, and <p>, including explanations and lists, examples, and practical insights. Organize the article with clear, creative headings using appropriate heading tags (e.g., <h2>, <h3> etc.). Break down the ideas using unordered lists with <ul> and <li> tags where appropriate. Use <p> tags for paragraphs to enhance readability and content parsing. Ensure that each heading is followed by a paragraph to maintain smooth flow. The headings should not be numbered.
      `;

      try {
        const aiResponse = await generateText(detailedPrompt);
        responsesRef.current = [...responsesRef.current, aiResponse]; // Update responsesRef.current
      } catch (error) {
        console.error("Error generating text:", error);
      } finally {
        setCount((prev) => prev + 1);
      }
    };

    if (typeof count === "number") {
      // Ensure count is a number
      if (count >= 0) {
        // Handle 0 and positive numbers
        generateNextArticle(count); // Start the recursive calls from the count value
      }
    }
  }, [count, Array]);

  const onGenerateArticle = async () => {
    if (Array?.length === 0) return;
    responsesRef.current = [];
    // responsesRef.current = content; // Update responsesRef.current
    scrollIntoView("breadcrumb-ai-editer");
    setViewPort("AITEXTGENERATION");
    setCount(0);
  };

  const resetGenerateData = (item) => {
    setSelectedTopicKeyword(item);
    setGeneratedData([]);
  };

  const today = new Date();
  var dd = today?.getDate();
  var monthNames = [
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "May",
    "Jun",
    "Jul",
    "Aug",
    "Sep",
    "Oct",
    "Nov",
    "Dec",
  ];
  var mm = monthNames[today.getMonth()];
  var yyyy = today?.getFullYear();

  const articalSaveHand = () => {
    const htmlContent = responsesRef.current?.[0];
    // Create a temporary DOM element to parse the HTML content
    const tempDiv = document.createElement("div");
    tempDiv.innerHTML = htmlContent;
    // Extract the text content of the first <p> tag
    const firstParagraphText = tempDiv.querySelector("p").textContent;
    if (responsesRef.current?.length === 0) return;
    const item = {
      email: admin_mail,
      title: selectedTopicKeyword,
      content: [
        {
          desc: firstParagraphText,
          content: TextEditerValue || htmlString,
        },
      ],
      user_proj: websiteurl,
      keyword: topicKeywords,
      date: dd,
      month: mm,
      year: yyyy,
    };

    axios
      .post(POST_SAVEBLOG(), item)
      .then((res) => {
        setAlert({
          type: "success",
          message: "Article Updated Successfull",
          show: true,
        });
        setTimeout(() => {
          navigate("/ai/content/projects");
        }, 1000);
      })
      .catch(() => {
        setAlert({
          type: "error",
          message: "Article Not Updated",
          show: true,
        });
      });
  };

  const handleTextEditorOnChange = (value) => {
    setTextEditerValue(value);
  };

  const scrollIntoView = (id) => {
    const element = document.getElementById(id);
    if (element) {
      element.scrollIntoView({
        block: "start",
        behavior: "smooth",
      });
    }
  };

  const exportAsPDF = async () => {
    const htmlContent = TextEditerValue || htmlString; // Ensure htmlContent is not undefined
    // Create a div element to hold the HTML content
    const div = document.createElement("div");
    div.innerHTML = htmlContent;
    // Convert the HTML content to PDF
    html2pdf(div, {
      margin: 15,
      filename: "AI-writer.pdf",
      html2canvas: { scale: 2 },
      jsPDF: { format: "a4", orientation: "portrait" },
    })
      .from()
      .save();
  };

  const exportAsDoc = () => {
    const htmlContent = TextEditerValue || htmlString;
    const converted = htmlDocx.asBlob(htmlContent);
    saveAs(converted, "AI-Writer.docx");
  };

  const dataList = {
    list: [
      <button
        className="cm-btn_2 rounded-5 px-3 py-2"
        onClick={() => exportAsPDF()}
      >
        <i
          className="fa-regular fa-file-lines"
          style={{ paddingRight: "10px" }}
        ></i>
        Export PDF
      </button>,
      <button
        className="cm-btn_2 rounded-5 px-3 py-2"
        onClick={() => exportAsDoc()}
      >
        <SaveIcon />
        Export DOC
      </button>,
    ],
    // title: "Export File"
  };

  const handleTextEditor = () => {
    setViewPort("TEXTEDITOR");
  };

  const chatGPTContent = {
    content: responsesRef.current,
    keyword: keyword,
    topic: selectedTopicKeyword,
    total_api_call: Array?.length,
  };

  const handleBackAction = (type) => {
    if (type === "KEYWORDTOPICGENERATION") {
      setSelectedTopicKeyword();
      setViewPort("KEYWORDTOPIC");
    } else if (type === "TEXTEDITOR") {
      setSelectedTopicKeyword();
      responsesRef.current = [];
      setViewPort("KEYWORD");
    } else if (type === "AITEXTGENERATION") {
      setCount();
      responsesRef.current = [];
      setViewPort("KEYWORDTOPICGENERATION");
    }
  };

  return (
    <div className="Domain_view">
      <AlertPopUp config={Alert} handleClose={setAlert} />
      {viewPort === "KEYWORD" ? (
        <div className="ai-article-container my-4">
          <BannerHeader />
          <div className="add-pro-box mt-4">
            <div className="d-flex align-items-center">
              <Link to={-1}>
                <button className="icon-btn large-btn">
                  <img src={backIcon} alt="back button" />
                </button>
              </Link>
              <h1 className="heading-large font-light-bold ms-3 mb-0">
                Create Keyword
              </h1>
            </div>
            <div className="add-pr-url">
              <div
                className="row gy-3 add-pro-web-input mt-3"
                style={{ alignItems: "baseline" }}
              >
                <div className="col-12 col-md-6 col-lg-6 add-sel">
                  <label className="heading-medium font-regular mb-1">
                    Topic Keyword
                  </label>
                  <input
                    className="heading-small mt-2"
                    value={keyword}
                    onChange={handleInputChange}
                    style={{
                      color: "#00000099",
                      border: "1px solid #0000004D",
                    }}
                    placeholder="Enter SEO Keyword"
                  />
                  {Error ? (
                    <small className="keyword-error">{Error}</small>
                  ) : null}
                </div>

                <div className="col-12 col-md-6 col-lg-6 add-sel">
                  <label className="heading-medium font-regular mb-1">
                    No Of Titles To Generate
                  </label>
                  <div className="d-flex px-0 mt-2">
                    <span
                      className={
                        fixedTitles == 5 ? "seleced-title-span" : "title-span"
                      }
                      onClick={() => setFixedTitles(5)}
                    >
                      5
                    </span>
                    <span
                      className={
                        fixedTitles == 10 ? "seleced-title-span" : "title-span"
                      }
                      onClick={() => setFixedTitles(10)}
                    >
                      10
                    </span>
                    <span
                      className={
                        fixedTitles == 15 ? "seleced-title-span" : "title-span"
                      }
                      onClick={() => setFixedTitles(15)}
                    >
                      15
                    </span>
                    <span
                      className={
                        fixedTitles == 20 ? "seleced-title-span" : "title-span"
                      }
                      onClick={() => setFixedTitles(20)}
                    >
                      20
                    </span>
                    <span
                      className={
                        fixedTitles == 25 ? "seleced-title-span" : "title-span"
                      }
                      onClick={() => setFixedTitles(25)}
                    >
                      25
                    </span>
                    <span
                      className={
                        fixedTitles == 30 ? "seleced-title-span" : "title-span"
                      }
                      onClick={() => setFixedTitles(30)}
                    >
                      30
                    </span>
                  </div>
                </div>
              </div>

              <div className="add-pr-btn mt-4">
                <button
                  className="btn_new_bg"
                  style={{
                    borderRadius: "5px",
                    minWidth: "100px",
                    height: "48px",
                  }}
                  onClick={() => onCreateTopicKeywordHandler()}
                >
                  Create Topic Keyword
                </button>
              </div>
            </div>
          </div>
        </div>
      ) : viewPort === "KEYWORDTOPIC" ? (
        <>
          <button
            className="mt-4 icon-btn large-btn"
            onClick={() => {
              setSelectedTopicKeyword();
              setViewPort("KEYWORD");
            }}
          >
            <img src={backIcon} alt="" />
          </button>
          <div className="row mt-2 gy-3">
            <div className="col-lg-4">
              <div className="generate-topics">
                <div className="py-3 d-flex justify-content-between">
                  <input
                    className="w-100 heading-small"
                    defaultValue={keyword}
                    readOnly
                    placeholder="Enter SEO Keyword"
                  />
                </div>
                <p className="heading-medium">AI Article Generations</p>
                <div className="cmd ai-article-rem">
                  <div className="ai-tab-value">
                    <span className="heading-small">Used :</span>
                    <span className="heading-small font-bold ms-1">0</span>
                  </div>
                  <div className="ai-tab-value">
                    <span className="heading-small">Remaining :</span>
                    <span className="heading-small font-bold ms-1">0</span>
                  </div>
                </div>
                <ul
                  style={{
                    marginBottom: "60px",
                  }}
                >
                  {seoKeywordData?.length == 0 ? (
                    <span className="loader-center">
                      <div
                        className="spinner-border text-primary"
                        role="status"
                      >
                        <span className="sr-only"></span>
                      </div>
                    </span>
                  ) : (
                    seoKeywordData?.map((item) => {
                      return (
                        <li
                          className="topic-generate-item"
                          key={item}
                          onClick={(e) => resetGenerateData(item)}
                        >
                          <input
                            type="checkbox"
                            checked={item == selectedTopicKeyword}
                            readOnly
                          />
                          <span>{item}</span>
                        </li>
                      );
                    })
                  )}
                </ul>
              </div>
            </div>
            <div className="col-lg-8">
              <div className="welcome-ai-banner">
                <img src={aiArt3} alt="welcome-ai-banner" />
                <h2 className="heading-xlarge font-bold">
                  Welcome to AI Article Writer!
                </h2>
                <h5 className="heading-xlarge font-regular">
                  Pick the "Topic keyword" or Generate "Article" from the title
                  list
                </h5>
                <div className="">
                  <img src={aiArt4} alt="welcome-ai-banner" />
                  <button
                    className="btn_new_bg mt-4"
                    style={{ marginLeft: "60px" }}
                    onClick={() => onStartTopicGeneration()}
                  >
                    Generate
                  </button>
                </div>
                {Error ? (
                  <small className="keyword-error">{Error}</small>
                ) : (
                  <small></small>
                )}
              </div>
            </div>
          </div>
        </>
      ) : viewPort === "KEYWORDTOPICGENERATION" ? (
        <>
          <button
            className="icon-btn large-btn mt-4"
            onClick={() => handleBackAction("KEYWORDTOPICGENERATION")}
          >
            <img src={backIcon} alt="" />
          </button>
          <div className="add-keyword-card w-100 mt-4">
            <div className="row m-0">
              <div className="col-md-9 px-0">
                <div
                  className="add-keyword-heading heading-medium font-bold"
                  style={{
                    borderTopLeftRadius: "5px",
                  }}
                >
                  Generate Article
                </div>
                <div className="px-4 py-3">
                  <ul className="">
                    {generatedData?.length == 0 ||
                    generatedData?.length == 0 ? (
                      <span className="loader-center">
                        <div
                          className="spinner-border text-primary"
                          role="status"
                        >
                          <span className="sr-only"></span>
                        </div>
                      </span>
                    ) : (
                      generatedData?.map((item) => {
                        return (
                          <li className="gen-art-head-item" key={item}>
                            <p>{item}</p>
                          </li>
                        );
                      })
                    )}
                  </ul>
                  <div className="gen-art-btn-sec">
                    <p>
                      Re-generate the outline or accept and generate article.
                    </p>
                    <div>
                      <button
                        className="cm-btn px-3 py-2 me-4"
                        onClick={() => onRegenerateTopicKeywords()}
                        disabled={generatedData?.length == 0}
                      >
                        Re-generate headlines
                      </button>
                      <button
                        className="cm-btn-b2 px-3 py-2"
                        onClick={() => onGenerateArticle()}
                        disabled={generatedData?.length == 0}
                      >
                        Generate articles
                      </button>
                    </div>
                  </div>
                </div>
              </div>
              <div
                className="col-md-3 px-0"
                style={{
                  borderLeft: "1px solid #00000033",
                }}
              >
                <div
                  className="add-keyword-heading heading-medium font-bold"
                  style={{
                    borderTopRightRadius: "5px",
                  }}
                >
                  Title
                </div>
                <div className="ai-gen-tle-sec">
                  <p>{selectedTopicKeyword}</p>
                </div>
              </div>
            </div>
          </div>
        </>
      ) : viewPort === "TEXTEDITOR" ? (
        <>
          <div className="row mb-3 mb-sm-5 mt-2 mt-md-5 justify-content-between">
            <div className="col-12 col-md-3 d-flex justify-content-between">
              <button
                className="icon-btn large-btn"
                onClick={() => handleBackAction("TEXTEDITOR")}
              >
                <img src={backIcon} alt="" />
              </button>
              <div className="d-flex justify-content-end d-flex d-md-none mb-4">
                <MenuListDropdown data={dataList} cusClass="large-btn" />
                <button
                  className="icon-btn ms-3 large-btn"
                  onClick={() => articalSaveHand()}
                >
                  <img src={saveIcon} alt="" />
                </button>
              </div>
            </div>
            <div className="col-12 col-md-6">
              <div className="text-center">
                <h3 className="text-head">Title: {selectedTopicKeyword}</h3>
                <h3 className="text-head-val mt-2">Keyword: {keyword}</h3>
              </div>
            </div>
            <div className="col-12 col-md-3 d-flex justify-content-end d-none d-md-flex">
              <MenuListDropdown data={dataList} cusClass="large-btn" />
              <button
                className="icon-btn ms-3 large-btn"
                onClick={() => articalSaveHand()}
              >
                <img src={saveIcon} alt="" />
              </button>
            </div>
          </div>
          <div className="my-4">
            <TiptopEditor
              title={selectedTopicKeyword}
              keyword={keyword}
              content={TextEditerValue || htmlString}
              onchangeHandler={handleTextEditorOnChange}
            />
          </div>
        </>
      ) : viewPort === "AITEXTGENERATION" ? (
        <div>
          <div className="ai-text-generation">
            <button
              className="icon-btn large-btn"
              onClick={() => handleBackAction("AITEXTGENERATION")}
            >
              <img src={backIcon} alt="" />
            </button>
            <Box className="ai-robo-container">
              <Box className="d-flex align-items-center">
                <h1 className="mb-0">Best AI Content Platform</h1>
                <Player
                  src={AIRoboAni}
                  className="player ai-robo"
                  loop
                  autoplay
                  speed={1}
                />
              </Box>
              <p>
                Lorem Ipsum�is simply dummy text of the printing and typesetting
                industry
              </p>
            </Box>
          </div>
          <ChatGPTTypingAnimation
            content={responsesRef.current}
            data={chatGPTContent}
            handleTextEditor={() => handleTextEditor()}
          />
        </div>
      ) : null}
    </div>
  );
}

export default AIArticle;
