import React, { useEffect, useState, useRef, useMemo } from "react";
import { Box, Paper } from "@mui/material";
import { ReactComponent as OpenEditor } from "../../../Assets/ai-article/open-editor.svg";

const ChatGPTTypingAnimation = ({ data, handleTextEditor }) => {
  const [displayResponse, setDisplayResponse] = useState([]);
  const [count, setCount] = useState(0);
  const [completedTyping, setCompletedTyping] = useState(false);
  const containerRef = useRef(null);
  let keyword = data?.keyword || "";
  let title = data?.topic || "";
  let content = data?.content || [];
  const str = content?.[count];
  const isActiveBtn =
    count === content?.length && data?.total_api_call === content?.length;
  // Function to count words in a string
  const wordCounts = useMemo(() => {
    const countWords = (text) => (text ? text?.split(/\s+/)?.length : 0);
    const wordCounts = content?.map((item) => countWords(item));
    const totalWordCount = wordCounts?.reduce(
      (acc, current) => acc + current,
      0
    );
    return isActiveBtn ? totalWordCount : "0";
  }, [content, isActiveBtn]);

  useEffect(() => {
    if (count >= content.length) return;
    setCompletedTyping(false);
    setDisplayResponse((prev) => [...prev, { count, str: "" }]);

    let i = 0;
    const intervalId = setInterval(() => {
      setDisplayResponse((prev) => {
        const newDisplayResponse = [...prev];
        newDisplayResponse[count] = { count, str: str.slice(0, i) };
        return newDisplayResponse;
      });
      i++;

      if (containerRef.current) {
        containerRef.current.scrollTop = containerRef.current.scrollHeight;
      }

      if (i > str.length) {
        clearInterval(intervalId);
        setCompletedTyping(true);
        setCount((prev) => prev + 1);
      }
    }, 5);

    return () => clearInterval(intervalId);
  }, [str, count]);

  return (
    <Box className="text-editor-container">
      <Paper elevation={3} className="my-4 text-genrated">
        <div className="gen-editor-content" ref={containerRef}>
          {displayResponse.length === 0 || content?.length === 0
            ? !completedTyping && <CursorSVG />
            : displayResponse.map((item, index) => (
                <React.Fragment key={index}>
                  <span
                    className="tiptap"
                    dangerouslySetInnerHTML={{ __html: item.str }}
                  />
                </React.Fragment>
              ))}
          {!isActiveBtn && completedTyping && <CursorSVG />}
        </div>
      </Paper>

      <Paper elevation={3} className="my-4 text-genrated-for">
        <div className="">
          <div className="d-flex flex-column mb-5">
            <h3 className="text-head text-center mb-3">Title</h3>
            <span className="text-head-val text-center">{title}</span>
          </div>

          <h3 className="mb-3 text-head">
            Keyword: <span className="text-head-val">{keyword}</span>
          </h3>

          <h3 className="mb-3 text-head">
            Word Count: <span className="text-head-val">{wordCounts}</span>
          </h3>

          <h3 className="mb-2 mt-4 heading-medium">
            <span
              className="heading-small"
              style={{ fontWeight: "600", textDecoration: "underline" }}
            ></span>
          </h3>
        </div>
        <div>
          <button
            className="ms-auto d-flex editor-btn"
            onClick={() => handleTextEditor()}
            disabled={!isActiveBtn}
          >
            <OpenEditor />
            Open Editor
          </button>
        </div>
      </Paper>
    </Box>
  );
};

const CursorSVG = () => (
  <svg
    viewBox="8 4 8 16"
    xmlns="http://www.w3.org/2000/svg"
    className="ai-writer-cursor"
  >
    <rect x="10" y="6" width="4" height="18" fill="black" />
  </svg>
);

export default ChatGPTTypingAnimation;