import React from "react";
import RanksProLogo from "../../../../Mycomponent/Assets/site-audit/RanksProLogo.png";
import {
  Page,
  Text,
  View,
  Document,
  StyleSheet,
  Image,
  Link,
  PDFDownloadLink,
} from "@react-pdf/renderer";
import { FiDownload } from "react-icons/fi";
import { getCurrentYear } from "../../../share/helper-js/current-year";
import { useNavigate } from "react-router-dom";


const styles = StyleSheet.create({
  page: {
    paddingHorizontal: 20,
    flexDirection: "column",
    paddingTop: 30,
    paddingBottom: 80, // Set vertical padding to 30 pixels
    borderStyle: "solid",
  },
  header: {
    paddingBottom:20
  },
  footer: {
    fontSize: 12,
    textAlign: "center",
    color: "grey",
    position: "absolute",
    bottom: 20,
    left: 0,
    right: 0,
  },
  logo: {
    width: 250,
  },
  title: {
    fontSize: 24,
    textAlign: "center",
    marginBottom: 20,
  },

  tableContainer: {
    marginBottom: 20,
  },
  table: {
    width: "100%",
    borderStyle: "solid",
    borderWidth: 1,
    display: "flex", // Use flex to create a flexible row layout
    flexDirection: "column", // Each table row is a flex column
  },
  tableRow: {
    display: "flex",
    flexDirection: "row", // Each cell is a flex item in a row
    borderBottomWidth: 1,
    borderColor: "#000",
    alignItems: "center",
  },
  keywordLoc: {
    flex: 1, // Each cell should take equal space within the row
    padding: 5,
    borderRightWidth: 1,
    borderColor: "#000",
    justifyContent: "start",
    minHeight: 40,
    height: "100%",
    alignItems: "start",
    flexDirection: "column",
  },
  tableCell: {
    flex: 1, // Each cell should take equal space within the row
    padding: 5,
    borderRightWidth: 1,
    borderColor: "rgb(221, 221, 221)",
    justifyContent: "center",
    alignItems: "center",
    flexDirection: "row",
  },
  fixedWidthCell: {
    maxWidth: 80, // Disable flex for fixed width cells
  },
  cardContainer: {
    flexDirection: "row",
    flexWrap: "wrap", // Enable flex wrapping
    justifyContent: "space-between", // Arrange cards with equal spacing
    marginTop: 10,
  },
  card: {
    flexDirection: "row",
    justifyContent: "space-between",
    width: 180,
    backgroundColor: "#FFFFFF",
    padding: 10,
    borderRadius: 5,
    marginBottom: 20,
  },
  image: {
    width: 30,
    height: 40,
  },
  title: {
    fontSize: 11,
    fontWeight: "bold",
    marginBottom: 5,
    color: "#6c757d",
  },
  description: {
    fontSize: 16,
  },
  imageContainer: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  arrowIcon: {
    width: 20,
    height: 20,
    objectFit: "contain",
  },
  view_button: {
    borderWidth: 1,
    borderColor: "#365486",
    padding: 5,
    borderRadius: 7,
    color: "#365486",
    fontSize: 11,
  },
  keyword_bold: {
    fontSize: 13,
    fontWeight: "bold",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  keyword: {
    fontSize: 11,
    color: "#6c757d",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  rank: {
    fontSize: 11,
    color: "#6c757d",
    textAlign: "center",
    marginRight: 10,
  },
  table_cell: {
    fontSize: 7,
    color: "#6c757d",
    textAlign: "center",
    marginVertical: 5,
  },
  table_cell_url: {
    fontSize: 7,
    color: "blue",
    textAlign: "center",
    marginVertical: 5,
    overflow: "hidden",
  },
  table_header: {
    fontSize: 9,
    textAlign: "center",
  },
  pageNumber: {
    position: "absolute",
    fontSize: 11,
    bottom: 45,
    left: 0,
    right: 0,
    textAlign: "center",
    color: "grey",
  },
  link_text: {
    fontSize: 7,
    textDecoration: "none",
    textOverflow: "hidden",
    textAlign: "start",
  },
  fixedWidthCell: {
    width: 100,
    padding: 5,
    borderRightWidth: 1,
    borderColor: "rgb(221, 221, 221)",
    justifyContent: "center",
    alignItems: "center",
    flexDirection: "row",
  },
  logoHead: {
    width: 125,
  },
});





export const PdfFile = ({currentPageTableData,domainName,pdfNewData}) =>{ 

  
  const Data = JSON.parse(sessionStorage.getItem("pdfSessionData"));
  const Web_url = Data ? Data?.domainName : domainName;
  const sorted_Table_Data = Data ? Data?.currentPageTableData : currentPageTableData;

  const image_logo = pdfNewData
    ? pdfNewData?.reset
      ? RanksProLogo
      : pdfNewData?.logo?.src
    : RanksProLogo;



  return(
  <Document>
    <Page size={"A4"} style={styles.page}>
      <View
        style={{
          alignItems: "center",
          display: "flex",
          height: "82vh",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <Image style={styles.logo} src={image_logo} />
        <Text style={{ marginBottom: 20 }}>Backlinks</Text>
        <Text style={styles.title}>Domain: {Web_url}</Text>
      </View>


</Page>
<Page size={"A4"} style={styles.page}>
<View
          style={{
            ...styles.header,
            alignItems: pdfNewData?.reset
              ? "flex-start"
              : pdfNewData?.logo.position,
          }}
          fixed
        >
          <Image style={styles.logoHead} src={image_logo} />
        </View>
      <View style={{ ...styles.tableContainer }}>
        <View style={styles.table}>
          <View style={styles.tableRow}>
            
            <View style={{ ...styles.fixedWidthCell, backgroundColor: "#f4f4f4" }}>
              <Text style={styles.table_header}>url | target page</Text>
            </View>
            <View style={{ ...styles.tableCell, backgroundColor: "#f4f4f4" }}>
              <Text style={styles.table_header}>DR</Text>
            </View>
            <View style={{ ...styles.tableCell, backgroundColor: "#f4f4f4" }}>
              <Text style={styles.table_header}>PR</Text>
            </View>
            <View style={{ ...styles.tableCell, backgroundColor: "#f4f4f4" }}>
              <Text style={styles.table_header}>SS</Text>
            </View>
            <View style={{ ...styles.tableCell, backgroundColor: "#f4f4f4" }}>
              <Text style={styles.table_header}>anchor text</Text>
            </View>
            <View style={{ ...styles.tableCell, backgroundColor: "#f4f4f4" }}>
              <Text style={styles.table_header}>first sceen</Text>
            </View>
            <View style={{ ...styles.tableCell, backgroundColor: "#f4f4f4" }}>
              <Text style={styles.table_header}>last scene</Text>
            </View>
          </View>
          <View>
            {sorted_Table_Data?.map((item, index) => (
              <View style={styles.tableRow} key={`table-row-${index}`}>
                
                <View style={{ ...styles.fixedWidthCell }}>
                  <View style={{
                        ...styles.tableCell,
                        border: "none",
                        display: "flex",
                        flexFlow: "column",
                        flexDirection: "column",
                      }} >
                    <Text
                      style={{
                        ...styles.table_cell,
                        fontSize: 7,
                        textAlign: "left",
                      }}
                    >
                      {item?.page_from_title}
                    </Text>
                    <Text
                      style={{
                        ...styles.table_cell_url,
                        fontSize: 7,
                        textAlign: "left",
                      }}
                    >
                      Source: {item?.url_from}
                    </Text>
                    <Text
                      style={{
                        ...styles.table_cell_url,
                        fontSize: 7,
                        textAlign: "left",
                      }}
                    >
                      Target: {item?.url_to}
                    </Text>
                  </View>
                </View>
                <View style={styles.tableCell}>
                  <Text style={styles.table_cell}>
                    {Math.round(item?.domain_authority / 10) < 5
                      ? 0
                      : Math.round(item?.domain_authority / 10) || 0}
                  </Text>
                </View>
                <View style={styles.tableCell}>
                  <Text style={styles.table_cell}>
                    {Math.round(item?.page_authority / 10) < 5
                      ? 0
                      : Math.round(item?.page_authority / 10) || 0}
                  </Text>
                </View>
                <View style={styles.tableCell}>
                  <Text style={styles.table_cell}>
                    {`${item?.backlink_spam_score}%`}
                  </Text>
                </View>
                <View style={styles.tableCell}>
                  <View>
                    <Text
                      style={{
                        ...styles.table_cell,
                        fontSize: 7,
                        textAlign: "left",
                      }}
                    >
                      {item?.anchor}
                    </Text>
                    <Text style={styles.table_cell}>
                      {item.dofollow ? "Follow" : "No Follow"}
                    </Text>
                  </View>
                </View>
                <View style={styles.tableCell}>
                  <Text style={styles.table_cell}>
                    {item?.first_seen.split(" ", 1)}
                  </Text>
                </View>
                <View style={styles.tableCell}>
                  <Text style={styles.table_cell}>
                    {item?.last_seen.split(" ", 1)}
                  </Text>
                </View>
              </View>
            ))}
          </View>
        </View>
      </View>

      <Text style={styles.footer} fixed>
      {pdfNewData?.reset
            ? `Copyright ${getCurrentYear} eSearch Logix | Prepared using RanksPro.io`
            : pdfNewData?.footer}
      </Text>

    </Page>
  </Document>
)};






const BackLinksPdfModal = ({
  currentPageTableData,
  targetBacklinksTable,
  isFilterOpen,
  diffStyle,
  domainName,
}) => {
 



  const navigate = useNavigate()


  const handleRoute =()=>{
    sessionStorage.setItem('pdfSessionData',JSON.stringify({currentPageTableData:currentPageTableData,domainName:domainName,id:7}))
    setTimeout(() => {
      navigate("/CrawledPageEditor");
    }, 500)
     
    }
  return (
    <div>
      <div className="d-flex justify-content-end" style={{    padding: "5px 100px"}}>
      <FiDownload className="down" onClick={handleRoute} title="Backlinks PDF" aria-label="Close" data-bs-dismiss="modal"/>
   
      </div>
      <div className="mt-3 custom-table-container">
      <div
          style={{ width: "100%", height: "20vh" }}
          className="pg-2 d-flex justify-content-center align-items-center flex-column"
        >
          <img src={RanksProLogo} alt="" style={{ width: "300px" }} />
        </div>
        <table className="backlink-table" ref={targetBacklinksTable}>
          <thead>
            <tr
              className="table-sticky"
              style={{
                position: isFilterOpen ? "static" : "sticky",
              }}
            >
              <th scope="col" style={{ width: "120px" }}>
                <span className="point">DR</span>
              </th>
              <th scope="col" className="t_left ">
                Source page title & url | target page
              </th>

              <th scope="col" style={{ width: "120px" }}>
                <span className="point">PR</span>
              </th>
              <th scope="col" style={{ width: "120px" }}>
                <span className="point">ss</span>
              </th>
              <th scope="col" style={{ width: "230px" }}>
                anchor text
              </th>
              <th scope="col" style={{ width: "170px" }}>
                <span className="point">first seen</span>
              </th>
              <th scope="col" style={{ width: "170px" }}>
                <span className="point">last seen</span>
              </th>
            </tr>
          </thead>
          <tbody>
            {currentPageTableData?.map((item, index) => {
              return (
                <tr
                  key={`blacklink-table-list-${index}-${item?.page_from_title}`}
                >
                  <td>
                    {Math.round(item?.domain_authority / 10) < 5
                      ? 0
                      : Math.round(item?.domain_authority / 10) || 0}
                  </td>

                  <td className="t_left table_cell">
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        height: "90px",
                      }}
                      className="ellipsis justify-content-center"
                    >
                      <span className="blacklink-table-span">
                        <a
                          href={item?.url_from}
                          className="w-100"
                          target="_blank"
                          style={{
                            color: "#000000E5",
                            paddingLeft: "0px",
                          }}
                          rel="noopener noreferrer"
                        >
                          {item?.page_from_title}
                        </a>
                      </span>
                      <span className="blacklink-table-span">
                        <span className="backlink-table-head mt-0">
                          Source:
                        </span>
                        <a
                          href={item?.url_from}
                          target="_blank"
                          className=""
                          rel="noopener noreferrer"
                        >
                          <span>{item?.url_from}</span>
                        </a>
                      </span>

                      <span className="blacklink-table-span">
                        <span className="backlink-table-head mt-0">
                          Target:
                        </span>
                        <a
                          href={item?.url_to}
                          target="_blank"
                          className=""
                          rel="noopener noreferrer"
                        >
                          <span>{item?.url_to}</span>
                        </a>
                      </span>
                    </div>
                  </td>

                  <td>
                    {Math.round(item?.page_authority / 10) < 5
                      ? 0
                      : Math.round(item?.page_authority / 10) || 0}
                  </td>
                  <td className="table_cell vert-alg ">
                    <span
                      className="spamscores-table text-white"
                      style={diffStyle(item?.backlink_spam_score)}
                    >
                      {`${item?.backlink_spam_score}%`}
                    </span>
                  </td>
                  <td className="table_cell vert-alg ">
                    <div className="d-flex flex-column">
                      <span
                        className="table-cell-value "
                        style={{
                          width: "fit-content",
                        }}
                      >
                        {item?.anchor}
                      </span>
                      <span
                        className={
                          item.dofollow
                            ? "follow-btn py-1 px-3"
                            : "do-follow-btn py-1 px-3"
                        }
                      >
                        {item.dofollow ? "Follow" : "No Follow"}
                      </span>
                    </div>
                  </td>
                  <td>{item?.first_seen.split(" ", 1)}</td>
                  <td>{item?.last_seen.split(" ", 1)}</td>
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default BackLinksPdfModal;
