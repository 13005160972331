import React from "react";
import lIMG from "../Assets/seoimg/logo.png";

const Loader = () => {
  return (
    <div className="">
      <div
        className="cmc"
        style={{
          position: "fixed",
          top: "0",
          left: "0",
          height: "100vh",
          width: "100vw",
          background: "#fff",
        }}
      >
        <div role="status" style={{ position: "relative" }}>
          <img
            style={{
              width: "250px",
              animation: " infinite alternate",
              marginTop: "-5rem",
            }}
            src={lIMG}
            alt="loading-image"
          />
          <span className="sr-only"></span>
        </div>
      </div>
      <DottLoader />
    </div>
  );
};

export default Loader;

export const DottLoader1 = () => {
  return (
    <>
      <div
        style={{
          color: "#F3A344",
          width: "0.8rem",
          height: "0.8rem",
          animation: " .70s linear infinite spinner-grow",
        }}
        className="spinner-grow"
        role="status"
      >
        <span className="sr-only"></span>
      </div>
      <div
        style={{
          color: "#365486",
          width: "0.9rem",
          height: "0.9rem",
          animation: " .75s linear infinite spinner-grow",
        }}
        className="spinner-grow"
        role="status"
      >
        <span className="sr-only"></span>
      </div>
      <div
        style={{
          color: "#E95655",
          width: "1rem",
          height: "1rem",
          animation: " .80s linear infinite spinner-grow",
        }}
        className="spinner-grow"
        role="status"
      >
        <span className="sr-only"></span>
      </div>
    </>
  );
};

export const DottLoader = () => {
  return (
    <>
      <div
        className="cmc"
        style={{
          position: "fixed",
          top: "0",
          left: "0",
          height: "100vh",
          width: "100vw",
        }}
      >
        <div
          style={{
            color: "#F3A344",
            width: "0.8rem",
            height: "0.8rem",
            animation: " .70s linear infinite spinner-grow",
          }}
          className="spinner-grow"
          role="status"
        >
          <span className="sr-only"></span>
        </div>
        <div
          style={{
            color: "#365486",
            width: "0.9rem",
            height: "0.9rem",
            animation: " .75s linear infinite spinner-grow",
          }}
          className="spinner-grow"
          role="status"
        >
          <span className="sr-only"></span>
        </div>
        <div
          style={{
            color: "#E95655",
            width: "1rem",
            height: "1rem",
            animation: " .80s linear infinite spinner-grow",
          }}
          className="spinner-grow"
          role="status"
        >
          <span className="sr-only"></span>
        </div>
      </div>
    </>
  );
};
