import { Routes, Route, Navigate } from "react-router-dom";
import Login from "../Mycomponent/pages/login/login";
import Layout from "../Layout";
import Home from "../Mycomponent/pages";
import RankTracking from "../Mycomponent/pages/rankTracking";
import Register from "../Mycomponent/pages/login/register";
import PlLogin from "../Mycomponent/pages/login/plLogin";
import AddProject from "../Mycomponent/pages/AddProject/addProject";
import { AddWebsite } from "../Mycomponent/pages/AddProject/addwebsite";
import { AddCountry } from "../Mycomponent/pages/AddProject/addLocation";
import { AddKeyword } from "../Mycomponent/pages/AddProject/addKeyword";
import Upgrade from "../Mycomponent/pages/upgrade";
import Forgot from "../Mycomponent/pages/login/forgot";
import Keywords from "../Mycomponent/pages/keywords";
import NoPage from "../Mycomponent/pages/login/noPage";
import Profile from "../Mycomponent/pages/profile";
import GrowTraffic from "../Mycomponent/pages/AddProject/growTraffic";
import GoogleData from "../Mycomponent/pages/google-data";
import {
  addcompetitors,
  inviteemail,
  addcountry,
  addkeyword,
  addpr,
  addwebsite,
  agencydetail,
  admin,
  adminkeyword,
  backlinks,
  codes,
  dashboard,
  feedback,
  forgot,
  forgotPath,
  gotraffic,
  home,
  backlink_smtp,
  keywords,
  login,
  mail,
  nopage,
  overview,
  plans,
  pllogin,
  profile,
  rank,
  register,
  register_InvitePath,
  siteaudit,
  traffic,
  upgrade,
  users,
  digitalAgency,
  agencyform,
  aiarticle,
  seokeyword,
  aiprojects,
  affiliateReward,
  register_Affiliate,
  backlinksAnchor,
  ViewAllListBackLink,
  ViewAllList,
  backlinkChecker,
  seoTool,
  siteOverViewChecker,
  rankChecker,
  freeTool,
  freeTool_TableRankTracker,
  freeTool_TablePageVital,
  freeTool_TablebackLink,
  ai_CaptionGen,
  register_sumo,
  login_sumo,
  forget_sumo,
  referral_user,
  affiliateList,
  digital_agency,
  single_agency,
  review,
  google_data,
  backlink_gap,
  contact_agency,
  register_appsumo,
  project,
  guest_rank,
  Crawled_Page_Editor,
} from "./constant";
import Admin from "../admin/admin";
import User from "../admin/pages/user";
import Keyword from "../admin/pages/keyword";
import Plans from "../admin/pages/plans";
import Codes from "../admin/pages/codes";
import Siteaudit from "../Mycomponent/pages/siteaudit";
import Traffic from "../Mycomponent/pages/traffic";
import Dashboard from "../admin/pages/dashboard";
import AddCompetitors from "../Mycomponent/pages/AddProject/addCompetitors";
import { useSelector } from "react-redux";
import PlAdd from "../Mycomponent/pages/login/plAdd";
import Mail from "../admin/pages/mail";
import DomainOverview from "../Mycomponent/pages/DmainOverview";
import FeedBack from "../admin/pages/feedback";
import DigitalAgency from "../Mycomponent/pages/DigitalAgencey/digitalAgency";
import SeoAgencydetail from "../Mycomponent/pages/DigitalAgencey/seoAgencydetail";
import AIArticle from "../Mycomponent/share/components/ai-article.component";
import SEOKeyword from "../Mycomponent/share/components/seo-keyword.component";

import { useEffect } from "react";
import Digital_agenceyForm from "../Mycomponent/pages/DigitalAgencey/digital_agenceyForm";
// import AiProject from "../Mycomponent/pages/aiProject/aiProject";
import AiProject from "../Mycomponent/pages/aiProject/aiProject";
// import { isPlain } from "@reduxjs/toolkit";
import PlUpgrade from "../Mycomponent/pages/login/plUpgrade";
import InviteEmail from "../admin/pages/invitemail";
import SeoImpact from "../Tools/SeoImpact/SeoImpact";
import KeywordChecker from "../Mycomponent/pages/Tools/rank-checker";
import SiteOverView from "../Mycomponent/pages/Tools/site-overview";
// import ViewListbackLinks from '../Mycomponent/pages/backlinks/ViewListBackLinks'

import BackLinks from "../Mycomponent/pages/backlinks/backLinks";
import BacklinkSmtp from "../Mycomponent/pages/backlinks/backlink-smtp";
import { ViewList } from "../Mycomponent/pages/keyWordSubFiles/ViewList";
import ViewListBackLinks from "../Mycomponent/pages/backlinks/ViewListBackLinks";
import BacklinkChecker from "../Mycomponent/pages/Tools/backlink-checker";

import ToolUsage from "../admin/pages/ToolsUsage/toolUsage";
import RankTrackerTooltable from "../admin/pages/ToolsUsage/toolTable/RankTrackerTooltable";
import PageVitalToolTable from "../admin/pages/ToolsUsage/toolTable/PageVitalToolTable";
import BackLinkToolTable from "../admin/pages/ToolsUsage/toolTable/BackLinkToolTable";
import AiGenerateCaption from "../AiCaptionGenerator/AiGenerateCaption";
import seoMetaData from "../seo/seo-meta-data";
import SeoManager from "../seo/seo-manager";

// affiliate
import AffiliateLayout from "../Affiliate";
import ReferralUser from "../Mycomponent/pages/login/referral-user";
import { AffilateApprove } from "../admin/pages/AffilateApprove";
import AffiliateReward from "../admin/pages/affiliate";
import AffiliateWithdrawal from "../admin/pages/affiliate-withdrawal";
import RegisterAppsumo from "../Mycomponent/pages/login/appsumo-register";

import {
  affiliate,
  LOGIN,
  SIGNUP,
  FORGOT,
  VERIFY,
  SIGNUPREFER,
  affiliate_notification,
  payment_earnings,
  aff_dashboard,
  promotional,
  PROFILE,
  performance_metrics,
  affiliate_withdrawal,
  update_agency,
} from "./constant";
import Affiliate from "../Affiliate/pages/home.page/home";
import { AffiliateLogin } from "../Affiliate/pages/login/Login";
import { Forget } from "../Affiliate/pages/login/Forget";
import { Signup } from "../Affiliate/pages/login/Signup";
import AffiliateNotification from "../Affiliate/pages/affiliate-notification";
import PaymentAndEarning from "../Affiliate/pages/payment-and-earning/payment-and-earning";
import AffilateDashboard from "../Affiliate/pages/dashboard/dashboard";
import Promotional from "../Affiliate/pages/promotional/promotional";
import { AffiliateProfile } from "../Affiliate/pages/Profile/Profile";
import Performance from "../Affiliate/pages/performance/performance";
import { Verify } from "../Affiliate/pages/login/Verify";
import { Digital_agency } from "../admin/pages/digital-agencies/Digital_agency";
import UpdateAgencies from "../admin/pages/digital-agencies/update-agencies";
import Contact from "../admin/pages/digital-agencies/contact";
import { Single_agency } from "../admin/pages/Single_agency";
import TermAndCondition from "../Affiliate/pages/term & condition/terms-and-conditions";
import AddOnPlan from "../admin/pages/Add-on-plans/addon";

// pdf viewer
import PdfViewer from "../Mycomponent/pages/pdf-viewer";
import Reviews from "../admin/pages/Reviewa";
import BacklinkGap from "../Mycomponent/pages/backlinks/backlink-gap";
import Projects from "../Mycomponent/pages/Project-Section/projects";
import CrawledPagesEditor from "../Mycomponent/pages/pdfEditors/CrawledPagesEditor";

import GuestLayout from "../Layout/GuestLayout";

const RouterConfrigration = () => {
  const isproject = useSelector((state) => state.isproject);
  const loginOut = useSelector((state) => state.loginout);
  const isplan = useSelector((state) => state.isplan);

  return (
    <Routes>
      {/* for unknown path  */}
      <Route path={pllogin} element={<PlLogin />} />
      <Route path="/" element={<Navigate to="/dashboard" />} />

      <Route
        path={nopage}
        element={
          <SeoManager data={seoMetaData?.page_not_found}>
            <NoPage />
          </SeoManager>
        }
      />

      <Route
        path={login}
        element={
          <SeoManager data={seoMetaData?.login}>
            <Login />
          </SeoManager>
        }
      />

      {/* register by mail url path */}
      <Route
        path={register_InvitePath}
        element={
          <SeoManager data={seoMetaData?.register}>
            <Register />
          </SeoManager>
        }
      />
      {/* appsumo register page */}
      {/* <Route
        path={register_appsumo}
        element={
          <SeoManager data={seoMetaData?.register}>
            <RegisterAppsumo />
          </SeoManager>
        }
      /> */}

      <Route
        path={register}
        element={
          <SeoManager data={seoMetaData?.register}>
            <Register />
          </SeoManager>
        }
      />

      {/* forgot by mail url path */}
      <Route
        path={forgotPath}
        element={
          <SeoManager data={seoMetaData?.forgot}>
            <Forgot />
          </SeoManager>
        }
      />
      <Route
        path={forgot}
        element={
          <SeoManager data={seoMetaData?.forgot}>
            <Forgot />
          </SeoManager>
        }
      />

      <Route
        path={"/"}
        element={
          <SeoManager>
            <Layout />
          </SeoManager>
        }
      >
        {/* main pages  */}

        <Route
          path={project}
          index
          element={
            !isplan ? (
              <PlUpgrade />
            ) : isproject ? (
              <SeoManager data={seoMetaData?.home}>
                <Projects />
              </SeoManager>
            ) : !loginOut ? (
              <PlLogin />
            ) : (
              <PlAdd />
            )
          }
        />

        <Route
          path="/dashboard"
          element={
            !isplan ? (
              <PlUpgrade />
            ) : isproject ? (
              <SeoManager data={seoMetaData?.home}>
                <Home />
              </SeoManager>
            ) : !loginOut ? (
              <PlLogin />
            ) : (
              <PlAdd />
            )
          }
        />

        <Route
          path={google_data}
          element={
            !isplan ? (
              <PlUpgrade />
            ) : isproject ? (
              <SeoManager data={seoMetaData?.home}>
                <GoogleData />
              </SeoManager>
            ) : !loginOut ? (
              <PlLogin />
            ) : (
              <PlAdd />
            )
          }
        />

        <Route
          path={rank}
          element={
            !isplan ? (
              <PlUpgrade />
            ) : isproject ? (
              <SeoManager data={seoMetaData?.rank_tracking}>
                <RankTracking />
              </SeoManager>
            ) : !loginOut ? (
              <PlLogin />
            ) : (
              <PlAdd />
            )
          }
        />

        <Route
          path="/pdf-editor"
          element={
            !isplan ? (
              <PlUpgrade />
            ) : isproject ? (
              <PdfViewer />
            ) : !loginOut ? (
              <PlLogin />
            ) : (
              <PlAdd />
            )
          }
        />

<Route path={Crawled_Page_Editor} element={<CrawledPagesEditor/>}/>

        <Route
          path={aiarticle}
          element={
            !isplan ? (
              <PlUpgrade />
            ) : isproject ? (
              <AIArticle />
            ) : !loginOut ? (
              <PlLogin />
            ) : (
              <PlAdd />
            )
          }
        />
        <Route
          path={aiprojects}
          element={!isplan ? <PlUpgrade /> : <AiProject />}
        />
        <Route
          path={siteaudit}
          element={
            !isplan ? (
              <PlUpgrade />
            ) : isproject ? (
              <SeoManager data={seoMetaData?.site_audit}>
                <Siteaudit />
              </SeoManager>
            ) : !loginOut ? (
              <PlLogin />
            ) : (
              <PlAdd />
            )
          }
        />
        <Route
          path={overview}
          element={
            !isplan ? (
              <PlUpgrade />
            ) : loginOut ? (
              <SeoManager data={seoMetaData?.site_overview}>
                <DomainOverview />
              </SeoManager>
            ) : (
              <PlLogin />
            )
          }
        />
        <Route
          path={seokeyword}
          element={
            !isplan ? <PlUpgrade /> : loginOut ? <SEOKeyword /> : <PlLogin />
          }
        />

        <Route
          path={backlink_gap}
          element={
            !isplan ? (
              <PlUpgrade />
            ) : (
              <SeoManager data={seoMetaData?.backlink}>
                <BacklinkGap />
              </SeoManager>
            )
          }
        />
        <Route
          path={backlinks}
          element={
            !isplan ? (
              <PlUpgrade />
            ) : (
              <SeoManager data={seoMetaData?.backlink}>
                <BackLinks />
              </SeoManager>
            )
          }
        />

        <Route
          path={keywords}
          element={
            !isplan ? (
              <PlUpgrade />
            ) : (
              <SeoManager data={seoMetaData?.keyword}>
                <Keywords />
              </SeoManager>
            )
          }
        />
        <Route
          path={ViewAllList}
          element={!isplan ? <PlUpgrade /> : <ViewList />}
        />

        <Route
          path={ViewAllListBackLink}
          element={!isplan ? <PlUpgrade /> : <ViewListBackLinks />}
        />

        <Route
          path={backlink_smtp}
          element={
            !isplan ? (
              <PlUpgrade />
            ) : (
              <SeoManager data={seoMetaData?.backlink}>
                <BacklinkSmtp />
              </SeoManager>
            )
          }
        />

        {/* add project layout  */}
        <Route path={addpr} element={loginOut ? <AddProject /> : <PlLogin />}>
          <Route index element={<AddWebsite />} />
          <Route path={addwebsite} element={<AddWebsite />} />
          <Route path={addcompetitors} element={<AddCompetitors />} />
          <Route path={addcountry} element={<AddCountry />} />
          <Route path={addkeyword} element={<AddKeyword />} />
          <Route path={gotraffic} element={<GrowTraffic />} />
        </Route>
        {/* add project layout  */}

        {/* header pages  */}
        <Route
          path={profile}
          element={
            loginOut ? (
              <SeoManager data={seoMetaData?.account}>
                <Profile />
              </SeoManager>
            ) : (
              <PlLogin />
            )
          }
        />

        <Route
          path={upgrade}
          element={
            loginOut ? (
              <SeoManager data={seoMetaData?.upgrade}>
                <Upgrade />
              </SeoManager>
            ) : (
              <PlLogin />
            )
          }
        />
      </Route>

      {/* Guest link*/}
      <Route path={guest_rank} element={<GuestLayout />}>
        <Route
          index
          element={
            <SeoManager data={seoMetaData?.rank_tracking}>
              <RankTracking />
            </SeoManager>
          }
        />
      </Route>

      {/* here the Admin Layout  */}
      <Route path={admin} element={<Admin />}>
        <Route index element={<Dashboard />} />
        <Route path={dashboard} element={<Dashboard />} />
        <Route path={users} element={<User />} />
        <Route path={adminkeyword} element={<Keyword />} />
        <Route path={plans} element={<Plans />} />
        <Route path={codes} element={<Codes />} />
        <Route path={mail} element={<Mail />} />
        <Route path={feedback} element={<FeedBack />} />
        <Route path={affiliateReward} element={<AffiliateReward />} />
        <Route path={inviteemail} element={<InviteEmail />} />
        {/*  Tools For admin */}
        <Route path={freeTool} element={<ToolUsage />} />
        <Route
          path={freeTool_TableRankTracker}
          element={<RankTrackerTooltable />}
        />
        <Route
          path={freeTool_TablePageVital}
          element={<PageVitalToolTable />}
        />
        <Route path={freeTool_TablebackLink} element={<BackLinkToolTable />} />
        {/* <Route path={freeTool_TablebackLink} element={<BackLinkToolTable />} /> */}
        <Route path={affiliateList} element={<AffilateApprove />} />
        <Route path={affiliate_withdrawal} element={<AffiliateWithdrawal />} />
        {/* Digital Agency  */}
        <Route path={digital_agency} element={<Digital_agency />} />
        <Route path={update_agency} element={<UpdateAgencies />} />
        <Route path={contact_agency} element={<Contact />} />
        <Route path={single_agency} element={<Single_agency />} />
        <Route path={review} element={<Reviews />} />
        <Route path="addon-plans" element={<AddOnPlan />} />
      </Route>

      {/* here the Affiliate Layout start */}
      <Route
        path="affiliate/"
        element={
          <SeoManager data={seoMetaData?.affiliate}>
            <Affiliate />
          </SeoManager>
        }
      />
      <Route
        path={LOGIN}
        element={
          <SeoManager data={seoMetaData?.aff_login}>
            <AffiliateLogin />
          </SeoManager>
        }
      />
      <Route
        path={FORGOT}
        element={
          <SeoManager data={seoMetaData?.aff_forgot}>
            <Forget />
          </SeoManager>
        }
      />
      <Route
        path={SIGNUP}
        element={
          <SeoManager data={seoMetaData?.aff_signup}>
            <Signup />
          </SeoManager>
        }
      />
      <Route path={VERIFY} element={<Verify />} />
      <Route
        path={register_Affiliate}
        element={
          <SeoManager data={seoMetaData?.register}>
            <ReferralUser />
          </SeoManager>
        }
      />

      <Route path={affiliate} element={<AffiliateLayout />}>
        <Route index path={dashboard} element={<AffilateDashboard />} />
        <Route
          path={affiliate_notification}
          element={<AffiliateNotification />}
        />
        <Route path={performance_metrics} element={<Performance />} />
        <Route path={payment_earnings} element={<PaymentAndEarning />} />
        <Route path={promotional} element={<Promotional />} />
        <Route path={PROFILE} element={<AffiliateProfile />} />
      </Route>
      <Route
        path={"terms-&-conditions"}
        element={
          <SeoManager data={seoMetaData?.term_and_condition}>
            <TermAndCondition />
          </SeoManager>
        }
      />
      {/* here the Affiliate Layout ends */}

      {/* seo tool route free checker */}
      <Route
        path={siteOverViewChecker}
        element={
          <SeoManager data={seoMetaData?.free_site_overview}>
            <SiteOverView />
          </SeoManager>
        }
      />

      <Route
        path={backlinkChecker}
        element={
          <SeoManager data={seoMetaData?.backlink_checker}>
            <BacklinkChecker />
          </SeoManager>
        }
      />
      <Route
        path={seoTool}
        element={
          <SeoManager data={seoMetaData?.seo_insight}>
            <SeoImpact />
          </SeoManager>
        }
      />
      <Route
        path={rankChecker}
        element={
          <SeoManager data={seoMetaData?.rank_checker}>
            <KeywordChecker />
          </SeoManager>
        }
      />

      {/*  AI CAPTION GENERATE TOOL  */}
      <Route
        path={ai_CaptionGen}
        element={
          <SeoManager data={seoMetaData?.ai_caption}>
            <AiGenerateCaption />
          </SeoManager>
        }
      />
    </Routes>
  );
};

export default RouterConfrigration;
