import React from "react";
import { Box } from "@mui/material";

const Loader = () => {
  return (
    <Box
      sx={{ display: "flex", minHeight: 250, minWidth: 250 }}
      className="w-100 h-100 cad"
    >
      <div
        className="spinner-border"
        role="status"
        style={{ color: "#365486", width: "3rem", height: "3rem" }}
      >
        <span className="sr-only"></span>
      </div>
    </Box>
  );
};

export default Loader;
