import React from "react";
import { useEffect } from "react";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useLocation, useNavigate } from "react-router-dom";
import ProjectStepper from "./project-stepper";
import backIcon from "../../Assets/common/back.svg";
import CountryList from "../../../helperJs/country-list";

export const AddWebsite = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const location_data = CountryList(); // *important for loading location data
  const [websiteURL, setWebsiteUrl] = useState("");
  const [websiteName, setWebsiteName] = useState("");
  const [FilteredUrl, setFilteredUrl] = useState();
  const [valida, setValida] = useState("");
  const userallprojectname = useSelector((state) => state.userallprojectname);
  const userProjectlimit = useSelector((state) => state.userprojectlimit);
  const USERALLPROJECTLength = useSelector((state) => state.userprojectlength);
  const [ShowAlert, setShowAlert] = useState(false);

  // LocalStorage
  useEffect(() => {
    const LocalData = JSON.parse(localStorage.getItem("ProjectsDetail"));
    if (LocalData != null) {
      setWebsiteName(LocalData.ProjectName);
      setWebsiteUrl(LocalData.domain);
    }
  }, []);

  // authentication lmt for customer Project
  useEffect(() => {
    if (USERALLPROJECTLength >= Number(userProjectlimit)) {
      setShowAlert(true);
    } else {
      setShowAlert(false);
    }
  });

  useEffect(() => {
    const filteredUrl =
      userallprojectname &&
      userallprojectname.filter((res) => {
        if (res === websiteURL) {
          return res;
        } else {
          setValida("");
        }
      });
    setFilteredUrl(filteredUrl[0]);
  }, [websiteURL, websiteName]);

  const Addwebsite = () => {
    const domain = /^[a-zA-Z0-9-]+(?:\.[a-zA-Z]{2,})+$/i.test(websiteURL);
    if (!domain) {
      setValida("please enter valid domain");
    } else if (websiteURL.length === 0) {
      setValida("please provide all the details");
    } else if (websiteName.length === 0) {
      setValida("please provide all the details");
    } else if (FilteredUrl === websiteURL) {
      setValida("This project Url is already exist please add another project");
    } else {
      // when user not subscribe to any plan
      dispatch({ type: "NEWPROJECTURL", payload: websiteURL });
      localStorage.setItem(
        "ProjectsDetail",
        JSON.stringify({ domain: websiteURL, ProjectName: websiteName })
      );
      navigate(`addcountry`);
    }
  };

  return (
    <section className="">
      <div className="cmd">
        <Link to={-1}>
          <button
            className="icon-btn large-btn"
            onClick={() => {
              dispatch({ type: "NEWPROJECTURL", payload: false });
            }}
          >
            <img src={backIcon} alt="back button" />
          </button>
        </Link>
        <ProjectStepper activeStep={0} />
        <button className="icon-btn large-btn" onClick={() => Addwebsite()}>
          <img
            src={backIcon}
            alt="back button"
            style={{
              transform: "scaleX(-1)",
            }}
          />
        </button>
      </div>

      <div className="add-pr-url">
        <div>
          <h1 className="">Add Website</h1>
          <p className="mb-0 proj-tag-line">
            Start tracking and improving your website’s SEO traffic.
          </p>
          <p className="mb-0 proj-tag-line">
            <span>INSTRUCTION: </span> In Website URL add only the domain name
            e.g. domain.com
          </p>
          <div className="row gy-3 add-pro-web-input">
            <div className="col-12 col-md-6 col-lg-6">
              {/* .replace func for anoyoing spaces and .match  for http filetr  */}
              <input
                type="text"
                autoComplete="true"
                value={websiteURL}
                className="heading-small"
                placeholder="Website URL:"
                onChange={(e) => {
                  const url = e.target.value;
                  const match = url.match(
                    /^(?:https?:\/\/)?(?:www\.)?([^/]+)/i
                  );
                  setWebsiteUrl(match ? match[1].replace(/\s/g, "") : "");
                }}
              ></input>
            </div>
            <div className="col-12 col-md-6 col-lg-6">
              <input
                type="text"
                autoComplete="true"
                className="heading-small"
                placeholder="Project Name:"
                value={websiteName}
                onChange={(e) => setWebsiteName(e.target.value)}
              ></input>
            </div>
            {valida ? (
              <p className="vl-msd-line mt-0 text-right heading-small">
                {valida}
              </p>
            ) : null}
          </div>

          <div className="add-pr-btn mt-4">
            <button
              className="btn_new_bg"
              style={{ borderRadius: "5px", minWidth: "130px", height: "50px" }}
              onClick={() => Addwebsite()}
            >
              Next
            </button>
          </div>
        </div>
      </div>
      {ShowAlert ? (
        <div className="pop">
          <div className="popBody">
            <div className="exeMark">
              <h1>?</h1>{" "}
            </div>
            <p className="heading-small">
              Your project limit exceeded if you want to add more projects
              please Upgrade your plan{" "}
            </p>
            <div className="cmd" style={{ justifyContent: "space-evenly" }}>
              <button onClick={() => navigate(-1)} className="cm-btn-b">
                {" "}
                Cancel
              </button>
              <button onClick={() => navigate("/upgrade")} className="cm-btn">
                {" "}
                upgrade
              </button>
            </div>
          </div>
        </div>
      ) : null}
    </section>
  );
};
