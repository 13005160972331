import React, { useState, useEffect } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { Button, Box } from "@mui/material";
import Loader from "../../share/loader";
import TextField from "@mui/material/TextField";
import Paper from "@mui/material/Paper";
import axios from "axios";
import { GET_DIGITAL_AGENCIES } from "../../../services/constants";
import { useFormik, FieldArray } from "formik";
import * as Yup from "yup";
import Dropdown from "../../share/dropdown";
import Chip from "@mui/material/Chip";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import { useSelector } from "react-redux";
import Rating from "@mui/material/Rating";
import { FaStar } from "react-icons/fa";
import Divider from "@mui/material/Divider";
import {
  noOfEmployeeOptions,
  clientBussinessSizeOptions,
  priceModelOptions,
  budgetOptions,
  languageOptions,
  geographicFocusOptions,
  industryOptions,
  serviceOptions,
  agencyCategoryOptions,
} from "./agency-data";
import { UPDATE_AGENCY_DETAIL } from "../../../services/constants";
import AlertPopUp from "../../../Mycomponent/share/components/alert.component";

const UpdateAgencies = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const ID = location?.state;
  const [agencyData, setAgencyData] = useState(null);
  const [email, setEmail] = useState("");
  const [emailData, setEmailData] = useState([]);
  const [mailVarify, setMailvarify] = useState(true);
  const [showReview, setShowReview] = useState(false);
  const EMAIL = useSelector((state) => state?.email);
  const [loaded, setLoaded] = useState(false);
  const [Alert, setAlert] = useState({
    type: "",
    message: "",
    show: false,
  });

  // Services
  const [selectedServices, setSelectedServices] = useState([
    { name: "services", key: "services", data: [], extraData: [] },
    { name: "industry", key: "industry", data: [], extraData: [] },
    {
      name: "geographicFocus",
      key: "geographicFocus",
      data: [],
      extraData: [],
    },
    { name: "languages", key: "languages", data: ["English"], extraData: [] },
    { name: "budget", key: "budget", data: ["Any"], extraData: [] },
    { name: "priceModel", key: "priceModel", data: [], extraData: [] },
    {
      name: "clientBussinessSize",
      key: "clientBussinessSize",
      data: [],
      extraData: [],
    },
    { name: "category", key: "category", data: [], extraData: [] },
  ]);
  // contacts
  const [locations, setLocations] = useState([]);
  const [form, setForm] = useState({
    id: null,
    location: "",
    streetAddress: "",
    postalCode: "",
    PhoneNumber: "",
  });
  //review
  const [reviews, setReviews] = useState([]);
  const [reviewForm, setReviewForm] = useState({
    id: null,
    approve: false,
    review: "",
    rating: "",
    email: "",
  });

  const HandleGetValue = (name, value) => {
    const trimmedValue = value.trim();
    setSelectedServices((prevServices) =>
      prevServices.map((service) => {
        if (service.name === name) {
          if (name === "budget" || name === "languages") {
            return { ...service, data: [trimmedValue] };
          } else {
            const dataSet = new Set(service.data.map((item) => item.trim()));
            if (dataSet.has(trimmedValue)) {
              dataSet.delete(trimmedValue);
            } else {
              dataSet.add(trimmedValue);
            }
            return { ...service, data: Array.from(dataSet) };
          }
        }
        return service;
      })
    );
  };

  // services data list
  const seo_form_services = [
    {
      label: "Services",
      key: "services",
      title: "Select Services",
      data: serviceOptions,
    },
    {
      label: "Industry",
      key: "industry",
      title: "Select Industry",
      data: industryOptions,
    },
    {
      label: "Geographic Focus",
      key: "geographicFocus",
      title: "Select Location",
      data: geographicFocusOptions,
    },
    {
      label: "Languages",
      key: "languages",
      title: selectedServices?.[3]?.data,
      data: languageOptions,
    },

    {
      label: "Budget",
      key: "budget",
      title: selectedServices?.[4]?.data,
      data: budgetOptions,
    },
    {
      label: "Price Model",
      key: "priceModel",
      title: "Select Price Model",
      data: priceModelOptions,
    },
    {
      label: "Client Business Size",
      key: "clientBussinessSize",
      title: "Select ClientBusinessSize",
      data: clientBussinessSizeOptions,
    },
    {
      label: "Category",
      key: "category",
      title: "Select Category",
      data: agencyCategoryOptions,
    },
  ];
  // email handler
  const emailHandler = (e) => {
    e.preventDefault();
    if (emailData.length >= 3) {
      alert("your mail limit is exceeded");
    } else if (email) {
      setEmailData((obj) => {
        return [...obj, email];
      });
      setEmail("");
    } else {
      return false;
    }
  };
  //remove mail
  const handleEmailDelete = (email) => {
    setEmailData(emailData.filter((item) => item !== email));
  };

  // contacts
  const handleChange = (e) => {
    setForm({
      ...form,
      [e.target.name]: e.target.value,
    });
  };

  const handleAddOrUpdate = () => {
    if (
      form.location === "" &&
      form?.streetAddress === "" &&
      form?.postalCode === "" &&
      form?.PhoneNumber === ""
    ) {
      setAlert({
        type: "error",
        message: "Please enter contact field",
        show: true,
      });
      return;
    }
    if (form.id) {
      // Update existing location
      setLocations(locations.map((loc) => (loc.id === form.id ? form : loc)));
    } else {
      // Add new location
      setLocations([...locations, { ...form, id: Date.now() }]);
    }
    setForm({
      id: null,
      location: "",
      streetAddress: "",
      postalCode: "",
      PhoneNumber: "",
    });
  };

  const handleEdit = (location) => {
    setForm(location);
  };

  const handleDelete = (id) => {
    setLocations(locations.filter((loc) => loc.id !== id));
  };

  // review
  const handleReviewChange = (e) => {
    setReviewForm({
      ...reviewForm,
      [e.target.name]: e.target.value,
    });
  };

  const handleAddOrUpdateReview = () => {
    if (
      reviewForm?.review === "" ||
      reviewForm?.rating === "" ||
      reviewForm?.email === ""
    ) {
      setAlert({
        type: "error",
        message: "Please Enter all review fields",
        show: true,
      });
      return;
    }
    if (reviewForm.id) {
      setReviews(
        reviews.map((rev) => (rev.id === reviewForm.id ? reviewForm : rev))
      );
    } else {
      setReviews([...reviews, { ...reviewForm, id: Date.now() }]);
    }
    setReviewForm({
      id: null,
      approve: false,
      review: "",
      rating: "",
      email: "",
    });
  };

  const handleEditReview = (review) => {
    setReviewForm(review);
  };

  const handleDeleteReview = (id) => {
    setReviews(reviews.filter((rev) => rev.id !== id));
  };

  useEffect(() => {
    const name = ID?.toLowerCase();
    if (ID == null) {
      setLoaded(true);
      setAgencyData(null);
    } else {
      axios.get(GET_DIGITAL_AGENCIES(name)).then((response) => {
        if (response?.data?.length === 0) {
          setLoaded(true);
          setAgencyData(null);
        } else {
          const data = response.data?.[0];
          setAgencyData(response.data?.[0]);
          const addressesWithId =
            data?.location?.map((address, index) => ({
              ...address,
              id: index + 1,
            })) || [];
          const reviewWithId =
            data?.review?.map((review, index) => ({
              ...review,
              id: index + 1,
            })) || [];
          setLocations(addressesWithId);
          setReviews(reviewWithId);
          setEmailData(data?.notify);
          setShowReview(data?.show);
          setMailvarify(true);
          const service = data?.services?.length > 0 ? data?.services : [];
          const industry = data?.industry?.length > 0 ? data?.industry : [];
          const geographic_focus =
            data?.geographic_focus?.length > 0 ? data?.geographic_focus : [];
          const language = data?.languages?.length > 0 ? data?.languages : [];
          const budget = data?.budget?.length > 0 ? data?.budget : [];
          const pricing_model =
            data?.pricing_model?.length > 0 ? data?.pricing_model : [];
          const client_business_size =
            data?.client_business_size?.length > 0
              ? data?.client_business_size
              : [];
          const categories =
            data?.categories?.length > 0 ? data?.categories : [];
          const service_data = [
            {
              name: "services",
              key: "services",
              data: service,
              extraData: service,
            },
            {
              name: "industry",
              key: "industry",
              data: industry,
              extraData: industry,
            },
            {
              name: "geographicFocus",
              key: "geographicFocus",
              data: geographic_focus,
              extraData: geographic_focus,
            },
            {
              name: "languages",
              key: "languages",
              data: language,
              extraData: language,
            },
            { name: "budget", key: "budget", data: budget, extraData: budget },
            {
              name: "priceModel",
              key: "priceModel",
              data: pricing_model,
              extraData: pricing_model,
            },
            {
              name: "clientBussinessSize",
              key: "clientBussinessSize",
              data: client_business_size,
              extraData: client_business_size,
            },
            {
              name: "category",
              key: "category",
              data: categories,
              extraData: categories,
            },
          ];
          setSelectedServices(service_data);
          setLoaded(true);
        }
      });
    }
  }, [ID]);

  const formik = useFormik({
    initialValues: {
      name: agencyData?.name || "",
      logoUrl: agencyData?.logo || "",
      tagline: agencyData?.tag_line || "",
      shortIntroduction: agencyData?.short_description || "",
      detailIntroduction: agencyData?.detailed_description || "",
      founded: agencyData?.founded || "",
      noOfEmployees: agencyData?.employee || "",
      website: agencyData?.website?.[0] || "",
      register_email: agencyData?.email || "",
    },
    enableReinitialize: true,
    validationSchema: Yup.object({
      name: Yup.string().required("Name is required"),
      logoUrl: Yup.string().url("Must be a valid URL"),
      tagline: Yup.string(),
      shortIntroduction: Yup.string(),
      detailIntroduction: Yup.string(),
      founded: Yup.string(),
      noOfEmployees: Yup.string(),
      register_email: Yup.string(),
      website: Yup.string()
        .url("Must be a valid URL")
        .required("URL is required"),
    }),
    onSubmit: (values, { setSubmitting }) => {
      const data = {
        email: values.register_email,
        name: values.name,
        logo: values.logoUrl,
        sh_desc: values.shortIntroduction,
        de_desc: values.detailIntroduction,
        tag: values.tagline,
        founded: values.founded,
        employee: values.noOfEmployees,
        services: JSON.stringify(selectedServices?.[0]?.data),
        industry: JSON.stringify(selectedServices?.[1]?.data),
        geographic_focus: JSON.stringify(selectedServices?.[2]?.data),
        languages: JSON.stringify(selectedServices?.[3]?.data),
        budget: JSON.stringify(selectedServices?.[4]?.data),
        pricing_model: JSON.stringify(selectedServices?.[5]?.data),
        client_business_size: JSON.stringify(selectedServices?.[6]?.data),
        tech_stack: "[]",
        website: JSON.stringify([values.website]),
        notify: JSON.stringify(emailData),
        location: JSON.stringify(locations),
        categories: JSON.stringify(selectedServices?.[7]?.data),
        show: showReview,
        review: JSON.stringify(reviews),
      };
      setSubmitting(false);
      axios
        .put(UPDATE_AGENCY_DETAIL(agencyData?.id), {
          data: JSON.stringify([data]),
        })
        .then(() => {
          setAlert({
            type: "success",
            message: "Agency Updated Successfully",
            show: true,
          });
          setTimeout(() => {
            navigate(-1);
          }, 500);
        })
        .catch(() => {
          setAlert({
            type: "error",
            message: "Agency Updation Failed",
            show: true,
          });
        });
    },
  });

  if (!agencyData && loaded) {
    return (
      <>
        <Button className="btn_1 mb-3" onClick={() => navigate(-1)}>
          Back
        </Button>
        <div className="no-data-found">
          <h4>No Data Found</h4>
        </div>
      </>
    );
  }

  if (!agencyData) {
    return <Loader />;
  }

  return (
    <>
      <AlertPopUp config={Alert} handleClose={setAlert} />
      <Button className="btn_1 mb-3" onClick={() => navigate(-1)}>
        Back
      </Button>
      <main className="update-agency-container">
        <Paper elevation={3}>
          <Box className="p-3 px-4 about-agency">
            <h4 className="w-100">About Your Agency</h4>
            <TextField
              id="name"
              name="name"
              type="text"
              label="Name"
              placeholder="Enter Agency Name"
              fullWidth
              {...formik.getFieldProps("name")}
              error={formik.touched.name && Boolean(formik.errors.name)}
              helperText={formik.touched.name && formik.errors.name}
            />
            <TextField
              id="logoUrl"
              name="logoUrl"
              type="text"
              label="Logo URL"
              placeholder="Enter your logo"
              fullWidth
              {...formik.getFieldProps("logoUrl")}
              error={formik.touched.logoUrl && Boolean(formik.errors.logoUrl)}
              helperText={formik.touched.logoUrl && formik.errors.logoUrl}
            />
            <TextField
              id="tagline"
              name="tagline"
              type="text"
              label="Tagline"
              placeholder="Enter your tagline"
              fullWidth
              {...formik.getFieldProps("tagline")}
              error={formik.touched.tagline && Boolean(formik.errors.tagline)}
              helperText={formik.touched.tagline && formik.errors.tagline}
            />
            <TextField
              id="founded"
              name="founded"
              type="text"
              label="Founded"
              placeholder="Enter your agency name"
              fullWidth
              {...formik.getFieldProps("founded")}
              error={formik.touched.founded && Boolean(formik.errors.founded)}
              helperText={formik.touched.founded && formik.errors.founded}
            />
            <TextField
              id="shortIntroduction"
              name="shortIntroduction"
              type="text"
              label="Short Introduction"
              multiline
              maxRows={4}
              className="w-100"
              fullWidth
              {...formik.getFieldProps("shortIntroduction")}
              error={
                formik.touched.shortIntroduction &&
                Boolean(formik.errors.shortIntroduction)
              }
              helperText={
                formik.touched.shortIntroduction &&
                formik.errors.shortIntroduction
              }
            />
            <TextField
              id="detailIntroduction"
              name="detailIntroduction"
              type="text"
              label="Detail Introduction"
              multiline
              maxRows={4}
              className="w-100"
              fullWidth
              {...formik.getFieldProps("detailIntroduction")}
              error={
                formik.touched.detailIntroduction &&
                Boolean(formik.errors.detailIntroduction)
              }
              helperText={
                formik.touched.detailIntroduction &&
                formik.errors.detailIntroduction
              }
            />

            <FormControl
              fullWidth
              error={
                formik.touched.noOfEmployees &&
                Boolean(formik.errors.noOfEmployees)
              }
            >
              <InputLabel id="noOfEmployees-label">No. of Employees</InputLabel>
              <Select
                labelId="noOfEmployees-label"
                id="noOfEmployees"
                name="noOfEmployees"
                value={formik.values.noOfEmployees}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
              >
                {noOfEmployeeOptions.map((employee) => (
                  <MenuItem value={employee} key={employee}>
                    {employee}
                  </MenuItem>
                ))}
              </Select>
              {formik.touched.noOfEmployees && formik.errors.noOfEmployees && (
                <div
                  style={{
                    color: "red",
                    fontSize: "0.75rem",
                    marginTop: "8px",
                  }}
                >
                  {formik.errors.noOfEmployees}
                </div>
              )}
            </FormControl>
          </Box>
          <Divider />
          <Box className="p-3 px-4 contact-agency">
            <h4 className="w-100">Contact Info</h4>
            <TextField
              id="register_email"
              name="register_email"
              type="email"
              label="Email"
              fullWidth
              {...formik.getFieldProps("register_email")}
              error={
                formik.touched.register_email &&
                Boolean(formik.errors.register_email)
              }
              helperText={
                formik.touched.register_email && formik.errors.register_email
              }
            />
            <TextField
              id="website"
              name="website"
              type="text"
              label="Website"
              fullWidth
              {...formik.getFieldProps("website")}
              error={formik.touched.website && Boolean(formik.errors.website)}
              helperText={formik.touched.website && formik.errors.website}
            />
            <Box className="location-container">
              <Box className="w-100 add-location">
                <TextField
                  id="location"
                  name="location"
                  type="text"
                  label="Location"
                  value={form.location}
                  onChange={handleChange}
                  fullWidth
                />
                <TextField
                  id="streetAddress"
                  name="streetAddress"
                  type="text"
                  label="Street Address"
                  value={form.streetAddress}
                  onChange={handleChange}
                  fullWidth
                />
                <TextField
                  id="postalCode"
                  name="postalCode"
                  type="text"
                  label="Postal Code"
                  value={form.postalCode}
                  onChange={handleChange}
                  fullWidth
                />
                <TextField
                  id="PhoneNumber"
                  name="PhoneNumber"
                  type="text"
                  label="Phone Number"
                  value={form.PhoneNumber}
                  onChange={handleChange}
                  fullWidth
                />
                <button className="btn_1" onClick={() => handleAddOrUpdate()}>
                  {form.id ? "Update" : "Add"}
                </button>
              </Box>
              <ul className="w-100">
                {locations.map((loc) => (
                  <li key={loc.id} className="mb-2">
                    <span>
                      Location: {""} {loc.location}
                    </span>
                    ,{" "}
                    <span>
                      Street Address: {""} {loc.streetAddress}
                    </span>
                    ,{" "}
                    <span>
                      Postal Code: {""} {loc.postalCode}
                    </span>
                    ,{" "}
                    <span>
                      Phone Number: {""} {loc.PhoneNumber}
                    </span>
                    <EditIcon
                      className="ms-3"
                      style={{
                        width: "20px",
                        height: "20px",
                        cursor: "pointer",
                      }}
                      onClick={() => handleEdit(loc)}
                    />
                    <DeleteIcon
                      className="ms-3"
                      style={{
                        width: "20px",
                        height: "20px",
                        cursor: "pointer",
                      }}
                      onClick={() => handleDelete(loc.id)}
                    />
                  </li>
                ))}
              </ul>
            </Box>
          </Box>
          <Divider />

          {/* services */}
          <Box className="p-3 px-4 agency-services">
            <h4 className="w-100">Services</h4>
            <div className="p-3 p-4-md content p-sm-0" id="agency-services">
              {seo_form_services?.map((service) => {
                return (
                  <div className="cmd-drop-con" key={service.label}>
                    <label className="">{service.label}:</label>
                    <div className="px-0 d-flex flex-column">
                      <Dropdown
                        key={service.key}
                        targetName={service.key}
                        title={service?.title}
                        data={service?.data}
                        selectedData={
                          selectedServices?.filter((select) => {
                            return select?.key === service.key;
                          }) || []
                        }
                        action={HandleGetValue}
                      />
                    </div>
                  </div>
                );
              })}
            </div>
          </Box>
          <Divider />

          {/* agencu review */}
          <Box className="p-3 px-4 agency-review">
            <h4 className="w-100">Agency Review</h4>

            <div className="d-flex w-100 update-agency-switch">
              <div className="">
                <p className="mb-0 heading-medium">Show Review:</p>
              </div>
              <div className="ms-4">
                <label className="switch">
                  <input
                    type="checkbox"
                    checked={showReview}
                    onChange={(e) => setShowReview((pre) => !pre)}
                  />
                  <span className="slider round"></span>
                </label>
              </div>
            </div>

            <Box className="review-update">
              <div className="w-100">
                <TextField
                  id="review"
                  name="review"
                  type="text"
                  label="Review"
                  multiline
                  maxRows={3}
                  fullWidth
                  sx={{ width: "100%", maxWidth: "100%" }}
                  value={reviewForm.review}
                  onChange={handleReviewChange}
                />
              </div>

              <Box
                sx={{
                  width: 200,
                  display: "flex",
                  alignItems: "center",
                }}
              >
                <p className="mb-0 me-2">Rating: </p>
                <Rating
                  name="rating"
                  value={reviewForm.rating}
                  precision={1}
                  onChange={handleReviewChange}
                  icon={<FaStar fontSize="inherit" />}
                  emptyIcon={
                    <FaStar style={{ opacity: 0.55 }} fontSize="inherit" />
                  }
                />
              </Box>

              <TextField
                id="email"
                name="email"
                type="email"
                label="Email"
                value={reviewForm.email}
                onChange={handleReviewChange}
                placeholder="Email"
                fullWidth
              />

              <label className="approved-checkbox">
                Approve:
                <input
                  type="checkbox"
                  name="approve"
                  checked={reviewForm.approve}
                  onChange={(e) =>
                    setReviewForm({ ...reviewForm, approve: e.target.checked })
                  }
                />
              </label>
              <button
                className="btn_1"
                onClick={() => handleAddOrUpdateReview()}
              >
                {reviewForm.id ? "Update" : "Add"}
              </button>
            </Box>
            <ul>
              {reviews.map((rev) => (
                <li key={rev.id} className="mb-2">
                  <span>
                    <b>Review: </b>
                    {rev.review}
                  </span>
                  ,{" "}
                  <span>
                    <b>Rating: </b>
                    {rev.rating}
                  </span>
                  ,{" "}
                  <span>
                    <b>Email: </b>
                    {rev.email}
                  </span>
                  ,{" "}
                  <span>
                    <b>Approve: </b>
                    {rev.approve ? "Approved" : "Not Approved"}
                  </span>
                  <EditIcon
                    className="ms-3"
                    style={{
                      width: "20px",
                      height: "20px",
                      cursor: "pointer",
                    }}
                    onClick={() => handleEditReview(rev)}
                  />
                  <DeleteIcon
                    className="ms-3"
                    style={{
                      width: "20px",
                      height: "20px",
                      cursor: "pointer",
                    }}
                    onClick={() => handleDeleteReview(rev.id)}
                  />
                </li>
              ))}
            </ul>
          </Box>
          <Divider />

          {/* email notification */}
          <Box className="p-3 px-4 email-notify">
            <h4 className="w-100 mb-2">Email Notification</h4>
            <p className="mb-0 w-100 heading-medium">
              Let us know where you want to receive notifiction when we find new
              leads for you.
            </p>

            <div className="d-flex w-100 update-agency-switch">
              <div className="">
                <p className="mb-0 heading-medium">
                  Receive emails about new leads
                </p>
              </div>
              <div className="ms-4">
                <label className="switch">
                  <input
                    type="checkbox"
                    checked={!mailVarify}
                    onChange={(e) => {
                      if (e.target.checked) {
                        setMailvarify(false);
                      } else {
                        setMailvarify(true);
                      }
                    }}
                  />
                  <span className="slider round"></span>
                </label>
              </div>
            </div>

            <div className="d-flex w-100 email-add-notify">
              <div className="d-flex align-items-center">
                <p className="mb-0  heading-medium me-3">Email Addresses</p>
                <Chip
                  label={`${emailData.length} / 3`}
                  variant="outlined"
                  className="heading-small"
                  sx={{ padding: "15px 10px" }}
                />
              </div>
              <div className="">
                <form onSubmit={(e) => emailHandler(e)}>
                  <input
                    disabled={mailVarify}
                    type="email"
                    placeholder="Enter Email Address"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                    className="ms-3"
                  />
                </form>
              </div>
            </div>

            {emailData?.length !== 0 ? (
              <>
                <div className="subscribe-mail">
                  <p className="mb-0 heading-medium">Subscribe Email:</p>
                  {emailData?.map((email) => {
                    return (
                      <Chip
                        label={email}
                        variant="outlined"
                        key={email}
                        className="heading-small"
                        sx={{ padding: "15px 10px" }}
                        onDelete={() => handleEmailDelete(email)}
                      />
                    );
                  })}
                </div>
              </>
            ) : null}
          </Box>
          <Divider />

          <Button
            className="m-3 mx-4 btn_1"
            variant="contained"
            color="primary"
            onClick={formik.handleSubmit}
            disabled={formik.isSubmitting}
          >
            Update
          </Button>
        </Paper>
      </main>
    </>
  );
};

export default UpdateAgencies;
