import React, { useEffect, useRef, useState } from "react";
import "./header.scss";
import LOGO from "../../../assets/images/Global/logoMain.png";
import { CgProfile } from "react-icons/cg";
import { SidebarMob } from "./SideBarMob";
import { RxCross2, RxHamburgerMenu } from "react-icons/rx";
import { VscLayers, VscPreview, VscTools } from "react-icons/vsc";
import { RiLogoutCircleLine } from "react-icons/ri";
import { BsSpeedometer2 } from "react-icons/bs";
import {
  AiOutlineHome,
  AiOutlineMessage,
  AiOutlinePayCircle,
  AiOutlineUserAdd,
} from "react-icons/ai";
import { FiUser } from "react-icons/fi";
import { MdOutlineAutoGraph } from "react-icons/md";
import { Link } from "react-router-dom";
import { IoCloseCircleOutline } from "react-icons/io5";
import {
  affiliate,
  performance_metrics,
  affiliate_notification,
  payment_earnings,
  promotional,
  dashboard,
  LOGIN,
  SIGNUP,
} from "../../../utils/constant";
import { AnimatePresence, motion } from "framer-motion";
import { useSelector } from "react-redux";
import bonus from "../../../assets/images/Global/bonus.svg";
import { ProfilePop } from "../../Profile/ProfilePop";
import { useLocation, useNavigate } from "react-router";
import { ToastContainer, toast } from "react-toastify";
import { POST_WITHRA_REQ, GET_USER_WITHRA } from "../../../utils/Api";
import axios from "axios";

export const Header = () => {
  const nav = useNavigate();
  const TOPBAR = useRef();
  const Path = useLocation();
  const user_profile = useSelector((state) => state.profile);
  const BG = Path.pathname === "/affiliate/profile";
  const [raiseWithdrawal, setRaiseWithdrawal] = useState(false);
  const [paypalId, setPaypalId] = useState("");
  const [error, setError] = useState(false);

  useEffect(() => {
    !BG
      ? (TOPBAR.current.style.background = "#457097")
      : (TOPBAR.current.style.background = "transparent");
  }, []);

  useEffect(() => {
    if (raiseWithdrawal) {
      document.body.style.overflow = "hidden";
    } else {
      document.body.style.overflow = "auto";
    }
  }, [raiseWithdrawal]);

  const [OpenDelay, setOpenDelay] = useState(false);
  const [open, setOpen] = useState(false);
  const toggleSidebar = () => setOpen(!open);

  const OPEN = () => {
    setOpen(true);
    setTimeout(() => setOpenDelay(true), 300);
  };

  const CLOSE = () => {
    setOpen(false);
    setTimeout(() => setOpenDelay(false), 300);
  };

  const menu = [
    {
      name: "Home",
      link: dashboard,
      Icon: <AiOutlineHome />,
    },
    {
      name: "Performance Metrics",
      link: performance_metrics,
      Icon: <BsSpeedometer2 />,
    },
    {
      name: "Promotional Material",
      link: promotional,
      Icon: <MdOutlineAutoGraph />,
    },
    {
      name: "Payment & Earnings",
      link: payment_earnings,
      Icon: <AiOutlinePayCircle />,
    },
    {
      name: "Message & Notification",
      link: affiliate_notification,
      Icon: <AiOutlineMessage />,
    },
  ];

  useEffect(() => {
    if (open) {
      document.body.style.overflow = "hidden";
      document.body.style.height = "90vh";
    } else {
      document.body.style.overflow = "";
    }
  }, [open]);

  const HandleLogOut = () => {
    localStorage.removeItem("AFFTOKEN");
    localStorage.removeItem("EMAIL");
    nav("/affiliate/login");
  };

  const handleCloseReq = () => {
    setPaypalId("");
    setError(false);
    setRaiseWithdrawal(false);
  };

  const user_pr_details = user_profile?.profile1;

  const withra_details = {
    email: user_profile?.profile1?.email,
    affiliate_id: user_profile?.profile2?.aff_id,
    name: user_profile?.profile1?.name,
    phone: user_profile?.profile1?.contact,
    address: user_profile?.profile1?.address,
    country: user_profile?.profile1?.country,
    paypal_id: paypalId,
    earning: user_profile?.profile2?.reward,
  };

  const postRaiseReq = (data) => {
    axios
      .post(POST_WITHRA_REQ(), data)
      .then(() => {
        handleCloseReq();
        toast.success("Withdrawal Request Raise successfully", {
          position: "top-right",
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        });
      })
      .catch(() => {
        handleCloseReq();
        toast.error("Withdrawal Request Raise Failed", {
          position: "top-right",
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        });
      });
  };
  const handleRaiseWithdrawalReq = () => {
    axios.get(GET_USER_WITHRA(user_pr_details?.email)).then((response) => {
      if (response.data?.length === 0) {
        if (paypalId !== "") {
          postRaiseReq(withra_details);
        } else {
          setRaiseWithdrawal(true);
        }
      } else {
        const data = response.data?.[0];
        withra_details.paypal_id = data?.paypal_id;
        if (withra_details?.paypal_id !== "") {
          postRaiseReq(withra_details);
        }
      }
    });
  };
  return (
    <>
      <ToastContainer />
      <AnimatePresence mode="wait" initial={false}>
        {" "}
        {raiseWithdrawal && (
          <div className="Pr_pop">
            <div className="overlay"></div>
            <motion.div className="pr_PopBody " {...framerSidebarBackground}>
              <div className="pr_content">
                <div className="modalBody">
                  <IoCloseCircleOutline onClick={() => handleCloseReq()} />
                  <div className="input-content">
                    <input
                      type="text"
                      placeholder="Enter Paypal ID"
                      value={paypalId}
                      onChange={(e) => setPaypalId(e.target.value)}
                    />
                    {error && (
                      <span className="msg-error">Please Enter Paypal ID</span>
                    )}
                  </div>
                  <div className="input-content">
                    <span className="" style={{ color: "#365486" }}>
                      Total Reward :
                    </span>
                    <span className="ms-1 font-light-bold">
                      {user_profile?.profile2?.reward}
                      {/* {parseFloat(user_profile?.profile2?.reward?.toFixed(2))} */}
                    </span>
                  </div>
                  <div className="buttons">
                    <button onClick={() => handleCloseReq()}>Cancel</button>
                    <button
                      onClick={() => handleRaiseWithdrawalReq()}
                      disabled={paypalId == ""}
                    >
                      Raise Request
                    </button>
                  </div>
                </div>
              </div>
            </motion.div>
          </div>
        )}
      </AnimatePresence>

      <div className="HeaderContainer">
        <div className="header">
          <div className="FirstBar">
            <div className="row m-0">
              <div className="col-lg-6  col-md-6 col-12">
                <div className="hd_left">
                  <div className="hd_logo">
                    <Link to={"../affiliate/dashboard"}>
                      <img src={LOGO} alt="RanksPro.io Logo" />
                    </Link>
                  </div>
                </div>
              </div>
              <div className="col-lg-6 col-md-6 col-12 d-flex align-items-center justify-content-end ">
                <div className="hd_RIght">
                  <div className="rai-wdl-btn">
                    <button
                      className="cont"
                      onClick={() => handleRaiseWithdrawalReq()}
                    >
                      <img src={bonus} alt="Raise Withdrawal Request" />
                      Raise Withdrawal Request
                    </button>
                  </div>
                  <div className="TopDrop">
                    <div className="cont">
                      <div className="email">
                        {user_profile?.profile1?.email}
                      </div>
                      <div className="vector">
                        <CgProfile />
                      </div>
                    </div>
                    <div className="dropAf border">
                      <ul>
                        <li onClick={() => nav("/affiliate/profile")}>
                          <span>
                            <FiUser />
                          </span>
                          Profile
                        </li>
                        <li onClick={HandleLogOut}>
                          <span>
                            <RiLogoutCircleLine />
                          </span>{" "}
                          Logout
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
                {/* FOR MOBILE */}
                <div className="d-flex justify-content-end align-items-center h-100 w-100 d-md-flex d-lg-none ">
                  {!OpenDelay ? (
                    <button
                      onClick={OPEN}
                      className="p-2 Toggler"
                      aria-label="toggle sidebar"
                    >
                      <RxHamburgerMenu />{" "}
                    </button>
                  ) : (
                    <button
                      onClick={CLOSE}
                      className="p-2 Toggler"
                      aria-label="toggle sidebar"
                    >
                      <RxCross2 />
                    </button>
                  )}
                  <SidebarMob
                    {...{
                      toggleSidebar,
                      open,
                      setOpen,
                      menu,
                      CLOSE,
                      HandleLogOut,
                    }}
                  />
                </div>
              </div>
            </div>
          </div>
          <div className="d-none d-md-none d-lg-block m-0">
            <div className="TopBar" ref={TOPBAR}>
              {menu?.map((item) => {
                return (
                  <Link to={`/affiliate/${item?.link}`} key={item?.name}>
                    <span>{item?.name}</span>
                  </Link>
                );
              })}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

const framerSidebarBackground = {
  initial: { transform: "scale(0) ", transformOrigin: "top" },
  animate: { transform: "scale(1) ", transformOrigin: "top" },
  exit: {
    transform: "scale(0) ",
    transition: { delay: 0.2 },
    transformOrigin: "top",
  },
  transition: { duration: 0.3 },
};
