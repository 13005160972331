import React, { useState, useEffect, Suspense, useMemo, useRef } from "react";
import "../css/site-audit.scss";
import UnderConstruction from "./login/underConstruction";
import seoImg from "../Assets/site-audit/seo-score.png";
import backlinksImg from "../Assets/site-audit/backlinks.png";
import trafficImg from "../Assets/site-audit/traffic.png";
import organicImg from "../Assets/site-audit/keywords.png";
import RanksProLogo from "../../Mycomponent/Assets/site-audit/RanksProLogo.png";
import axios from "axios";
import {
  POST_SITEAUDIT,
  GET_SITE_AUDIT,
  GET_AUDIT_LIMIT,
  POST_SITEAUDIT_LMT,
  SITE_AUDIT_UPDATE,
  DOMAINOVERVIEW_GET,
} from "../../services/constants";
import { useSelector, useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import Papa from "papaparse";
import { saveAs } from "file-saver";
import JSZip from "jszip";
import LineAlert from "../share/components/lineAlert";
import AuditTable from "../share/tables/AuditTable";
import ProjectList from "../share/searchBox/ProjectList";
import ToolTip from "../share/components/tooltip";
import useMediaQuery from "@mui/material/useMediaQuery";
import LinearProgress from "@mui/material/LinearProgress";
import { motion, AnimatePresence } from "framer-motion";
import seoIssueIcon from "../Assets/site-audit/seoissue.svg";
import pageStatusIcon from "../Assets/site-audit/pagestatus.svg";
import viewIcon from "../Assets/site-audit/view.svg";
import recrawlIcon from "../Assets/site-audit/recrawl.svg";
import desktopIcon from "../Assets/site-audit/desktop.svg";
import useDebouncedFunction from "../../helperJs/debounce";
import { CircularProgressbar, buildStyles } from "react-circular-progressbar";
import backIcon from "../Assets/common/back.svg";
import {
  incrementNumber,
  millisToSeconds,
  roundSpeedData,
} from "../../helperJs/helper";
import SpeedMeter from "../share/components/speed-meter";
import exportFileIcon from "../Assets/rank-tracking-assets/export-file.svg";
import { ZustandStore } from "../../store/zustand-store";
import useWebHook from "../../helperJs/useWebHook";
import CrawledPagesTable from "../share/tables/crawled-pages.table";
import useQuery from "../share/custom-hook/useQuery";
import jsPDF from "jspdf";
import html2canvas from "html2canvas";
import CrawledPagesPdf from "./Modals/CrawledPagesPdf";
import MenuListDropdown from "../share/components/menulist.component";
import PdfModal from "./Modals/PdfModals/PdfModals";
import { FiDownload } from "react-icons/fi";
import {
  Page,
  Text,
  View,
  Document,
  StyleSheet,
  Image,
  Link,
  PDFDownloadLink,
} from "@react-pdf/renderer";
import { getCurrentYear } from "../share/helper-js/current-year";

const styles = StyleSheet.create({
  page: {
    paddingHorizontal: 20,
    flexDirection: "column",
    paddingTop: 30,
    paddingBottom: 80, // Set vertical padding to 30 pixels
    borderStyle: "solid",
  },
  header: {
    paddingBottom:20
  },
  footer: {
    fontSize: 12,
    textAlign: "center",
    color: "grey",
    position: "absolute",
    bottom: 20,
    left: 0,
    right: 0,
  },
  logo: {
    width: 250,
  },
  title: {
    fontSize: 24,
    textAlign: "center",
    marginBottom: 20,
  },

 
  tableContainer: {
    marginBottom: 20,
  },

  keywordLoc: {
    flex: 1, // Each cell should take equal space within the row
    padding: 5,
    borderRightWidth: 1,
    borderColor: "#000",
    justifyContent: "start",
    minHeight: 40,
    height: "100%",
    alignItems: "start",
    flexDirection: "column",
  },
 
  fixedWidthCell: {
    maxWidth: 80, // Disable flex for fixed width cells
  },
  cardContainer: {
    flexDirection: "row",
    flexWrap: "wrap", // Enable flex wrapping
    justifyContent: "space-between", // Arrange cards with equal spacing
    marginTop: 10,
  },
  card: {
    flexDirection: "row",
    justifyContent: "space-between",
    width: 180,
    backgroundColor: "#FFFFFF",
    padding: 10,
    borderRadius: 5,
    marginBottom: 20,
  },
  image: {
    width: 30,
    height: 40,
  },
  title: {
    fontSize: 11,
    fontWeight: "bold",
    marginBottom: 5,
    color: "#6c757d",
  },
  description: {
    fontSize: 16,
  },
  imageContainer: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  arrowIcon: {
    width: 20,
    height: 20,
    objectFit: "contain",
  },
  view_button: {
    borderWidth: 1,
    borderColor: "#365486",
    padding: 5,
    borderRadius: 7,
    color: "#365486",
    fontSize: 11,
  },
  keyword_bold: {
    fontSize: 13,
    fontWeight: "bold",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  keyword: {
    fontSize: 11,
    color: "#6c757d",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  rank: {
    fontSize: 11,
    color: "#6c757d",
    textAlign: "center",
    marginRight: 10,
  },
  
 
  pageNumber: {
    position: "absolute",
    fontSize: 11,
    bottom: 45,
    left: 0,
    right: 0,
    textAlign: "center",
    color: "grey",
  },
  link_text: {
    fontSize: 7,
    textDecoration: "none",
    textOverflow: "hidden",
    textAlign: "start",
  },
  logoHead: {
    width: 125,
  },
});






export const PdfFile = ({pdfNewData}) => {
  
  const DashImage = sessionStorage.getItem("dashboardImage");
  const Data = JSON.parse(sessionStorage.getItem("pdfSessionData"));
  const Web_url = Data.websiteurl
  const image_logo = pdfNewData
    ? pdfNewData?.reset
      ? RanksProLogo
      : pdfNewData?.logo?.src
    : RanksProLogo;
  return(
  <Document>
    <Page size={"A4"} style={styles.page}>
      <View
        style={{
          alignItems: "center",
          display: "flex",
          height: "82vh",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <Image style={styles.logo} src={image_logo} />
        <Text style={{ marginBottom: 20 }}>Organic Search</Text>
        <Text style={styles.title}>Domain: {Web_url}</Text>
      </View>


</Page>
<Page size={"A4"} style={styles.page}>
  
<View
          style={{
            ...styles.header,
            alignItems: pdfNewData?.reset
              ? "flex-start"
              : pdfNewData?.logo.position,
          }}
          fixed
        >
          <Image style={styles.logoHead} src={image_logo} />
        </View>

      <View style={{ ...styles.tableContainer }}>
        <View>
       <Image src={DashImage}/>
        </View>
      </View>

      <Text style={styles.footer} fixed>
      {pdfNewData?.reset
            ? `Copyright ${getCurrentYear} eSearch Logix | Prepared using RanksPro.io`
            : pdfNewData?.footer}
      </Text>

     
    </Page>
  </Document>
);}










const Siteaudit = () => {
  const websiteurl = useSelector((state) => state.websiteurl);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const matches = useMediaQuery("(min-width:600px)");
  const usercurrentprojectlocation = useSelector(
    (state) => state.usercurrentprojectlocation
  );
  const websiteUrl = useSelector((state) => state.websiteurl);
  const userName = useSelector((state) => state.name);
  const { setQueryParams, removeQueryParams } = useQuery();

  let domainName = "";
  if (typeof websiteUrl === "string") {
    domainName = websiteUrl.replace(/\s/g, "");
  }

  const curr_proj = usercurrentprojectlocation?.[0];
  const currentlocation = curr_proj?.location_name;
  const currentlocationcode = curr_proj?.location_code;

  const crawldomainlimit = useSelector((state) => state.crawldomainlimit);
  const {
    site_audit_data,
    site_audit_domain,
    site_audit_overview,
    country_Language_Data,
    emptySiteAuditData,
  } = ZustandStore((state) => state);
  // over view configure
  const [count, setCount] = useState(0);
  const [etv, setEtv] = useState([0]);
  const [domainviewData, setDomainViewDate] = useState([]);
  const { fetchData } = useWebHook();

  const data = true;
  const admin_mail = localStorage.getItem("admin_mail");
  const crawllimit = useSelector((state) => state.crawllimit);

  const [auditData, setAuditData] = useState([]);
  const [AllauditData, setAllAuditData] = useState([]);
  const [onpage_score, setOnpage_score] = useState(0);
  // audit items data
  const [totalTypes, setTotalTypes] = useState(0);
  const [queueData, setQueueData] = useState(0);

  // Bar chart
  const [typeSuccessfull, setTypeSuccessfull] = useState(0);
  const [typeRedirected, setRedirected] = useState(0);
  const [typeBroken, setBroken] = useState(0);
  const [typeBlocked, setBlocked] = useState(0);

  // post data
  const [postApiCall, setPostApiCall] = useState({
    showPostComp: false,
    showGetComp: true,
  });
  const [bannerAlert, setBannerAlert] = useState(false);

  // Alert conditional
  const [lowWord_Count_Alert, setLowWord_Count_Alert] = useState(false);
  const [duplicateMeta_Desc_Alert, setDuplicateMeta_Desc_Alert] =
    useState(false);
  const [duplicateTitle_tag_Alert, setDuplicateTitle_tag_Alert] =
    useState(false);
  const [no_title_tags_Alert, setNoTitle_Tags_Alert] = useState(false);
  const [withOut_h1_Alert, setWithOut_h1_Alert] = useState(false);
  const [with_broken_links_Alert, setWith_broken_links_Alert] = useState(false);
  const [without_meta_desc_Alert, setWithout_meta_desc_Alert] = useState(false);
  const [with_title_tooLong_Alert, setWith_title_tooLong_Alert] =
    useState(false);
  const [with_title_tooShort_Alert, setWith_title_tooShort_Alert] =
    useState(false);
  const [poorly_formatted_Alert, setPoorly_formatted_Alert] = useState(false);
  const [doctype_not_decl_Alert, setDoctype_not_decl_Alert] = useState(false);
  const [long_loading_time_Alert, setLong_loading_time_Alert] = useState(false);
  const [urls_too_long_Alert, seturls_too_long_Alert] = useState(false);
  const [description, setDescription] = useState({
    show_short_des: false,
    show_long_des: false,
    show_duplicate_h1_tag: false,
    show_same_h1_and_titles: false,
    show_conanical_issues: false,
    show_no_favicon: false,
    show_no_image_alt: false,
    show_no_image_title: false,
    short_des: [],
    long_des: [],
    duplicate_h1_tag: [],
    same_h1_and_title_tags: [],
    conanical_issue: [],
    no_favicon: [],
    no_image_alt: [],
    no_image_title: [],
  });

  // Low word count data
  const [lowWord_Count, setLowWord_Count] = useState([]);
  const [duplicateMeta_Desc, setDuplicateMeta_desc] = useState([]);
  const [duplicateTitle_tag, setDuplicateTitle_tag] = useState([]);
  const [no_title_tags, setNoTitle_Tags] = useState([]);
  const [withOut_h1, setWithOut_h1] = useState([]);
  const [with_broken_links, setWith_broken_links] = useState([]);
  const [without_meta_desc, setWithout_meta_desc] = useState([]);
  const [with_title_tooLong, setWith_title_tooLong] = useState([]);
  const [with_title_tooShort, setWith_title_tooShort] = useState([]);
  const [poorly_formatted, setPoorly_formatted] = useState([]);
  const [doctype_not_decl, setDoctype_not_decl] = useState([]);
  const [long_loading_time, setLong_loading_time] = useState([]);
  const [urls_too_long, seturls_too_long] = useState([]);
  const [connectionTime, setConnectionTime] = useState();
  const [time_to_interactive, setTime_to_interactive] = useState();
  const [fatchTime, setfatchTime] = useState();
  const [Seo_descovered, setSeo_Descovered] = useState(0);
  const [postAlert, setPostAlert] = useState(false);
  const [progress, setProgress] = useState(0);

  const today = new Date();
  const sevenDaysLater = new Date(today.setDate(today.getDate() + 7));
  const sevenDaysLaterDate = sevenDaysLater.getDate();

  const [selectedTab, setSelectedTab] = useState(1);
  const [reCrawlStatus, setReCrawlStatus] = useState(false);
  const [error, setError] = useState("");

  const getCountry = useMemo(() => {
    const country = country_Language_Data?.data?.filter((country) => {
      return (
        country.location_name ===
          usercurrentprojectlocation?.[0]?.location_name &&
        country?.location_type === "Country"
      );
    });
    return country?.[0]?.available_languages?.[0]?.language_name || "English";
  }, [country_Language_Data, usercurrentprojectlocation]);

  const sendauditmailHandler = () => {
    axios
      .post(
        `https://app.rankspro.io/api/user/sendauditmail/?email=${admin_mail}&name=${userName}&domain=${domainName}`
      )
      .then((response) => {
        console.log(": " + response);
      })
      .catch((error) => {
        console.log(": " + error);
      });
  };

  const handleNoData = () => {
    setError("Sorry, There is no Crawled Data for this Domain.");
    setPostApiCall({
      showPostComp: false,
      showGetComp: false,
    });
    setAuditData([]);
    ZustandStore.setState({
      site_audit_data: [],
    });
  };

  const RecrawlHandler = () => {
    if (
      admin_mail === "akash.sahu@esearchlogix.in" ||
      admin_mail === "ashutosh@esearchlogix.com" ||
      admin_mail === "rajeev.rawat@esearchlogix.in" ||
      admin_mail === "kuldeep.dm@esearchlogix.in" ||
      admin_mail === "dipanshu@esearchlogix.in"
    ) {
      return false;
    } else {
      const audit_json = {
        email: admin_mail,
        domain: domainName,
      };

      axios.get(GET_AUDIT_LIMIT(audit_json)).then((res) => {
        if (crawllimit <= 0) {
          setPostAlert("You don't have any plan. Please Upgrade.");
        }
        if (res.data[0].limit <= 0) {
          setPostAlert(`Your Crawl limit has been excceeded for this domain`);
          fatch_Site_Audit();
        } else {
          const item = {
            email: admin_mail,
            domain: domainName,
            max_crawl_page: res.data?.[0].limit,
          };
          axios.post(POST_SITEAUDIT(item)).then((res) => {
            emptySiteAuditData();
            setPostAlert("Your new crawled data will be ready in 10 min");
            setAuditData([]);
            setTotalTypes(0);
            setQueueData(0);
            setReCrawlStatus(true);
            setBannerAlert(true);
            sendauditmailHandler();
          });
        }
      });
    }
  };

  // post audit items done
  const item = {
    email: admin_mail,
    domain: domainName,
    max_crawl_page: crawllimit,
  };

  const POST_Audit = () => {
    setPostApiCall({ showPostComp: false, showGetComp: true });
    axios.post(POST_SITEAUDIT(item)).then((res) => {
      const POST_LMT = {
        email: admin_mail,
        domain: domainName,
        siteauditlmt: crawllimit,
        date: sevenDaysLaterDate,
      };
      axios.post(POST_SITEAUDIT_LMT(), POST_LMT);
      const obj_data = {
        try_upgrade: null,
        domain: domainName,
      };
      fetchData(obj_data);
      setPostAlert("Your new crawled data will be ready in 10 min");
      setBannerAlert(true);
      setReCrawlStatus(true);
      sendauditmailHandler();
    });
  };

  const debouncedFunction = useDebouncedFunction(() => {
    POST_Audit();
  }, 500);

  const handlePostSiteAuditClick = () => {
    debouncedFunction();
  };

  const siteAuditDataUpdate = () => {
    setQueueData(
      site_audit_data[site_audit_data.length - 1]?.data[0]?.result[0]
        ?.crawl_status.pages_in_queue || 0
    );

    setDomainViewDate(site_audit_overview);
    const etvArray = [];
    const etvLabel = [];
    const etvAll = [];
    const P1to3 = [];
    const P4to10 = [];
    const P11to50 = [];
    const P51to100 = [];
    const currCount =
      site_audit_overview?.metrics?.[0]?.metrics?.organic?.count;
    setCount(currCount);

    const months = [
      "Jan",
      "Feb",
      "Mar",
      "Apr",
      "May",
      "Jun",
      "Jul",
      "Aug",
      "Sep",
      "Oct",
      "Nov",
      "Dec",
    ];

    site_audit_overview?.metrics &&
      site_audit_overview?.metrics.map((res) => {
        etvLabel.push(months[res.month - 1] + " " + res.year);
        etvArray.push(res.metrics.organic?.etv);
        etvAll.push(res.metrics.paid?.etv);

        P1to3.push(res.metrics.organic?.pos_1 + res.metrics.organic?.pos_2_3);
        P4to10.push(res.metrics.organic?.pos_4_10);
        P11to50.push(
          res.metrics.organic?.pos_11_20 +
            res.metrics.organic?.pos_21_30 +
            res.metrics.organic?.pos_31_40 +
            res.metrics.organic?.pos_41_50
        );
        P51to100.push(
          res.metrics.organic?.pos_51_60 +
            res.metrics.organic?.pos_61_70 +
            res.metrics.organic?.pos_71_80 +
            res.metrics.organic?.pos_81_90 +
            res.metrics.organic?.pos_91_100
        );
      });

    setEtv(etvArray);
    setAuditData(
      site_audit_data[site_audit_data.length - 1]?.data?.[0]?.result
    );
    setAllAuditData(site_audit_data[site_audit_data.length - 1]);
  };

  // fatch audit based on condition
  const fatch_Site_Audit = () => {
    setError("");
    setPostApiCall({
      showPostComp: false,
      showGetComp: true,
    });
    if (crawllimit) {
      if (crawllimit <= 0) {
        setPostAlert("You don't have any plan. Please Upgrade.");
      } else if (
        site_audit_domain === domainName &&
        site_audit_data?.length != 0 &&
        site_audit_overview?.length != 0 &&
        !bannerAlert &&
        !reCrawlStatus
      ) {
        siteAuditDataUpdate();
      } else if (
        domainName !== "" &&
        domainName !== null &&
        currentlocation !== null
      ) {
        const audit_json = {
          email: admin_mail,
          domain: domainName,
        };
        ZustandStore.setState({
          site_audit_domain: domainName,
        });
        axios.get(GET_AUDIT_LIMIT(audit_json)).then((res) => {
          // get site audit
          axios.get(GET_SITE_AUDIT(item)).then((response) => {
            // for reset the alert value.
            // response get audit data
            const audit_data = response.data;
            if (audit_data.length !== 0) {
              setPostAlert(false);

              const queueDataValue =
                audit_data[audit_data.length - 1]?.data[0]?.result[0]
                  ?.crawl_status.pages_in_queue || 0;

              if (queueDataValue === 0) {
                setReCrawlStatus(false);
                ZustandStore.setState({
                  site_audit_data: audit_data,
                });
              }
              setQueueData(
                audit_data[audit_data.length - 1]?.data[0]?.result[0]
                  ?.crawl_status.pages_in_queue || 0
              );
              // overview congif start
              const json_getDomain = {
                domainName: domainName,
                domainCountry: currentlocation,
                domainLanuage: getCountry,
              };

              axios(DOMAINOVERVIEW_GET(json_getDomain)).then((res) => {
                const months = [
                  "Jan",
                  "Feb",
                  "Mar",
                  "Apr",
                  "May",
                  "Jun",
                  "Jul",
                  "Aug",
                  "Sep",
                  "Oct",
                  "Nov",
                  "Dec",
                ];
                if (res.data.length !== 0) {
                  const data = res.data[0];
                  ZustandStore.setState({
                    site_audit_overview: data,
                  });
                  setDomainViewDate(data);
                  const etvArray = [];
                  const etvLabel = [];
                  const etvAll = [];
                  const P1to3 = [];
                  const P4to10 = [];
                  const P11to50 = [];
                  const P51to100 = [];
                  const currCount =
                    res?.data?.[0]?.metrics?.[0]?.metrics?.organic?.count;
                  setCount(currCount);

                  res.data?.[0]?.metrics &&
                    res.data?.[0]?.metrics.map((res) => {
                      etvLabel.push(months[res.month - 1] + " " + res.year);
                      etvArray.push(res.metrics.organic?.etv);
                      etvAll.push(res.metrics.paid?.etv);

                      P1to3.push(
                        res.metrics.organic?.pos_1 +
                          res.metrics.organic?.pos_2_3
                      );
                      P4to10.push(res.metrics.organic?.pos_4_10);
                      P11to50.push(
                        res.metrics.organic?.pos_11_20 +
                          res.metrics.organic?.pos_21_30 +
                          res.metrics.organic?.pos_31_40 +
                          res.metrics.organic?.pos_41_50
                      );
                      P51to100.push(
                        res.metrics.organic?.pos_51_60 +
                          res.metrics.organic?.pos_61_70 +
                          res.metrics.organic?.pos_71_80 +
                          res.metrics.organic?.pos_81_90 +
                          res.metrics.organic?.pos_91_100
                      );
                    });

                  setEtv(etvArray);
                } else {
                  const today = new Date();
                  var dd = today.getDate();
                  var monthNames = [
                    "Jan",
                    "Feb",
                    "Mar",
                    "Apr",
                    "May",
                    "Jun",
                    "Jul",
                    "Aug",
                    "Sep",
                    "Oct",
                    "Nov",
                    "Dec",
                  ];
                  var yyyy = today.getFullYear();
                  if (dd < 10) dd = "0" + dd;
                  let aobj = {
                    backlink: 0,
                    keyword: [],
                    metrics: [],
                    Month: monthNames[today.getMonth() + 1],
                    Year: yyyy,
                    last_updated: dd,
                  };
                  const backlinkPromise = axios
                    .get(
                      `https://app.rankspro.io/api/user/backlink/?domain_name=${domainName}&location_code=${currentlocationcode}&language_code=en&location_name=${currentlocation}&language_name=${getCountry}`
                    )
                    .then((res) => {
                      aobj.backlink = res.data[0].backlink;
                    });

                  const keywordPromise = axios
                    .get(
                      `https://app.rankspro.io/api/user/domainkeyword/?domain_name=${domainName}&location_code=${currentlocationcode}&language_code=en&location_name=${currentlocation}&language_name=${getCountry}`
                    )
                    .then((res) => {
                      aobj.keyword = res.data;
                    });

                  const metricsPromise = axios
                    .get(
                      `https://app.rankspro.io/api/user/domainoverview/?domain_name=${domainName}&location_code=${currentlocationcode}&language_code=en&location_name=${currentlocation}&language_name=${getCountry}`
                    )
                    .then((res) => {
                      aobj.metrics = res.data;
                    });

                  Promise.all([
                    backlinkPromise,
                    keywordPromise,
                    metricsPromise,
                  ]).then(() => {
                    setDomainViewDate(aobj);
                    ZustandStore.setState({
                      site_audit_overview: aobj,
                    });
                    const etvArray = [];
                    const etvLabel = [];
                    const etvAll = [];
                    const P1to3 = [];
                    const P4to10 = [];
                    const P11to50 = [];
                    const P51to100 = [];

                    const currCount = aobj.metrics[0].metrics.organic.count;
                    setCount(currCount);

                    aobj?.metrics &&
                      aobj?.metrics?.map((res) => {
                        etvLabel.push(months[res.month - 1] + " " + res.year);
                        etvArray.push(res.metrics.organic?.etv);
                        etvAll.push(res.metrics.paid?.etv);

                        P1to3.push(
                          res.metrics.organic?.pos_1 +
                            res.metrics.organic?.pos_2_3
                        );
                        P4to10.push(res.metrics.organic?.pos_4_10);
                        P11to50.push(
                          res.metrics.organic?.pos_11_20 +
                            res.metrics.organic?.pos_21_30 +
                            res.metrics.organic?.pos_31_40 +
                            res.metrics.organic?.pos_41_50
                        );
                        P51to100.push(
                          res.metrics.organic?.pos_51_60 +
                            res.metrics.organic?.pos_61_70 +
                            res.metrics.organic?.pos_71_80 +
                            res.metrics.organic?.pos_81_90 +
                            res.metrics.organic?.pos_91_100
                        );
                      });

                    setEtv(etvArray);
                  });
                }
              });
              // overview congif end
              setAuditData(
                audit_data[audit_data.length - 1]?.data?.[0]?.result
              );
              setAllAuditData(audit_data[audit_data.length - 1]);

              let remainLimit = 0;

              audit_data.forEach((audit_res, index) => {
                remainLimit += audit_res.limit + audit_res.queue;
              });
              if (res.data[0].domain === false) {
                const POST_LMT = {
                  email: admin_mail,
                  domain: domainName,
                  siteauditlmt: crawllimit,
                  date: sevenDaysLaterDate,
                };
                axios.post(POST_SITEAUDIT_LMT(), POST_LMT);
              } else {
                const POST_LMT = {
                  email: admin_mail,
                  domain: domainName,
                  limit: crawllimit - remainLimit,
                  date: res.data[0]?.date,
                };
                axios.put(SITE_AUDIT_UPDATE(POST_LMT));
              }
            } else {
              if (res.data[0]?.domain_limit >= crawldomainlimit) {
                setPostAlert("Your Domain limit is Exceedded");
                setPostApiCall({
                  showPostComp: false,
                  showGetComp: false,
                });
              } else {
                setPostApiCall({
                  showPostComp: true,
                  showGetComp: false,
                });
              }
            }
          });
        });
      }
    }
  };

  const handleAddQuery = () => {
    setQueryParams({ domain: websiteUrl });
  };

  const handleRemoveQuery = () => {
    removeQueryParams(["domain"]);
  };

  useEffect(() => {
    if (websiteUrl && getCountry && curr_proj) {
      handleAddQuery();
      setAuditData([]);
      fatch_Site_Audit();
    } else {
      handleRemoveQuery();
    }
  }, [websiteUrl, getCountry, crawllimit, curr_proj]);

  // set , get audit datas
  useEffect(() => {
    if (auditData.length !== 0) {
      const crawl_progress = auditData?.[0]?.crawl_progress;
      const site_audit_pages = auditData?.[0];
      const audit_item = auditData?.[0]?.items || [];
      if (audit_item?.length === 0) {
        handleNoData();
      } else {
        setOnpage_score(auditData?.[0]?.items[0]?.onpage_score || 0);
        setConnectionTime(
          auditData?.[0]?.items[0]?.page_timing?.connection_time || 0
        );
        setTime_to_interactive(
          auditData?.[0]?.items[0]?.page_timing?.time_to_interactive || 0
        );
        setfatchTime(auditData?.[0]?.items[0]?.page_timing?.fetch_end || 0);
        setTotalTypes(auditData?.[0]?.items);

        let resource_type_html = 0;
        let resource_type_redirect = 0;
        let resource_type_broken = 0;
        let resource_type_block = 0;
        let low_word_count = [];
        let dup_meta_desc = [];
        let dup_title_tag = [];
        let no_title_Tags = [];
        let with_h1 = [];
        let with_broken = [];
        let without_meta = [];
        let with_title_too = [];
        let with_title_short = [];
        let poorly_format = [];
        let doc_not_decl = [];
        let long_load_time = [];
        let url_long = [];
        let short_desc = [];
        let long_desc = [];
        let duplicate_h1_tags = [];
        let same_h1_and_title_tags = [];
        let conanical_issue = [];
        let no_favicon = [];
        let no_image_alt = [];
        let no_image_title = [];

        auditData[0]?.items &&
          auditData[0]?.items?.forEach((res) => {
            if (res.resource_type === "html") {
              resource_type_html += 1;

              // long description
              if (res?.meta?.description_length > 165) {
                long_desc.push({
                  url: res?.url || "No URL",
                  title: res?.meta?.title || "No title",
                  desc: res?.meta?.description || "No description",
                  length: res?.meta?.description_length || "0",
                  status_code: res?.status_code || "No status code",
                });
              }
              // short description
              if (res?.meta?.description_length < 150) {
                short_desc.push({
                  url: res?.url || "No URL",
                  title: res?.meta?.title || "No title",
                  desc: res?.meta?.description || "No description",
                  length: res?.meta?.description_length || "0",
                  status_code: res?.status_code || "No status code",
                });
              }

              // duplicate H1 tag
              if (res?.meta?.htags) {
                const h1Tags = res?.meta?.htags?.h1;
                // Check the number of h1 tags
                if (h1Tags?.length > 1) {
                  duplicate_h1_tags.push({
                    url: res?.url || "No URL",
                    title: res?.meta?.title || "No title",
                    no_of_h1_tags: h1Tags?.length || "0",
                    status_code: res?.status_code || "No status code",
                  });
                }
              }

              // same H1 and title tags
              if (res?.meta?.htags) {
                const h1Tags = res?.meta?.htags?.h1 || "NO H1 tags";
                const title = res?.meta?.title || "No title";
                // Check the number of h1 tags
                if (h1Tags?.includes(title)) {
                  same_h1_and_title_tags.push({
                    url: res?.url || "No URL",
                    title: res?.meta?.title || "No title",
                    h1_tags: title || "No H1 tags",
                    status_code: res?.status_code || "No Status Code",
                  });
                }
              }

              // conanical issue
              if (!res?.checks?.canonical) {
                conanical_issue.push({
                  url: res?.url || "No URL",
                  title: res?.meta?.title || "No title",
                  status_code: res?.status_code || "No Status Code",
                });
              }

              // no favicons found
              if (res?.checks?.no_favicon) {
                no_favicon.push({
                  url: res?.url || "No URL",
                  title: res?.meta?.title || "No title",
                  status_code: res?.status_code || "No status code",
                });
              }

              // no favicons found
              if (res?.checks?.no_image_alt) {
                no_image_alt.push({
                  url: res?.url || "No URL",
                  title: res?.meta?.title || "No title",
                  status_code: res?.status_code || "No status code",
                });
              }
              // no image title found
              if (res?.checks?.no_image_title) {
                no_image_title.push({
                  url: res?.url || "No URL",
                  title: res?.meta?.title || "No title",
                  status_code: res?.status_code || "No status code",
                });
              }

              // set low word count
              if (res?.meta?.content?.plain_text_word_count < 200) {
                low_word_count.push({
                  url: res.meta.canonical || "No Canonical URL",
                  title: res.meta.title || "No Title",
                  count: res.meta.content.plain_text_word_count || "No Content",
                  status_code: res?.status_code || "No Status Code",
                });
              }

              if (res.duplicate_description === true) {
                dup_meta_desc.push({
                  url: res.url || "No URL",
                  title: res.meta.title || "No Title",
                  desc: res.meta.description || "No description",
                });
              }

              if (res.checks.duplicate_title_tag === true) {
                dup_title_tag.push({
                  url: res.url || "No URL",
                  title: res.meta.title || "No title",
                });
              }

              if (res.checks.no_title === true) {
                no_title_Tags.push({
                  url: res.url || "No URL",
                  title: res.meta.title || "No title",
                });
              }

              if (res.checks.no_h1_tag === true) {
                with_h1.push({
                  url: res.url || "No URL",
                  title: res.meta.title || "No title",
                });
              }

              if (res.checks.is_broken === true) {
                with_broken.push({
                  url: res.url || "No URL",
                  title: res.meta.title || "No title",
                });
              }

              if (res.checks.no_description === true) {
                without_meta.push({
                  url: res.url || "No URL",
                  title: res.meta.title || "No title",
                });
              }
              if (res.checks.title_too_long === true) {
                with_title_too.push({
                  url: res.url || "No URL",
                  title: res.meta.title || "No title",
                  length: res?.meta?.title_length || "0",
                  status_code: res?.status_code || "No status code",
                });
              }
              if (res.checks.title_too_short === true) {
                with_title_short.push({
                  url: res.url || "No URL",
                  title: res.meta.title || "No title",
                  length: res?.meta?.title_length || "0",
                  status_code: res?.status_code || "No status code",
                });
              }
              if (res.checks.seo_friendly_url === false) {
                poorly_format.push({
                  url: res.url || "No URL",
                  status_code: res?.status_code || "No status code",
                  characters:
                    res.checks.seo_friendly_url_characters_check || "undefined",
                  dynamic:
                    res.checks.seo_friendly_url_dynamic_check || "undefined",
                  keywords:
                    res.checks.seo_friendly_url_keywords_check || "undefined",
                  title: res.meta.title || "title",
                });
              }
              if (res.checks.no_doctype === true) {
                doc_not_decl.push({
                  url: res.url || "No URL",
                  title: res.meta.title || "No title",
                });
              }
              if (res?.checks?.high_loading_time === true) {
                const seconds = res?.page_timing?.duration_time / 1000;
                const time = seconds?.toFixed(1) + " sec";
                long_load_time.push({
                  url: res.url || "No URL",
                  time: time || "undefined",
                  title: res.meta.title || "No title",
                  status_code: res?.status_code || "No status code",
                });
              }
              if (res.url_length > 100) {
                url_long.push({
                  url: res.url || "No URL",
                  title: res.meta.title || "No title",
                  length: res?.url_length || "0",
                  status_code: res?.status_code || "No status code",
                });
              }
            } else if (res.resource_type === "redirect") {
              resource_type_redirect += 1;
            } else if (res.resource_type === "broken") {
              resource_type_broken += 1;
            } else if (res.resource_type === "block") {
              resource_type_block += 1;
            }
          });

        setDescription((pre) => ({
          ...pre,
          short_des: short_desc,
          long_des: long_desc,
          duplicate_h1_tag: duplicate_h1_tags,
          same_h1_and_title_tags: same_h1_and_title_tags,
          conanical_issue: conanical_issue,
          no_favicon: no_favicon,
          no_image_alt: no_image_alt,
          no_image_title: no_image_title,
        }));

        setTypeSuccessfull(resource_type_html);
        setRedirected(resource_type_redirect);
        setBroken(resource_type_broken);
        setBlocked(resource_type_block);
        setLowWord_Count(low_word_count);
        // dup_meta_desc duplicate set
        const groupedData = {};
        dup_meta_desc?.forEach(({ url, desc, title }) => {
          groupedData[desc] = groupedData[desc] || { desc, url: [], title };
          groupedData[desc].url.push(url);
        });

        const result = {
          data: Object.values(groupedData),
          page: dup_meta_desc,
        };
        setDuplicateMeta_desc(result);

        // dup_title_tag duplicate set
        const dup_title_tagGroupedData = {};
        dup_title_tag?.forEach(({ url, title }) => {
          dup_title_tagGroupedData[title] = dup_title_tagGroupedData[title] || {
            title,
            url: [],
          };
          dup_title_tagGroupedData[title].url.push(url);
        });

        const dup_title_tagResult = {
          page: dup_title_tag,
          data: Object.values(dup_title_tagGroupedData),
        };
        setDuplicateTitle_tag(dup_title_tagResult);
        setNoTitle_Tags(no_title_Tags);
        setWithOut_h1(with_h1);
        setWith_broken_links(with_broken);
        setWithout_meta_desc(without_meta);
        setWith_title_tooLong(with_title_too);
        setWith_title_tooShort(with_title_short);
        setPoorly_formatted(poorly_format);
        setDoctype_not_decl(doc_not_decl);
        setLong_loading_time(long_load_time);
        seturls_too_long(url_long);
        setSeo_Descovered(
          low_word_count.length +
            dup_meta_desc.length +
            dup_title_tag.length +
            no_title_Tags.length +
            with_h1.length +
            with_broken.length +
            without_meta.length +
            with_title_too.length +
            with_title_short.length +
            poorly_format.length +
            doc_not_decl.length +
            long_load_time.length +
            url_long.length +
            short_desc?.length +
            long_desc?.length +
            duplicate_h1_tags?.length +
            same_h1_and_title_tags?.length +
            conanical_issue?.length +
            no_favicon?.length +
            no_image_alt?.length +
            no_image_title?.length
        );
      }
    }
  }, [auditData]);

  const multipleData = [
    { filename: "Have_a_low_word_coun.csv", data: lowWord_Count },
    {
      filename: "Duplicate meta description.csv",
      data: duplicateMeta_Desc?.data,
    },
    { filename: "Duplicate title tags.csv", data: duplicateTitle_tag?.data },
    { filename: "With no title Tags.csv", data: no_title_tags },
    { filename: "Without H1 Heading.csv", data: withOut_h1 },
    { filename: "With broken links.csv", data: with_broken_links },
    { filename: "Without Meta description.csv", data: without_meta_desc },
    {
      filename: "With title tag that is too long.csv",
      data: with_title_tooLong,
    },
    {
      filename: "With title tag that is too short.csv",
      data: with_title_tooShort,
    },
    { filename: "Poorly formatted URL for SEO.csv", data: poorly_formatted },
    { filename: "doctype not declared.csv", data: doctype_not_decl },
    { filename: "Long Loading Time.csv", data: long_loading_time },
    { filename: "URLs are too long.csv", data: urls_too_long },
    {
      filename: "With Description too short.csv",
      data: description.short_des,
    },
    {
      filename: "With Description too long.csv",
      data: description.long_des,
    },
    {
      filename: "Multiple H1 tag.csv",
      data: description.duplicate_h1_tag,
    },
    {
      filename: "Duplicate H1 and Title Tag.csv",
      data: description.same_h1_and_title_tags,
    },
    {
      filename: "No canonical tag.csv",
      data: description.conanical_issue,
    },
    {
      filename: "No favicon.csv",
      data: description.no_favicon,
    },
    {
      filename: "No image alt.csv",
      data: description.no_image_alt,
    },
    {
      filename: "No image title.csv",
      data: description.no_image_title,
    },
  ];

  const handleDownload = async (file) => {
    const zip = new JSZip();
    if (file) {
      const file_name = `${file}.csv`;
      const data = multipleData?.filter((filename) => {
        return filename.filename === file_name;
      });
      if (data) {
        const csvContent = Papa.unparse(data?.[0]?.data);
        zip.file(file_name, csvContent);
        zip.generateAsync({ type: "blob" }).then((content) => {
          saveAs(content, `${file}.zip`);
        });
      }
    } else {
      await Promise.all(
        multipleData?.map(async ({ filename, data }) => {
          const csvContent = Papa.unparse(data);
          zip.file(filename, csvContent);
        })
      );

      zip.generateAsync({ type: "blob" }).then((content) => {
        saveAs(content, "SEO Issues Discovered.zip");
      });
    }
  };

  const card_data = [
    {
      id: 1,
      title: "ON-PAGE SEO SCORE",
      result: onpage_score || 0,
      img: seoImg,
      tool: "The score of your site based on Search Engines. The On-Page Score is out of 100.",
      path: "",
      color: "#48A9571A",
    },
    {
      id: 2,
      title: "ORGANIC MONTHLY TRAFFIC",
      result: Math.round(etv[0]) || 0,
      img: trafficImg,
      tool: "The total estimated traffic this domain  get considering the organic keywords .",
      path: "",
      color: "#D9E2EA",
    },
    {
      id: 3,
      title: "ORGANIC KEYWORDS",
      result: count || 0,
      img: organicImg,
      tool: "The number of keywords this domain ranks for in the organic search.",
      path: "",
      color: "#EFE6D8",
    },
    {
      id: 4,
      title: "BACKLINKS",
      result: domainviewData?.backlink || 0,
      img: backlinksImg,
      tool: "How many incoming hyperlinks from other websites to this domain.",
      path: "/backlinks",
      color: "#EED8D8",
      callBack: () => {
        dispatch({ type: "BACKLINKDOMAIN", payload: domainName });
        navigate("/backlinks");
      },
    },
  ];

  const LineData = [
    {
      title: "Successfull",
      value: typeSuccessfull,
    },
    {
      title: "Redirected",
      value: typeRedirected,
    },
    {
      title: "Broken",
      value: typeBroken,
    },
    {
      title: "Blocked",
      value: typeBlocked,
    },
  ];

  const issueTable = [
    {
      td1: "Have a Low Word Count",
      td2: lowWord_Count,
      td3: () => {
        selectedTab != 2 && setSelectedTab(2);
        setLowWord_Count_Alert(true);
      },
    },
    {
      td1: "Duplicate Meta Description",
      td2: duplicateMeta_Desc?.page,
      td3: () => {
        selectedTab != 2 && setSelectedTab(2);
        setDuplicateMeta_Desc_Alert(true);
      },
    },
    {
      td1: "Duplicate Title tags",
      td2: duplicateTitle_tag?.page,
      td3: () => {
        selectedTab != 2 && setSelectedTab(2);
        setDuplicateTitle_tag_Alert(true);
      },
    },
    {
      td1: "With no Title Tags",
      td2: no_title_tags,
      td3: () => {
        selectedTab != 2 && setSelectedTab(2);
        setNoTitle_Tags_Alert(true);
      },
    },
    {
      td1: "Without H1 Heading",
      td2: withOut_h1,
      td3: () => {
        selectedTab != 2 && setSelectedTab(2);
        setWithOut_h1_Alert(true);
      },
    },
    {
      td1: "With Broken Links",
      td2: with_broken_links,
      td3: () => {
        selectedTab != 2 && setSelectedTab(2);
        setWith_broken_links_Alert(true);
      },
    },
    {
      td1: "Without Meta Description",
      td2: without_meta_desc,
      td3: () => {
        selectedTab != 2 && setSelectedTab(2);
        setWithout_meta_desc_Alert(true);
      },
    },
    {
      td1: "With Title tag that is too long",
      td2: with_title_tooLong,
      td3: () => {
        selectedTab != 2 && setSelectedTab(2);
        setWith_title_tooLong_Alert(true);
      },
    },
    {
      td1: "With Title tag that is too short",
      td2: with_title_tooShort,
      td3: () => {
        selectedTab != 2 && setSelectedTab(2);
        setWith_title_tooShort_Alert(true);
      },
    },
    {
      td1: "With Description too long",
      td2: description?.long_des,
      td3: () => {
        selectedTab != 2 && setSelectedTab(2);
        setDescription((pre) => ({ ...pre, show_long_des: true }));
      },
    },
    {
      td1: "With Description too short",
      td2: description?.short_des,
      td3: () => {
        selectedTab != 2 && setSelectedTab(2);
        setDescription((pre) => ({ ...pre, show_short_des: true }));
      },
    },
    {
      td1: "Multiple H1 tag",
      td2: description?.duplicate_h1_tag,
      td3: () => {
        selectedTab != 2 && setSelectedTab(2);
        setDescription((pre) => ({ ...pre, show_duplicate_h1_tag: true }));
      },
    },
    {
      td1: "Duplicate H1 and Title Tag",
      td2: description?.same_h1_and_title_tags,
      td3: () => {
        selectedTab != 2 && setSelectedTab(2);
        setDescription((pre) => ({ ...pre, show_same_h1_and_titles: true }));
      },
    },
    {
      td1: "No canonical tag",
      td2: description?.conanical_issue,
      td3: () => {
        selectedTab != 2 && setSelectedTab(2);
        setDescription((pre) => ({ ...pre, show_conanical_issues: true }));
      },
    },
    {
      td1: "No favicon",
      td2: description?.no_favicon,
      td3: () => {
        selectedTab != 2 && setSelectedTab(2);
        setDescription((pre) => ({ ...pre, show_no_favicon: true }));
      },
    },
    {
      td1: "No image alt",
      td2: description?.no_image_alt,
      td3: () => {
        selectedTab != 2 && setSelectedTab(2);
        setDescription((pre) => ({ ...pre, show_no_image_alt: true }));
      },
    },
    {
      td1: "No image title",
      td2: description?.no_image_title,
      td3: () => {
        selectedTab != 2 && setSelectedTab(2);
        setDescription((pre) => ({ ...pre, show_no_image_title: true }));
      },
    },
    {
      td1: "Poorly formatted URL for SEO",
      td2: poorly_formatted,
      td3: () => {
        selectedTab != 2 && setSelectedTab(2);
        setPoorly_formatted_Alert(true);
      },
    },
    {
      td1: "Doctype not Declared",
      td2: doctype_not_decl,
      td3: () => {
        selectedTab != 2 && setSelectedTab(2);
        setDoctype_not_decl_Alert(true);
      },
    },
    {
      td1: "Long Loading Time",
      td2: long_loading_time,
      td3: () => {
        selectedTab != 2 && setSelectedTab(2);
        setLong_loading_time_Alert(true);
      },
    },
    {
      td1: "URLs are too long",
      td2: urls_too_long,
      td3: () => {
        selectedTab != 2 && setSelectedTab(2);
        seturls_too_long_Alert(true);
      },
    },
  ];

  const total = totalTypes?.length || 0;
  const totalPages = queueData + total;
  const crawledPagesProgress = (total / totalPages) * 100;
  const percentage = Math.floor(crawledPagesProgress) || 1;

  useEffect(() => {
    let interval;
    if (percentage === 1) {
      let number = 0;
      interval = setInterval(() => {
        number += 1;
        setProgress(number);
        if (number > 5) {
          clearInterval(interval);
        }
      }, 20000); // 20000 milliseconds = 20 seconds
    } else {
      setProgress(Math.floor(crawledPagesProgress));
    }

    // Cleanup interval on unmount or when dependencies change
    return () => clearInterval(interval);
  }, [percentage, crawledPagesProgress]);

  useEffect(() => {
    const percentage = Math.floor(crawledPagesProgress);
    if (percentage === 100 || !postApiCall?.showGetComp) {
      setBannerAlert(false);
      setReCrawlStatus(false);
    } else if (
      reCrawlStatus &&
      percentage != 100 &&
      websiteUrl &&
      currentlocation &&
      crawllimit
    ) {
      const interval = 60000;
      const intervalId = setInterval(() => {
        fatch_Site_Audit();
      }, interval);
      return () => clearInterval(intervalId);
    } else if (percentage >= 0 && percentage <= 100) {
      if (!reCrawlStatus && postApiCall?.showGetComp) {
        setReCrawlStatus(true);
        setBannerAlert(true);
      }
    }
  }, [
    crawledPagesProgress,
    reCrawlStatus,
    websiteUrl,
    currentlocation,
    crawllimit,
    postApiCall,
  ]);

  function handleNaNAndUndefined(value) {
    if (isNaN(value)) {
      return 0;
    } else if (value === undefined) {
      return 0;
    } else {
      return value;
    }
  }

  // exporting the pdf file logic

  const targetDashboard = useRef();


 




  const handleRoute =()=>{
    const element = document.getElementById("dash");
  
    if (element) {
      html2canvas(element, {
        useCORS: true, // If your container has external images, this helps to prevent cross-origin issues.
        backgroundColor: null, // Set to null if you want a transparent background.
      }).then((canvas) => {
        // Convert the canvas to a data URL in JPG format
        const imgData = canvas.toDataURL("image/jpeg");
  
        // Save the image data to sessionStorage
        sessionStorage.setItem("dashboardImage", imgData);
      }).catch((error) => {
        console.error("Error capturing the dashboard as an image:", error);
      });
    }

    sessionStorage.setItem('pdfSessionData',JSON.stringify({websiteurl:websiteurl,id:9}))
    setTimeout(() => {
      navigate("/CrawledPageEditor");
    }, 500)
     
    }

  const GenerateDashboardPDF = () => {
    const input = targetDashboard.current;
    const pdf = new jsPDF("p", "mm", "a4");
    const margin = 10; // Set the margin size
    const pageWidth = pdf.internal.pageSize.getWidth();
    const pageHeight = pdf.internal.pageSize.getHeight();

    // Add cover page
    pdf.setFontSize(20);
    pdf.setFont("helvetica");
    pdf.text("Site Audit", pageWidth / 2, pageHeight / 3 + 60, {
      align: "center",
    });

    pdf.setFontSize(16);
    pdf.setFont("helvetica", "normal");
    pdf.setTextColor("gray");
    pdf.text(`Domain:${websiteurl}`, pageWidth / 2, pageHeight / 3 + 70, {
      align: "center",
    });

    // Add your image to the cover page
    const imageURL = RanksProLogo; // Replace with your image URL or data URI
    const imageWidth = 74; // Adjust width as needed
    const imageHeight = 32; // Adjust height as needed
    pdf.addImage(
      imageURL,
      "PNG",
      (pageWidth - imageWidth) / 2,
      pageHeight / 3 - imageHeight + 60 - 5,
      imageWidth,
      imageHeight
    );

    pdf.addPage(); // Add a new page for the actual content

    // Capture and add the main content
    html2canvas(input, { scale: 2 }).then((canvas) => {
      const imgData = canvas.toDataURL("image/png");
      const imgWidth = pageWidth - 2 * margin;
      const imgHeight = (canvas.height * imgWidth) / canvas.width;

      let position = margin;

      // Add logo to the top-left corner of the second page
      pdf.addImage(RanksProLogo, "PNG", margin, margin, 40, 17); // Adjust size as needed

      pdf.addImage(imgData, "PNG", margin, position + 20, imgWidth, imgHeight);

      // Add additional pages if content exceeds one page
      while (position + imgHeight > pageHeight - margin) {
        position -= pageHeight - 2 * margin;
        pdf.addPage();
        pdf.addImage(RanksProLogo, "PNG", margin, margin, 30, 20); // Add logo on each new page
        pdf.addImage(
          imgData,
          "PNG",
          margin,
          position + 20,
          imgWidth,
          imgHeight
        );
      }

      pdf.save("Dashboard.pdf");
    });
  };

  return (
    <main className="site-audit-container w-100 main-pd">
      {postAlert ? <LineAlert data={postAlert} /> : false}
      {data ? (
        <>
          <AnimatePresence mode="wait">
            {bannerAlert ? (
              <motion.div
                className="sie-audit-banner"
                id="sie-audit-banner"
                initial={{ y: 10, opacity: 0 }}
                animate={{ y: 0, opacity: 1 }}
                exit={{ y: -10, opacity: 0 }}
                transition={{ duration: 1 }}
              >
                <div className="site-audit-loader">
                  <div className="circle-loader">
                    <span className="percent">
                      {handleNaNAndUndefined(
                        percentage === 1 || true
                          ? progress
                          : Math.floor(crawledPagesProgress)
                      )}
                      %
                    </span>
                  </div>
                  <div className="linear-loader">
                    <LinearProgress
                      variant="determinate"
                      value={
                        percentage === 1 || true
                          ? progress
                          : Math.floor(crawledPagesProgress)
                      }
                      className="w-100"
                      sx={{
                        "& .MuiLinearProgress-bar": {
                          backgroundColor: "rgba(255, 255, 255, 0.8)",
                          borderRadius: "10px",
                        },
                        height: "5px",
                        borderRadius: "10px !important",
                        backgroundColor: "rgba(255, 255, 255, 0.1)",
                      }}
                    />
                  </div>
                </div>
              </motion.div>
            ) : null}
          </AnimatePresence>

          {postApiCall?.showGetComp ? (
            <section
              className={
                auditData.length === 0 && postApiCall?.showGetComp
                  ? "loading-skeleton page-tab-container"
                  : "page-tab-container"
              }
            >
              <nav
                className={`page-tab-nav ${
                  selectedTab === 3 || selectedTab === 2 ? "d-none" : ""
                }`}
              >
                <ul>
                  <li
                    className={selectedTab === 1 ? "selected" : ""}
                    onClick={() => setSelectedTab(1)}
                  >
                    Dashboard
                  </li>
                  <li
                    className={selectedTab === 2 ? "selected" : ""}
                    onClick={() => setSelectedTab(2)}
                  >
                    SEO Issues
                  </li>
                  <li
                    className={selectedTab === 3 ? "selected" : ""}
                    onClick={() => setSelectedTab(3)}
                  >
                    Crawled Pages
                  </li>
                </ul>

                <FiDownload
                  className="down"
                  onClick={handleRoute}
                  title="download Dashboard PDF"
                />


              </nav>
              <AnimatePresence mode="wait">
                <motion.div
                  key={selectedTab ? selectedTab : "empty"}
                  initial={{ y: 10, opacity: 0 }}
                  animate={{ y: 0, opacity: 1 }}
                  exit={{ y: -10, opacity: 0 }}
                  transition={{ duration: 0.1 }}
                >
                  {selectedTab === 1 ? (
                    <div ref={targetDashboard} id="dash" style={{backgroundColor:"#fffbf5"}}>
                      <section className="dashboard-cont row gx-1 gx-md-4 gy-3" style={{backgroundColor:"#fffbf5"}}>
                        <div className="col-12 col-md-12 col-lg-7 col-xl-6">
                          <div className="project-inf-cont p-0">
                            <div className="h-50 project-lis">
                              <div className="row m-0">
                                <div className="col-9 col-sm-12">
                                  {AllauditData && AllauditData?.date ? (
                                    <div className="last-crwal-tim mb-2">
                                      <span className="heading-medium font-medium">
                                        Last Crawled :{" "}
                                      </span>
                                      <small className="heading-medium font-medium">{`${
                                        AllauditData?.date || ""
                                      } ${AllauditData?.month || ""}, ${
                                        AllauditData?.year || ""
                                      }`}</small>
                                    </div>
                                  ) : null}
                                </div>
                                <div className="col-3">
                                  {auditData?.length === 0
                                    ? null
                                    : crawllimit === 0
                                    ? null
                                    : !matches &&
                                      percentage === 100 && (
                                        <button
                                          className="icon-btn"
                                          onClick={() => RecrawlHandler()}
                                          title="Recrawl"
                                        >
                                          <img src={recrawlIcon} alt="" />
                                        </button>
                                      )}
                                </div>
                              </div>

                              <div className="row m-0 p-0 py-2 py-md-0 p-md-4">
                                <div className="col-12 col-sm-10">
                                  <ProjectList
                                    style={{
                                      padding: "0.9em 1em",
                                      width: "100%",
                                      textTransform: "none",
                                      border: "1px solid #FFFFFF",
                                      borderRadius: "10px",
                                      boxShadow: "none",
                                      fontWeight: "500",
                                      color: "#FFFFFF",
                                      height: "50px",
                                    }}
                                  />
                                  <div className="row gy-2 gx-4 mt-2 mt-md-4">
                                    {card_data?.map((data) => {
                                      return (
                                        <div
                                          className={"col-6"}
                                          style={{
                                            cursor:
                                              data.title === "BACKLINKS"
                                                ? "pointer"
                                                : "auto",
                                          }}
                                          key={`overview-card-${data?.title}`}
                                        >
                                          <div
                                            className="site-card text-start fst-normal"
                                            onClick={data.callBack}
                                          >
                                            <div className="w-100 site-card-cont">
                                              <h1 className="text-dark text-uppercase">
                                                {data?.title}
                                                <ToolTip title={data.tool} />
                                              </h1>
                                              <h2 className="text-dark mb-0">
                                                {0 ||
                                                  data?.result?.toLocaleString()}
                                              </h2>
                                            </div>
                                          </div>
                                        </div>
                                      );
                                    })}
                                  </div>
                                </div>
                                <div className="col-2">
                                  {auditData.length === 0
                                    ? null
                                    : crawllimit === 0
                                    ? null
                                    : matches &&
                                      percentage === 100 && (
                                        <button
                                          className="icon-btn"
                                          onClick={() => RecrawlHandler()}
                                          title="Recrawl"
                                        >
                                          <img src={recrawlIcon} alt="" />
                                        </button>
                                      )}
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="col-12 col-md-6 col-lg-5 col-xl-3">
                          <div
                            className="seo-issue-cont"
                            id="seo-issues-discovered"
                          >
                            <div className="cmd mb-3">
                              <div className="d-flex align-items-center">
                                <h1 className="heading mb-0">
                                  SEO Issues Discovered :
                                </h1>
                                <h1 className="seo-desc mx-2 mb-0">
                                  {Seo_descovered}
                                </h1>
                              </div>
                              <div className="d-flex align-items-center">
                                <button
                                  className="icon-btn me-2"
                                  title="Download SEO Issues File"
                                  onClick={() => handleDownload()}
                                >
                                  <img src={exportFileIcon} alt="" />
                                </button>
                                <button
                                  className="icon-btn"
                                  title="SEO Issues Table"
                                  onClick={() => setSelectedTab(2)}
                                >
                                  <img src={seoIssueIcon} alt="" />
                                </button>
                              </div>
                            </div>
                            <div className="content">
                              {issueTable?.length > 0 ? (
                                <>
                                  <div className="row header">
                                    <div className="col-6">
                                      <div className="text-start head justify-content-start">
                                        Top SEO Issues
                                      </div>
                                    </div>
                                    <div className="col-3">
                                      <div className="text-center head">
                                        Pages
                                      </div>
                                    </div>
                                    <div className="col-3">
                                      <div className="text-center head">
                                        Status
                                      </div>
                                    </div>
                                  </div>
                                  {issueTable?.slice(0, 6)?.map((item) => {
                                    return (
                                      <div
                                        className="row seo-issue-row"
                                        key={item.td1}
                                      >
                                        <div className="col-6 text-start">
                                          {item?.td1}
                                        </div>
                                        <div className="col-3 text-center">
                                          {item?.td2?.length}
                                        </div>
                                        <div className="col-3 text-center v_All">
                                          <img
                                            src={viewIcon}
                                            alt=""
                                            style={{
                                              width: "30px",
                                              objectFit: "contain",
                                            }}
                                            onClick={
                                              item?.td2?.length > 0
                                                ? item.td3
                                                : () => {}
                                            }
                                          />
                                        </div>
                                      </div>
                                    );
                                  })}
                                </>
                              ) : null}
                            </div>
                          </div>
                        </div>
                        <div className="col-12 col-md-6 col-lg-12 col-xl-3">
                          <div className="page-status-cont" id="page-status">
                            <div className="w-100 h-100 d-flex flex-column">
                              <div className="d-flex justify-content-between align-items-center mb-2">
                                <div className="d-flex justify-content-between align-items-center ">
                                  <h4 className="heading mb-0">Page Status</h4>
                                  <ToolTip title="Total no. of pages crawled with their status." />
                                </div>
                                <button
                                  onClick={() => setSelectedTab(3)}
                                  className="icon-btn"
                                  title="Page Status"
                                >
                                  <img src={pageStatusIcon} alt="" />
                                </button>
                              </div>
                              <div className="content">
                                <div className="total-cont">
                                  <p className="m-0">Total</p>
                                  <p className="m-0 px-1">-</p>
                                  <p className="m-0">{total}</p>
                                </div>
                                <div className="page-content">
                                  {LineData?.map((item) => {
                                    return (
                                      <div
                                        className="success"
                                        key={item?.title}
                                      >
                                        <span className="m-auto">
                                          {item?.title}
                                        </span>
                                        <div className="circle-progress">
                                          <CircularProgressbar
                                            className="m-0"
                                            value={(item?.value / total) * 100}
                                            text={
                                              item?.value === 0
                                                ? "0"
                                                : item?.value
                                            }
                                            background="rgba(54, 84, 134, 0.7)"
                                            strokeWidth={18}
                                            styles={buildStyles({
                                              strokeLinecap: "round",
                                              pathTransitionDuration: 0.5,
                                            })}
                                            counterClockwise={true}
                                          />
                                        </div>
                                      </div>
                                    );
                                  })}
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </section>
                      {fatchTime === undefined &&
                      time_to_interactive === undefined &&
                      connectionTime === undefined ? null : (
                        <section className="site-speed mt-4">
                          <h3 className="mb-4 pt-3 px-3 heading-large font-bold">
                            Site Speed
                          </h3>
                          <ProgressIndicator
                            show={auditData?.length > 0}
                            connectionTime={connectionTime}
                            time_to_interactive={time_to_interactive}
                            fatchTime={fatchTime}
                          />
                        </section>
                      )}
                    </div>
                  ) : selectedTab === 2 ? (
                    <section className="seo-issue-section">
                      {lowWord_Count_Alert ? (
                        <>
                          <div className="d-flex align-items-center">
                            <button
                              className="icon-btn large-btn"
                              onClick={() => setLowWord_Count_Alert(false)}
                            >
                              <img src={backIcon} alt="back-button" />
                            </button>
                            <h5 className="page-status-heading">
                              Have a Low Word Count
                            </h5>

                            <button
                              title="export file"
                              className="icon-btn large-btn ms-auto"
                              onClick={() =>
                                handleDownload("Have_a_low_word_coun")
                              }
                            >
                              <img src={exportFileIcon} alt="back-button" />
                            </button>

                            <FiDownload
                              className="down"
                              data-bs-toggle="modal"
                              data-bs-target="#staticBackdrop"
                              title="download Long Title pdf"
                            />
                            <div
                              className="modal fade"
                              id="staticBackdrop"
                              data-bs-backdrop="static"
                              data-bs-keyboard="false"
                              tabindex="-1"
                              aria-labelledby="staticBackdropLabel"
                              aria-hidden="true"
                            >
                              <div className="modal-dialog">
                                <div className="modal-content">
                                  <div
                                    className="modal-header "
                                    style={{
                                      borderBottom: "none",
                                      position: "absolute",
                                      right: 0,
                                      padding: "36px",
                                      zIndex: "100",
                                    }}
                                  >
                                    <button
                                      type="button"
                                      className="btn-close "
                                      data-bs-dismiss="modal"
                                      aria-label="Close"
                                    ></button>
                                  </div>
                                  <div className="modal-body">
                                    <PdfModal
                                      tableData={lowWord_Count}
                                      showTitle={false}
                                      type="poorly_formatted"
                                      tableHeader={[
                                        "Page Url",
                                        "Word Count",
                                        "Status Code",
                                      ]}
                                      tag={"Have a Low Word Count"}
                                    />
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>

                          <AuditTable
                            tableData={lowWord_Count}
                            showTitle={false}
                            type="poorly_formatted"
                            tableHeader={[
                              "Page Url",
                              "Word Count",
                              "Status Code",
                            ]}
                          />
                        </>
                      ) : duplicateMeta_Desc_Alert ? (
                        <>
                          <div className="d-flex align-items-center">
                            <button
                              className="icon-btn large-btn"
                              onClick={() => setDuplicateMeta_Desc_Alert(false)}
                            >
                              <img src={backIcon} alt="back-button" />
                            </button>
                            <h5 className="page-status-heading">
                              Duplicate Meta Description
                            </h5>
                            <button
                              title="export file"
                              className="icon-btn large-btn ms-auto"
                              onClick={() =>
                                handleDownload("Duplicate meta description")
                              }
                            >
                              <img src={exportFileIcon} alt="back-button" />
                            </button>

                            <FiDownload
                              className="down"
                              data-bs-toggle="modal"
                              data-bs-target="#staticBackdrop"
                              title="download Long Title pdf"
                            />
                            <div
                              className="modal fade"
                              id="staticBackdrop"
                              data-bs-backdrop="static"
                              data-bs-keyboard="false"
                              tabindex="-1"
                              aria-labelledby="staticBackdropLabel"
                              aria-hidden="true"
                            >
                              <div className="modal-dialog">
                                <div className="modal-content">
                                  <div
                                    className="modal-header"
                                    style={{
                                      zIndex: "100",
                                      borderBottom: "none",
                                      position: "absolute",
                                      right: 0,
                                      padding: "36px",
                                    }}
                                  >
                                    <button
                                      type="button"
                                      className="btn-close "
                                      data-bs-dismiss="modal"
                                      aria-label="Close"
                                    ></button>
                                  </div>
                                  <div className="modal-body">
                                    <PdfModal
                                      tableData={duplicateMeta_Desc?.data}
                                      tableHeader={["Page Url", "Description"]}
                                      showTitle={false}
                                      tag={"Duplicate Meta Description"}
                                    />
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>

                          <AuditTable
                            tableData={duplicateMeta_Desc?.data}
                            tableHeader={["Page Url", "Description"]}
                            showTitle={false}
                          />
                        </>
                      ) : duplicateTitle_tag_Alert ? (
                        <>
                          <div className="d-flex align-items-center">
                            <button
                              className="icon-btn large-btn"
                              onClick={() => setDuplicateTitle_tag_Alert(false)}
                            >
                              <img src={backIcon} alt="back-button" />
                            </button>
                            <h5 className="page-status-heading">
                              Duplicate Title tags
                            </h5>
                            <button
                              title="export file"
                              className="icon-btn large-btn ms-auto"
                              onClick={() =>
                                handleDownload("Duplicate title tags")
                              }
                            >
                              <img src={exportFileIcon} alt="back-button" />
                            </button>
                          </div>
                          <AuditTable
                            tableData={duplicateTitle_tag?.data}
                            showTitle={true}
                            type="poorly_formatted"
                            tableHeader={["Title", "Page Url"]}
                          />
                        </>
                      ) : no_title_tags_Alert ? (
                        <>
                          <div className="d-flex align-items-center">
                            <button
                              className="icon-btn large-btn"
                              onClick={() => setNoTitle_Tags_Alert(false)}
                            >
                              <img src={backIcon} alt="back-button" />
                            </button>
                            <h5 className="page-status-heading">
                              With No Title tags
                            </h5>
                            <button
                              title="export file"
                              className="icon-btn large-btn ms-auto"
                              onClick={() =>
                                handleDownload("With no title Tags")
                              }
                            >
                              <img src={exportFileIcon} alt="back-button" />
                            </button>
                          </div>
                          <AuditTable
                            tableData={no_title_tags}
                            showTitle={false}
                            tableHeader={["Page Url"]}
                          />
                        </>
                      ) : withOut_h1_Alert ? (
                        <>
                          <div className="d-flex align-items-center">
                            <button
                              className="icon-btn large-btn"
                              onClick={() => setWithOut_h1_Alert(false)}
                            >
                              <img src={backIcon} alt="back-button" />
                            </button>
                            <h5 className="page-status-heading">
                              Without H1 Heading
                            </h5>
                            <button
                              title="export file"
                              className="icon-btn large-btn ms-auto"
                              onClick={() =>
                                handleDownload("Without H1 Heading")
                              }
                            >
                              <img src={exportFileIcon} alt="back-button" />
                            </button>

                            <FiDownload
                              className="down"
                              data-bs-toggle="modal"
                              data-bs-target="#staticBackdrop"
                              title="download Long Title pdf"
                            />
                            <div
                              className="modal fade"
                              id="staticBackdrop"
                              data-bs-backdrop="static"
                              data-bs-keyboard="false"
                              tabindex="-1"
                              aria-labelledby="staticBackdropLabel"
                              aria-hidden="true"
                            >
                              <div className="modal-dialog">
                                <div className="modal-content">
                                  <div
                                    className="modal-header"
                                    style={{
                                      borderBottom: "none",
                                      position: "absolute",
                                      right: 0,
                                      padding: "36px",
                                      zIndex: "100",
                                    }}
                                  >
                                    <button
                                      type="button"
                                      className="btn-close "
                                      data-bs-dismiss="modal"
                                      aria-label="Close"
                                    ></button>
                                  </div>
                                  <div className="modal-body">
                                    <PdfModal
                                      tableData={withOut_h1}
                                      showTitle={false}
                                      type="poorly_formatted"
                                      tableHeader={["Page Url"]}
                                      tag={"Without H1 Heading"}
                                    />
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                          <AuditTable
                            tableData={withOut_h1}
                            showTitle={false}
                            type="poorly_formatted"
                            tableHeader={["Page Url"]}
                          />
                        </>
                      ) : with_broken_links_Alert ? (
                        <>
                          <div className="d-flex align-items-center">
                            <button
                              className="icon-btn large-btn"
                              onClick={() => setWith_broken_links_Alert(false)}
                            >
                              <img src={backIcon} alt="back-button" />
                            </button>
                            <h5 className="page-status-heading">
                              With Broken Links
                            </h5>
                            <button
                              title="export file"
                              className="icon-btn large-btn ms-auto"
                              onClick={() =>
                                handleDownload("With broken links")
                              }
                            >
                              <img src={exportFileIcon} alt="back-button" />
                            </button>
                          </div>

                          <AuditTable
                            tableData={with_broken_links}
                            showTitle={false}
                            tableHeader={["Page Url"]}
                          />
                        </>
                      ) : without_meta_desc_Alert ? (
                        <>
                          <div className="d-flex align-items-center">
                            <button
                              className="icon-btn large-btn"
                              onClick={() => setWithout_meta_desc_Alert(false)}
                            >
                              <img src={backIcon} alt="back-button" />
                            </button>
                            <h5 className="page-status-heading">
                              Without Meta Description
                            </h5>
                            <button
                              title="export file"
                              className="icon-btn large-btn ms-auto"
                              onClick={() =>
                                handleDownload("Without Meta description")
                              }
                            >
                              <img src={exportFileIcon} alt="back-button" />
                            </button>

                            <FiDownload
                              className="down"
                              data-bs-toggle="modal"
                              data-bs-target="#staticBackdrop"
                              title="download Long Title pdf"
                            />
                            <div
                              className="modal fade"
                              id="staticBackdrop"
                              data-bs-backdrop="static"
                              data-bs-keyboard="false"
                              tabindex="-1"
                              aria-labelledby="staticBackdropLabel"
                              aria-hidden="true"
                            >
                              <div className="modal-dialog">
                                <div className="modal-content">
                                  <div
                                    className="modal-header"
                                    style={{
                                      borderBottom: "none",
                                      position: "absolute",
                                      right: 0,
                                      padding: "36px",
                                      zIndex: "100",
                                    }}
                                  >
                                    <button
                                      type="button"
                                      className="btn-close "
                                      data-bs-dismiss="modal"
                                      aria-label="Close"
                                    ></button>
                                  </div>
                                  <div className="modal-body">
                                    <PdfModal
                                      tableData={without_meta_desc}
                                      showTitle={false}
                                      tableHeader={["Page Url"]}
                                      tag={"Without Meta Description"}
                                    />
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>

                          <AuditTable
                            tableData={without_meta_desc}
                            showTitle={false}
                            tableHeader={["Page Url"]}
                          />
                        </>
                      ) : with_title_tooLong_Alert ? (
                        <>
                          <div className="d-flex align-items-center">
                            <button
                              className="icon-btn large-btn"
                              onClick={() => setWith_title_tooLong_Alert(false)}
                            >
                              <img src={backIcon} alt="back-button" />
                            </button>
                            <h5 className="page-status-heading">
                              With Title tag that is too long
                            </h5>
                            <button
                              className="icon-btn large-btn ms-auto"
                              title="export file"
                              onClick={() =>
                                handleDownload(
                                  "With title tag that is too long"
                                )
                              }
                            >
                              <img src={exportFileIcon} alt="back-button" />
                            </button>

                            <FiDownload
                              className="down"
                              data-bs-toggle="modal"
                              data-bs-target="#staticBackdrop"
                              title="download Long Title pdf"
                            />
                            <div
                              className="modal fade"
                              id="staticBackdrop"
                              data-bs-backdrop="static"
                              data-bs-keyboard="false"
                              tabindex="-1"
                              aria-labelledby="staticBackdropLabel"
                              aria-hidden="true"
                            >
                              <div className="modal-dialog">
                                <div className="modal-content">
                                  <div
                                    className="modal-header"
                                    style={{
                                      borderBottom: "none",
                                      position: "absolute",
                                      right: 0,
                                      padding: "36px",
                                      zIndex: "100",
                                    }}
                                  >
                                    <button
                                      type="button"
                                      className="btn-close "
                                      data-bs-dismiss="modal"
                                      aria-label="Close"
                                    ></button>
                                  </div>
                                  <div className="modal-body">
                                    <PdfModal
                                      tableData={with_title_tooLong}
                                      showTitle={true}
                                      type="poorly_formatted"
                                      tableHeader={[
                                        "Page Url",
                                        "HTTP Status",
                                        "Title",
                                        "Length",
                                      ]}
                                      tag={"With Title tag that is too long"}
                                    />
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>

                          <AuditTable
                            tableData={with_title_tooLong}
                            showTitle={true}
                            type="poorly_formatted"
                            tableHeader={[
                              "Page Url",
                              "HTTP Status",
                              "Title",
                              "Length",
                            ]}
                          />
                        </>
                      ) : with_title_tooShort_Alert ? (
                        <>
                          <div className="d-flex align-items-center">
                            <button
                              className="icon-btn large-btn"
                              onClick={() =>
                                setWith_title_tooShort_Alert(false)
                              }
                            >
                              <img src={backIcon} alt="back-button" />
                            </button>
                            <h5 className="page-status-heading">
                              With Title tag that is too short
                            </h5>
                            <button
                              title="export file"
                              className="icon-btn large-btn ms-auto"
                              onClick={() =>
                                handleDownload(
                                  "With title tag that is too short"
                                )
                              }
                            >
                              <img src={exportFileIcon} alt="back-button" />
                            </button>

                            <FiDownload
                              className="down"
                              data-bs-toggle="modal"
                              data-bs-target="#staticBackdrop"
                              title="download Long Title pdf"
                            />
                            <div
                              className="modal fade"
                              id="staticBackdrop"
                              data-bs-backdrop="static"
                              data-bs-keyboard="false"
                              tabindex="-1"
                              aria-labelledby="staticBackdropLabel"
                              aria-hidden="true"
                            >
                              <div className="modal-dialog">
                                <div className="modal-content">
                                  <div
                                    className="modal-header"
                                    style={{
                                      borderBottom: "none",
                                      position: "absolute",
                                      right: 0,
                                      padding: "36px",
                                    }}
                                  >
                                    <button
                                      type="button"
                                      className="btn-close "
                                      data-bs-dismiss="modal"
                                      aria-label="Close"
                                    ></button>
                                  </div>
                                  <div className="modal-body">
                                    <PdfModal
                                      tableData={with_title_tooShort}
                                      showTitle={true}
                                      type="poorly_formatted"
                                      tableHeader={[
                                        "Page Url",
                                        "HTTP Status",
                                        "Title",
                                        "Length",
                                      ]}
                                      tag={"With Title tag that is too short"}
                                    />
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                          <AuditTable
                            tableData={with_title_tooShort}
                            showTitle={true}
                            type="poorly_formatted"
                            tableHeader={[
                              "Page Url",
                              "HTTP Status",
                              "Title",
                              "Length",
                            ]}
                          />
                        </>
                      ) : poorly_formatted_Alert ? (
                        <>
                          <div className="d-flex align-items-center">
                            <button
                              className="icon-btn large-btn"
                              onClick={() => setPoorly_formatted_Alert(false)}
                            >
                              <img src={backIcon} alt="back-button" />
                            </button>
                            <h5 className="page-status-heading">
                              Poorly formatted URL for SEO
                            </h5>
                            <button
                              className="icon-btn large-btn ms-auto"
                              title="export file"
                              onClick={() =>
                                handleDownload("Poorly formatted URL for SEO")
                              }
                            >
                              <img src={exportFileIcon} alt="back-button" />
                            </button>

                            <FiDownload
                              className="down"
                              data-bs-toggle="modal"
                              data-bs-target="#staticBackdrop"
                              title="download 
                            long description pdf"
                            />
                            <div
                              className="modal fade"
                              id="staticBackdrop"
                              data-bs-backdrop="static"
                              data-bs-keyboard="false"
                              tabindex="-1"
                              aria-labelledby="staticBackdropLabel"
                              aria-hidden="true"
                            >
                              <div className="modal-dialog">
                                <div className="modal-content">
                                  <div
                                    className="modal-header"
                                    style={{
                                      borderBottom: "none",
                                      position: "absolute",
                                      right: 0,
                                      padding: "36px",
                                    }}
                                  >
                                    <button
                                      type="button"
                                      className="btn-close "
                                      data-bs-dismiss="modal"
                                      aria-label="Close"
                                    ></button>
                                  </div>
                                  <div className="modal-body">
                                    <PdfModal
                                      tableData={poorly_formatted}
                                      tableHeader={[
                                        "Page Url",
                                        "Status Code",
                                        "Characters Check",
                                        "Dynamic Check",
                                        "Keyword Check",
                                      ]}
                                      showTitle={false}
                                      type="poorly_formatted"
                                      tag={"Poorly formatted URL for SEO"}
                                    />
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>

                          <AuditTable
                            tableData={poorly_formatted}
                            tableHeader={[
                              "Page Url",
                              "Status Code",
                              "Characters Check",
                              "Dynamic Check",
                              "Keyword Check",
                            ]}
                            showTitle={false}
                            type="poorly_formatted"
                          />
                        </>
                      ) : doctype_not_decl_Alert ? (
                        <>
                          <div className="d-flex align-items-center">
                            <button
                              className="icon-btn large-btn"
                              onClick={() => setDoctype_not_decl_Alert(false)}
                            >
                              <img src={backIcon} alt="back-button" />
                            </button>
                            <h5 className="page-status-heading">
                              Doctype not Declared
                            </h5>
                            <button
                              title="export file"
                              className="icon-btn large-btn ms-auto"
                              onClick={() =>
                                handleDownload("doctype not declared")
                              }
                            >
                              <img src={exportFileIcon} alt="back-button" />
                            </button>
                          </div>

                          <AuditTable
                            tableData={doctype_not_decl}
                            showTitle={false}
                            tableHeader={["Page Url"]}
                          />
                        </>
                      ) : urls_too_long_Alert ? (
                        <>
                          <div className="d-flex align-items-center">
                            <button
                              className="icon-btn large-btn"
                              onClick={() => seturls_too_long_Alert(false)}
                            >
                              <img src={backIcon} alt="back-button" />
                            </button>
                            <h5 className="page-status-heading">
                              URLs are too long
                            </h5>
                            <button
                              className="icon-btn large-btn ms-auto"
                              title="export file"
                              onClick={() =>
                                handleDownload("URLs are too long")
                              }
                            >
                              <img src={exportFileIcon} alt="back-button" />
                            </button>

                            <FiDownload
                              className="down"
                              data-bs-toggle="modal"
                              data-bs-target="#staticBackdrop"
                              title="download Long Title pdf"
                            />
                            <div
                              className="modal fade"
                              id="staticBackdrop"
                              data-bs-backdrop="static"
                              data-bs-keyboard="false"
                              tabindex="-1"
                              aria-labelledby="staticBackdropLabel"
                              aria-hidden="true"
                            >
                              <div className="modal-dialog">
                                <div className="modal-content">
                                  <div
                                    className="modal-header"
                                    style={{
                                      borderBottom: "none",
                                      position: "absolute",
                                      right: 0,
                                      padding: "36px",
                                      zIndex: "100",
                                    }}
                                  >
                                    <button
                                      type="button"
                                      className="btn-close "
                                      data-bs-dismiss="modal"
                                      aria-label="Close"
                                    ></button>
                                  </div>
                                  <div className="modal-body">
                                    <PdfModal
                                      tableData={urls_too_long}
                                      showTitle={false}
                                      type="poorly_formatted"
                                      tableHeader={[
                                        "Page",
                                        "Status Code",
                                        "URL Length",
                                      ]}
                                      tag={"URLs are too long"}
                                    />
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                          <AuditTable
                            tableData={urls_too_long}
                            showTitle={false}
                            type="poorly_formatted"
                            tableHeader={["Page", "Status Code", "URL Length"]}
                          />
                        </>
                      ) : long_loading_time_Alert ? (
                        <>
                          <div className="d-flex align-items-center">
                            <button
                              className="icon-btn large-btn"
                              onClick={() => setLong_loading_time_Alert(false)}
                            >
                              <img src={backIcon} alt="back-button" />
                            </button>
                            <h5 className="page-status-heading">
                              Long Loading Time
                            </h5>

                            <button
                              className="icon-btn large-btn ms-auto"
                              title="export file"
                              onClick={() =>
                                handleDownload("Long Loading Time")
                              }
                            >
                              <img src={exportFileIcon} alt="back-button" />
                            </button>

                            <FiDownload
                              className="down"
                              data-bs-toggle="modal"
                              data-bs-target="#staticBackdrop"
                              title="download Long Title pdf"
                            />
                            <div
                              className="modal fade"
                              id="staticBackdrop"
                              data-bs-backdrop="static"
                              data-bs-keyboard="false"
                              tabindex="-1"
                              aria-labelledby="staticBackdropLabel"
                              aria-hidden="true"
                            >
                              <div className="modal-dialog">
                                <div className="modal-content">
                                  <div
                                    className="modal-header"
                                    style={{
                                      borderBottom: "none",
                                      position: "absolute",
                                      right: 0,
                                      padding: "36px",
                                      zIndex: "100",
                                    }}
                                  >
                                    <button
                                      type="button"
                                      className="btn-close "
                                      data-bs-dismiss="modal"
                                      aria-label="Close"
                                    ></button>
                                  </div>
                                  <div className="modal-body">
                                    <PdfModal
                                      tableData={long_loading_time}
                                      showTitle={false}
                                      tableHeader={[
                                        "Page Url",
                                        "Status Code",
                                        "Load Time",
                                      ]}
                                      tag={"Long Loading Time"}
                                    />
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                          <AuditTable
                            tableData={long_loading_time}
                            showTitle={false}
                            tableHeader={[
                              "Page Url",
                              "Status Code",
                              "Load Time",
                            ]}
                          />
                        </>
                      ) : description?.show_long_des ? (
                        <>
                          <div className="d-flex align-items-center">
                            <button
                              className="icon-btn large-btn"
                              onClick={() =>
                                setDescription((pre) => ({
                                  ...pre,
                                  show_long_des: false,
                                }))
                              }
                            >
                              <img src={backIcon} alt="back-button" />
                            </button>
                            <h5 className="page-status-heading">
                              With Description too long
                            </h5>

                            <button
                              className="icon-btn large-btn ms-auto"
                              title="export file"
                              onClick={() =>
                                handleDownload("With Description too long")
                              }
                            >
                              <img src={exportFileIcon} alt="back-button" />
                            </button>

                            <FiDownload
                              className="down"
                              data-bs-toggle="modal"
                              data-bs-target="#staticBackdrop"
                              title="download 
                            long description pdf"
                            />
                            <div
                              className="modal fade"
                              id="staticBackdrop"
                              data-bs-backdrop="static"
                              data-bs-keyboard="false"
                              tabindex="-1"
                              aria-labelledby="staticBackdropLabel"
                              aria-hidden="true"
                            >
                              <div className="modal-dialog">
                                <div className="modal-content">
                                  <div
                                    className="modal-header"
                                    style={{
                                      borderBottom: "none",
                                      position: "absolute",
                                      right: 0,
                                      padding: "36px",
                                      zIndex: "100",
                                    }}
                                  >
                                    <button
                                      type="button"
                                      className="btn-close "
                                      data-bs-dismiss="modal"
                                      aria-label="Close"
                                    ></button>
                                  </div>
                                  <div className="modal-body">
                                    <PdfModal
                                      tableData={description?.long_des}
                                      type="poorly_formatted"
                                      showTitle={false}
                                      tableHeader={[
                                        "Page Url",
                                        "HTTP Status",
                                        "Description",
                                        "Length",
                                      ]}
                                      tag={"With Description too long"}
                                    />
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                          <AuditTable
                            tableData={description?.long_des}
                            type="poorly_formatted"
                            showTitle={false}
                            tableHeader={[
                              "Page Url",
                              "HTTP Status",
                              "Description",
                              "Length",
                            ]}
                          />
                        </>
                      ) : description?.show_short_des ? (
                        <>
                          <div className="d-flex align-items-center">
                            <button
                              className="icon-btn large-btn"
                              onClick={() =>
                                setDescription((pre) => ({
                                  ...pre,
                                  show_short_des: false,
                                }))
                              }
                            >
                              <img src={backIcon} alt="back-button" />
                            </button>
                            <h5 className="page-status-heading">
                              With Description too short
                            </h5>

                            <button
                              className="icon-btn large-btn ms-auto"
                              title="export file"
                              onClick={() =>
                                handleDownload("With Description too short")
                              }
                            >
                              <img src={exportFileIcon} alt="back-button" />
                            </button>

                            <FiDownload
                              className="down"
                              data-bs-toggle="modal"
                              data-bs-target="#staticBackdrop"
                              title="download 
                            long description pdf"
                            />
                            <div
                              className="modal fade"
                              id="staticBackdrop"
                              data-bs-backdrop="static"
                              data-bs-keyboard="false"
                              tabindex="-1"
                              aria-labelledby="staticBackdropLabel"
                              aria-hidden="true"
                            >
                              <div className="modal-dialog">
                                <div className="modal-content">
                                  <div
                                    className="modal-header"
                                    style={{
                                      borderBottom: "none",
                                      position: "absolute",
                                      right: 0,
                                      padding: "36px",
                                      zIndex: "100",
                                    }}
                                  >
                                    <button
                                      type="button"
                                      className="btn-close "
                                      data-bs-dismiss="modal"
                                      aria-label="Close"
                                    ></button>
                                  </div>
                                  <div className="modal-body">
                                    <PdfModal
                                      tableData={description?.short_des}
                                      showTitle={false}
                                      type="poorly_formatted"
                                      tableHeader={[
                                        "Page Url",
                                        "HTTP Status",
                                        "Description",
                                        "Length",
                                      ]}
                                      tag={"With Description too short"}
                                    />
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                          <AuditTable
                            tableData={description?.short_des}
                            showTitle={false}
                            type="poorly_formatted"
                            tableHeader={[
                              "Page Url",
                              "HTTP Status",
                              "Description",
                              "Length",
                            ]}
                          />
                        </>
                      ) : description?.show_duplicate_h1_tag ? (
                        <>
                          <div className="d-flex align-items-center">
                            <button
                              className="icon-btn large-btn"
                              onClick={() =>
                                setDescription((pre) => ({
                                  ...pre,
                                  show_duplicate_h1_tag: false,
                                }))
                              }
                            >
                              <img src={backIcon} alt="back-button" />
                            </button>
                            <h5 className="page-status-heading">
                              Multiple H1 tag
                            </h5>

                            <button
                              className="icon-btn large-btn ms-auto"
                              title="export file"
                              onClick={() => handleDownload("Multiple H1 tag")}
                            >
                              <img src={exportFileIcon} alt="back-button" />
                            </button>

                            <FiDownload
                              className="down"
                              data-bs-toggle="modal"
                              data-bs-target="#staticBackdrop"
                              title="download 
                            long description pdf"
                            />
                            <div
                              className="modal fade"
                              id="staticBackdrop"
                              data-bs-backdrop="static"
                              data-bs-keyboard="false"
                              tabindex="-1"
                              aria-labelledby="staticBackdropLabel"
                              aria-hidden="true"
                            >
                              <div className="modal-dialog">
                                <div className="modal-content">
                                  <div
                                    className="modal-header"
                                    style={{
                                      borderBottom: "none",
                                      position: "absolute",
                                      right: 0,
                                      padding: "36px",
                                      zIndex: "100",
                                    }}
                                  >
                                    <button
                                      type="button"
                                      className="btn-close "
                                      data-bs-dismiss="modal"
                                      aria-label="Close"
                                    ></button>
                                  </div>
                                  <div className="modal-body">
                                    <PdfModal
                                      tableData={description?.duplicate_h1_tag}
                                      showTitle={false}
                                      type="poorly_formatted"
                                      tableHeader={[
                                        "Page Url",
                                        "HTTP Status",
                                        "No. of H1 Tags",
                                      ]}
                                      tag={"Multiple H1 tag"}
                                    />
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                          <AuditTable
                            tableData={description?.duplicate_h1_tag}
                            showTitle={false}
                            type="poorly_formatted"
                            tableHeader={[
                              "Page Url",
                              "HTTP Status",
                              "No. of H1 Tags",
                            ]}
                          />
                        </>
                      ) : description?.show_same_h1_and_titles ? (
                        <>
                          <div className="d-flex align-items-center">
                            <button
                              className="icon-btn large-btn"
                              onClick={() =>
                                setDescription((pre) => ({
                                  ...pre,
                                  show_same_h1_and_titles: false,
                                }))
                              }
                            >
                              <img src={backIcon} alt="back-button" />
                            </button>
                            <h5 className="page-status-heading">
                              Duplicate H1 and Title Tag
                            </h5>

                            <button
                              className="icon-btn large-btn ms-auto"
                              title="export file"
                              onClick={() =>
                                handleDownload("Duplicate H1 and Title Tag")
                              }
                            >
                              <img src={exportFileIcon} alt="back-button" />
                            </button>

                            <FiDownload
                              className="down"
                              data-bs-toggle="modal"
                              data-bs-target="#staticBackdrop"
                              title="download 
                            long description pdf"
                            />
                            <div
                              className="modal fade"
                              id="staticBackdrop"
                              data-bs-backdrop="static"
                              data-bs-keyboard="false"
                              tabindex="-1"
                              aria-labelledby="staticBackdropLabel"
                              aria-hidden="true"
                            >
                              <div className="modal-dialog">
                                <div className="modal-content">
                                  <div
                                    className="modal-header"
                                    style={{
                                      borderBottom: "none",
                                      position: "absolute",
                                      right: 0,
                                      padding: "36px",
                                      zIndex: "100",
                                    }}
                                  >
                                    <button
                                      type="button"
                                      className="btn-close "
                                      data-bs-dismiss="modal"
                                      aria-label="Close"
                                    ></button>
                                  </div>
                                  <div className="modal-body">
                                    <PdfModal
                                      tableData={
                                        description?.same_h1_and_title_tags
                                      }
                                      showTitle={false}
                                      type="poorly_formatted"
                                      tableHeader={[
                                        "Page Url",
                                        "HTTP Status",
                                        "H1 Tag",
                                      ]}
                                      tag={"Duplicate H1 and Title Tag"}
                                    />
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                          <AuditTable
                            tableData={description?.same_h1_and_title_tags}
                            showTitle={false}
                            type="poorly_formatted"
                            tableHeader={["Page Url", "HTTP Status", "H1 Tag"]}
                          />
                        </>
                      ) : description?.show_conanical_issues ? (
                        <>
                          <div className="d-flex align-items-center">
                            <button
                              className="icon-btn large-btn"
                              onClick={() =>
                                setDescription((pre) => ({
                                  ...pre,
                                  show_conanical_issues: false,
                                }))
                              }
                            >
                              <img src={backIcon} alt="back-button" />
                            </button>
                            <h5 className="page-status-heading">
                              No canonical tag
                            </h5>

                            <button
                              className="icon-btn large-btn ms-auto"
                              title="export file"
                              onClick={() => handleDownload("No canonical tag")}
                            >
                              <img src={exportFileIcon} alt="back-button" />
                            </button>

                            <FiDownload
                              className="down"
                              data-bs-toggle="modal"
                              data-bs-target="#staticBackdrop"
                              title="download 
                            long description pdf"
                            />
                            <div
                              className="modal fade"
                              id="staticBackdrop"
                              data-bs-backdrop="static"
                              data-bs-keyboard="false"
                              tabindex="-1"
                              aria-labelledby="staticBackdropLabel"
                              aria-hidden="true"
                            >
                              <div className="modal-dialog">
                                <div className="modal-content">
                                  <div
                                    className="modal-header"
                                    style={{
                                      borderBottom: "none",
                                      position: "absolute",
                                      right: 0,
                                      padding: "36px",
                                      zIndex: "100",
                                    }}
                                  >
                                    <button
                                      type="button"
                                      className="btn-close "
                                      data-bs-dismiss="modal"
                                      aria-label="Close"
                                    ></button>
                                  </div>
                                  <div className="modal-body">
                                    <PdfModal
                                      tableData={description?.conanical_issue}
                                      showTitle={false}
                                      type="poorly_formatted"
                                      tableHeader={["Page Url", "HTTP Status"]}
                                      tag={"No canonical tag"}
                                    />
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                          <AuditTable
                            tableData={description?.conanical_issue}
                            showTitle={false}
                            type="poorly_formatted"
                            tableHeader={["Page Url", "HTTP Status"]}
                          />
                        </>
                      ) : description?.show_no_favicon ? (
                        <>
                          <div className="d-flex align-items-center">
                            <button
                              className="icon-btn large-btn"
                              onClick={() =>
                                setDescription((pre) => ({
                                  ...pre,
                                  show_no_favicon: false,
                                }))
                              }
                            >
                              <img src={backIcon} alt="back-button" />
                            </button>
                            <h5 className="page-status-heading">No favicon</h5>

                            <button
                              className="icon-btn large-btn ms-auto"
                              title="export file"
                              onClick={() => handleDownload("No favicon")}
                            >
                              <img src={exportFileIcon} alt="back-button" />
                            </button>

                            <FiDownload
                              className="down"
                              data-bs-toggle="modal"
                              data-bs-target="#staticBackdrop"
                              title="download Long Title pdf"
                            />
                            <div
                              className="modal fade"
                              id="staticBackdrop"
                              data-bs-backdrop="static"
                              data-bs-keyboard="false"
                              tabindex="-1"
                              aria-labelledby="staticBackdropLabel"
                              aria-hidden="true"
                            >
                              <div className="modal-dialog">
                                <div className="modal-content">
                                  <div
                                    className="modal-header "
                                    style={{
                                      borderBottom: "none",
                                      position: "absolute",
                                      right: 0,
                                      padding: "36px",
                                      zIndex: "100",
                                    }}
                                  >
                                    <button
                                      type="button"
                                      className="btn-close "
                                      data-bs-dismiss="modal"
                                      aria-label="Close"
                                    ></button>
                                  </div>
                                  <div className="modal-body">
                                    <PdfModal
                                      tableData={description?.no_favicon}
                                      showTitle={false}
                                      type="poorly_formatted"
                                      tableHeader={["Page Url", "HTTP Status"]}
                                      tag={"No favicon"}
                                    />
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                          <AuditTable
                            tableData={description?.no_favicon}
                            showTitle={false}
                            type="poorly_formatted"
                            tableHeader={["Page Url", "HTTP Status"]}
                          />
                        </>
                      ) : description?.show_no_image_alt ? (
                        <>
                          <div className="d-flex align-items-center">
                            <button
                              className="icon-btn large-btn"
                              onClick={() =>
                                setDescription((pre) => ({
                                  ...pre,
                                  show_no_image_alt: false,
                                }))
                              }
                            >
                              <img src={backIcon} alt="back-button" />
                            </button>
                            <h5 className="page-status-heading">
                              No image alt
                            </h5>

                            <button
                              className="icon-btn large-btn ms-auto"
                              title="export file"
                              onClick={() => handleDownload("No image alt")}
                            >
                              <img src={exportFileIcon} alt="back-button" />
                            </button>

                            <FiDownload
                              className="down"
                              data-bs-toggle="modal"
                              data-bs-target="#staticBackdrop"
                              title="download Long Title pdf"
                            />
                            <div
                              className="modal fade"
                              id="staticBackdrop"
                              data-bs-backdrop="static"
                              data-bs-keyboard="false"
                              tabindex="-1"
                              aria-labelledby="staticBackdropLabel"
                              aria-hidden="true"
                            >
                              <div className="modal-dialog">
                                <div className="modal-content">
                                  <div
                                    className="modal-header"
                                    style={{
                                      borderBottom: "none",
                                      position: "absolute",
                                      right: 0,
                                      padding: "36px",
                                      zIndex: "100",
                                    }}
                                  >
                                    <button
                                      type="button"
                                      className="btn-close "
                                      data-bs-dismiss="modal"
                                      aria-label="Close"
                                    ></button>
                                  </div>
                                  <div className="modal-body">
                                    <PdfModal
                                      tableData={description?.no_image_alt}
                                      showTitle={false}
                                      type="poorly_formatted"
                                      tableHeader={["Page Url", "HTTP Status"]}
                                      tag={"No image alt"}
                                    />
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                          <AuditTable
                            tableData={description?.no_image_alt}
                            showTitle={false}
                            type="poorly_formatted"
                            tableHeader={["Page Url", "HTTP Status"]}
                          />
                        </>
                      ) : description?.show_no_image_title ? (
                        <>
                          <div className="d-flex align-items-center">
                            <button
                              className="icon-btn large-btn"
                              onClick={() =>
                                setDescription((pre) => ({
                                  ...pre,
                                  show_no_image_title: false,
                                }))
                              }
                            >
                              <img src={backIcon} alt="back-button" />
                            </button>
                            <h5 className="page-status-heading">
                              No image title
                            </h5>

                            <button
                              className="icon-btn large-btn ms-auto"
                              title="export file"
                              onClick={() => handleDownload("No image title")}
                            >
                              <img src={exportFileIcon} alt="back-button" />
                            </button>

                            <FiDownload
                              className="down"
                              data-bs-toggle="modal"
                              data-bs-target="#staticBackdrop"
                              title="download 
                            long description pdf"
                            />
                            <div
                              className="modal fade"
                              id="staticBackdrop"
                              data-bs-backdrop="static"
                              data-bs-keyboard="false"
                              tabindex="-1"
                              aria-labelledby="staticBackdropLabel"
                              aria-hidden="true"
                            >
                              <div className="modal-dialog">
                                <div className="modal-content">
                                  <div
                                    className="modal-header"
                                    style={{
                                      borderBottom: "none",
                                      position: "absolute",
                                      right: 0,
                                      padding: "36px",
                                      zIndex: "100",
                                    }}
                                  >
                                    <button
                                      type="button"
                                      className="btn-close "
                                      data-bs-dismiss="modal"
                                      aria-label="Close"
                                    ></button>
                                  </div>
                                  <div className="modal-body">
                                    <PdfModal
                                      tableData={description?.no_image_title}
                                      showTitle={false}
                                      type="poorly_formatted"
                                      tableHeader={["Page Url", "HTTP Status"]}
                                      tag={"No image title"}
                                    />
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                          <AuditTable
                            tableData={description?.no_image_title}
                            showTitle={false}
                            type="poorly_formatted"
                            tableHeader={["Page Url", "HTTP Status"]}
                          />
                        </>
                      ) : crawllimit === 0 ? null : (
                        <>
                          <div className="d-flex align-items-center justify-content-between">
                            <button
                              className="icon-btn large-btn"
                              onClick={() => setSelectedTab(1)}
                            >
                              <img src={backIcon} alt="back-button" />
                            </button>

                            <div style={{ display: "flex", gap: "20px" }}>
                              <FiDownload
                                className="down"
                                data-bs-toggle="modal"
                                data-bs-target="#staticBackdrop"
                                title="download SEO issues  pdf"
                              />
                              <div
                                className="modal fade"
                                id="staticBackdrop"
                                data-bs-backdrop="static"
                                data-bs-keyboard="false"
                                tabindex="-1"
                                aria-labelledby="staticBackdropLabel"
                                aria-hidden="true"
                              >
                                <div className="modal-dialog">
                                  <div className="modal-content">
                                    <div
                                      className="modal-header"
                                      style={{
                                        borderBottom: "none",
                                        position: "absolute",
                                        right: 0,
                                        padding: "36px",
                                        zIndex: "100",
                                      }}
                                    >
                                      <button
                                        type="button"
                                        className="btn-close "
                                        data-bs-dismiss="modal"
                                        aria-label="Close"
                                      ></button>
                                    </div>
                                    <div className="modal-body">
                                      <PdfModal
                                        tableData={issueTable}
                                        showTitle={false}
                                        tableHeader={[
                                          "Top SEO Issues",
                                          "Pages",
                                          "Status",
                                        ]}
                                        tag={"seoIssuesPdf"}
                                      />
                                    </div>
                                  </div>
                                </div>
                              </div>

                              <button
                                className="icon-btn large-btn"
                                onClick={() => handleDownload()}
                                title="Download SEO Issues File"
                              >
                                <img src={exportFileIcon} alt="" />
                              </button>
                            </div>
                          </div>

                          <AuditTable
                            tableData={issueTable}
                            showTitle={false}
                            tableHeader={["Top SEO Issues", "Pages", "Status"]}
                          />
                        </>
                      )}
                    </section>
                  ) : (
                    <section className="page-status">
                      <div className="d-flex align-items-center justify-content-between">
                        <div className="d-flex align-items-center">
                          <button
                            className="icon-btn large-btn"
                            onClick={() => setSelectedTab(1)}
                          >
                            <img src={backIcon} alt="back-button" />
                          </button>
                          <h5 className="page-status-heading">All Pages</h5>
                        </div>
                        <FiDownload
                          className="down"
                          title="Download CrawledPages Pdf File"
                          data-bs-toggle="modal"
                          data-bs-target="#staticBackdrop"
                        />
                        <div
                          className="modal fade"
                          id="staticBackdrop"
                          data-bs-backdrop="static"
                          data-bs-keyboard="false"
                          tabindex="-1"
                          aria-labelledby="staticBackdropLabel"
                          aria-hidden="true"
                        >
                          <div className="modal-dialog">
                            <div className="modal-content">
                              <div
                                className="modal-header"
                                style={{
                                  borderBottom: "none",
                                  position: "absolute",
                                  right: 0,
                                  padding: "36px",
                                  zIndex: "100",
                                }}
                              >
                                <button
                                  type="button"
                                  className="btn-close "
                                  data-bs-dismiss="modal"
                                  aria-label="Close"
                                ></button>
                              </div>
                              <div className="modal-body">
                                <CrawledPagesPdf data={totalTypes} />
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <CrawledPagesTable data={totalTypes} />
                    </section>
                  )}
                </motion.div>
              </AnimatePresence>
            </section>
          ) : (
            <section
              className="keyword-research-container main-pd"
              style={{ minHeight: "100vh" }}
            >
              <div className="keyword-research-form keyword2">
                <div className="w-100">
                  <h5 className="">Site Audit</h5>
                  <p className="keyword-p">
                    You want to run site-audit for this
                  </p>
                  <div className="overview-search-contain">
                    <ProjectList
                      style={{
                        padding: "0.9em 1em",
                        width: "250px",
                        textTransform: "none",
                        border: "1px solid rgba(0, 0, 0, 0.3)",
                        borderRadius: "5px",
                        boxShadow: "none",
                        fontWeight: "500",
                        color: "rgba(0, 0, 0, 1)",
                        height: "50px",
                      }}
                    />
                    {error == "" ? (
                      <button
                        className="btn_3"
                        style={{ height: "50px" }}
                        onClick={handlePostSiteAuditClick}
                      >
                        Run Site Audit
                      </button>
                    ) : null}
                  </div>
                  {error != "" ? <p className="msg-error">{error}</p> : null}
                </div>
              </div>
            </section>
          )}
        </>
      ) : (
        <UnderConstruction />
      )}
    </main>
  );
};

export default Siteaudit;

function ProgressIndicator(props) {
  const speeddata = [
    {
      type: "Load Time",
      time: millisToSeconds(props?.connectionTime),
      timeType: "seconds",
      value: roundSpeedData(props?.connectionTime / 1000, 1),
    },
    {
      type: "Interactivity",
      time: millisToSeconds(props?.time_to_interactive),
      timeType: "seconds",
      value: roundSpeedData(props?.time_to_interactive / 1000, 1),
    },
    {
      type: "Visual Stability",
      time: millisToSeconds(props?.fatchTime),
      timeType: "seconds",
      value: roundSpeedData(props?.fatchTime / 1000, 1),
    },
  ];

  return (
    <div className="row">
      {speeddata?.map((res, index) => {
        return (
          <div className="col-lg-4" key={index}>
            <div className="gauge-meter">
              {props?.show ? (
                <Suspense fallback={<div>Loading...</div>}>
                  <SpeedMeter speed={res?.time} />
                </Suspense>
              ) : (
                <div style={{ width: "250px", height: "250px" }}></div>
              )}
            </div>
            <div className="guage-type">
              <div className="">
                <img src={desktopIcon} alt="" />
              </div>
              <div className="content ms-2">
                <span className="meter-type">{res?.type}</span>
                <span className="meter-interval">{res?.time} Sec</span>
              </div>
            </div>
          </div>
        );
      })}
    </div>
  );
}
