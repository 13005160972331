import React from "react";
import Alert from "@mui/material/Alert";
import Snackbar from "@mui/material/Snackbar";
import { Slide, Grow } from "@mui/material";

function GrowTransition(props) {
  return <Grow {...props} />;
}

function AlertPopUp({ config, handleClose }) {
  const vertical = "top";
  const horizontal = "right";

  const handleClosePopUp = () => {
    handleClose((prevState) => ({
      ...prevState,
      show: false,
    }));
  };

  return (
    <Snackbar
      anchorOrigin={{ vertical, horizontal }}
      open={config.show}
      onClose={() => handleClosePopUp()}
      autoHideDuration={5000}
      TransitionComponent={GrowTransition}
      key={vertical + horizontal}
      message={config.message}
      sx={{
        "& .MuiSnackbarContent-root": {
          backgroundColor: "#365486",
          color: "white",
          fontWeight: "700",
          minWidth: 300,
        },
      }}
    >
      {/* <Alert
        severity={config.type || "success"}
        // sx={{ backgroundColor: "#F4A720", color: "#365486" }}
      >
        {config.message}
      </Alert> */}
    </Snackbar>
  );
}

export default AlertPopUp;
