import React from "react";

export default function VerticalLinearStepper({ activeStep }) {
  return (
    <div className="stepper-cont">
      <div className="align-bar high-cont">
        <span
          className={
            activeStep === 3 ? "stepper-point active-stepper" : "stepper-point"
          }
        >
          <span className="circle"></span>
        </span>
        <span className="stepper-bar"></span>
        <span className="stepper-label">High</span>
      </div>
      <div className="align-bar medium-cont">
        <span
          className={
            activeStep === 2 ? "stepper-point active-stepper" : "stepper-point"
          }
        >
          <span className="circle"></span>
        </span>
        <span className="stepper-bar"></span>
        <span className="stepper-label">Medium</span>
      </div>
      <div className="low-cont align-bar">
        <span
          className={
            activeStep === 1
              ? "stepper-point low-point active-stepper"
              : "stepper-point low-point"
          }
        >
          <span className="circle"></span>
        </span>
        <span className="stepper-bar start-bar"></span>
        <span className="stepper-label">Low</span>
        {activeStep === 0 && (
          <span className="stepper-point start-point"></span>
        )}
      </div>
    </div>
  );
}
