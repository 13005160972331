import React, { useMemo, useCallback } from "react";
import "../../css/login.css";
import logi from "../../Assets/loginSignup.png";
import logo from "../../Assets/seoimg/logo.png";
import { Link, json, useNavigate, useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
  INVITE_AUTH,
  POST_MAILFORNEWUSER_REGISTER,
  regester_consent,
  regester_withAPi,
  regester_withOTP,
  INVITE_LIMIT_CHECK,
  POST_ADDON,
} from "../../../services/constants";
import axios from "axios";
import { useState } from "react";
import { useEffect } from "react";
import GetCustomer from "../../../confrigration/getCustomer";
import { ReCaptcha } from "../../share/components/googleRecaptacha";
import Footer from "../../share/footer";
import "./login.scss";
import closeEyeIcon from "../../Assets/common/close-eye.svg";
import openEyeIcon from "../../Assets/common/open-eye.svg";
import { Player } from "@lottiefiles/react-lottie-player";
import animationData from "../../Assets/login-animated.json";
import { Admin_Keyword_Get } from "../../../services/constants";
import Tooltip from "../../share/components/tooltip";
import keywordTag from "../../Assets/Tag.png";
import liveInd from "../../dataFiles/animation/live-indc..json";
import { addCommasToNumber } from "../../../helperJs/helper";
import { POST_OTP } from "../../../Affiliate/utils/Api";
import { validatePassword } from "../../../helperJs/helper";
import useIpAddress from "../../../helperJs/useIpAddress";
import countryList from "../../dataFiles/country";
import Dropdown from "../../share/components/dropdown";
import { submit_hubspot_form } from "../../../helperJs/hubspotFormSubmit";
import SocialJuice from "../../share/components/SocialJuice";

const Register = () => {
  // navigator
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const ipAddress = useIpAddress();
  const location = useLocation();
  const { pathname } = location;
  // useSelector variable
  const subuserinvitelength = useSelector((state) => state.subuserinvitelength);
  const subuserinvitelimit = useSelector((state) => state.subuserinvitelimit);
  // state vaiables
  const [name, setName] = useState("");
  const [lastname, setLastName] = useState("");
  const [company, setCompany] = useState("");
  const [password2, setPassword2] = useState("");
  const [email, setEmail] = useState("");
  const [SpecialCharError, setSpecialCharError] = useState(false);
  const [password, setPassword] = useState("");
  const [OTP, setOTP] = useState(false);
  const [OTPVail, setOtpVail] = useState(0);
  const [byorganization, setByOrganization] = useState(false);
  const [main_user_mail, setMain_user_mail] = useState(null);
  const [PassSecure, setPassSecure] = useState(false);
  const [PassSecure2, setPassSecure2] = useState(false);
  const [keywords, setKeywords] = useState([]);
  const [consent, setConsent] = useState(false);
  const [minLengthError, setMinLengthError] = useState(false);
  const [country, setCountry] = useState({
    name: "United States",
    value: 2840,
    flag: "https://flagsapi.com/US/flat/24.png",
  });

  const country_data = useMemo(() => {
    return countryList?.map((country) => {
      return {
        location_code: country.location_code,
        location_name: country.location_name,
        location_flag: country.location_flag,
      };
    });
  }, [countryList]);

  const [Count, setCount] = useState(0);
  const [CountValid, setCountValid] = useState(30);
  const [isDisable, setisDisable] = useState(false);

  const getKeyword = () => {
    axios.get(Admin_Keyword_Get()).then((res) => {
      const data = res.data;
      setKeywords(data?.length);
    });
  };

  useEffect(() => {
    const queryParams = new URLSearchParams(location.search);
    const invite_code = queryParams.get("l");
    if (invite_code) {
      sessionStorage.setItem("invite-code", invite_code);
      queryParams.delete("l");
      navigate(
        {
          pathname: location.pathname,
          search: queryParams.toString(),
        },
        { replace: true }
      );
    }
  }, [location, navigate]);

  useEffect(() => {
    if (keywords?.length === 0) {
      getKeyword();
    }
  }, [keywords]);

  // validation alerts
  const [mydata, setMydata] = useState(false);

  //invite by organization handler
  const urlSearchParams = new URLSearchParams(window.location.search);
  const main_userMail = urlSearchParams.get("email");
  const user_aff_id = urlSearchParams.get("aff_id");

  const fatch_InviteAuth = () => {
    // invite SUBUSERINVITELENGTH
    axios.get(INVITE_AUTH(main_userMail)).then((res) => {
      dispatch({ type: "SUBUSERINVITELENGTH", payload: res.data });
      dispatch({ type: "NOTLOADING" });
    });
  };

  const postAddonLimits = () => {
    axios
      .post(POST_ADDON(), {
        email: email,
        project: 0,
        track_key: 0,
        dom_over: 0,
        site_audit: 0,
        key_res: 0,
        backlinks: 0,
        pdf: "false",
      })
      .then(() => {})
      .catch(() => console.log("addon limit failed"));
  };

  useEffect(() => {
    if (main_userMail) {
      fatch_InviteAuth();
      setByOrganization(true);
      setMain_user_mail(main_userMail);
    }
  }, [main_userMail]);

  const hasSpecialChars = /[!@#$%^&*()_+{}\[\]:;<>,.?~\\/-]/;

  useEffect(() => {
    const MIN_LENGTH = 3;
    setSpecialCharError(hasSpecialChars.test(name));
    setMinLengthError(name.length > 0 && name.length < MIN_LENGTH);
  }, [name]);

  const user_consent = () => {
    const consent = {
      email: email,
      consent: "Yes",
    };
    axios
      .post(regester_consent(), consent)
      .then((response) => {})
      .catch((error) => {});
  };

  const hubspot_fields = [
    {
      name: "firstname",
      value: name,
    },
    {
      name: "lastname",
      value: lastname || "",
    },
    {
      name: "email",
      value: email,
    },
    {
      name: "company",
      value: company || "",
    },
    {
      name: "country",
      value: country?.name,
    },
    {
      name: "rankspro_plan",
      value: "",
    },
  ];

  const registerHandler = (e) => {
    e.preventDefault();

    if (email === "" || password === "" || password2 === "" || name === "") {
      setMydata("Please fill all details");
      return;
    }
    const passwordError = validatePassword(password);
    if (passwordError) {
      setMydata(validatePassword(password));
      return;
    }

    const items = {
      email: email,
      first_name: name,
      last_name: "",
      subscription: "",
      address: ipAddress,
    };

    const config = {
      method: "post",
      url: "https://app.rankspro.io/api/user/mailchimp/",
      maxBodyLength: Infinity,
      data: items,
    };

    if (password.length > 32) {
      setMydata("Password is too long only 32 characters allowed");
    } else if (password !== password2) {
      setMydata("Confirm Password did not match");
    } // Individual User
    else if (OTP !== false && byorganization === false) {
      if (Number(OTPVail) === OTP) {
        setMydata(false);
        let item = {
          user_type: "individual",
          main_user_mail: email,
          email: email,
          name: name,
          last_name: lastname === "" ? "0" : lastname,
          company_name: company === "" ? "0" : company,
          country: country.name,
          password: password,
          password2: password2,
          tc: "True",
          affiliate_id: user_aff_id || "0",
        };

        axios
          .post(regester_withAPi(), item)
          .then(async (res) => {
            setOTP(false);
            setMydata(false);
            navigate("/login");
            user_consent();

            const hubspot_response = await submit_hubspot_form(hubspot_fields);
            axios
              .get("https://api64.ipify.org/")
              .then((response) => {
                const newuserregister = {
                  email: email,
                  name: name,
                  Ip: response.data,
                };
                postAddonLimits();
                // for sending mail for admin
                axios.post(POST_MAILFORNEWUSER_REGISTER(), newuserregister);
              })
              .catch(() => {
                const newuserregister = {
                  email: email,
                  name: name,
                  Ip: "No Ip Found",
                };
                // for sending mail for admin
                axios.post(POST_MAILFORNEWUSER_REGISTER(), newuserregister);
              });

            axios.request(config).catch((error) => console.log(error));
          })
          .catch((res) => {
            setMydata("User with this Email already exists");
          });
      } else {
        setMydata("Otp did not match");
      }
    } // Organization User
    else if (OTP !== false && byorganization === true) {
      if (Number(OTPVail) === OTP) {
        setMydata(false);
        let item = {
          user_type: "organization",
          main_user_mail: main_user_mail,
          email: email,
          password: password,
          password2: password2,
          name: name,
          last_name: lastname === "" ? "0" : lastname,
          company_name: company === "" ? "0" : company,
          country: country.name,
          tc: "True",
          affiliate_id: user_aff_id || "0",
        };

        axios
          .post(regester_withAPi(), item)
          .then(async (res) => {
            setOTP(false);
            setMydata(false);
            navigate("/login");
            user_consent();

            const hubspot_response = await submit_hubspot_form(hubspot_fields);
            axios.request(config).catch((error) => console.log(error));

            axios
              .get("https://api64.ipify.org/")
              .then((response) => {
                const newuserregister = {
                  email: email,
                  name: name,
                  Ip: response.data,
                };
                postAddonLimits();
                // for sending mail for admin
                axios.post(POST_MAILFORNEWUSER_REGISTER(), newuserregister);
              })
              .catch(() => {
                const newuserregister = {
                  email: email,
                  name: name,
                  Ip: "No Ip Found",
                };
                // for sending mail for admin
                axios.post(POST_MAILFORNEWUSER_REGISTER(), newuserregister);
              });
          })
          .catch((error) => {
            setMydata("User with this Email already exists");
          });
      } else {
        setMydata("Otp did not match");
      }
    } else {
      const limit = sessionStorage.getItem("invite-code");
      let item = {
        email: email,
      };

      if (pathname === "/register") {
        setOTP(true);
        setisDisable(true);
        axios.post(regester_withOTP(), item).then((res) => {
          setOTP(res.data.otp);
          setCount(30);
        });
      } else if (limit != null) {
        axios
          .get(INVITE_LIMIT_CHECK(email, limit))
          .then((response) => {
            if (response.data.msg === "True") {
              sessionStorage.removeItem("invite-code");
              setOTP(true);
              setisDisable(true);
              axios.post(regester_withOTP(), item).then((res) => {
                setOTP(res.data.otp);
                setCount(30);
              });
            } else {
              setMydata("Invite limit exceeded");
            }
          })
          .catch(() => {
            setMydata("🚫 Something Went Wrong");
          });
      } else {
        setMydata("🚫 Something Went Wrong");
      }
    }
  };

  const getKeywordNumber = () => {
    const number = keywords?.length === 0 ? 0 : keywords + 100000;
    const to_number = number;
    return addCommasToNumber(to_number);
  };

  const VERIFY = () => {
    setOTP(true);
    let item = {
      email: email,
    };
    axios.post(regester_withOTP(), item).then((res) => {
      setOTP(res.data.otp);
    });
  };

  const HandleResend = () => {
    VERIFY();
    setisDisable(true);
    setCount(30);
  };

  useEffect(() => {
    let interval;
    if (Count > 0) {
      interval = setInterval(() => {
        setCount((prevTimer) => prevTimer - 1);
      }, 1000);
    } else {
      setisDisable(false);
    }

    return () => clearInterval(interval);
  }, [Count]);

  useEffect(() => {
    let interval;
    if (CountValid > 0) {
      interval = setInterval(() => {
        setCountValid((prevTimer) => prevTimer - 1);
      }, 1000);
    }
    return () => clearInterval(interval);
  }, [CountValid]);

  // dropdownhandler
  const countryDetailHandler = (res) => {
    setCountry({
      name: res.location_name,
      value: res.location_code,
      flag: res.location_flag,
    });
  };

  const handleFirstNameChange = useCallback((e) => {
    const value = e.target.value;
    if (!value.includes(" ")) {
      setName(value);
    }
  }, []);

  const handleLastNameChange = useCallback((e) => {
    const value = e.target.value;
    if (!value.includes(" ")) {
      setLastName(value);
    }
  }, []);

  return (
    <>
      {main_user_mail ? <GetCustomer email={main_user_mail} /> : false}
      <div className="loginBox">
        <div className="row">
          <div
            className="col-md-6 col-12 d-none d-md-block d-lg-block"
            style={{ background: "#365486" }}
          >
            <div className="loginLeft">
              <div
                className=""
                style={{ width: "70%", height: "70%", overflowY: "auto" }}
              >
                <SocialJuice id="de54d170-815e-461f-b737-fd181d55415c" />
              </div>
            </div>
          </div>
          <div className="col-md-6 col-12">
            <div className="loginRight">
              <div className="keyword-track-heading p-3 d-none d-lg-flex">
                <div className="w-100 h-100 position-relative">
                  <img
                    src={keywordTag}
                    alt="keyword tag"
                    className="keyword-tag"
                  />
                  <h3 className="heading-small font-medium mb-0">
                    <div style={{ width: "30px", height: "30px" }}>
                      <Player
                        src={liveInd}
                        className="player"
                        loop
                        autoplay
                        speed={1}
                      />
                    </div>
                    Keyword Tracked:
                    <span className="font-bolder ms-1 tooltip-svg">
                      {getKeywordNumber()}
                      <Tooltip title="Total number of keywords tracking live on tool." />
                    </span>
                  </h3>
                </div>
              </div>
              <div className="FormBox RegisterForm">
                <img className="Loginlogo" src={logo} alt="login img"></img>
                <div className="keyword-track-heading d-flex d-lg-none justify-content-end position-relative end-0">
                  <div className="h-100 position-relative">
                    <img
                      src={keywordTag}
                      alt="keyword tag"
                      className="keyword-tag"
                    />
                    <h3 className="heading-small font-medium mb-0">
                      <div style={{ width: "30px", height: "30px" }}>
                        <Player
                          src={liveInd}
                          className="player"
                          loop
                          autoplay
                          speed={1}
                        />
                      </div>
                      Keyword Tracked:
                      <span className="font-bolder ms-1 tooltip-svg">
                        {getKeywordNumber()}
                        <Tooltip title="Total number of keywords tracking live on tool." />
                      </span>
                    </h3>
                  </div>
                </div>
                <h1 className="text-left mb-4 ">
                  {OTP ? "Please verify..." : "Register"}
                </h1>
                <form onChange={() => setMydata(false)}>
                  <div className="formLogin">
                    {/* <div className="reg-anim pt-2"> */}
                    {OTP ? (
                      <div className="optic-scroll">
                        {/* <div className="desg"> </div> */}
                        <p className="mb-1" style={{ color: "#828282" }}>
                          Enter the verification code sent to your email.
                        </p>
                        <input
                          type="number"
                          placeholder="Enter OTP"
                          onChange={(e) => setOtpVail(e.target.value)}
                        ></input>
                        <div className="resend-otp">
                          <p className="vl-msd-line-login">{mydata} </p>
                          <div className="otp">
                            <button
                              onClick={HandleResend}
                              disabled={isDisable || CountValid > 1}
                              style={{
                                opacity: isDisable || CountValid > 1 ? 0.5 : 1,
                              }}
                            >
                              Resend Code
                            </button>
                            {isDisable && (
                              <p className="mb-0">{`(${Count} sec)`}</p>
                            )}
                          </div>
                        </div>
                      </div>
                    ) : (
                      <div className="RegisterInputBox">
                        <label id="email" htmlFor="email">
                          Email
                        </label>
                        <input
                          type="email"
                          name="email"
                          placeholder="Email"
                          onChange={(e) => setEmail(e.target.value)}
                        ></input>

                        <div className="row mt-2">
                          <div className="col-12 col-lg-6">
                            <label id="name" htmlFor="name">
                              First Name
                            </label>
                            <input
                              type="text"
                              name="name"
                              placeholder="First Name"
                              value={name}
                              onChange={handleFirstNameChange}
                            />
                          </div>
                          <div className="col-12 col-lg-6">
                            <label id="name" htmlFor="name">
                              Last Name
                            </label>
                            <input
                              type="text"
                              name="name"
                              value={lastname}
                              placeholder="Last Name (Optional)"
                              onChange={handleLastNameChange}
                            />
                          </div>
                        </div>
                        {
                          <span className="specialError">
                            {SpecialCharError &&
                              "Special Characters Not Allowed (e.g !@#$%^&*()_+{}[]:;<>,.?~\\/-)"}
                            {minLengthError &&
                              "Name must be length of 3 characters"}
                          </span>
                        }

                        <div className="row mt-2">
                          <div className="col-12 col-lg-6">
                            <label id="name" htmlFor="name">
                              Company
                            </label>
                            <input
                              type="text"
                              name="name"
                              placeholder="Company Name (Optional)"
                              onChange={(e) => {
                                setCompany(e.target.value);
                              }}
                            />
                          </div>
                          <div className="col-12 col-lg-6">
                            <label id="name" htmlFor="name">
                              Country
                            </label>

                            <Dropdown
                              img={country.flag}
                              data={country_data}
                              title={country.name}
                              style={{
                                textTransform: "none",
                                boxShadow: "none",
                                border: "1px solid rgba(0, 0, 0, 0.5019607843)",
                                padding: "0.65rem",
                                height: "43px",
                                width: "100%",
                              }}
                              callback={countryDetailHandler}
                            />
                          </div>
                        </div>

                        <div className="row mt-2">
                          <div className="col-12 col-lg-6">
                            <label id="password" htmlFor="password">
                              Password
                            </label>
                            <div className="password-type">
                              <input
                                name="password"
                                type={PassSecure ? "text" : "password"}
                                placeholder="Password"
                                onChange={(e) => setPassword(e.target.value)}
                                autoComplete="false"
                              />
                              {PassSecure ? (
                                <img
                                  src={closeEyeIcon}
                                  alt=""
                                  onClick={() => setPassSecure(false)}
                                />
                              ) : (
                                <img
                                  src={openEyeIcon}
                                  alt=""
                                  onClick={() => setPassSecure(true)}
                                />
                              )}
                            </div>
                          </div>
                          <div className="col-12 col-lg-6">
                            <label
                              id="confirm-password"
                              htmlFor="confirm-password"
                            >
                              Confirm Password
                            </label>
                            <div className="password-type">
                              <input
                                name="confirm-password"
                                type={PassSecure2 ? "text" : "password"}
                                placeholder=" Confirm Password"
                                onChange={(e) => setPassword2(e.target.value)}
                                autoComplete="false"
                              />
                              {PassSecure2 ? (
                                <img
                                  src={closeEyeIcon}
                                  alt=""
                                  onClick={() => setPassSecure2(false)}
                                />
                              ) : (
                                <img
                                  src={openEyeIcon}
                                  alt=""
                                  onClick={() => setPassSecure2(true)}
                                />
                              )}
                            </div>
                          </div>
                        </div>
                        <ul
                          className="mt-1"
                          style={{
                            paddingLeft: "5px",
                            opacity: ".5",
                            fontSize: "13px",
                          }}
                        >
                          <li>
                            *Password must contain at least one number, one
                            uppercase letter, one lowercase letter, one special
                            character, and be between 8 and 16 characters long
                          </li>
                        </ul>

                        <div className="consent-register">
                          <input
                            type="checkbox"
                            value={consent}
                            name="consent"
                            onChange={() => setConsent((pre) => !pre)}
                          />
                          <p className="mb-0 ms-2" htmlFor="consent">
                            I agree to the{" "}
                            <a
                              href="https://rankspro.io/privacy-policy"
                              target="_blank"
                            >
                              Privacy Policy
                            </a>
                            ,{" "}
                            <a
                              href="https://rankspro.io/terms-and-conditions"
                              target="_blank"
                            >
                              Terms and Conditions
                            </a>
                            , and to receive emails that will guide you on using
                            RanksPro.io and growing your traffic.
                          </p>
                        </div>
                        <p className="vl-msd-line-login mb-0">
                          {mydata ? mydata : false}{" "}
                        </p>
                      </div>
                    )}
                    {/* </div> */}

                    <div>
                      <button
                        type="submit"
                        className="login_btn  w-100 ResgisterSubmit"
                        onClick={registerHandler}
                        disabled={!OTP && !consent}
                      >
                        {OTP ? "Verify Now" : "Submit"}
                      </button>
                      <h6 className="rs-now lastLine">
                        Already have an account ?
                        <Link
                          to="/login"
                          className=""
                          style={{ color: "#E95655" }}
                        >
                          {" "}
                          Login Now{" "}
                        </Link>
                      </h6>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
        <Footer />
      </div>
      <ReCaptcha />
    </>
  );
};

export default Register;
