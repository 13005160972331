import React, { useEffect, useState } from "react";
import "../../css/login.css";
import logi from "../../Assets/loginSignup.png";
import logo from "../../Assets/seoimg/logo.png";
import { Link, useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { login_withAPi } from "../../../services/constants";
import axios from "axios";
import { ReCaptcha } from "../../share/components/googleRecaptacha";
import Footer from "../../share/footer";
import "./login.scss";
import closeEyeIcon from "../../Assets/common/close-eye.svg";
import openEyeIcon from "../../Assets/common/open-eye.svg";
import { Player } from "@lottiefiles/react-lottie-player";
import animationData from "../../Assets/login-animated.json";
import { Admin_Keyword_Get } from "../../../services/constants";
import Tooltip from "../../share/components/tooltip";
import keywordTag from "../../Assets/Tag.png";
import liveInd from "../../dataFiles/animation/live-indc..json";
import { addCommasToNumber } from "../../../helperJs/helper";
import SocialJuice from "../../share/components/SocialJuice";

const Login = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const token = localStorage.getItem("token");
  // useState variables
  const [mydata, setMydata] = useState("");
  const [Email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [ErrorMessage, setErrorMessage] = useState("");
  const [PassSecure, setPassSecure] = useState(false);
  const [keywords, setKeywords] = useState([]);

  const getKeyword = () => {
    axios.get(Admin_Keyword_Get()).then((res) => {
      const data = res.data;
      setKeywords(data?.length);
    });
  };

  useEffect(() => {
    if (token) {
      navigate("/dashboard");
      dispatch({ type: "USER", payload: true });
    }
  });

  useEffect(() => {
    if (keywords?.length === 0) {
      getKeyword();
    }
  }, [keywords]);

  const login = (e) => {
    e.preventDefault();
    if (Email === "" || password === "") {
      setMydata("Please Enter correct email or password");
    } else {
      localStorage.clear();
      let item = {
        email: Email,
        password: password,
      };
      axios
        .post(login_withAPi(), item)
        .then((res) => {
          const status = res?.data?.status;
          if (status === "200") {
            localStorage.setItem("token", res.data.token.access);
            dispatch({ type: "LOADING" });
            dispatch({ type: "USER", payload: true });
          } else {
            setMydata(res.data.msg);
          }
        })
        .catch((error) => {
          setMydata(error.response?.data?.email[0]);
        });
    }
  };

  const getKeywordNumber = () => {
    const number = keywords?.length === 0 ? 0 : keywords + 100000;
    const to_number = number;
    return addCommasToNumber(to_number);
  };

  return (
    <>
      <div className="loginBox">
        <div className="row">
          <div
            className="col-12 col-md-6 d-none d-md-block d-lg-block"
            style={{ background: "#365486" }}
          >
            <div className="loginLeft">
              <div className="imgLogin" style={{ flexDirection: "column" }}>
                <div className="mb-2">
                  <SocialJuice id="e9499166-1c27-4903-ab39-9210750013c7" />
                </div>
                <Player
                  src={animationData}
                  className="player"
                  loop
                  autoplay
                  speed={1}
                />
              </div>
            </div>
          </div>
          <div className="col-12 col-md-6">
            <div className="loginRight">
              <div className="keyword-track-heading p-3">
                <div className="w-100 h-100 position-relative">
                  <img
                    src={keywordTag}
                    alt="keyword tag"
                    className="keyword-tag"
                  />
                  <h3 className="heading-small font-medium mb-0">
                    <div style={{ width: "30px", height: "30px" }}>
                      <Player
                        src={liveInd}
                        className="player"
                        loop
                        autoplay
                        speed={1}
                      />
                    </div>
                    Keyword Tracked:
                    <span className="font-bolder ms-1 tooltip-svg">
                      {getKeywordNumber()}
                      <Tooltip title="Total number of keywords tracking live on tool." />
                    </span>
                  </h3>
                </div>
              </div>
              {/* <div className="keyword-track-heading shadow-lg p-3 bg-white">
                <span
                  className="heading-large font-bold
                "
                >
                  {keywords}
                </span>
                <div className="ms-1">
                  <span
                    className="heading-small font-bold
                "
                  >
                    Keyword Tracking
                  </span>
                  <span
                    className="heading-small font-bold ms-1
                "
                  >
                    Live
                  </span>
                </div>
              </div> */}
              <div className="FormBox ">
                <img className="Loginlogo" src={logo} alt="login img"></img>
                <h1 className="text-left mb-4 ">Login to your Account</h1>
                <form onChange={() => setMydata("")}>
                  <div className="formLogin">
                    <label id="lb">Email</label>
                    <input
                      type="text"
                      placeholder="Email"
                      onChange={(e) => setEmail(e.target.value)}
                      autoComplete="true"
                    ></input>
                    <label id="lb">Password</label>
                    <div className="password-type">
                      <input
                        type={PassSecure ? "text" : "password"}
                        placeholder="Password"
                        onChange={(e) => setPassword(e.target.value)}
                        autoComplete="true"
                      ></input>
                      {PassSecure ? (
                        <img
                          src={closeEyeIcon}
                          alt=""
                          onClick={() => setPassSecure(false)}
                        />
                      ) : (
                        <img
                          src={openEyeIcon}
                          alt=""
                          onClick={() => setPassSecure(true)}
                        />
                      )}
                    </div>
                    <p className="forgot">
                      {" "}
                      <span className="vl-msd-line-login">{mydata}</span>
                      <span className="">
                        <Link to="/forgot" style={{ color: "#E95655" }}>
                          Forgot Password ?
                        </Link>
                      </span>{" "}
                    </p>

                    <div>
                      <button
                        type="submit"
                        className="login_btn w-100"
                        onClick={login}
                      >
                        LOGIN
                      </button>
                      <h6 className="rs-now">
                        Don't have an account ?{" "}
                        <Link to="/register" style={{ color: "#E95655" }}>
                          {" "}
                          Register Now
                        </Link>
                      </h6>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
        <Footer />
      </div>
      <ReCaptcha />
    </>
  );
};

export default Login;
