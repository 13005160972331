import { useRef } from "react";
import { AnimatePresence, motion } from "framer-motion";
import { useClickAway } from "react-use";
import { BiLogOutCircle } from "react-icons/bi";
import { Link, NavLink } from "react-router-dom";
import { MdOutlineAccountCircle } from "react-icons/md";

export const SidebarMob = ({ open, setOpen, menu, CLOSE, HandleLogOut }) => {
  const ref = useRef(null);
  useClickAway(ref, () => {
    setOpen(!open);
  });

  return (
    <>
      <AnimatePresence mode="wait" initial={false}>
        {open && (
          <>
            <motion.div
              {...framerSidebarBackground}
              aria-hidden="true"
              className="setBackRopAf  "
            ></motion.div>
            <motion.div
              {...framerSidebarPanel}
              className=" childBackAf  "
              ref={ref}
              aria-label="Sidebar"
            >
              <ul>
                {menu.map((item, idx) => {
                  const { title, href, Icon } = item;
                  return (
                    <>
                      {item.name === "Free Tools" || item.name === "Feature" ? (
                        <div
                          // onClick={() => addUniqueStringToArray(item.name)}
                          style={{
                            borderBottom:
                              "1px solid rgba(128, 128, 128, 0.239)",
                          }}
                          className={
                            item.name === "Free Tools" ||
                            item.name === "Feature"
                              ? "w-100 d-flex align-items-center active-menu"
                              : "w-100 d-flex align-items-center dropOption"
                          }
                          key={item.name}
                        >
                          <>
                            <NavLink
                              key={item?.name}
                              to={item?.link}
                              className="menuLinks "
                            >
                              <motion.div {...framerIcon(idx)}>
                                {Icon}
                              </motion.div>
                              <motion.span {...framerIcon(idx)}>
                                {item.name}
                              </motion.span>
                            </NavLink>

                            {/* <div className="dropdown-btn-sm">
                              <AiOutlineDown />
                            </div> */}
                          </>
                        </div>
                      ) : (
                        <div className="menuLinks" key={item.name}>
                          <NavLink
                            key={item?.name}
                            to={item?.link}
                            className="sidebarmenu "
                            onClick={CLOSE}
                          >
                            <motion.div {...framerIcon(idx)}>{Icon}</motion.div>
                            <motion.span {...framerIcon(idx)}>
                              {item.name}
                            </motion.span>
                          </NavLink>
                        </div>
                      )}
                    </>
                  );
                })}
              </ul>
              <div className="SideBarLog">
                <div className="sideBottom">
                  <motion.button {...framerIcon(3.5)} onClick={HandleLogOut}>
                    <BiLogOutCircle /> <span>Logout</span>
                  </motion.button>

                  <motion.button {...framerIcon(5.5)}>
                    <MdOutlineAccountCircle />
                    <Link to={"../affiliate/profile"} onClick={CLOSE}>
                      <span>Account</span>
                    </Link>
                  </motion.button>
                </div>
              </div>
            </motion.div>
          </>
        )}
      </AnimatePresence>
    </>
  );
};

const framerSidebarBackground = {
  initial: { transform: "scale(0) ", transformOrigin: "top" },
  animate: { transform: "scale(1) ", transformOrigin: "top" },
  exit: {
    transform: "scale(0) ",
    transition: { delay: 0.2 },
    transformOrigin: "top",
  },
  transition: { duration: 0.3 },
};

const framerSidebarPanel = {
  initial: { transform: "scale(0) ", transformOrigin: "top" },
  animate: { transform: "scale(1) ", transformOrigin: "top" },
  exit: { transform: "scale(0) ", transformOrigin: "top" },
  transition: { duration: 0.3 },
};

const framerText = (delay) => {
  return {
    initial: { opacity: 0, x: -100 },
    animate: { opacity: 1, x: 0 },
    transition: {
      //   delay: 0.5 + .5 / 10,
    },
  };
};

const framerIcon = (delay) => {
  return {
    initial: { scale: 0 },
    animate: { scale: 1 },
    transition: {
      type: "spring",
      stiffness: 260,
      damping: 20,
      delay: 0.1 + delay / 10,
    },
  };
};
