import axios from "axios";
import React, { useEffect, useState } from "react";
import { GET_ALL_WITHDRA } from "../../services/constants";
import "../css/Affiliate.scss";
import PopOver from "../../Mycomponent/share/components/popover.component";
import CloseIcon from "@mui/icons-material/Close";
import { IconButton } from "@mui/material";
import APPROVE from "../assets/approve.png";
import PdfViewer from "../share/pdf-viewer";
import { MdCloudUpload } from "react-icons/md";
import { Box, Button } from "@mui/material";
import { CSVLink } from "react-csv";
import CustomTable from "../../Mycomponent/share/customTable";

function AffiliateWithdrawal() {
  const [LISTS, setLISTS] = useState([]);
  const [Refetch, setRefetch] = useState(0);
  const [Show, setShow] = useState(false);
  const [SendData, setSendData] = useState({});
  const [page, setPage] = React.useState(1);
  const [affLoader, setAffLoader] = React.useState(true);

  const HandleFetchList = () => {
    axios.get(GET_ALL_WITHDRA()).then(({ data }) => {
      setLISTS(data);
      setAffLoader(false);
    });
  };

  const HandleFetchAllList = () => {
    // axios.get(ALL_AFFILIATE_USER()).then(({ data }) => {
    //   setAllList(data);
    // });
  };

  const handleOpen = (Elem) => {
    setSendData(Elem);
    setShow(true);
  };

  const HandleApprove = ({ aff_id, aff_link, email }) => {
    // axios.post(POST_AFFILIATE_APPROVE(email, aff_link, aff_id)).then((res) => {
    //   axios.put(PUT_AFFILIATE_APPROVE(email, aff_link, aff_id)).then((res) => {
    //     setRefetch(Refetch + 1);
    //     axios
    //       .post(POST_AFFILIATE_APPROVE_CLICK_COUNT(aff_id))
    //       .then((res) => {});
    //   });
    //   setShow(false);
    // });
  };

  useEffect(() => {
    HandleFetchList();
    HandleFetchAllList();
  }, [Refetch]);

  const [selectedFile, setSelectedFile] = useState(null);
  const [preview, setPreview] = useState(null);
  const [previewPdf, setPreviewPdf] = useState(false);
  const fileInputRef = React.createRef();

  const processFile = (file) => {
    if (file) {
      if (file.type.startsWith("image/")) {
        const reader = new FileReader();
        reader.onloadend = () => {
          setSelectedFile(file);
          setPreview(reader.result);
        };
        reader.readAsDataURL(file);
      } else if (file.type === "application/pdf") {
        setSelectedFile(file);
        setPreview(null);
      } else {
        alert("Unsupported file type. Please upload an image or a PDF.");
        setSelectedFile(null);
        setPreview(null);
      }
    }
  };

  const handleFileChange = (event) => {
    const file = event.target.files[0];
    processFile(file);
  };

  const handleUpload = () => {
    handleClosePopup();
  };

  const handleClosePopup = () => {
    setShow(false);
    setSelectedFile(null);
    setPreview(null);
  };

  const handleDrop = (event) => {
    event.preventDefault();

    const file = event.dataTransfer.files[0];
    processFile(file);
  };

  const handleDragOver = (event) => {
    event.preventDefault();
  };

  const loadCSVData = () => {
    if (LISTS?.length === 0) return [];
    return LISTS?.map((item) => {
      const obj = {
        name: item?.name,
        email: item?.email,
        affiliate_id: item?.affiliate_id,
        earning: item?.earning,
        paypal_id: item?.paypal_id,
      };
      return obj;
    });
  };

  const tableHeader = [
    {
      label: "NAME",
      key: "name",
      minWidth: 100,
      width: "25%",
      align: "left",
    },
    {
      label: "EMAIL",
      key: "email",
      minWidth: 100,
      align: "left",
      width: "25%",
    },
    {
      label: "AFFILIATE ID",
      key: "affiliate_id",
      minWidth: 100,
      align: "center",
      width: "25%",
    },
    {
      label: "EARNING",
      key: "earning",
      minWidth: 100,
      align: "center",
      width: "25%",
    },
    {
      label: "PAYPAL ID",
      key: "paypal_id",
      minWidth: 100,
      align: "center",
      width: "25%",
    },
    {
      label: "ACTIONS",
      key: "action",
      minWidth: 250,
      align: "center",
      width: "25%",
    },
  ];

  function createData(name, email, affiliate_id, earning, paypal_id, action) {
    return {
      name,
      email,
      affiliate_id,
      earning,
      paypal_id,
      action,
    };
  }

  const rows = LISTS?.map((item) => {
    return createData(
      item?.name,
      item?.email,
      item?.affiliate_id,
      item?.earning,
      item?.paypal_id,
      <div className="BUTTONS">
        <button className="me-2">Deny</button>
        <button onClick={() => handleOpen(item)} className="green">
          Approve
        </button>
      </div>
    );
  });

  return (
    <>
      <div className="">
        {previewPdf ? (
          <div>
            <h1 className="table_title heading-large font-bold mb-2">
              {" "}
              Withdrawal Request
            </h1>
            <button className="btn_1 mb-3" onClick={() => setPreviewPdf(false)}>
              close
            </button>
            <PdfViewer file={selectedFile} />
          </div>
        ) : (
          <>
            {Show && (
              <PopOver anchorEl={true}>
                <div className="ApproveModal" style={{ minHeight: "290px" }}>
                  <CloseIcon onClick={() => setShow(false)} />
                  <h1 style={{ fontSize: "1.7em" }}>
                    Upload Withdrawal Transaction Receipt
                  </h1>
                  <div className="imgBoxApp">
                    {preview && selectedFile.type.startsWith("image/") && (
                      <img
                        src={preview}
                        alt="Uploaded Withdrawal Transaction Receipt"
                      />
                    )}
                  </div>
                  <div
                    onDrop={handleDrop}
                    onDragOver={handleDragOver}
                    style={{
                      border: "2px dashed #ccc",
                      borderRadius: "4px",
                      padding: "20px",
                      textAlign: "center",
                      cursor: "pointer",
                      position: "relative",
                      height: "100px",
                    }}
                    className="mt-3"
                  >
                    <label
                      htmlFor="file-input"
                      style={{
                        position: "absolute",
                        top: 0,
                        left: 0,
                        width: "100%",
                        height: "100%",
                        flexDirection: "column",
                      }}
                      className="d-flex align-items-center justify-content-center"
                    >
                      {/* <MdCloudUpload /> */}
                      <p className="mb-0">
                        Drag and drop a file here or click to select a file
                      </p>
                    </label>
                    <input
                      id="file-input"
                      name="file-input"
                      type="file"
                      accept="image/*, application/pdf"
                      onChange={handleFileChange}
                      style={{ display: "none" }}
                    />
                  </div>
                  <div className="m-auto my-4" style={{ width: "90%" }}>
                    {selectedFile && <p>Selected File: {selectedFile.name}</p>}
                    {selectedFile &&
                      selectedFile.type === "application/pdf" && (
                        <button
                          onClick={() => setPreviewPdf(true)}
                          className="btn_1"
                        >
                          Preview PDF
                        </button>
                      )}
                  </div>
                  <div className="buttons w-100">
                    <button onClick={() => handleClosePopup()}>Cancel</button>
                    <button
                      onClick={() => handleUpload()}
                      disabled={!selectedFile}
                    >
                      Submit
                    </button>
                  </div>
                </div>
              </PopOver>
            )}

            <CustomTable
              tableTitle="Withdrawal Request"
              showSearchBar={true}
              downloadCSVFile={
                <CSVLink
                  data={loadCSVData()}
                  filename={"affiliate-withdrawal.csv"}
                >
                  <Button className="btn_1 ms-2">Download CSV</Button>
                </CSVLink>
              }
              rows={rows}
              dataLoader={affLoader}
              tableHeader={tableHeader}
              page={page}
              setPage={setPage}
            />
          </>
        )}
      </div>
    </>
  );
}

export default AffiliateWithdrawal;
