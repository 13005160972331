import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { ZustandStore } from "../store/zustand-store";
import { HiArrowDown, HiArrowUp } from "react-icons/hi";
import upArrowIcon from "../Mycomponent/Assets/pdf/green.png";
import downArrowIcon from "../Mycomponent/Assets/pdf/red.png";
import noArrowIcon from "../Mycomponent/Assets/pdf/gray.png";
import { Store } from "../Affiliate/utils/STORE";
import { getCurrentCountryFlag } from "./getCountryFlag";

const RankTable = () => {
  const dispatch = useDispatch();
  const {
    keyword_latest_data,
    keyword_old_data,
    keyword_loc_data,
    all_loc_data,
  } = ZustandStore((state) => state);

  const showLoader = useSelector((state) => state.showloader);
  const grayGrowth = <HiArrowUp size={14} style={{ color: "#000000CC" }} />;
  const greenGrowth = <HiArrowUp size={14} style={{ color: "#238654" }} />;
  const redGrowth = <HiArrowDown size={14} style={{ color: "#F42C2C" }} />;

  function convertArrayToObject(arr, location) {
    const array = arr?.map((item) => {
      return {
        keyword: item,
        location,
        flag: getCurrentCountryFlag(location),
      };
    });
    return array;
  }

  function mergeCompetitorData(data) {
    const mergedData = {};

    data?.forEach((competitorArray) => {
      competitorArray?.forEach((competitor) => {
        const competitorName = competitor.competitor;
        if (!mergedData[competitorName]) {
          mergedData[competitorName] = [];
        }
        competitor?.comp_data?.forEach((keywordData) => {
          mergedData[competitorName].push(keywordData);
        });
      });
    });
    dispatch({ type: "USERCOMPATITORS", payload: [mergedData] });
  }

  const updateKeywordCompData = (data) => {
    if (data?.USERCOMPATITORS?.length === 0) return;
    const curr_comp = data?.USERCOMPATITORS;
    return curr_comp;
  };

  function mergeArraysByKey(arr, key) {
    const merged = {};
    arr?.forEach((obj) => {
      const arrForKey = obj[key];
      if (!merged[key]) {
        merged[key] = [...arrForKey];
      } else {
        merged[key] = merged[key].concat(arrForKey);
      }
    });

    return merged[key];
  }

  const previous_all_old_data = (item, index) => {
    const data = keyword_old_data?.[index]?.PREVIOUSALLOLDDATA;
    const location = keyword_old_data?.[index]?.LOCATION;
    return {
      id: index,
      data,
      location,
      flag: getCurrentCountryFlag(location),
    };
  };

  const calculate_keyword = (data, index) => {
    const keywordData = keyword_latest_data?.[index]?.KEYWORDDATA;
    const UserAllPendingResult =
      keyword_latest_data?.[index]?.USERALLPENDINGRESULT;
    const UserAllKeywordResult =
      keyword_latest_data?.[index]?.USERALLKEYWORDRESULT;
    const oldKeywordData = keyword_old_data?.[index]?.OLDKEYWORDDATA;
    const location = keyword_latest_data?.[index]?.LOCATION;

    const cal_keyword = {
      KEYWORDDATADETAILS: [],
      KEYWORDMOVEUP: [],
      KEYWORDMOVEDOWN: [],
      USERALLPENDINGRESULTWITHLOCATION: convertArrayToObject(
        UserAllPendingResult,
        location
      ),
    };

    keywordData?.filter((res, key) => {
      const resRankgroup = res.rank_group && res.rank_group;
      const oldataRankgroup =
        oldKeywordData?.length > key
          ? oldKeywordData[key].rank_group
          : "No Rank";
      if (resRankgroup === "No Rank" || resRankgroup === undefined) {
        cal_keyword?.KEYWORDDATADETAILS.push({
          keyword: UserAllKeywordResult[key]?.keyword,
          rank: res.rank_group,
          oldrank: oldataRankgroup,
          url: res.url,
          result: 0,
          growth: grayGrowth,
          img: noArrowIcon,
          backgroundColor: "#EBEBEB",
          color: "#000000CC",
          serachvolume: Number(UserAllKeywordResult[key]?.search_volume),
          location: location,
          flag: getCurrentCountryFlag(location),
        });
      } else if (
        oldataRankgroup === "No Rank" ||
        oldataRankgroup === undefined
      ) {
        cal_keyword?.KEYWORDDATADETAILS.push({
          keyword: UserAllKeywordResult[key].keyword,
          rank: res.rank_group,
          oldrank: oldataRankgroup,
          url: res.url,
          result: 0,
          growth: grayGrowth,
          img: noArrowIcon,
          backgroundColor: "#EBEBEB",
          color: "#000000CC",
          serachvolume: Number(UserAllKeywordResult[key].search_volume),
          location: location,
          flag: getCurrentCountryFlag(location),
        });
      } else if (resRankgroup - oldataRankgroup === 0) {
        cal_keyword?.KEYWORDDATADETAILS.push({
          keyword: UserAllKeywordResult[key].keyword,
          rank: res.rank_group,
          oldrank: oldataRankgroup,
          url: res.url,
          result: oldataRankgroup - resRankgroup,
          growth: grayGrowth,
          img: noArrowIcon,
          backgroundColor: "#EBEBEB",
          color: "#000000CC",
          serachvolume: Number(UserAllKeywordResult[key].search_volume),
          location: location,
          flag: getCurrentCountryFlag(location),
        });
      } else if (resRankgroup - oldataRankgroup <= 0) {
        cal_keyword?.KEYWORDDATADETAILS.push({
          keyword: UserAllKeywordResult[key].keyword,
          rank: res.rank_group,
          oldrank: oldataRankgroup,
          url: res.url,
          result: oldataRankgroup - resRankgroup,
          growth: greenGrowth,
          img: upArrowIcon,
          backgroundColor: "#B3FECC",
          color: "#238654",
          serachvolume: Number(UserAllKeywordResult[key].search_volume),
          location: location,
          flag: getCurrentCountryFlag(location),
        });
        cal_keyword.KEYWORDMOVEUP.push(1);
      } else {
        cal_keyword?.KEYWORDDATADETAILS.push({
          keyword: UserAllKeywordResult[key].keyword,
          rank: res.rank_group,
          oldrank: oldataRankgroup,
          url: res.url,
          result: resRankgroup - oldataRankgroup,
          growth: redGrowth,
          img: downArrowIcon,
          backgroundColor: "#FCCACA",
          color: "#F42C2C",
          serachvolume: Number(UserAllKeywordResult[key].search_volume),
          location: location,
          flag: getCurrentCountryFlag(location),
        });
        cal_keyword.KEYWORDMOVEDOWN.push(1);
      }
    });
    return cal_keyword;
  };

  function keywordRankData(keywordData) {
    if (keywordData?.length === 0) return;
    const result = {
      top3: [],
      top10: [],
      top100: [],
      noRank: [],
    };

    keywordData?.forEach((res) => {
      if (res.rank === "No Rank") {
        result.noRank.push(res.rank);
      }
      if (res.rank <= 3) {
        result.top3.push(res.rank);
      }
      if (res.rank <= 10) {
        result.top10.push(res.rank);
      }
      if (res.rank <= 100) {
        result.top100.push(res.rank);
      }
    });
    const perc = {
      top3: (result?.top3?.length / keywordData?.length) * 100,
      top10: (result?.top10?.length / keywordData?.length) * 100,
      top100: (result?.top100?.length / keywordData?.length) * 100,
      noRank: (result?.noRank?.length / keywordData?.length) * 100,
      total_keyword: keywordData?.length,
    };
    Store.setState({
      RankDownPercent: perc,
      RankUpPercent: result,
    });
  }

  useEffect(() => {
    if (
      keyword_loc_data === "All Locations" &&
      all_loc_data?.length === 0 &&
      keyword_old_data?.length > 0 &&
      keyword_latest_data?.length > 0
    ) {
      ZustandStore.setState({
        all_loc_data: [
          {
            keyword_old_data: keyword_old_data,
            keyword_latest_data: keyword_latest_data,
          },
        ],
      });
    }
  }, [keyword_loc_data, keyword_old_data, keyword_latest_data, all_loc_data]);

  useEffect(() => {
    if (keyword_old_data?.length > 0) {
      const mergedOldKeywordData = mergeArraysByKey(
        keyword_old_data,
        "OLDKEYWORDDATA"
      );

      const previous_old_data = keyword_old_data?.map((item, index) => {
        return previous_all_old_data(item, index);
      });

      previous_old_data &&
        dispatch({
          type: "PREVIOUSALLOLDDATA",
          payload: previous_old_data,
        });

      mergedOldKeywordData &&
        dispatch({
          type: "OLDKEYWORDDATA",
          payload: mergedOldKeywordData,
        });
    } else {
      dispatch({
        type: "PREVIOUSALLOLDDATA",
        payload: [],
      });
      dispatch({
        type: "OLDKEYWORDDATA",
        payload: [],
      });
    }
    if (!showLoader && keyword_latest_data?.length > 0) {
      const data = keyword_latest_data?.map((item, index) => {
        return calculate_keyword(item, index);
      });
      const competitors = keyword_latest_data?.map((item, index) => {
        return updateKeywordCompData(item);
      });
      competitors && mergeCompetitorData(competitors);
      dispatch({
        type: "SELECTEDPROJECTNEWDATA",
        payload: keyword_latest_data?.[0]?.SELECTEDPROJECTNEWDATA,
      });

      if (data) {
        const mergedUserAllPendingResult = mergeArraysByKey(
          data,
          "USERALLPENDINGRESULTWITHLOCATION"
        );
        mergedUserAllPendingResult &&
          dispatch({
            type: "USERALLPENDINGRESULT",
            payload: mergedUserAllPendingResult,
          });

        const mergedUserAllKeywordResult = mergeArraysByKey(
          keyword_latest_data,
          "USERALLKEYWORDRESULT"
        );
        mergedUserAllKeywordResult &&
          dispatch({
            type: "USERALLKEYWORDRESULT",
            payload: mergedUserAllKeywordResult,
          });

        const mergedKeywordsDataDetails = mergeArraysByKey(
          data,
          "KEYWORDDATADETAILS"
        );
        const mergedKeywordsMoveUp = mergeArraysByKey(data, "KEYWORDMOVEUP");
        const mergedKeywordsMoveDown = mergeArraysByKey(
          data,
          "KEYWORDMOVEDOWN"
        );

        mergedKeywordsDataDetails && keywordRankData(mergedKeywordsDataDetails);

        ZustandStore.setState({
          rank_keyword: mergedKeywordsDataDetails || [],
        });

        mergedKeywordsMoveUp &&
          dispatch({
            type: "RANKMOVEDUP",
            payload: mergedKeywordsMoveUp?.length || 0,
          });

        mergedKeywordsMoveDown &&
          dispatch({
            type: "RANKMOVEDDOWN",
            payload: mergedKeywordsMoveDown?.length || 0,
          });
      }
    } else {
      ZustandStore.setState({
        rank_keyword: [],
      });
      const result = {
        top3: [],
        top10: [],
        top100: [],
        noRank: [],
      };
      const perc = {
        top3: 0,
        top10: 0,
        top100: 0,
        noRank: 0,
        total_keyword: 0,
      };
      Store.setState({
        RankDownPercent: perc,
        RankUpPercent: result,
      });
      dispatch({ type: "RANKMOVEDUP", payload: 0 });
      dispatch({ type: "RANKMOVEDDOWN", payload: 0 });
      dispatch({
        type: "USERALLPENDINGRESULT",
        payload: [],
      });
      dispatch({
        type: "USERALLKEYWORDRESULT",
        payload: [],
      });
      ZustandStore.setState({
        rank_keyword: [],
      });
      dispatch({
        type: "SELECTEDPROJECTNEWDATA",
        payload: [],
      });
      dispatch({ type: "USERCOMPATITORS", payload: [] });
    }
  }, [keyword_latest_data, keyword_old_data, showLoader]);

  return null;
};

export default RankTable;
