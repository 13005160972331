import React, { useState, useMemo, useRef, memo } from "react";
import Spinner from "../../share/components/spinner";
import RanksProLogo from "../../../Mycomponent/Assets/site-audit/RanksProLogo.png";
import { useSelector } from "react-redux";
import "jspdf-autotable";
import {
  Page,
  Text,
  View,
  Document,
  StyleSheet,
  Image,
  Link,
  PDFDownloadLink,
} from "@react-pdf/renderer";
import { FiDownload } from "react-icons/fi";
import { useNavigate } from "react-router-dom";
import { getCurrentYear } from "../../share/helper-js/current-year";


const styles = StyleSheet.create({
  page: {
    paddingHorizontal: 20,
    flexDirection: "column",
    paddingTop: 30,
    paddingBottom: 80, // Set vertical padding to 30 pixels
    borderStyle: "solid",
  },
  header: {
    // position: "absolute",
    // top: 20,
    // left: 15,
    // right: 0,
    paddingBottom:20
  },
  footer: {
    fontSize: 12,
    textAlign: "center",
    color: "grey",
    position: "absolute",
    bottom: 20,
    left: 0,
    right: 0,
  },
  logo: {
    width: 250,
  },
  title: {
    fontSize: 24,
    textAlign: "center",
    marginBottom: 20,
  },

  tableContainer: {
    marginBottom: 20,
  },
  table: {
    width: "100%",
    borderStyle: "solid",
    borderWidth: 1,
    display: "flex", // Use flex to create a flexible row layout
    flexDirection: "column", // Each table row is a flex column
  },
  tableRow: {
    display: "flex",
    flexDirection: "row", // Each cell is a flex item in a row
    borderBottomWidth: 1,
    borderColor: "#000",
    alignItems: "center",
  },
  keywordLoc: {
    flex: 1, // Each cell should take equal space within the row
    padding: 5,
    borderRightWidth: 1,
    borderColor: "#000",
    justifyContent: "start",
    minHeight: 40,
    height: "100%",
    alignItems: "start",
    flexDirection: "column",
  },
  tableCell: {
    flex: 1, // Each cell should take equal space within the row
    padding: 5,
    borderRightWidth: 1,
    borderColor: "rgb(221, 221, 221)",
    justifyContent: "center",
    alignItems: "center",
    flexDirection: "row",
  },
  fixedWidthCell: {
    maxWidth: 80, // Disable flex for fixed width cells
  },
  cardContainer: {
    flexDirection: "row",
    flexWrap: "wrap", // Enable flex wrapping
    justifyContent: "space-between", // Arrange cards with equal spacing
    marginTop: 10,
  },
  card: {
    flexDirection: "row",
    justifyContent: "space-between",
    width: 180,
    backgroundColor: "#FFFFFF",
    padding: 10,
    borderRadius: 5,
    marginBottom: 20,
  },
  image: {
    width: 30,
    height: 40,
  },
  title: {
    fontSize: 11,
    fontWeight: "bold",
    marginBottom: 5,
    color: "#6c757d",
  },
  description: {
    fontSize: 16,
  },
  imageContainer: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  arrowIcon: {
    width: 20,
    height: 20,
    objectFit: "contain",
  },
  view_button: {
    borderWidth: 1,
    borderColor: "#365486",
    padding: 5,
    borderRadius: 7,
    color: "#365486",
    fontSize: 11,
  },
  keyword_bold: {
    fontSize: 13,
    fontWeight: "bold",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  keyword: {
    fontSize: 11,
    color: "#6c757d",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  rank: {
    fontSize: 11,
    color: "#6c757d",
    textAlign: "center",
    marginRight: 10,
  },
  table_cell_url: {
    fontSize: 7,
    textAlign: "center",
    // marginVertical:5,
    overflow: "hidden",
    color: "blue",
    textOverflow: "ellipsis",
  },
  table_cell: {
    fontSize: 7,
    color: "#6c757d",
    textAlign: "center",
    marginVertical: 5,
  },
  table_header: {
    fontSize: 9,
    textAlign: "center",
  },
  pageNumber: {
    position: "absolute",
    fontSize: 11,
    bottom: 45,
    left: 0,
    right: 0,
    textAlign: "center",
    color: "grey",
  },
  link_text: {
    fontSize: 7,
    textDecoration: "none",
    textOverflow: "hidden",
    textAlign: "start",
  },
  logoHead: {
    width: 125,
  },
  fixedWidthCell: {
    width: 100,
    padding: 5,
    borderRightWidth: 1,
    borderColor: "rgb(221, 221, 221)",
    justifyContent: "center",
    alignItems: "center",
    flexDirection: "row",
  }
});






export const PdfFile = ({ sortedTableData, websiteurl, pdfNewData }) => {

  const Data = JSON.parse(sessionStorage.getItem("pdfSessionData"));


  const truncateText = (text, maxLength) => {
    return text.length > maxLength.length ? `...${text.substring(maxLength.length, 60)}` : text;
  };


  const Web_url = Data ? Data?.websiteurl : websiteurl;
  const sorted_Table_Data = Data ? Data?.sortedTableData : sortedTableData;
  const image_logo = pdfNewData
    ? pdfNewData?.reset
      ? RanksProLogo
      : pdfNewData?.logo?.src
    : RanksProLogo;


  return (
    <Document>
      {/* First Page */}
      <Page size={"A4"} style={styles.page}>
        <View
          style={{
            alignItems: "center",
            display: "flex",
            height: "100%",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
            <Image style={styles.logo} src={image_logo} />
         
          <Text style={{ marginBottom: 20 }}>Crawled pages</Text>
          <Text style={styles.title}>Domain: {Web_url}</Text>
        </View>
      </Page>

      {/* Subsequent Pages */}
      <Page size={"A4"} style={styles.page}>
        <View
          style={{
            ...styles.header,
            alignItems: pdfNewData?.reset
              ? "flex-start"
              : pdfNewData?.logo.position,
          }}
          fixed
        >
          <Image style={styles.logoHead} src={image_logo} />
        </View>

        <View style={{ ...styles.tableContainer }}>
          <View style={styles.table}>
            <View style={{ ...styles.tableRow, backgroundColor: "#f4f4f4" }}>
              <View
                style={{ ...styles.fixedWidthCell, backgroundColor: "#f4f4f4" }}
              >
                <Text style={styles.table_header}> Pages</Text>
              </View>
              <View style={{ ...styles.tableCell, backgroundColor: "#f4f4f4" }}>
                <Text style={styles.table_header}>Inbound Links Count</Text>
              </View>
              <View style={{ ...styles.tableCell, backgroundColor: "#f4f4f4" }}>
                <Text style={styles.table_header}>Internal Links Count</Text>
              </View>
              <View style={{ ...styles.tableCell, backgroundColor: "#f4f4f4" }}>
                <Text style={styles.table_header}>Onpage Score</Text>
              </View>
              <View style={{ ...styles.tableCell, backgroundColor: "#f4f4f4" }}>
                <Text style={styles.table_header}>External Links Count</Text>
              </View>
              <View style={{ ...styles.tableCell, backgroundColor: "#f4f4f4" }}>
                <Text style={styles.table_header}>Status</Text>
              </View>
            </View>
            <View>
              {sorted_Table_Data?.map((res, index) => (
                <View style={styles.tableRow} key={`table-row-${index}`}>
                  <View style={styles.fixedWidthCell}>
                    <View
                      style={{
                        ...styles.tableCell,
                        border: "none",
                        display: "flex",
                        flexFlow: "column",
                        flexDirection: "column",
                      }}
                    >
                      <Text style={styles.table_cell}>{res?.title}</Text>
                      <Link
                        src={res?.url}
                        style={{
                          ...styles.table_cell_url,
                          color: "blue",
                          textOverflow: "ellipsis",
                          overflow: "hidden",
                          textDecoration: "none",
                        }}
                      >
                        {truncateText(res?.url,`https://www.${Web_url}` )}
                      </Link>
                    </View>
                  </View>
                  <View style={styles.tableCell}>
                    <Text style={styles.table_cell}>
                      {res?.inbound_links_count || "-"}
                    </Text>
                  </View>
                  <View style={styles.tableCell}>
                    <Text style={styles.table_cell}>
                      {res?.internal_links_count || "-"}
                    </Text>
                  </View>
                  <View style={styles.tableCell}>
                    <Text style={styles.table_cell}>
                      {res?.onpage_score || "-"}
                    </Text>
                  </View>
                  <View style={styles.tableCell}>
                    <Text style={styles.table_cell}>
                      {res?.external_links_count || "-"}
                    </Text>
                  </View>
                  <View style={styles.tableCell}>
                    <Text style={styles.table_cell}>
                      {res.resource_type === "html"
                        ? "Successful"
                        : res.resource_type}
                    </Text>
                  </View>
                </View>
              ))}
            </View>
          </View>
        </View>

        <Text style={styles.footer} fixed>
          {pdfNewData?.reset
            ? `Copyright ${getCurrentYear} eSearch Logix | Prepared using RanksPro.io`
            : pdfNewData?.footer}
        </Text>
      </Page>
    </Document>
  );
};















const CrawledPagesPdf = ({ data }) => {
  const [sortConfig, setSortConfig] = useState({ key: null, direction: "asc" });
  const [loading, setLoading] = useState(false);
  const targetCrawledPages = useRef();
  const navigate = useNavigate()
  const websiteurl = useSelector((state) => state.websiteurl);
  const rows = useMemo(() => {
    return data?.map((item) => {
      return {
        title: item?.meta?.title,
        url: item.url,
        inbound_links_count: item?.meta?.inbound_links_count,
        internal_links_count: item.meta?.internal_links_count,
        onpage_score: item?.onpage_score,
        external_links_count: item?.meta?.external_links_count,
        resource_type: item?.resource_type,
      };
    });
  }, [data]);

  const sortedTableData = useMemo(() => {
    if (!sortConfig.key) return rows;
    return [...rows].sort((a, b) => {
      const aValue = a[sortConfig.key];
      const bValue = b[sortConfig.key];

      const isANoRank = aValue === undefined;
      const isBNoRank = bValue === undefined;

      if (isANoRank && isBNoRank) return 0;
      if (isANoRank) return 1;
      if (isBNoRank) return -1;

      if (sortConfig.direction === "asc") {
        return aValue > bValue ? 1 : -1;
      } else {
        return aValue < bValue ? 1 : -1;
      }
    });
  }, [rows, sortConfig]);

  const requestSort = (key) => {
    setLoading(true);
    setTimeout(() => {
      setLoading(false);
    }, 10);
    let direction = "asc";
    if (sortConfig.key === key && sortConfig.direction === "asc") {
      direction = "desc";
    }
    setSortConfig({ key, direction });
  };

  



const handleRoute =()=>{

sessionStorage.setItem('pdfSessionData',JSON.stringify({sortedTableData:sortedTableData,websiteurl:websiteurl,id:1}))
setTimeout(() => {
  navigate("/CrawledPageEditor");
}, 500)
 
}


  return (
    <div>
<div className="d-flex justify-content-end" style={{    padding: "5px 100px"}}>



       <FiDownload onClick={handleRoute} className="down" title="Crawled Pages PDF" aria-label="Close" data-bs-dismiss="modal"/>
     
</div>

      <div className="custom-table-container mt-4" ref={targetCrawledPages}>
        <div
          style={{ width: "100%", height: "20vh" }}
          className="pg-2 d-flex justify-content-center align-items-center flex-column"
        >
          <img src={RanksProLogo} alt="" style={{ width: "300px" }} />
        </div>
        <table className="site-audit-table m-0">
          <thead>
            <tr className="table-sticky">
              <th
                scope="col"
                className="text-start"
                style={{ fontSize: "0.9em", width: "500px" }}
              >
                <span className="point" onClick={() => requestSort("title")}>
                  Pages
                </span>
              </th>
              <th
                scope="col"
                className="text-center"
                style={{ padding: "12px", width: "240px", textWrap: "nowrap" }}
              >
                <span
                  className="point"
                  onClick={() => requestSort("inbound_links_count")}
                >
                  Inbound Links Count
                </span>
              </th>
              <th
                scope="col"
                className="text-center"
                style={{ padding: "12px", width: "220px", textWrap: "nowrap" }}
              >
                <span
                  className="point"
                  onClick={() => requestSort("internal_links_count")}
                >
                  Internal Links Count
                </span>
              </th>
              <th
                className="text-center"
                scope="col"
                style={{ padding: "12px", width: "200px", textWrap: "nowrap" }}
              >
                <span
                  className="point"
                  onClick={() => requestSort("onpage_score")}
                >
                  Onpage Score
                </span>
              </th>
              <th
                scope="col"
                className="text-center"
                style={{ padding: "12px", width: "200px", textWrap: "nowrap" }}
              >
                <span
                  className="point"
                  onClick={() => requestSort("external_links_count")}
                >
                  External Links Count
                </span>
              </th>
              <th
                scope="col"
                className="text-center"
                style={{ width: "230px", textAlign: "center" }}
              >
                Status
              </th>
            </tr>
          </thead>
          <tbody>
            {data?.length !== 0 ? (
              loading ? (
                <tr style={{ height: "300px" }}>
                  <td colSpan={6}>
                    <Spinner />
                  </td>
                </tr>
              ) : (
                sortedTableData?.map((res, index) => {
                  return (
                    <tr key={index}>
                      <td
                        colSpan={1}
                        style={{
                          maxWidth: "",
                          padding: "12px 30px",
                        }}
                      >
                        <div>
                          <span className="">{res?.title}</span>
                          <a
                            href={res.url === "undefined" ? "#" : res.url}
                            target="_blank"
                            rel="noreferrer"
                            style={{
                              maxWidth: "100%",
                              color: "rgb(54, 84, 134)",
                            }}
                            className=""
                          >
                            {res?.url}{" "}
                          </a>
                        </div>
                      </td>

                      <td
                        className="text-center"
                        style={{
                          fontWeight: res?.inbound_links_count ? "400" : "700",
                        }}
                      >
                        {res?.inbound_links_count || "-"}
                      </td>
                      <td
                        className="text-center"
                        style={{
                          fontWeight: res?.internal_links_count ? "400" : "700",
                        }}
                      >
                        {res?.internal_links_count || "-"}
                      </td>
                      <td
                        className="text-center"
                        style={{
                          fontWeight: res?.onpage_score ? "400" : "700",
                        }}
                      >
                        {res?.onpage_score || "-"}
                      </td>
                      <td
                        className="text-center"
                        style={{
                          fontWeight: res?.external_links_count ? "400" : "700",
                        }}
                      >
                        {res?.external_links_count || "-"}
                      </td>

                      <td colSpan={1} className="show-page-status">
                        <span
                          className=""
                          style={
                            res.resource_type === "html"
                              ? {
                                  borderColor: "rgba(53, 114, 77, 1)",
                                  backgroundColor: "rgba(95, 166, 128, 0.6)",
                                }
                              : res.resource_type === "redirect"
                              ? {
                                  borderColor: "rgba(252, 135, 85, 1)",
                                  backgroundColor: "rgba(255, 147, 47, 0.45)",
                                }
                              : {
                                  borderColor: "rgba(129, 13, 13, 0.8)",
                                  backgroundColor: "rgba(254, 54, 54, 0.6)",
                                }
                          }
                        >
                          {res.resource_type === "html"
                            ? "Successful"
                            : res.resource_type}
                        </span>
                      </td>
                    </tr>
                  );
                })
              )
            ) : (
              <tr>
                <td colSpan={6}>You don't have any data !</td>
              </tr>
            )}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default memo(CrawledPagesPdf);
