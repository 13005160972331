import React, { useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import Dropdown from "../components/dropdown";
import countryList from "../../dataFiles/country";
import globeImg from "../../Assets/rank-tracking-assets/globe.png";
import { ZustandStore } from "../../../store/zustand-store";

const ProjectLocation = (props) => {
  const dispatch = useDispatch();
  const usercurrentprojectlocation = useSelector(
    (state) => state.usercurrentprojectlocation
  );
  const {
    keyword_loc_data,
    keyword_latest_data,
    keyword_old_data,
    emptyKeywordData,
    emptyTagData,
  } = ZustandStore((state) => state);
  const CurrentLocation = useRef([]);
  const currentlocation = useSelector((state) => state.currentlocation);

  CurrentLocation.current = usercurrentprojectlocation;
  CurrentLocation.current =
    usercurrentprojectlocation &&
    usercurrentprojectlocation.filter((obj, index, self) => {
      return (
        index === self.findIndex((t) => t.location_code === obj.location_code)
      );
    });

  const LocationHandler = (e) => {
    if (
      e.location_name === keyword_loc_data &&
      keyword_old_data?.length > 0 &&
      keyword_latest_data?.length > 0
    )
      return;
    emptyTagData();
    emptyKeywordData();
    dispatch({ type: "CURRENTLOCATIONCODE", payload: e.location_code });
    dispatch({ type: "CURRENTLOCATION", payload: e.location_name });
    dispatch({ type: "LOADING" });
  };

  const getCurrentCountryDetails = () => {
    const data = countryList?.filter((item) => {
      return item.location_name === currentlocation;
    });
    if (currentlocation === "All Locations") {
      return globeImg;
    }
    return data?.[0]?.location_flag;
  };

  const dataWithFlags = CurrentLocation?.current?.map((item) => {
    const selected_country = countryList?.filter((country) => {
      return country.location_name === item.location_name;
    });
    return {
      ...item,
      location_flag: selected_country?.[0]?.location_flag,
    };
  });

  return (
    <Dropdown
      data={[
        {
          location_name: "All Locations",
          location_code: "All Locations",
          location_flag: globeImg,
        },
        ...dataWithFlags,
      ]}
      title={currentlocation}
      callback={LocationHandler}
      img={getCurrentCountryDetails()}
      style={
        props.style
          ? props.style
          : {
              width: "30%",
              textTransform: "none",
              backgroundColor: "#fff",
              boxShadow: "none",
              borderRadius: "10px",
            }
      }
    />
  );
};

export default ProjectLocation;
