import axios from "axios";
import React from "react";
import { useEffect } from "react";
import { useState } from "react";
import {
  delete_Plan,
  get_Plans_Details,
  post_Plans_Details,
  update_plans_Details,
} from "../../services/constants";
import { DottLoader } from "../../Mycomponent/share/loader";
import AreYousure from "../../Mycomponent/share/card-layout/AreYousure";
import CustomTable from "../../Mycomponent/share/customTable";
import AddIcon from "@mui/icons-material/Add";
import { Box, Button } from "@mui/material";
import { CSVLink } from "react-csv";

const Plans = () => {
  // state manage
  const [page, setPage] = React.useState(1);
  const [planDetails, setPlanDetails] = useState([]);
  const [ShowAlert, setShowAlert] = useState(false);
  const [deleteAlert, setdeleteAlert] = useState(false);
  const [updateAlert, setUpdateAlert] = useState(false);
  const [planId, setPlanId] = useState(null);
  const [price, setPrice] = useState(null);
  const [name, setName] = useState("alpha");
  const [productId, setProductId] = useState(null);
  const [priceId, setPriceId] = useState(null);
  const [link, setLink] = useState(null);
  const [projlmt, setProjLmt] = useState(0);
  const [keyLmt, setKeyLmt] = useState(0);
  const [inviteLmt, setInviteLmt] = useState(0);
  const [CompeLmt, setCompeLmt] = useState(0);
  const [domainLmt, setDomainLmt] = useState(0);
  const [auditLmt, setAuditLmt] = useState(0);
  const [crawlDomainLmt, setCrawlDomainLmt] = useState(0);
  const [keywordViewLimit, setKeywordviewLimit] = useState(0);
  const [backlinkrowlimit, setBacklinkrowLimit] = useState(0);
  const [keyListLimit, setKeyListLimit] = useState(0);
  const [backlinkdomainlimit, setBacklinkdomainLimit] = useState(0);
  const [validityFor, setValidityFor] = useState("month");
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    fatchPlan_Details();
  }, []);

  const fatchPlan_Details = () =>
    axios.get(get_Plans_Details()).then((res) => {
      setPlanDetails(res.data.data);
      setLoading(false);
    });

  const ShowAlertHandler = () => {
    setShowAlert(true);
    setPrice(null);
    setProductId(null);
    setName("alpha");
    setLink(null);
    setProjLmt(null);
    setKeyLmt(null);
    setInviteLmt(null);
    setCompeLmt(null);
    setDomainLmt(null);
    setAuditLmt(null);
    setKeywordviewLimit(null);
    setValidityFor("month");
    setCrawlDomainLmt(null);
    setPriceId(null);
  };

  const UpdateAlert = (e) => {
    setUpdateAlert(true);
    setPlanId(e.id);
    setPrice(e.price);
    setProductId(e.prod_id);
    setName(e.name);
    setLink(e.payment_link);
    setProjLmt(e.proj_len);
    setKeyLmt(e.keyword_len);
    setInviteLmt(e.sub_users_limit);
    setCompeLmt(e.competitors_limit);
    setDomainLmt(e.site_overview_limit);
    setAuditLmt(e.site_audit_limit);
    setValidityFor(e.validity);
    setCrawlDomainLmt(e.crawl_domain_limit);
    setKeywordviewLimit(e.key_limit);
    setPriceId(e.price_id);
    setBacklinkdomainLimit(e.backlinks_domain_lmt);
    setBacklinkrowLimit(e.backlinks_rows_limit);
    setKeyListLimit(e.key_list_lmt);
  };

  const CancelHandler = () => {
    setShowAlert(false);
    setUpdateAlert(false);
  };

  const PlansHandler = () => {
    const data = {
      price: price,
      prod_id: productId,
      payment_link: link,
      name: name,
      validity: validityFor,
      proj_len: projlmt,
      keyword_len: keyLmt,
      competitors_limit: CompeLmt,
      sub_users_limit: inviteLmt,
      site_overview_limit: domainLmt,
      site_audit_limit: auditLmt,
      crawl_domain_limit: crawlDomainLmt,
      key_limit: keywordViewLimit,
      key_list_lmt: keyListLimit,
      price_id: priceId,
      backlinks_rows_limit: backlinkrowlimit,
      backlinks_domain_lmt: backlinkdomainlimit,
    };

    if (ShowAlert === true) {
      axios.post(post_Plans_Details(), data).then(() => {
        setShowAlert(false);
        fatchPlan_Details();
      });
    } else if (updateAlert === true) {
      axios.put(update_plans_Details(planId), data).then(() => {
        setUpdateAlert(false);
        fatchPlan_Details();
      });
    }
  };

  const DeleteAlert = (e) => {
    setdeleteAlert(true);
    setPlanId(e);
  };

  const DeletePlan = (res) => {
    if (res) {
      axios
        .delete(delete_Plan(planId))
        .then(() => {
          setdeleteAlert(false);
          fatchPlan_Details();
        })
        .catch(() => {});
    } else {
      setdeleteAlert(false);
    }
  };

  const tableHeader = [
    {
      label: "NAME",
      key: "name",
      minWidth: 100,
      align: "left",
    },
    {
      label: "PRICE",
      key: "price",
      minWidth: 100,
      align: "center",
    },
    {
      label: "PRODUCT ID",
      key: "product_id",
      minWidth: 190,
      align: "center",
    },

    {
      label: "PROJECT LGTH",
      key: "project_lgth",
      minWidth: 150,
      align: "center",
    },
    {
      label: "KEYWORD LGTH",
      key: "keyword_lgth",
      minWidth: 150,
      align: "center",
    },
    {
      label: "INVITE LGTH",
      key: "invite_lgth",
      minWidth: 120,
      align: "center",
    },
    {
      label: "COMPETITORS LGTH",
      key: "competitors_lgth",
      minWidth: 170,
      align: "center",
    },
    {
      label: "OVERVIEW LGTH",
      key: "overview_lgth",
      minWidth: 150,
      align: "center",
    },
    {
      label: "SITEAUDIT LGTH",
      key: "siteaudit_lgth",
      minWidth: 150,
      align: "center",
    },
    {
      label: "CRAWL DOMAIN LGTH",
      key: "crawl_domain_lgth",
      minWidth: 180,
      align: "center",
    },
    {
      label: "KEYWORD VIEW LGTH",
      key: "keyword_view_lgth",
      minWidth: 180,
      align: "center",
    },
    {
      label: "BACKLINK ROW LMT",
      key: "backlink_row_lgth",
      minWidth: 180,
      align: "center",
    },
    {
      label: "BACKLINK DOMAIN LMT",
      key: "backlink_domin_lmt",
      minWidth: 200,
      align: "center",
    },
    {
      label: "KEY LIST LMT",
      key: "key_list_lmt",
      minWidth: 150,
      align: "center",
    },
    {
      label: "PAYMENT LINK",
      key: "payment_link",
      minWidth: 170,
      align: "center",
    },
    {
      label: "VALIDITY",
      key: "validity",
      minWidth: 120,
      align: "center",
    },
    {
      label: "",
      key: "space",
      minWidth: 100,
      align: "center",
    },
  ];

  function createData(
    id,
    name,
    price,
    product_id,
    project_lgth,
    keyword_lgth,
    invite_lgth,
    competitors_lgth,
    overview_lgth,
    siteaudit_lgth,
    crawl_domain_lgth,
    keyword_view_lgth,
    backlink_row_lgth,
    backlink_domin_lmt,
    key_list_lmt,
    payment_link,
    validity,
    space
  ) {
    return {
      id,
      name,
      price,
      product_id,
      project_lgth,
      keyword_lgth,
      invite_lgth,
      competitors_lgth,
      overview_lgth,
      siteaudit_lgth,
      crawl_domain_lgth,
      keyword_view_lgth,
      backlink_row_lgth,
      backlink_domin_lmt,
      key_list_lmt,
      payment_link,
      validity,
      space,
    };
  }

  const rows = planDetails?.map((item) => {
    return createData(
      item?.id,
      item?.name,
      "$" + `${item?.price}`,
      item?.prod_id,
      item?.proj_len,
      item?.keyword_len,
      item?.sub_users_limit,
      item?.competitors_limit,
      item?.site_overview_limit,
      item?.site_audit_limit,
      item?.crawl_domain_limit || 0,
      item?.key_limit || 0,
      item?.backlinks_rows_limit || 0,
      item?.backlinks_domain_lmt || 0,
      item?.key_list_lmt || 0,
      <span className="tb-link-lmt">{item?.payment_link}</span>,
      item?.validity,
      <>
        <span
          onClick={() => UpdateAlert(item)}
          style={{ color: "rgb(162, 68, 68)", fontSize: "18px" }}
        >
          <i className="fa-solid fa-edit"></i>
        </span>
        <span
          onClick={() => DeleteAlert(item.id)}
          style={{
            color: "rgb(162, 68, 68)",
            fontSize: "18px",
            marginLeft: "15px",
          }}
        >
          <i className=" fa-solid fa-trash"></i>
        </span>
      </>
    );
  });

  const loadCSVData = () => {
    if (planDetails?.length === 0) return [];
    return planDetails?.map((item) => {
      const obj = {
        name: item?.name,
        price: "$" + `${item?.price}`,
        product_id: item?.prod_id,
        project_length: item?.proj_len,
        keyword_length: item?.keyword_len,
        sub_users_limit: item?.sub_users_limit,
        competitors_limit: item?.competitors_limit,
        site_overview_limit: item?.site_overview_limit,
        site_audit_limit: item?.site_audit_limit,
        crawl_domain_limit: item?.crawl_domain_limit || 0,
        key_limit: item?.key_limit || 0,
        backlinks_rows_limit: item?.backlinks_rows_limit || 0,
        backlink_domin_lmt: item?.backlinks_domain_lmt || 0,
        key_list_lmt: item?.key_list_lmt || 0,
        validity: item?.validity,
      };
      return obj;
    });
  };

  if (loading) {
    return (
      <div>
        <h1 className="table_title heading-large font-bold mb-4">Plans</h1>
        <DottLoader />
      </div>
    );
  }

  return (
    <>
      <CustomTable
        showSearchBar={true}
        tableTitle="Plans"
        downloadCSVFile={
          <div>
            <Button
              className="btn_1 me-2 ms-2"
              onClick={() => ShowAlertHandler()}
              startIcon={<AddIcon />}
            >
              Add Plan
            </Button>
            <CSVLink data={loadCSVData()} filename={"plan_list.csv"}>
              <Button className="btn_1">Download CSV</Button>
            </CSVLink>
          </div>
        }
        tableHeader={tableHeader}
        rows={rows}
        page={page}
        setPage={setPage}
      />

      {/* delete plan popup  */}
      {deleteAlert ? (
        <AreYousure
          callback={DeletePlan}
          title={"You will not able to recover this Plan !"}
        />
      ) : null}

      {/* pop up for add plan */}
      {ShowAlert || updateAlert ? (
        <div className="pop">
          <div className="popBody">
            <h3>Add Plan </h3>
            <div className="pop-form">
              <div style={{ textAlign: "left" }}>
                <input
                  type="Number"
                  placeholder="Price"
                  value={price}
                  onChange={(e) => setPrice(e.target.value)}
                ></input>
                <label id="lb">$Price</label>
                <input
                  type="text"
                  placeholder="Product Id"
                  value={productId}
                  onChange={(e) => setProductId(e.target.value)}
                ></input>
                <label id="lb">Product Id</label>
                <input
                  type="text"
                  placeholder="Price Id"
                  value={priceId}
                  onChange={(e) => setPriceId(e.target.value)}
                ></input>
                <label id="lb">Price Id</label>
                <input
                  type="url"
                  placeholder="https://Payment Link "
                  value={link}
                  onChange={(e) => setLink(e.target.value)}
                ></input>
                <label id="lb">Link</label>
                <div className="">
                  <div className="row">
                    <div className="col-6">
                      <input
                        type="number"
                        placeholder="Project limit "
                        value={projlmt}
                        onChange={(e) => setProjLmt(e.target.value)}
                      ></input>
                      <label id="lb">Project Lmt </label>
                    </div>
                    <div className="col-6">
                      <input
                        type="number"
                        placeholder="Keyword limit "
                        value={keyLmt}
                        onChange={(e) => setKeyLmt(e.target.value)}
                      ></input>
                      <label id="lb">Keyword Lmt</label>
                    </div>
                    <div className="col-6">
                      <input
                        type="number"
                        placeholder="Invite limit "
                        value={inviteLmt}
                        onChange={(e) => setInviteLmt(e.target.value)}
                      ></input>
                      <label id="lb">Invite Lmt </label>
                    </div>
                    <div className="col-6">
                      <input
                        type="number"
                        placeholder="Competitors limit "
                        value={CompeLmt}
                        onChange={(e) => setCompeLmt(e.target.value)}
                      ></input>
                      <label id="lb">Competitors Lmt</label>
                    </div>
                    <div className="col-6">
                      <input
                        type="number"
                        placeholder="Overview limit "
                        value={domainLmt}
                        onChange={(e) => setDomainLmt(e.target.value)}
                      ></input>
                      <label id="lb">Overview Lmt</label>
                    </div>
                    <div className="col-6">
                      <input
                        type="number"
                        placeholder="Audit limit"
                        value={auditLmt}
                        onChange={(e) => setAuditLmt(e.target.value)}
                      ></input>
                      <label id="lb">Audit Lmt</label>
                    </div>
                    <div className="col-6">
                      <input
                        type="number"
                        placeholder="Crawl Domain Lmt"
                        value={crawlDomainLmt}
                        onChange={(e) => setCrawlDomainLmt(e.target.value)}
                      ></input>
                      <label id="lb">Crawl Domain Lmt</label>
                    </div>
                    <div className="col-6">
                      <input
                        type="number"
                        placeholder="Keyowrd View Lmt"
                        value={keywordViewLimit}
                        onChange={(e) => setKeywordviewLimit(e.target.value)}
                      ></input>
                      <label id="lb">Keyword View Lmt</label>
                    </div>
                    <div className="col-6">
                      <input
                        type="number"
                        placeholder="Backlink Rows Limit"
                        value={backlinkrowlimit}
                        onChange={(e) => setBacklinkrowLimit(e.target.value)}
                      ></input>
                      <label id="lb">Backlink Row Lmt</label>
                    </div>
                    <div className="col-6">
                      <input
                        type="number"
                        placeholder="Backlinks Domain Limit"
                        value={backlinkdomainlimit}
                        onChange={(e) => setBacklinkdomainLimit(e.target.value)}
                      ></input>
                      <label id="lb">Backlinks Domain Lmt</label>
                    </div>
                    <div className="col-12">
                      <input
                        type="number"
                        placeholder="Key List Limit"
                        value={keyListLimit}
                        onChange={(e) => setKeyListLimit(e.target.value)}
                      ></input>
                      <label id="lb">Key List Limit</label>
                    </div>
                  </div>
                </div>
                <div className="pop-select mt-2">
                  <select
                    value={name}
                    onChange={(e) => setName(e.target.value)}
                  >
                    <option value="alpha">alpha</option>
                    <option value="starter">starter</option>
                    <option value="pro">pro</option>
                    <option value="elite">elite</option>
                    <option value="ultimate">ultimate</option>
                    <option value="lifetime">lifetime</option>
                  </select>
                  <label id="lb"> Plan Name </label>
                </div>

                <div className="pop-select mt-2">
                  <select
                    value={validityFor}
                    onChange={(e) => setValidityFor(e.target.value)}
                  >
                    <option value="month">Month</option>
                    <option value="yearly">Yearly</option>
                    <option value="lifetime">lifetime</option>
                  </select>
                  <label id="lb">Validity For </label>
                </div>
              </div>
            </div>

            <div className="cmd">
              <button className="cm-btn-b" onClick={() => CancelHandler()}>
                Cancel
              </button>
              <button className="cm-btn" onClick={() => PlansHandler()}>
                Submit
              </button>
            </div>
            {/* </form> */}
          </div>
        </div>
      ) : null}
    </>
  );
};

export default Plans;
