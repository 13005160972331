import countryList from "../Mycomponent/dataFiles/country";

export const getCurrentCountryDetails = (location) => {
  const data = countryList?.filter((item) => {
    return item.location_name === location;
  });
  return data;
};

export const getCurrentCountryFlag = (location) => {
  const data = countryList?.filter((item) => {
    return item.location_name === location;
  });
  return data?.[0]?.location_flag;
};
