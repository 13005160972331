import React from "react";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
import { Bar } from "react-chartjs-2";

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
);

function BarChart({ data }) {
  const option = {
    maintainAspectRatio: false,
    responsive: true,
    interaction: { mode: "index", intersect: false },
    plugins: {
      title: {
        display: false,
      },
      legend: {
        display: false,
      },
      tooltip: {
        boxPadding: 5,
        cornerRadius: 10,
        caretSize: 10,
        padding: 10,
        margin: 10,
        boxHeight: 30,
        borderWidth: 4,
        boxWidth: 4,
        titleColor: "black",
        bodyColor: "black",
        bodyFont: {
          size: 16,
        },
        bodySpacing: 10,
        usePointStyle: true,
        backgroundColor: "#F0F0F0",
        titleFont: {
          weight: "bold",
          size: 15,
        },
      },
    },
    scales: {
      x: {
        stacked: false,
        ticks: {
          display: true,
          color: "black",
          font: {
            size: 16,
            weight: 500,
          },
        },
        grid: {
          display: false,
        },
      },
      y: {
        grid: {
          display: true,
        },
        suggestedMin: 1,
        ticks: {
          font: {
            weight: 500,
            size: 16,
          },
          color: "black",
          maxTicksLimit: 7,
          stepSize: 300,
          autoSkip: false,
          beginAtZero: false,
          min: 1,
        },
        border: {
          display: true,
          width: 2,
          dash: [3, 3],
        },
        stacked: true,
      },
    },
  };

  const labels = data?.labels;

  const bar_data = {
    labels,
    datasets: [
      {
        label: "Conversion Rate",
        data: data?.barData,
        backgroundColor: "rgba(69, 112, 151, 1)",
      },
    ],
  };

  return (
    <Bar
      options={option}
      data={bar_data}
      style={{ width: "100% !important", height: "100% !important" }}
    />
  );
}
export default BarChart;
