import React from "react";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Filler,
  Legend,
} from "chart.js";
import { Line } from "react-chartjs-2";

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Filler,
  Legend
);

function LineChart({ data }) {
  const options = {
    maintainAspectRatio: false,
    responsive: true,
    interaction: { mode: "index", intersect: false },
    plugins: {
      legend: {
        position: "top",
        display: false,
      },
      title: {
        display: true,
      },
      axisY: {
        gridDashType: "dash",
      },
      tooltip: {
        boxPadding: 5,
        cornerRadius: 10,
        caretSize: 10,
        padding: 10,
        margin: 10,
        boxHeight: 30,
        borderWidth: 4,
        boxWidth: 4,
        titleColor: "black",
        bodyColor: "black",
        bodyFont: {
          size: 16,
        },
        bodySpacing: 10,
        usePointStyle: true,
        backgroundColor: "#F0F0F0",
        titleFont: {
          weight: "bold",
          size: 15,
        },
      },
    },
    scales: {
      y: {
        type: "linear",
        display: true,
        position: "left",
        reverse: false,
        beginAtZero: true,
        border: {
          display: false,
          width: 2,
          dash: [6, 6],
        },
        grid: {
          display: true,
          color: "rgba(0, 0, 0, 0.2)",
          lineWidth: 1,
          borderDash: [3, 7], // Set the borderDash array for the Y-axis grid lines
        },
        ticks: {
          font: {
            size: 18,
            weight: 500,
          },
          color: "black",
          maxTicksLimit: 7,
          stepSize: 4,
          autoSkip: false,
          beginAtZero: true,
        },
      },

      x: {
        ticks: {
          display: false,
        },
        grid: {
          display: false,
        },
        border: {
          display: false,
          width: 2,
        },
      },
    },
  };

  const labels = data?.labels;
  const line_data = {
    labels,
    datasets: data?.dataSet,
  };

  return <Line options={options} data={line_data} id="single-line-chart" />;
}

export default LineChart;
