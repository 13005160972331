import React from "react";
import { Outlet } from "react-router-dom";
import SideBar from "./admin/share/sideBar";
import Footer from "./Mycomponent/share/footer";
import Header from "./Mycomponent/share/header";
import MANAGEDATA_CONFIGURE from "./confrigration/constants";
import { useDispatch } from "react-redux";

const Layout = () => {
  const dispatch = useDispatch();
  dispatch({ type: "SHOWMENU", payload: false });

  return (
    <div>
      <MANAGEDATA_CONFIGURE admin={false} />
      <div className="body_layout">
        <SideBar type="user" />
        <div className="layout_outlet" id="home_layout">
          <Header />
          <div className="home_layout">
            <Outlet />
          </div>
          <Footer />
        </div>
      </div>
    </div>
  );
};

export default Layout;
