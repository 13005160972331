export const language = [
  {
    language_name: "Afrikaans",
    language_code: "af",
  },
  {
    language_name: "Akan",
    language_code: "ak",
  },
  {
    language_name: "Albanian",
    language_code: "sq",
  },
  {
    language_name: "Amharic",
    language_code: "am",
  },
  {
    language_name: "Arabic",
    language_code: "ar",
  },
  {
    language_name: "Armenian",
    language_code: "hy",
  },
  {
    language_name: "Azeri",
    language_code: "az",
  },
  {
    language_name: "Balinese",
    language_code: "ban",
  },
  {
    language_name: "Basque",
    language_code: "eu",
  },
  {
    language_name: "Belarusian",
    language_code: "be",
  },
  {
    language_name: "Bengali",
    language_code: "bn",
  },
  {
    language_name: "Bosnian",
    language_code: "bs",
  },
  {
    language_name: "Bulgarian",
    language_code: "bg",
  },
  {
    language_name: "Burmese",
    language_code: "my",
  },
  {
    language_name: "Catalan",
    language_code: "ca",
  },
  {
    language_name: "Cebuano",
    language_code: "ceb",
  },
  {
    language_name: "Chichewa",
    language_code: "ny",
  },
  {
    language_name: "Chinese (Simplified)",
    language_code: "zh-CN",
  },
  {
    language_name: "Chinese (Traditional)",
    language_code: "zh-TW",
  },
  {
    language_name: "Croatian",
    language_code: "hr",
  },
  {
    language_name: "Czech",
    language_code: "cs",
  },
  {
    language_name: "Danish",
    language_code: "da",
  },
  {
    language_name: "Dutch",
    language_code: "nl",
  },
  {
    language_name: "English",
    language_code: "en",
  },
  {
    language_name: "Espanol (Latinoamerica)",
    language_code: "es-419",
  },
  {
    language_name: "Estonian",
    language_code: "et",
  },
  {
    language_name: "Ewe",
    language_code: "ee",
  },
  {
    language_name: "Faroese",
    language_code: "fo",
  },
  {
    language_name: "Farsi",
    language_code: "fa",
  },
  {
    language_name: "Filipino",
    language_code: "fil",
  },
  {
    language_name: "Finnish",
    language_code: "fi",
  },
  {
    language_name: "French",
    language_code: "fr",
  },
  {
    language_name: "Frisian",
    language_code: "fy",
  },
  {
    language_name: "Ga",
    language_code: "gaa",
  },
  {
    language_name: "Galician",
    language_code: "gl",
  },
  {
    language_name: "Ganda",
    language_code: "lg",
  },
  {
    language_name: "Georgian",
    language_code: "ka",
  },
  {
    language_name: "German",
    language_code: "de",
  },
  {
    language_name: "Greek",
    language_code: "el",
  },
  {
    language_name: "Gujarati",
    language_code: "gu",
  },
  {
    language_name: "Haitian",
    language_code: "ht",
  },
  {
    language_name: "Hausa",
    language_code: "ha",
  },
  {
    language_name: "Hebrew",
    language_code: "he",
  },
  {
    language_name: "Hebrew (old)",
    language_code: "iw",
  },
  {
    language_name: "Hindi",
    language_code: "hi",
  },
  {
    language_name: "Hungarian",
    language_code: "hu",
  },
  {
    language_name: "Icelandic",
    language_code: "is",
  },
  {
    language_name: "IciBemba",
    language_code: "bem",
  },
  {
    language_name: "Igbo",
    language_code: "ig",
  },
  {
    language_name: "Indonesian",
    language_code: "id",
  },
  {
    language_name: "Irish",
    language_code: "ga",
  },
  {
    language_name: "Italian",
    language_code: "it",
  },
  {
    language_name: "Japanese",
    language_code: "ja",
  },
  {
    language_name: "Kannada",
    language_code: "kn",
  },
  {
    language_name: "Kazakh",
    language_code: "kk",
  },
  {
    language_name: "Khmer",
    language_code: "km",
  },
  {
    language_name: "Kinyarwanda",
    language_code: "rw",
  },
  {
    language_name: "Kirundi",
    language_code: "rn",
  },
  {
    language_name: "Kongo",
    language_code: "kg",
  },
  {
    language_name: "Korean",
    language_code: "ko",
  },
  {
    language_name: "Kreol morisien",
    language_code: "mfe",
  },
  {
    language_name: "Kreol Seselwa",
    language_code: "crs",
  },
  {
    language_name: "Krio",
    language_code: "kri",
  },
  {
    language_name: "Kurdish",
    language_code: "ckb",
  },
  {
    language_name: "Kyrgyz",
    language_code: "ky",
  },
  {
    language_name: "Lao",
    language_code: "lo",
  },
  {
    language_name: "Latvian",
    language_code: "lv",
  },
  {
    language_name: "Lingala",
    language_code: "ln",
  },
  {
    language_name: "Lithuanian",
    language_code: "lt",
  },
  {
    language_name: "Luo",
    language_code: "ach",
  },
  {
    language_name: "Macedonian",
    language_code: "mk",
  },
  {
    language_name: "Malagasy",
    language_code: "mg",
  },
  {
    language_name: "Malay",
    language_code: "ms",
  },
  {
    language_name: "Malayam",
    language_code: "ml",
  },
  {
    language_name: "Maltese",
    language_code: "mt",
  },
  {
    language_name: "Maori",
    language_code: "mi",
  },
  {
    language_name: "Marathi",
    language_code: "mr",
  },
  {
    language_name: "Mongolian",
    language_code: "mn",
  },
  {
    language_name: "Montenegro",
    language_code: "sr-ME",
  },
  {
    language_name: "Nepali",
    language_code: "ne",
  },
  {
    language_name: "Northern Sotho",
    language_code: "nso",
  },
  {
    language_name: "Norwegian",
    language_code: "no",
  },
  {
    language_name: "Nyankole",
    language_code: "nyn",
  },
  {
    language_name: "Oromo",
    language_code: "om",
  },
  {
    language_name: "Pashto",
    language_code: "ps",
  },
  {
    language_name: "Pidgin",
    language_code: "pcm",
  },
  {
    language_name: "Polish",
    language_code: "pl",
  },
  {
    language_name: "Portuguese",
    language_code: "pt",
  },
  {
    language_name: "Portuguese (Brazil)",
    language_code: "pt-BR",
  },
  {
    language_name: "Portuguese (Portugal)",
    language_code: "pt-PT",
  },
  {
    language_name: "Punjabi",
    language_code: "pa",
  },
  {
    language_name: "Quechua",
    language_code: "qu",
  },
  {
    language_name: "Romanian",
    language_code: "ro",
  },
  {
    language_name: "Romansh",
    language_code: "rm",
  },
  {
    language_name: "Russian",
    language_code: "ru",
  },
  {
    language_name: "Serbian",
    language_code: "sr",
  },
  {
    language_name: "Serbian (Latin)",
    language_code: "sr-Latn",
  },
  {
    language_name: "Sesotho",
    language_code: "st",
  },
  {
    language_name: "Shona",
    language_code: "sn",
  },
  {
    language_name: "Silozi",
    language_code: "loz",
  },
  {
    language_name: "Sindhi",
    language_code: "sd",
  },
  {
    language_name: "Sinhalese",
    language_code: "si",
  },
  {
    language_name: "Slovak",
    language_code: "sk",
  },
  {
    language_name: "Slovenian",
    language_code: "sl",
  },
  {
    language_name: "Somali",
    language_code: "so",
  },
  {
    language_name: "Spanish",
    language_code: "es",
  },
  {
    language_name: "Swahili",
    language_code: "sw",
  },
  {
    language_name: "Swedish",
    language_code: "sv",
  },
  {
    language_name: "Tagalog",
    language_code: "tl",
  },
  {
    language_name: "Tajik",
    language_code: "tg",
  },
  {
    language_name: "Tamil",
    language_code: "ta",
  },
  {
    language_name: "Telugu",
    language_code: "te",
  },
  {
    language_name: "Thai",
    language_code: "th",
  },
  {
    language_name: "Tigrinya",
    language_code: "ti",
  },
  {
    language_name: "Tonga (Tonga Islands)",
    language_code: "to",
  },
  {
    language_name: "Tshiluba",
    language_code: "lua",
  },
  {
    language_name: "Tswana",
    language_code: "tn",
  },
  {
    language_name: "Tumbuka",
    language_code: "tum",
  },
  {
    language_name: "Turkish",
    language_code: "tr",
  },
  {
    language_name: "Turkmen",
    language_code: "tk",
  },
  {
    language_name: "Ukrainian",
    language_code: "uk",
  },
  {
    language_name: "Urdu",
    language_code: "ur",
  },
  {
    language_name: "Uzbek",
    language_code: "uz",
  },
  {
    language_name: "Vietnamese",
    language_code: "vi",
  },
  {
    language_name: "Wolof",
    language_code: "wo",
  },
  {
    language_name: "Xhosa",
    language_code: "xh",
  },
  {
    language_name: "Yoruba",
    language_code: "yo",
  },
  {
    language_name: "Zulu",
    language_code: "zu",
  },
];
