// validation url base
export const forgotPath = "/api/user/reset/*";
export const register_InvitePath = "/api/user/invite/*";
export const register_Affiliate = `/affiliate/user/register/`;
export const baseUrlPath = "app.rankspro.io";

// appsumo routes
export const register_appsumo = "appsumo-signup";
// root path for this project
export const project = "/projects";
export const nopage = "*";
export const home = "/";
export const login = "login";
export const pllogin = "pllogin";
export const register = "register";
export const forgot = "forgot";
export const profile = "profile";
export const rank = "rank-tracker";
export const guest_rank = "guest/rank-tracker";
export const overview = "site-overview";
export const keywords = "keyword-research";
export const siteaudit = "site-audit";
export const ViewAllList = "view-lists";
export const traffic = "traffic";
export const upgrade = "upgrade";
export const addpr = "addpr";
export const addwebsite = "addwebsite";
export const addcompetitors = "addcompetitors";
export const addcountry = "addcountry";
export const addkeyword = "addkeyword";
export const gotraffic = "gotraffic";
export const admin = "/admin";
export const dashboard = "dashboard";
export const sidebar = "sidebar";
export const plans = "plans";
export const adminkeyword = "adminkeywords";
export const users = "users";
export const codes = "codes";
export const mail = "mail";
export const feedback = "feedback";
export const affiliateReward = "affiliate-reward";
export const inviteemail = "invite-mail";

export const digitalAgency = "digital-agencies";
export const agencydetail = "digital-agencies-profile";
export const agencyform = "digital-agencies-form";
export const aiarticle = "rank-tracker/ai/content-writer";
export const seokeyword = "site-overview/seo-keyword";
export const aiprojects = "ai/content/projects";

// Affiliate
export const affiliate = "/affiliate";
export const affiliateList = "affiliate-list";
export const affiliate_withdrawal = "affiliate-withdrawal-request";

export const performance_metrics = "performance-metrics";
export const affiliate_notification = "message-and-notification";
export const payment_earnings = "payment-and-earnings";
export const promotional = "promotional-material";
export const aff_dashboard = "dashboard";
export const PROFILE = "profile";
// For normal
export const LOGIN = "/affiliate/login";
export const FORGOT = "/affiliate/forgot";
export const SIGNUP = "/affiliate/signup";
export const VERIFY = "/affiliate/verify";
export const SIGNUPREFER =
  "http://localhost:3000/affiliate/user/register/?aff_id=:id"; // For Refer

// backlinks
export const backlinks = "backlinks";
export const backlink_smtp = "backlink/contact";
export const ViewAllListBackLink = "view-backlink-lists";
export const backlink_gap = "backlink-gap";

// seo tools
export const backlinkChecker = "free-backlink-checker";
export const siteOverViewChecker = "free-site-overview-checker";
export const rankChecker = "free-rank-checker";
export const seoTool = "free-seo-insights-checker";

// Free Tools routes for Admin Pamels

export const freeTool = "free-tool-usage";
export const freeTool_TableRankTracker = "free-tool-rank-tracker";
export const freeTool_TablePageVital = "free-tool-page-vital";
export const freeTool_TablebackLink = "free-tool-backlink";
export const ai_CaptionGen = "free-caption-generator";

// app.sumo

export const register_sumo = "/register/app-sumo";
export const login_sumo = "/login/app-sumo";
export const forget_sumo = "/forgot/app-sumo";

// Digital Agency
export const digital_agency = "digital-agency";
export const single_agency = "details";
export const update_agency = "detail/update-agency";
export const contact_agency = "contact-agency";

// Reviews
export const review = "reviews";
// google data
export const google_data = "google-data";


//pdfEditors

export const Crawled_Page_Editor = "/CrawledPageEditor"