import axios from "axios";

export const submit_hubspot_form = async (fields) => {
  const portalId = "46530613"; // example portal ID (not real)
  const formGuid = "2f70694a-cd1d-4713-b76b-480d6754fe90"; // example form GUID (not real)
  const config = {
    // important!
    headers: {
      "Content-Type": "application/json",
    },
  };

  const response = await axios.post(
    `https://api.hsforms.com/submissions/v3/integration/submit/${portalId}/${formGuid}`,
    {
      portalId,
      formGuid,
      fields,
    },
    config
  );
  return response;
};

export const updateContact = async (profile, plan) => {
  const hubspot_fields = {
    firstname: profile?.name,
    lastname: profile?.last_name === "0" ? "" : profile?.last_name || "",
    email: profile?.email,
    company: profile?.company === "0" ? "" : profile?.company || "",
    country: profile?.country === "0" ? "" : profile?.country || "",
    rankspro_plan: plan,
  };

  const url = `https://app.rankspro.io/api/user/hubspot/?email=${hubspot_fields?.email}&f_name=${hubspot_fields?.firstname}&l_name=${hubspot_fields?.lastname}&company=${hubspot_fields?.company}&country=${hubspot_fields?.country}&plan=${hubspot_fields?.rankspro_plan}`;

  try {
    await axios.post(url, {
      headers: {
        "Content-Type": "application/json",
      },
    });
  } catch (error) {
    console.error("Error updating contact:", error);
  }
};
