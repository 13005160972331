import React from "react";
import { styled } from "@mui/material/styles";
import Tooltip, { tooltipClasses, TooltipProps } from "@mui/material/Tooltip";
import IconButton from "@mui/material/IconButton";
import HelpIcon from "@mui/icons-material/Help";
import Zoom from "@mui/material/Zoom";
import { ReactComponent as ToolTipIcon } from "../../Assets/rank-tracking-assets/tooltip-ques.svg";

const StyledTooltip = styled(({ className, ...props }) => (
  <Tooltip
    {...props}
    arrow
    classes={{ popper: className }}
    disableInteractive
    sx={{ zIndex: (theme) => theme.zIndex.tooltip + 1 }}
  />
))(({ theme }) => ({
  [`& .${tooltipClasses.arrow}`]: {
    color: "black",
    "&::before": {
      backgroundColor: "#FFFFFF",
      border: "1px solid #FFFFFF",
    },
  },
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: "#FFFFFF",
    color: "rgba(0, 0, 0, 0.7)",
    maxWidth: 300,
    textTransform: "capitalise",
    textAlign: "center",
    padding: 8,
    fontSize: theme.typography.pxToRem(12),
    border: "1px solid #dadde9",
  },
}));

const SimpleTooltip = styled(({ className, ...props }) => (
  <Tooltip
    {...props}
    arrow
    classes={{ popper: className }}
    disableInteractive
    sx={{ zIndex: "2" }}
  />
))(({ theme }) => ({
  [`& .${tooltipClasses.arrow}`]: {
    color: "black",
    "&::before": {
      backgroundColor: "black",
      border: "1px solid black",
    },
  },
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: "black",
    color: "white",
    maxWidth: 300,
    width: "100%",
    textTransform: "lowercase",
    textAlign: "center",
    padding: 8,
    fontSize: theme.typography.pxToRem(12),
    border: "1px solid black",
  },
}));

function ToolTip({ title, children, icon }) {
  return (
    <>
      {children ? (
        <SimpleTooltip title={title} arrow TransitionComponent={Zoom}>
          {children}
        </SimpleTooltip>
      ) : (
        <StyledTooltip title={title} arrow TransitionComponent={Zoom}>
          <IconButton
            sx={{
              padding: "0px",
              paddingBottom: "1px",
              marginLeft: "5px",
            }}
          >
            {icon ? icon : <ToolTipIcon className="ms-0" />}
          </IconButton>
        </StyledTooltip>
      )}
    </>
  );
}

export default React.memo(ToolTip);
