import React, { useEffect, useMemo, useState } from "react";
import "../../css/ViewList.scss";
import KEY from "../../Assets/keyword-subfiles/key.png";
import KEYIMG from "../../Assets/keyword-subfiles/KeyImg.png";
import exportFileIcon from "../../Assets/rank-tracking-assets/export-file.svg";
import deleteIcon from "../../Assets/rank-tracking-assets/delete.svg";
import { ReactComponent as UnsortIcon } from "../../Assets/rank-tracking-assets/unshort.svg";

import {
  DELETE_LIST,
  DELETE_VIEWLISTKEYWORD,
  GET_allList,
} from "../../../services/constants";
import { useSelector } from "react-redux";
import axios from "axios";
import { Delete, SelectAll } from "@mui/icons-material";
import AreYousure from "../../share/card-layout/AreYousure";
import { ToastContainer, toast } from "react-toastify";
import Toast from "../../share/components/Toast";
import { Link } from "react-router-dom";
import ToolTip from "../../share/components/tooltip";
import AlertPopUp from "../../share/components/alert.component";
import { DottLoader } from "../../share/loader";

import backIcon from "../../Assets/common/back.svg";
import { FiKey } from "react-icons/fi";
import { CiCircleList } from "react-icons/ci";
import { CSVLink } from "react-csv";
import { dynamicSort } from "../../../helperJs/helper";
import { useSearchParams } from "react-router-dom";
import { ZustandStore } from "../../../store/zustand-store";
import useKeywordTags from "../../share/components/keyword-tag/getkeywordlist";

export const ViewList = () => {
  const { tag_keywords } = ZustandStore((state) => state);
  // useState
  const [searchParams] = useSearchParams();
  const list_type = searchParams.get("list_type");
  const [AllLists, setAllLists] = useState([]);
  const [Namae, setNamae] = useState("");
  const [selectedKeywords, setselectedKeywords] = useState([]);
  const [SingleListView, setSingleListView] = useState(false);
  const [loading, setloading] = useState(false);
  const [selectAll, setSelectAll] = useState(false);
  const [tableData, setTableData] = useState([]);
  const [direction, setDirection] = useState(false);
  const [Alert, setAlert] = useState({
    type: "",
    message: "",
    show: false,
  });

  // MODAL MUI FOR DELETE
  const [open, setOpen] = useState(false);
  const handleOpen = (name) => {
    setNamae(name);
    setOpen(true);
  };

  useEffect(() => {
    const single_list = AllLists?.filter((name) => Namae === name.list_name);
    setTableData(single_list);
    // if(single_list)
  }, [Namae, AllLists]);

  // UseSelector
  const EMAIL = useSelector((state) => state?.email);

  useEffect(() => {
    if (selectAll) {
      const array = single_list?.[0]?.keyword_list?.map((keyword) => {
        return keyword.keyword;
      });
      setselectedKeywords(array);
    } else {
      setselectedKeywords([]);
    }
  }, [selectAll]);

  // Gte List
  const handleFetchListData = () => {
    setloading(true);
    const path = "";
    axios.get(GET_allList(EMAIL)).then(({ data }) => {
      const list = data?.filter((item) => {
        return item.list_type === list_type;
      });
      setAllLists(list);
      setTimeout(() => setloading(false), 1000);
    });
  };
  useEffect(() => {
    handleFetchListData();
  }, [EMAIL, list_type]);

  const HandleDeleteList = (RES) => {
    if (RES) {
      axios.delete(DELETE_LIST(EMAIL, Namae, list_type)).then((res) => {
        if (res?.data?.msg === "List Deleted Successfully") {
          setAlert({
            type: "success",
            message: "Success",
            show: true,
          });
          setOpen(false);
          handleFetchListData();
        }
      });
    } else {
      setOpen(false);
    }
  };

  // Table
  const single_list = AllLists?.filter((name) => Namae === name.list_name);

  const handleCheckboxChange = (keyword, res) => {
    setselectedKeywords((prevSelectedKeywords) => {
      if (prevSelectedKeywords.includes(keyword)) {
        const updatedKeywords = prevSelectedKeywords.filter(
          (selectedKeyword) => selectedKeyword !== keyword
        );
        return updatedKeywords;
      } else {
        return [...prevSelectedKeywords, keyword];
      }
    });
  };

  const handleSingleShow = (res) => {
    if (res?.keyword_list?.length === 0) {
      setAlert({
        type: "error",
        message: "List has no keywords",
        show: true,
      });
      return;
    }
    setNamae(res.list_name);
    // setSingleData([res])
    setSingleListView(true);
  };

  const handleDeleteKeyWord = () => {
    const encodedArray = encodeURIComponent(JSON.stringify(selectedKeywords));

    axios
      .delete(DELETE_VIEWLISTKEYWORD(EMAIL, Namae, encodedArray, list_type))
      .then(({ data }) => {
        if (data?.msg === "Keyword Deleted Successfully") {
          setAlert({
            type: "success",
            message: "Deleted Successfully",
            show: true,
          });
          handleFetchListData();
          setselectedKeywords([]);
          setSelectAll(false);
        }
      });
  };

  function getUniqueKeys(arrayOfObjects) {
    const keysSet = new Set();
    arrayOfObjects?.forEach((obj) => {
      Object.keys(obj).forEach((key) => {
        keysSet.add(key);
      });
    });
    return Array.from(keysSet);
  }

  const tableHeader =
    tableData?.[0]?.keyword_list && getUniqueKeys(tableData?.[0]?.keyword_list);

  const loadCSVData2 = () => {
    if (selectedKeywords?.length === 0) return [];
    const data = single_list?.[0]?.keyword_list?.filter((keyword) => {
      return selectedKeywords?.includes(keyword.keyword);
    });
    return data;
  };

  const loadCSVData = () => {
    if (selectedKeywords?.length === 0) return [];
    if (tableHeader?.length > 2) {
      return loadCSVData2();
    }
    const CSV_Array = [];
    selectedKeywords?.map((res) => {
      CSV_Array.push({
        keyword: res,
      });
    });
    return CSV_Array;
  };

  const headerInfo = {
    keyword: "Words that people type on Google",
    volume: "The number of searches this particular keyword has during a month",
    cpc: "Cost per Click: Average cost per click if you want to pay google to be seen as an ad. if someone is paying a high CPC the keyword is usually a more valuable.",
    difficulty:
      "SEO Difficulty: Estimated competition in organic search, the higher the number the more competitive",
    position: "The Position this keyword is ranked in Google search",
  };

  const handleSorting = (field) => {
    const data = tableData?.[0]?.keyword_list;
    // Ascending Order
    const sortedByAsc = dynamicSort([...data], field, "asc");
    // Descending Order
    const sortedByDesc = dynamicSort([...data], field, "desc");
    setDirection((pre) => !pre);
    const sorted_data = [
      {
        list_name: tableData?.[0]?.list_name,
        keyword_list: direction ? sortedByAsc : sortedByDesc,
      },
    ];
    setTableData(sorted_data);
  };

  // const config = useMemo(() => {
  //   return {
  //     email,
  //     proj_name: websiteurl,
  //     tag_type: "rank",
  //     location_name: currentlocation,
  //   };
  // }, [email, websiteurl, currentlocation]);

  // const { loaded, error, data } = useKeywordTags(config);

  return (
    <div className="Domain_view">
      <ToastContainer />
      <AlertPopUp config={Alert} handleClose={setAlert} />
      {open && (
        <AreYousure
          callback={HandleDeleteList}
          title={"You will not able to recover this List !"}
        />
      )}
      {!SingleListView && (
        <div className="mt-3">
          <Link to={-1}>
            {" "}
            <button className="icon-btn large-btn">
              <img src={backIcon} alt="" />
            </button>
          </Link>
          <div className="row viewList ">
            {loading ? (
              <DottLoader />
            ) : AllLists.length > 0 ? (
              AllLists?.map((val, index) => {
                return (
                  <div
                    className="col-md-4 col-lg-4 col-12"
                    key={`alllist-${index}`}
                  >
                    <div className="ViewListBox ">
                      <div
                        className="d-flex justify-content-between point"
                        onClick={() => handleSingleShow(val)}
                      >
                        <div className="key-name font-bold ">
                          {val.list_name}{" "}
                          <p className="heading-small mb-0 font-medium"></p>
                        </div>
                        <div className="keyIcon">
                          <CiCircleList className="new-list-icon" />
                        </div>
                      </div>
                      <hr />
                      <div className="d-flex justify-content-between">
                        <div className="d-flex">
                          <div className="keyIconSub">
                            <FiKey
                              style={{
                                color: "#365486",
                                width: "25px",
                                height: "25px",
                              }}
                            />
                          </div>
                          <div className="descript ms-3">
                            Total Keywords :{" "}
                            <strong>{val.keyword_list.length}</strong>
                          </div>
                        </div>
                        <div className="deleteBt">
                          <Delete
                            style={{ cursor: "pointer", color: "#365486" }}
                            onClick={() => {
                              handleOpen(val.list_name);
                            }}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                );
              })
            ) : (
              <>
                <div className="EmptyBox">
                  <h3 className="text-danger">No List Found</h3>
                </div>
              </>
            )}
          </div>
        </div>
      )}
      {SingleListView && (
        <div className="ViewSingleList mt-3">
          <div className="d-flex justify-content-between mt-2">
            <div className="d-flex align-items-center">
              <button
                className="icon-btn large-btn"
                onClick={() => {
                  setSingleListView(false);
                  setSelectAll(false);
                  setselectedKeywords([]);
                }}
              >
                <img src={backIcon} alt="" />
              </button>
              <h2 className="ms-5 mb-0" style={{ textTransform: "capitalize" }}>
                {Namae}
              </h2>
            </div>
            <div className="d-flex align-items-center">
              <button
                className="icon-btn ms-3 large-btn"
                title="Delete Keyboard"
                onClick={() => handleDeleteKeyWord()}
                disabled={selectedKeywords?.length === 0}
              >
                <img src={deleteIcon} alt="export-file" />
              </button>
              <CSVLink
                data={loadCSVData()}
                filename={`${single_list?.[0]?.list_name}.csv`}
              >
                <button
                  className="icon-btn ms-3 large-btn"
                  title="Export File"
                  disabled={selectedKeywords?.length === 0}
                >
                  <img src={exportFileIcon} alt="export-file" />
                </button>
              </CSVLink>
            </div>
          </div>

          {single_list[0]?.keyword_list?.length === 0 ? (
            <div className="no-data">
              <p>No Data</p>
            </div>
          ) : (
            <div className="custom-table-container mt-4">
              <table className="">
                <thead>
                  <tr className="table-sticky">
                    <th
                      scope="col"
                      className="check-box-input"
                      onClick={() => setSelectAll(!selectAll)}
                    >
                      <input
                        type="checkbox"
                        checked={
                          single_list[0]?.keyword_list?.length ===
                          selectedKeywords?.length
                        }
                        readOnly
                      />
                    </th>
                    {tableHeader?.map((header) => (
                      <th className="col" key={header}>
                        <div
                          className={
                            header === "keyword"
                              ? "d-flex"
                              : "d-flex justify-content-center"
                          }
                        >
                          <div
                            className="d-flex align-items-center point"
                            onClick={() => handleSorting(header)}
                          >
                            {header.toUpperCase()}
                            <span className="ms-2">
                              <UnsortIcon />
                            </span>
                          </div>
                          <ToolTip title={`${headerInfo?.[header]}`} />
                        </div>
                      </th>
                    ))}
                  </tr>
                </thead>
                <tbody>
                  {loading ? (
                    <DottLoader />
                  ) : tableData?.length === 0 ? (
                    <tr>
                      <td colSpan={6}>No data found !</td>
                    </tr>
                  ) : (
                    tableData[0]?.keyword_list?.map((res, index) => {
                      return (
                        <tr key={`table-row-${index}-${res.keyword}`}>
                          <td
                            className="check-box-input"
                            onClick={() => {
                              handleCheckboxChange(res.keyword, res);
                            }}
                          >
                            <input
                              type="checkbox"
                              checked={selectedKeywords?.includes(res.keyword)}
                              readOnly
                            />
                          </td>
                          {tableHeader.map((header, index) => (
                            <td
                              key={`${res[header]}-table-row-${index}`}
                              className={
                                header === "keyword" ? "" : "text-center"
                              }
                            >
                              {header === "cpc"
                                ? res[header]
                                  ? `$${res[header]}`
                                  : "$0"
                                : res[header] || 0}
                            </td>
                          ))}
                        </tr>
                      );
                    })
                  )}
                </tbody>
              </table>
            </div>
          )}
        </div>
      )}
    </div>
  );
};
