import axios from "axios";
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  LATEST_DATA,
  OLD_LATEST_DATA,
  USERPROJECT_GET,
} from "../services/constants";
import { makeUnique } from "../helperJs/helper";
import { ZustandStore } from "../store/zustand-store";

const GetGuestData = ({ email, websiteurl }) => {
  const dispatch = useDispatch();
  const {
    keyword_old_data,
    keyword_latest_data,
    keyword_loc_data,
    all_loc_data,
  } = ZustandStore((state) => state);
  const loading = useSelector((state) => state.loading);
  // user projects
  const userallprojectdetails = useSelector(
    (state) => state.userallprojectdetails
  );
  const userselectedprojectallid = useSelector(
    (state) => state.userselectedprojectallid
  );

  // user select project location
  const currentlocationcode = useSelector((state) => state.currentlocationcode);
  const currentlocation = useSelector((state) => state.currentlocation);

  // user select domain, device type, date for old data
  const deviceType = useSelector((state) => state.devicetype);
  const customDate = useSelector((state) => state.selectcustomdate);

  const callLatestDataApi = async (latestData) => {
    try {
      const response = await axios.get(LATEST_DATA(latestData));
      return { location: latestData.location_name, data: response.data };
    } catch (error) {
      console.error(
        `Error fetching data for ${latestData.location_name}:`,
        error
      );
      return { location: latestData.location_name, error: true };
    }
  };

  const callOldDataApi = async (latestData) => {
    try {
      const response = await axios.get(OLD_LATEST_DATA(latestData));
      return { location: latestData.location_name, data: response.data };
    } catch (error) {
      console.error(
        `Error fetching data for ${latestData.location_name}:`,
        error
      );
      return { location: latestData.location_name, error: true };
    }
  };

  const userOldDataUpdate = (data, filterDeviceType, index) => {
    const filterDeviceTypeData = filterDeviceType?.filter((loc) => {
      return loc.location_name === data?.location;
    });

    let oldDataCalculate = {
      INDEX: index,
      LOCATION: data?.location,
      PREVIOUSALLOLDDATA: [],
      ALLOLDKEYWORDDATA: [],
      OLDKEYWORDDATA: [],
    };

    const old_data = data?.data;
    if (old_data?.length !== 0) {
      oldDataCalculate.PREVIOUSALLOLDDATA = old_data?.map((obj) => {
        const keywordSet = new Set();
        const updatedObjData = obj.data.filter((item) => {
          if (!keywordSet.has(item.keyword)) {
            keywordSet.add(item.keyword);
            return true;
          }
          return false;
        });
        return { ...obj, data: updatedObjData };
      });

      oldDataCalculate?.PREVIOUSALLOLDDATA?.map((upRes, key) => {
        const UserAllKeywordResult = [];
        const UserAllPendingResult = [];
        const KeywordData = [];

        filterDeviceTypeData?.forEach((detail) => {
          detail.keyword &&
            detail.keyword.forEach((onlyKeyword) => {
              const foundKeyword = upRes.data.find(
                (rankKeyword) => rankKeyword.keyword === onlyKeyword
              );
              if (foundKeyword) {
                UserAllKeywordResult.push(foundKeyword);
                KeywordData.push(foundKeyword.data[0]);
              } else {
                UserAllPendingResult.push(onlyKeyword);
              }
            });
        });

        oldDataCalculate.ALLOLDKEYWORDDATA.push({
          data: KeywordData,
          date: upRes.date,
          month: upRes.month,
          year: upRes.year,
        });
      });

      oldDataCalculate.OLDKEYWORDDATA =
        oldDataCalculate.ALLOLDKEYWORDDATA[
          oldDataCalculate.ALLOLDKEYWORDDATA?.length - 1
        ]?.data;

      return oldDataCalculate;
    } else {
      return oldDataCalculate;
    }
  };

  const userLatestDataUpdate = (data, filterDeviceType, index) => {
    const filterDeviceTypeData = filterDeviceType?.filter((loc) => {
      return loc.location_name === data?.location;
    });
    let latestDataCalculate = {
      INDEX: index,
      LOCATION: data?.location,
      USERCOMPATITORS: [],
      SELECTEDPROJECTNEWDATA: [],
      KEYWORDDATA: [],
      USERALLKEYWORDRESULT: [],
      USERALLPENDINGRESULT: [],
    };

    const latest_data = data?.data;
    latestDataCalculate.USERCOMPATITORS = data?.data[0]?.competitors || [];
    latestDataCalculate.SELECTEDPROJECTNEWDATA = data?.data;
    const selectProjectAllKeyword_Data = data?.data[0]?.data;

    if (latest_data.length !== 0) {
      filterDeviceTypeData &&
        filterDeviceTypeData.forEach((detail) => {
          detail.keyword &&
            detail.keyword.forEach((onlyKeyword) => {
              const foundKeyword = selectProjectAllKeyword_Data.find(
                (rankKeyword) => rankKeyword.keyword === onlyKeyword
              );
              if (foundKeyword) {
                foundKeyword["location"] = detail?.location_name;
                latestDataCalculate.USERALLKEYWORDRESULT.push(foundKeyword);
                latestDataCalculate.KEYWORDDATA.push(foundKeyword.data[0]);
              } else {
                latestDataCalculate.USERALLPENDINGRESULT.push(onlyKeyword);
              }
            });
        });
    } else {
      latestDataCalculate.USERALLPENDINGRESULT =
        filterDeviceTypeData[0]?.keyword || [];
    }
    return latestDataCalculate;
  };

  const showLoaderWithDelay = () => {
    setTimeout(() => {
      dispatch({ type: "SHOWLOADER", payload: false });
    }, 1000);
  };

  const userDataUpdate = async (userAllProjectDetails) => {
    // if (!loading) return;
    dispatch({ type: "KEYWORDDATA", payload: [] });
    dispatch({ type: "OLDKEYWORDDATA", payload: [] });
    dispatch({ type: "USERALLKEYWORDRESULT", payload: [] });
    dispatch({ type: "RANKMOVEDUP", payload: null });
    dispatch({ type: "RANKMOVEDDOWN", payload: null });
    dispatch({ type: "SHOWLOADER", payload: true });

    const userAllProject = userAllProjectDetails
      .map((res) => res.Project)
      .flat();

    //----------user all project name ----------//
    const filteredPrNameUrl = Array.from(
      new Set(userAllProject.map((prnameurl) => prnameurl.weburl))
    );
    if (filteredPrNameUrl.length !== 0) {
      dispatch({ type: "USERALLPROJECTNAME", payload: filteredPrNameUrl });
    }

    //----------------------user current selected project id  -----------//
    const selectedProject = userAllProjectDetails.find(
      (res) => res.Project[0].weburl === websiteurl
    );

    if (selectedProject) {
      const projectId = Number(selectedProject.id);
      dispatch({ type: "USERSELECTEDPROJECTALLID", payload: [projectId] });
    }

    if (userAllProject.length !== 0) {
      dispatch({ type: "ISPROJECT", payload: true });
      // --------------------------User project length--------------------------------//
      const uniqueWebURLs = [
        ...new Set(userAllProject.map((item) => item.weburl)),
      ];
      const totalWebURLs = uniqueWebURLs.length;
      dispatch({ type: "USERPROJECTLENGTH", payload: totalWebURLs });

      // --------------------------project keyword length--------------------------------//
      let totalKeywordCount = 0;
      userAllProject?.reduce((acc, item) => {
        const key = `${item.weburl}_${item.deviceType}_${item.location_code}`;
        if (!acc[key]) {
          acc[key] = {
            count: 0,
          };
        }
        const uniqueKeywords = new Set(item.keyword); // Use a Set to remove duplicate keywords
        const keywordCount = uniqueKeywords.size; // Count the unique keywords
        acc[key].count += keywordCount;
        totalKeywordCount += keywordCount;
        return acc;
      }, {});

      dispatch({ type: "USERKEYWORDLENGTH", payload: totalKeywordCount });
      //----------user selected project all details ----------//
      const filteredUrlBasedData = userAllProject.filter(
        (selectedUrl) => selectedUrl.weburl === websiteurl
      );
      dispatch({
        type: "USERCURRENTPROJECTLOCATION",
        payload: filteredUrlBasedData,
      });

      if (currentlocationcode === null && websiteurl !== null) {
        dispatch({
          type: "CURRENTLOCATIONCODE",
          payload: "All Locations",
        });
        dispatch({
          type: "CURRENTLOCATION",
          payload: "All Locations",
        });
      }

      // ----------selected project data by LocationCode ---------- //
      const country_Based_Data = filteredUrlBasedData.filter((countrybased) =>
        currentlocationcode === "All Locations"
          ? countrybased
          : countrybased.location_code === currentlocationcode
      );
      // ---------- End getting selected project data by LocationCode ---------- //

      // ---------- Start getting selected project data by  LocationCode with device type ---------- //
      const filterDeviceTypeData = country_Based_Data.filter(
        (type) => type.deviceType === deviceType
      );

      dispatch({ type: "NOTLOADING" });
      if (currentlocationcode && currentlocation && websiteurl) {
        if (
          currentlocation === keyword_loc_data &&
          keyword_old_data?.length > 0 &&
          keyword_latest_data?.length > 0
        ) {
          ZustandStore.setState({
            keyword_old_data: keyword_old_data,
            keyword_latest_data: keyword_latest_data,
            keyword_loc_data: keyword_loc_data,
          });
          showLoaderWithDelay();
        } else if (
          currentlocation === "All Locations" &&
          all_loc_data?.length === 0
        ) {
          ZustandStore.setState({
            keyword_loc_data: "All Locations",
          });
          const getCountry = filterDeviceTypeData?.map((item) => ({
            location_name: item.location_name,
            location_code: item.location_code,
          }));

          let uniqueCountry = makeUnique(getCountry);

          const allPromises = uniqueCountry.map((location) => {
            const latestData = {
              id: userselectedprojectallid[0], // project data id
              email: email,
              deviceType: deviceType, // default is desktop
              location_code: location?.location_code, // country code
              weburl: websiteurl, // selected domain
              location_name: location?.location_name, // country name
            };

            const oldData = {
              ...latestData,
              days: customDate?.day,
            };

            return Promise.allSettled([
              callLatestDataApi(latestData),
              callOldDataApi(oldData),
            ]);
          });

          const results = await Promise.all(allPromises);

          const successfulLatestData = results
            ?.map((result) => result[0])
            .filter((result) => result.status === "fulfilled")
            .map((result) => result.value);

          const successfulOldData = results
            ?.map((result) => result[1])
            .filter((result) => result.status === "fulfilled")
            .map((result) => result.value);

          if (successfulLatestData) {
            const data = successfulLatestData?.map((loc, index) => {
              return userLatestDataUpdate(
                successfulLatestData?.[index],
                filterDeviceTypeData,
                index
              );
            });

            ZustandStore.setState({
              keyword_latest_data: data,
            });
          }

          if (successfulOldData) {
            const data = successfulOldData?.map((loc, index) => {
              return userOldDataUpdate(
                successfulOldData?.[index],
                filterDeviceTypeData,
                index
              );
            });

            ZustandStore.setState({
              keyword_old_data: data,
            });
          }
          showLoaderWithDelay();
        } else if (all_loc_data?.length > 0) {
          const data = all_loc_data?.[0];
          if (currentlocation === "All Locations") {
            ZustandStore.setState({
              keyword_old_data: data?.keyword_old_data,
              keyword_latest_data: data?.keyword_latest_data,
              keyword_loc_data: currentlocation,
            });
          } else {
            const old_data = data?.keyword_old_data?.filter((item) => {
              return item.LOCATION === currentlocation;
            });
            const new_data = data?.keyword_latest_data?.filter((item) => {
              return item.LOCATION === currentlocation;
            });
            ZustandStore.setState({
              keyword_old_data: old_data,
              keyword_latest_data: new_data,
              keyword_loc_data: currentlocation,
            });
          }
          showLoaderWithDelay();
        } else {
          ZustandStore.setState({
            keyword_loc_data: currentlocation,
            keyword_old_data: [],
            keyword_latest_data: [],
          });
          const latestData = {
            id: userselectedprojectallid[0], // project data id
            email: email,
            deviceType: deviceType, // default is desktop
            location_code: currentlocationcode, // country code
            weburl: websiteurl, // selected domain
            location_name: currentlocation,
            days: customDate?.day, // country name
          };
          const [getLatestData, getOldData] = await Promise.all([
            callLatestDataApi(latestData),
            callOldDataApi(latestData),
          ]);

          if (getOldData?.data) {
            const data = userOldDataUpdate(getOldData, filterDeviceTypeData, 0);
            ZustandStore.setState({
              keyword_old_data: [data],
            });
          }
          if (getLatestData?.data) {
            const data = userLatestDataUpdate(
              getLatestData,
              filterDeviceTypeData,
              0
            );
            ZustandStore.setState({
              keyword_latest_data: [data],
            });
          }
          showLoaderWithDelay();
        }
      }
    } else {
      dispatch({ type: "ISPROJECT", payload: false });
      dispatch({ type: "WEBSITEURL", payload: null });
      dispatch({ type: "NOTLOADING" });
    }
  };

  const getUserProject = () => {
    if (email == null) return;
    axios
      .get(USERPROJECT_GET(email))
      .then((response) => {
        dispatch({
          type: "USERALLPROJECTDETAILS",
          payload: response.data?.data,
        });
        userDataUpdate(response.data?.data);
      })
      .catch((error) => console.log("error while getting data", error));
  };

  useEffect(() => {
    if (email && websiteurl) {
      dispatch({
        type: "WEBSITEURL",
        payload: websiteurl,
      });
      userallprojectdetails?.length > 0
        ? userDataUpdate(userallprojectdetails)
        : getUserProject();
    } else {
    }
  }, [
    email,
    websiteurl,
    deviceType,
    currentlocationcode,
    customDate,
    loading,
    all_loc_data,
  ]);

  return null;
};

export default GetGuestData;
