import axios from "axios";
import React, { useState, useEffect, useMemo } from "react";
import { Admin_Keyword_Get } from "../../services/constants";
import { DottLoader } from "../../Mycomponent/share/loader";
import country from "../../Mycomponent/dataFiles/countryList";
import CustomTable from "../../Mycomponent/share/customTable";
import { Box, Button } from "@mui/material";
import { CSVLink } from "react-csv";

const Keyword = () => {
  const [keyword, setKeyword] = useState([]);
  const [loading, setLoading] = useState(true);
  const [page, setPage] = React.useState(1);

  useEffect(() => {
    axios
      .get(Admin_Keyword_Get())
      .then((response) => {
        const data = response.data;
        setKeyword(data);
      })
      .catch((error) => {
        console.error("Error fetching keyword data:", error);
        setLoading(false);
      });
  }, []);

  const filterKeywordArray = useMemo(() => {
    if (keyword?.length === 0) return [];
    const newArray = keyword?.map((item1) => {
      const { location_code, device, keyword } = item1;
      const matchingObject = country.find(
        (obj) => obj.location_code == location_code
      );

      if (matchingObject) {
        return {
          device,
          keyword,
          location_code,
          location_name: matchingObject.location_name,
          location_flag: matchingObject.location_flag,
        };
      }
      return null; // Handle cases where no match is found
    });

    const filteredData = newArray.filter(
      (obj) => obj && !Object.values(obj).every((val) => val === undefined)
    );
    setLoading(false);
    return filteredData;
  }, [keyword]);

  function createData(
    id,
    keywords,
    device,
    location_name,
    location_code,
    location_flag
  ) {
    return {
      id,
      keywords,
      device,
      location_name,
      location_code,
      location_flag,
    };
  }

  const tableHeader = [
    {
      label: "KEYWORDS",
      key: "keywords",
      minWidth: 120,
      align: "left",
      width: "25%",
    },
    {
      label: "DEVICE",
      key: "device",
      minWidth: 120,
      width: "25%",
      align: "left",
    },
    {
      label: "LOCATION NAME",
      key: "location_name",
      minWidth: 120,
      align: "center",
      width: "25%",
    },
  ];

  const rows = filterKeywordArray?.map((item, index) => {
    return createData(
      index,
      item?.keyword,
      item?.device,
      item?.location_name,
      item?.location_code,
      item?.location_flag
    );
  });

  const loadCSVData = () => {
    if (filterKeywordArray?.length === 0) return [];
    return filterKeywordArray?.map((item) => {
      const obj = {
        keyword: item?.keyword,
        device: item?.device,
        location_name: item?.location_name,
        location_code: item?.location_code,
        location_flag: item?.location_flag,
      };
      return obj;
    });
  };

  if (loading) {
    return (
      <>
        <h1 className="table_title mb-4 font-bold heading-large">Keywords</h1>
        <DottLoader />
      </>
    );
  }

  return (
    <>
      <CustomTable
        showSearchBar={true}
        tableHeader={tableHeader}
        rows={rows}
        page={page}
        setPage={setPage}
        tableTitle="Keywords"
        downloadCSVFile={
          <CSVLink data={loadCSVData()} filename={"keyword.csv"}>
            <Button className="btn_1 ms-2">Download CSV</Button>
          </CSVLink>
        }
      />
    </>
  );
};

export default Keyword;
