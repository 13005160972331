import { useEffect, useCallback, useMemo } from "react";
import axios from "axios";
import { GET_KEYWORD_TAG } from "../../../../services/constants";
import { ZustandStore } from "../../../../store/zustand-store";
import useDebouncedFunction from "../../../../helperJs/debounce";

const useKeywordTags = (config, refetch) => {
  const { keyword_tag_data } = ZustandStore((state) => state);
  const { loaded, error, data } = keyword_tag_data;

  const getKeywordTag = useCallback(() => {
    axios
      .get(GET_KEYWORD_TAG(config))
      .then((response) => {
        ZustandStore.setState({
          tag_keywords: [],
          filter_tag: [],
          keyword_tag_data: {
            loaded: true,
            error: false,
            data: response.data,
          },
        });
      })
      .catch(() => {
        ZustandStore.setState({
          tag_keywords: [],
          filter_tag: [],
          keyword_tag_data: {
            loaded: true,
            error: true,
            data: [],
          },
        });
      });
  }, [config]);

  const debouncedGetKeywordTag = useDebouncedFunction(getKeywordTag, 100);

  useEffect(() => {
    debouncedGetKeywordTag();
  }, [refetch]);

  return useMemo(() => ({ loaded, error, data }), [loaded, error, data]);
};

export default useKeywordTags;
