import React from "react";
import ToolTip from "../components/tooltip";
import { formatNumberWithSymbols } from "../../../helperJs/helper";

function CardOverviewValue({ data }) {
  return (
    <div
      className="site-card text-start fst-normal w-100"
      style={{ minHeight: "0", backgroundColor: data?.bg }}
    >
      <div className="w-100" style={{ margin: "0px 18px" }}>
        <h6 className="mb-0 text-white text-uppercase">
          {data?.title}
          <ToolTip title={data?.toolkit} />
        </h6>
        <div className="">
          <img
            src={data?.Src}
            alt={data?.title}
            className="object-fit-contain my-2"
            style={{ width: "30px", height: "30px" }}
          />
          <h1 className="text-white mb-0">
            {formatNumberWithSymbols(data?.rank || 0)}
          </h1>
        </div>
      </div>
    </div>
  );
}

export default React.memo(CardOverviewValue);
