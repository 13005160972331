import React, { useState, useEffect, useRef, useMemo } from "react";
import GoogleAuth from "../../confrigration/googleAuth";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useLocation, Link } from "react-router-dom";
import "../css/index.scss";
import "../css/rankTracking.css";
import "../css/loader.css";
import axios from "axios";
import {
  GET_Google_Console_Data,
  GET_Google_Console_Site,
  POST_Google_Console_Data,
} from "../../services/constants";
import Dropdown from "../share/components/dropdown";
import { formatDate } from "../share/upDater/constant";
import SingleLineChart from "../share/charts/line-chart";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import { useMediaQuery } from "@mui/material";
import { LiaDesktopSolid } from "react-icons/lia";
import { BsPhone } from "react-icons/bs";
import { FaComputer } from "react-icons/fa6";

import PopOver from "../share/components/popover.component";
import { Typography } from "@mui/material";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import ListSubheader from "@mui/material/ListSubheader";
import List from "@mui/material/List";
import ListItemText from "@mui/material/ListItemText";
import Divider from "@mui/material/Divider";
import IconButton from "@mui/material/IconButton";
import ListItemButton from "@mui/material/ListItemButton";
import ToolTip from "../share/components/tooltip";
import CloseIcon from "@mui/icons-material/Close";
import ArrowPagination from "../share/components/arrow-pagination.component";
import googleConsoleImg from "../Assets/home/google-search-console.png";
import greenTick from "../Assets/google-analytics/checked.png";
import backIcon from "../Assets/common/back.svg";
import crossIcon from "../Assets/rank-tracking-assets/cross.svg";
import { ZustandStore } from "../../store/zustand-store";

const GoogleData = () => {
  const email = useSelector((state) => state.email);
  const websiteurl = useSelector((state) => state.websiteurl);
  const userAnalyticData = useSelector((state) => state.userAnalyticData);
  const userAnalyticLogin = useSelector((state) => state.userAnalyticLogin);
  const { property_name } = ZustandStore((state) => state);

  const [Count, setCount] = useState(0);
  const GraphRef = useRef();
  const [value, setValue] = React.useState(0);
  const [dropDownOption, setDropdownOption] = React.useState(0);
  const [Loader, setLoader] = React.useState(false);
  const [consoleStatus, setConsoleStatus] = useState(false);
  const [loadMore, setLoadMore] = useState(false);
  const [consoleLoader, setConsoleLoader] = React.useState(false);
  const [consoleTableMonth, setConsoleTableMonth] = useState("");
  const [consoleTableOption, setConsoleTableOption] = useState("queries");
  const [tablePage, setTablePage] = useState(1);
  const [consoleData, setConsoleData] = useState([]);
  const [siteData, setSiteData] = useState([]);
  const [consoleSite, setConsoleSite] = useState("");

  const MOBILE = useMediaQuery("(max-width:768px)");

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const getConsoleDataList = () => {
    if (email === null) return;
    axios
      .get(GET_Google_Console_Site(email, websiteurl))
      .then((response) => {
        if (response?.data?.length > 0) {
          setConsoleData(response.data);
          setConsoleStatus(false);
        }
      })
      .catch((error) => console.log(error));
  };

  useEffect(() => {
    if (consoleData?.length === 0) {
      getConsoleDataList(email);
      const interval = 30000; // 60000 milliseconds = 1 minute
      const intervalId = setInterval(() => {
        getConsoleDataList();
      }, interval);
      return () => clearInterval(intervalId);
    }
  }, [email, consoleData]);

  useEffect(() => {
    setConsoleLoader(true);
    const intervalId = setTimeout(() => {
      setConsoleLoader(false);
    }, 1000);
    return () => clearInterval(intervalId);
  }, [loadMore, consoleTableMonth, consoleTableOption]);

  useEffect(() => {
    setLoader(true);
    const intervalId = setTimeout(() => {
      setLoader(false);
    }, 200);
    return () => clearInterval(intervalId);
  }, [userAnalyticLogin]);

  const selectDay = [
    { title: "week", day: 7, meta_des: "1", value: 0 },
    { title: "days", day: 30, meta_des: "30", value: 1 },
    { title: "month", day: 90, meta_des: "3", value: 2 },
    { title: "month", day: 180, meta_des: "6", value: 3 },
    { title: "year", day: 365, meta_des: "1", value: 4 },
    { title: "Data", day: 0, meta_des: "All", value: 5 },
  ];

  const default_date = selectDay[dropDownOption];
  const title =
    default_date?.day === 0
      ? default_date?.meta_des + " " + default_date?.title
      : "Last " + default_date?.meta_des + " " + default_date?.title;
  const show_data_upto =
    default_date?.day === 0 ? userAnalyticData?.length : default_date?.day;

  const handleDateUpdate = (e) => {
    setDropdownOption(e.value);
  };

  const chart_label = userAnalyticData?.map((item) => {
    return formatDate(item.date);
  });

  const arrayModification = (arr) => {
    const reversedArray = [...arr].reverse();
    const slicedArray = reversedArray.slice(0, show_data_upto);
    const finalArray = slicedArray.reverse();
    return finalArray;
  };

  const chartLabel =
    chart_label?.length > 0 ? arrayModification(chart_label) : [];

  const dataLoadedFrom =
    chartLabel?.length > 0
      ? `${chartLabel?.[0]} - ${chartLabel?.[chartLabel?.length - 1]}`
      : false;

  const chart_mobile_dataset = userAnalyticData?.map((item) => {
    return item.mobile;
  });
  const chart_tablet_dataset = userAnalyticData?.map((item) => {
    return item.tablet;
  });
  const chart_default_dataset = userAnalyticData?.map((item) => {
    return item.total_data;
  });
  const chart_desktop_dataset = userAnalyticData?.map((item) => {
    return item.desktop;
  });

  const trickPointerRadius = () => {
    if (show_data_upto >= 90 || show_data_upto === 0) {
      return 0;
    } else {
      return 5;
    }
  };

  const trickPointerHoverRadius = () => {
    if (show_data_upto >= 90 || show_data_upto === 0) {
      return 3;
    } else {
      return 5;
    }
  };

  const handleSampleData = (array) => {
    return array?.length > 0 ? arrayModification(array) : [];
  };

  const COLOR = useMemo(() => {
    if (document.getElementById("single-line-chart") !== null) {
      var ctx = document.getElementById("single-line-chart").getContext("2d");
      var gradient = ctx.createLinearGradient(100, 80, 100, 200);
      gradient.addColorStop(0, "rgba(120, 66, 232, 0.7)");
      gradient.addColorStop(1, "rgba(250, 250, 250, 0.99)");
      return gradient;
    }
  }, [Count]);

  useEffect(() => {
    setTimeout(() => {
      setCount(Count + 1);
    }, 1000);
  }, []);

  const selected_chart_dataset = [
    {
      label: "Users",
      data: handleSampleData(chart_default_dataset),
      borderColor: "#365486",
      fill: "start",
      backgroundColor: COLOR,
      borderWidth: 1,
      tension: 0.1,
      yAxisID: "y",
      pointRadius: trickPointerRadius(),
      pointHoverRadius: trickPointerHoverRadius(),
      pointBorderColor: "#365486",
      pointBackgroundColor: "#FFFFFF",
    },
    {
      label: "Desktop",
      data: handleSampleData(chart_desktop_dataset),
      fill: "start",
      backgroundColor: COLOR,
      borderColor: "#365486",
      borderWidth: 1,
      tension: 0.1,
      yAxisID: "y",
      pointRadius: trickPointerRadius(),
      pointHoverRadius: trickPointerHoverRadius(),
      pointBackgroundColor: "#FFFFFF",
      pointBorderColor: "#365486",
    },
    {
      label: "Mobile",
      data: handleSampleData(chart_mobile_dataset),
      fill: "start",
      backgroundColor: COLOR,
      borderColor: "#365486",
      borderWidth: 1,
      tension: 0.1,
      pointRadius: trickPointerRadius(),
      yAxisID: "y",
      pointHoverRadius: trickPointerHoverRadius(),
      pointBackgroundColor: "#FFFFFF",
      pointBorderColor: "#365486",
    },
  ];

  const options = {
    maintainAspectRatio: false,
    responsive: true,
    interaction: { mode: "index", intersect: false },
    plugins: {
      legend: {
        position: "top",
        display: false,
      },
      title: {
        display: true,
      },
      axisY: {
        gridDashType: "dash",
      },
      tooltip: {
        enabled: userAnalyticData?.length > 0 ? true : false,
        boxPadding: 5,
        cornerRadius: 10,
        caretSize: 10,
        padding: 10,
        margin: 10,
        boxHeight: 30,
        borderWidth: 4,
        boxWidth: 4,
        titleColor: "black",
        bodyColor: "black",
        bodyFont: {
          size: 16,
        },
        bodySpacing: 10,
        backgroundColor: "#F0F0F0",
        usePointStyle: true,
        background: "linear-gradient(rgb(209,157,32), rgba(0, 0, 255, 0.5)", //
        titleFont: {
          size: 15,
        },
      },
    },
    scales: {
      y: {
        type: "linear",
        display: true,
        position: "left",
        reverse: false,
        border: {
          display: false,
        },
        beginAtZero: true,
        ticks: {
          maxTicksLimit: 8,
          autoSkip: false,
          min: 0,
          max: 500,
          stepSize: 100,
          beginAtZero: true,
        },
        grid: {
          display: true,
          tickBorderDash: [2, 15], // also for the tick, if long enough
          tickLength: 10, // just to see the dotted line
          tickWidth: 2,
        },
      },
      x: {
        ticks: {
          display: true,
          maxTicksLimit: 6,
          min: 5,
          max: 6,
          autoSkip: true,
          beginAtZero: false,
        },
        grid: {
          display: false,
          tickBorderDash: [2, 15], // also for the tick, if long enough
          tickLength: 10, // just to see the dotted line
          tickWidth: 2,
        },
      },
    },
    cubicInterpolationMode: "monotone",
  };

  const chart_data = {
    label: "",
    labels: chartLabel,
    dataSet: [selected_chart_dataset?.[value]],
  };

  const getGoogleConsoleData = async () => {
    axios
      .get(GET_Google_Console_Data(email, websiteurl))
      .then((response) => {
        setSiteData(response.data);
      })
      .catch((error) => console.error(error));
  };

  const postGoogleConsoleData = async () => {
    const config = {
      email,
      refresh_token: siteData[0]?.refresh_token,
      site: consoleSite,
      domain: websiteurl,
    };
    setConsoleStatus(true);
    setSiteData([]);
    axios.post(POST_Google_Console_Data(config)).catch(() => {});
  };

  const handleMonthUpdate = (e) => {
    setConsoleTableMonth(e);
    setTablePage(1);
  };

  const consoleDataOptionUpdate = (value) => {
    if (value === consoleTableOption) return;
    setConsoleTableOption(value);
    setTablePage(1);
    setConsoleTableMonth("");
  };

  const consoleDataOption =
    consoleTableOption === "queries"
      ? consoleData[0]?.query_data
      : consoleData[0]?.page_url;

  const consoleDropMonth = consoleDataOption?.map((item) => {
    return item.month;
  });

  const consoleSelectedMonth =
    consoleTableMonth === "" ? consoleDropMonth?.[0] : consoleTableMonth;

  const consoleTableSelect = consoleDataOption?.filter((data) => {
    return data.month === consoleSelectedMonth;
  });

  const currentPageTableData = consoleTableSelect?.[0]?.data;
  const itemsPerPage = 10;
  const startIndex = (tablePage - 1) * itemsPerPage;
  const endIndex = startIndex + itemsPerPage;
  const totalPages = Math.ceil(currentPageTableData?.length / itemsPerPage);

  const table_console_data = useMemo(() => {
    const array = currentPageTableData?.slice(startIndex, endIndex);
    return array;
  }, [currentPageTableData, startIndex, endIndex]);

  const handlePaginationChange = (value) => {
    setTablePage(value);
  };

  const Sppiner = () => {
    return (
      <Box
        sx={{ display: "flex", minHeight: 350, minWidth: 350 }}
        className="w-100 h-100 cad"
      >
        <div className="spinner-border text-primary" role="status">
          <span className="sr-only"></span>
        </div>
      </Box>
    );
  };

  const con_chart_clicks = consoleData?.[0]?.data?.map((data) => {
    return data.clicks;
  });

  const con_chart_impressions = consoleData?.[0]?.data?.map((data) => {
    return data.impressions;
  });

  const con_chart_label = consoleData?.[0]?.data?.map((data) => {
    return data.date;
  });

  const con_chart_data = {
    label: consoleData?.[0]?.site,
    labels: con_chart_label,
    dataSet: [
      {
        label: "Impressions",
        data: con_chart_impressions,
        borderColor: "#039FFF",
        fill: "start",
        backgroundColor: "transparent",
        yAxisID: "y1",
        borderWidth: 2,
        pointRadius: 0,
        pointHoverRadius: 0,
        pointBorderColor: "#039FFF",
        pointBackgroundColor: "#FFFFFF",
      },
      {
        label: "Clicks",
        data: con_chart_clicks,
        borderColor: "#FFAC30",
        fill: "start",
        borderWidth: 2,
        backgroundColor: "transparent",
        yAxisID: "y",
        pointRadius: 0,
        pointHoverRadius: 0,
        pointBorderColor: "#FFAC30",
        pointBackgroundColor: "#FFFFFF",
      },
    ],
  };

  const con_options = {
    maintainAspectRatio: false,
    responsive: true,
    interaction: { mode: "index", intersect: false },
    plugins: {
      legend: {
        position: "top",
        display: false,
      },
      title: {
        display: true,
      },
      axisY: {
        gridDashType: "dash",
      },
      tooltip: {
        boxPadding: 5,
        cornerRadius: 10,
        caretSize: 10,
        padding: 10,
        margin: 10,
        boxHeight: 30,
        borderWidth: 4,
        boxWidth: 4,
        titleColor: "black",
        bodyColor: "black",
        bodyFont: {
          size: 16,
        },
        bodySpacing: 10,
        backgroundColor: "#F0F0F0",
        usePointStyle: true,
        background: "linear-gradient(rgb(209,157,32), rgba(0, 0, 255, 0.5)", //
        titleFont: {
          weight: "bold",
          size: 15,
        },
      },
    },
    scales: {
      y: {
        type: "linear",
        display: true,
        position: "left",
        reverse: false,
        color: "black",
        border: {
          display: true,
        },
        beginAtZero: true,
        title: {
          display: true,
          text: "Clicks",
          font: {
            size: 14,
            weight: 500,
          },
        },
        ticks: {
          maxTicksLimit: 8,
          autoSkip: true,
          min: 3,
          max: 5,
          stepSize: 1,
          beginAtZero: true,
          size: 16,
          weight: 500,
        },
        grid: {
          display: false,
          tickBorderDash: [2, 15], // also for the tick, if long enough
          tickLength: 10, // just to see the dotted line
          tickWidth: 2,
        },
      },
      y1: {
        type: "linear",
        display: true,
        reverse: false,
        position: "right",
        color: "black",
        border: {
          display: true,
        },
        title: {
          display: true,
          text: "Impressions",
          font: {
            size: 14,
            weight: 500,
          },
        },
        ticks: {
          maxTicksLimit: 8,
          autoSkip: true,
          min: 90,
          max: 100,
          stepSize: 30,
          beginAtZero: true,
          size: 16,
          weight: 500,
        },
        grid: {
          display: true,
          tickBorderDash: [2, 15], // also for the tick, if long enough
          tickLength: 10, // just to see the dotted line
          tickWidth: 2,
        },
      },
      x: {
        ticks: {
          display: true,
          maxTicksLimit: 6,
          autoSkip: true,
          beginAtZero: false,
        },
        grid: {
          display: false,
          tickBorderDash: [2, 15], // also for the tick, if long enough
          tickLength: 10, // just to see the dotted line
          tickWidth: 2,
        },
      },
    },
    // cubicInterpolationMode: "monotone",
  };

  function sumArray(numbers) {
    if (numbers?.length > 0) {
      const sum = numbers?.reduce(
        (accumulator, currentValue) => accumulator + currentValue,
        0
      );
      if (sum >= 1000) {
        return (sum / 1000).toFixed(2) + "k"; // Use toFixed to round to 2 decimal places
      }
      return sum;
    } else {
      return 0;
    }
  }

  return (
    <main className="main-pd rank-tracking-container dashboard-app-container">
      <PopOver anchorEl={siteData?.length > 0}>
        <Box
          sx={{
            bgcolor: "rgba(255, 255, 255, 1)",
            flexDirection: "column",
          }}
          className="popover-cont"
        >
          <Typography
            component="h1"
            className="heading-large font-bold popover-list-header py-2 px-2"
          >
            Select Google Site
            <ToolTip
              title="List of Properties attached with your Google Analyics
                                Account, select property for which you want data to be
                                shown."
            />
            <IconButton
              className="popover-close-icon"
              onClick={() => setSiteData([])}
              sx={{
                "&:hover": {
                  backgroundColor: "rgba(240, 242, 255, 1)",
                },
              }}
            >
              <CloseIcon sx={{ color: "black" }} fontSize="large" />
            </IconButton>
          </Typography>

          <Grid
            container
            spacing={2}
            columns={12}
            className="mb-2"
            sx={{ minWidth: { md: 700 } }}
          >
            <Grid item xs={12} md={12}>
              {siteData?.length === 0 ||
              siteData?.console_data?.length === 0 ? (
                <h1>no data</h1>
              ) : (
                <List
                  component="nav"
                  aria-label="select-google-property"
                  className="overflow-auto p-0 list-border h-100"
                  sx={{ maxHeight: 350 }}
                  subheader={
                    <ListSubheader
                      component="h1"
                      id="select-google-property"
                      className="heading-small font-bold popover-list-header mb-0"
                    >
                      Select Site
                    </ListSubheader>
                  }
                >
                  {siteData[0]?.console_data?.map((item, index) => {
                    return (
                      <React.Fragment key={`google-console-list${index}`}>
                        <ListItemButton
                          selected={item === consoleSite}
                          sx={{
                            "&:hover": {
                              backgroundColor: "rgba(240, 242, 255, 1)",
                            },
                          }}
                          onClick={() => setConsoleSite(item)}
                        >
                          <ListItemText
                            primary={item}
                            sx={{
                              "& .MuiListItemText-primary": {
                                fontSize: "1.2em",
                                color: "#365486",
                                fontWeight: "600",
                              },
                              "& .MuiListItemText-secondary": {
                                fontSize: "1em",
                                color: "black",
                              },
                            }}
                          />
                        </ListItemButton>
                        <Divider />
                      </React.Fragment>
                    );
                  })}
                </List>
              )}
            </Grid>
          </Grid>
          <button className="btn_new_bg" onClick={postGoogleConsoleData}>
            Submit
          </button>
        </Box>
      </PopOver>
      <div>
        <Link to="/dashboard" className="">
          <button className="icon-btn large-btn">
            <img src={backIcon} alt="" />
          </button>
        </Link>
      </div>
      <div className="Google_any_data">
        <div
          className="cmd-b  mb-4"
          style={{
            display: "flex",
            flexDirection: "column",
            borderRadius: "10px",
            backgroundColor: "white",
          }}
        >
          <div className="cmd analy-con-div w-100">
            <div className="row align-items-center w-100">
              <div className="col-auto">
                <h5 className="heading-large mb-0">Organic Traffic</h5>
              </div>
              <div className="col-auto ms-auto">
                <div className="analy-gol d-flex align-items-center">
                  <div className="me-3">
                    <span className="heading-medium font-light-bold">
                      Domain:{" "}
                    </span>
                    <span className="font-regular heading-medium">
                      {property_name?.[0]?.display_name}{" "}
                    </span>
                  </div>
                  <GoogleAuth analyticShow={true} />
                </div>
              </div>
            </div>
          </div>
          {Loader ? (
            <div
              style={{ display: "flex", minHeight: "50px" }}
              className="w-100 h-100 cad"
            >
              <div
                className="spinner-border"
                style={{ color: "#365486" }}
                role="status"
              >
                <span className="sr-only"></span>
              </div>
            </div>
          ) : userAnalyticData?.length === 0 ? (
            userAnalyticLogin ? (
              <div className="w-100 ">
                <div className="w-100">
                  <div
                    style={{
                      position: "relative",
                    }}
                    className="w-100"
                  >
                    <div
                      className="w-100"
                      style={{ maxHeight: "500px", minHeight: "200px" }}
                    ></div>
                    <div
                      className="w-50"
                      style={{ position: "absolute", top: "50%", left: "35%" }}
                    >
                      <span className="heading-medium font-bold">
                        We Are Fetching Your Google Analytics Data Please
                        Wait...
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            ) : null
          ) : (
            <div className="w-100">
              <div className="row w-100 d-flex mt-2 gy-2 align-items-center">
                <div className="col-lg-auto col-md-auto col-12">
                  <Tabs
                    value={value}
                    onChange={handleChange}
                    aria-label="icon tabs example"
                    className="tab-icons-cont"
                    sx={{
                      "& .MuiTabs-flexContainer": {
                        backgroundColor: "rgba(120, 66, 232, 1)",
                        padding: ".6em .8em",
                        borderRadius: "50px",
                        overflow: "hidden",
                      },
                      "& .MuiButtonBase-root": {
                        backgroundColor: "rgba(120, 66, 232, 1)",
                        color: "white",
                      },
                      "& .MuiTabs-indicator": {
                        backgroundColor: "transparent",
                        backgroundColor: "#e3e7ff",
                        height: 0,
                      },
                      "& .MuiTabs-scroller": {
                        borderRadius: "10px",
                      },
                      "& .MuiTab-root.Mui-selected": {
                        color: "rgba(120, 66, 232, 1)",
                        backgroundColor: "white",
                        borderRadius: "50px",
                      },
                    }}
                  >
                    <Tab
                      icon={<FaComputer />}
                      aria-label="default"
                      className="tab-icons"
                      id="tab_google"
                    />
                    <Tab
                      className="tab-icons"
                      icon={<LiaDesktopSolid />}
                      aria-label="desktop"
                      id="tab_google"
                    />

                    <Tab
                      className="tab-icons"
                      icon={<BsPhone />}
                      aria-label="mobile"
                      id="tab_google"
                    />
                  </Tabs>
                </div>
                <div className="col-lg-auto col-md-auto col-12">
                  <div className="cmd justify-md-content-end justify-lg-content-end justify--content-between">
                    <Dropdown
                      id="googleDrop"
                      callback={handleDateUpdate}
                      data={selectDay}
                      title={title}
                      style={{
                        width: `${MOBILE ? "125px" : "200px"}`,
                        textTransform: "capitalize",
                        boxShadow: "none",
                        border: "2px solid rgba(1, 1, 1, 0.8)",
                        borderRadius: "10px",
                        padding: ".9rem 1rem",
                        color: "rgba(1, 1, 1, 0.8)",
                      }}
                    />
                    <span className="ms-4">{dataLoadedFrom}</span>
                  </div>
                </div>
              </div>
              <div
                style={{ maxHeight: "500px", height: "230px" }}
                className="w-100"
              >
                <SingleLineChart
                  Ref={GraphRef}
                  data={chart_data}
                  Options={options}
                  MOBILE={MOBILE}
                />
              </div>
            </div>
          )}
        </div>
      </div>

      {userAnalyticLogin ? (
        <div className="google-console-data">
          <div
            className="cmd-b  mb-4"
            style={{
              display: "flex",
              flexDirection: "column",
              borderRadius: "10px",
              backgroundColor: "white",
            }}
          >
            <div className="cmd analy-con-div w-100">
              <div className="row align-items-center w-100 gy-3">
                <div className="col-12 col-md-12 col-lg-6">
                  <div className="d-flex align-items-center">
                    <h5 className="heading-large mb-0">Search Console</h5>
                    {consoleData[0]?.site && (
                      <div className="d-flex align-items-center">
                        <img
                          className="googleIMG"
                          src={googleConsoleImg}
                          alt=""
                          style={{ marginLeft: MOBILE ? "0px" : "75px" }}
                        />
                        <img
                          src={greenTick}
                          alt=""
                          style={{ width: "25px", height: "25px" }}
                          className="ms-2 me-2"
                        />
                      </div>
                    )}
                  </div>
                </div>
                <div className="col-12 col-md-12 col-lg-6 ms-auto">
                  <div className="analy-gol d-flex align-items-center justify-content-end">
                    {consoleData?.length === 0 && !consoleStatus ? (
                      <button
                        className="btn_new_bg"
                        onClick={getGoogleConsoleData}
                      >
                        GET DATA
                      </button>
                    ) : (
                      <div className="d-flex align-items-center justify-content-end">
                        {consoleData[0]?.site && (
                          <>
                            <div>
                              <span className="heading-large font-light-bold">
                                Domain:{" "}
                              </span>
                              <span className="font-regular heading-large">
                                {consoleData[0]?.site?.replace(
                                  "sc-domain:",
                                  ""
                                )}{" "}
                              </span>
                              {consoleData[0]?.date && (
                                <span className="heading-xs mt-1">
                                  (Last 3 months)
                                </span>
                              )}
                            </div>
                          </>
                        )}
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
            {consoleData?.length === 0 ? (
              consoleStatus ? (
                <h1 className="heading-medium font-light-bold">
                  {" "}
                  We Are Fetching Your Search Console Data. Please Wait...{" "}
                </h1>
              ) : null
            ) : (
              <div className="w-100 ">
                <div>
                  <div className="d-flex console-chart-box overAuto">
                    <div className="console-total-clicks">
                      <div className="heading-medium font-light-bold">
                        Total clicks :
                      </div>
                      <div className="heading-xlarge font-light-bold me-2">
                        {sumArray(con_chart_clicks)}
                      </div>
                    </div>
                    <div className="console-total-impressions">
                      <div className="heading-medium font-light-bold">
                        Total impressions :
                      </div>
                      <div className="heading-xlarge font-light-bold me-2">
                        {sumArray(con_chart_impressions)}
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  className="w-100"
                  style={{ maxHeight: "500px", height: "300px" }}
                >
                  {consoleData?.length > 0 && (
                    <SingleLineChart
                      data={con_chart_data}
                      Options={con_options}
                    />
                  )}
                </div>

                <div className="w-100 mt-5">
                  <div className="row gy-3 align-items-center">
                    <div className="col-12 col-md-8 col-md-8">
                      <ul className="console-data-opion">
                        <li
                          className={
                            consoleTableOption === "queries"
                              ? "console-data-li-active"
                              : ""
                          }
                          onClick={() => consoleDataOptionUpdate("queries")}
                        >
                          Queries
                        </li>
                        <li
                          className={
                            consoleTableOption === "queries"
                              ? ""
                              : "console-data-li-active"
                          }
                          onClick={() => consoleDataOptionUpdate("pages")}
                        >
                          Pages
                        </li>
                      </ul>
                    </div>
                    <div className="col-12 col-md-4 col-lg-4">
                      <Dropdown
                        id="consoleDrop"
                        callback={handleMonthUpdate}
                        data={consoleDropMonth}
                        title={consoleSelectedMonth}
                        style={{
                          width: "100%",
                          maxWidth: "250px",
                          textTransform: "capitalize",
                          boxShadow: "none",
                          border: "2px solid rgba(1, 1, 1, 0.8)",
                          borderRadius: "10px",
                          padding: ".9rem 1rem",
                          color: "rgba(1, 1, 1, 0.8)",
                          margin: "auto",
                        }}
                      />
                    </div>
                  </div>
                  <div className="google-console-table custom-table-container mt-3">
                    <table className="table my-0">
                      <thead>
                        <tr className="table-sticky">
                          <th scope="col" className="t_left">
                            {consoleTableOption === "queries"
                              ? "Keyword"
                              : "Page URL"}
                            <span className="ms-3">
                              {startIndex + 1}-
                              {endIndex > currentPageTableData?.length
                                ? currentPageTableData?.length
                                : endIndex}{" "}
                              [{currentPageTableData?.length}]
                            </span>
                          </th>

                          <th
                            scope="col"
                            className=""
                            style={{ width: "230px" }}
                          >
                            Click
                          </th>

                          <th
                            scope="col"
                            className=""
                            style={{ width: "250px" }}
                          >
                            Impressions
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        {consoleLoader ? (
                          <tr>
                            <td colSpan={3}>
                              <Sppiner />
                            </td>
                          </tr>
                        ) : (
                          table_console_data?.map((item, index) => {
                            return (
                              <tr key={`list-${index}`}>
                                <td className="t_left">
                                  {consoleTableOption === "queries" ? (
                                    item.keyword
                                  ) : (
                                    <a href={item?.page_url} target="blanck">
                                      {item.page_url}
                                    </a>
                                  )}
                                </td>
                                <td className="">{item.clicks}</td>
                                <td className="">{item?.impressions}</td>
                              </tr>
                            );
                          })
                        )}
                      </tbody>
                    </table>
                  </div>
                  {currentPageTableData?.length > 10 && (
                    <div className="mt-4">
                      <ArrowPagination
                        page={tablePage}
                        onPageChange={handlePaginationChange}
                        lastPage={totalPages}
                      />
                    </div>
                  )}
                </div>
              </div>
            )}
          </div>
        </div>
      ) : null}
    </main>
  );
};

export default GoogleData;
