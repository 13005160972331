import React, { useState } from "react";
import "../css/upgrade.css";
import "../css/upgrade-banner.scss";
import { useSelector } from "react-redux";
import { useEffect } from "react";
import {
  POST_ALPHAPLAN,
  customer_Subs_Email,
  get_Plans_Details,
} from "../../services/constants";
import axios from "axios";
import Dropdown from "../share/components/dropdown";
import priceZero from "../Assets/upgrade/plan-0.svg";
import priceOne from "../Assets/upgrade/plan-1.svg";
import priceTwo from "../Assets/upgrade/plan-2.svg";
import priceThree from "../Assets/upgrade/plan-3.svg";
import useWebHook from "../../helperJs/useWebHook";
import { redirect } from "react-router-dom";
import PopOver from "../share/components/popover.component";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import CloseIcon from "@mui/icons-material/Close";
import IconButton from "@mui/material/IconButton";
import { useNavigate } from "react-router-dom";

const Upgrade = () => {
  const admin_mail = localStorage.getItem("admin_mail");
  const user_aff_id = localStorage.getItem("affliate_id");
  const EMAIL = useSelector((state) => state?.email);
  const user_name = useSelector((state) => state?.name);
  const { fetchData } = useWebHook();
  const navigate = useNavigate();

  const [showPlanModal, setShowPlanModal] = useState({
    plan: "",
    show: false,
    loading: false,
  });
  const [planType, setplanType] = useState("month");
  const [plansDetails, setPlansDetails] = useState([]);
  const [filteredPlansShow, setFilteredPlansShow] = useState([]);
  const [currencyType, setCurrencyType] = useState("USD");
  const plan_price = localStorage.getItem("plan_price");

  const fatch_plansDetails = () => {
    setPlansDetails([]);
    axios.get(get_Plans_Details(currencyType)).then((res) => {
      const data = res.data.data;
      setPlansDetails(data);
    });
  };

  useEffect(() => {
    fatch_plansDetails();
  }, [currencyType]);

  const ChangeMonthType = () => {
    setplanType("month");
  };

  const ChangeLifeTimeType = () => {
    setplanType("yearly");
  };

  const currency_data = ["USD", "INR"];

  useEffect(() => {
    setFilteredPlansShow([]);
    plansDetails &&
      plansDetails.filter((plan) => {
        if (planType === plan.validity) {
          setFilteredPlansShow((res) => {
            return [...res, plan];
          });
        }
      });
  }, [plansDetails, planType]);

  const handleUserSubscription = (data) => {
    setShowPlanModal((pre) => ({
      ...pre,
      plan: data.name,
      loading: true,
    }));

    let Customerconfig = {
      method: "get",
      maxBodyLength: Infinity,
      url: customer_Subs_Email(EMAIL),
      headers: {
        Authorization:
          "Bearer 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",
        "Content-Type": "application/x-www-form-urlencoded",
      },
    };
    axios(Customerconfig)
      .then((response) => {
        if (response?.data?.data?.length > 0) {
          setShowPlanModal({
            loading: false,
            plan: "",
            show: true,
          });
        } else {
          setShowPlanModal({
            loading: false,
            show: false,
            plan: "",
          });
          window.open(data.payment_link, "_blank");
        }
      })
      .catch(() => {
        setShowPlanModal({
          loading: false,
          show: false,
          plan: "",
        });
      });
  };

  const alphaHandler = (data) => {
    if (data?.name === "alpha") {
      const item = {
        email: admin_mail,
        is_plan: true,
      };
      axios.post(POST_ALPHAPLAN(), item).then((res) => {
        window.location.href = "https://app.rankspro.io/dashboard";
      });
    } else {
      handleUserSubscription(data);
    }
    const obj_data = {
      try_upgrade: data?.name,
      domain: null,
    };
    fetchData(obj_data);
  };

  return (
    <>
      <PopOver anchorEl={showPlanModal?.show}>
        <Box
          sx={{
            bgcolor: "rgba(255, 255, 255, 1)",
            flexDirection: "column",
          }}
          className="popover-cont"
        >
          <div className="p-4" style={{ height: "230px" }}>
            <Typography id="modal-modal-title" variant="h6" component="h2">
              Manage Subscription
              <IconButton
                className="popover-close-icon"
                onClick={() =>
                  setShowPlanModal({
                    loading: false,
                    show: false,
                  })
                }
                sx={{
                  "&:hover": {
                    backgroundColor: "rgba(240, 242, 255, 1)",
                  },
                }}
              >
                <CloseIcon sx={{ color: "black" }} fontSize="medium" />
              </IconButton>
            </Typography>
            <Typography id="modal-modal-description" sx={{ mt: 2 }}>
              You're already subscribed to one of our Plans. Now,
            </Typography>
            <Typography id="modal-modal-description" sx={{ mb: 2 }}>
              you can update the plan by using Manage Subscription button.
            </Typography>

            <a
              href={"https://billing.stripe.com/p/login/14k7us0IvchS8so6oo"}
              target="_blank"
            >
              <Button
                variant="outlined"
                sx={{
                  margin: "auto",
                  display: "flex",
                  borderColor: "#e95655 !important",
                  color: "#e95655",
                }}
              >
                Manage Subscription
              </Button>
            </a>
          </div>
        </Box>
      </PopOver>
      <section className="Domain_view">
        <div className="w-100 mt-3" style={{ position: "relative" }}>
          <div className="cmc my-3">
            <div
              style={{
                position: "absolute",
                left: "0",
                top: 0,
              }}
            >
              <Dropdown
                data={currency_data}
                title={currencyType}
                style={{
                  width: "130px",
                  textTransform: "none",
                  boxShadow: "2px 4px 10px rgba(0, 0, 0, .08)",
                  padding: "1rem",
                  borderRadius: "30px",
                }}
                callback={setCurrencyType}
              />
            </div>
            <div className="up-nav ">
              <button
                style={{ borderRadius: "0px" }}
                className={planType === "month" ? "cm-btn" : "cm-btn-b"}
                onClick={ChangeMonthType}
              >
                <div className="btn-hov">Monthly</div>
              </button>

              <button
                style={{ borderRadius: "0px" }}
                className={planType === "yearly" ? "cm-btn" : "cm-btn-b"}
                onClick={ChangeLifeTimeType}
              >
                <div className="btn-hov">Yearly</div>
              </button>
            </div>
          </div>
          <div className="">
            <div className="row">
              {filteredPlansShow !== 0
                ? filteredPlansShow?.map((res, index) => {
                    return (
                      <div
                        className={
                          res.name === "alpha"
                            ? "col-md-6 col-lg-4 col-12 mt-3 order-first"
                            : res.name === "starter"
                            ? "col-md-6 col-lg-4  col-12 mt-3 order-first"
                            : "col-md-6 col-lg-4  col-12 mt-3 "
                        }
                        key={res.name}
                      >
                        <div className="upgrade-card ">
                          <div
                            className={`cmd ${
                              res.name === "alpha"
                                ? "justify-content-lg-end"
                                : "justify-content-lg-between"
                            }`}
                          >
                            {
                              <div
                                className={`${
                                  res.name !== "alpha" &&
                                  "dom-lmt heading-xs font-light-bold"
                                } `}
                              >
                                {res.name !== "alpha" && "7-Day Trial"}
                              </div>
                            }
                            <div className="dom-lmt heading-xs font-light-bold">
                              {res?.proj_len === "1000"
                                ? "Unlimited Domains"
                                : res?.proj_len === "1"
                                ? `${res?.proj_len} Domain`
                                : `${res?.proj_len} Domains`}{" "}
                            </div>
                          </div>
                          <div className="up-img">
                            <img
                              src={
                                planType === "yearly"
                                  ? index === 0
                                    ? priceZero
                                    : index === 1
                                    ? priceOne
                                    : priceTwo
                                  : res.name === "alpha"
                                  ? priceZero
                                  : res.name === "pro"
                                  ? priceOne
                                  : res.name === "elite"
                                  ? priceTwo
                                  : priceThree
                              }
                              style={{
                                width: "60px",
                                height: "60px",
                                objectFit: "contain",
                              }}
                              alt="price-card"
                            />
                          </div>

                          <div
                            style={{
                              display: "flex",
                              alignItems: "flex-end",
                              marginBottom: "1rem",
                            }}
                          >
                            <h4 className="font-bold">
                              {currencyType === "INR" ? "₹" : "$"}
                              {res.price}
                            </h4>
                            <p className="usd-p heading-medium">
                              {planType === "yearly"
                                ? currencyType === "INR"
                                  ? "INR/year"
                                  : "USD/year"
                                : currencyType === "INR"
                                ? "INR/month"
                                : "USD/month"}
                            </p>
                          </div>
                          <h3
                            style={{ textTransform: "capitalize" }}
                            className="heading-large"
                          >
                            {res.name}
                          </h3>
                          <div
                            className="my-2"
                            style={{
                              backgroundColor: "rgb(186, 186, 186)",
                              height: "1px",
                            }}
                          ></div>
                          <div className="up-cont">
                            <ul>
                              <li>
                                <i className="fa-solid fa-check"></i>
                                {res.proj_len === "1000"
                                  ? "Unlimited Domains"
                                  : res.proj_len === "1"
                                  ? `${res.proj_len} Domain`
                                  : `${res.proj_len} Domains`}{" "}
                              </li>
                              <li>
                                <i className="fa-solid fa-check"></i>
                                {res.keyword_len} tracked keywords
                              </li>
                              <li>
                                <i className="fa-solid fa-check"></i>
                                {res.competitors_limit} competitors / domain
                              </li>
                              <li>
                                <i className="fa-solid fa-check"></i>
                                {res.sub_users_limit === "1000"
                                  ? "Unlimited"
                                  : res.sub_users_limit}{" "}
                                users
                              </li>

                              <a
                                className="InternalLink heading-small"
                                href={"#PLANS"}
                              >
                                See More
                              </a>
                            </ul>

                            <button
                              onClick={() => alphaHandler(res)}
                              className={
                                plan_price === res?.price
                                  ? "upgrade-button upgrade-button-selected d-flex align-items-center justify-content-center"
                                  : "upgrade-button d-flex align-items-center justify-content-center"
                              }
                              disabled={plan_price === res?.price}
                            >
                              {showPlanModal?.loading &&
                              showPlanModal.plan === res.name ? (
                                <Box
                                  sx={{
                                    display: "flex",
                                    minHeight: 3,
                                    minWidth: 3,
                                  }}
                                  className="h-100 cad me-2"
                                >
                                  <div
                                    className="spinner-border text-primary"
                                    role="status"
                                  />
                                </Box>
                              ) : plan_price === res?.price ? (
                                "Current"
                              ) : res.name === "alpha" ? (
                                "Forever Free"
                              ) : plan_price > Number(res?.price) ? (
                                "Downgrade"
                              ) : (
                                "Upgrade"
                              )}
                            </button>
                          </div>
                        </div>
                      </div>
                    );
                  })
                : "No plans added for this type"}
            </div>
          </div>

          <div className="PlansTable">
            <table
              className={`${filteredPlansShow.length !== 0 ? "SHOW" : "HIDE"}`}
            >
              <thead>
                <tr>
                  <th>
                    <div className="hightlightHeading">
                      <h5 className="heading-large">Pick a plan</h5>
                      <h5 className="heading-xlarge font-bold">
                        And Grow Your
                      </h5>
                      <h5 className="heading-xlarge font-bold">Seo Traffic</h5>
                    </div>
                  </th>
                  {filteredPlansShow !== 0 &&
                    filteredPlansShow
                      .sort((a, b) => a.price - b.price)
                      .map((res) => (
                        <React.Fragment key={res.price}>
                          <th id="PLANS">
                            <div className="d-flex flex-column jusify-content-center align-items-center">
                              <div
                                style={{
                                  display: "flex",
                                  alignItems: "flex-end",
                                  marginBottom: ".2rem",
                                }}
                              >
                                <h4 className="mb-0 font-bold heading-large">
                                  {currencyType === "INR" ? "₹" : "$"}
                                  {res.price}
                                </h4>
                                <p className="usd-p mb-0 heading-small">
                                  {planType === "yearly"
                                    ? currencyType === "INR"
                                      ? "INR/month"
                                      : "USD/year"
                                    : currencyType === "INR"
                                    ? "INR/month"
                                    : "USD/month"}
                                </p>
                              </div>
                              <h3
                                className="PlansName"
                                style={{
                                  textTransform: "capitalize",
                                }}
                              >
                                {res.name}
                              </h3>
                            </div>
                          </th>
                        </React.Fragment>
                      ))}
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>
                    <div className="">
                      <h5>Projects</h5>
                    </div>
                  </td>

                  {filteredPlansShow !== 0 &&
                    filteredPlansShow
                      .sort((a, b) => a.price - b.price)

                      .map((res) => {
                        return (
                          <React.Fragment key={res.proj_len}>
                            <td>
                              <div className="">
                                <h5>
                                  {res.proj_len === "1000"
                                    ? "Unlimited"
                                    : res.proj_len}{" "}
                                </h5>
                              </div>
                            </td>
                          </React.Fragment>
                        );
                      })}
                </tr>
                <tr>
                  <td>
                    <div className="">
                      <h5>rank Tracking</h5>
                    </div>
                  </td>
                  {filteredPlansShow !== 0 &&
                    filteredPlansShow
                      .sort((a, b) => a.price - b.price)

                      .map((res) => {
                        return (
                          <React.Fragment key={res.keyword_len}>
                            <td>
                              <div className="">
                                <h5>{res.keyword_len}</h5>
                              </div>
                            </td>
                          </React.Fragment>
                        );
                      })}
                </tr>
                <tr>
                  <td>
                    <div className="">
                      <h5>Competitors / Project</h5>
                    </div>
                  </td>
                  {filteredPlansShow !== 0 &&
                    filteredPlansShow
                      .sort((a, b) => a.price - b.price)

                      .map((res) => {
                        return (
                          <React.Fragment key={res.competitors_limit}>
                            <td>
                              <div className="">
                                <h5>{res.competitors_limit}</h5>
                              </div>
                            </td>
                          </React.Fragment>
                        );
                      })}
                </tr>

                <tr>
                  <td>
                    <div className="">
                      <h5>Users</h5>
                    </div>
                  </td>
                  {filteredPlansShow !== 0 &&
                    filteredPlansShow
                      .sort((a, b) => a.price - b.price)

                      .map((res) => {
                        return (
                          <React.Fragment key={res.sub_users_limit}>
                            <td>
                              <div className="">
                                <h5>
                                  {res.sub_users_limit === "1000"
                                    ? "Unlimited"
                                    : res.sub_users_limit}{" "}
                                </h5>
                              </div>
                            </td>
                          </React.Fragment>
                        );
                      })}
                </tr>
                <tr>
                  <td>
                    <div className="">
                      <h5>Site Overview</h5>
                    </div>
                  </td>
                  {filteredPlansShow !== 0 &&
                    filteredPlansShow
                      .sort((a, b) => a.price - b.price)

                      .map((res) => {
                        return (
                          <React.Fragment key={res.site_overview_limit}>
                            <td>
                              <div className="">
                                <h5>
                                  {res.site_overview_limit === "1000"
                                    ? "Unlimited"
                                    : res.site_overview_limit}{" "}
                                </h5>
                              </div>
                            </td>
                          </React.Fragment>
                        );
                      })}
                </tr>
                <tr>
                  <td>
                    <div className="">
                      <h5>Site Audit Weekly</h5>
                    </div>
                  </td>
                  {filteredPlansShow !== 0 &&
                    filteredPlansShow
                      .sort((a, b) => a.price - b.price)

                      .map((res) => {
                        return (
                          <React.Fragment key={res.site_audit_limit}>
                            <td>
                              <div className="">
                                <h5>
                                  {res.site_audit_limit === "1000"
                                    ? "Unlimited"
                                    : res.site_audit_limit}{" "}
                                </h5>
                              </div>
                            </td>
                          </React.Fragment>
                        );
                      })}
                </tr>
                <tr>
                  <td>
                    <div className="">
                      <h5>Keyword Research</h5>
                    </div>
                  </td>
                  {filteredPlansShow !== 0 &&
                    filteredPlansShow
                      .sort((a, b) => a.price - b.price)

                      .map((res) => {
                        return (
                          <React.Fragment key={res.key_limit}>
                            <td>
                              <div className="">
                                <h5>
                                  {res.key_limit === "1000"
                                    ? "Unlimited"
                                    : res.key_limit}{" "}
                                </h5>
                              </div>
                            </td>
                          </React.Fragment>
                        );
                      })}
                </tr>
                <tr>
                  <td>
                    <div className="">
                      <h5>Backlinks</h5>
                    </div>
                  </td>
                  {filteredPlansShow !== 0 &&
                    filteredPlansShow
                      .sort((a, b) => a.price - b.price)

                      .map((res) => {
                        return (
                          <React.Fragment key={res.backlinks_rows_limit}>
                            <td>
                              <div className="">
                                <h5>{res.backlinks_rows_limit}</h5>
                              </div>
                            </td>
                          </React.Fragment>
                        );
                      })}
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </section>
    </>
  );
};

export default Upgrade;
