import React, { useState, useEffect, useMemo } from "react";
import {
  post_googleprop_data,
  disconnect_google,
  post_selected_googleprop,
  post_userpostanalytics,
  getanalyticsdata,
  getsavegoogleoauth,
  postgooglepropertydata,
  postgoogleaccount,
} from "../services/constants";
import { useLocation, useNavigate } from "react-router-dom";
import axios from "axios";
import PopOver from "../Mycomponent/share/components/popover.component";
import { useDispatch, useSelector } from "react-redux";
import Button from "@mui/material/Button";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import ListSubheader from "@mui/material/ListSubheader";
import List from "@mui/material/List";
import ListItemText from "@mui/material/ListItemText";
import Divider from "@mui/material/Divider";
import IconButton from "@mui/material/IconButton";
import ListItemButton from "@mui/material/ListItemButton";
import ToolTip from "../Mycomponent/share/components/tooltip";
import CloseIcon from "@mui/icons-material/Close";
import googleImg from "../Mycomponent/Assets/google-analytics/google.png";
import googleAnalyticsImg from "../Mycomponent/Assets/google-analytics/Logo_Google_Analytics.svg.png";
import greenTick from "../Mycomponent/Assets/google-analytics/checked.png";
import { Typography } from "@mui/material";
import { DottLoader } from "../Mycomponent/share/loader";
import useMediaQuery from "@mui/material/useMediaQuery";
import AlertPopUp from "../Mycomponent/share/components/alert.component";
import { ZustandStore } from "../store/zustand-store";

const GoogleAuth = ({ analyticShow }) => {
  const location = useLocation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { pathname } = location;
  const matches = useMediaQuery("(min-width:600px)");
  const queryParams = new URLSearchParams(location.search);
  const domainName = useSelector((state) => state.websiteurl);
  const loading = useSelector((state) => state.loading);
  const [data, setData] = useState([]);
  const [AnalyticData, setAnalyticData] = useState([]);
  const [anchorEl, setAnchorEl] = React.useState(false);
  const [Error, setError] = React.useState("");
  const [googleProperty, setGoogleProperty] = React.useState([]);
  const [Loader, setLoader] = React.useState(false);
  const [selectedGoogleProperty, setselectedGoogleProperty] = React.useState(
    []
  );
  const [buttonLoader, setButtonLoader] = useState(false);
  const [Alert, setAlert] = useState({
    type: "",
    message: "",
    show: false,
  });
  const userAnalyticLogin = useSelector((state) => state.userAnalyticLogin);
  const email = localStorage.getItem("admin_mail");
  const google_anayltic_code = queryParams.get("code");

  function handleClose() {
    setAnchorEl(false);
  }

  const getAnalyticsDataByApi = async (api) => {
    return await axios
      .get(api)
      .then((response) => {
        return response.data;
      })
      .catch(() => {
        return [];
      });
  };

  const getConfig = {
    email: email,
    domain_name: domainName,
  };

  const handleGetAnalyticsData = async () => {
    const api = getanalyticsdata(getConfig);
    return await getAnalyticsDataByApi(api);
  };

  const handleGetSaveGoogleOauth = async () => {
    const api = getsavegoogleoauth(getConfig);
    return await getAnalyticsDataByApi(api);
  };

  const handleUserAnalyticData = async () => {
    if (email == null) return;
    if (AnalyticData?.length > 0) return;
    const analyticData = await handleGetAnalyticsData();
    if (analyticData?.length === 0) {
      const getSaveGoogleOauth = await handleGetSaveGoogleOauth();
      if (getSaveGoogleOauth?.length > 0) {
        dispatch({ type: "USERANALYTICLOGIN", payload: true });
        dispatch({ type: "USERANALYTICDATA", payload: [] });
      } else {
        axios.post(postgoogleaccount(email, domainName)).then((response) => {
          if (response.data?.length > 0) {
            setData(response.data);
            setAnchorEl(true);
            setLoader(false);
          } else {
            dispatch({ type: "USERANALYTICLOGIN", payload: false });
            dispatch({ type: "USERANALYTICDATA", payload: [] });
          }
        });
      }
    } else if (analyticData?.length > 0) {
      setAnalyticData(analyticData);
      dispatch({ type: "USERANALYTICDATA", payload: analyticData });
      dispatch({ type: "USERANALYTICLOGIN", payload: true });
    }
  };

  useEffect(() => {
    const dominUpdate = () => {
      setAnchorEl(false);
      setData([]);
      setAnalyticData([]);
      setselectedGoogleProperty([]);
      setGoogleProperty([]);
      dispatch({ type: "USERANALYTICLOGIN", payload: false });
      dispatch({ type: "USERANALYTICDATA", payload: [] });
    };
    dominUpdate();
  }, [domainName]);

  const checkUserAnalyticData = async () => {
    if (email == null) return;
    if (AnalyticData?.length > 0) return;
    const analyticData = await handleGetAnalyticsData();
    const getSaveGoogleOauth = await handleGetSaveGoogleOauth();
    ZustandStore.setState({
      property_name: getSaveGoogleOauth || [],
    });
    if (analyticData?.length === 0) {
      if (getSaveGoogleOauth?.length > 0) {
        dispatch({ type: "USERANALYTICLOGIN", payload: true });
        dispatch({ type: "USERANALYTICDATA", payload: [] });
      }
    } else if (analyticData?.length > 0) {
      setAnalyticData(analyticData);
      dispatch({ type: "USERANALYTICDATA", payload: analyticData });
      dispatch({ type: "USERANALYTICLOGIN", payload: true });
    }
  };

  useEffect(() => {
    if (AnalyticData?.length === 0) {
      checkUserAnalyticData(email);
      const interval = 60000 * 2; // 60000 milliseconds = 1 minute
      const intervalId = setInterval(() => {
        checkUserAnalyticData(email);
      }, interval);
      return () => clearInterval(intervalId);
    }
  }, [email, AnalyticData, userAnalyticLogin]);

  const postGooglePropertyData = (config) => {
    axios
      .post(postgooglepropertydata(config))
      .then((response) => {
        setGoogleProperty(response.data);
      })
      .catch((error) => console.log(error));
  };

  const post_googleAnalytic_code = (config) => {
    navigate(pathname, { replace: true });
    axios
      .post(post_googleprop_data(config))
      .then((response) => {
        setData(response.data);
        setAnchorEl(true);
      })
      .catch(() => {
        setAlert({
          type: "error",
          message: "Error: Google Account Not Connected",
          show: true,
        });
      });
  };

  useEffect(() => {
    if (
      google_anayltic_code != null &&
      email != null &&
      !loading &&
      domainName
    ) {
      const config = {
        code: google_anayltic_code,
        email: email,
        domain: domainName,
      };
      post_googleAnalytic_code(config);
    }
  }, [google_anayltic_code, email, loading, domainName]);

  const handleButtonClick = () => {
    setLoader(true);
    setData([]);
    axios.post(postgoogleaccount(email, domainName)).then((response) => {
      if (response.data?.length === 0) {
        setLoader(false);
        window.oauth2SignIn();
      } else {
        handleUserAnalyticData();
      }
    });
  };

  const handleGooglePropertyData = (item) => {
    const config = {
      account_id: item.account_id,
      property_id: item.property_id,
      date: item?.date,
      refresh_token: item.refresh_token,
      email: email,
      domain_name: domainName,
      display_name: item?.property_name,
    };
    const config2 = {
      property_id: item.property_id,
      date: item?.date,
      refresh_token: item.refresh_token,
      email: email,
      domain_name: domainName,
      display_name: item?.property_name,
    };

    axios
      .post(post_selected_googleprop(config))
      .then(() => {
        setAnchorEl(false);
        setData([]);
        dispatch({ type: "USERANALYTICLOGIN", payload: true });
        axios.post(post_userpostanalytics(config2));
      })
      .catch((error) => console.log(error));
  };

  const handleDisconnectGoogle = () => {
    if (email == null) return;
    setButtonLoader(true);
    axios
      .post(disconnect_google(getConfig))
      .then(() => {
        dispatch({ type: "USERANALYTICLOGIN", payload: false });
        dispatch({ type: "USERANALYTICDATA", payload: [] });
        setButtonLoader(true);
        // window.location.reload();
      })
      .catch(() => {
        setButtonLoader(false);
        setAlert({
          type: "error",
          message: "Error: Google Account Not Disconnected",
          show: true,
        });
      });
  };

  const handleGoogleAnaltyicAccountId = (type, value, index) => {
    const data = {
      id: index,
      account_id: value?.account_id,
      account_name: value?.account_name,
      property_id: "",
      property_name: "",
      date: "",
      refresh_token: value?.refresh_token,
      email: email,
    };
    if (type === "Account") {
      setselectedGoogleProperty(data);
      postGooglePropertyData(data);
    } else if (type === "Select") {
      setselectedGoogleProperty(data);
    } else {
      setselectedGoogleProperty({
        ...selectedGoogleProperty,
        property_id: value?.name,
        property_name: value?.displayName,
        date: value?.createTime,
      });
    }
  };

  const handleSubmitGoogleProperty = () => {
    if (selectedGoogleProperty?.length === 0) {
      setError("Please, Select Account");
    } else if (selectedGoogleProperty?.property_id === "") {
      setError("Please, Select Property");
    } else {
      setError("");
      handleGooglePropertyData(selectedGoogleProperty);
    }
  };

  const Sppiner = () => {
    return (
      <Box
        sx={{ display: "flex", minHeight: 350, minWidth: 350 }}
        className="w-100 h-100 cad"
      >
        <div className="spinner-border text-primary" role="status">
          <span className="sr-only"></span>
        </div>
      </Box>
    );
  };

  return (
    <>
      {Loader && <DottLoader />}
      <AlertPopUp config={Alert} handleClose={setAlert} />
      {userAnalyticLogin ? (
        <div className="d-flex align-items-center">
          {analyticShow ? (
            <div className="row gy-2 align-items-center">
              {matches && (
                <div className="col-md-6 col-lg-6 col-6 p-0 p-md-1 p-lg-1 ps-1">
                  <div className="d-flex me-4 align-items-center">
                    <img
                      className="googleIMG"
                      src={googleAnalyticsImg}
                      alt=""
                    />
                    <img
                      src={greenTick}
                      alt=""
                      style={{ width: "25px", height: "25px" }}
                      className="ms-2 me-2"
                    />
                  </div>
                </div>
              )}

              <div
                className={
                  matches
                    ? "col-md-6 col-lg-6 col-6 p-0 p-md-1 p-lg-1"
                    : "col-12 p-0 p-md-1 p-lg-1"
                }
              >
                <button
                  onClick={() => handleDisconnectGoogle()}
                  className="google-login-btn d-flex align-items-center"
                >
                  {buttonLoader ? (
                    <Box
                      sx={{ display: "flex", minHeight: 8, minWidth: 8 }}
                      className="w-100 h-100 cad"
                    >
                      <div
                        className="spinner-border text-primary"
                        role="status"
                      >
                        <span className="sr-only"></span>
                      </div>
                    </Box>
                  ) : (
                    <>
                      <img
                        src={googleImg}
                        alt=""
                        className=""
                        style={{
                          width: "25px",
                          height: "25px",
                          marginRight: "10px",
                        }}
                      />
                      Disconnect with Google
                    </>
                  )}
                </button>
              </div>
            </div>
          ) : null}
        </div>
      ) : (
        <div className="d-flex align-items-center">
          <button
            onClick={handleButtonClick}
            style={{ background: "white", color: "black" }}
            className="google-login-btn d-flex align-items-center"
          >
            <img
              src={googleImg}
              alt=""
              className=""
              style={{ width: "25px", height: "25px", marginRight: "12px" }}
            />
            Connect with Google
          </button>
        </div>
      )}
      <PopOver anchorEl={anchorEl} setAnchorEl={() => setAnchorEl(false)}>
        <Box
          sx={{
            bgcolor: "rgba(255, 255, 255, 1)",
          }}
          className="popover-cont"
        >
          <Box sx={{ width: "100%" }}>
            <Typography
              component="h1"
              id="select-google-property"
              className="heading-large font-bold popover-list-header py-2 px-2"
            >
              Select Google Property
              <ToolTip
                title="List of Properties attached with your Google Analyics
                            Account, select property for which you want data to be
                            shown."
              />
              <IconButton
                className="popover-close-icon"
                onClick={handleClose}
                sx={{
                  "&:hover": {
                    backgroundColor: "rgba(240, 242, 255, 1)",
                  },
                }}
              >
                <CloseIcon sx={{ color: "black" }} fontSize="small" />
              </IconButton>
            </Typography>
            {data?.length > 0 ? (
              <>
                <Grid
                  container
                  spacing={2}
                  columns={12}
                  className="mb-2"
                  sx={{ minWidth: { md: 700 } }}
                >
                  <Grid item xs={12} md={6}>
                    {data?.length === 0 ? (
                      <Sppiner />
                    ) : (
                      <List
                        component="nav"
                        aria-label="select-google-property"
                        className="overflow-auto p-0 list-border h-100"
                        sx={{ maxHeight: 350 }}
                        subheader={
                          <ListSubheader
                            component="h1"
                            id="select-google-property"
                            className="heading-small font-bold popover-list-header mb-0"
                          >
                            Select Account
                          </ListSubheader>
                        }
                      >
                        {data?.map((item, index) => {
                          return (
                            <React.Fragment
                              key={`google-property-list${index}`}
                            >
                              <ListItemButton
                                selected={
                                  selectedGoogleProperty?.account_id ===
                                  item?.account_id
                                }
                                sx={{
                                  "&:hover": {
                                    backgroundColor: "rgba(240, 242, 255, 1)",
                                  },
                                }}
                                onClick={() =>
                                  handleGoogleAnaltyicAccountId("Account", item)
                                }
                              >
                                <ListItemText
                                  primary={item?.account_name}
                                  secondary={item?.account_id}
                                  sx={{
                                    "& .MuiListItemText-primary": {
                                      fontSize: "1.2em",
                                      color: "#365486",
                                      fontWeight: "600",
                                    },
                                    "& .MuiListItemText-secondary": {
                                      fontSize: "1em",
                                      color: "black",
                                    },
                                  }}
                                />
                              </ListItemButton>
                              <Divider />
                            </React.Fragment>
                          );
                        })}
                      </List>
                    )}
                  </Grid>
                  <Grid item xs={12} md={6}>
                    {googleProperty?.length === 0 ? (
                      selectedGoogleProperty?.length === 0 ? (
                        <Box
                          sx={{ display: "flex", minHeight: 300 }}
                          className="cad"
                        >
                          <h1 className="heading-small font-bold popover-list-header">
                            Select your Account to see property list
                          </h1>
                        </Box>
                      ) : googleProperty?.[0]?.final_res?.properties?.length ===
                        0 ? (
                        <Box
                          sx={{ display: "flex", minHeight: 300 }}
                          className="cad"
                        >
                          <h1 className="heading-small font-bold popover-list-header">
                            Select your Account to see property list
                          </h1>
                        </Box>
                      ) : (
                        <Sppiner />
                      )
                    ) : (
                      <List
                        component="nav"
                        aria-label="select-google-property"
                        className="overflow-auto p-0 list-border h-100"
                        sx={{ maxHeight: 350 }}
                        subheader={
                          <ListSubheader
                            component="h1"
                            id="select-google-property"
                            className="heading-small font-bold popover-list-header mb-0"
                          >
                            Select Property
                          </ListSubheader>
                        }
                      >
                        {googleProperty?.[0]?.final_res?.properties?.map(
                          (item, index) => {
                            return (
                              <React.Fragment
                                key={`google-property-list${index}`}
                              >
                                <ListItemButton
                                  selected={
                                    selectedGoogleProperty?.property_id ===
                                    item?.name
                                  }
                                  sx={{
                                    "&:hover": {
                                      backgroundColor: "rgba(240, 242, 255, 1)",
                                    },
                                  }}
                                  onClick={() =>
                                    handleGoogleAnaltyicAccountId(
                                      "Property",
                                      item
                                    )
                                  }
                                >
                                  <ListItemText
                                    primary={item?.displayName}
                                    secondary={item?.name}
                                    sx={{
                                      "& .MuiListItemText-primary": {
                                        fontSize: "1.2em",
                                        color: "#365486",
                                        fontWeight: "600",
                                      },
                                      "& .MuiListItemText-secondary": {
                                        fontSize: "1em",
                                        color: "black",
                                      },
                                    }}
                                  />
                                </ListItemButton>
                                <Divider />
                              </React.Fragment>
                            );
                          }
                        )}
                      </List>
                    )}
                  </Grid>
                </Grid>
                {Error && (
                  <span className="msg-error w-100 d-flex justify-content-center">
                    {Error}
                  </span>
                )}
                <Divider />
                <Box className="pt-2 justify-content-center d-flex">
                  <Button
                    variant="outlined"
                    sx={{
                      color: "#FFB032",
                      borderColor: "#FFB032",
                      "&:hover": {
                        color: "#FFB032",
                        borderColor: "#FFB032",
                      },
                    }}
                    onClick={() => handleSubmitGoogleProperty()}
                  >
                    Submit
                  </Button>
                </Box>
              </>
            ) : (
              <div
                className="d-flex align-items-center justify-content-center w-100 h-100"
                style={{
                  minHeight: "180px",
                  flexDirection: "column",
                  padding: "0 40px",
                }}
              >
                <p className="mb-0 msg-error">
                  There is no Google Property added in this account.
                </p>
                <p className="mb-0 msg-error">Please check your account.</p>
              </div>
            )}
          </Box>
        </Box>
      </PopOver>
    </>
  );
};

export default GoogleAuth;
