import React, { useEffect, useState } from "react";
import { Link, useLocation } from "react-router-dom";
import countryList from "../../../../Mycomponent/dataFiles/country";
import { DottLoader } from "../../../../Mycomponent/share/loader";
import CustomTable from "../../../../Mycomponent/share/customTable";
import { GoDash } from "react-icons/go";
import { Box, Button } from "@mui/material";
import { CSVLink } from "react-csv";

const RankTrackerTooltable = () => {
  const [isLoading, setisLoading] = useState(false);
  const [page, setPage] = React.useState(1);

  useEffect(() => {
    setisLoading(true);
    setTimeout(() => setisLoading(false), 1000);
  }, [0]);

  const { state } = useLocation();

  const HandleGetLocation = (code) => {
    const ReturnData = countryList?.filter((Elem) => {
      if (Elem.location_code === code) {
        return Elem;
      }
    });
    return ReturnData[0]?.location_name;
  };

  const tableHeader = [
    {
      label: "USERS",
      key: "users",
      minWidth: 170,
      align: "left",
      width: "25%",
    },
    {
      label: "KEYWORD",
      key: "keyword",
      minWidth: 170,
      width: "25%",
      align: "left",
    },
    {
      label: "LOCATION",
      key: "location",
      minWidth: 170,
      width: "25%",
      align: "center",
    },
    {
      label: "DOMAIN",
      key: "domain",
      minWidth: 170,
      width: "25%",
      align: "center",
    },
    {
      label: "Rank",
      key: "rank",
      minWidth: 170,
      width: "25%",
      align: "center",
    },
    {
      label: "IP Address",
      key: "ip_address",
      minWidth: 170,
      width: "25%",
      align: "center",
    },
  ];

  function createData(users, keyword, location, domain, rank, ip_address) {
    return {
      users,
      keyword,
      location,
      domain,
      rank,
      ip_address,
    };
  }

  const rows = state?.map((item) => {
    return createData(
      item?.user,
      item?.keyword,
      HandleGetLocation(item?.location_code),
      item?.domain,
      item?.rank || <GoDash />,
      item?.ip || <GoDash />
    );
  });

  const loadCSVData = () => {
    if (state?.length === 0) return [];
    return state?.reverse()?.map((item) => {
      const obj = {
        user: item?.user,
        keyword: item?.keyword,
        location: HandleGetLocation(item?.location_code),
        domain: item?.domain,
        rank: item?.rank || 0,
        ip_address: item?.ip || 0,
      };
      return obj;
    });
  };

  return (
    <>
      <Link to={-1} style={{ display: "inline-block" }}>
        <button className="AddListName d-flex align-items-center">
          <i
            className="fa-solid fa-backward"
            style={{ paddingRight: "10px" }}
          ></i>
          <p className="m-0 p-0">Back</p>
        </button>
      </Link>
      <Box className="mb-2 d-flex justify-content-end">
        <CSVLink data={loadCSVData()} filename={"rank_tracker_tool_usage.csv"}>
          <Button className="btn_1">Download CSV</Button>
        </CSVLink>
      </Box>
      {isLoading ? (
        <DottLoader />
      ) : (
        <CustomTable
          tableHeader={tableHeader}
          rows={rows}
          page={page}
          setPage={setPage}
        />
      )}
    </>
  );
};

export default RankTrackerTooltable;
