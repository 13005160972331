import React, { useEffect, useState } from "react";

const useDeviceSize = () => {
  const isSSR = typeof window === "undefined";

  const [windowSize, setWindowSize] = useState(
    isSSR ? 1024 : window.innerWidth
  );

  const isDesktop = windowSize >= 1024;
  const isMobile = windowSize < 600;
  const isTab = windowSize >= 768 && windowSize < 1024;

  const handleResize = () => {
    setWindowSize(window.innerWidth);
  };

  useEffect(() => {
    if (!isSSR) {
      window.addEventListener("resize", handleResize);
      // setWindowSize(window.innerWidth);
      return () => {
        window.removeEventListener("resize", handleResize);
      };
    }
  }, [isSSR]);

  return { isDesktop, isMobile, isTab, windowSize };
};

export default useDeviceSize;
