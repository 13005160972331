import React from "react";
import "./component.scss";

function HeadingDesign({ children }) {
  return (
    <span className="header-design">
      {children}
      <span className="design"></span>
    </span>
  );
}

export default HeadingDesign;
