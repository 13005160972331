import axios from "axios";
import React, { useEffect, useState } from "react";
import {
  DIGITAL_AGENCY_DELETE,
  DIGITAL_AGENCY_GET,
  DIGITAL_UPDATE_STATUS,
} from "../../../Affiliate/utils/constant";
import "../../css/Digital.scss";
import { AiOutlineDelete } from "react-icons/ai";
import { FaCircleCheck } from "react-icons/fa6";
import AlertPopUp from "../../../Mycomponent/share/components/alert.component";
import { useNavigate } from "react-router-dom";
import Papa from "papaparse";
import { IoMdCloseCircle } from "react-icons/io";
import PopOver from "../../../Mycomponent/share/components/popover.component";
import { POST_DIGITAL_AGENCIES } from "../../../services/constants";
import sampleCSV from "../../assets/Data/sample_csv.csv";
import { Box, Button } from "@mui/material";
import CustomTable from "../../../Mycomponent/share/customTable";
import { DottLoader1 } from "../../../Mycomponent/share/loader";

export const Digital_agency = () => {
  const nav = useNavigate();
  const [allAgencyData, setAllAgencyData] = useState([]);
  const [Refetch, setRefetch] = useState(0);
  const [page, setPage] = React.useState(1);
  const [csvLoader, setCSVLOader] = React.useState(false);
  const [Alert, setAlert] = useState({
    type: "",
    message: "",
    show: false,
  });
  const [agencyCSVFile, setAgencyCSVFile] = useState(false);
  const [selectedFile, setSelectedFile] = useState(null);
  const [registerAllAgency, setRegisterAllAgency] = useState([]);
  const [agencyLoader, setAgencyLoader] = useState(true);

  const HandleUpdate = (data) => {
    const PAYLOAD = {
      ...data,
      show: true,
    };

    axios.put(DIGITAL_UPDATE_STATUS(data?.id), PAYLOAD).then((res) => {
      setRefetch(Refetch + 1);
      setAlert({
        type: "success",
        message: "Approved ",
        show: true,
      });
    });
  };

  useEffect(() => {
    axios
      .get("https://app.rankspro.io/api/user/digiagenciesget/")
      .then((response) => {
        if (response) {
          setAllAgencyData(response?.data);
          setAgencyLoader(false);
        }
      })
      .catch(() => {
        setAgencyLoader(false);
      });
  }, [Refetch]);

  //   DELETE
  const Hanlde_DLETE = (id) => {
    axios.delete(DIGITAL_AGENCY_DELETE(id)).then((res) => {
      setRefetch(Refetch + 1);
      setAlert({
        type: "success",
        message: "Deleted ",
        show: true,
      });
    });
  };

  const handleContactSmtp = (id) => {
    localStorage.setItem("ACCESS_ID_FOR_CONTACT", id);
    nav("/admin/contact-agency", { state: id });
  };

  const HandleSendLin = (id) => {
    localStorage.setItem("ACCESS_ID_FROM_PANEL", id);
    nav("../details", { state: id });
  };

  const HandleSendReview = (id) => {
    localStorage.setItem("ACCESS_REVIEW_FROM_PANEL", id);
    nav("../reviews", { state: id });
  };

  function extractBudgetValuesWithSign(budgetString) {
    const regex = /\$\d+(?:,\d+)*\+|\$\d+(?:,\d+)*|\bAny\b/g;
    const matches = budgetString.match(regex);
    if (!matches) return [];
    return matches;
  }

  function parseObjectArray(objArray) {
    try {
      return objArray.map((obj, index) => {
        const review = obj?.review ? obj.review.split(",") : [];
        const rating = obj?.rating ? obj?.rating.split(",") : [];
        const review_email = obj?.review_email
          ? obj?.review_email.split(",")
          : [];

        const location = obj?.location ? obj.location.split(",") : [];
        const street_address = obj?.street_address
          ? obj?.street_address.split(",")
          : [];
        const postal_code = obj?.postal_code ? obj?.postal_code.split(",") : [];
        const phone_number = obj?.phone_number
          ? obj.phone_number.split(",")
          : [];

        const parsedObj = {
          email: obj.email || "",
          name: obj.name || "",
          logo: obj.logo || "",
          short_description: obj.short_description || "",
          detailed_description: obj.detailed_description || "",
          tag_line: obj.tag_line || "",
          founded: obj.founded || "",
          employee: obj.employee || "",
          services: obj.services ? obj.services.split(",") : [],
          industry: obj.industry ? obj.industry.split(",") : [],
          geographic_focus: obj.geographic_focus
            ? obj.geographic_focus.split(",")
            : [],
          languages: obj.languages ? obj.languages.split(",") : [],
          // budget: obj.budget ? obj.budget.split(",") : [],
          budget: obj.budget ? extractBudgetValuesWithSign(obj.budget) : [],
          pricing_model: obj.pricing_model ? obj.pricing_model.split(",") : [],
          client_business_size: obj.client_business_size
            ? obj.client_business_size.split(",")
            : [],
          tech_stack: obj.tech_stack ? obj.tech_stack : [],
          website: obj.website ? obj?.website : "",
          location: obj.location
            ? location?.map((item, index) => ({
                location: item || "",
                streetAddress: street_address?.[index] || "",
                postalCode: postal_code?.[index] || "",
                PhoneNumber: phone_number?.[index] || "",
              }))
            : [],
          notify: obj.notify ? obj.notify.split(",") : [],
          show: obj.show === "TRUE" ? true : false,
          review: obj?.review
            ? review?.map((item, index) => ({
                approve: obj.review_approve === "TRUE" ? true : false,
                review: item || "",
                rating: rating?.[index] || 0,
                email: review_email?.[index] || "",
              }))
            : [],
          categories: obj.categories ? obj.categories.split(",") : [],
        };
        return parsedObj;
      });
    } catch (error) {
      return [];
    }
  }

  const handleFileChange = (event) => {
    const file = event.target.files[0];
    setSelectedFile(file);
    Papa.parse(file, {
      header: true,
      complete: (results) => {
        const keywordSet = results.data;
        setRegisterAllAgency(parseObjectArray(keywordSet));
      },
    });
  };

  const handleDownloadSample = () => {
    fetch(sampleCSV)
      .then((response) => {
        if (!response.ok) {
          throw new Error("Network response was not ok");
        }
        return response.blob();
      })
      .then((blob) => {
        const url = URL.createObjectURL(blob);
        const link = document.createElement("a");
        link.href = url;
        link.download = "sample_csv";
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
        URL.revokeObjectURL(url);
      })
      .catch((error) => {
        console.error("There was a problem with your fetch operation:", error);
      });
    setSelectedFile(null);
  };

  const handleUpload = async () => {
    setAgencyCSVFile(false);
    setCSVLOader(true);
    try {
      const promises = registerAllAgency?.map(async (agency) => {
        const item = {
          email: agency.email,
          name: agency?.name,
          logo: agency?.logo,
          sh_desc: agency.short_description,
          de_desc: agency.detailed_description,
          tag: agency.tag_line,
          founded: agency.founded,
          employee: agency.employee,
          services: JSON.stringify(agency.services),
          industry: JSON.stringify(agency.industry),
          geographic_focus: JSON.stringify(agency.geographic_focus),
          languages: JSON.stringify(agency.languages),
          budget: JSON.stringify(agency.budget),
          pricing_model: JSON.stringify(agency.pricing_model),
          client_business_size: JSON.stringify(agency.client_business_size),
          tech_stack: JSON.stringify(agency.tech_stack),
          website: JSON.stringify([agency.website]),
          location: JSON.stringify(agency.location),
          notify: JSON.stringify(agency.notify),
          show: agency.show,
          review: JSON.stringify(agency.review),
          categories: JSON.stringify(agency.categories),
        };
        const getDuplicateAgency =
          allAgencyData?.filter((item) => {
            return item.name == agency?.name;
          }) || [];
        if (getDuplicateAgency?.length === 0 && agency?.name) {
          return axios
            .post("https://app.rankspro.io/api/user/digiagencies/", {
              data: JSON.stringify([item]),
            })
            .then(() => {});
        }
      });
      // Wait for all promises to resolve
      await Promise.all(promises).then(() => {
        setAlert({
          type: "success",
          message: "Agency Register Successfully",
          show: true,
        });
        setSelectedFile(null);
        setAgencyCSVFile(false);
        setCSVLOader(false);
      });
    } catch (error) {
      setAlert({
        type: "error",
        message: "Agency Register failed",
        show: true,
      });
      setSelectedFile(null);
      setCSVLOader(false);
    }
  };

  const handleDrop = (event) => {
    event.preventDefault();
    const file = event.dataTransfer.files[0];
    Papa.parse(file, {
      header: true,
      complete: (results) => {
        const keywordSet = results.data;
        setRegisterAllAgency(parseObjectArray(keywordSet));
      },
    });
  };

  const handleDragOver = (event) => {
    event.preventDefault();
  };

  const tableHeader = [
    {
      label: "EMAIL",
      key: "email",
      minWidth: 230,
      align: "left",
      width: "25%",
      maxWidth: "400px",
    },
    {
      label: "NAME",
      key: "name",
      minWidth: 150,
      width: "25%",
      align: "left",
    },
    {
      label: "WEBSITE",
      key: "website",
      minWidth: 150,
      align: "center",
      width: "25%",
    },
    {
      label: "DETAIL",
      key: "detail",
      minWidth: 150,
      align: "center",
      width: "25%",
    },
    {
      label: "REVIEW",
      key: "review",
      minWidth: 150,
      align: "center",
      width: "25%",
    },
    {
      label: "CONTACT",
      key: "contact",
      minWidth: 150,
      align: "center",
      width: "25%",
    },
    {
      label: "ACTIONS",
      key: "action",
      minWidth: 150,
      align: "center",
      width: "25%",
    },
  ];

  function createData(
    id,
    email,
    name,
    website,
    detail,
    review,
    contact,
    action
  ) {
    return {
      id,
      email,
      name,
      website,
      detail,
      review,
      contact,
      action,
    };
  }

  const rows = allAgencyData?.map((item, index) => {
    return createData(
      index + 1,
      item?.email,
      item?.name,
      item?.website?.[0],
      <button className="m-0" onClick={() => HandleSendLin(item.name)}>
        View
      </button>,
      <button className="m-0" onClick={() => HandleSendReview(item.id)}>
        View
      </button>,
      <button className="m-0" onClick={() => handleContactSmtp(item.id)}>
        contact
      </button>,
      <div className="BUTTONS_DIGITAL">
        {item?.show === false && (
          <abbr title="Approve">
            <button onClick={() => HandleUpdate(item)} className="me-2 green">
              <span>
                {" "}
                <FaCircleCheck />
              </span>
            </button>
          </abbr>
        )}
        <abbr title="Delete">
          <button
            className="me-2"
            style={{ backgroundColor: "rgb(162, 68, 68)" }}
            onClick={() => Hanlde_DLETE(item.id)}
          >
            <span>
              <AiOutlineDelete />
            </span>
          </button>
        </abbr>
      </div>
    );
  });

  return (
    <main>
      <AlertPopUp config={Alert} handleClose={setAlert} />
      {agencyCSVFile && (
        <PopOver anchorEl={agencyCSVFile}>
          <div
            className="ApproveModal register-agency"
            style={{ minHeight: "290px" }}
          >
            <IoMdCloseCircle
              onClick={() => {
                setSelectedFile(null);
                setAgencyCSVFile(false);
                setCSVLOader(false);
              }}
              style={{
                width: "30px",
                height: "30px",
              }}
            />
            <h1 style={{ fontSize: "1.7em" }}>
              {csvLoader ? "CSV file Uploading" : "Upload CSV"}
            </h1>
            {csvLoader ? (
              <Box
                className="d-flex justify-content-center align-items-center"
                sx={{ height: "200px" }}
              >
                <DottLoader1 />
              </Box>
            ) : (
              <>
                <div
                  onDrop={handleDrop}
                  onDragOver={handleDragOver}
                  style={{
                    border: "2px dashed #ccc",
                    borderRadius: "4px",
                    padding: "20px",
                    textAlign: "center",
                    cursor: "pointer",
                    position: "relative",
                    height: "100px",
                  }}
                  className="mt-3"
                >
                  <label
                    htmlFor="file-input"
                    style={{
                      position: "absolute",
                      top: 0,
                      left: 0,
                      width: "100%",
                      height: "100%",
                      flexDirection: "column",
                    }}
                    className="d-flex align-items-center justify-content-center"
                  >
                    {/* <MdCloudUpload /> */}
                    <p className="mb-0">
                      Drag and drop a file here or click to select a file
                    </p>
                  </label>
                  <input
                    id="file-input"
                    name="file-input"
                    type="file"
                    accept=".csv"
                    onChange={handleFileChange}
                    style={{ display: "none" }}
                  />
                </div>
                <div className="m-auto my-4" style={{ width: "90%" }}>
                  {selectedFile && <p>Selected File: {selectedFile.name}</p>}
                </div>
                <div className="buttons w-100">
                  <button onClick={() => handleDownloadSample()}>
                    Download Sample
                  </button>
                  <button onClick={() => handleUpload()}>Submit</button>
                </div>
              </>
            )}
          </div>
        </PopOver>
      )}
      <CustomTable
        showSearchBar={true}
        tableTitle="Digital Agency"
        downloadCSVFile={
          <Button
            className="btn_1 ms-2"
            onClick={() => {
              setAgencyCSVFile(true);
            }}
          >
            Import From CSV
          </Button>
        }
        rows={rows}
        dataLoader={agencyLoader}
        tableHeader={tableHeader}
        page={page}
        setPage={setPage}
      />
    </main>
  );
};
