import React from "react";
import {
  Page,
  Text,
  View,
  Document,
  StyleSheet,
  Image,
} from "@react-pdf/renderer";
import barImg from "../../Assets/overview-img/bar.png";
import defaultLogo from "../../Assets/seoimg/logo.png";
import { LuCopyright } from "react-icons/lu";
import { getCurrentYear } from "../helper-js/current-year";

const styles = StyleSheet.create({
  page: {
    flexDirection: "column",
    backgroundColor: "#F4F5FA",
    paddingTop: 35,
    paddingBottom: 65,
    paddingHorizontal: 20,
  },
  header: {
    color: "#365486",
    fontWeight: "bold",
  },
  tableContainer: {
    marginBottom: 20,
  },
  table: {
    width: "100%",
    borderStyle: "solid",
    borderWidth: 1,
    display: "flex", // Use flex to create a flexible row layout
    flexDirection: "column", // Each table row is a flex column
  },
  tableRow: {
    flexDirection: "row", // Each cell is a flex item in a row
    borderBottomWidth: 1,
    borderColor: "#000",
    alignItems: "center",
  },
  tableCell: {
    flex: 1, // Each cell should take equal space within the row
    padding: 5,
    borderRightWidth: 1,
    borderColor: "#000",
    justifyContent: "center",
    minHeight: 40,
    alignItems: "center",
    flexDirection: "row",
  },
  cardContainer: {
    flexDirection: "row",
    flexWrap: "wrap", // Enable flex wrapping
    justifyContent: "space-between", // Arrange cards with equal spacing
    marginTop: 10,
  },
  card: {
    flexDirection: "row",
    justifyContent: "space-between",
    width: 180,
    backgroundColor: "#FFFFFF",
    padding: 10,
    borderRadius: 5,
    marginBottom: 20,
  },
  image: {
    width: 30,
    height: 40,
  },
  title: {
    fontSize: 12,
    fontWeight: "bold",
    marginBottom: 5,
    color: "#6c757d",
  },
  description: {
    fontSize: 16,
  },
  imageContainer: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  view_button: {
    borderWidth: 1,
    borderColor: "#365486",
    padding: 5,
    borderRadius: 7,
    color: "#365486",
    fontSize: 12,
  },
  table_cell: {
    fontSize: 12,
    color: "#6c757d",
    textAlign: "center",
  },
  table_header: {
    fontSize: 12,
    textAlign: "center",
  },
  footer: {
    fontSize: 12,
    textAlign: "center",
    color: "grey",
    position: "absolute",
    bottom: 20,
    left: 0,
    right: 0,
  },
  pageNumber: {
    position: "absolute",
    fontSize: 12,
    bottom: 45,
    left: 0,
    right: 0,
    textAlign: "center",
    color: "grey",
  },
});

function KeywordPdf({ data, Src, newData }) {
  const card_data = [
    {
      title: "SEARCH VOLUME",
      discription:
        data?.seed_keyword_data?.keyword_info?.monthly_searches?.[0]
          ?.search_volume || 0,
    },
    {
      title: "SEO DIFFICULTY",
      discription:
        data?.seed_keyword_data?.keyword_properties?.keyword_difficulty || 0,
    },
    {
      title: "COST PER CLICK",
      discription: "$" + data?.seed_keyword_data?.keyword_info?.cpc || 0,
    },
  ];

  const diffStyle = (val) => {
    if (val <= 25) {
      return {
        color: "black",
        backgroundColor: "rgb(159, 191, 67)",
      };
    } else if (25 < val && val <= 50) {
      return {
        color: "black",
        backgroundColor: "rgb(245, 166, 35)",
      };
    } else if (50 < val && val <= 75) {
      return {
        color: "black",
        backgroundColor: "rgb(245, 142, 35)",
      };
    } else {
      return {
        color: "black",
        backgroundColor: "rgb(245, 117, 35)",
      };
    }
  };

  const months = [
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "May",
    "Jun",
    "Jul",
    "Aug",
    "Sep",
    "Oct",
    "Nov",
    "Dec",
  ];

  let chart_data = [];
  let labels = [];

  data?.seed_keyword_data?.keyword_info.monthly_searches?.map((res) => {
    chart_data.push(res.search_volume);
    labels.push(`${months[res.month - 1]} ${res.year}`);
  });

  const reversedChartData = chart_data.slice().reverse();
  const reversedChartLabels = labels.slice().reverse();

  return (
    <>
      <Document>
        <Page size="A4" style={styles.page}>
          <View
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: newData?.reset
                ? "flex-start"
                : newData?.logo.position,
              marginBottom: 20,
            }}
          >
            <Image
              src={newData?.reset ? defaultLogo : newData?.logo?.src}
              style={{
                width: 150,
                height: 50,
                objectFit: "contain",
              }}
            />
          </View>
          <Text style={{ marginBottom: 15, textAlign: "center" }}>
            Keyword Research Report
          </Text>
          <View style={{ flexDirection: "row" }}>
            <Text>Keyword Research For : </Text>
            <Text style={{ color: "#365486" }}>{data?.seed_keyword}</Text>
          </View>
          <View style={styles.cardContainer}>
            {card_data?.map((item, index) => {
              return (
                <View style={styles.card} key={`index-${index}`}>
                  <View>
                    <Text style={styles.title}>{item?.title}</Text>
                    <Text style={styles.description}>{item?.discription}</Text>
                  </View>
                  <View style={styles.imageContainer}>
                    <Image src={barImg} style={styles.image} />
                  </View>
                </View>
              );
            })}
          </View>
          <View>
            <Text>Search Volume :</Text>
            <Image
              src={Src}
              style={{
                marginVertical: 20,
                marginHorizontal: 100,
                height: 50,
              }}
            />
            <View style={{ marginBottom: 20 }}>
              <View style={styles.table}>
                <View style={styles.tableRow}>
                  {reversedChartLabels?.map((months, index) => {
                    return (
                      <View style={styles.tableCell} key={`months-${index}`}>
                        <Text style={styles.table_header}>{months}</Text>
                      </View>
                    );
                  })}
                </View>
                <View style={styles.tableRow}>
                  {reversedChartData?.map((val, index) => {
                    return (
                      <View style={styles.tableCell} key={index}>
                        <Text style={styles.table_cell}>{val}</Text>
                      </View>
                    );
                  })}
                </View>
              </View>
            </View>
          </View>
          <Text style={{ marginTop: 10, marginBottom: 10 }}>
            Keywords Ideas :
          </Text>
          <View style={styles.tableContainer}>
            <View style={styles.table}>
              <View style={styles.tableRow}>
                <View style={styles.tableCell}>
                  <Text style={styles.table_header}>KEYWORDS</Text>
                </View>
                <View style={styles.tableCell}>
                  <Text style={styles.table_header}>VOLUME</Text>
                </View>
                <View style={styles.tableCell}>
                  <Text style={styles.table_header}>CPC</Text>
                </View>
                <View style={styles.tableCell}>
                  <Text style={styles.table_header}>SD</Text>
                </View>
              </View>
              {data?.items?.map((row, index) => (
                <View key={index} style={styles.tableRow}>
                  <View style={styles.tableCell}>
                    <Text style={styles.table_cell}>{row?.keyword}</Text>
                  </View>
                  <View style={styles.tableCell}>
                    <Text style={styles.table_cell}>
                      {row?.keyword_info?.search_volume}
                    </Text>
                  </View>
                  <View style={styles.tableCell}>
                    <Text style={styles.table_cell}>
                      ${row?.keyword_info?.cpc}
                    </Text>
                  </View>
                  <View
                    style={{
                      ...styles.tableCell,
                      ...diffStyle(row?.keyword_properties?.keyword_difficulty),
                    }}
                  >
                    <Text style={styles.table_cell}>
                      {row?.keyword_properties?.keyword_difficulty}
                    </Text>
                  </View>
                </View>
              ))}
            </View>
          </View>
          <Text style={styles.footer} fixed>
            <LuCopyright className="me-1 heading-medium" />
            {newData?.reset
              ? `Copyright ${getCurrentYear} eSearch Logix | Prepared using RanksPro.io`
              : newData?.footer}
          </Text>
        </Page>
      </Document>
    </>
  );
}

export default KeywordPdf;
