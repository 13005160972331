import React from "react";
import "../../css/login.css";
import logi from "../../Assets/loginSignup.png";
import logo from "../../Assets/seoimg/logo.png";
import { Link, json, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
  INVITE_AUTH,
  POST_MAILFORNEWUSER_REGISTER,
  regester_withAPi,
  regester_withOTP,
  GET_CLICK_COUNT,
  UPDATE_CLICK_COUNT,
} from "../../../services/constants";
import axios from "axios";
import { useState } from "react";
import { useEffect } from "react";
import GetCustomer from "../../../confrigration/getCustomer";
import { ReCaptcha } from "../../share/components/googleRecaptacha";
import Footer from "../../share/footer";
import "./login.scss";
import { validatePassword } from "../../../helperJs/helper";

const ReferralUser = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const subuserinvitelength = useSelector((state) => state.subuserinvitelength);
  const subuserinvitelimit = useSelector((state) => state.subuserinvitelimit);

  // state vaiables
  const [name, setName] = useState("");
  const [password2, setPassword2] = useState("");
  const [email, setEmail] = useState("");
  const [SpecialCharError, setSpecialCharError] = useState(false);
  const [password, setPassword] = useState("");
  const [OTP, setOTP] = useState(false);
  const [OTPVail, setOtpVail] = useState(0);
  const [byorganization, setByOrganization] = useState(false);
  const [main_user_mail, setMain_user_mail] = useState(null);
  const [mydata, setMydata] = useState(false);

  //invite by organization handler
  const urlSearchParams = new URLSearchParams(window.location.search);
  const main_userMail = urlSearchParams.get("email");
  const user_aff_id = urlSearchParams.get("aff_id");

  const fatch_InviteAuth = () => {
    // invite SUBUSERINVITELENGTH
    axios.get(INVITE_AUTH(main_userMail)).then((res) => {
      dispatch({ type: "SUBUSERINVITELENGTH", payload: res.data });
      dispatch({ type: "NOTLOADING" });
    });
  };

  useEffect(() => {
    var currentDate = new Date();
    var currentYear = currentDate.getFullYear();
    var currentMonth = currentDate.toLocaleString("default", {
      month: "short",
    });

    var clickValue = 1;
    var currentMonthArray = [
      {
        year: currentYear,
        month: currentMonth,
        click: clickValue,
      },
    ];

    if (user_aff_id) {
      axios.get(GET_CLICK_COUNT(user_aff_id)).then((response) => {
        if (response?.data === 0 || response?.data?.length === 0) {
          const config = {
            click: JSON.stringify(currentMonthArray),
            aff_id: user_aff_id,
          };
          axios.post(UPDATE_CLICK_COUNT(config));
        } else {
          var array = response?.data;
          var targetYear = currentYear;
          var targetMonth = currentMonth;
          var targetIndex = array.findIndex(function (entry) {
            return entry.year === targetYear && entry.month === targetMonth;
          });
          var newClickValue = array[targetIndex].click + 1;
          if (targetIndex !== -1) {
            array[targetIndex].click = newClickValue;
          } else {
            array.push({
              year: targetYear,
              month: targetMonth,
              click: 1,
            });
          }
          const config = {
            click: JSON.stringify(array),
            aff_id: user_aff_id,
          };
          axios.post(UPDATE_CLICK_COUNT(config));
        }
      });
    }
  }, [user_aff_id]);

  useEffect(() => {
    if (main_userMail) {
      fatch_InviteAuth();
      setByOrganization(true);
      setMain_user_mail(main_userMail);
    }
  }, [main_userMail]);

  const hasSpecialChars = /[!@#$%^&*()_+{}\[\]:;<>,.?~\\/-]/;

  useEffect(() => {
    setSpecialCharError(hasSpecialChars.test(name));
  }, [name]);

  const registerHandler = (e) => {
    e.preventDefault();

    if (email === "" || password === "" || password2 === "" || name === "") {
      setMydata("Please fill all details");
      return;
    }
    const passwordError = validatePassword(password);
    if (passwordError) {
      setMydata(validatePassword(password));
      return;
    }
    if (password.length > 32) {
      setMydata("Password is too long only 32 characters allowed");
    } else if (password !== password2) {
      setMydata("Confirm Password did not match");
    } // Individual User
    else if (OTP !== false && byorganization === false) {
      if (Number(OTPVail) === OTP) {
        setMydata(false);
        let item = {
          user_type: "individual",
          main_user_mail: email,
          email: email,
          password: password,
          password2: password2,
          name: name,
          tc: "True",
          affiliate_id: user_aff_id || "0",
        };

        axios
          .post(regester_withAPi(), item)
          .then((res) => {
            setOTP(false);
            setMydata(false);
            navigate("/login");

            axios
              .get("https://api64.ipify.org/")
              .then((response) => {
                const newuserregister = {
                  email: email,
                  name: name,
                  Ip: response.data,
                };
                // for sending mail for admin
                axios.post(POST_MAILFORNEWUSER_REGISTER(), newuserregister);
              })
              .catch(() => {
                const newuserregister = {
                  email: email,
                  name: name,
                  Ip: "No Ip Found",
                };
                // for sending mail for admin
                axios.post(POST_MAILFORNEWUSER_REGISTER(), newuserregister);
              });
          })
          .catch((res) => {
            // setMydata(res.response.data.email);
            setMydata("User with this Email already exists");
          });
      } else {
        setMydata("Otp did not match");
      }
    } // Organization User
    else if (OTP !== false && byorganization === true) {
      if (Number(OTPVail) === OTP) {
        if (subuserinvitelength.length >= subuserinvitelimit) {
          setMydata("Invite limit exceeded");
        } else {
          setMydata(false);

          let item = {
            user_type: "organization",
            main_user_mail: main_user_mail,
            email: email,
            password: password,
            password2: password2,
            name: name,
            tc: "True",
            affiliate_id: user_aff_id || "0",
          };

          axios
            .post(regester_withAPi(), item)
            .then((res) => {
              setOTP(false);
              setMydata(false);
              navigate("/login");

              axios
                .get("https://api64.ipify.org/")
                .then((response) => {
                  const newuserregister = {
                    email: email,
                    name: name,
                    Ip: response.data,
                  };
                  // for sending mail for admin
                  axios.post(POST_MAILFORNEWUSER_REGISTER(), newuserregister);
                })
                .catch(() => {
                  const newuserregister = {
                    email: email,
                    name: name,
                    Ip: "No Ip Found",
                  };
                  // for sending mail for admin
                  axios.post(POST_MAILFORNEWUSER_REGISTER(), newuserregister);
                });
            })
            .catch((error) => {
              // setMydata(res.response.data.email);
              setMydata("User with this Email already exists");
            });
        }
      } else {
        setMydata("Otp did not match");
      }
    } else {
      setOTP(true);
      let item = {
        email: email,
      };
      axios.post(regester_withOTP(), item).then((res) => {
        setOTP(res.data.otp);
      });
    }
  };

  return (
    <>
      {main_user_mail ? <GetCustomer email={main_user_mail} /> : false}
      <div className="loginBox">
        <div className="row">
          <div
            className="col-md-6 col-12 d-none d-md-block d-lg-block"
            style={{ background: "#365486" }}
          >
            <div className="loginLeft">
              <div className="imgLogin">
                <img className="lg_pg-image" src={logi} alt="login img"></img>
              </div>
            </div>
          </div>
          <div className="col-md-6 col-12">
            <div className="loginRight ">
              <div className="FormBox RegisterForm">
                <img className="Loginlogo" src={logo} alt="login img"></img>
                <h1 className="text-left mb-4">
                  {OTP ? "Please verify..." : "Register"}
                </h1>
                <form onChange={() => setMydata(false)}>
                  <div className="formLogin">
                    {/* <div className="reg-anim pt-2"> */}
                    {OTP ? (
                      <div className="optic-scroll">
                        {/* <div className="desg"> </div> */}
                        <p className="mb-1" style={{ color: "#828282" }}>
                          Enter the verification code sent to your email.
                        </p>
                        <input
                          type="number"
                          placeholder="Enter OTP"
                          onChange={(e) => setOtpVail(e.target.value)}
                        ></input>
                        <p className="vl-msd-line-login">{mydata} </p>
                      </div>
                    ) : (
                      <div className="RegisterInputBox">
                        <label id="lb">Email*</label>
                        <input
                          type="email"
                          placeholder="Email"
                          onChange={(e) => setEmail(e.target.value)}
                        ></input>

                        <label id="lb">Name*</label>
                        <input
                          type="text"
                          placeholder="Name"
                          onChange={(e) => {
                            setName(e.target.value);
                          }}
                        ></input>
                        {
                          <span className="specialError">
                            {SpecialCharError &&
                              "Special Characters Not Allowed (e.g !@#$%^&*()_+{}[]:;<>,.?~\\/-)"}
                          </span>
                        }
                        <label id="lb">Password*</label>
                        <input
                          type="password"
                          placeholder="Password"
                          onChange={(e) => setPassword(e.target.value)}
                          autoComplete="false"
                        ></input>

                        <label id="lb">Confirm Password*</label>
                        <input
                          type="password"
                          placeholder=" Confirm Password"
                          onChange={(e) => setPassword2(e.target.value)}
                          autoComplete="false"
                        ></input>

                        <p className="vl-msd-line-login">
                          {mydata ? mydata : false}{" "}
                        </p>
                      </div>
                    )}
                    {/* </div> */}

                    <div>
                      <button
                        type="submit"
                        className="btn_1  w-100 font-light-bold ResgisterSubmit"
                        onClick={registerHandler}
                      >
                        {OTP ? "Verify Now" : "Submit"}
                      </button>
                      <h6 className="rs-now lastLine font-regular">
                        Already have an account ?
                        <Link
                          to="/login"
                          className="font-light-bold text-decoration-underline"
                        >
                          {" "}
                          Login Now{" "}
                        </Link>
                      </h6>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
        <Footer />
      </div>
      <ReCaptcha />
    </>
  );
};

export default ReferralUser;
