import React from "react";
import "./Footer.scss";
import LOGO from "../../../assets/images/Global/logo.png";
import FB from "../../../assets/images/Global/fb.png";
import INSTA from "../../../assets/images/Global/insta.png";
import TWIT from "../../../assets/images/Global/twit.png";
import LINKDIN from "../../../assets/images/Global/link.png";
import { PiPhoneCallLight } from "react-icons/pi";
import { AiOutlineMail } from "react-icons/ai";
import HeadingDesign from "../../../components/heading-design";
import { Link } from "react-router-dom";

export const Footer = () => {
  return (
    <div className="FooterContainerAf">
      <div className="Footer">
        <div className="FootBox">
          <h1>
            Revamp Marketing For
            <HeadingDesign>Income Growth</HeadingDesign>
          </h1>
          <Link to={`../affiliate/signup`}>
            <button>Become An Affiliate</button>
          </Link>
          <p>
            Already a member?{" "}
            <span>
              <Link to={`../affiliate/login`}>Sign in</Link>
            </span>
          </p>
          <Link to={"../terms-&-conditions"} id="showLine">
            Terms and Conditions{" "}
          </Link>
        </div>
        <div className="sec_row">
          <div className="row m-0">
            <div className="col-md-4 col-lg-4">
              <div className="footLog">
                <img src={LOGO} alt="RanksPro.io Logo" />
              </div>
            </div>
            <div className="col-md-4 col-lg-4 d-flex align-items-center justify-content-center">
              <div className="footIcon">
                <p>Find us on social media</p>
                <div className="icons">
                  <a
                    href="https://www.facebook.com/eslrankspro"
                    target="_blank"
                  >
                    <img src={FB} alt="facebook-icon" />
                  </a>
                  <a href="https://x.com/rankspro_io" target="_blank">
                    <img src={TWIT} alt="twitter-icon" />
                  </a>
                  <a
                    href="https://www.instagram.com/rankspro.io/"
                    target="_black"
                  >
                    <img src={INSTA} alt="instagram-icon" />
                  </a>
                  <a
                    href="https://www.linkedin.com/company/rankspro-io"
                    target="_blank"
                  >
                    <img src={LINKDIN} alt="linkedin-icon" />
                  </a>
                </div>
              </div>
            </div>
            <div className="col-md-4 col-lg-4 d-flex align-items-center justify-content-center">
              <div className="footAdd">
                <h3>Get In Touch</h3>
                <ul>
                  <li>
                    <span>
                      <PiPhoneCallLight />
                    </span>
                    +1-(702)-909-2783{" "}
                  </li>
                  <li>
                    <span>
                      <AiOutlineMail />
                    </span>
                    <a href="mailto:hello@rankspro.io">hello@rankspro.io</a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
