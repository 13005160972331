import React, { useState } from "react";
import axios from "axios";

function InviteEmail() {
  const [user, setState] = useState({
    name: "",
    email: "",
  });
  const [Error, setError] = useState("");

  const handleSubmit = () => {
    let newCodes = [];
    let precode = "";
    const characters =
      "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
    for (let j = 0; j < 12; j++) {
      const randomIndex = Math.floor(Math.random() * characters.length);
      precode += characters[randomIndex];
      newCodes.push(precode);
    }
    if (user.name == "") return setError("Enter Name");
    if (user.email == "") return setError("Enter E-mail");
    const data = {
      email: user.email,
      name: user.name,
      password: newCodes[11],
      password2: newCodes[11],
      tc: "True",
      user_type: "individual",
      main_user_mail: user.email,
      affiliate_id: "0",
    };
    axios
      .post("https://app.rankspro.io/api/user/invuseradmin/", data)
      .then((response) => {
        axios.post(
          `https://app.rankspro.io/api/user/passwordsave/?password=${newCodes[11]}`
        );
        setState({ name: "", email: "" });
      })
      .catch((error) => {
        setError("User already Registered");
      });
  };

  const handleInputValue = (target) => {
    setError("");
    var change = {};
    change[target.name] = target.value;
    setState({ ...user, ...change });
  };
  return (
    <>
      <h2 className="table_title heading-large font-bold mb-4">Invite Mail</h2>
      <div
        className="Box"
        style={{ maxWidth: "600px", display: "flex", flexDirection: "column" }}
      >
        {/* <form> */}
        <input
          type="text"
          name="name"
          className="heading-small"
          placeholder="Enter Name"
          value={user.name}
          onChange={(e) => handleInputValue(e.target)}
          style={{ marginBottom: "10px" }}
        />
        <input
          type="email"
          name="email"
          value={user.email}
          className="heading-small"
          placeholder="Enter E-mail"
          onChange={(e) => handleInputValue(e.target)}
          style={{ marginBottom: "10px" }}
        />
        {Error && (
          <span
            className="heading-xs"
            style={{ marginBottom: "10px", color: "red" }}
          >
            {Error}
          </span>
        )}
        <button
          // type="submit"
          className="btn_1"
          onClick={() => handleSubmit()}
        >
          Submit
        </button>
        {/* </form> */}
      </div>
    </>
  );
}

export default InviteEmail;
