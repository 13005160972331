import React from "react";
import JSCharting from "jscharting-react";

const divStyle = {
  maxWidth: "100%",
  maxHeight: "100%",
  height: "300px",
};

const SpeedMeter = React.memo(({ speed }) => {
  function calculatePercentageInRange(timeRange) {
    if (timeRange < 0 || timeRange <= 3) {
      // If time range is less than 0 or from 0 to 3
      return (timeRange / 3) * 30; // Scale the range from 0 to 3 to a percentage from 0 to 30
    } else if (timeRange <= 7) {
      // If time range is from 3 to 7
      return 30 + ((timeRange - 3) / 4) * 40; // Scale the range from 3 to 7 to a percentage from 30 to 70
    } else {
      // If time range is more than 7
      return 70 + ((timeRange - 7) / (100 - 7)) * 30; // Scale the range from 7 to 100 to a percentage from 70 to 100
    }
  }

  const speed_status = speed > 5 ? "Bad" : speed > 3 ? "Good" : "Best";
  const initConfig = {
    debug: false,
    mutable: true,
    type: "gauge ",
    legend_visible: false,
    chartArea_boxVisible: false,
    xAxis: {
      scale: { range: [0, 1], invert: true },
    },
    palette: {
      pointValue: "%yValue",
      ranges: [
        { value: 0, color: "#E95655" },
        { value: 30, color: "#365486" },
        { value: [70, 100], color: "#365486" },
      ],
    },
    yAxis: {
      defaultTick: { padding: 13, enabled: false, label_visible: false },
      customTicks: [30, 70],
      line: {
        width: 15,
        breaks_gap: 0.03,
        color: "smartPalette",
      },
      scale: { range: [0, 100] },
    },
    defaultSeries: {
      opacity: 1,
      shape: {
        label: { align: "center", verticalAlign: "middle" },
      },
    },
    series: [
      {
        type: "marker",
        name: "Score",
        shape_label: {
          text: `${speed} sec<br/> <span style='fontSize: 25'>${speed_status}!</span>`,
          style: { fontSize: 25 },
        },
        defaultPoint: {
          tooltip: `${speed} sec`,
          marker: {
            outline: { width: 10, color: "currentColor" },
            fill: "white",
            type: "circle",
            visible: true,
            size: 25,
          },
        },
        points: [[1, calculatePercentageInRange(speed)]],
      },
    ],
  };
  return (
    <div style={divStyle}>
      <JSCharting options={initConfig} />{" "}
    </div>
  );
});

export default SpeedMeter;
