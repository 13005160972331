import React from "react";
import { Outlet } from "react-router-dom";
import "../../css/addProject.scss";

const AddProject = () => {
  return (
    <main className="add-project-container">
      <Outlet />
    </main>
  );
};

export default AddProject;
