import React, { useState, useEffect } from "react";
// MUI Imports
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import Button from "@mui/material/Button";
import IconButton from "@mui/material/IconButton";
import Grid from "@mui/material/Grid";
import TextField from "@mui/material/TextField";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import crossIcon from "../../../Mycomponent/Assets/rank-tracking-assets/cross.svg";
import { Box } from "@mui/material";

const countries = ["True", "False"];

const AddLimits = ({ SelectedUser, setSelectedUser, handleAddLimits }) => {
  const [addressData, setAddressData] = useState();

  useEffect(() => {
    setAddressData(SelectedUser);
  }, [SelectedUser]);

  return (
    <>
      <Dialog
        open={setSelectedUser?.length != 0}
        scroll="body"
        fullWidth
        maxWidth="md"
        onClose={() => {
          setSelectedUser([]);
        }}
        className="addon-limits-dialog"
      >
        <DialogTitle
          variant="h5"
          className="d-flex gap-2 flex-column text-center pb-3 pb-sm-1 pb-lg-0 px-sm-3"
        >
          {"Addon Plan"}
        </DialogTitle>
        <Box>
          <DialogContent className="pb-0 px-sm-3">
            <IconButton
              onClick={() => setSelectedUser([])}
              className="position-absolute top-0 end-0 mt-2 me-2"
            >
              <img src={crossIcon} alt="cancel" width={15} height={15} />
            </IconButton>
            <Grid container spacing={2}>
              <Grid item xs={12} sm={6}>
                <TextField
                  fullWidth
                  label="Backlinks"
                  name="backlinks"
                  variant="outlined"
                  placeholder="Enter Backlinks Limit"
                  value={addressData?.backlinks}
                  InputLabelProps={{ shrink: true }}
                  onChange={(e) =>
                    setAddressData({
                      ...addressData,
                      backlinks: e.target.value,
                    })
                  }
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  fullWidth
                  label="Domain Overview"
                  name="domain_overview"
                  variant="outlined"
                  placeholder="Enter Domain Overview Limit"
                  value={addressData?.dom_over}
                  InputLabelProps={{ shrink: true }}
                  onChange={(e) =>
                    setAddressData({ ...addressData, dom_over: e.target.value })
                  }
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  fullWidth
                  label="Keyword Research"
                  name="keyword_research"
                  variant="outlined"
                  placeholder="Enter Keyword Research Limit"
                  value={addressData?.key_res}
                  InputLabelProps={{ shrink: true }}
                  onChange={(e) =>
                    setAddressData({ ...addressData, key_res: e.target.value })
                  }
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  fullWidth
                  label="Site-Audit"
                  name="site_audit"
                  variant="outlined"
                  placeholder="Enter Site-Audit Limit"
                  value={addressData?.site_audit}
                  InputLabelProps={{ shrink: true }}
                  onChange={(e) =>
                    setAddressData({
                      ...addressData,
                      site_audit: e.target.value,
                    })
                  }
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  fullWidth
                  label="Track Keyword"
                  name="track_keyword"
                  variant="outlined"
                  placeholder="Enter Track Keyword Limit"
                  value={addressData?.track_key}
                  InputLabelProps={{ shrink: true }}
                  onChange={(e) =>
                    setAddressData({
                      ...addressData,
                      track_key: e.target.value,
                    })
                  }
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  fullWidth
                  label="Projects"
                  name="project"
                  variant="outlined"
                  placeholder="Enter Project Limit"
                  value={addressData?.project}
                  InputLabelProps={{ shrink: true }}
                  onChange={(e) =>
                    setAddressData({ ...addressData, project: e.target.value })
                  }
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <FormControl fullWidth>
                  <InputLabel>White Label</InputLabel>
                  <Select
                    label="White Label"
                    name="white_lable"
                    variant="outlined"
                    value={
                      addressData?.pdf?.toLowerCase().replace(/\s+/g, "-") || ""
                    }
                    onChange={(e) =>
                      setAddressData({ ...addressData, pdf: e.target.value })
                    }
                  >
                    {countries.map((item, index) => (
                      <MenuItem
                        key={index}
                        value={item.toLowerCase().replace(/\s+/g, "-")}
                      >
                        {item}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid>
            </Grid>
          </DialogContent>
          <DialogActions className="justify-content-center pb-0 pb-sm-3 px-sm-3">
            <Button
              variant="contained"
              onClick={() => handleAddLimits(addressData)}
              type="click"
            >
              Update
            </Button>
            <Button
              variant="outlined"
              color="secondary"
              onClick={() => setSelectedUser([])}
              type="reset"
            >
              Cancel
            </Button>
          </DialogActions>
        </Box>
      </Dialog>
    </>
  );
};

export default AddLimits;
