import React, {
  useRef,
  useEffect,
  useState,
  useCallback,
  useMemo,
} from "react";
import filterIcon from "../../../Assets/backlink/filter.svg";
import { motion, AnimatePresence } from "framer-motion";
import { useClickAway } from "react-use";
import TagDropdown from "./tag-dropdown";
import { ZustandStore } from "../../../../store/zustand-store";
import { useSelector } from "react-redux";
import { useLocation } from "react-router-dom";

const framerFilterEffect = {
  initial: { opacity: 0, transformOrigin: "left" },
  animate: { opacity: 1, transformOrigin: "left" },
  exit: {
    opacity: 0,
    transition: { delay: 0.2 },
    transformOrigin: "left",
  },
  transition: { duration: 0.3 },
};

const FilterTag = React.memo(() => {
  const filterRef = useRef(null);
  const location = useLocation();
  const pathname = location.pathname;
  const [isFilterOpen, setIsFilterOpen] = useState(false);
  const userallprojectdetails = useSelector(
    (state) => state.userallprojectdetails
  );
  const websiteurl = useSelector((state) => state.websiteurl);
  const deviceType = useSelector((state) => state.devicetype);

  const { filter_tag, updateTag, keyword_tag_data } = ZustandStore((state) => ({
    filter_tag: state.filter_tag,
    updateTag: state.updateTag,
    keyword_tag_data: state.keyword_tag_data,
  }));

  const { data } = keyword_tag_data;

  useClickAway(filterRef, () => {
    isFilterOpen && setIsFilterOpen(false);
  });

  const tag_list = useMemo(() => {
    const selected_project = userallprojectdetails?.flatMap((project) => {
      const projects = project?.Project;
      return projects?.filter((item) => {
        return item.deviceType === deviceType && item.weburl === websiteurl;
      });
    });

    const project_keyword =
      selected_project?.flatMap((item) => {
        const keywordArr = item.keyword;
        return keywordArr?.map((keyword) => {
          return {
            keyword: keyword,
            location: item.location_name,
          };
        });
      }) || [];
    // remove empty keyword list
    const filtered = data?.filter((item) => item.keyword_list.length > 0);
    // Function to normalize keywords for better matching
    const uniqueArray = Array.from(
      new Map(
        filtered?.map((item) => [
          `${item.tag_name}-${item.location_name}`,
          item,
        ])
      ).values()
    );

    const filteredArray = uniqueArray?.filter((item) => {
      const matchingFilter = project_keyword?.filter(
        (filterItem) => filterItem.location === item.location_name
      );
      return item?.keyword_list?.some((keyword) =>
        matchingFilter?.some((filterItem) => filterItem.keyword === keyword)
      );
    });
    return pathname === "/rank-tracker" ? filteredArray : uniqueArray;
  }, [data, userallprojectdetails, websiteurl, deviceType]);

  const filteredKeywords = useMemo(() => {
    // Create a Set of criteria tuples for efficient lookup
    const criteriaSet = new Set(filter_tag.map((tag_name) => `${tag_name}`));
    // Filter the items based on the criteria
    const filteredItems = tag_list.filter(({ tag_name }) =>
      criteriaSet.has(`${tag_name}`)
    );

    const locationMap = filteredItems.reduce((acc, obj) => {
      const { location_name, keyword_list } = obj;
      if (!acc[location_name]) {
        acc[location_name] = new Set(); // Use a Set to avoid duplicates
      }
      keyword_list.forEach((keyword) => acc[location_name].add(keyword));
      return acc;
    }, {});

    // Convert the map to the desired array format
    return Object.keys(locationMap).map((location_name) => ({
      keyword_list: Array.from(locationMap[location_name]),
      location_name,
    }));
  }, [tag_list, filter_tag]);

  const uniqueKeywords = useMemo(
    () => Array.from(new Set(filteredKeywords)),
    [filteredKeywords]
  );

  useEffect(() => {
    ZustandStore.setState({
      tag_keywords: uniqueKeywords,
    });
  }, [uniqueKeywords]);

  const handleFilterToggle = () => {
    setIsFilterOpen((prevState) => !prevState);
  };

  const handleTagClick = useCallback(
    (tag) => {
      updateTag(tag);
    },
    [updateTag]
  );

  const handleFilterTag = useCallback(
    (tag) => {
      handleTagClick(tag);
    },
    [handleTagClick]
  );

  return (
    <div
      className="d-flex align-items-center backlink-filter ms-3"
      ref={filterRef}
    >
      <button
        className="icon-btn"
        onClick={(e) => handleFilterToggle(e)}
        title="filter tag name"
      >
        <img src={filterIcon} alt="filter icon" />
      </button>

      <AnimatePresence>
        {isFilterOpen && (
          <motion.div {...framerFilterEffect}>
            <div className="d-flex ms-2">
              <TagDropdown
                data={tag_list}
                selectedTag={filter_tag}
                title="Filter by tag"
                style={{
                  border: "1px solid #0000004D",
                  textTransform: "none",
                  boxShadow: "none",
                  padding: "1.1em 1em",
                  borderRadius: "5px",
                  color: "#000000",
                  height: "40px",
                  width: "150px",
                }}
                callback={handleFilterTag}
              />
            </div>
          </motion.div>
        )}
      </AnimatePresence>
    </div>
  );
});

export default FilterTag;
