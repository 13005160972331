import React, { useState, useMemo, useEffect } from "react";
// import ActiveLastBreadcrumb from "./breadcrumb.component";
import axios from "axios";
import Box from "@mui/material/Box";
import Dropdown from "../../share/components/dropdown";
import { Grid, Button, IconButton, Typography } from "@mui/material";
import ToolTip from "../../share/components/tooltip";
import PopOver from "../../share/components/popover.component";
import CloseIcon from "@mui/icons-material/Close";
import "../../css/backlink-checker.scss";
import CustomButtonStyle from "../../share/components/button.component";
import KeywordImg from "../../Assets/rank-tranking/19196970-removebg-preview.png";
import { ReCaptcha } from "../../share/components/googleRecaptacha";
import ToolNav from "../../share/components/tool-nav";
import locations from "../../dataFiles/countryList";
import { languages } from "../../dataFiles/language";
import OpenInNewIcon from "@mui/icons-material/OpenInNew";
import "./Tools.scss";
import SEC1 from "./Assests/Rank/sec1.png";
import SEC2 from "./Assests/Rank/sec2.png";
import SEC3 from "./Assests/Rank/sec3.png";
import { FooterTool } from "./Footer/Footer";
import useIpAddress from "../../../helperJs/useIpAddress";
import { domainRegex } from "../../../helperJs/helper";

function RankChecker() {
  const ipAddress = useIpAddress();
  const [Data, setData] = useState([]);
  const [Error, setError] = useState({
    domian: "",
    keyword: "",
  });
  const [keyword, setKeyword] = useState("");
  const [domain, setDomain] = useState("");
  const [language, setLanguage] = useState({
    name: "English",
    code: "en",
  });
  const [country, setCountry] = useState({
    name: "India",
    code: "2356",
    img: "https://flagsapi.com/IN/flat/24.png",
  });
  const [anchorEl, setAnchorEl] = useState(false);

  const freeranktracker = (config) =>
    `https://app.rankspro.io/api/user/freeranktracker/?data=${config.data}&domain=${config.domain}`;

  const freeranktrackersavedata = () =>
    `https://app.rankspro.io/api/user/freeranktrackersavedata/`;

  const handleCountrySlection = (e) => {
    const obj = {
      name: e.location_name,
      code: e.location_code,
      img: e.location_flag,
    };
    setCountry(obj);
  };
  const handleLanguageSlection = (e) => {
    const obj = {
      name: e.language,
      code: e.languagecode,
    };
    setLanguage(obj);
  };

  const selectedCountryList = useMemo(() => {
    let filterCountry = [];
    locations?.map((res) => {
      res.languagedata?.map((response) => {
        if (response?.language_name === language.name) {
          filterCountry.push(res);
        }
      });
    });
    filterCountry?.length > 0 && handleCountrySlection(filterCountry?.[0]);
    return filterCountry;
  }, [locations, language]);

  const freeRankTrackerSaveData = (rank) => {
    const data = [
      {
        language_code: language.code,
        location_code: country.code,
        keyword: keyword,
        serp_rank: rank,
        ip_address: ipAddress,
      },
    ];
    const config = {
      domain: domain,
      data: JSON.stringify(data),
    };
    axios({
      method: "post",
      url: freeranktrackersavedata(),
      headers: {},
      data: config,
    });
  };

  const rankTrackerData = (config) => {
    axios
      .post(freeranktracker(config))
      .then((response) => {
        if (response.data?.length === 0) {
          setData([]);
          setAnchorEl(false);
        } else {
          const domainData = response.data?.domain_data;
          const rank = domainData?.rank_group
            ? domainData?.rank_group
            : typeof domainData === "string"
            ? domainData
            : 0;
          freeRankTrackerSaveData(rank);
          setTimeout(() => {
            setData(response.data);
          }, 1000);
        }
      })
      .catch(() => {
        setData([]);
        setAnchorEl(false);
      });
  };

  const rankCheckerClickHandler = (e) => {
    e.preventDefault();
    if (domain === "" && keyword === "") {
      setError({
        domian: "Please enter domain",
        keyword: "Please enter keyword",
      });
      return;
    }
    if (domain === "") {
      setError({
        domian: "Please enter domain",
        keyword: "",
      });
      return;
    }
    if (keyword === "") {
      setError({
        domian: "",
        keyword: "Please enter keyword",
      });
      return;
    }

    if (!domainRegex.test(domain)) {
      setError({
        domian: "Please enter valid domain",
        keyword: "",
      });
    } else {
      setAnchorEl(true);
      const data = [
        {
          language_code: language.code,
          location_code: country.code,
          keyword: keyword,
        },
      ];
      const config = {
        domain: domain,
        data: JSON.stringify(data),
      };
      rankTrackerData(config);
    }
  };

  const domainChangeHandler = (target) => {
    const url = target.value;
    const match = url.match(/^(?:https?:\/\/)?(?:www\.)?([^/]+)/i);
    setDomain(match ? match[1].replace(/\s/g, "") : "");
    setError((pre) => ({ ...pre, domain: "" }));
  };

  const keywordChangeHandler = (target) => {
    setKeyword(target.value);
    setError((pre) => ({ ...pre, keyword: "" }));
  };

  function handleClose() {
    setAnchorEl(false);
    setData([]);
    setKeyword("");
    setDomain("");
  }

  const allData = Data?.all_data?.slice(0, 10);
  const domainData = Data?.domain_data;

  const Sppiner = () => {
    return (
      <Box
        sx={{ display: "flex", minHeight: 350, minWidth: 350 }}
        className="w-100 h-100 cad"
      >
        <div
          className="spinner-border"
          role="status"
          style={{ color: "#365486", width: "3rem", height: "3rem" }}
        >
          <span className="sr-only"></span>
        </div>
      </Box>
    );
  };
  const Section1Ul = [
    "Ranking position",
    "Keyword Difficulty (KD)",
    "Estimated organic traffic",
    "Ranking URL",
    "Search volume",
    "SEO Impact",
    "Trends",
  ];
  const Section2Ul = [
    "Get accurate and up-to-date rankings based on the latest ranking data from leading search engines",
    "A simple and flexible tool to track your rankings across 150 countries, multiple search engines, and geo-locations",
    "Generate topic ideas and write high-quality content based on relevant keywords with an in-built AI writer.",
    "Generate reports and insights on your keyword rankings and website performance.",
    "Get visualized data to better understand your SEO performance and share it with your clients.",
  ];
  return (
    <>
      <div style={{ overflow: "auto", height: "100vh" }}>
        <div className="rank-tracker-container">
          <ToolNav />
          <div className="Tool-App">
            <div className="Rank-container">
              <div>
                <h4 className="heading-medium font-regular">
                  Free SEO Tools /
                </h4>
                <h1 className="heading-xlarge font-bold">Rank Checker</h1>
                <p className="heading-medium font-regular">
                  Try the free version of Rank Checker. Get a glimpse into the
                  power of our premium tool.
                </p>
                <div className="search-container">
                  <Grid container spacing={2}>
                    <Grid item xs={6}>
                      <input
                        type="text"
                        className="input-search w-100"
                        placeholder="Enter your Keyword"
                        value={keyword}
                        style={{ height: "50px" }}
                        onChange={(e) => keywordChangeHandler(e.target)}
                      />
                      {Error?.keyword && (
                        <span className="msg-error heading-xs font-regular">
                          {Error?.keyword}
                        </span>
                      )}
                    </Grid>
                    <Grid item xs={6}>
                      <input
                        type="text"
                        className="input-search w-100"
                        placeholder="Enter domain name"
                        value={domain}
                        style={{ height: "50px" }}
                        onChange={(e) => domainChangeHandler(e.target)}
                      />
                      {Error?.domian && (
                        <span className="msg-error heading-xs font-regular">
                          {Error?.domian}
                        </span>
                      )}
                    </Grid>
                  </Grid>
                  <Grid container spacing={2} className="mt-1">
                    <Grid item xs={"auto"}>
                      <Dropdown
                        title={language?.name || "No Language"}
                        callback={handleLanguageSlection}
                        data={languages}
                        style={{
                          width: "230px",
                          height: "50px",
                          textTransform: "none",
                          backgroundColor: "transparent",
                          boxShadow: "none",
                          borderRadius: "5px",
                          border: "1px solid #0000004D",
                        }}
                      />
                    </Grid>
                    <Grid item xs={"auto"}>
                      <Dropdown
                        title={country?.name || "No Country"}
                        callback={handleCountrySlection}
                        data={selectedCountryList}
                        img={country.img}
                        style={{
                          width: "230px",
                          height: "50px",
                          textTransform: "none",
                          backgroundColor: "transparent",
                          boxShadow: "none",
                          borderRadius: "5px",
                          border: "1px solid #0000004D",
                        }}
                      />
                    </Grid>
                  </Grid>
                  <Button
                    variant="outlined"
                    className="btn-backlink"
                    onClick={(e) => rankCheckerClickHandler(e)}
                  >
                    Submit
                  </Button>
                </div>
              </div>
              <img
                src={KeywordImg}
                alt=""
                className="backlink-img flex-none-sm"
              />
            </div>
          </div>

          <PopOver anchorEl={anchorEl} setAnchorEl={() => setAnchorEl(false)}>
            <Box
              sx={{
                bgcolor: "rgba(255, 255, 255, 1)",
                minWidth: { md: 700 },
              }}
              className=""
            >
              {Data?.length === 0 ? (
                <Sppiner />
              ) : (
                <Box sx={{ width: "100%", cursor: "default" }}>
                  <div className="w-100 px-4 pt-2">
                    <Typography
                      component="h1"
                      id="select-google-property"
                      className="heading-large font-bold popover-list-header py-2 px-2"
                    >
                      Organic Ranking{" "}
                      <ToolTip
                        title={`Organic Ranking: Natural position in search results. Higher rankings mean better visibility and more organic traffic.`}
                      />
                      <IconButton
                        className="popover-close-icon"
                        onClick={handleClose}
                        sx={{
                          "&:hover": {
                            backgroundColor: "rgba(240, 242, 255, 1)",
                          },
                        }}
                      >
                        <CloseIcon sx={{ color: "black" }} fontSize="large" />
                      </IconButton>
                    </Typography>
                    <div className="row mb-2 px-2 gy-2">
                      <div className="col-12 col-md-6">
                        <div className="d-flex align-items-center">
                          <span className="heading-medium font-regular me-2 text-nowrap">
                            Keyword :{" "}
                          </span>
                          <span
                            className="heading-large font-bold"
                            style={{ color: "#365486" }}
                          >
                            {keyword}
                          </span>
                        </div>
                      </div>
                      <div className="col-12 col-md-6">
                        <div className="d-flex align-items-center">
                          <span className="heading-medium font-regular me-2 text-nowrap">
                            Domain :{" "}
                          </span>
                          <span
                            className="heading-large font-bold"
                            style={{ color: "#365486" }}
                          >
                            {domain}
                          </span>
                        </div>
                      </div>
                      <div className="col-12 col-md-6">
                        <div className="d-flex align-items-center">
                          <span className="heading-medium font-regular me-2 text-nowrap">
                            SERP Ranking :{" "}
                          </span>
                          <span
                            className="heading-large font-bold"
                            style={{ color: "#365486" }}
                          >
                            {domainData?.rank_group
                              ? domainData?.rank_group
                              : typeof domainData === "string"
                              ? domainData
                              : 0}
                          </span>
                        </div>
                      </div>
                      <div className="col-12 col-md-6">
                        <div className="d-flex align-items-center">
                          <span className="heading-medium font-regular me-2 text-nowrap">
                            Location :{" "}
                          </span>
                          <span
                            className="heading-large font-bold"
                            style={{ color: "#365486" }}
                          >
                            {country?.name || 0}
                          </span>
                        </div>
                      </div>
                    </div>
                    <div className="mt-1">
                      <h1 className="heading-large font-bold">SERP Overview</h1>
                      <div
                        style={{
                          overflow: "scroll",
                          maxHeight: "420px",
                          border: "1px solid gray",
                        }}
                        className="mb-2"
                      >
                        <table className="table backlink-list-table mt-0">
                          <thead>
                            <tr>
                              <th scope="col" className="t_left">
                                URL
                                <ToolTip title="URL that people type into Google" />
                              </th>
                              <th scope="col" className="">
                                Position
                                <ToolTip title="The Position this URL is ranked in Google search" />
                              </th>
                            </tr>
                          </thead>
                          <tbody>
                            {allData?.length === 0 ? (
                              <tr>
                                <td className="text-center" colSpan={2}>
                                  "No Data found"
                                </td>
                              </tr>
                            ) : (
                              allData?.map((item, index) => {
                                return (
                                  <tr
                                    key={`blacklink-table-list-${index}-${item?.page_from_title}`}
                                  >
                                    <td className="t_left table_cell">
                                      <div
                                        style={{
                                          display: "flex",
                                          flexDirection: "column",
                                          height: "45px",
                                        }}
                                        className="ellipsis"
                                      >
                                        <span className="blacklink-table-span mt-0">
                                          <a
                                            href={item?.url}
                                            className=""
                                            target="_blank"
                                            style={{
                                              color: "#475EE5",
                                              paddingLeft: "0px",
                                            }}
                                            rel="noopener noreferrer"
                                          >
                                            {item?.title}
                                          </a>
                                        </span>
                                        <span className="blacklink-table-span mt-0">
                                          <a
                                            href={item?.url}
                                            target="_blank"
                                            className="p-0"
                                            rel="noopener noreferrer"
                                          >
                                            {item?.url}
                                            <OpenInNewIcon
                                              sx={{
                                                fontSize: "13px",
                                                marginLeft: "6px",
                                              }}
                                            />
                                          </a>
                                        </span>

                                        {/* <span className="blacklink-table-span ">
                                    <b>Target:</b>
                                    <a
                                      href={item?.url_to}
                                      target="_blank"
                                      className=""
                                      rel="noopener noreferrer"
                                    >
                                      {item?.url_to}
                                      <OpenInNewIcon
                                        sx={{
                                          fontSize: "13px",
                                          marginLeft: "6px",
                                        }}
                                      />
                                    </a>
                                  </span> */}
                                      </div>
                                    </td>

                                    <td className="table_cell vert-alg ">
                                      <span className="table-cell-value ">
                                        {item?.rank_group}
                                      </span>
                                    </td>
                                  </tr>
                                );
                              })
                            )}
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>
                  <div
                    className="row p-3"
                    style={{
                      backgroundColor: "#FFE6BF",
                    }}
                  >
                    <div className="col-8">
                      <div>
                        <h1 className="heading-medium font-bold mb-1">
                          Want to Track Ranking for your website with daily
                          updates?
                        </h1>
                        <p className="heading-small font-regular m-0">
                          Sign Up for RanksPro.io and Monitor your Ranking in
                          Google with other insights and details.
                        </p>
                      </div>
                    </div>
                    <div className="col-4 p-sm-0 d-flex align-items-center justify-content-center">
                      <a target="blank" href="/register">
                        <CustomButtonStyle
                          name="Start 7-Day Free Trial"
                          hover="#365486"
                          bgColor="#365486"
                          variant="contained"
                          className="font-regular heading-small"
                        />
                      </a>
                    </div>
                  </div>
                </Box>
              )}
            </Box>
          </PopOver>

          <ReCaptcha />
        </div>
        {/* Body Start */}
        <div className="RankTrakerBody">
          <div className="RankBox">
            <h4>
              Find and improve your search engine rankings with our free rank
              checker.
            </h4>
            <h5>
              Discover how your website is ranking against the competition and
              find your top traffic-driving keywords. Try our free rank-tracking
              tool today.
            </h5>
            <p>
              RanksPro.io is a cutting-edge rank tracking tool that makes it
              easy and quick for you to know your website's ranking, get
              insights on rank gains and drops, and spot trends and
              opportunities to take the right action. For each keyword, we
              display the following metrics:
            </p>
            <div className="IMGbox">
              <img src={SEC1} alt="secOne " />
            </div>
            <ul>
              {Section1Ul?.map((Li) => (
                <li key={Li}>{Li}</li>
              ))}
            </ul>
            <p>
              Unlike other rank tracking tools, our ranking tool also offers AI
              writing assistance, which generates trendy topics based on
              competitive keyword writers to let you pick the "topic keyword" or
              "article" from the title list.
            </p>
            <h5>How to use the free rank checker?</h5>
            <div className="IMGbox">
              <img src={SEC2} alt="secOne " />
            </div>
            <p>
              With RanksPro.io, it takes you less than a minute to track your
              website performance and top traffic-driving keywords. All you need
              to do is simply enter your domain name and location, and it will
              provide you with valuable insights such as keyword position, URL
              ranking, search volume, search impact, and current trends. You
              don't have to be an expert in SEO, nor do you need any technical
              expertise to operate it.
            </p>
            <h5>What more do you get with the free rank tracking tool?</h5>
            <ul className="ms-4 mt-4 ">
              {Section2Ul?.map((Li) => (
                <li key={Li}>{Li}</li>
              ))}
            </ul>
            <div className="IMGbox">
              <img src={SEC3} alt="secOne " className="mt-3" />
            </div>
            <h6>
              We make sure to update these metrics at regular intervals to
              always provide fresh data.
            </h6>
            <br />
            <hr />
            <div className="secEnd">
              <div className="row">
                <div className="col-md-9 Leftb">
                  <h3>
                    Sign up for a free trial of our rank tracking tool today and
                    unlock valuable insights to make smart SEO strategies.
                  </h3>
                </div>
                <div className="col-md-3 Rightb">
                  <a href="https://app.rankspro.io/register" target="_blank">
                    <button className="btnNormal">Sign up for a free</button>
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
        <FooterTool />
      </div>
    </>
  );
}

export default RankChecker;
