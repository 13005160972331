import React, {
  useState,
  useEffect,
  useRef,
  useCallback,
  useMemo,
} from "react";
import { ReactComponent as DropArrow } from "../../Assets/common/droparrow.svg";
import { ZustandStore } from "../../../store/zustand-store";
import VirtualizeList from "../components/virtualize-list"; // Import the VirtualizeList component
import useDebouncedFunction from "../../../helperJs/debounce";
import { categorizeByLocationType } from "../../../helperJs/helper";
import countryList from "../../dataFiles/country";

const LocationList = (props) => {
  const { country_Data } = ZustandStore((state) => state);
  const [clickDrop, setClickDrop] = useState(false);
  const [searched, setSearched] = useState([]);
  const [inputFocused, setInputFocused] = useState(false); // Track input focus
  const dropdownRef = useRef(null);
  const inputRef = useRef(null);

  const handleClick = (res) => {
    props.callback(res);
    setClickDrop(false);
    setSearched([]);
  };

  const categorizedLocations = useMemo(() => {
    return categorizeByLocationType(
      searched.length > 0 ? searched : country_Data?.data || []
    );
  }, [searched, country_Data]);

  const getCurrentCountryDetails = (curr_location_code) => {
    const data = countryList?.filter((item) => {
      return item.location_code === curr_location_code;
    });
    return data?.[0]?.location_flag;
  };

  const searchCountry = useCallback(
    useDebouncedFunction((text) => {
      if (!text) {
        setSearched([]);
      } else {
        const escapedText = text.replace(/[-\/\\^$*+?.()|[\]{}]/g, "\\$&"); // Escapes special characters in the search text
        const regex = new RegExp(escapedText, "gi");
        const matches = country_Data?.data?.filter((prname) => {
          return prname.location_name && regex.test(prname.location_name);
        });
        setSearched(matches);
      }
    }, 300),
    [country_Data]
  );

  const handleClickOutside = (event) => {
    if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
      setSearched([]);
      setClickDrop(false);
    }
  };

  useEffect(() => {
    window.addEventListener("click", handleClickOutside);
    return () => {
      window.removeEventListener("click", handleClickOutside);
    };
  }, []);

  useEffect(() => {
    if (inputFocused && inputRef.current) {
      inputRef.current.focus();
    }
  }, [inputFocused]);

  const rowRenderer = ({ key, index, style }, category) => {
    const item = categorizedLocations[category][index];
    const curr_location_code = item?.location_code_parent
      ? item?.location_code_parent
      : item.location_code;
    const loc_flag = getCurrentCountryDetails(curr_location_code);

    return (
      <li
        key={key}
        style={style}
        onClick={() => handleClick(item)}
        className={
          item.location_name === props.selectedValue
            ? "prloc_drop-selected"
            : ""
        }
      >
        {loc_flag && (
          <img
            className="me-2"
            src={loc_flag}
            alt={item.location_code}
            style={{ width: "20px", objectFit: "contain" }}
          />
        )}

        {item.location_name}
      </li>
    );
  };

  return (
    <>
      <div
        className="prLocation location-list-dropdown"
        style={props.style}
        ref={dropdownRef}
      >
        <div
          className="dropdown-drop"
          onClick={() => {
            setInputFocused(true); // Focus the input on click
            setClickDrop(!clickDrop);
          }}
        >
          {props.img && (
            <img
              className="me-2"
              src={props.img}
              alt="selected-icon"
              style={{ width: "20px", objectFit: "contain" }}
            />
          )}
          <p
            className="heading-small"
            style={{ fontSize: props.style.fontSize }}
          >
            {props.title}
          </p>
        </div>

        <div
          style={{ background: "transparent" }}
          onClick={() => {
            setInputFocused(true); // Focus the input on click
            setClickDrop(!clickDrop);
          }}
        >
          <DropArrow
            style={{
              width: "22px",
              height: "22px",
              color: "#000000",
              transform: clickDrop ? "rotateX(180deg)" : "rotateX(0deg)",
            }}
          />
        </div>

        {clickDrop && country_Data?.data?.length > 0 ? (
          <div
            className="prloc_drop"
            style={{
              borderRadius: props?.style?.borderRadius || "5px",
            }}
          >
            <div className="prloc-list">
              {!props?.searchType && (
                <div
                  className="p-0 mb-2"
                  style={{
                    top: "0",
                    position: "sticky",
                    background: "white !important",
                  }}
                >
                  <input
                    type="search"
                    ref={inputRef}
                    className="focus-on-click heading-small mt-0"
                    onFocus={() => setInputFocused(true)}
                    onBlur={() => setInputFocused(false)}
                    onChange={(e) => searchCountry(e.target.value)}
                    placeholder="search here.."
                  />
                </div>
              )}
              <ul className="prloc-list-show">
                {["Country", "State", "City"].map((type) => {
                  const data = categorizedLocations[type] || [];
                  if (data?.length === 0) return null;
                  return (
                    <div
                      key={`virtualize-list-${type}`}
                      className={`virtualize-list-${type}`}
                      style={{
                        position: "relative", // Make sure the container has relative positioning
                        minHeight: data?.length > 2 ? "300px" : "100px",
                      }}
                    >
                      <li
                        className="category-title"
                        style={{
                          position: "sticky",
                          top: 0,
                          backgroundColor: "#fff", // Ensure the title background doesn't overlap with the list
                          zIndex: 10,
                          marginBottom: "10px", // Space between the sticky title and the first list item
                          boxShadow: "0px 2px 4px rgba(0, 0, 0, 0.1)", // Optional: Add a shadow for better visibility
                        }}
                      >
                        <strong>{type}</strong>
                      </li>
                      <VirtualizeList
                        data={data}
                        rowRenderer={(props) => rowRenderer(props, type)}
                        rowHeight={40} // Adjust based on your list item height
                      />
                    </div>
                  );
                })}
              </ul>
            </div>
          </div>
        ) : null}
      </div>
    </>
  );
};

export default LocationList;
