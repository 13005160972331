export const login_withAPi = () => `https://app.rankspro.io/api/user/login/`;
export const regester_withAPi = () =>
  `https://app.rankspro.io/api/user/register/`;

export const regester_consent = () =>
  `https://app.rankspro.io/api/user/consent/`;
export const regester_withOTP = () => `https://app.rankspro.io/api/user/otp/`;
export const forgot_withApi = () =>
  `https://app.rankspro.io/api/user/send-reset-password-email/`;
export const reset_password_withApi = () =>
  `https://app.rankspro.io/api/user/reset-password/`;
export const post_Plans_Details = () =>
  `https://app.rankspro.io/api/user/plan/`;
export const get_Plans_Details = (cur) =>
  `https://app.rankspro.io/api/user/planget/?currency=${cur}`;
export const delete_User = (UserId) =>
  `https://app.rankspro.io/api/user/deleteuser/${UserId}/`;
export const delete_Plan = (PlanId) =>
  `https://app.rankspro.io/api/user/deleteplan/${PlanId}/`;
export const update_plans_Details = (PlanId) =>
  `https://app.rankspro.io/api/user/planupdate/${PlanId}/`;
export const delete_Project = (projectId) =>
  `https://app.rankspro.io/api/user/deleteproject/${projectId}/`;

// post data for SERP API
export const KEYWORD_POST = () => `https://app.rankspro.io/api/user/Keyword/`;
export const PROJECT_POST = () => `https://app.rankspro.io/api/user/project/`;

// Getting Data From THE DATABASE ------
export const profile_withApi = () =>
  `https://app.rankspro.io/api/user/profile/`;
export const PROJECT_GET = () => `https://app.rankspro.io/api/user/projectGet/`;

export const LATEST_DATA = (latestData) =>
  `https://app.rankspro.io/api/user/latestData/?email=${latestData.email}&proj_name=${latestData.weburl}&location=${latestData.location_code}&deviceType=${latestData.deviceType}&location_name=${latestData.location_name}`;
export const OLD_LATEST_DATA = (latestData) =>
  `https://app.rankspro.io/api/user/previousData/?email=${latestData.email}&proj_name=${latestData.weburl}&location=${latestData.location_code}&deviceType=${latestData.deviceType}&days=${latestData.days}`;
export const ADMIN_USERS = () =>
  `https://app.rankspro.io/api/user/adminprofile/`;
// referral codes get
export const Reffral_Get = () => `https://app.rankspro.io/api/user/codesGet/`;
export const InviteUserBy_Mail = (limit) =>
  `https://app.rankspro.io/api/user/invitemail/?limit=${limit}`;

// POST APIS
export const POST_CODES = () => `https://app.rankspro.io/api/user/codes/`;
export const POST_USEDCODES = () =>
  `https://app.rankspro.io/api/user/usedcode/`;
export const POST_CODEVALID = () =>
  `https://app.rankspro.io/api/user/codevalid/`;
export const POST_MAILFORNEWUSER_REGISTER = () =>
  `https://app.rankspro.io/api/user/newuserregister/`;
export const SUBSCRIBE_MAIL = () =>
  `https://app.rankspro.io/api/user/subscribemail/`;
export const POST_FEEDBACK = () => `https://app.rankspro.io/api/user/feedback/`;
export const POST_SITEAUDIT = (item) =>
  `https://app.rankspro.io/api/user/siteaudit/?email=${item.email}&domain=${item.domain}&max_crawl_pages=${item.max_crawl_page}`;
export const POST_SITEAUDIT_LMT = () =>
  `https://app.rankspro.io/api/user/siteauditlmt/`;
export const ADMIN_PASSWORD_CHANGE = (item) =>
  `https://app.rankspro.io/api/user/passchange/?email=${item.email}&new_pass=${item.password}`;
// agencies api for admin
export const POST_DIGITAL_AGENCIES = () =>
  `https://app.rankspro.io/api/user/digitalagencies/`;

export const GET_DIGITAL_AGENCIES = (name) =>
  `https://app.rankspro.io/api/user/digiagenciessingleget/?name=${name}`;
export const POST_KEYWORD_VIEW_LIMIT = () =>
  `https://app.rankspro.io/api/user/keywordviewlmt/`;
export const POST_KEYWORD = (item) =>
  `https://app.rankspro.io/api/user/keyword/?keyword=${item.keyword}&location_code=${item.locationcode}&language_code=${item.language_code}`;

export const POST_SAVEBLOG = () => `https://app.rankspro.io/api/user/saveblog/`;

export const POST_ALPHAPLAN = () =>
  `https://app.rankspro.io/api/user/subsplan/`;
export const POSt_STRIPE_UPDATE = (item) =>
  `https://app.rankspro.io/api/user/stripeupdate/?email=${item.email}&price_id=${item.priceId}`;

export const POST_CONVERTKIT = (item) =>
  `https://app.rankspro.io/api/user/convertkit/?email=${item.email}&name=${item.name}&subscription=${item.subscription}`;

export const POST_AFFILIATE_REWARD = (e) =>
  `https://app.rankspro.io/api/user/affreward/?reward=${e}`;
export const POST_Affiliate_Register = (email) =>
  `https://app.rankspro.io/api/user/affsave/?email=${email}`;
export const POST_LISTKEYWORD = () => {
  return `https://app.rankspro.io/api/user/keywordlist/`;
};

// GET APIS
export const UPDATE_AGENCY_DETAIL = (id) =>
  `https://app.rankspro.io/api/user/digiagenciesupdate/${id}/`;

export const Admin_Keyword_Get = () =>
  `https://app.rankspro.io/api/user/adminkeywordget/`;
export const CODEVALID_GET = () =>
  `https://app.rankspro.io/api/user/codevalidGet/`;
export const USEDCODES_GET = () =>
  `https://app.rankspro.io/api/user/usedcodeget/`;
export const USERPROJECT_GET = (email) =>
  `https://app.rankspro.io/api/user/userprojectget/?email=${email}`;
export const INVITE_AUTH = (email) =>
  `https://app.rankspro.io/api/user/inviteauth/?email=${email}`;
export const Get_SUbSCRIBEDMAIL = () =>
  `https://app.rankspro.io/api/user/subscribemailget/`;
export const SENDINGMAIL_GET = () =>
  `https://app.rankspro.io/api/user/sendingMailGet/`;
export const ADMINUSER_DETAIL = (user) =>
  `https://app.rankspro.io/api/user/adminprofiledetail/?email=${user.email}`;
export const DOMAINOVERVIEW_GET = (user) =>
  `https://app.rankspro.io/api/user/domainoverviewget/?domain=${
    user.domainName
  }&location_name=${user.domainCountry}&language_name=${
    user?.domainLanuage || ""
  }`;
export const Get_FeedBack = () =>
  `https://app.rankspro.io/api/user/feedbackget/`;
export const GET_AUDIT_LIMIT = (item) =>
  `https://app.rankspro.io/api/user/siteauditlmtget/?email=${item.email}&domain_name=${item.domain}`;
export const GET_SITE_AUDIT = (item) =>
  `https://app.rankspro.io/api/user/siteauditget/?email=${item.email}&domain=${item.domain}`;
export const DIGITAL_GET = () => `https://app.rankspro.io/api/user/digitalget/`;
export const KEYWORD_GET = (item) =>
  `https://app.rankspro.io/api/user/keywordget/?keyword=${item.keyword}&location_code=${item.locationcode}&language_code=${item.language_code}`;
export const KEYWORD_VIEW_LIMIT_get = (email) =>
  `https://app.rankspro.io/api/user/keywordviewlmtget/?email=${email}`;
export const AIPROJECT_GET = (email) =>
  `https://app.rankspro.io/api/user/blogget/?email=${email}`;
export const get_AlphaPlan = (email) =>
  `https://app.rankspro.io/api/user/subsplanget/?email=${email}`;
export const GET_AFFILIATE_REWARD = () =>
  `https://app.rankspro.io/api/user/affrewardget/`;
export const GET_affiliateGet = (email) =>
  `https://app.rankspro.io/api/user/affiliateGet/?email=${email}`;

// In Keyword research List APi
export const GET_allList = (email) =>
  `https://app.rankspro.io/api/user/keywordlistget/?email=${email}`;

export const PUT_AddKeyWord = (email, listName, keyList, list_type) =>
  `https://app.rankspro.io/api/user/keywordlistupdate/?email=${email}&list_name=${listName}&keyword=${keyList}&list_type=${list_type}`;

export const DELETE_LIST = (EMAIL, listedName, list_type) =>
  `https://app.rankspro.io/api/user/keywordlistdelete/?email=${EMAIL}&list_name=${listedName}&list_type=${list_type}`;
export const DELETE_VIEWLISTKEYWORD = (
  email,
  listName,
  keyWordList,
  list_type
) =>
  `https://app.rankspro.io/api/user/keyworddeletelist/?email=${email}&list_name=${listName}&keyword=${keyWordList}&list_type=${list_type}`;

// DELETE, PUT API
export const DELETE_CODE = (item) =>
  `https://app.rankspro.io/api/user/deletecode/${item.id}/?code=${item.code}`;
export const UPDATE_KEYWORD = (item) =>
  `https://app.rankspro.io/api/user/updatekeyword/${item.id}/?key=${item.key}&device=${item.device}&location_code=${item.location}`;
export const DELETE_KEYWORD = (item) =>
  `https://app.rankspro.io/api/user/delkeyword/${item.id}/?key=${item.key}&device=${item.device}&location_code=${item.location}`;
export const DELETE_REFERRALCODE = (item) =>
  `https://app.rankspro.io/api/user/promcodedelete/?code=${item}`;
export const SENDINGMAIL_UPDATE = (item) =>
  `https://app.rankspro.io/api/user/sendingMailUpdate/${item.id}/`;
export const DELETE_SUBSCRIPTION = (email) =>
  `https://app.rankspro.io/api/user/deletesubscription/?email=${email}`;
export const SITE_AUDIT_UPDATE = (item) =>
  `https://app.rankspro.io/api/user/siteauditlmtupdate/?email=${item.email}&domain=${item.domain}&limit=${item.limit}&date=${item.date}`;
export const DELETE_Agency = (id) =>
  `https://app.rankspro.io/api/user/digitaldelete/${id}/`;
export const Update_Agency = (id) =>
  `https://app.rankspro.io/api/user/digitalupdate/${id}/`;
export const KEYWORD_VIEW_LIMIT_UPDATE = (id) =>
  `https://app.rankspro.io/api/user/keywordviewlmtupdate/${id}/`;
export const codevalidupdate = (id) =>
  `https://app.rankspro.io/api/user/codevalidupdate/${id}/`;

// affiliate

export const GET_CLICK_COUNT = (aff_id) =>
  `https://app.rankspro.io/api/user/affclickget/?aff_id=${aff_id}`;

export const UPDATE_CLICK_COUNT = (config) =>
  `https://app.rankspro.io/api/user/affclickupdate/?aff_id=${config.aff_id}&click=${config.click}`;

export const UPDATE_AFFILIATE_REWARD = (e) =>
  `https://app.rankspro.io/api/user/affrewardupdate/?reward=${e}`;

export const UPDATE_affiliateGet = (item) =>
  `https://app.rankspro.io/api/user/affupdate/?aff_id=${item.aff_id}&user_data=${item.data}`;

export const ALL_AFFILIATE_USER = () =>
  `https://app.rankspro.io/api/user/affiliatealluser/`;

export const GET_ALL_WITHDRA = () =>
  `https://app.rankspro.io/api/user/allwithdrawget/`;

// STRIPE API
export const customer_Subs_Email = (email) =>
  `https://api.stripe.com/v1/customers?email=${email}`;
export const customer_Subscription = () =>
  `https://api.stripe.com/v1/subscriptions`;

// GET LOCATION FROM SERP API
export const Country = () =>
  `https://api.dataforseo.com/v3/serp/google/locations`;

// backlink Api
export const get_backlink_data = (config) =>
  `https://app.rankspro.io/api/user/backlinkdataget/?domain=${config.domain}&include_subdomains=${config.include_subdomains}`;

export const get_domainauthority = (domain) =>
  `https://app.rankspro.io/api/user/domainauthorityget/?domain=${domain}`;

export const post_backlink_data = (config) =>
  `https://app.rankspro.io/api/user/backlinkdata/?domain=${config.domain}&include_subdomains=${config.include_subdomains}`;

export const get_user_backlinklimit = (config) =>
  `https://app.rankspro.io/api/user/bakclinklmtget/?email=${config.email}&domain_name=${config.domain}`;

export const post_user_backlinklimit = () =>
  "https://app.rankspro.io/api/user/bakclinklmt/";

export const update_user_backlinklimit = (id) =>
  `https://app.rankspro.io/api/user/bakclinklmtupdate/${id}/`;

export const post_morebacklink = (config) =>
  `https://app.rankspro.io/api/user/morebacklink/?domain=${config.domain}&search_after_token=${config.searchaftertoken}&include_subdomains=${config.include_subdomains}`;

export const get_backlinktotallmtGet = (email) =>
  `https://app.rankspro.io/api/user/backlinktotallmtGet/?email=${email}`;

export const post_backlinktotallmt = () =>
  `https://app.rankspro.io/api/user/backlinktotallmt/`;

export const update_backlinktotallmtupdate = (id) =>
  `https://app.rankspro.io/api/user/backlinktotallmtupdate/${id}/`;

// google Analytics

// post_googleprop_data
export const post_googleprop_data = (config) =>
  `https://app.rankspro.io/api/user/googleaccountdata/?code=${config.code}&email=${config.email}&domain_name=${config.domain}`;

export const disconnect_google = (config) =>
  `https://app.rankspro.io/api/user/disconnectgoogle/?email=${config.email}&domain_name=${config.domain_name}`;

export const post_selected_googleprop = (config) =>
  `https://app.rankspro.io/api/user/savegoogleoauth/?account_id=${config.account_id}&property_id=${config.property_id}&date=${config.date}&refresh_token=${config.refresh_token}&email=${config.email}&domain_name=${config.domain_name}&display_name=${config.display_name}`;

export const post_userpostanalytics = (config) =>
  `https://app.rankspro.io/api/user/postanalytics/?property_id=${config.property_id}&date=${config.date}&refresh_token=${config.refresh_token}&email=${config.email}&domain_name=${config.domain_name}&display_name=${config.display_name}`;

export const getanalyticsdata = (config) =>
  `https://app.rankspro.io/api/user/getanalyticsdata/?email=${config.email}&domain_name=${config.domain_name}`;

export const getsavegoogleoauth = (config) =>
  `https://app.rankspro.io/api/user/getsavegoogleoauth/?email=${config.email}&domain_name=${config.domain_name}`;

export const postgooglepropertydata = (config) =>
  `https://app.rankspro.io/api/user/googlepropertydata/?email=${config.email}&account_id=${config.account_id}&account_name=${config.account_name}&refresh_token=${config.refresh_token}    `;

export const postgoogleaccount = (email, domain) =>
  `https://app.rankspro.io/api/user/getgoogleaccount/?email=${email}&domain_name=${domain}`;

export const blogdelete = (config) =>
  `https://app.rankspro.io/api/user/blogdelete/?email=${config.email}&keyword=${config.keyword}&date=${config.date}&month=${config.month}&year=${config.year}`;

// Backlinks List Apis

export const GET_ALLLIST_BACKLINKS = (EMAIL) =>
  `https://app.rankspro.io/api/user/backlinklistget/?email=${EMAIL}`;

export const POST_NEWLIST_BACKLINKS = () =>
  `https://app.rankspro.io/api/user/backlinklist/`;

export const DELETE_LIST_BACKLINKS = (EMAIL, LISTNAME) =>
  `https://app.rankspro.io/api/user/backlinklistdelete/?email=${EMAIL}&back_list_name=${LISTNAME}`;

export const PUT_ADDBACKLINKS = (email, listName, keyList) =>
  `https://app.rankspro.io/api/user/backlinklistupdate/?email=${email}&back_list_name=${listName}&url=${JSON.stringify(
    keyList
  )}`;

export const DELETE_VIEWLISTBACKLINK = (email, listName, backLinkKayName) =>
  `https://app.rankspro.io/api/user/backlinkdelete/?email=${email}&back_list_name=${listName}&url=${JSON.stringify(
    backLinkKayName
  )}`;

export const USER_BACKLINK_LIST_URLEML = () =>
  `https://app.rankspro.io/api/user/backlinkemail/`;

export const USER_BACKLINK_LIST_URL = () =>
  `https://app.rankspro.io/api/user/selectbacklinkurlemail/`;

export const USER_SMTP_CHECK = (email) =>
  `https://app.rankspro.io/api/user/usersmtpcheck/?email=${email}`;

export const USER_SMTP_ADD = () => `https://app.rankspro.io/api/user/usersmtp/`;

// smtp send first mail
export const SMTP_FIRST_MAIL = () =>
  `https://app.rankspro.io/api/user/sendemail/`;

export const SMTP_SEQUENCE_MAIL = () =>
  `https://app.rankspro.io/api/user/seqemail/`;

export const GET_SMTP_DOMAIN_MAIL = (config) =>
  `https://app.rankspro.io/api/user/cehckseqemail/?email=${config.email}&domain=${config.domain}`;

export const SEND_BACKLINK_EMAIL = (config) =>
  `https://app.rankspro.io/api/user/sendbacklinkemail/?email=${config?.email}&recipient=${config?.to}&subject=${config?.subject}&body=${config?.body}`;
// Backlinks List Apis End

// Affilates API
export const GET_AFFLIATESLISTS = () =>
  `https://app.rankspro.io/api/user/affiliateGet/`;

export const POST_AFFILIATE_APPROVE = (EMAIL, AFF_LINK, aff_id) =>
  `https://app.rankspro.io/api/user/affiliateapproved/?email=${EMAIL}&aff_id=${aff_id}&aff_link=${AFF_LINK}`;
export const PUT_AFFILIATE_APPROVE = (EMAIL, AFF_LINK, aff_id) =>
  `https://app.rankspro.io/api/user/affapproveadmin/?email=${EMAIL}&aff_id=${aff_id}&aff_link=${AFF_LINK}`;
export const POST_AFFILIATE_APPROVE_CLICK_COUNT = (aff_id) =>
  `https://app.rankspro.io/api/user/affclickapprove/?aff_id=${aff_id}&click=0`;

export const POST_AFFILIATE_SEND_APPROVE_MAIL = (config) =>
  `https://app.rankspro.io/api/user/affapprovemail/?email=${config.email}&aff_id=${config.aff_id}&aff_link=${config.aff_link}`;

export const GET_AFFILIATE_CLICK = (aff_id) =>
  `https://app.rankspro.io/api/user/affclickget/?aff_id=${aff_id}`;

// Admin > Update Review
export const PUT_UPDATE_REVIEW = (id) =>
  `https://app.rankspro.io/api/user/digitalupdate/${id}/`;

// google console api
export const GET_Google_Console_Data = (email, domain) =>
  `https://app.rankspro.io/api/user/getconsoledata/?email=${email}&domain_name=${domain}`;
export const POST_Google_Console_Data = (config) =>
  `https://app.rankspro.io/api/user/consoledata/?email=${config.email}&refresh_token=${config.refresh_token}&site=${config.site}&domain=${config.domain}`;
export const GET_Google_Console_Site = (email, domain) =>
  `https://app.rankspro.io/api/user/consdata/?email=${email}&domain=${domain}`;

// user last login date
export const GET_USER_LAST_LOGIN = (email) =>
  `https://app.rankspro.io/api/user/lastloginget/?email=${email}`;
export const POST_USER_LAST_LOGIN = () =>
  `https://app.rankspro.io/api/user/lastlogin/`;
export const UPDATE_USER_LAST_LOGIN = (id) =>
  `https://app.rankspro.io/api/user/lastloginupdate/${id}/`;
// user last login all user list
export const GET_ALL_USER_LAST_LOGIN_LIST = () =>
  `https://app.rankspro.io/api/user/lastloginallget/`;

// keyword question based keyword
export const GET_QUSETION_BASED_KEYWORD = (item) =>
  `https://app.rankspro.io/api/user/queskeywordget/?keyword=${item.keyword}&location_code=${item.locationcode}&language_code=${item.language_code}`;
export const POST_QUSETION_BASED_KEYWORD = (item) =>
  `https://app.rankspro.io/api/user/queskeyword/?keyword=${item.keyword}&location_code=${item.locationcode}&language_code=${item.language_code}`;

export const GET_CUS_PLAN = (email) =>
  `https://app.rankspro.io/api/user/cusplan/?email=${email}`;

export const GET_AI_BASED_KEYWORD = (config) =>
  `https://app.rankspro.io/api/user/getquesbasedkey/?keyword=${config.keyword}`;

export const POST_AI_BASED_KEYWORD = (config) =>
  `https://app.rankspro.io/api/user/quesbasedkey/?ques_keyword=${config.keyword}`;

// admin user account usages
export const GET_USER_ACC_USAGE = (email) =>
  `https://app.rankspro.io/api/user/usertotalusage/?email=${email}`;

// competeoitors
export const PUT_UPDATE_COMPETOTORS = (id, list) =>
  `https://app.rankspro.io/api/user/updatecomp/${id}/?competitor=${list}`;
export const DELETE_COMPETOTORS = (id, list) =>
  `https://app.rankspro.io/api/user/delcomp/${id}/?competitor=${list}`;

// keyword tag
export const GET_KEYWORD_TAG = (tag) =>
  `https://app.rankspro.io/api/user/keytagget/?email=${tag.email}&tag_type=${tag.tag_type}&proj_name=${tag.proj_name}&location_name=${tag?.location_name}&language_name=${tag?.language_name}`;

export const UPDATE_KEYWORD_TAG = (tag) =>
  `https://app.rankspro.io/api/user/keytagupdate/?email=${tag.email}&tag_type=${
    tag.tag_type
  }&tag_name=${tag.tag_name}&proj_name=${
    tag.proj_name
  }&keyword=${encodeURIComponent(
    JSON.stringify(tag.keyword_list)
  )}&location_name=${tag?.location_name}&language_name=${tag?.language_name}`;

export const POST_KEYWORD_TAG = () =>
  `https://app.rankspro.io/api/user/keytag/`;

export const DELETE_KEYWORD_TAG = (tag) =>
  `https://app.rankspro.io/api/user/delkeytag/?email=${tag.email}&tag_type=${
    tag.tag_type
  }&tag_name=${tag.tag_name}&proj_name=${
    tag.proj_name
  }&keyword=${encodeURIComponent(
    JSON.stringify(tag.keyword_list)
  )}&location_name=${tag?.location_name}&language_name=${tag?.language_name}`;

export const INVITE_LIMIT_CHECK = (email, limit) =>
  `https://app.rankspro.io/api/user/chlimit/?email=${email}&limit=${limit}`;

//  Project Section
export const GET_PROJECT_SEC_DATA = (email) =>
  `https://app.rankspro.io/api/user/projectsec/?email=${email}`;

// admin
export const GET_ADD_ON_PLAN = () =>
  `https://app.rankspro.io/api/user/getaddon/`;

export const UPDATE_ADDON = (id) =>
  `https://app.rankspro.io/api/user/updateaddon/${id}/`;

export const POST_ADDON = () => `https://app.rankspro.io/api/user/addon/`;

export const GET_USER_ADDON = (email) =>
  `https://app.rankspro.io/api/user/useraddon/?email=${email}`;
