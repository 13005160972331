import { create } from "zustand";
export const Store = create((set) => ({
  OTP: "",
  RegisterData: {},
  locationName: "United States",
  phone: "",
  Resend: false,
  email: "",
  ForgotOTP: null,
  RankUpPercent: 0,
  RankDownPercent: 0,
}));
