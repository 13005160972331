import React from "react";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Filler,
  Legend,
} from "chart.js";
import { Line } from "react-chartjs-2";

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Filler,
  Legend
);

const KeywordRanksChart = (props) => {
  const labels = props.labels;
  const dataSet = props.dataset;

  const options = {
    maintainAspectRatio: false,
    responsive: true,
    interaction: { mode: "index", intersect: false },
    plugins: {
      legend: { position: "top", display: false },
      title: { display: false, text: "Chart.js Line Chart" },
      gradientColors: {
        backgroundColor: {
          axis: "y",
          colors: {
            0: "red",
            50: "yellow",
            100: "green",
          },
        },
      },
      tooltip: {
        displayColors: false,
        boxPadding: 5,
        cornerRadius: 10,
        caretSize: 10,
        padding: 10,
        margin: 10,
        boxHeight: 30,
        borderWidth: 1,
        borderColor: "#00000033",
        boxWidth: 4,
        titleColor: "black",
        bodyColor: "black",
        bodyFont: {
          size: 14,
          weight: 500,
          font: "Lato",
        },
        bodySpacing: 10,
        usePointStyle: true,
        backgroundColor: "rgba(255, 255, 255, 0.9)",
        titleFont: {
          size: 14,
          weight: 600,
          font: "Lato",
        },
      },
    },

    scales: {
      x: {
        color: "black",
        grid: {
          display: false,
          color: "#dcdcdc",
        },

        ticks: {
          display: false,
          size: 16,
          weight: 500,
        },
      },
      y: {
        grid: {
          display: true,
          color: "#b8b0b0b8",
        },
        // min: 0,
        position: "left",
        reverse: true,
        ticks: {
          stepSize: 10,
          // color: "#eadede",
          color: "black",
          size: 16,
          weight: 500,
        },
      },
    },
    cubicInterpolationMode: "monotone",
  };

  if (document.getElementById("line-chart") !== null) {
    var ctx = document.getElementById("line-chart").getContext("2d");
    var gradient = ctx.createLinearGradient(100, 80, 100, 200);
    gradient.addColorStop(0, "rgba(59, 161, 197, 0.3)");
    gradient.addColorStop(1, "rgba(59, 161, 197, 0.1)");
  }
  const data = {
    labels,
    datasets: [
      {
        fill: "start",
        label: props.Keyword,
        data: dataSet,
        backgroundColor: gradient,
        borderColor: "#3BA1C5",
        borderWidth: 2,
        tension: 0.1,
        pointRadius: 0,
        pointHoverRadius: dataSet?.length > 90 ? 0 : 6,
        pointBackgroundColor: "#3BA1C5",
        pointBorderColor: "#3BA1C5",
      },
    ],
  };

  return (
    <div className="vertical-bar" style={{ height: "100px" }}>
      <Line options={options} data={data} id="line-chart" />
    </div>
  );
};

export default KeywordRanksChart;
