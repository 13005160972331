import React, { useState } from "react";
import { styled } from "@mui/material/styles";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import { useNavigate, useLocation, Link } from "react-router-dom";
import { Button, Typography } from "@mui/material";
import CustomButtonStyle from "./button.component";
import logo from "../../Assets/seoimg/logo.png";
import Accordion from "@mui/material/Accordion";
import AccordionDetails from "@mui/material/AccordionDetails";
import AccordionSummary from "@mui/material/AccordionSummary";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import MenuIcon from "@mui/icons-material/Menu";
import useMediaQuery from "@mui/material/useMediaQuery";

const seoTools = [
  {
    language: "SEO Insights",
    path: "/free-seo-insights-checker",
  },
  {
    language: "Rank Checker",
    path: "/free-rank-checker",
  },
  // {
  //   language: "Site Overview",
  //   path: "/free-site-overview-checker",
  // },
  {
    language: "Backlink Checker",
    path: "/free-backlink-checker",
  },
  {
    language: "Caption Generator",
    path: "/free-caption-generator",
  },
];

function ControlledAccordions() {
  const [expanded, setExpanded] = useState(false);
  const navigate = useNavigate();
  const location = useLocation();

  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

  const pathChangeHandler = (e) => {
    navigate(e.path);
  };

  return (
    <Accordion
      expanded={expanded === "panel1"}
      onChange={handleChange("panel1")}
      sx={{
        backgroundColor: "transparent",
        boxShadow: "none",
        "& .MuiButtonBase-root": {
          minHeight: "30px !important",
        },
      }}
    >
      <AccordionSummary
        expandIcon={<ExpandMoreIcon />}
        aria-controls="panel1bh-content"
        id="panel1bh-header"
        sx={{
          color: "#365486",
          "& .MuiAccordionSummary-content": {
            padding: 0,
            margin: "0px !important",
          },
        }}
        className="m-0 p-0"
      >
        <Typography sx={{ width: "33%", flexShrink: 0 }}>Tools</Typography>
      </AccordionSummary>
      <AccordionDetails
        sx={{
          padding: 0,
          margin: 0,
        }}
        className="m-0 p-0"
      >
        <ul className="w-100">
          {seoTools?.map((menu, index) => {
            return (
              <li
                key={`menu-sm-${index}`}
                onClick={() => pathChangeHandler(menu)}
                className={
                  location.pathname === menu?.path
                    ? "menu-sm-active"
                    : "menu-not-active"
                }
              >
                {menu.language}
              </li>
            );
          })}
        </ul>
      </AccordionDetails>
    </Accordion>
  );
}

const StyledMenu = styled((props) => (
  <Menu
    elevation={0}
    anchorOrigin={{
      vertical: "bottom",
      horizontal: "right",
    }}
    transformOrigin={{
      vertical: "top",
      horizontal: "right",
    }}
    className="add-border"
    {...props}
  />
))(({ theme }) => ({
  "& .MuiPaper-root": {
    borderRadius: 6,
    top: "50px !important",
    marginTop: theme.spacing(1),
    minWidth: 190,
    color: "#365486",
    boxShadow:
      "rgb(255, 255, 255) 0px 0px 0px 0px, rgba(0, 0, 0, 0.05) 0px 0px 0px 1px, rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px",
    "& .MuiMenu-list": {
      padding: "4px 0",
      border: "1px solid #365486",
      borderRadius: 6,
    },
    backgroundColor: "rgba(240, 242, 255)",
    "& .MuiMenuItem-root": {
      "& .MuiSvgIcon-root": {
        fontSize: 18,
        color: "#365486",
        marginRight: theme.spacing(1.5),
      },
      "&:active": {
        backgroundColor: "#e95655",
      },
    },
  },
}));

function CustomizedMenus({ anchorEl, setAnchorEl, open }) {
  const navigate = useNavigate();
  const location = useLocation();

  const pathChangeHandler = (e) => {
    setAnchorEl(null);
    navigate(e.path);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <div>
      <Button
        id="demo-customized-button"
        aria-controls={open ? "demo-customized-menu" : undefined}
        aria-haspopup="true"
        aria-expanded={open ? "true" : undefined}
        variant="outlined"
        disableElevation
        className="nav-dropdown"
        onClick={(e) => setAnchorEl(e.currentTarget)}
        endIcon={<KeyboardArrowDownIcon />}
      >
        Free Tools
      </Button>
      <StyledMenu
        id="demo-customized-menu"
        MenuListProps={{
          "aria-labelledby": "demo-customized-button",
        }}
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
      >
        {seoTools?.map((menu, index) => {
          return (
            <MenuItem
              key={`menu-${index}`}
              onClick={() => pathChangeHandler(menu)}
              disableRipple
              className={
                location.pathname === menu?.path
                  ? "menu-active"
                  : "menu-not-active"
              }
            >
              {menu.language}
            </MenuItem>
          );
        })}
      </StyledMenu>
    </div>
  );
}

const ProType = ({ open }) => {
  return (
    <div className="nv-select" style={{ marginRight: "20px", border: "none" }}>
      <div>
        <div
          className={
            open
              ? "d-flex nv-select_con add-border"
              : "nv-select_con add-border"
          }
          style={{ width: "190px" }}
        >
          <ul>
            <li>
              <ControlledAccordions />
            </li>
            <li className="heading-small font-regular">
              <Link to="/login">Sign In</Link>
            </li>
            <li className="heading-small font-regular">
              <Link to="/register">Get Started</Link>
            </li>
          </ul>
        </div>
      </div>
      <div className="">
        <MenuIcon fontSize="large" />
      </div>
    </div>
  );
};

function ToolNav() {
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const sm = useMediaQuery("(min-width:600px)");

  return (
    <div>
      <div
        className="navBar"
        style={{
          height: "70px",
          borderBottom: "none",
          background: "transparent",
        }}
      >
        <div
          className={
            !sm ? "d-flex w-100 justify-content-between" : "d-flex w-100"
          }
        >
          <div className="nv-logo">
            <a href="https://rankspro.io/" target="_blank">
              <img src={logo} alt="RanksPro.io Logo" />
            </a>
          </div>
          {!sm ? (
            <ProType open={open} />
          ) : (
            <>
              <div
                className="d-flex align-items-center"
                style={{ marginLeft: "30px" }}
              >
                <CustomizedMenus
                  open={open}
                  setAnchorEl={setAnchorEl}
                  anchorEl={anchorEl}
                />
              </div>
              <ul className="nv-ul ms-auto">
                <li className="me-3">
                  <Link to="/login">
                    <CustomButtonStyle
                      name="Sign In"
                      hover="#365486"
                      variant="contained"
                      className="font-regular btn-register heading-small"
                    />
                  </Link>
                </li>
                <li className="">
                  <Link to="/register">
                    <CustomButtonStyle
                      name="Get Started"
                      hover="#365486"
                      variant="outlined"
                      className="font-regular heading-small btn-signin"
                    />
                  </Link>
                </li>
              </ul>
            </>
          )}
        </div>
      </div>
      <div className="nav-banner mt-2">
        <p
          className="heading-small font-regular m-0"
          style={{
            textAlign: !sm ? "center" : "initial",
            maxWidth: !sm ? "85%" : "100%",
          }}
        >
          Learn more about{" "}
          <strong style={{ cursor: "pointer" }}>RanksPro.io</strong>, Brand
          Authority, please visit &nbsp;
          <a
            target="_blank"
            href="https://rankspro.io/"
            className="nav-redirect"
          >
            https://rankspro.io/
          </a>
        </p>
      </div>
    </div>
  );
}

export default ToolNav;
