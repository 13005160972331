import React, { useEffect, useState } from "react";
import LOGIN from "../../assets/images/Login/login.png";
import MAIL from "../../assets/images/Login/sms.png";
import PASS from "../../assets/images/Login/lock.png";
import LOGO from "../../assets/images/Global/logo.png";
import "./Login.scss";
import { Link, useNavigate } from "react-router-dom";
import { Field, Form, Formik, ErrorMessage } from "formik";
import axios from "axios";
import { LOGGED, GET_PROFILE, GET_PROFILE1 } from "../../utils/Api";
import { YUPlogin } from "./YupSchema";
import { ToastContainer, toast } from "react-toastify";
import { IoEyeOff, IoEye } from "react-icons/io5";
import { useDispatch } from "react-redux";

export const AffiliateLogin = () => {
  // Get a reference to the input field
  const [PassSecure, setPassSecure] = useState(false);
  const dispatch = useDispatch();
  const inputField = document.getElementById("myInput");
  const [inputValue, setInputValue] = useState("");
  const nav = useNavigate();
  // Disable autocomplete and autofill
  if (inputField) {
    inputField.setAttribute("autoComplete", "off");
    inputField.setAttribute("autoCorrect", "off");
    inputField.setAttribute("autocapitalize", "off");
    inputField.setAttribute("spellcheck", "false");
  }
  const [value, setValue] = useState("");

  const handleAutocomplete = (event) => {
    // event.preventDefault();
    // Set the autocomplete attribute to "off".
    event.target.setAttribute("autocomplete", "off");
  };

  const handleProfileData = (email) => {
    dispatch({ type: "LOADING" });
    axios.get(GET_PROFILE(email)).then(({ data }) => {
      axios.get(GET_PROFILE1(email)).then((res) => {
        const user = {
          profile1: data[0],
          profile2: res?.data?.length === 0 ? res.data : res.data[0],
        };
        dispatch({ type: "LOGIN", payload: true });
        dispatch({ type: "PROFILE", payload: user });
        dispatch({ type: "NOTLOADING" });
        nav("/affiliate/dashboard");
      });
    });
  };

  const HandleSubmit = ({ email, password }, resetForm) => {
    const PAYLOAD = {
      email,
      password,
    };
    axios.post(LOGGED(PAYLOAD)).then(({ data }) => {
      if (data.msg === "Login Successfull") {
        dispatch({ type: "LOGIN", payload: true });
        localStorage.setItem("AFFTOKEN", data?.token?.access);
        localStorage.setItem("AFFDetails", email);
        localStorage.setItem("EMAIL", email);
        handleProfileData(email);
      } else if (data.msg === "Invalid Credentials") {
        dispatch({ type: "LOGIN", payload: false });
        toast.error(data.msg, {
          position: "top-right",
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        });
      }
    });
  };
  return (
    <div className="LoginContainer">
      <ToastContainer />
      <div className="Login">
        <div className="row">
          <div className="col-md-6 col-lg-6 d-none d-md-block d-lg-block">
            <div className="log_left">
              <img src={LOGIN} alt="Login " />
            </div>
          </div>
          <div className="col-md-6 col-lg-6">
            <div className="log_Right">
              <div className="log_content login_content">
                <div className="logo">
                  <img src={LOGO} alt="" />
                </div>
                <h1>Login to your Account</h1>
                <Formik
                  initialValues={{
                    email: "",
                    password: "",
                  }}
                  onSubmit={(value, { resetForm }) => {
                    HandleSubmit(value, resetForm);
                  }}
                  validationSchema={YUPlogin}
                >
                  <Form autoComplete="off">
                    <div className="Feilds Login_fields">
                      <div className="INPUTPR">
                        <div className="input_group">
                          <img src={MAIL} />{" "}
                          <Field
                            type="email"
                            name="email"
                            autoComplete="off"
                            placeholder="Enter Your Email"
                            id="myInput"
                          />
                        </div>
                        <ErrorMessage
                          name="email"
                          component="div"
                          className="error"
                        />
                      </div>
                      <div className="INPUTPR">
                        <div className="input_group">
                          <img src={PASS} />{" "}
                          <Field
                            type={PassSecure ? "text" : "password"}
                            name="password"
                            autoComplete="new-password"
                            placeholder="Enter Your Password"
                            id="myInput"
                          />
                          {PassSecure ? (
                            <IoEye
                              style={{
                                position: "absolute",
                                right: "10px",
                                color: "white",
                                width: "25px",
                                height: "20px",
                                top: "13px",
                              }}
                              onClick={() => setPassSecure(false)}
                            />
                          ) : (
                            <IoEyeOff
                              style={{
                                position: "absolute",
                                right: "10px",
                                color: "white",
                                width: "25px",
                                height: "20px",
                                top: "13px",
                              }}
                              onClick={() => setPassSecure(true)}
                            />
                          )}
                        </div>
                        <ErrorMessage
                          name="password"
                          component="div"
                          className="error"
                        />
                      </div>
                      <div
                        className="input_group"
                        style={{
                          opacity: 0,
                          position: "absolute",
                          top: "-1000px",
                        }}
                      >
                        <img src={MAIL} />{" "}
                        <Field
                          type="text"
                          name="dummy-username"
                          autoComplete="new-username"
                        />
                      </div>
                      <Field
                        type="password"
                        name="dummy-password"
                        autoComplete="new-password"
                        style={{
                          opacity: 0,
                          position: "absolute",
                          top: "-1000px",
                        }}
                      />
                    </div>
                    <p>
                      <Link to={"/affiliate/forgot"}>Forgot Password</Link>
                    </p>
                    <div className="FlexBtn">
                      <button>Login</button>
                    </div>
                    <div className="OR">
                      <div className="line1"></div>
                      <span>OR</span>
                      <div className="line1"></div>
                    </div>
                    <div className="RegisterLink">
                      <p>
                        Don’t have an account?{" "}
                        <Link to="/affiliate/signup">Register Now</Link>
                      </p>
                    </div>
                  </Form>
                </Formik>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
