import React from "react";
import { Link } from "react-router-dom";
import ToolTip from "../../../Mycomponent/share/components/tooltip";
import { ReactComponent as CrossIcon } from "../../../Mycomponent/Assets/rank-tracking-assets/cross.svg";
import greenTickIcon from "../../../Mycomponent/Assets/seo-impact/green-tick.svg";
import redIcon from "../../../Mycomponent/Assets/seo-impact/red-excl.svg";

export const SeoImpactModal = ({
  values,
  seoImpactAlert,
  setseoImpactAlert,
  ImpactData,
  seoImpactLoader,
}) => {
  const NumberRangeCheck = (number, min, max) => {
    if (number >= min && number <= max) {
      return true;
    } else {
      return false;
    }
  };

  const lengthCheck = (title, value) => {
    const length = value?.length || 0;
    const lenValue =
      title === "Meta Description"
        ? NumberRangeCheck(length, 70, 155)
        : NumberRangeCheck(length, 50, 65);

    if (title === "Meta Description" || title === "Title") {
      return (
        <div className="seo-inspect-char-box">
          <div className="d-flex align-items-center">
            <span
              className="heading-xs font-regular"
              style={{ paddingRight: "5px" }}
            >
              {length} Characters
            </span>
          </div>
          <ToolTip
            title={
              title === "Meta Description"
                ? "Meta Description of URL. An ideal length for description would be 70-155 characters."
                : "Title of URL. An ideal length for Title would be 55-65 characters."
            }
          />
          {lenValue ? (
            <img src={greenTickIcon} alt="green-tick" className="mx-2" />
          ) : (
            <img src={redIcon} alt="red-tick" className="mx-2" />
          )}
        </div>
      );
    } else {
      return null;
    }
  };

  return (
    <>
      {seoImpactAlert ? (
        <div className="pop ">
          <div className="popBodySeoImpactTool">
            <div className="SeoImpactHeadTool">
              <h4 className="mb-0 heading-large font-bold">SEO Impact</h4>
              <CrossIcon
                style={{
                  width: "30px",
                  height: "30px",
                  cursor: "pointer",
                  float: "right",
                  cursor: "pointer",
                  top: "15px",
                }}
                onClick={() => setseoImpactAlert(false)}
              />
            </div>
            {/* <form> */}
            <div className="pop-form">
              <div className="listModalHeightTool list-scroll">
                <div className="container-fluid">
                  <div className="row">
                    <div className="col-md-12 col-12">
                      <div className="AddListBox">
                        <div
                          className="add-Seo-url border"
                          style={{ borderRadius: "5px" }}
                        >
                          <div className="row ">
                            <div className="SeoHeadTool">
                              <div className="row">
                                <div className="col-6">
                                  <div className="DmName heading-small">
                                    <h2 className="heading-small font-bold mb-0">
                                      SEO Impact Keyword :
                                    </h2>
                                    {values.keyword.length <= 59 ? (
                                      <p className="heading-small">
                                        {" "}
                                        {values.keyword}
                                      </p>
                                    ) : (
                                      <p className="heading-small">
                                        {" "}
                                        {values.keyword.substring(0, 60)}...
                                      </p>
                                    )}
                                    {/* <p>{values?.keyword}</p> */}
                                  </div>
                                </div>
                                <div className="col-6">
                                  <div className="UrlName heading-small">
                                    <h2 className="heading-small font-bold mb-0">
                                      SEO Impact URL :{" "}
                                    </h2>
                                    <Link to={values.url}>
                                      {values.url.length <= 40 ? (
                                        <p>{values.url}</p>
                                      ) : (
                                        <p>{values.url.substring(0, 60)}...</p>
                                      )}
                                    </Link>
                                  </div>
                                </div>
                              </div>
                            </div>

                            <div className="row mt-4">
                              {ImpactData?.map((item) => {
                                return Object.entries(item).map(
                                  ([key, value]) =>
                                    key.split("_").join(" ") ===
                                      "Meta Description" ||
                                    key.split("_").join(" ") === "Title" ? (
                                      <div className="col-12 col-md-6">
                                        <div className="seo-inspect-cont">
                                          <h1 className="font-light-bold heading-xs">
                                            {key.split("_").join(" ")}
                                          </h1>
                                          {lengthCheck(
                                            key.split("_").join(" "),
                                            value
                                          )}
                                          <p className="my-4 font-medium heading-xs">
                                            {value}
                                          </p>
                                        </div>
                                      </div>
                                    ) : null
                                );
                              })}
                            </div>

                            {/* <div className="col-7 col-md-7 col-lg-7 "> */}
                            <div className="ForScroll list-scroll">
                              {seoImpactLoader ? (
                                <div class="spinner-border" role="status">
                                  <span class="visually-hidden">
                                    
                                  </span>
                                </div>
                              ) : ImpactData.length > 0 ? (
                                ImpactData.map((item, index) => (
                                  <div key={index} className="data-item">
                                    {Object.entries(item).map(([key, value]) =>
                                      key.split("_").join(" ") ===
                                        "Meta Description" ||
                                      key.split("_").join(" ") ===
                                        "Title" ? null : (
                                        <div
                                          key={key}
                                          className="row pb-1 pt-1 border-bottom  alterBG"
                                          style={{ height: "50px" }}
                                        >
                                          <div
                                            className="font-medium heading-xs d-flex flex-column flex-md-row justify-content-center justify-content-md-start justify-content-lg-start  align-items-start align-items-md-center align-items-lg-center   flex-lg-row col-6 col-lg-6 col-md-6 text-capitalize fw-normal p-1 ps-4 "
                                            style={{
                                              textAlign: "left",
                                              alignItems: "center",
                                            }}
                                          >
                                            {key.split("_").join(" ")}
                                          </div>
                                          <div className="col-6 col-lg-6 col-md-6 p-0 ps-3 m-auto font-medium heading-xs">
                                            {value}
                                          </div>
                                        </div>
                                      )
                                    )}
                                  </div>
                                ))
                              ) : (
                                <h1 className="heading-medium font-bold">
                                  No data Found
                                </h1>
                              )}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="footerSeoTool">
              <div className="row">
                <div className="col-8">
                  <h5> Want to see all data?</h5>
                  <h6>
                    Sign up for Rankspro.io to check Insights of any website or
                    URL.
                  </h6>
                </div>
                <div className="col-4 d-flex justify-content-end align-items-center">
                  <Link to="/register">
                    <button className="SeoToolPrice">
                      Start 7-Day Free Trial
                    </button>
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      ) : (
        false
      )}
    </>
  );
};
