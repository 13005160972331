import React, { useState } from "react";
import "./component.scss";
import { Store } from "../utils/STORE";
export const Dropdown = ({ data, VALUE, setVALUE, Ref,Ref2, Show, setShow }) => {
  const [Search, setSearch] = useState("");

  const HandleToggle = () => {
    setShow(!Show);
  };
  return (
    <>
      <div className="dropContainer">
        <div className="showInput">
          <div className="DropBox">
            <img src={VALUE?.img} alt={VALUE?.name|| "sel-icon"} />
            <input
              ref={Ref2}
              onClick={HandleToggle}
              type="text"
              placeholder="Choose Location"
              readOnly
              id="locationInput"
              value={VALUE?.name}
            />
          </div>
          {Show && (
            <>
              <input
                ref={Ref}
                id="dropSearch"
                type="text"
                placeholder="Search..."
                onChange={(e) => setSearch(e.target.value)}
              />
              <div className="lists">
                <ul>
                  {data
                    ?.filter((FilterData) => {
                      if (Search == "") {
                        return FilterData;
                      } else if (
                        FilterData.location_name
                          .toLowerCase()
                          .includes(Search.toLowerCase())
                      ) {
                        return FilterData;
                      }
                    })
                    .map((res) => (
                      <li
                        onClick={() => {
                          Store.setState({ locationName : res.location_name });
                          setVALUE({
                            img: res.location_flag,
                            name: res.location_name,
                          });
                          setShow(false);
                        }}
                      >
                        <span>
                          <img src={res.location_flag} alt={res?.location_name} />
                        </span>
                        {res.location_name}
                      </li>
                    ))}
                </ul>
              </div>
            </>
          )}
        </div>
      </div>
    </>
  );
};
