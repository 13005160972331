import React from "react";
import { List, AutoSizer } from "react-virtualized";

function VirtualizeList({ data, rowRenderer, rowHeight }) {
  return (
    <AutoSizer>
      {({ height, width }) => (
        <List
          className="virtualize-list"
          width={width}
          height={height || 150}
          rowCount={data?.length}
          rowHeight={rowHeight || height}
          rowRenderer={rowRenderer}
          overscanRowCount={30}
        />
      )}
    </AutoSizer>
  );
}

export default VirtualizeList;
