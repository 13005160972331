import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import "../css/pdf-viewer.scss";
import defaultLogo from "../Assets/seoimg/logo.png";
import { DottLoader1 } from "../share/loader";
import { useNavigate } from "react-router-dom";
import KeywordPdf from "../share/pdf/keyword";
import { BlobProvider } from "@react-pdf/renderer";
import backIcon from "../Assets/common/back.svg";
import RankTrackingPdf from "../share/pdf/new.pdf";
import { ZustandStore } from "../../store/zustand-store";
import { checkIfAdmin } from "../../helperJs/helper";
import { stringToBoolean } from "../../helperJs/helper";

function PdfViewer() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { plan_details } = ZustandStore((state) => state);
  const user_email = useSelector((state) => state.email);
  const pdfData = useSelector((state) => state.pdfviewerdata);
  const [pdfLogo, setPdfLogo] = useState({
    src: null,
    position: "flex-start",
    type: null,
  });
  const [footerText, setFooterText] = useState("");
  const [pdfNewData, setPdfNewData] = useState({
    reset: true,
    logo: null,
    footer: "",
  });

  const codeValid = plan_details?.referral_user?.code_valid;
  const codeValidJsonString = codeValid?.replace(/'/g, '"');
  const codeValidJsonStringParsedArray =
    (codeValidJsonString && JSON.parse(codeValidJsonString)) || [];

  const admin_auth =
    stringToBoolean(plan_details?.white_label) ||
    checkIfAdmin(user_email) ||
    codeValidJsonStringParsedArray?.length === 3 ||
    false;

  const Pdf_editable = admin_auth
    ? admin_auth
    : (plan_details?.user_type === "subscription" &&
        plan_details?.plan_name === "ultimate") ||
      false;

  let pdf_editable = stringToBoolean(Pdf_editable);

  useEffect(() => {
    const checkArrayIsEmpty = () => {
      if (pdfData.length === 0) {
        navigate(-1);
      }
    };
    dispatch({ type: "NOTLOADING" });
    const timeoutId = setTimeout(checkArrayIsEmpty, 10000);
    return () => clearTimeout(timeoutId);
  }, [pdfData]);

  const processFile = (file) => {
    if (file) {
      if (file.type.startsWith("image/")) {
        const reader = new FileReader();
        reader.onloadend = () => {
          setPdfLogo((prevPdfLogo) => ({
            ...prevPdfLogo,
            src: reader.result,
            type: file,
          }));
        };
        reader.readAsDataURL(file);
      } else {
        alert("Unsupported file type. Please upload an image or a PDF.");
        setPdfLogo((prevPdfLogo) => ({
          ...prevPdfLogo,
          src: null,
          type: null,
        }));
      }
    }
  };

  const handleFileChange = (event) => {
    const file = event.target.files[0];
    processFile(file);
    document.getElementById("file-input").value = "";
  };

  const handleUpload = () => {
    console.log("File uploaded:", pdfLogo.type);
  };

  const handleDrop = (event) => {
    event.preventDefault();
    const file = event.dataTransfer.files[0];
    processFile(file);
  };

  const handleDragOver = (event) => {
    event.preventDefault();
  };

  const newPdfUpdatedData = () => {
    const data = {
      logo: pdfLogo,
      footer: footerText,
      reset: false,
    };
    pdf_editable && setPdfNewData(data);
  };

  const resetPdfUpdatedData = () => {
    const data = {
      logo: null,
      footer: "",
      reset: true,
    };
    setPdfLogo({ src: null, position: "flex-start", type: null });
    setPdfNewData(data);
  };

  const handleBackButton = () => {
    if (pdfData?.type === "rank") {
      navigate("/rank-tracker");
    } else if (pdfData?.type === "keyword") {
      navigate("/keyword-research");
    } else {
      navigate("/dashboard");
    }
  };

  return (
    <>
      <button className="icon-btn large-btn mt-3" onClick={handleBackButton}>
        <img src={backIcon} alt="" />
      </button>
      <div className="pdf-viewer-container row gy-3">
        <div className="pdf-update-sec col-12 col-md-5 col-lg-4">
          <h1>PDF Editor</h1>
          <div className="img-box">
            {pdfLogo?.src && pdfLogo?.type?.type.startsWith("image/") ? (
              <img src={pdfLogo?.src} alt="brand-logo" />
            ) : (
              <img src={defaultLogo} alt="brand-logo" />
            )}
          </div>
          <div className="pdf-edit-view">
            {pdf_editable ? null : (
              <div className="pdf-editable">
                <div className="pdf-box-upgrade">
                  <p>
                    Upgrade your current plan to unlock professional PDF editing
                    features and improve your workflow efficiency.
                  </p>
                  <button onClick={() => navigate("/upgrade")}>Upgrade</button>
                </div>
              </div>
            )}
            <div style={{ filter: pdf_editable ? "none" : "blur(2px)" }}>
              <div className="change-logo">
                <label>Change Your Brand Logo</label>
                <div
                  onDrop={handleDrop}
                  onDragOver={handleDragOver}
                  style={{
                    border: "2px dashed #ccc",
                    borderRadius: "4px",
                    padding: "20px",
                    textAlign: "center",
                    cursor: "pointer",
                    position: "relative",
                    height: "100px",
                  }}
                  className="mt-2"
                >
                  <label
                    htmlFor="file-input"
                    style={{
                      position: "absolute",
                      top: 0,
                      left: 0,
                      width: "100%",
                      height: "100%",
                      flexDirection: "column",
                    }}
                    className="d-flex align-items-center justify-content-center"
                  >
                    {/* <MdCloudUpload /> */}
                    <p className="mb-0">
                      Drag and drop a image here or click to select a image
                    </p>
                  </label>
                  <input
                    id="file-input"
                    name="file-input"
                    disabled={!pdf_editable}
                    type="file"
                    accept="image/*, application/pdf"
                    onChange={handleFileChange}
                    style={{ display: "none" }}
                  />
                </div>
                {/* <input type="checkbox" /> */}
              </div>
              <div className="mt-3 logo-position">
                <p className="mb-1">Please Select LOGO Position:</p>
                <div className="d-flex radio-btn">
                  <label htmlFor="flex-start">Start</label>
                  <input
                    type="radio"
                    id="flex-start"
                    name="positions"
                    value="flex-start"
                    checked={pdfLogo.position === "flex-start"}
                    onChange={() =>
                      pdf_editable &&
                      setPdfLogo((prevPdfLogo) => ({
                        ...prevPdfLogo,
                        position: "flex-start",
                      }))
                    }
                  />
                  <label htmlFor="center">Center</label>
                  <input
                    type="radio"
                    id="center"
                    name="positions"
                    value="center"
                    checked={pdfLogo.position === "center"}
                    onChange={() =>
                      pdf_editable &&
                      setPdfLogo((prevPdfLogo) => ({
                        ...prevPdfLogo,
                        position: "center",
                      }))
                    }
                  />
                  <label htmlFor="flex-end">End</label>
                  <input
                    type="radio"
                    id="flex-end"
                    name="positions"
                    value="flex-end"
                    checked={pdfLogo.position === "flex-end"}
                    onChange={() =>
                      pdf_editable &&
                      setPdfLogo((prevPdfLogo) => ({
                        ...prevPdfLogo,
                        position: "flex-end",
                      }))
                    }
                  />
                </div>
              </div>
              <div className="mt-3 change-footer">
                <label htmlFor="footer-input">Change PDF Footer</label>
                <input
                  type="text"
                  id="footer-input"
                  name="footer-input"
                  disabled={!pdf_editable}
                  className="mt-2"
                  placeholder="Enter Footer text"
                  value={footerText}
                  onChange={(e) => setFooterText(e.target.value)}
                />
                <div className="footer-notes">
                  <span>*</span>
                  <small>Don't Want Footer Leave It Blank</small>
                </div>
              </div>
              <div className="colloction-btn">
                <button
                  onClick={newPdfUpdatedData}
                  className="btn_3"
                  disabled={!pdf_editable}
                >
                  Update PDF
                </button>
                <button
                  onClick={resetPdfUpdatedData}
                  className="btn_3"
                  disabled={!pdf_editable}
                >
                  Reset PDF
                </button>
              </div>
            </div>
          </div>
        </div>
        <div
          className="col-12 col-md-7 col-lg-8 pdf-view-sec"
          style={{ minHeight: "60vh" }}
        >
          {pdfData?.length === 0 ? (
            <div className="loader">
              <DottLoader1 />
            </div>
          ) : (
            <BlobProvider
              document={
                pdfData?.type === "rank" ? (
                  <RankTrackingPdf
                    table_data={pdfData?.data}
                    data={pdfNewData}
                  />
                ) : (
                  <KeywordPdf
                    data={pdfData?.data?.data}
                    Src={pdfData?.data?.src}
                    newData={pdfNewData}
                  />
                )
              }
            >
              {({ url, loading }) => {
                return loading ? (
                  <div className="loader">
                    <DottLoader1 />
                  </div>
                ) : (
                  <div style={{ height: "100vh" }}>
                    <object
                      data={url}
                      type="application/pdf"
                      width="100%"
                      height="100%"
                    >
                      <p>
                        If the PDF preview is not available, you can download
                        the file using the link provided below.
                      </p>
                      <a
                        target="_blank"
                        href={url}
                        download="default_filename.pdf"
                      >
                        Download PDF
                      </a>
                    </object>
                  </div>
                );
              }}
            </BlobProvider>
          )}
        </div>
      </div>
    </>
  );
}

export default PdfViewer;
