import React, { useState } from "react";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
import { Bar } from "react-chartjs-2";
import { formatNumberWithSymbols } from "../../../helperJs/helper";

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
);

const StackedBarChartMulti = (props) => {
  const pos1to3 = [...props.pos1to3].reverse();
  const pos4to10 = [...props.pos4to10].reverse();
  const pos11to50 = [...props.pos11to50].reverse();
  const pos51to100 = [...props.pos51to100].reverse();
  const labels = [...props.etvlabel].reverse();

  const data = {
    labels,
    datasets: [
      {
        label: "POS 1-3 ",
        data: pos1to3,
        backgroundColor: "#F3A344",
        borderWidth: 0,
        borderSkipped: "start",
        borderRadius: 1,
        barPercentage: 1, // Remove gap between bars for Dataset 2
        categoryPercentage: 0.1, // Remove gap between bars for Dataset 2
        barThickness: 12,
      },
      {
        label: "POS 4-10 ",
        data: pos4to10,
        backgroundColor: "#3BA1C5",
        borderWidth: 0,
        borderRadius: 1,
        barPercentage: 1, // Remove gap between bars for Dataset 2
        categoryPercentage: 0.1, // Remove gap between bars for Dataset 2
        barThickness: 12,
      },
      {
        label: "POS 11-50 ",
        data: pos11to50,
        backgroundColor: "#365486",
        borderWidth: 0,
        borderRadius: 1,
        barPercentage: 1, // Remove gap between bars for Dataset 2
        categoryPercentage: 0.1, // Remove gap between bars for Dataset 2
        barThickness: 12,
      },
      {
        label: "POS 51-100",
        data: pos51to100,
        backgroundColor: "#E95655",
        borderWidth: 0,
        borderRadius: 1,
        barPercentage: 1, // Remove gap between bars for Dataset 2
        categoryPercentage: 0.1, // Remove gap between bars for Dataset 2
        barThickness: 12,
      },
    ],
  };

  const options = {
    maintainAspectRatio: false,
    responsive: true,
    plugins: {
      legend: {
        position: "top",
        display: false,
      },
      title: {
        display: false,
        text: "Chart.js Bar Chart - Stacked",
      },
      tooltip: {
        enabled: true,
        intersect: "point",
        callbacks: {
          label: (context) => {
            const datasetLabel = context.dataset.label || "";
            const value = context.parsed.y;
            return `${datasetLabel}: ${formatNumberWithSymbols(
              value
            )}`;
          },
        },
        boxPadding: 5,
        cornerRadius: 1,
        caretSize: 10,
        padding: 10,
        margin: 0,
        boxHeight: 30,
        borderWidth: 0,
        boxWidth: 10,
        titleColor: "black",
        bodyColor: "black",
        bodyFont: {
          size: 14,
          weight: 500,
          font: "Lato",
        },
        bodySpacing: 7,
        usePointStyle: true,
        backgroundColor: "#F0F0F0",
        titleFont: {
          size: 14,
          weight: 400,
          font: "Lato",
        },
      },
    },
    responsive: true,
    scales: {
      x: {
        grid: {
          display: false,
        },
        stacked: false,
        barPercentage: 1, // Remove the gap between bars
        categoryPercentage: 1, // Remove the gap between bars
      },
      y: {
        grid: {
          borderDash: [8, 4],
          display: true,
        },
        ticks: {
          callback: function (value) {
            const number = formatNumberWithSymbols(value);
            return number;
          },
        },
        stacked: false,
      },
    },
    elements: {
      bar: {
        barThickness: 10, // Set this value to make the bars as thick as available space
      },
    },
  };

  return (
    <div
      className="TRAFFIC_CHART"
      style={{ height: "210px", minWidth: "1500px" }}
    >
      <Bar options={options} data={data} />
    </div>
  );
};
export default StackedBarChartMulti;
