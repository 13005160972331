export const referral_plan = [
  {
    plan: 1,
    name: "referral_pro",
    proj_len: 25,
    keyword_len: 800,
    competitors_limit: 4,
    sub_users_limit: 2,
    site_overview_limit: 400,
    backlinks_rows_limit: 10000,
    backlinks_domain_lmt: 10,
    site_audit_limit: 1000,
    key_limit: 100,
    crawl_domain_limit: 5,
  },

  {
    plan: 2,
    name: "referral_elite",
    proj_len: 50,
    keyword_len: 1800,
    competitors_limit: 5,
    sub_users_limit: 4,
    site_overview_limit: 600,
    backlinks_rows_limit: 20000,
    backlinks_domain_lmt: 10,
    site_audit_limit: 5000,
    key_limit: 400,
    crawl_domain_limit: 10,
  },

  {
    plan: 3,
    name: "referral_ultimate",
    proj_len: 100,
    keyword_len: 3800,
    competitors_limit: 5,
    sub_users_limit: 10,
    site_overview_limit: 1000,
    backlinks_rows_limit: 40000,
    backlinks_domain_lmt: 10,
    site_audit_limit: 10000,
    key_limit: 1000,
    crawl_domain_limit: 15,
  },
];
