import React, { useState, useMemo, useRef } from "react";
import RanksProLogo from "../../../../Mycomponent/Assets/site-audit/RanksProLogo.png";
import { useSelector } from "react-redux";
import "jspdf-autotable";
import useKeywordTags from "../../../share/components/keyword-tag/getkeywordlist";
import CommonLineChart from "../../../share/charts/commonLineChart";
import trendSvg from "../../../Assets/rank-tracking-assets/trend.svg";
import { color } from "framer-motion";
import "../../../css/keyword.scss";
import {
  Page,
  Text,
  View,
  Document,
  StyleSheet,
  Image,
  Link,
  PDFDownloadLink,
} from "@react-pdf/renderer";
import { FiDownload } from "react-icons/fi";
import { useNavigate } from "react-router-dom";
import { getCurrentYear } from "../../../share/helper-js/current-year";

const styles = StyleSheet.create({
  page: {
    paddingHorizontal: 20,
    flexDirection: "column",
    paddingTop: 30,
    paddingBottom: 80, // Set vertical padding to 30 pixels
    borderStyle: "solid",
  },
  header: {
    // position: "absolute",
    // top: 20,
    // left: 15,
    // right: 0,
    paddingBottom:20
  },
  footer: {
    fontSize: 12,
    textAlign: "center",
    color: "grey",
    position: "absolute",
    bottom: 20,
    left: 0,
    right: 0,
  },
  logo: {
    width: 250,
  },
  title: {
    fontSize: 24,
    textAlign: "center",
    marginBottom: 20,
  },

  tableContainer: {
    marginBottom: 20,
  },
  table: {
    width: "100%",
    borderStyle: "solid",
    borderWidth: 1,
    display: "flex", // Use flex to create a flexible row layout
    flexDirection: "column", // Each table row is a flex column
  },
  tableRow: {
    display: "flex",
    flexDirection: "row", // Each cell is a flex item in a row
    borderBottomWidth: 1,
    borderColor: "#000",
    alignItems: "center",
  },
  keywordLoc: {
    flex: 1, // Each cell should take equal space within the row
    padding: 5,
    borderRightWidth: 1,
    borderColor: "#000",
    justifyContent: "start",
    minHeight: 40,
    height: "100%",
    alignItems: "start",
    flexDirection: "column",
  },
  tableCell: {
    flex: 1, // Each cell should take equal space within the row
    padding: 5,
    borderRightWidth: 1,
    borderColor: "rgb(221, 221, 221)",
    justifyContent: "center",
    alignItems: "center",
    flexDirection: "row",
  },
  fixedWidthCell: {
    maxWidth: 80, // Disable flex for fixed width cells
  },
  cardContainer: {
    flexDirection: "row",
    flexWrap: "wrap", // Enable flex wrapping
    justifyContent: "space-between", // Arrange cards with equal spacing
    marginTop: 10,
  },
  card: {
    flexDirection: "row",
    justifyContent: "space-between",
    width: 180,
    backgroundColor: "#FFFFFF",
    padding: 10,
    borderRadius: 5,
    marginBottom: 20,
  },
  image: {
    width: 30,
    height: 40,
  },
  title: {
    fontSize: 11,
    fontWeight: "bold",
    marginBottom: 5,
    color: "#6c757d",
  },
  description: {
    fontSize: 16,
  },
  imageContainer: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  arrowIcon: {
    width: 20,
    height: 20,
    objectFit: "contain",
  },
  view_button: {
    borderWidth: 1,
    borderColor: "#365486",
    padding: 5,
    borderRadius: 7,
    color: "#365486",
    fontSize: 11,
  },
  keyword_bold: {
    fontSize: 13,
    fontWeight: "bold",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  keyword: {
    fontSize: 11,
    color: "#6c757d",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  rank: {
    fontSize: 11,
    color: "#6c757d",
    textAlign: "center",
    marginRight: 10,
  },
  table_cell: {
    fontSize: 7,
    color: "#6c757d",
    textAlign: "center",
    marginVertical: 5,
  },
  table_header: {
    fontSize: 9,
    textAlign: "center",
  },
  pageNumber: {
    position: "absolute",
    fontSize: 11,
    bottom: 45,
    left: 0,
    right: 0,
    textAlign: "center",
    color: "grey",
  },
  link_text: {
    fontSize: 7,
    textDecoration: "none",
    textOverflow: "hidden",
    textAlign: "start",
  },
  logoHead: {
    width: 125,
  },
});









export const PdfFile = ({tableData,keywordName,pdfNewData}) => {
  
  const Data = JSON.parse(sessionStorage.getItem("pdfSessionData"));
  const Web_url = Data ? Data?.keywordName : keywordName;
  const sorted_Table_Data = Data ? Data?.tableData : tableData;
  const image_logo = pdfNewData
    ? pdfNewData?.reset
      ? RanksProLogo
      : pdfNewData?.logo?.src
    : RanksProLogo;


    // console.log(Data)
  return(
  <Document>
    <Page size={"A4"} style={styles.page}>
      <View
        style={{
          alignItems: "center",
          display: "flex",
          height: "82vh",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <Image style={styles.logo} src={image_logo} />
        <Text style={{ marginBottom: 20 }}>Keyword Research</Text>
        <Text style={styles.title}>keyword: {Web_url}</Text>
      </View>
</Page>
<Page size={"A4"} style={styles.page}>

<View
          style={{
            ...styles.header,
            alignItems: pdfNewData?.reset
              ? "flex-start"
              : pdfNewData?.logo.position,
          }}
          fixed
        >
          <Image style={styles.logoHead} src={image_logo} />
        </View>
  
      <View style={{ ...styles.tableContainer }}>
        <View style={styles.table}>
          <View style={styles.tableRow}>
            <View style={{ ...styles.tableCell, backgroundColor: "#f4f4f4" }}>
              <Text style={styles.table_header}>KEYWORD</Text>
            </View>
            <View style={{ ...styles.tableCell, backgroundColor: "#f4f4f4" }}>
              <Text style={styles.table_header}>VOLUME</Text>
            </View>
            <View style={{ ...styles.tableCell, backgroundColor: "#f4f4f4" }}>
              <Text style={styles.table_header}>CP</Text>
            </View>
            <View style={{ ...styles.tableCell, backgroundColor: "#f4f4f4" }}>
              <Text style={styles.table_header}>SD</Text>
            </View>
          </View>
          <View>
            {sorted_Table_Data?.map((res, index) => (
              <View style={styles.tableRow} key={`table-row-${index}`}>
                <View style={styles.tableCell}>
                  <Text style={styles.table_cell}>{res.keyword}</Text>
                </View>
                <View style={styles.tableCell}>
                  <Text style={styles.table_cell}>
                    {res?.keyword_info?.monthly_searches[0]?.search_volume ||
                      0}
                  </Text>
                </View>
                <View style={styles.tableCell}>
                  <Text style={styles.table_cell}>
                    ${res?.keyword_info?.cpc || 0}
                  </Text>
                </View>
                <View style={styles.tableCell}>
                  <Text style={styles.table_cell}>
                    {res?.keyword_properties?.keyword_difficulty || 0}
                  </Text>
                </View>
              </View>
            ))}
          </View>
        </View>
      </View>

      <Text style={styles.footer} fixed>
      {pdfNewData?.reset
            ? `Copyright ${getCurrentYear} eSearch Logix | Prepared using RanksPro.io`
            : pdfNewData?.footer}
      </Text>

     
    </Page>
  </Document>
);}










const KeywordVariationsPdf = ({ tableData, keywordName }) => {
  const [selectAll, setSelectAll] = useState({
    select: false,
    page: 1,
  });
  const navigate = useNavigate()
  const [selectedKeywordName, setSelectedKeywordName] = useState("");
  const [key_Data, setKey_Data] = useState([]);
  const [selectedKey, setSelectedKey] = useState(false);
  const [selectedKeyData, setSelectedKeyData] = useState([]);
  const [selectedKeyLabels, setSelectedKeyLabels] = useState([]);
  const [selectedTab, setSelectedTab] = useState(1);
  const targetCrawledPages = useRef();
  const email = useSelector((state) => state.email);
  const [domainLocation, setDomainLocation] = useState({
    location_name: "United States",
    location_code: 2840,
    location_flag: "https://flagsapi.com/US/flat/24.png",
    language_code: "en",
    language_name: "English",
  });
  const diffStyle = (val) => {
    if (val <= 25) {
      return {
        backgroundColor: "rgba(46, 108, 45, 1)",
        display: "inline-flex",
        justifyContent: "center",
        alignItems: "center",
        width: "36px",
        height: "36px",
        color: "rgba(255, 255, 255, 1)",
        borderRadius: "100%",
      };
    } else if (25 < val && val <= 50) {
      return {
        backgroundColor: "rgba(46, 108, 45, 1)",
        display: "inline-flex",
        justifyContent: "center",
        alignItems: "center",
        width: "36px",
        height: "36px",
        color: "rgba(255, 255, 255, 1)",
        borderRadius: "100%",
      };
    } else if (50 < val && val <= 75) {
      return {
        backgroundColor: "rgba(255, 142, 59, 1)",
        display: "inline-flex",
        justifyContent: "center",
        alignItems: "center",
        width: "36px",
        height: "36px",
        color: "rgba(255, 255, 255, 1)",
        borderRadius: "100%",
      };
    } else {
      return {
        backgroundColor: "rgba(233, 86, 85, 1)",
        display: "inline-flex",
        justifyContent: "center",
        alignItems: "center",
        width: "36px",
        height: "36px",
        color: "rgba(255, 255, 255, 1)",
        borderRadius: "100%",
      };
    }
  };

  const handlekeywordVolRes = (res) => {
    const months = [
      "Jan",
      "Feb",
      "Mar",
      "Apr",
      "May",
      "Jun",
      "Jul",
      "Aug",
      "Sep",
      "Oct",
      "Nov",
      "Dec",
    ];

    let data = [];
    let labels = [];

    res?.keyword_info?.monthly_searches?.map((res) => {
      data.push(res.search_volume);
      labels.push(`${months[res.month - 1]} ${res.year}`);
    });

    const reversedChartData = data.slice().reverse();
    const reversedChartLabels = labels.slice().reverse();

    setSelectedKeyData(reversedChartData);
    setSelectedKeyLabels(reversedChartLabels);
  };

  const config = useMemo(() => {
    return {
      email,
      proj_name: keywordName,
      tag_type: selectedTab === 2 ? "keyword" : "qsb",
      location_name: domainLocation?.location_name,
      language_name: domainLocation?.language_name,
    };
  }, [email, keywordName, domainLocation, selectedTab]);

  const { loaded, error, data } = useKeywordTags(config, selectedTab);

  const handleRoute =()=>{

    sessionStorage.setItem('pdfSessionData',JSON.stringify({tableData:tableData,keywordName:keywordName,id:3}))
    setTimeout(() => {
      navigate("/CrawledPageEditor");
    }, 500)
     
    }

  return (
    <div>
      <div className="d-flex justify-content-end" style={{    padding: "5px 100px"}}>
      <FiDownload
      onClick={handleRoute}
          className="down"
          title="Keyword Variaions PDF"
          aria-label="Close" data-bs-dismiss="modal"
          />
     
          </div>

      <div className="custom-table-container mt-4" ref={targetCrawledPages}>
        <div
          style={{ width: "100%", height: "20vh" }}
          className="pg-2 d-flex justify-content-center align-items-center flex-column"
        >
          <img src={RanksProLogo} alt="" style={{ width: "300px" }} />
        </div>
        <table className="">
          <thead>
            <tr className="table-sticky">
              <th className="col ">
                <div className="d-flex">
                  <div className="d-flex align-items-center point justify-content-center text-center w-100">
                    KEYWORDS
                  </div>
                </div>
              </th>

              <th className="col">
                <div className="d-flex ">
                  <div className="d-flex align-items-center point justify-content-center text-center w-100">
                    VOLUME
                  </div>
                </div>
              </th>

              <th className="col ">
                <div className="d-flex ">
                  <div className="d-flex align-items-center point justify-content-center text-center w-100">
                    CPC
                  </div>
                </div>
              </th>

              <th className="col ">
                <div className="d-flex ">
                  <div className="d-flex align-items-center point justify-content-center text-center w-100">
                    SD
                  </div>
                </div>
              </th>
            </tr>
          </thead>
          <tbody>
            {tableData?.length === 0 ? (
              <tr>
                <td colSpan={6} className="text-center">
                  No data found !
                </td>
              </tr>
            ) : (
              tableData?.map((res, ind) => {
                return (
                  <React.Fragment key={`research-keyword-${res.keyword}`}>
                    <tr key={res.keyword}>
                      <td className="t_left text-center">{res.keyword}</td>

                      <td className="text-center">
                        {res?.keyword_info?.monthly_searches[0]?.search_volume}
                      </td>

                      <td className="text-center">
                        ${res?.keyword_info?.cpc || 0}
                      </td>

                      <td className="text-center">
                        <span
                          className="keyword-sd text-center"
                          style={diffStyle(
                            res?.keyword_properties?.keyword_difficulty || 0
                          )}
                        >
                          {res?.keyword_properties?.keyword_difficulty || 0}
                        </span>
                      </td>
                    </tr>
                  </React.Fragment>
                );
              })
            )}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default React.memo(KeywordVariationsPdf);
