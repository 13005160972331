import React, { useState } from "react";
import axios from "axios";
import Alert from "@mui/material/Alert";
import Snackbar from "@mui/material/Snackbar";
import { saveAs } from "file-saver";
import htmlDocx from "html-docx-js/dist/html-docx";
import MenuListDropdown from "../../share/components/menulist.component";
import saveIcon from "../../Assets/ai-article/save.svg";
import html2pdf from "html2pdf.js";
import TiptopEditor from "../../share/components/Tiptop Editor/tiptop-editor";
import { ReactComponent as SaveIcon } from "../../Assets/ai-article/save.svg";
import backIcon from "../../Assets/common/back.svg";

const ProjectContent = ({ data, userproject, handleClose }) => {
  const [text, setText] = useState("");
  const [Open, setOpen] = useState(false);
  const vertical = "top";
  const horizontal = "right";
  const admin_mail = localStorage.getItem("admin_mail");

  const handleEditorChange = (value) => {
    setText(value);
  };

  const onSaveEditerText = () => {
    const item = {
      email: admin_mail,
      title: data?.title,
      content: [
        {
          desc: data?.content[0]?.desc,
          content: text,
        },
      ],
      user_proj: userproject,
      keyword: data?.keyword,
      date: data?.date,
      month: data?.month,
      year: data?.year,
    };

    axios
      .put(`https://app.rankspro.io/api/user/blogupdate/${data?.id}/`, item)
      .then(() => {
        setOpen(true);
        window.location.reload(false);
      });
  };

  const exportAsPDF = () => {
    const htmlContent = text || data?.content[0]?.content || ""; // Ensure htmlContent is not undefined
    // Create a div element to hold the HTML content
    const div = document.createElement("div");
    div.innerHTML = htmlContent;
    // Convert the HTML content to PDF
    html2pdf(div, {
      margin: 15,
      filename: "AI-writer.pdf",
      html2canvas: { scale: 2 },
      jsPDF: { format: "a4", orientation: "portrait" },
    })
      .from()
      .save();
  };

  const exportAsDoc = () => {
    const htmlContent = text || data?.content[0]?.content || ""; // Ensure htmlContent is not undefined
    const converted = htmlDocx.asBlob(htmlContent);
    if (converted) {
      saveAs(converted, "AI-writer.docx");
    } else {
      console.error("Conversion failed. Invalid HTML content.");
    }
  };

  const dataList = {
    list: [
      <button
        className="btn_new_tr_bg rounded-5 px-3 py-2"
        onClick={() => exportAsPDF()}
      >
        <i
          className="fa-regular fa-file-lines"
          style={{ paddingRight: "10px" }}
        ></i>
        Export PDF
      </button>,
      <button
        className="btn_new_tr_bg rounded-5 px-3 py-2"
        onClick={() => exportAsDoc()}
      >
        <SaveIcon />
        Export DOC
      </button>,
    ],
  };

  return (
    <>
      <Snackbar
        anchorOrigin={{ vertical, horizontal }}
        open={Open}
        onClose={() => setOpen(false)}
        autoHideDuration={3000}
        key={vertical + horizontal}
      >
        <Alert
          severity="success"
          sx={{ backgroundColor: "#F4A720", color: "#365486" }}
        >
          Article Updated Successfull
        </Alert>
      </Snackbar>
      <div className="row mb-3 mb-sm-5 mt-2 mt-md-5 justify-content-between">
        <div className="col-12 col-md-3 d-flex justify-content-between">
          <button className="icon-btn large-btn" onClick={() => handleClose()}>
            <img src={backIcon} alt="" />
          </button>
          <div className="d-flex justify-content-end d-flex d-md-none mb-4">
            <MenuListDropdown data={dataList} cusClass="large-btn" />
            <button
              className="icon-btn ms-3 large-btn"
              disabled={text === ""}
              onClick={() => onSaveEditerText()}
            >
              <img src={saveIcon} alt="" />
            </button>
          </div>
        </div>
        <div className="col-12 col-md-6">
          <div className="text-center">
            <h3 className="text-head">Title: {data?.title}</h3>
            <h3 className="text-head-val mt-2">Keyword: {data.keyword}</h3>
          </div>
        </div>
        <div className="col-12 col-md-3 d-flex justify-content-end d-none d-md-flex">
          <MenuListDropdown data={dataList} cusClass="large-btn" />
          <button
            className="icon-btn ms-3 large-btn"
            disabled={text === ""}
            onClick={() => onSaveEditerText()}
          >
            <img src={saveIcon} alt="" />
          </button>
        </div>
      </div>
      <TiptopEditor
        content={data?.content[0]?.content}
        onchangeHandler={handleEditorChange}
      />
    </>
  );
};

export default ProjectContent;
