import React, { useEffect, useMemo, useRef } from "react";
import { DottLoader } from "../share/loader";
import { CSVLink } from "react-csv";
import { useState } from "react";
import { Link, useNavigate, useLocation } from "react-router-dom";
import CustomPagination from "../share/components/pagination.component";
import { useDispatch, useSelector } from "react-redux";
import cost_per_click from "../Assets/keyword/cost-per-click.svg";
import seo_diff from "../Assets/keyword/seo-diff.svg";
import search_volume from "../Assets/keyword/search-volume.svg";
import VerticalLinearStepper from "../share/components/vertical-steper";
import { CiCircleList } from "react-icons/ci";
import exportIcon from "../Assets/rank-tracking-assets/export-file.svg";
import { ReactComponent as UnsortIcon } from "../Assets/rank-tracking-assets/unshort.svg";
import { domainRegex, dynamicSort } from "../../helperJs/helper";
import Spinner from "../share/components/spinner";
import { FiDownload } from "react-icons/fi";
import jsPDF from "jspdf";
import html2canvas from "html2canvas";
import RanksProLogo from "../../Mycomponent/Assets/site-audit/RanksProLogo.png";
//home page
import axios from "axios";
import {
  GET_allList,
  KEYWORD_GET,
  KEYWORD_VIEW_LIMIT_UPDATE,
  KEYWORD_VIEW_LIMIT_get,
  POST_KEYWORD,
  POST_KEYWORD_VIEW_LIMIT,
  POST_LISTKEYWORD,
  PUT_AddKeyWord,
  GET_AI_BASED_KEYWORD,
  POST_AI_BASED_KEYWORD,
} from "../../services/constants";
import CommonLineChart from "../share/charts/commonLineChart";
import { motion, AnimatePresence } from "framer-motion";

import { ToastContainer } from "react-toastify";
import { AddListModal } from "./Modals/addListModal";
import { AddNewListModal } from "./Modals/addNewListModal";
import { ViewAllList } from "../../Navigation/constant";
import "react-toastify/dist/ReactToastify.css";
import ToolTip from "../share/components/tooltip";
import "../css/keyword.scss";
import AlertPopUp from "../share/components/alert.component";

import searchArrow from "../Assets/keyword/search-arrow.svg";
import addIcon from "../Assets/rank-tracking-assets/add.svg";
import exportFileIcon from "../Assets/rank-tracking-assets/export-file.svg";
import trendSvg from "../Assets/rank-tracking-assets/trend.svg";
import searchIntent from "../Assets/keyword/search-intent.png";

import averageImpres from "../Assets/keyword/average-imp.svg";
import competiIcon from "../Assets/keyword/compet.svg";
import avgDalClk from "../Assets/keyword/avg-dai-clk.svg";
import avgBackIcon from "../Assets/keyword/avg-back.svg";
import domainRankIcon from "../Assets/keyword/domain-rank.svg";
import { ZustandStore } from "../../store/zustand-store";
import CountryBasedOnLanuage from "../share/components/CountryBasedOnLanuage";
import useKeywordTags from "../share/components/keyword-tag/getkeywordlist";
import FilterTag from "../share/components/keyword-tag/tag-filter";
import TagComponent from "../share/components/keyword-tag/tag.component";
import { filterItemsWithKeyword } from "../share/components/keyword-tag/tag-operations";
import AddKeywordResearch from "../share/components/add-keyword-research/AddKeywordDialog";
import KeywordVariationsPdf from "./Modals/PdfModals/KeywordVariationsPdf";
import QuestionBasedVarificationPdf from "./Modals/PdfModals/QuestionBasedVarificationPdf";
import {
  Page,
  Text,
  View,
  Document,
  StyleSheet,
  Image,
  PDFDownloadLink,
} from "@react-pdf/renderer";
import { getCurrentYear } from "../share/helper-js/current-year";

const styles = StyleSheet.create({
  page: {
    paddingHorizontal: 20,
    flexDirection: "column",
    paddingTop: 30,
    paddingBottom: 80, // Set vertical padding to 30 pixels
    borderStyle: "solid",
  },
  header: {
    paddingBottom:20
  },
  footer: {
    fontSize: 12,
    textAlign: "center",
    color: "grey",
    position: "absolute",
    bottom: 20,
    left: 0,
    right: 0,
  },
  logo: {
    width: 250,
  },
  title: {
    fontSize: 24,
    textAlign: "center",
    marginBottom: 20,
  },

 
  tableContainer: {
    marginBottom: 20,
  },

  keywordLoc: {
    flex: 1, // Each cell should take equal space within the row
    padding: 5,
    borderRightWidth: 1,
    borderColor: "#000",
    justifyContent: "start",
    minHeight: 40,
    height: "100%",
    alignItems: "start",
    flexDirection: "column",
  },
 
  fixedWidthCell: {
    maxWidth: 80, // Disable flex for fixed width cells
  },
  cardContainer: {
    flexDirection: "row",
    flexWrap: "wrap", // Enable flex wrapping
    justifyContent: "space-between", // Arrange cards with equal spacing
    marginTop: 10,
  },
  card: {
    flexDirection: "row",
    justifyContent: "space-between",
    width: 180,
    backgroundColor: "#FFFFFF",
    padding: 10,
    borderRadius: 5,
    marginBottom: 20,
  },
  image: {
    width: 30,
    height: 40,
  },
  title: {
    fontSize: 11,
    fontWeight: "bold",
    marginBottom: 5,
    color: "#6c757d",
  },
  description: {
    fontSize: 16,
  },
  imageContainer: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  arrowIcon: {
    width: 20,
    height: 20,
    objectFit: "contain",
  },
  view_button: {
    borderWidth: 1,
    borderColor: "#365486",
    padding: 5,
    borderRadius: 7,
    color: "#365486",
    fontSize: 11,
  },
  keyword_bold: {
    fontSize: 13,
    fontWeight: "bold",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  keyword: {
    fontSize: 11,
    color: "#6c757d",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  rank: {
    fontSize: 11,
    color: "#6c757d",
    textAlign: "center",
    marginRight: 10,
  },
  
 
  pageNumber: {
    position: "absolute",
    fontSize: 11,
    bottom: 45,
    left: 0,
    right: 0,
    textAlign: "center",
    color: "grey",
  },
  link_text: {
    fontSize: 7,
    textDecoration: "none",
    textOverflow: "hidden",
    textAlign: "start",
  },
  logoHead: {
    width: 125,
  },
});






export const PdfFile = ({pdfNewData}) => {
  
  const DashImage = sessionStorage.getItem("dashboardImage");
  const Data = JSON.parse(sessionStorage.getItem("pdfSessionData"));
  const Web_url = Data.keywordName
  const image_logo = pdfNewData
    ? pdfNewData?.reset
      ? RanksProLogo
      : pdfNewData?.logo?.src
    : RanksProLogo;
  return(
  <Document>
    <Page size={"A4"} style={styles.page}>
      <View
        style={{
          alignItems: "center",
          display: "flex",
          height: "82vh",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <Image style={styles.logo} src={image_logo} />
        <Text style={{ marginBottom: 20 }}>Organic Search</Text>
        <Text style={styles.title}>Domain: {Web_url}</Text>
      </View>


</Page>
<Page size={"A4"} style={styles.page}>
  
<View
          style={{
            ...styles.header,
            alignItems: pdfNewData?.reset
              ? "flex-start"
              : pdfNewData?.logo.position,
          }}
          fixed
        >
          <Image style={styles.logoHead} src={image_logo} />
        </View>

      <View style={{ ...styles.tableContainer }}>
        <View>
       <Image src={DashImage}/>
        </View>
      </View>

      <Text style={styles.footer} fixed>
      {pdfNewData?.reset
            ? `Copyright ${getCurrentYear} eSearch Logix | Prepared using RanksPro.io`
            : pdfNewData?.footer}
      </Text>

     
    </Page>
  </Document>
);}














const Keywords = () => {
  const dispatch = useDispatch();
  const keywordviewlimit = useSelector((state) => state.keywordviewlimit);
  const email = useSelector((state) => state.email);
  const [imageSrc, setImageSrc] = useState(null);
  const [keywordviewLength, setKeywordviewLength] = useState([]);
  const admin_mail = localStorage.getItem("admin_mail");
  const [showAddKeyword, setShowAddKeyword] = useState(false);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const {
    keyword_research_data,
    keyword_ques_based_data,
    country_Language_Data,
    tag_keywords,
  } = ZustandStore((state) => state);

  const navigate = useNavigate();
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const [keywordName, setKeywordName] = useState("");
  const [selectedKeywordName, setSelectedKeywordName] = useState("");
  const [selectAll, setSelectAll] = useState({
    select: false,
    page: 1,
  });
  const [quesBasedSelect, setQueBasedSelect] = useState(false);
  const [selectQuesBasedKey, setSelectQuesBasedKey] = useState([]);

  const [domainLocation, setDomainLocation] = useState({
    location_name: "United States",
    location_code: 2840,
    location_flag: "https://flagsapi.com/US/flat/24.png",
    language_code: "en",
    language_name: "English",
  });
  const [key_Data, setKey_Data] = useState([]);
  const [searchVolData, setSearchVolData] = useState([]);
  const [searchVolLabels, setSearchVolLabels] = useState([]);
  const [selectedKey, setSelectedKey] = useState(false);
  const [selectedKeyData, setSelectedKeyData] = useState([]);
  const [selectedKeyLabels, setSelectedKeyLabels] = useState([]);
  // for input check
  const [selectedKeywords, setSelectedKeywords] = useState([]); // State to hold selected keyword strings
  const [selectedResObjects, setSelectedResObjects] = useState([]); // State to hold selected res objects

  const [tableData, setTableData] = useState([]);
  const [direction, setDirection] = useState(false);

  const [tablePage, setTablePage] = useState(1);
  const itemsPerPage = 50;
  const startIndex = (tablePage - 1) * itemsPerPage;
  const endIndex = startIndex + itemsPerPage;
  const totalPages = Math.ceil(tableData?.length / itemsPerPage);
  const displayedItems = tableData?.slice(startIndex, endIndex);

  const addAllKeyword = () => {
    if (displayedItems?.length === 0) return;
    const allKeywordArray = displayedItems?.map((keyword) => {
      return keyword.keyword;
    });
    setSelectedKeywords(allKeywordArray);
    setSelectedResObjects(displayedItems);
  };

  useEffect(() => {
    if (selectAll?.select) {
      addAllKeyword();
    } else {
      setSelectedKeywords([]);
      setSelectedResObjects([]);
    }
  }, [selectAll]);

  // form alert
  const [valAlert, setValAlert] = useState(false);
  const [loading, setLoading] = useState(false);

  // Modals
  const [addListModal, setaddlistModal] = useState(false);
  const [addNewListModal, setaddNewlistModal] = useState(false);

  // Lists
  const [listValue, setListValue] = useState("");
  const [AllLists, setAllLists] = useState([]);
  const [ViewAllLists, setViewAllLists] = useState([]);
  const [alertMsg, setAlertMsg] = useState(false);
  const [ListError, setListError] = useState(false);
  const [inputEmptyErr, setinputEmptyErr] = useState(false);
  const [ListLimitError, setListLimitError] = useState(false);
  const [listName, setListName] = useState("");
  const [keyWordLenErr, setKeyWordLenErr] = useState(false);
  const [selectedTab, setSelectedTab] = useState(1);
  const [queBasedKeyword, setQueBasedKeyword] = useState([]);
  const [Alert, setAlert] = useState({
    type: "",
    message: "",
    show: false,
  });
  // UseSelector
  const EMAIL = useSelector((state) => state?.email);
  const LISTLIMIT = useSelector((state) => state?.createListLimit);

  const handleFetchListData = () => {
    axios.get(GET_allList(EMAIL)).then(({ data }) => {
      const keyword_list = data?.filter((item) => {
        return item.list_type === "keyword";
      });
      const qbk_list = data?.filter((item) => {
        return item.list_type === "qsb";
      });
      const list = selectedTab === 2 ? keyword_list : qbk_list;
      setViewAllLists(list);
      setAllLists(list);
    });
  };

  useEffect(() => {
    handleFetchListData();
  }, [EMAIL, selectedTab]);

  const questionBasedKeywords = useMemo(() => {
    return tag_keywords?.length > 0
      ? filterItemsWithKeyword("qsb", tag_keywords, queBasedKeyword)
      : queBasedKeyword;
  }, [queBasedKeyword, tag_keywords]);

  useEffect(() => {
    if (quesBasedSelect) {
      setSelectQuesBasedKey(queBasedKeyword);
    } else {
      setSelectQuesBasedKey([]);
    }
  }, [quesBasedSelect]);

  const selectedComData = [
    ["Keyword Ideas"],

    [],

    ["Keyword", "Search Volume", "Cost Per Click", "SEO Difficulty"],
    ...(selectedResObjects && Array.isArray(selectedResObjects)
      ? selectedResObjects.map((res) => [
          res.keyword,
          res?.keyword_info?.monthly_searches[0]?.search_volume,
          `$${res?.keyword_info?.cpc || 0}`,
          res?.keyword_properties?.keyword_difficulty || 0,
        ])
      : []),
  ];
  // create object for sendind data in api create list
  const OtherData = useMemo(() => {
    const array = selectedResObjects?.map((result) => {
      return {
        keyword: result.keyword,
        cpc: result.keyword_info.cpc,
        volume: result.keyword_info.monthly_searches[0].search_volume,
        difficulty: result.keyword_properties.keyword_difficulty,
      };
    });

    return array;
  }, [selectedResObjects]);

  const loadCSVData = () => {
    if (selectQuesBasedKey?.length === 0) return [];
    const data =
      tag_keywords?.length > 0
        ? filterItemsWithKeyword("qsb", tag_keywords, queBasedKeyword)
        : selectQuesBasedKey;

    const CSV_Array = [];
    data?.map((res) => {
      CSV_Array.push({
        keyword: res,
      });
    });
    return CSV_Array;
  };

  const payLoad = {
    email: EMAIL,
    list_name: listValue,
    keyword_list: selectedTab === 2 ? OtherData : loadCSVData(),
    list_type: selectedTab === 2 ? "keyword" : "qsb",
  };

  const list_type = selectedTab === 2 ? "keyword" : "qsb";

  const handleListCreate = () => {
    if (EMAIL && listValue && selectedKeywords) {
      axios.post(POST_LISTKEYWORD(), payLoad).then((result) => {
        // Add List Api
        if (result?.data)
          setAlert({
            type: "success",
            message: "Success",
            show: true,
          });
        setListError(false);
        handleFetchListData();
        setaddlistModal(false);
        setListLimitError(false);
        setSelectedResObjects([]);
        setSelectedKeywords([]);
        setListLimitError(false);
        setAlertMsg(false);
        setListValue("");
        setaddNewlistModal(false);
        setQueBasedSelect(false);
        setSelectQuesBasedKey([]);
      });
    }
  };

  const [DeleteLoading, setDeleteLoading] = useState(false);

  const HandleDeleteList = (listedname) => {
    setDeleteLoading(true);
    const api = `https://app.rankspro.io/api/user/keywordlistdelete/?email=${EMAIL}&list_name=${listedname.list_name}`;
    axios.delete(api).then((res) => {
      setTimeout(() => {
        setDeleteLoading(false);
        if (res?.data?.msg === "List Deleted Successfully") {
          handleFetchListData();
        }
      }, 500);
    });
  };

  const HandleListAdd = () => {
    setAlertMsg(false);
    if (listValue.length === 0) {
      setinputEmptyErr(true);
      return;
    } else {
      const Output = AllLists.map((result) => {
        return result.list_name === listValue;
      });
      const ReturnData = Output.includes(true);
      if (ReturnData) {
        setAlertMsg(true);
        setTimeout(() => {
          setAlertMsg(false);
        }, 3000);
      } else {
        handleListCreate();
      }
    }
  };

  const handleGetListData = (listData) => {
    setKeyWordLenErr(false);
    const TOTAL = listData.keyword_list.length + selectedKeywords.length;
    if (TOTAL >= 100) {
      setKeyWordLenErr(true);
      return;
    } else {
      setKeyWordLenErr(false);
    }
    setListError(false);
    setListName(listData.list_name);
  };

  const HandleAddKeywordList = () => {
    setListLimitError(false);
    setListError(false);
    let obj = {
      data: [],
    };
    ViewAllLists?.map((all) => {
      if (all.list_name === listName) {
        const keyword_list = all.keyword_list;
        const namesInArray1 = new Set(keyword_list.map((item) => item.keyword));
        const quesArray = loadCSVData();
        const array = OtherData?.filter(
          (item) => !namesInArray1.has(item.keyword)
        );
        const array2 = quesArray?.filter(
          (item) => !namesInArray1.has(item.keyword)
        );
        const data = selectedTab === 2 ? array : array2;
        obj.data.push(data);
      }
    });
    const encodedArray = encodeURIComponent(JSON.stringify(...obj.data));

    if (listName) {
      axios
        .put(PUT_AddKeyWord(EMAIL, listName, encodedArray, list_type))
        .then((result) => {
          if (result?.data)
            setAlert({
              type: "success",
              message: "Successfully Added",
              show: true,
            });

          setaddlistModal(false);
          setSelectedResObjects([]);
          setSelectedKeywords([]);
          setQueBasedSelect(false);
          setSelectQuesBasedKey([]);
          handleFetchListData();
          setListError(false);
          setListName("");
        });
    } else {
      setListError(true);
    }
  };

  useEffect(() => {
    handleFetchListData();
  }, [0]);

  const HandleOpenModalAddList = () => {
    if (AllLists.length === LISTLIMIT) {
      setListLimitError(true);
      setTimeout(() => {
        setListLimitError(false);
      }, 3000);
    } else {
      setListLimitError(false);
      setaddNewlistModal(true);
    }
  };

  useEffect(() => {
    const onCapture = (id) => {
      if (document.getElementById(id) == null) return;
      const canvas = document.getElementById(id);
      const img = canvas.toDataURL("image/png");
      setImageSrc(img);
    };
    setTimeout(() => {
      onCapture("line-chart");
    }, 2000);
  }, [searchVolData]);

  const combinedData = [
    ["Detailed Keyword Overview for: " + keywordName || key_Data?.seed_keyword],
    [],
    ["Search Volume", "SEO Difficulty", "Cost Per Click"],
    [
      key_Data?.seed_keyword_data?.keyword_info?.monthly_searches[0]
        ?.search_volume || 0,
      key_Data?.seed_keyword_data?.keyword_properties?.keyword_difficulty || 0,
      `$${key_Data?.seed_keyword_data?.keyword_info?.cpc || 0}`,
    ],
    [],
    ["Search Volume"],
    searchVolLabels,
    searchVolData,
    [],
    ["Keywords Ideas"],
    ["Keyword", "Search Volume", "Cost Per Click", "SEO Difficulty"],
    ...(key_Data?.items && Array.isArray(key_Data?.items)
      ? key_Data?.items.map((res) => [
          res.keyword,
          res?.keyword_info?.monthly_searches[0]?.search_volume,
          `$${res?.keyword_info?.cpc || 0}`,
          res?.keyword_properties?.keyword_difficulty || 0,
        ])
      : []),
  ];

  // input check selector
  const handleQuesBasedKeywordCheckboxChange = (keyword) => {
    setSelectQuesBasedKey((prevSelectedKeywords) => {
      if (prevSelectedKeywords.includes(keyword)) {
        // If the keyword is already in the array, remove it
        const updatedKeywords = prevSelectedKeywords.filter(
          (selectedKeyword) => selectedKeyword !== keyword
        );
        return updatedKeywords;
      } else {
        return [...prevSelectedKeywords, keyword];
      }
    });
  };

  const handleCheckboxChange = (keyword, res) => {
    setSelectedKeywords((prevSelectedKeywords) => {
      if (prevSelectedKeywords.includes(keyword)) {
        // If the keyword is already in the array, remove it
        const updatedKeywords = prevSelectedKeywords.filter(
          (selectedKeyword) => selectedKeyword !== keyword
        );
        setSelectedResObjects((prevSelectedResObjects) =>
          prevSelectedResObjects.filter((resObj) => resObj.keyword !== keyword)
        );
        return updatedKeywords;
      } else {
        // If the keyword is not in the array, add it
        setSelectedResObjects((prevSelectedResObjects) => [
          ...prevSelectedResObjects,
          res,
        ]);
        return [...prevSelectedKeywords, keyword];
      }
    });
  };

  const fatch_keyLIMIT = () => {
    axios.get(KEYWORD_VIEW_LIMIT_get(admin_mail)).then((res) => {
      setKeywordviewLength(res.data);
    });
  };

  const onRefreshCall = () => {
    const domainName = sessionStorage.getItem("KEYWORD_DOMAIN_NAME");
    const country = JSON.parse(
      sessionStorage.getItem("KEYWORD_DOMAIN_COUNTRY")
    );
    if (domainName != null) {
      setKeywordName(domainName);
      if (country != null) {
        setDomainLocation({
          location_name: country?.location_name,
          location_code: country?.location_code,
          location_flag: country?.location_flag,
          language_code: country?.language_code,
          language_name: country?.language_name,
        });
      }
      setLoading(true);
      setKey_Data([]);
      addQueryParamToURL(domainName);
      if (
        keyword_research_data?.length === 0 &&
        keyword_ques_based_data?.length === 0
      ) {
        fatch_keywordGet(domainName);
      } else {
        setLoading(false);
        setKey_Data(keyword_research_data);
        setQueBasedKeyword(keyword_ques_based_data);
      }
    }
  };
  // get user keyword limit
  useEffect(() => {
    fatch_keyLIMIT();
    onRefreshCall();
  }, [keywordviewlimit]);

  // keyword get data
  const fatch_keywordGet = () => {
    setKey_Data([]);
    var domain = null;
    const domainName = sessionStorage.getItem("KEYWORD_DOMAIN_NAME");
    if (domainName != null) {
      domain = domainName;
    } else {
      domain = keywordName;
    }
    if (domain === "") {
      setValAlert("Please enter keyword");
    } else if (domainLocation?.location_name === "No Country") {
      setValAlert(
        "No Country for This Language, Please select another language"
      );
    } else if (keywordviewlimit === 0) {
      setValAlert("You don't have any plan , please upgrade ");
    } else if (
      keywordviewLength?.[0]?.keywordlmt >= keywordviewlimit &&
      keywordviewLength?.length !== 0
    ) {
      setValAlert(
        "Your credits are used, please upgrade to the plan for increased limit."
      );
    } else {
      setSelectedKeywordName(domain);
      sessionStorage.setItem("KEYWORD_DOMAIN_NAME", domain);
      addQueryParamToURL(domain);

      if (keywordviewLength.length === 0) {
        const postIntem = {
          email: admin_mail,
          keywordlmt: 1,
        };
        axios.post(POST_KEYWORD_VIEW_LIMIT(), postIntem).then(() => {
          fatch_keyLIMIT();
        });
      } else {
        const postIntem = {
          email: admin_mail,
          keywordlmt: keywordviewLength[0]?.keywordlmt + 1,
        };
        axios
          .put(KEYWORD_VIEW_LIMIT_UPDATE(keywordviewLength[0]?.id), postIntem)
          .then(() => {
            fatch_keyLIMIT();
          });
      }

      setLoading(true);
      const country = JSON.parse(
        sessionStorage.getItem("KEYWORD_DOMAIN_COUNTRY")
      );

      var langCode = null;
      var countryCode = null;
      if (country != null) {
        countryCode = country?.location_code;
        langCode = country?.language_code;
      } else {
        countryCode = domainLocation?.location_code;
        langCode = domainLocation?.language_code;
      }

      const item = {
        locationcode: countryCode,
        keyword: domain,
        language_code: langCode,
      };

      axios
        .get(GET_AI_BASED_KEYWORD(item))
        .then((response) => {
          const data = response?.data;
          if (data?.length > 0) {
            ZustandStore.setState({
              keyword_ques_based_data: data,
            });
            setQueBasedKeyword(data);
          } else {
            axios
              .post(POST_AI_BASED_KEYWORD(item))
              .then((response) => {
                const post_data = response.data;
                setQueBasedKeyword(post_data);
                ZustandStore.setState({
                  keyword_ques_based_data: post_data,
                });
              })
              .catch(() => {
                setQueBasedKeyword([]);
              });
          }
        })
        .catch(() => {
          setQueBasedKeyword([]);
        });

      axios
        .get(KEYWORD_GET(item))
        .then((res) => {
          if (res.data.length !== 0) {
            if (
              res?.data?.length === 0 ||
              res?.data[0]?.items == null ||
              res?.data[0]?.items?.length === 0
            ) {
              setValAlert("Sorry, don't have any data for this keyword");
              setKey_Data([]);
              ZustandStore.setState({
                keyword_research_data: [],
              });
              setLoading(false);
            } else {
              setKey_Data(res?.data[0]);
              ZustandStore.setState({
                keyword_research_data: res?.data[0],
              });
              setValAlert(false);
              setLoading(false);
            }
          } else {
            axios
              .post(POST_KEYWORD(item))
              .then((res) => {
                if (
                  res?.data?.length === 0 ||
                  res?.data[0]?.items == null ||
                  res?.data[0]?.items?.length === 0
                ) {
                  setValAlert("Sorry, don't have any data for this keyword");
                  setKey_Data([]);
                  ZustandStore.setState({
                    keyword_research_data: [],
                    keyword_ques_based_data: [],
                  });
                  setLoading(false);
                } else {
                  setKey_Data(res?.data[0]);
                  ZustandStore.setState({
                    keyword_research_data: res?.data[0],
                  });
                  setValAlert(false);
                  setLoading(false);
                }
              })
              .catch(() => {
                setKey_Data([]);
                ZustandStore.setState({
                  keyword_research_data: [],
                  keyword_ques_based_data: [],
                });
                setValAlert("Sorry, don't have any data for this keyword");
                setLoading(false);
              });
          }
        })
        .catch(() => {
          ZustandStore.setState({
            keyword_research_data: [],
            keyword_ques_based_data: [],
          });
          setKey_Data([]);
          setValAlert("Sorry, don't have any data for this keyword");
          setLoading(false);
        });
    }
  };

  // search volume
  useEffect(() => {
    const months = [
      "Jan",
      "Feb",
      "Mar",
      "Apr",
      "May",
      "Jun",
      "Jul",
      "Aug",
      "Sep",
      "Oct",
      "Nov",
      "Dec",
    ];

    let data = [];
    let labels = [];

    key_Data?.seed_keyword_data?.keyword_info?.monthly_searches?.map((res) => {
      data.push(res.search_volume);
      labels.push(`${months[res.month - 1]} ${res.year}`);
    });
    if (key_Data?.items?.length > 0) {
      const key_data =
        tag_keywords?.length > 0
          ? filterItemsWithKeyword("keyword", tag_keywords, key_Data?.items)
          : key_Data?.items;
      const data = key_data?.map((item) => {
        return {
          ...item,
          volume: item?.keyword_info?.monthly_searches[0]?.search_volume,
          cpc: item?.keyword_info?.cpc,
          sd: item?.keyword_properties?.keyword_difficulty,
        };
      });
      setTableData(data);
    }
    const reversedChartData = data.slice().reverse();
    const reversedChartLabels = labels.slice().reverse();
    setSearchVolData(reversedChartData);
    setSearchVolLabels(reversedChartLabels);
  }, [key_Data, tag_keywords]);

  // keyword volume
  const handlekeywordVolRes = (res) => {
    const months = [
      "Jan",
      "Feb",
      "Mar",
      "Apr",
      "May",
      "Jun",
      "Jul",
      "Aug",
      "Sep",
      "Oct",
      "Nov",
      "Dec",
    ];

    let data = [];
    let labels = [];

    res?.keyword_info?.monthly_searches?.map((res) => {
      data.push(res.search_volume);
      labels.push(`${months[res.month - 1]} ${res.year}`);
    });

    const reversedChartData = data.slice().reverse();
    const reversedChartLabels = labels.slice().reverse();

    setSelectedKeyData(reversedChartData);
    setSelectedKeyLabels(reversedChartLabels);
  };

  // submit handler
  const keywordSearchHand = (e) => {
    e.preventDefault();

    if (keywordName === "") {
      setValAlert("Please enter keyword");
      setKey_Data([]);
      return;
    } else if (domainRegex.test(keywordName)) {
      setValAlert("Please Enter Valid keyword.");
      setKey_Data([]);
      return;
    }
    const sel_country = {
      location_name: domainLocation?.location_name,
      location_code: domainLocation?.location_code,
      location_flag: domainLocation?.location_flag,
      language_code: domainLocation?.language_code,
      language_name: domainLocation?.language_name,
    };

    sessionStorage.setItem(
      "KEYWORD_DOMAIN_COUNTRY",
      JSON.stringify(sel_country)
    );
    fatch_keywordGet();
  };

  const diffStyle = (val) => {
    if (val <= 25) {
      return {
        backgroundColor: "rgba(46, 108, 45, 1)",
      };
    } else if (25 < val && val <= 50) {
      return {
        backgroundColor: "rgba(46, 108, 45, 1)",
      };
    } else if (50 < val && val <= 75) {
      return {
        backgroundColor: "rgba(255, 142, 59, 1)",
      };
    } else {
      return {
        backgroundColor: "rgba(233, 86, 85, 1)",
      };
    }
  };

  const scrollIntoView = (id) => {
    setTimeout(() => {
      const element = document.getElementById(id);
      if (element) {
        element.scrollIntoView({
          block: "start",
          behavior: "smooth",
        });
      }
    }, 200);
  };

  const handlePaginationChange = (value) => {
    setTablePage(value);
    scrollIntoView("keywords-ideas");
  };

  const addQueryParamToURL = (name) => {
    const country = JSON.parse(
      sessionStorage.getItem("KEYWORD_DOMAIN_COUNTRY")
    );

    var langname = null;
    var countryname = null;
    if (country != null) {
      countryname = country?.location_name;
      langname = country.language_name;
    } else {
      countryname = domainLocation?.location_name;
      langname = domainLocation?.language_name;
    }

    if (keywordName || name) {
      queryParams.set("keyword", keywordName || name);
      queryParams.set("lanuage", langname);
      queryParams.set("country", countryname);
    } else {
      queryParams.set("keyword", keywordName || name);
      queryParams.delete("lanuage");
      queryParams.delete("country");
    }
    const newSearch = queryParams.toString();
    window.history.pushState({}, "", `?${newSearch}`);
  };

  const openPdfViewer = () => {
    const pdf_data = {
      type: "keyword",
      data: {
        data: key_Data,
        src: imageSrc,
      },
    };
    dispatch({ type: "PDFVIEWERDATA", payload: pdf_data });
    dispatch({ type: "LOADING" });
    setTimeout(() => {
      navigate("/pdf-editor");
    }, 500);
  };

  const dataList = {
    list: [
      <button
        className="btn_new_tr_bg rounded-5 px-3 py-2"
        onClick={openPdfViewer}
      >
        <i
          className="fa-regular fa-file-lines"
          style={{ paddingRight: "10px" }}
        ></i>
        PDF
      </button>,
      <CSVLink data={combinedData} filename={"Keyword_Research_Report.csv"}>
        <button className="btn_new_tr_bg rounded-5 px-3 py-2">
          <i
            className="fa-regular fa-file-lines"
            style={{ paddingRight: "10px" }}
          ></i>
          CSV
        </button>
      </CSVLink>,
    ],
  };

  const card_data = [
    {
      title: "Search Volume",
      toolkit:
        "The numbers of searches this particular keyword has during a month",
      bg_color: "#D9E2EA",
      img: search_volume,
      rank:
        key_Data?.seed_keyword_data?.keyword_info?.monthly_searches[0]
          ?.search_volume || 0,
    },
    {
      title: "SEO Difficulty",
      toolkit:
        "Estimated competition in organic search , the higher the number the more competitor",
      bg_color: "#EFE6D8",
      img: seo_diff,
      rank:
        key_Data?.seed_keyword_data?.keyword_properties?.keyword_difficulty ||
        0,
    },
    {
      title: "Cost Per Click",
      bg_color: "#EED8D8",
      img: cost_per_click,
      rank: `$${key_Data?.seed_keyword_data?.keyword_info?.cpc || 0}`,
      toolkit:
        "Average cost per click if you want to pay google, to be seen as an ad. if someone is paying a high CPC, the keyword is usually more valuable.",
    },
  ];

  const sm_card_data = [
    {
      title: "Average Daily Impression",
      img: averageImpres,
      value: `${
        key_Data?.seed_keyword_data?.impressions_info
          ?.daily_impressions_average || 0
      }`,
      toolkit: "Average daily Impressions of this Keyword",
    },
    {
      title: "Competition",
      img: competiIcon,
      value: `${key_Data?.seed_keyword_data?.keyword_info?.competition || 0}`,
      toolkit: "Average Competition Value based on this Keyword",
    },
    {
      title: "Average Daily Clicks",
      img: avgDalClk,
      value: `${
        key_Data?.seed_keyword_data?.impressions_info?.daily_clicks_average || 0
      }`,
      toolkit: "Average Daily Clicks on this Keyword",
    },
    {
      title: "Average Backlinks",
      img: avgBackIcon,
      value: `${
        key_Data?.seed_keyword_data?.avg_backlinks_info?.backlinks || 0
      }`,
      toolkit: "Average Backlinks based on this Keyword",
    },
    {
      title: "Average Domain Rank",
      img: domainRankIcon,
      value: `${
        key_Data?.seed_keyword_data?.avg_backlinks_info?.main_domain_rank || 0
      }`,
      toolkit: "Average Domain rank based on this Keyword",
    },
  ];

  const competitionLevel =
    key_Data?.seed_keyword_data?.keyword_info?.competition_level || 0;

  const headers = [
    {
      label: "Keyword",
      key: "keyword",
    },
  ];

  const handleQuesBasedSorting = () => {
    const data = queBasedKeyword;
    const sortedAscending = [...data].sort((a, b) => a.localeCompare(b));
    const sortedDescending = [...data].sort((a, b) => b.localeCompare(a));
    setDirection((pre) => !pre);
    const data2 = direction ? sortedAscending : sortedDescending;
    setQueBasedKeyword(data2);
  };

  const handleKeywordSorting = (field) => {
    const data = tableData;
    // Ascending Order
    const sortedByAsc = dynamicSort([...data], field, "asc");
    // Descending Order
    const sortedByDesc = dynamicSort([...data], field, "desc");
    setDirection((pre) => !pre);
    const sorted_data = direction ? sortedByAsc : sortedByDesc;
    setTableData(sorted_data);
  };

  const countryDetailHandler = (e) => {
    setDomainLocation({
      location_name: e?.location_name,
      location_code: e?.location_code,
      location_flag: e?.flag,
      language_code: e?.language_code,
      language_name: e?.language_name,
    });
  };

  const handleTabChange = (tab) => {
    ZustandStore.setState({
      tag_keywords: [],
      filter_tag: [],
      keyword_tag_data: {
        loaded: false,
        error: false,
        data: [],
      },
    });
    setSelectedTab(tab);
  };

  const showQuestionBasedTable = () => {
    setSelectAll({ select: false, page: 1 });
    setSelectedResObjects([]);
    setSelectedKeywords([]);
    handleTabChange(3);
  };
  const showKeywordVariantTable = () => {
    setQueBasedSelect(false);
    setSelectQuesBasedKey([]);
    handleTabChange(2);
  };

  const config = useMemo(() => {
    return {
      email,
      proj_name: keywordName,
      tag_type: selectedTab === 2 ? "keyword" : "qsb",
      location_name: domainLocation?.location_name,
      language_name: domainLocation?.language_name,
    };
  }, [email, keywordName, domainLocation, selectedTab]);

  const { loaded, error, data } = useKeywordTags(config, selectedTab);

  // generating Pdf dashboard

  const targetDashboard = useRef();
  const comp = useRef();
 
  const lvl = useRef();


  const handleRoute =()=>{
    const element = document.getElementById("keyresearch");
    if (comp.current) {
      comp.current.style.display = "none";
    }
    if (lvl.current) {
      lvl.current.style.display = "none";
    }
    if (element) {
      html2canvas(element, {
        useCORS: true, // If your container has external images, this helps to prevent cross-origin issues.
        backgroundColor: null, // Set to null if you want a transparent background.
      }).then((canvas) => {
        // Convert the canvas to a data URL in JPG format
        const imgData = canvas.toDataURL("image/jpeg");
  
        // Save the image data to sessionStorage
        sessionStorage.setItem("dashboardImage", imgData);
      }).catch((error) => {
        console.error("Error capturing the dashboard as an image:", error);
      });
    }

    sessionStorage.setItem('pdfSessionData',JSON.stringify({keywordName:keywordName,id:10}))
    setTimeout(() => {
      navigate("/CrawledPageEditor");
    }, 500)
     
    }




  const GenerateDashboardPDF = () => {
    const input = targetDashboard.current;


    if (comp.current) {
      comp.current.style.display = "none";
    }
    if (lvl.current) {
      lvl.current.style.display = "none";
    }
    const pdf = new jsPDF("p", "mm", "a4");
    const margin = 10; // Set the margin size
    const pageWidth = pdf.internal.pageSize.getWidth();
    const pageHeight = pdf.internal.pageSize.getHeight();

    // Add cover page
    pdf.setFontSize(20);
    pdf.setFont("helvetica");
    pdf.text("Keyword Research", pageWidth / 2, pageHeight / 3 + 60, {
      align: "center",
    });

    pdf.setFontSize(16);
    pdf.setFont("helvetica", "normal");
    pdf.setTextColor("gray");
    pdf.text(`keyword:${keywordName}`, pageWidth / 2, pageHeight / 3 + 70, {
      align: "center",
    });

    // Add your image to the cover page
    const imageURL = RanksProLogo; // Replace with your image URL or data URI
    const imageWidth = 74; // Adjust width as needed
    const imageHeight = 32; // Adjust height as needed
    pdf.addImage(
      imageURL,
      "PNG",
      (pageWidth - imageWidth) / 2,
      pageHeight / 3 - imageHeight + 60-5,
      imageWidth,
      imageHeight
    );

    pdf.addPage(); // Add a new page for the actual content

    // Capture and add the main content
    html2canvas(input, { scale: 2 }).then((canvas) => {
      const imgData = canvas.toDataURL("image/png");
      const imgWidth = pageWidth - 2 * margin;
      const imgHeight = (canvas.height * imgWidth) / canvas.width;

      let position = margin;

      // Add logo to the top-left corner of the second page
      pdf.addImage(RanksProLogo, "PNG", margin, margin, 40, 17); // Adjust size as needed

      pdf.addImage(imgData, "PNG", margin, position + 20, imgWidth, imgHeight);

      // Add additional pages if content exceeds one page
      while (position + imgHeight > pageHeight - margin) {
        position -= pageHeight - 2 * margin;
        pdf.addPage();
        pdf.addImage(RanksProLogo, "PNG", margin, margin, 30, 18); // Add logo on each new page
        pdf.addImage(
          imgData,
          "PNG",
          margin,
          position + 20,
          imgWidth,
          imgHeight
        );
      }

      pdf.save("KeywordResearch.pdf");
    });
  };

  return (
    <main className="keyword-research-container main-pd">
      <AlertPopUp config={Alert} handleClose={setAlert} />
      <div className="keyword-research-form keyword2">
        <div className="w-100">
          <h5 className="">Keyword Research</h5>
          <p className="keyword-p">Get a detailed Keyword overview</p>
          <form
            onSubmit={(e) => {
              keywordSearchHand(e);
            }}
          >
            <div className="overview-search-contain">
              <input
                className="keyword-input font-regular heading-small"
                placeholder="Enter a keyword"
                value={keywordName}
                onChange={(e) => {
                  sessionStorage.removeItem("KEYWORD_DOMAIN_NAME");
                  setKeywordName(e.target.value);
                  setValAlert(false);
                }}
              />

              <CountryBasedOnLanuage
                data={country_Language_Data}
                img={domainLocation?.location_flag}
                title={`${domainLocation?.language_name} / ${domainLocation?.location_name}`}
                style={{
                  border: "1px solid rgba(0, 0, 0, 0.3)",
                  textTransform: "none",
                  boxShadow: "none",
                  padding: "0.65rem",
                  minWidth: "300px",
                }}
                callback={countryDetailHandler}
              />

              <button
                className="icon-btn"
                onClick={(e) => keywordSearchHand(e)}
              >
                <img src={searchArrow} alt="" />
              </button>
            </div>
          </form>

          <p className="heading-medium font-bold vl-msd-line mt-3 ms-1">
            {" "}
            <span>{valAlert}</span>{" "}
          </p>
        </div>
      </div>
      {loading ? (
        <DottLoader />
      ) : key_Data?.length === 0 || key_Data?.items == null ? null : (
        <section className="page-tab-container">
          <nav className="page-tab-nav">
            <ul>
              <li
                className={selectedTab === 1 ? "selected" : ""}
                onClick={() => setSelectedTab(1)}
              >
                Dashboard
              </li>
              <li
                className={selectedTab === 2 ? "selected" : ""}
                onClick={() => showKeywordVariantTable()}
              >
                Keyword Variations
              </li>
          
              
              <li
                className={selectedTab === 3 ? "selected" : ""}
                onClick={() => showQuestionBasedTable()}
              >
                Question Based Keywords
              </li>
            </ul>
            <FiDownload
              style={{display:selectedTab !==1 ? "none":'flex'}}
               className="down"
               onClick={handleRoute}
              title="download Dashboard PDF"
            />

          </nav>
          <AnimatePresence mode="wait">
            <motion.div
              key={selectedTab ? selectedTab : "empty"}
              initial={{ y: 10, opacity: 0 }}
              animate={{ y: 0, opacity: 1 }}
              exit={{ y: -10, opacity: 0 }}
              transition={{ duration: 0.2 }}
            >
              {selectedTab === 1 ? (
                <div ref={targetDashboard} id="keyresearch" style={{backgroundColor:"#fffbf5"}}>
                  <section className="keyword-dashboard">
                    <div className="keyword-card-container">
                      <div className="keyword-card-cont">
                        <div className="large-card">
                          {card_data?.map((item) => {
                            return (
                              <KeywordCardMd data={item} key={item?.title} />
                            );
                          })}
                        </div>
                        <div className="mt-4 small-card">
                          {sm_card_data?.map((item) => {
                            return (
                              <KeywordCardSm data={item} key={item?.title} />
                            );
                          })}
                        </div>
                      </div>
                      <div className="competit-lvl">
                        <div className="comp-lvl-stepper">
                          <div className="text">
                            <h1 ref={comp} className="comp cls_lvl">COMPETITION</h1>
                            <h1 ref={lvl} className="lvl cls_lvl">LEVEL</h1>
                          </div>
                          <div className="stepper">
                            <VerticalLinearStepper
                              activeStep={
                                competitionLevel === "LOW"
                                  ? 1
                                  : competitionLevel === "MEDIUM"
                                  ? 2
                                  : competitionLevel === "HIGH"
                                  ? 3
                                  : 0
                              }
                            />
                          </div>
                        </div>
                        {key_Data?.seed_keyword_data?.search_intent_info
                          ?.main_intent && (
                          <div className="search-intent">
                            <h1>
                              {
                                key_Data?.seed_keyword_data?.search_intent_info
                                  ?.main_intent
                              }
                            </h1>
                            <img src={searchIntent} alt="search-intent" />
                          </div>
                        )}
                      </div>
                    </div>

                    <div className="row gy-3">
                      <div className="col-12 col-lg-8">
                        <div className="keyword-chart mt-4">
                          <h5 className="">Search Volume</h5>
                          <div style={{ overflow: "auto" }}>
                            <CommonLineChart
                              reverse={false}
                              label={selectedKeywordName}
                              chartdata={searchVolData}
                              chartlabels={searchVolLabels}
                              stepSize={5000}
                            />
                          </div>
                        </div>
                      </div>
                      <div className="col-12 col-lg-4">
                        <div
                          className="overview-table mt-4"
                          style={{
                            overflow: "hidden",
                            boxShadow: "0px 4px 4px 0px rgba(0, 0, 0, 0.25)",
                          }}
                        >
                          <table className="">
                            <thead>
                              <tr className="">
                                <th scope="col" className="ps-3 p-2">
                                  KEYWORDS
                                  <ToolTip title="Words that people type on Google." />
                                </th>
                                <th
                                  scope="col"
                                  className="p-2 text-center"
                                  style={{ width: "120px" }}
                                >
                                  VOLUME
                                  <ToolTip title="The number of searches this particular keyword has during a month." />
                                </th>
                                <th
                                  scope="col"
                                  className="p-2 text-center"
                                  style={{ width: "120px" }}
                                >
                                  CPC
                                  <ToolTip title="Cost per Click: Average cost per click if you want to pay google to be seen as an ad. if someone is paying a high CPC the keyword is usually a more valuable." />
                                </th>
                              </tr>
                            </thead>
                            <tbody>
                              {key_Data?.length === 0 ? (
                                <tr>
                                  <td colSpan={6} className="text-center">
                                    No data found !
                                  </td>
                                </tr>
                              ) : key_Data?.items?.length === 0 ||
                                key_Data?.items == null ? (
                                <tr>
                                  <td colSpan={6} className="text-center">
                                    No data found !
                                  </td>
                                </tr>
                              ) : (
                                key_Data?.items
                                  ?.slice(0, 6)
                                  ?.map((res, index) => {
                                    return (
                                      <React.Fragment
                                        key={`keyword-research-${index}`}
                                      >
                                        <tr
                                          key={`${index}-${res.keyword}`}
                                          style={{ verticalAlign: "middle" }}
                                        >
                                          <td className="t_left ps-3 keyword-name">
                                            {res.keyword}
                                          </td>
                                          <td className="text-center">
                                            {
                                              res?.keyword_info
                                                ?.monthly_searches[0]
                                                ?.search_volume
                                            }
                                          </td>
                                          <td className="text-center">
                                            ${res?.keyword_info?.cpc || 0}
                                          </td>
                                        </tr>
                                      </React.Fragment>
                                    );
                                  })
                              )}
                            </tbody>
                          </table>
                          {key_Data?.items?.length > 0 ? (
                            <div
                              className="see-more-link"
                              style={{ textDecoration: "none" }}
                            >
                              <button
                                className={`btn-sm float-right`}
                                style={{ fontSize: "1em !important" }}
                                onClick={() => handleTabChange(2)}
                              >
                                View All
                              </button>
                            </div>
                          ) : null}
                        </div>
                      </div>
                    </div>
                  </section>
                </div>
              ) : selectedTab === 2 ? (
                <section className="keyword-research-data">
                  <div className="d-flex justify-content-between align-items-center mb-4">
                    <h5>Keyword Ideas</h5>

                    <div className="d-flex justify-content-center align-items-center">
                      <FilterTag data={data} />
                      <button
                        className="cm-btn ms-3"
                        disabled={selectedResObjects?.length === 0}
                        onClick={() => {
                          setShowAddKeyword(true);
                        }}
                      >
                        <img
                          src={addIcon}
                          alt="add keyword"
                          className="me-2"
                          style={{ width: "15px" }}
                        />
                        Rank Tracking
                      </button>
                      <button
                        className="icon-btn ms-3"
                        title="Add Keyword to List"
                        disabled={selectedResObjects?.length === 0}
                        onClick={() => {
                          setaddlistModal(true);
                        }}
                      >
                        <img src={addIcon} alt="add keyword" />
                      </button>

                      <CSVLink
                        data={selectedComData}
                        filename={"keyword Ideas.csv"}
                      >
                        <button
                          disabled={selectedResObjects?.length === 0}
                          className="icon-btn ms-3"
                          title="Export File"
                        >
                          <img src={exportFileIcon} alt="export-file" />
                        </button>
                      </CSVLink>

                      <Link to={`../${ViewAllList}?list_type=keyword`}>
                        <button
                          className="icon-btn ms-3"
                          title="View Keyword List"
                        >
                          <CiCircleList />
                        </button>
                      </Link>

                      <FiDownload
                        class="down"
                        
                        title="Download KeywordVerification Pdf File"
                        data-bs-toggle="modal"
                        data-bs-target="#staticBackdrop"
                      />

                      <div
                        class="modal fade"
                        id="staticBackdrop"
                        data-bs-backdrop="static"
                        data-bs-keyboard="false"
                        tabindex="-1"
                        aria-labelledby="staticBackdropLabel"
                        aria-hidden="true"
                      >
                        <div class="modal-dialog">
                          <div class="modal-content">
                            <div class="modal-header"style={{
                                    zIndex:"100",
                                    borderBottom: "none",
    position: "absolute",
    right: 0,
    padding: "36px",
}}>
                              
                              <button
                                type="button"
                                class="btn-close"
                                data-bs-dismiss="modal"
                                aria-label="Close"
                              ></button>
                            </div>
                            <div class="modal-body">
                              <KeywordVariationsPdf
                                tableData={tableData}
                                keywordName={keywordName}
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div id="keywords-ideas">
                    <div className="w-100 keyword-research-table">
                      <div className="custom-table-container">
                        <table className="">
                          <thead>
                            <tr className="table-sticky">
                              <th
                                scope="col"
                                className="check-box-input"
                                onClick={() => {
                                  selectAll?.select &&
                                  selectAll?.page === tablePage
                                    ? setSelectAll({
                                        select: !selectAll?.select,
                                        page: tablePage,
                                      })
                                    : setSelectAll({
                                        select: true,
                                        page: tablePage,
                                      });
                                }}
                              >
                                <input
                                  type="checkbox"
                                  checked={
                                    selectAll?.select &&
                                    selectAll?.page === tablePage
                                  }
                                  readOnly
                                />
                              </th>
                              <th className="col">
                                <div className="d-flex">
                                  <div
                                    className="d-flex align-items-center point"
                                    onClick={() =>
                                      handleKeywordSorting("keyword")
                                    }
                                  >
                                    KEYWORDS
                                    <span className="ms-2">
                                      <UnsortIcon />
                                    </span>
                                  </div>
                                  <ToolTip title="Words that people type on Google." />
                                </div>
                              </th>

                              <th className="col">
                                TREND
                                <ToolTip title="Search volume of the keyword over the last 12 months." />
                              </th>

                              <th className="col">
                                <div className="d-flex justify-content-center">
                                  <div
                                    className="d-flex align-items-center point"
                                    onClick={() =>
                                      handleKeywordSorting("volume")
                                    }
                                  >
                                    VOLUME
                                    <span className="ms-2">
                                      <UnsortIcon />
                                    </span>
                                  </div>
                                  <ToolTip title="The number of searches this particular keyword has during a month." />
                                </div>
                              </th>

                              <th className="col">
                                <div className="d-flex justify-content-center">
                                  <div
                                    className="d-flex align-items-center point"
                                    onClick={() => handleKeywordSorting("cpc")}
                                  >
                                    CPC
                                    <span className="ms-2">
                                      <UnsortIcon />
                                    </span>
                                  </div>
                                  <ToolTip title="Cost per Click: Average cost per click if you want to pay google to be seen as an ad. if someone is paying a high CPC the keyword is usually a more valuable." />
                                </div>
                              </th>

                              <th className="col">
                                <div className="d-flex justify-content-center">
                                  <div
                                    className="d-flex align-items-center point"
                                    onClick={() => handleKeywordSorting("sd")}
                                  >
                                    SD
                                    <span className="ms-2">
                                      <UnsortIcon />
                                    </span>
                                  </div>
                                  <ToolTip
                                    title="SEO Difficulty: Estimated competition in organic search, the higher the number the more competitive.
                                Low Difficulty: Mark by Green color,
                                Medium Difficulty: Mark by  Orange color,
                                High Difficulty: Mark by Red color"
                                  />
                                </div>
                              </th>
                            </tr>
                          </thead>
                          <tbody>
                            {key_Data?.length === 0 ? (
                              <tr>
                                <td colSpan={6} className="text-center">
                                  No data found !
                                </td>
                              </tr>
                            ) : key_Data?.items?.length === 0 ||
                              key_Data?.items == null ? (
                              <tr>
                                <td colSpan={6} className="text-center">
                                  No data found !
                                </td>
                              </tr>
                            ) : !loaded ? (
                              <tr>
                                <td colSpan={6} className="text-center">
                                  <Spinner />
                                </td>
                              </tr>
                            ) : (
                              displayedItems?.map((res, ind) => {
                                return (
                                  <React.Fragment
                                    key={`research-keyword-${res.keyword}`}
                                  >
                                    <tr key={res.keyword}>
                                      <td
                                        className="check-box-input"
                                        onClick={(e) =>
                                          handleCheckboxChange(res.keyword, res)
                                        }
                                      >
                                        <input
                                          type="checkbox"
                                          checked={selectedKeywords.includes(
                                            res.keyword
                                          )}
                                          readOnly
                                        />
                                      </td>

                                      <td
                                        className="t_left"
                                        onClick={() => {
                                          // handlekeywordVolRes(res);
                                          // res.keyword === selectedKey
                                          //   ? setSelectedKey(false)
                                          //   : setSelectedKey(res.keyword);
                                        }}
                                      >
                                        {res.keyword}
                                        {loaded && !error && (
                                          <span>
                                            <TagComponent
                                              config={config}
                                              list={data}
                                              keyword={res.keyword}
                                              location={
                                                domainLocation?.location_name
                                              }
                                              key={res.keyword}
                                            />
                                          </span>
                                        )}
                                      </td>

                                      <td
                                        onClick={() => {
                                          handlekeywordVolRes(res);

                                          res.keyword === selectedKey
                                            ? setSelectedKey(false)
                                            : setSelectedKey(res.keyword);
                                        }}
                                      >
                                        <img src={trendSvg} alt="" />
                                      </td>

                                      <td
                                        onClick={() => {
                                          handlekeywordVolRes(res);

                                          res.keyword === selectedKey
                                            ? setSelectedKey(false)
                                            : setSelectedKey(res.keyword);
                                        }}
                                      >
                                        {
                                          res?.keyword_info?.monthly_searches[0]
                                            ?.search_volume
                                        }
                                      </td>

                                      <td
                                        onClick={() => {
                                          handlekeywordVolRes(res);

                                          res.keyword === selectedKey
                                            ? setSelectedKey(false)
                                            : setSelectedKey(res.keyword);
                                        }}
                                      >
                                        ${res?.keyword_info?.cpc || 0}
                                      </td>

                                      <td
                                        onClick={() => {
                                          handlekeywordVolRes(res);

                                          res.keyword === selectedKey
                                            ? setSelectedKey(false)
                                            : setSelectedKey(res.keyword);
                                        }}
                                      >
                                        <span
                                          className="keyword-sd"
                                          style={diffStyle(
                                            res?.keyword_properties
                                              ?.keyword_difficulty || 0
                                          )}
                                        >
                                          {res?.keyword_properties
                                            ?.keyword_difficulty || 0}
                                        </span>
                                      </td>
                                    </tr>

                                    {res.keyword === selectedKey ? (
                                      <tr style={{ backgroundColor: "#fff" }}>
                                        <td className="t_left" colSpan={6}>
                                          <div className="keyword-research-chart">
                                            <h6 className="">
                                              Search Volume :{" "}
                                              <span>{selectedKey}</span>
                                            </h6>

                                            <CommonLineChart
                                              reverse={false}
                                              label={selectedKeywordName}
                                              chartdata={selectedKeyData}
                                              chartlabels={selectedKeyLabels}
                                              stepSize={1000}
                                            />
                                          </div>
                                        </td>
                                      </tr>
                                    ) : null}
                                  </React.Fragment>
                                );
                              })
                            )}
                          </tbody>
                        </table>
                      </div>
                      <div className="mt-2 d-flex justify-content-center align-items-center">
                        <CustomPagination
                          page={tablePage}
                          onPageChange={handlePaginationChange}
                          totalPage={totalPages}
                        />
                      </div>
                    </div>
                  </div>
                </section>
              ) : (
                <section className="ques-based-keyword">
                  <div className="total-volume-cont mb-4">
                    <span className="volume mx-2">
                      Total Keywords
                      <ToolTip title="Total Number of Question based keywords" />
                      :{" "}
                      <small className="ms-2">
                        {questionBasedKeywords?.length || 0}
                      </small>
                    </span>
                    <span className="d-flex">
                      <FilterTag data={data} />
                      <button
                        className="icon-btn ms-3"
                        title="Add Keyword to List"
                        disabled={questionBasedKeywords?.length === 0}
                        onClick={() => {
                          setaddlistModal(true);
                        }}
                      >
                        <img src={addIcon} alt="add keyword" />
                      </button>

                      <CSVLink
                        filename={`${keywordName}.csv`}
                        headers={headers}
                        data={loadCSVData()}
                      >
                        <button
                          className="icon-btn ms-3"
                          title="Export File"
                          disabled={questionBasedKeywords?.length === 0}
                        >
                          <img src={exportFileIcon} alt="export-file" />
                        </button>
                      </CSVLink>

                      <Link to={`../${ViewAllList}?list_type=qsb`}>
                        <button
                          className="icon-btn ms-3"
                          title="View Keyword List"
                        >
                          <CiCircleList />
                        </button>
                      </Link>

                      <FiDownload
                        class=" down"
                      
                        title="Download Question Based Pdf File"
                        data-bs-toggle="modal"
                        data-bs-target="#staticBackdrop"
                      />

                      <div
                        class="modal fade"
                        id="staticBackdrop"
                        data-bs-backdrop="static"
                        data-bs-keyboard="false"
                        tabindex="-1"
                        aria-labelledby="staticBackdropLabel"
                        aria-hidden="true"
                      >
                        <div class="modal-dialog">
                          <div class="modal-content">
                            <div class="modal-header" style={{
                                    zIndex:"100",
                                    borderBottom: "none",
    position: "absolute",
    right: 0,
    padding: "36px",
}}>
                              
                              <button
                                type="button"
                                class="btn-close"
                                data-bs-dismiss="modal"
                                aria-label="Close"
                              ></button>
                            </div>
                            <div class="modal-body">
                              <QuestionBasedVarificationPdf
                                questionBasedKeywords={questionBasedKeywords}
                                keywordName={keywordName}
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                    </span>
                  </div>
                  <div className="custom-table-container">
                    <table className="ques-based-keyword-table">
                      <thead>
                        <tr className="table-sticky">
                          <th
                            scope="col"
                            className="check-box-input"
                            onClick={() => {
                              setQueBasedSelect((pre) => !pre);
                            }}
                          >
                            <input
                              type="checkbox"
                              checked={quesBasedSelect}
                              readOnly
                            />
                          </th>
                          <th className="ques-keyword">
                            <div className="d-flex">
                              <div
                                className="d-flex align-items-center point"
                                onClick={() => handleQuesBasedSorting()}
                              >
                                KEYWORDS
                                <span className="ms-2">
                                  <UnsortIcon />
                                </span>
                              </div>
                              <ToolTip title="Question based keywords" />
                            </div>
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        {questionBasedKeywords?.length === 0 ? (
                          <tr>
                            <td colSpan={2} className="text-center">
                              You don't have any data !
                            </td>
                          </tr>
                        ) : !loaded ? (
                          <tr>
                            <td colSpan={2} className="text-center">
                              <Spinner />
                            </td>
                          </tr>
                        ) : (
                          questionBasedKeywords?.map((item, index) => {
                            return (
                              <tr key={`${item}-${index}`}>
                                <td
                                  className="check-box-input"
                                  onClick={() =>
                                    handleQuesBasedKeywordCheckboxChange(item)
                                  }
                                >
                                  <input
                                    type="checkbox"
                                    checked={selectQuesBasedKey.includes(item)}
                                    readOnly
                                  />
                                </td>
                                <td className="ques-keyword">
                                  {item}
                                  {loaded && !error && (
                                    <span>
                                      <TagComponent
                                        config={config}
                                        list={data}
                                        keyword={item}
                                        key={item}
                                        location={domainLocation?.location_name}
                                      />
                                    </span>
                                  )}
                                </td>
                              </tr>
                            );
                          })
                        )}
                      </tbody>
                    </table>
                  </div>
                </section>
              )}
            </motion.div>
          </AnimatePresence>
        </section>
      )}
      <div>
        <AddListModal
          {...{
            DeleteLoading,
            setDeleteLoading,
            HandleDeleteList, // Delete
            handleGetListData, // getList Data on click
            HandleOpenModalAddList, // For Open Modal
            HandleAddKeywordList,
            ViewAllLists,
            setaddlistModal,
            addListModal,
            setaddNewlistModal,
            addNewListModal,
            ListError,
            ListLimitError,
            keyWordLenErr,
            setKeyWordLenErr,
          }}
        />
      </div>
      <div>
        <AddNewListModal
          {...{
            setinputEmptyErr,

            inputEmptyErr,
            ListLimitError,
            setAlertMsg,
            alertMsg,
            HandleListAdd,
            setListValue,
            setaddNewlistModal,
            addNewListModal,
          }}
        />
      </div>

      {showAddKeyword && (
        <AddKeywordResearch
          {...{
            showAddKeyword,
            setShowAddKeyword,
            selectedKeywords,
          }}
        />
      )}
      <ToastContainer autoClose={1000} />
    </main>
  );
};

export default Keywords;

const KeywordCardMd = ({ data }) => {
  return (
    <div className="keyword-card">
      <div className="d-flex keyword-card-header">
        <h6 className="">
          {data?.title}
          <ToolTip title={data?.toolkit} />
        </h6>
        <img src={data?.img} alt={data?.title} />
      </div>
      <h1 className="mb-0">{data?.rank?.toLocaleString() || 0}</h1>
    </div>
  );
};

const KeywordCardSm = ({ data }) => {
  return (
    <div className="keyword-card-sm">
      <div className="d-flex keyword-card-header">
        <h6 className="">
          {data?.title}
          <ToolTip title={data?.toolkit} />
        </h6>
        <img src={data?.img} alt={data?.title} />
      </div>
      <h1 className="mb-0">{data?.value?.toLocaleString() || 0}</h1>
    </div>
  );
};
