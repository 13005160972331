import { language } from "./dataforseo-languages";

const locations = [
  {
    location_name: "United States",
    location_code: 2840,
    location_flag: "https://flagsapi.com/US/flat/24.png",
    languagedata: language,
  },
  {
    location_name: "Afghanistan",
    location_code: 2004,
    location_flag: "https://flagsapi.com/AF/flat/24.png",
    languagedata: language,
  },
  {
    location_name: "Albania",
    location_code: 2008,
    location_flag: "https://flagsapi.com/AL/flat/24.png",
    languagedata: language,
  },
  {
    location_name: "Algeria",
    location_code: 2012,
    location_flag: "https://flagsapi.com/DZ/flat/24.png",
    languagedata: language,
  },
  {
    location_name: "American Samoa",
    location_code: 2016,
    location_flag: "https://flagsapi.com/AS/flat/24.png",
    languagedata: language,
  },
  {
    location_name: "Andorra",
    location_code: 2020,
    location_flag: "https://flagsapi.com/AD/flat/24.png",
    languagedata: language,
  },
  {
    location_name: "Angola",
    location_code: 2024,
    location_flag: "https://flagsapi.com/AO/flat/24.png",
    languagedata: language,
  },
  {
    location_name: "Antarctica",
    location_code: 2010,
    location_flag: "https://flagsapi.com/AQ/flat/24.png",
    languagedata: language,
  },
  {
    location_name: "Antigua and Barbuda",
    location_code: 2028,
    location_flag: "https://flagsapi.com/AG/flat/24.png",
    languagedata: language,
  },
  {
    location_name: "Argentina",
    location_code: 2032,
    location_flag: "https://flagsapi.com/AR/flat/24.png",
    languagedata: language,
  },
  {
    location_name: "Armenia",
    location_code: 2051,
    location_flag: "https://flagsapi.com/AM/flat/24.png",
    languagedata: language,
  },
  {
    location_name: "Australia",
    location_code: 2036,
    location_flag: "https://flagsapi.com/AU/flat/24.png",
    languagedata: language,
  },
  {
    location_name: "Austria",
    location_code: 2040,
    location_flag: "https://flagsapi.com/AT/flat/24.png",
    languagedata: language,
  },
  {
    location_name: "Azerbaijan",
    location_code: 2031,
    location_flag: "https://flagsapi.com/AZ/flat/24.png",
    languagedata: language,
  },
  {
    location_name: "Bahrain",
    location_code: 2048,
    location_flag: "https://flagsapi.com/BH/flat/24.png",
    languagedata: language,
  },
  {
    location_name: "Bangladesh",
    location_code: 2050,
    location_flag: "https://flagsapi.com/BD/flat/24.png",
    languagedata: language,
  },
  {
    location_name: "Barbados",
    location_code: 2052,
    location_flag: "https://flagsapi.com/BB/flat/24.png",
    languagedata: language,
  },
  {
    location_name: "Belgium",
    location_code: 2056,
    location_flag: "https://flagsapi.com/BE/flat/24.png",
    languagedata: language,
  },
  {
    location_name: "Belize",
    location_code: 2084,
    location_flag: "https://flagsapi.com/BZ/flat/24.png",
    languagedata: language,
  },
  {
    location_name: "Benin",
    location_code: 2204,
    location_flag: "https://flagsapi.com/BJ/flat/24.png",
    languagedata: language,
  },
  {
    location_name: "Bhutan",
    location_code: 2064,
    location_flag: "https://flagsapi.com/BT/flat/24.png",
    languagedata: language,
  },
  {
    location_name: "Bolivia",
    location_code: 2068,
    location_flag: "https://flagsapi.com/BO/flat/24.png",
    languagedata: language,
  },
  {
    location_name: "Bosnia and Herzegovina",
    location_code: 2070,
    location_flag: "https://flagsapi.com/BA/flat/24.png",
    languagedata: language,
  },
  {
    location_name: "Botswana",
    location_code: 2072,
    location_flag: "https://flagsapi.com/BW/flat/24.png",
    languagedata: language,
  },
  {
    location_name: "Brazil",
    location_code: 2076,
    location_flag: "https://flagsapi.com/BR/flat/24.png",
    languagedata: language,
  },
  {
    location_name: "Brunei",
    location_code: 2096,
    location_flag: "https://flagsapi.com/BN/flat/24.png",
    languagedata: language,
  },
  {
    location_name: "Bulgaria",
    location_code: 2100,
    location_flag: "https://flagsapi.com/BG/flat/24.png",
    languagedata: language,
  },
  {
    location_name: "Burkina Faso",
    location_code: 2854,
    location_flag: "https://flagsapi.com/BF/flat/24.png",
    languagedata: language,
  },
  {
    location_name: "Burundi",
    location_code: 2108,
    location_flag: "https://flagsapi.com/BI/flat/24.png",
    languagedata: language,
  },
  {
    location_name: "Cambodia",
    location_code: 2116,
    location_flag: "https://flagsapi.com/CH/flat/24.png",
    languagedata: language,
  },
  {
    location_name: "Cameroon",
    location_code: 2120,
    location_flag: "https://flagsapi.com/CM/flat/24.png",
    languagedata: language,
  },
  {
    location_name: "Canada",
    location_code: 2124,
    location_flag: "https://flagsapi.com/CA/flat/24.png",
    languagedata: language,
  },
  {
    location_name: "Cape Verde",
    location_code: 2132,
    location_flag: "https://flagsapi.com/CV/flat/24.png",
    languagedata: language,
  },
  {
    location_name: "Central African Republic",
    location_code: 2140,
    location_flag: "https://flagsapi.com/CF/flat/24.png",
    languagedata: language,
  },
  {
    location_name: "Chad",
    location_code: 2148,
    location_flag: "https://flagsapi.com/TD/flat/24.png",
    languagedata: language,
  },
  {
    location_name: "Chile",
    location_code: 2152,
    location_flag: "https://flagsapi.com/CL/flat/24.png",
    languagedata: language,
  },
  {
    location_name: "China",
    location_code: 2156,
    location_flag: "https://flagsapi.com/CN/flat/24.png",
    languagedata: language,
  },
  {
    location_name: "Christmas Island",
    location_code: 2162,
    location_flag: "https://flagsapi.com/CX/flat/24.png",
    languagedata: language,
  },
  {
    location_name: "Cocos (Keeling) Islands",
    location_code: 2166,
    location_flag: "https://flagsapi.com/CC/flat/24.png",
    languagedata: language,
  },
  {
    location_name: "Colombia",
    location_code: 2170,
    location_flag: "https://flagsapi.com/CO/flat/24.png",
    languagedata: language,
  },
  {
    location_name: "Comoros",
    location_code: 2174,
    location_flag: "https://flagsapi.com/KM/flat/24.png",
    languagedata: language,
  },
  {
    location_name: "Cook Islands",
    location_code: 2184,
    location_flag: "https://flagsapi.com/CK/flat/24.png",
    languagedata: language,
  },
  {
    location_name: "Costa Rica",
    location_code: 2188,
    location_flag: "https://flagsapi.com/CR/flat/24.png",
    languagedata: language,
  },
  {
    location_name: "Croatia",
    location_code: 2191,
    location_flag: "https://flagsapi.com/HR/flat/24.png",
    languagedata: language,
  },
  {
    location_name: "Cyprus",
    location_code: 2196,
    location_flag: "https://flagsapi.com/NY/flat/24.png",
    languagedata: language,
  },
  {
    location_name: "Czechia",
    location_code: 2203,
    location_flag: "https://flagsapi.com/CZ/flat/24.png",
    languagedata: language,
  },
  {
    location_name: "Democratic Republic of the Congo",
    location_code: 2180,
    location_flag: "https://flagsapi.com/CD/flat/24.png",
    languagedata: language,
  },
  {
    location_name: "Denmark",
    location_code: 2208,
    location_flag: "https://flagsapi.com/DK/flat/24.png",
    languagedata: language,
  },
  {
    location_name: "Djibouti",
    location_code: 2262,
    location_flag: "https://flagsapi.com/DJ/flat/24.png",
    languagedata: language,
  },
  {
    location_name: "Dominica",
    location_code: 2212,
    location_flag: "https://flagsapi.com/DM/flat/24.png",
    languagedata: language,
  },
  {
    location_name: "Dominican Republic",
    location_code: 2214,
    location_flag: "https://flagsapi.com/DO/flat/24.png",
    languagedata: language,
  },
  {
    location_name: "Ecuador",
    location_code: 2218,
    location_flag: "https://flagsapi.com/EC/flat/24.png",
    languagedata: language,
  },
  {
    location_name: "Egypt",
    location_code: 2818,
    location_flag: "https://flagsapi.com/EG/flat/24.png",
    languagedata: language,
  },
  {
    location_name: "El Salvador",
    location_code: 2222,
    location_flag: "https://flagsapi.com/SV/flat/24.png",
    languagedata: language,
  },
  {
    location_name: "Equatorial Guinea",
    location_code: 2226,
    location_flag: "https://flagsapi.com/GQ/flat/24.png",
    languagedata: language,
  },
  {
    location_name: "Eritrea",
    location_code: 2232,
    location_flag: "https://flagsapi.com/ER/flat/24.png",
    languagedata: language,
  },
  {
    location_name: "Estonia",
    location_code: 2233,
    location_flag: "https://flagsapi.com/EE/flat/24.png",
    languagedata: language,
  },
  {
    location_name: "Ethiopia",
    location_code: 2231,
    location_flag: "https://flagsapi.com/ET/flat/24.png",
    languagedata: language,
  },
  {
    location_name: "Fiji",
    location_code: 2242,
    location_flag: "https://flagsapi.com/FJ/flat/24.png",
    languagedata: language,
  },
  {
    location_name: "Finland",
    location_code: 2246,
    location_flag: "https://flagsapi.com/FI/flat/24.png",
    languagedata: language,
  },
  {
    location_name: "France",
    location_code: 2250,
    location_flag: "https://flagsapi.com/FR/flat/24.png",
    languagedata: language,
  },
  {
    location_name: "French Polynesia",
    location_code: 2258,
    location_flag: "https://flagsapi.com/PF/flat/24.png",
    languagedata: language,
  },
  {
    location_name: "French Southern and Antarctic Lands",
    location_code: 2260,
    location_flag: "https://flagsapi.com/TF/flat/24.png",
    languagedata: language,
  },
  {
    location_name: "Gabon",
    location_code: 2266,
    location_flag: "https://flagsapi.com/GA/flat/24.png",
    languagedata: language,
  },
  {
    location_name: "Georgia",
    location_code: 2268,
    location_flag: "https://flagsapi.com/GE/flat/24.png",
    languagedata: language,
  },
  {
    location_name: "Germany",
    location_code: 2276,
    location_flag: "https://flagsapi.com/DE/flat/24.png",
    languagedata: language,
  },
  {
    location_name: "Ghana",
    location_code: 2288,
    location_flag: "https://flagsapi.com/GH/flat/24.png",
    languagedata: language,
  },
  {
    location_name: "Greece",
    location_code: 2300,
    location_flag: "https://flagsapi.com/GR/flat/24.png",
    languagedata: language,
  },
  {
    location_name: "Grenada",
    location_code: 2308,
    location_flag: "https://flagsapi.com/GD/flat/24.png",
    languagedata: language,
  },
  {
    location_name: "Guam",
    location_code: 2316,
    location_flag: "https://flagsapi.com/GU/flat/24.png",
    languagedata: language,
  },
  {
    location_name: "Guatemala",
    location_code: 2320,
    location_flag: "https://flagsapi.com/GT/flat/24.png",
    languagedata: language,
  },
  {
    location_name: "Guinea",
    location_code: 2324,
    location_flag: "https://flagsapi.com/GN/flat/24.png",
    languagedata: language,
  },
  {
    location_name: "Guyana",
    location_code: 2328,
    location_flag: "https://flagsapi.com/GY/flat/24.png",
    languagedata: language,
  },
  {
    location_name: "Haiti",
    location_code: 2332,
    location_flag: "https://flagsapi.com/HT/flat/24.png",
    languagedata: language,
  },
  {
    location_name: "Honduras",
    location_code: 2340,
    location_flag: "https://flagsapi.com/HN/flat/24.png",
    languagedata: language,
  },
  {
    location_name: "Hungary",
    location_code: 2348,
    location_flag: "https://flagsapi.com/HU/flat/24.png",
    languagedata: language,
  },
  {
    location_name: "Iceland",
    location_code: 2352,
    location_flag: "https://flagsapi.com/IS/flat/24.png",
    languagedata: language,
  },
  {
    location_name: "India",
    location_code: 2356,
    location_flag: "https://flagsapi.com/IN/flat/24.png",
    languagedata: language,
  },
  {
    location_name: "Indonesia",
    location_code: 2360,
    location_flag: "https://flagsapi.com/ID/flat/24.png",
    languagedata: language,
  },
  {
    location_name: "Iraq",
    location_code: 2368,
    location_flag: "https://flagsapi.com/IQ/flat/24.png",
    languagedata: language,
  },
  {
    location_name: "Ireland",
    location_code: 2372,
    location_flag: "https://flagsapi.com/IE/flat/24.png",
    languagedata: language,
  },
  {
    location_name: "Israel",
    location_code: 2376,
    location_flag: "https://flagsapi.com/IL/flat/24.png",
    languagedata: language,
  },
  {
    location_name: "Italy",
    location_code: 2380,
    location_flag: "https://flagsapi.com/IT/flat/24.png",
    languagedata: language,
  },
  {
    location_name: "Jamaica",
    location_code: 2388,
    location_flag: "https://flagsapi.com/JM/flat/24.png",
    languagedata: language,
  },
  {
    location_name: "Japan",
    location_code: 2392,
    location_flag: "https://flagsapi.com/JP/flat/24.png",
    languagedata: language,
  },
  {
    location_name: "Jersey",
    location_code: 2832,
    location_flag: "https://flagsapi.com/JE/flat/24.png",
    languagedata: language,
  },
  {
    location_name: "Jordan",
    location_code: 2400,
    location_flag: "https://flagsapi.com/JO/flat/24.png",
    languagedata: language,
  },
  {
    location_name: "Kazakhstan",
    location_code: 2398,
    location_flag: "https://flagsapi.com/KZ/flat/24.png",
    languagedata: language,
  },
  {
    location_name: "Kenya",
    location_code: 2404,
    location_flag: "https://flagsapi.com/KE/flat/24.png",
    languagedata: language,
  },
  {
    location_name: "Kiribati",
    location_code: 2296,
    location_flag: "https://flagsapi.com/KI/flat/24.png",
    languagedata: language,
  },
  {
    location_name: "Kuwait",
    location_code: 2414,
    location_flag: "https://flagsapi.com/KW/flat/24.png",
    languagedata: language,
  },
  {
    location_name: "Kyrgyzstan",
    location_code: 2417,
    location_flag: "https://flagsapi.com/KG/flat/24.png",
    languagedata: language,
  },
  {
    location_name: "Laos",
    location_code: 2418,
    location_flag: "https://flagsapi.com/LA/flat/24.png",
    languagedata: language,
  },
  {
    location_name: "Latvia",
    location_code: 2428,
    location_flag: "https://flagsapi.com/LV/flat/24.png",
    languagedata: language,
  },
  {
    location_name: "Lebanon",
    location_code: 2422,
    location_flag: "https://flagsapi.com/LB/flat/24.png",
    languagedata: language,
  },
  {
    location_name: "Lesotho",
    location_code: 2426,
    location_flag: "https://flagsapi.com/LS/flat/24.png",
    languagedata: language,
  },
  {
    location_name: "Liberia",
    location_code: 2430,
    location_flag: "https://flagsapi.com/LR/flat/24.png",
    languagedata: language,
  },
  {
    location_name: "Libya",
    location_code: 2434,
    location_flag: "https://flagsapi.com/LY/flat/24.png",
    languagedata: language,
  },
  {
    location_name: "Liechtenstein",
    location_code: 2438,
    location_flag: "https://flagsapi.com/LI/flat/24.png",
    languagedata: language,
  },
  {
    location_name: "Lithuania",
    location_code: 2440,
    location_flag: "https://flagsapi.com/LT/flat/24.png",
    languagedata: language,
  },
  {
    location_name: "Malaysia",
    location_code: 2458,
    location_flag: "https://flagsapi.com/MY/flat/24.png",
    languagedata: language,
  },
  {
    location_name: "Maldives",
    location_code: 2462,
    location_flag: "https://flagsapi.com/MD/flat/24.png",
    languagedata: language,
  },
  {
    location_name: "Mali",
    location_code: 2466,
    location_flag: "https://flagsapi.com/ML/flat/24.png",
    languagedata: language,
  },
  {
    location_name: "Malta",
    location_code: 2470,
    location_flag: "https://flagsapi.com/MT/flat/24.png",
    languagedata: language,
  },
  {
    location_name: "Mauritius",
    location_code: 2480,
    location_flag: "https://flagsapi.com/MU/flat/24.png",
    languagedata: language,
  },
  {
    location_name: "Mexico",
    location_code: 2484,
    location_flag: "https://flagsapi.com/MX/flat/24.png",
    languagedata: language,
  },
  {
    location_name: "Monaco",
    location_code: 2492,
    location_flag: "https://flagsapi.com/MC/flat/24.png",
    languagedata: language,
  },
  {
    location_name: "Mongolia",
    location_code: 2496,
    location_flag: "https://flagsapi.com/MN/flat/24.png",
    languagedata: language,
  },
  {
    location_name: "Morocco",
    location_code: 2504,
    location_flag: "https://flagsapi.com/MA/flat/24.png",
    languagedata: language,
  },
  {
    location_name: "Myanmar (Burma)",
    location_code: 2104,
    location_flag: "https://flagsapi.com/MM/flat/24.png",
    languagedata: language,
  },
  {
    location_name: "Namibia",
    location_code: 2516,
    location_flag: "https://flagsapi.com/NA/flat/24.png",
    languagedata: language,
  },
  {
    location_name: "Nepal",
    location_code: 2524,
    location_flag: "https://flagsapi.com/NP/flat/24.png",
    languagedata: language,
  },
  {
    location_name: "Netherlands",
    location_code: 2528,
    location_flag: "https://flagsapi.com/NL/flat/24.png",
    languagedata: language,
  },
  {
    location_name: "New Zealand",
    location_code: 2554,
    location_flag: "https://flagsapi.com/NZ/flat/24.png",
    languagedata: language,
  },
  {
    location_name: "Niger",
    location_code: 2562,
    location_flag: "https://flagsapi.com/NE/flat/24.png",
    languagedata: language,
  },
  {
    location_name: "Nigeria",
    location_code: 2566,
    location_flag: "https://flagsapi.com/NG/flat/24.png",
    languagedata: language,
  },
  {
    location_name: "Norway",
    location_code: 2578,
    location_flag: "https://flagsapi.com/NO/flat/24.png",
    languagedata: language,
  },
  {
    location_name: "Oman",
    location_code: 2512,
    location_flag: "https://flagsapi.com/OM/flat/24.png",
    languagedata: language,
  },
  {
    location_name: "Pakistan",
    location_code: 2586,
    location_flag: "https://flagsapi.com/PK/flat/24.png",
    languagedata: language,
  },
  {
    location_name: "Palau",
    location_code: 2585,
    location_flag: "https://flagsapi.com/PW/flat/24.png",
    languagedata: language,
  },
  {
    location_name: "Panama",
    location_code: 2591,
    location_flag: "https://flagsapi.com/PA/flat/24.png",
    languagedata: language,
  },
  {
    location_name: "Papua New Guinea",
    location_code: 2598,
    location_flag: "https://flagsapi.com/PG/flat/24.png",
    languagedata: language,
  },
  {
    location_name: "Paraguay",
    location_code: 2600,
    location_flag: "https://flagsapi.com/PY/flat/24.png",
    languagedata: language,
  },
  {
    location_name: "Peru",
    location_code: 2604,
    location_flag: "https://flagsapi.com/PE/flat/24.png",
    languagedata: language,
  },
  {
    location_name: "Phillippines",
    location_code: 2608,
    location_flag: "https://flagsapi.com/PH/flat/24.png",
    languagedata: language,
  },
  {
    location_name: "Poland",
    location_code: 2616,
    location_flag: "https://flagsapi.com/PL/flat/24.png",
    languagedata: language,
  },
  {
    location_name: "Portugal",
    location_code: 2620,
    location_flag: "https://flagsapi.com/PT/flat/24.png",
    languagedata: language,
  },
  {
    location_name: "Qatar",
    location_code: 2634,
    location_flag: "https://flagsapi.com/QA/flat/24.png",
    languagedata: language,
  },
  {
    location_name: "Republic of the Congo",
    location_code: 2178,
    location_flag: "https://flagsapi.com/CD/flat/24.png",
    languagedata: language,
  },
  {
    location_name: "Romania",
    location_code: 2642,
    location_flag: "https://flagsapi.com/RO/flat/24.png",
    languagedata: language,
  },
  {
    location_name: "Saint Lucia",
    location_code: 2662,
    location_flag: "https://flagsapi.com/LC/flat/24.png",
    languagedata: language,
  },
  {
    location_name: "Samoa",
    location_code: 2882,
    location_flag: "https://flagsapi.com/WS/flat/24.png",
    languagedata: language,
  },
  {
    location_name: "San Marino",
    location_code: 2674,
    location_flag: "https://flagsapi.com/SM/flat/24.png",
    languagedata: language,
  },
  {
    location_name: "Saudi Arabia",
    location_code: 2682,
    location_flag: "https://flagsapi.com/SA/flat/24.png",
    languagedata: language,
  },
  {
    location_name: "Senegal",
    location_code: 2686,
    location_flag: "https://flagsapi.com/SN/flat/24.png",
    languagedata: language,
  },
  {
    location_name: "Serbia",
    location_code: 2688,
    location_flag: "https://flagsapi.com/RS/flat/24.png",
    languagedata: language,
  },
  {
    location_name: "Singapore",
    location_code: 2702,
    location_flag: "https://flagsapi.com/SG/flat/24.png",
    languagedata: language,
  },
  {
    location_name: "Slovakia",
    location_code: 2703,
    location_flag: "https://flagsapi.com/SK/flat/24.png",
    languagedata: language,
  },
  {
    location_name: "Slovenia",
    location_code: 2705,
    location_flag: "https://flagsapi.com/SI/flat/24.png",
    languagedata: language,
  },
  {
    location_name: "Solomon Islands",
    location_code: 2090,
    location_flag: "https://flagsapi.com/SB/flat/24.png",
    languagedata: language,
  },
  {
    location_name: "South Africa",
    location_code: 2710,
    location_flag: "https://flagsapi.com/ZA/flat/24.png",
    languagedata: language,
  },
  {
    location_name: "South Georgia and the South Sandwich Islands",
    location_code: 2239,
    location_flag: "https://flagsapi.com/GS/flat/24.png",
    languagedata: language,
  },
  {
    location_name: "South Korea",
    location_code: 2410,
    location_flag: "https://flagsapi.com/KR/flat/24.png",
    languagedata: language,
  },
  {
    location_name: "Spain",
    location_code: 2724,
    location_flag: "https://flagsapi.com/ES/flat/24.png",
    languagedata: language,
  },
  {
    location_name: "Sri Lanka",
    location_code: 2144,
    location_flag: "https://flagsapi.com/LK/flat/24.png",
    languagedata: language,
  },
  {
    location_name: "Sweden",
    location_code: 2752,
    location_flag: "https://flagsapi.com/SE/flat/24.png",
    languagedata: language,
  },
  {
    location_name: "Switzerland",
    location_code: 2756,
    location_flag: "https://flagsapi.com/CH/flat/24.png",
    languagedata: language,
  },
  {
    location_name: "Tajikistan",
    location_code: 2762,
    location_flag: "https://flagsapi.com/TJ/flat/24.png",
    languagedata: language,
  },
  {
    location_name: "Tanzania",
    location_code: 2834,
    location_flag: "https://flagsapi.com/TZ/flat/24.png",
    languagedata: language,
  },
  {
    location_name: "Thailand",
    location_code: 2764,
    location_flag: "https://flagsapi.com/TH/flat/24.png",
    languagedata: language,
  },
  {
    location_name: "The Bahamas",
    location_code: 2044,
    location_flag: "https://flagsapi.com/BS/flat/24.png",
    languagedata: language,
  },
  {
    location_name: "The Gambia",
    location_code: 2270,
    location_flag: "https://flagsapi.com/GM/flat/24.png",
    languagedata: language,
  },
  {
    location_name: "Tonga",
    location_code: 2776,
    location_flag: "https://flagsapi.com/TO/flat/24.png",
    languagedata: language,
  },
  {
    location_name: "Tunisia",
    location_code: 2788,
    location_flag: "https://flagsapi.com/TN/flat/24.png",
    languagedata: language,
  },
  {
    location_name: "Turkey",
    location_code: 2792,
    location_flag: "https://flagsapi.com/TR/flat/24.png",
    languagedata: language,
  },
  {
    location_name: "Turkmenistan",
    location_code: 2795,
    location_flag: "https://flagsapi.com/TM/flat/24.png",
    languagedata: language,
  },
  {
    location_name: "Tuvalu",
    location_code: 2798,
    location_flag: "https://flagsapi.com/TV/flat/24.png",
    languagedata: language,
  },
  {
    location_name: "Uganda",
    location_code: 2800,
    location_flag: "https://flagsapi.com/UG/flat/24.png",
    languagedata: language,
  },
  {
    location_name: "Ukraine",
    location_code: 2804,
    location_flag: "https://flagsapi.com/UA/flat/24.png",
    languagedata: language,
  },
  {
    location_name: "United Arab Emirates",
    location_code: 2784,
    location_flag: "https://flagsapi.com/AE/flat/24.png",
    languagedata: language,
  },
  {
    location_name: "United Kingdom",
    location_code: 2826,
    location_flag: "https://flagsapi.com/GB/flat/24.png",
    languagedata: language,
  },
  {
    location_name: "United States Monir Otlying Islands",
    location_code: 2581,
    location_flag: "https://flagsapi.com/UM/flat/24.png",
    languagedata: language,
  },
  {
    location_name: "Uruguay",
    location_code: 2858,
    location_flag: "https://flagsapi.com/UY/flat/24.png",
    languagedata: language,
  },
  {
    location_name: "Uzbekistan",
    location_code: 2860,
    location_flag: "https://flagsapi.com/UZ/flat/24.png",
    languagedata: language,
  },
  {
    location_name: "Vatican city",
    location_code: 2336,
    location_flag: "https://flagsapi.com/VA/flat/24.png",
    languagedata: language,
  },
  {
    location_name: "Venezuela",
    location_code: 2862,
    location_flag: "https://flagsapi.com/VE/flat/24.png",
    languagedata: language,
  },
  {
    location_name: "Vietnam",
    location_code: 2704,
    location_flag: "https://flagsapi.com/VN/flat/24.png",
    languagedata: language,
  },
  {
    location_name: "Yemen",
    location_code: 2887,
    location_flag: "https://flagsapi.com/YE/flat/24.png",
    languagedata: language,
  },
  {
    location_name: "Zambia",
    location_code: 2894,
    location_flag: "https://flagsapi.com/ZM/flat/24.png",
    languagedata: language,
  },
  {
    location_name: "Zimbabwe",
    location_code: 2716,
    location_flag: "https://flagsapi.com/ZW/flat/24.png",
    languagedata: language,
  },
];

export default locations;
