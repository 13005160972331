import React from "react";

export const AddNewListModal = ({
  setinputEmptyErr,
  inputEmptyErr,
  ListLimitError,
  setAlertMsg,
  alertMsg,
  addNewListModal,
  setaddNewlistModal,
  HandleListAdd,
  setListValue,
}) => {
  return (
    <>
      {addNewListModal ? (
        <div className="pop ">
          <div className="popBody">
            <h4 style={{ color: "#000000" }}>Create List</h4>
            <div className="pop-form">
              <div className="listModalHeight">
                <div className="container-fluid">
                  <div className="row">
                    <div className="col-md-12 col-12">
                      <div className="AddListBox">
                        <div className="add-pr-url">
                          <input
                            type="text"
                            autoComplete="true"
                            placeholder="LIST NAME"
                            onKeyUp={(e) => {
                              if (e.keyCode === 13) {
                                HandleListAdd();
                              }
                            }}
                            onChange={(e) => {
                              setListValue(e.target.value);
                              setinputEmptyErr(false);
                            }}
                          ></input>
                          {alertMsg && (
                            <h6
                              style={{
                                marginTop: "10px",
                                textAlign: "left",
                                fontSize: "12px",
                                color: "red",
                              }}
                            >
                              {" "}
                              list name already exist
                            </h6>
                          )}

                          {inputEmptyErr && (
                            <h6
                              style={{
                                marginTop: "10px",
                                textAlign: "left",
                                fontSize: "12px",
                                color: "red",
                              }}
                            >
                              {" "}
                              Please Type Something .
                            </h6>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="cmd mt-3 ms-2 me-2">
                <button
                  className="cm-btn-b"
                  onClick={() => {
                    setaddNewlistModal(false);
                    setAlertMsg(false);
                  }}
                >
                  Cancel
                </button>
                <button
                  className="cm-btn"
                  onClick={ListLimitError ? () => {} : HandleListAdd}
                >
                  Submit
                </button>
              </div>
              {/* </form> */}
            </div>
          </div>
        </div>
      ) : null}
    </>
  );
};
