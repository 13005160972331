import * as yup from "yup";

export const YupSchema = yup.object({
  name: yup.string().required("please Enter Your Name"),
  address: yup.string().required("please Enter Your Address"),
  email: yup.string().email().required("please Enter Your Email"),
  password: yup.string().min(8).max(25).required("Please Enter Your Password"),
  confirmPassword: yup
    .string()
    .oneOf([yup.ref("password"), null], "Passwords must match").required("Please Enter Confirm Password"),
});

export const YUPlogin = yup.object({
  email: yup.string().email().required("please Enter Your Email"),
  password: yup.string().required("Please Enter Your Password"),
});


// Forget

export const YUPForgot = yup.object({
  email: yup.string().email().required("please Enter Your Email"),
});