import React from "react";
import walkingMan from "../../Assets/seoimg/walking-animation.gif";
const LineAlert = (props) => {
  return (
    <div className="lineAlert">
      <div className=" lineAlert_Body ">
        <div>
          <i className="fa-solid fa-triangle-exclamation"></i> {props.data}{" "}
        </div>
        <div className="linealertImg">
          <img src={walkingMan} alt="notification-waliking-man-animation"></img>
          ...
        </div>
      </div>
    </div>
  );
};

export default LineAlert;
