import axios from "axios";
import React from "react";
import { Get_FeedBack } from "../../services/constants";
import { useEffect } from "react";
import { useState } from "react";
import { DottLoader } from "../../Mycomponent/share/loader";
import CustomTable from "../../Mycomponent/share/customTable";
import "../css/feedback-modal.scss";
import { styled } from "@mui/material/styles";
import Tooltip, { tooltipClasses } from "@mui/material/Tooltip";
import PopOver from "../../Mycomponent/share/components/popover.component";
import CloseIcon from "@mui/icons-material/Close";
import IconButton from "@mui/material/IconButton";
import { Box, Button } from "@mui/material";
import { CSVLink } from "react-csv";

const SimpleTooltip = styled(({ className, ...props }) => (
  <Tooltip
    {...props}
    arrow
    placement="bottom-start"
    classes={{ popper: className }}
    disableInteractive
  />
))(({ theme }) => ({
  [`& .${tooltipClasses.arrow}`]: {
    color: "black",
    "&::before": {
      backgroundColor: "#365486",
    },
  },
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: "#365486",
    color: "white",
    maxWidth: 400,
    textAlign: "start",
    margin: 8,
    fontSize: theme.typography.pxToRem(14),
  },
}));

const FeedBack = () => {
  const [feedBackData, setFeedBackData] = useState([]);
  const [feedBack, setFeedBack] = useState(false);
  const [feedbackmsg, setFeedbaclMsg] = useState("");
  const [loading, setLoading] = useState(true);
  const [page, setPage] = React.useState(1);

  const fatch_Feedback = () => {
    axios.get(Get_FeedBack()).then((res) => {
      setFeedBackData(res.data);
      setLoading(false);
    });
  };

  useEffect(() => {
    fatch_Feedback();
  }, []);

  const feedBackHandler = (e) => {
    setFeedBack(true);
    setFeedbaclMsg(e);
  };

  const tableHeader = [
    {
      label: "EMAIL",
      key: "email",
      minWidth: 150,
      align: "left",
      width: "25%",
    },
    {
      label: "URL",
      key: "url",
      minWidth: 150,
      width: "25%",
      align: "left",
    },
    {
      label: "FEEDBACK",
      key: "feedback",
      minWidth: 150,
      width: "50%",
      align: "center",
    },
  ];

  function createData(email, url, feedback, action) {
    return {
      email,
      url,
      feedback,
      action,
    };
  }

  const rows = feedBackData?.map((item) => {
    return createData(
      item?.email,
      item?.url,
      <SimpleTooltip title={item?.feedback} className="tooltip-feedback-des">
        {item?.feedback}
      </SimpleTooltip>,
      () => feedBackHandler(item?.feedback)
    );
  });

  const loadCSVData = () => {
    if (feedBackData?.length === 0) return [];
    return feedBackData?.map((item) => {
      const obj = {
        email: item?.email,
        url: item?.url,
        feedback: item?.feedback,
      };
      return obj;
    });
  };

  if (loading) {
    return (
      <div>
        <h2 className="table_title heading-large font-bold mb-4">Feedback</h2>
        <DottLoader />
      </div>
    );
  }

  return (
    <>
      <CustomTable
        showSearchBar={true}
        tableTitle="Feedback"
        downloadCSVFile={
          <CSVLink data={loadCSVData()} filename={"feedback.csv"}>
            <Button className="btn_1 ms-2">Download CSV</Button>
          </CSVLink>
        }
        tableHeader={tableHeader}
        rows={rows}
        page={page}
        setPage={setPage}
      />
      {feedBack && (
        <>
          <PopOver anchorEl={true}>
            <div className="modal-feedback">
              <h1 className="table_title heading-xlarge font-bold mb-4 feedback-modal">
                FEEDBACK
                <IconButton
                  className="feedback-close-icon"
                  onClick={() => setFeedBack(false)}
                  sx={{
                    "&:hover": {
                      backgroundColor: "rgba(240, 242, 255, 1)",
                    },
                  }}
                >
                  <CloseIcon sx={{ color: "black" }} fontSize="large" />
                </IconButton>
              </h1>
              <div class="quote mb-4">
                <span class="left">❝</span>
                <blockquote className="heading-large">{feedbackmsg}</blockquote>
                <span class="right">❞</span>
              </div>
            </div>
          </PopOver>
        </>
      )}
    </>
  );
};

export default FeedBack;
