import React from "react";
import "../css/topbar.css";
import userImg from "../../Mycomponent/Assets/seoimg/userImg.png";
import { Logout } from "../../Mycomponent/share/upDater/constant";
import { useDispatch, useSelector } from "react-redux";
import logo from "../assets/logo.png";
import { Link } from "react-router-dom";
import CloseIcon from "@mui/icons-material/Close";
import MenuIcon from "@mui/icons-material/Menu";
import { useNavigate } from "react-router-dom";

const TopBar = () => {
  const dispatch = useDispatch();
  const name = useSelector((state) => state.name);
  const email = useSelector((state) => state.email);
  const show = useSelector((state) => state.showmenu);
  const navigate = useNavigate();

  const handleGotoApp = () => {
    navigate("/dashboard");
  };

  return (
    <div className="Top-bar">
      <div className="cmd h-100">
        {/* <div> */}
        <Link to="/dashboard">
          <img
            src={logo}
            alt="RanksPro.io Logo"
            className="h-100 topbar_logo"
            style={{ width: "148px", objectFit: "contain" }}
          />{" "}
        </Link>
        {/* </div> */}
        <div className="top-bar_menu">
          {show ? (
            <CloseIcon
              fontSize="large"
              onClick={() => dispatch({ type: "SHOWMENU", payload: false })}
            />
          ) : (
            <MenuIcon
              fontSize="large"
              onClick={() => dispatch({ type: "SHOWMENU", payload: true })}
            />
          )}
        </div>

        <div className="admin_NameBar">
          <div className="userLogo">
            <img src={userImg} alt={`${name}-profile-image`} />
          </div>
          <div>
            <h6 className="heading-small font-light-bold">{name}</h6>
            <p className="heading-small"> {email}</p>
          </div>
          <div>
            <i className="fa-solid fa-caret-down"></i>
          </div>
          <div className="adminName_drop">
            <ul>
              <li onClick={() => handleGotoApp()} className="heading-small ">
                App Dashboard
              </li>
              <li onClick={Logout} className="heading-small">
                Sign out
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  );
};

export default TopBar;
