export const affiliate = "/affiliate";
export const performance_metrics = "performance-metrics";
export const affiliate_notification = "message-and-notification";
export const payment_earnings = "payment-and-earnings";
export const promotional = "promotional-material";
export const dashboard = "dashboard";
export const LOGIN = "/affiliate/login";
export const FORGOT = "/affiliate/forgot";
export const SIGNUP = "/affiliate/signup";
// For normal
export const VERIFY = "verify";
export const SIGNUPREFER =
  "http://localhost:3000/affiliate/user/register/?aff_id=:id"; // For Refer
export const PROFILE = "profile";

// FORGOT
export const POST_OTP_FORGOT = (email) =>
  `https://app.rankspro.io/api/user/affforgetpassword/?email=${email}`;

export const POST_NEW_PASS = (email, password) =>
  `https://app.rankspro.io/api/user/affpasswordreset/?email=${email}&password=${password}`;

// DIGITAL AGENCY
export const DIGITAL_AGENCY_GET = () =>
  `https://app.rankspro.io/api/user/digitalget/`;

export const DIGITAL_UPDATE_STATUS = (id) => `
  https://app.rankspro.io/api/user/digitalupdate/${id}/`;

export const DIGITAL_AGENCY_DELETE = (id) =>
  `https://app.rankspro.io/api/user/digitaldelete/${id}/`;

export const DIGITAL_AGENCY_GET_SINGLE = (id) => `
 https://app.rankspro.io/api/user/digiagenciessingleget/?name=${id}`;
