import axios from "axios";

const openaiApiKey = "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";

const openaiService = axios.create({
  baseURL: "https://api.openai.com/v1",
  headers: {
    "Content-Type": "application/json",
    Authorization: `Bearer ${openaiApiKey}`,
  },
});

export const generateText = async (prompt) => {
  const messages = [
    { role: "user", content: prompt.replace(/\s+/g, " ").trim() },
  ];
  try {
    const response = await openaiService.post("/chat/completions", {
      model: "gpt-3.5-turbo",
      messages: messages,
      temperature: 1,
      max_tokens: 300,
      top_p: 1,
      frequency_penalty: 0,
      presence_penalty: 0,
    });

    return response.data?.choices?.[0]?.message?.content;
  } catch (error) {
    if (axios.isCancel(error)) {
      console.log("Request canceled:", error.message);
    } else {
      console.error("Error generating text:", error);
    }
    throw error;
  }
};
