import React from "react";
import { MdOutlineArrowForwardIos, MdOutlineArrowBackIos } from "react-icons/md";

const ArrowPagination = ({ page, onPageChange, lastPage }) => {
  function generatePagination(currentPage, totalPages, numbersToShow) {
    let startPage = Math.max(1, currentPage - Math.floor(numbersToShow / 2));
    let endPage = Math.min(totalPages, startPage + numbersToShow - 1);

    if (endPage - startPage + 1 < numbersToShow) {
      startPage = Math.max(1, endPage - numbersToShow + 1);
    }

    let paginationArray = [];
    for (let i = startPage; i <= endPage; i++) {
      paginationArray.push(i);
    }

    return paginationArray;
  }
  const numbersToShow = 7;
  const pagination = generatePagination(page, lastPage, numbersToShow);

  const previousPageUpdate = () => {
    if (page > 1) onPageChange(page - 1);
  };
  const nextPageUpdate = () => {
    if (lastPage > page) onPageChange(page + 1);
  };
  return (
    <nav aria-label="page-navigation mb-2">
      <ul className="pagination justify-content-center pagination-sm flex-wrap">
        <li
          className={page === 1 ? "page-item disabled" : "page-item"}
          onClick={previousPageUpdate}
        >
          <MdOutlineArrowBackIos style={{fontSize: "25px"}}/>
        </li>
        <li
          className={page === lastPage ? "page-item disabled" : "page-item"}
          onClick={() => nextPageUpdate()}
        >
          <MdOutlineArrowForwardIos style={{fontSize: "25px"}}/>
        </li>
      </ul>
    </nav>
  );
};

export default ArrowPagination;
