import React from "react";
import Avatar from "@mui/material/Avatar";

const Avtar = ({ user_name }) => {
  function stringToColor(string) {
    let hash = 0;
    let i;

    /* eslint-disable no-bitwise */
    for (i = 0; i < string.length; i += 1) {
      hash = string.charCodeAt(i) + ((hash << 5) - hash);
    }

    let color = "#";

    for (i = 0; i < 3; i += 1) {
      const value = (hash >> (i * 8)) & 0xff;
      color += `00${value.toString(16)}`.slice(-2);
    }
    /* eslint-enable no-bitwise */

    return color;
  }

  function getInitials(name) {
    // Check if the name is provided
    if (!name || typeof name !== "string") {
      return ""; // Return empty string if name is not provided or not a string
    }

    // Split the name into an array of words
    const words = name.trim().split(/\s+/);

    // Initialize a variable to store the initials
    let initials = "";

    // Iterate through each word to extract the first character
    for (const word of words) {
      // Append the first character of each word to the initials string
      initials += word.charAt(0);
    }

    // Return the initials in uppercase
    return initials.toUpperCase();
  }

  function stringAvatar(name) {
    return {
      sx: {
        bgcolor: stringToColor(name),
      },
      children: getInitials(name),
    };
  }

  return (
    <Avatar
      {...stringAvatar(user_name)}
      sx={{ width: "100%", height: "100%" }}
    />
  );
};

export default Avtar;
