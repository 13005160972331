export const noOfEmployeeOptions = ["0-50", "51-200", "200+"];
export const serviceOptions = [
  "SEO",
  "Content Marketing",
  "Email Marketing",
  "Web Design",
  "Digital Agencies",
];
export const industryOptions = ["IT", "Logistics", "Ecommerce"];
export const geographicFocusOptions = [
  "India",
  "Australia",
  "Canada",
  "France",
  "United States",
  "Germany",
  "Spain",
  "United Kingdom",
];
export const languageOptions = ["English", "Hindi"];
export const budgetOptions = ["$1000+", "$2500+", "Any"];
export const priceModelOptions = [
  "Hourly Rate",
  "Retainer",
  "Project Based",
  "Commission",
];
export const clientBussinessSizeOptions = [
  "Small Business",
  "Mid Business",
  "Enterprise",
];

export const agencyCategoryOptions = [
  "Advertising",
  "Content Marketing",
  "Email Marketing",
  "Lead Generation",
  "Local SEO",
  "Market Research",
  "Pay Per Click Advertising",
  "Video Production",
  "Public Relations",
  "Social Media Marketing",
  "SEO",
  "UX Design",
  "Web Design",
  "Web Development",
];
