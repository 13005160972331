import { useState, useEffect } from "react";
import axios from "axios";

const useIpAddress = () => {
  const [ipAddress, setIpAddress] = useState("No IpAddress");

  useEffect(() => {
    const getIpAddress = async () => {
      try {
        const response = await axios.get("https://api.ipify.org");
        return response.data;
      } catch (error) {
        return null;
      }
    };

    getIpAddress().then((ip) => {
      if (ip) {
        setIpAddress(ip);
      } else {
        setIpAddress("No IP Address");
      }
    });
  }, []);

  return ipAddress;
};

export default useIpAddress;
