import React from "react";
import { styled } from "@mui/material/styles";
import Button from "@mui/material/Button";

function CustomButtonStyle({
  name,
  color,
  bgColor,
  hover,
  textStyle,
  startIcon,
  endIcon,
  className,
  variant,
  hoverColor,
  borderColor,
}) {
  const ButtonStyle = styled(Button)(() => ({
    color: color || "white",
    fontSize: "1em",
    backgroundColor: bgColor || "#365486",
    border: `1px solid ${borderColor}`,
    textTransform: textStyle || "none",
    "&:hover": {
      backgroundColor: hover || "#4b579be8",
      color: hoverColor || "white",
    },
  }));

  return (
    <ButtonStyle
      variant={"contained"}
      className={`${className} font-regular heading-small`}
    >
      {startIcon && startIcon}
      {name}
      {endIcon && endIcon}
    </ButtonStyle>
  );
}
export default CustomButtonStyle;
