import React, { useState, useEffect, useRef } from "react";
import { ReactComponent as DropArrow } from "../../Assets/common/droparrow.svg";

const Dropdown = (props) => {
  const [clickDrop, setClickDrop] = useState(false);
  const [searched, setSearched] = useState([]);
  const [inputFocused, setInputFocused] = useState(false); // Track input focus
  const dropdownRef = useRef(null);
  const inputRef = useRef(null);

  const handleClick = (res) => {
    props.callback(res);
    setClickDrop(false);
    setSearched([]);
  };

  const searchCountry = (text) => {
    if (!text) {
      setSearched([]);
    } else {
      let matches = props.data.filter((prname) => {
        const regex = new RegExp(`${text}`, "gi");
        return prname.language
          ? prname.language.match(regex)
          : prname.location_name
          ? prname.location_name.match(regex) ||
            prname.location_name.match(regex)
          : prname.match(regex);
      });
      setSearched(matches);
    }
  };

  const handleClickOutside = (event) => {
    if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
      setClickDrop(false);
    }
  };

  useEffect(() => {
    window.addEventListener("click", handleClickOutside);

    return () => {
      window.removeEventListener("click", handleClickOutside);
    };
  }, []);

  useEffect(() => {
    if (inputFocused && inputRef.current) {
      inputRef.current.focus();
    }
  }, [inputFocused]);

  return (
    <>
      <div
        className={props.largeIcon ? "dropdown-icon prLocation" : "prLocation"}
        style={props.style}
        ref={dropdownRef}
      >
        <div
          style={{
            overflow: "hidden",
            display: "flex",
            alignItems: "center",
            width: "100%",
          }}
          onClick={() => {
            setInputFocused(true); // Focus the input on click
            setClickDrop(!clickDrop);
          }}
        >
          {props.img && (
            <img
              className="me-2"
              src={props.img}
              alt="selected-icon"
              style={{ width: "20px", objectFit: "contain" }}
            />
          )}
          <p
            className="heading-small"
            style={{ fontSize: props.style.fontSize }}
          >
            {props.title}
          </p>
        </div>

        {props.largeIcon ? null : (
          <div
            style={{ background: "transparent" }}
            onClick={() => {
              setInputFocused(true); // Focus the input on click
              setClickDrop(!clickDrop);
            }}
          >
            <DropArrow
              style={{
                width: "22px",
                height: "22px",
                color: "#000000",
                transform: clickDrop ? "rotateX(180deg)" : "rotateX(0deg)",
              }}
            />
          </div>
        )}

        {clickDrop && props.data?.length > 0 ? (
          <div
            className="prloc_drop"
            style={{
              borderRadius: props?.style?.borderRadius || "5px",
            }}
          >
            <div className="prloc_drop_scroll">
              <ul style={{ position: "relative" }}>
                {props?.searchType ? null : (
                  <li
                    className="p-0 mb-2"
                    style={{
                      top: "0",
                      position: "sticky",
                      background: "white !important",
                    }}
                  >
                    <div className="">
                      <div>
                        <input
                          type="search"
                          ref={inputRef}
                          className="focus-on-click heading-small"
                          onFocus={() => setInputFocused(true)}
                          onBlur={() => setInputFocused(false)}
                          onChange={(e) => searchCountry(e.target.value)}
                          placeholder="search here.."
                        ></input>
                      </div>
                    </div>
                  </li>
                )}
                {searched.length === 0
                  ? props.data &&
                    props.data?.map((res, index) => {
                      const title =
                        res?.meta_des && res?.title
                          ? res?.day === 0
                            ? res.meta_des + " " + res.title
                            : "Last " + res?.meta_des + " " + res?.title
                          : res.language || res.location_name || res;
                      return (
                        <li
                          key={`dropdown-option-${index}`}
                          onClick={() => handleClick(res)}
                          className={
                            title === props.selectedValue
                              ? "prloc_drop-selected"
                              : ""
                          }
                        >
                          {res?.location_flag && (
                            <img
                              className="me-2"
                              src={res.location_flag}
                              alt={title}
                              style={{ width: "20px", objectFit: "contain" }}
                            />
                          )}
                          {title}
                        </li>
                      );
                    })
                  : searched.map((res, index) => {
                      const title =
                        res.meta_des && res.title
                          ? res?.day === 0
                            ? res.meta_des + " " + res.title
                            : "Last " + res.meta_des + " " + res.title
                          : res.language || res.location_name || res;
                      return (
                        <li
                          key={`search-dropdown-option-${index}`}
                          onClick={() => handleClick(res)}
                          className={
                            title === props.selectedValue
                              ? "prloc_drop-selected"
                              : ""
                          }
                        >
                          {res.location_flag && (
                            <img
                              className="me-2"
                              src={res.location_flag}
                              alt={title}
                              style={{ width: "20px", objectFit: "contain" }}
                            />
                          )}
                          {title}
                        </li>
                      );
                    })}
              </ul>
            </div>
          </div>
        ) : null}
      </div>
      {props.largeIcon ? (
        <div
          className="d-flex justify-content-center align-items-center"
          style={{
            background: "rgb(69, 81, 151)",
            width: "60px",
          }}
          onMouseLeave={() => setClickDrop(false)}
          onClick={() => setClickDrop(!clickDrop)}
        >
          <i
            className="fa-solid fa-angle-down cmc"
            style={{ fontSize: "30px", color: "white" }}
          ></i>
        </div>
      ) : null}
    </>
  );
};

export default Dropdown;
