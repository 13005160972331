import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import logo from "../Assets/seoimg/logo.png";
import "../css/header.css";
import RippleButton from "./components/rippleButton";
import { Logout } from "./upDater/constant";
import { useDispatch, useSelector } from "react-redux";
import FeedBackOverlay from "./components/feedBackOverlay";
import CustomButtonStyle from "./components/button.component";
import PopOver from "./components/popover.component";
import CloseIcon from "@mui/icons-material/Close";
import MenuIcon from "@mui/icons-material/Menu";
import profileSvg from "../../admin/assets/sidebar/profile.svg";
import HelpOutlineIcon from "@mui/icons-material/HelpOutline";

import { USER_SMTP_ADD, USER_SMTP_CHECK } from "../../services/constants";
import axios from "axios";
import { TextField } from "@mui/material";

const Header = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const show = useSelector((state) => state.showmenu);
  const loginOut = useSelector((state) => state.loginout);
  const user_type = localStorage.getItem("user_type");
  const [feedBack, setFeedBack] = useState(false);

  const EMAIL = useSelector((state) => state?.email);
  const [anchorEl, setAnchorEl] = useState(false);
  const [SMTPEmail, setSMTPEmail] = useState("");
  const [SMTPPassword, setSMTPPassword] = useState("");
  const [error, setError] = useState("");

  const ProType = () => {
    function checkIfAdmin(email) {
      const isAdmin = ["info@esearchlogix.com", "singhashish1.sh@gmail.com"];
      return isAdmin.includes(email);
    }

    const isAdmin = localStorage.getItem("isAdmin");
    if (loginOut === true) {
      return (
        <>
          <div className="nv-select">
            <div className="nv-select_con ">
              <ul>
                <li onClick={Profile} className="heading-small font-regular">
                  {" "}
                  Account
                </li>

                {isAdmin === "true" && checkIfAdmin(EMAIL) ? (
                  <Link to="/admin">
                    <li className="heading-small font-regular">Admin</li>
                  </Link>
                ) : null}

                <Link
                  to="upgrade"
                  style={{
                    display: user_type === "organization" ? "none" : "",
                  }}
                >
                  <li className="flex-md heading-small font-regular">
                    Upgrade
                  </li>
                </Link>
                <li onClick={Logout} className="heading-small font-regular">
                  Logout
                </li>
              </ul>
            </div>
            <div className="us-logo">
              <img src={profileSvg} alt="profileSvg" />
            </div>
          </div>
        </>
      );
    } else {
      return (
        <>
          <Link to="login">
            <RippleButton>Sign in</RippleButton>
          </Link>
        </>
      );
    }
  };

  const feedback_callback = (value) => {
    setFeedBack(value);
  };

  const Profile = () => {
    navigate("profile");
  };

  const handleContactSourcer = (url, email) => {
    dispatch({
      type: "SMTPUSER",
      payload: { url, email },
    });

    axios.get(USER_SMTP_CHECK(EMAIL)).then((response) => {
      if (response?.data?.length > 0) {
        navigate("/backlink/contact");
      } else {
        setAnchorEl(true);
      }
    });
  };

  const submitSmtpPassword = () => {
    if (SMTPEmail === "") {
      setError("Please enter details");
      return;
    } else if (SMTPPassword === "") {
      setError("Please enter details");
      return;
    } else {
      axios
        .post(USER_SMTP_ADD(), {
          email: EMAIL,
          send_email: SMTPEmail,
          smtp: SMTPPassword,
        })
        .then((response) => {
          setAnchorEl(false);
          navigate("/backlink/contact");
        })
        .catch((error) => {
          setAnchorEl(false);
        });
    }
  };

  return (
    <>
      <div className="navBar">
        <div className="d-flex w-100 h-100">
          <div className="top-bar-logo">
            <img src={logo} alt="RanksPro.io" />
          </div>
          <ul className="nv-ul ms-auto">
            {/* <li
              className="me-4"
              onClick={() => handleContactSourcer(null, null)}
            >
              <CustomButtonStyle
                color="#FFFFFFE5"
                borderColor="#365486"
                name="Outreach"
                bgColor="#365486"
                hover="#365486"
                variant="contained"
                hoverColor="white"
                className="font-regular heading-small CustomUpgrade"
              />
            </li> */}
            <li className="me-1 me-md-4">
              <Link to={"https://help.rankspro.io/"} target="_blank">
                <HelpOutlineIcon fontSize="medium" />
              </Link>
            </li>
            <Link
              to="upgrade"
              style={{ display: user_type === "organization" ? "none" : "" }}
              className="header-upgrade"
            >
              <li className="flex-none-sm">
                <CustomButtonStyle
                  color="#FFFFFFE5"
                  borderColor="#365486"
                  name="Upgrade"
                  bgColor="#365486"
                  hover="#365486"
                  variant="contained"
                  hoverColor="white"
                  className="font-regular heading-small CustomUpgrade"
                />
              </li>
            </Link>
            <li>
              <ProType />
            </li>
            <li>
              <div className="top-bar_menu">
                {show ? (
                  <CloseIcon
                    fontSize="large"
                    onClick={() =>
                      dispatch({ type: "SHOWMENU", payload: false })
                    }
                  />
                ) : (
                  <MenuIcon
                    fontSize="large"
                    onClick={() =>
                      dispatch({ type: "SHOWMENU", payload: true })
                    }
                  />
                )}
              </div>
            </li>
          </ul>
        </div>
      </div>
      <PopOver anchorEl={feedBack}>
        <FeedBackOverlay toggleOverlay={feedback_callback} />
      </PopOver>

      <PopOver anchorEl={anchorEl}>
        <div className="backlink-modal">
          <h1 className="heading-medium font-bold">Enter SMTP Info</h1>
          <TextField
            fullWidth
            id="outlined-basic"
            label="Email"
            variant="outlined"
            className="mt-3"
            value={SMTPEmail}
            onChange={(e) => setSMTPEmail(e.target.value)}
          />
          <TextField
            fullWidth
            id="outlined-basic"
            label="Password"
            className="mt-3"
            variant="outlined"
            value={SMTPPassword}
            onChange={(e) => setSMTPPassword(e.target.value)}
          />
          {error && <span className="msg-error mt-1">{error}</span>}
          <div className="backlink-modal-btn cmd mt-4">
            <button
              className="btn_2"
              style={{ height: "45px", width: "90px" }}
              onClick={() => {
                setError("");
                setAnchorEl(false);
              }}
            >
              Cancel
            </button>
            <button
              className="btn_1"
              style={{ padding: "0.5rem 0.75rem !important" }}
              onClick={() => submitSmtpPassword()}
            >
              Submit
            </button>
          </div>
        </div>
      </PopOver>
    </>
  );
};

export default Header;
