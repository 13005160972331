import React from "react";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
import { Line } from "react-chartjs-2";
import { formatNumberWithSymbols } from "../../../helperJs/helper";

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend
);
const TrafficChart = (props) => {
  const etv = [...props.etv].reverse();
  const etvlabel = [...props.etvlabel].reverse();
  const etvAllData = [...props.etvAllData].reverse();

  const options = {
    maintainAspectRatio: false,
    responsive: true,
    interaction: {
      mode: "index",
      intersect: false,
    },
    stacked: false,
    plugins: {
      legend: {
        position: "top",
        display: false,
      },
      title: {
        display: true,
        title: { display: false, text: "Chart.js Line Chart" },
      },
      tooltip: {
        callbacks: {
          label: function (context) {
            let label = context.dataset.label || "";
            if (label) {
              label += ": ";
            }
            if (context.parsed.y !== null) {
              label += formatNumberWithSymbols(
                context.parsed.y?.toFixed(0)
              );
            }
            return label;
          },
        },
        displayColors: false,
        boxPadding: 5,
        cornerRadius: 10,
        caretSize: 10,
        padding: 10,
        margin: 10,
        boxHeight: 18,
        borderWidth: 4,
        boxWidth: 4,
        titleColor: "#FFFFFF",
        bodyColor: "#FFFFFF",
        bodyFont: {
          size: 14,
          weight: 500,
          font: "Lato",
        },
        bodySpacing: 10,
        usePointStyle: true,
        backgroundColor: "#365486CC",
        titleFont: {
          size: 14,
          weight: 600,
          font: "Lato",
        },
      },
    },
    scales: {
      y: {
        type: "linear",
        display: true,
        position: "left",
        reverse: false,
        ticks: {
          min: 0,
          max: 5,
          maxTicksLimit: 7,
          stepSize: 4000,
          beginAtZero: true,
          callback: function (value) {
            const number = formatNumberWithSymbols(value);
            return number;
          },
        },
        grid: {
          drawOnChartArea: false,
        },
        title: {
          display: true,
          text: "Organic",
          color: "#3BA1C5",
          font: {
            size: 14,
            weight: 600,
            family: "Lato",
          },
        },
      },
      x: {
        grid: {
          display: false, // This removes the grid lines
          drawBorder: false, // This removes the border line
        },
      },
      y1: {
        type: "linear",
        display: true,
        reverse: false,
        position: "right",
        ticks: {
          min: 0,
          max: 5,
          maxTicksLimit: 7,
          stepSize: 4000,
          beginAtZero: true,
          fontColor: "purple",
          callback: function (value) {
            const number = formatNumberWithSymbols(value);
            return number;
          },
        },
        grid: {
          drawOnChartArea: false,
        },
        title: {
          display: true,
          text: "Paid",
          color: "#E95655",
          font: {
            size: 14,
            weight: 600,
            family: "Lato",
          },
        },
      },

      x: {
        ticks: {
          display: false,
        },
        grid: {
          display: false,
        },
      },
    },
    cubicInterpolationMode: "monotone",
  };

  const labels = etvlabel;

  if (document.getElementById("traffic-chart") !== null) {
    var ctx = document.getElementById("traffic-chart").getContext("2d");
    var gradient1 = ctx.createLinearGradient(0, 0, 0, 300);
    var gradient = ctx.createLinearGradient(0, 0, 0, 300);
    gradient1.addColorStop(1, "rgb(53, 162, 235,0.2)");
    gradient1.addColorStop(0, "rgb(53, 162, 235,0)");
    gradient.addColorStop(1, "rgba(255, 99, 132,0.2)");
    gradient.addColorStop(0, "rgba(255, 99, 132,0)");
  }

  const data = {
    labels,
    datasets: [
      {
        label: "Organic",
        data: etv,
        borderColor: "#3BA1C5",
        backgroundColor: "rgba(59, 161, 197, .4)",
        tension: 0.1,
        borderWidth: 1,
        fill: "start",
        borderWidth: 4,
        yAxisID: "y",
        pointRadius: 0,
        pointHoverRadius: 6,
        pointBackgroundColor: "#3BA1C5",
      },
      {
        label: "Paid",
        data: etvAllData,
        borderColor: "#E95655",
        backgroundColor: "rgba(233, 86, 85, .4)",
        borderDash: [10, 5],
        fill: "start",
        tension: 0.1,
        borderWidth: 4,
        fill: "start",
        yAxisID: "y1",
        pointRadius: 0,
        pointHoverRadius: 6,
        pointBackgroundColor: "#E95655",
      },
    ],
  };

  return (
    <div className="TRAFFIC_CHART">
      <Line options={options} data={data} id="traffic-chart" />
    </div>
  );
};

export default React.memo(TrafficChart);
