import { useEffect } from "react";
import axios from "axios";
import { ZustandStore } from "../store/zustand-store";

const CountryList = () => {
  const { country_Data } = ZustandStore((state) => state);

  useEffect(() => {
    const fetchData = async () => {
      try {
        let config = {
          method: "get",
          maxBodyLength: Infinity,
          url: "https://api.dataforseo.com/v3/serp/google/locations",
          headers: {
            Authorization:
              "Basic aW5mb0Blc2VhcmNobG9naXguY29tOmZmOWZiMjY4NDZhMTYwZGI=",
          },
        };

        const response = await axios.request(config);

        const data = response?.data?.tasks?.[0]?.result
          ?.filter((location) => {
            return (
              location.location_type === "State" ||
              location.location_type === "City" ||
              location.location_type === "Country"
            );
          })
          .flat();

        ZustandStore.setState({
          country_Data: {
            data: data,
            error: false,
            loading: false,
          },
        });
      } catch (error) {
        ZustandStore.setState({
          country_Data: {
            data: [],
            error: true,
            loading: false,
          },
        });
      }
    };

    if (country_Data?.data?.length === 0 && country_Data?.loading) {
      fetchData();
    }
  }, [country_Data]);
  return null;
};

export default CountryList;
