export const REGISTER = `https://app.rankspro.io/api/user/affiliateregister/`;
export const AFFILIATE = (email) =>
  `https://app.rankspro.io/api/user/affsave/?email=${email}`;

// LOGIN

export const LOGGED = (config) =>
  `https://app.rankspro.io/api/user/affiliatelogin/?email=${config.email}&password=${config.password}`;

export const POST_OTP = () => `https://app.rankspro.io/api/user/affotp/`;

//  PROFILE
export const GET_PROFILE = (email) =>
  `https://app.rankspro.io/api/user/affiliateprofile/?email=${email}`;
export const GET_PROFILE1 = (email) =>
  ` https://app.rankspro.io/api/user/affapproveget/?email=${email}`;

export const GET_CONVERSION = (email) =>
  `https://app.rankspro.io/api/user/affapproveget/?email=${email}`;

export const GET_AFF_CONVERSION = (aff_id) =>
  `https://app.rankspro.io/api/user/affidapproveget/?aff_id=${aff_id}`;

export const GET_AFFILIATE_REWARD = () =>
  `https://app.rankspro.io/api/user/affrewardget/`;

export const AFF_NEWUSER_REGISTER = () =>
  `https://app.rankspro.io/api/user/affnewuserregister/`;

// raise withdrawal

export const GET_USER_WITHRA = (email) =>
  `https://app.rankspro.io/api/user/userwithrawget/?email=${email}`;

export const POST_WITHRA_REQ = () =>
  `https://app.rankspro.io/api/user/withdrawreq/`;

  
