import React from "react";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
import { Bar } from "react-chartjs-2";
import { formatNumberWithSymbols } from "../../../helperJs/helper";

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
);

function BarChart({ data, option }) {
  let delayed;
  const options = {
    maintainAspectRatio: false,
    plugins: {
      title: {
        display: false,
      },
      legend: {
        display: false,
      },
      animation: {
        onComplete: () => {
          delayed = true;
        },
        delay: (context) => {
          let delay = 0;
          if (
            context.type === "data" &&
            context.mode === "default" &&
            !delayed
          ) {
            delay = context.dataIndex * 300 + context.datasetIndex * 100;
          }
          return delay;
        },
      },
      tooltip: {
        callbacks: {
          label: function (context) {
            let label = context.dataset.label || "";

            if (label) {
              label += ": ";
            }
            if (context.parsed.y !== null) {
              label += formatNumberWithSymbols(context.parsed.y?.toFixed(0));
            }
            return label;
          },
        },
        boxPadding: 5,
        cornerRadius: 10,
        caretSize: 10,
        padding: 10,
        margin: 10,
        boxHeight: 30,
        borderWidth: 4,
        boxWidth: 4,
        titleColor: "black",
        bodyColor: "black",
        bodyFont: {
          size: 14,
          weight: 500,
          font: "Lato",
        },
        bodySpacing: 10,
        usePointStyle: true,
        backgroundColor: "#F0F0F0",
        titleFont: {
          weight: 600,
          size: 14,
          font: "Lato",
        },
      },
    },
    responsive: true,
    scales: {
      x: {
        stacked: false,
        color: "black",
        ticks: {
          display: true,
          size: 16,
          weight: 500,
        },
        grid: {
          display: false,
        },
      },
      y: {
        grid: {
          display: true,
        },
        ticks: {
          callback: function (value) {
            const number = formatNumberWithSymbols(value);
            return number;
          },
        },
        border: {
          display: true,
          width: 2,
          // color: 'red',
        },
        stacked: true,
      },
    },
  };

  const labels = data?.labels;

  const bar_data = {
    labels,
    datasets: [
      {
        label: data.dataSet[0]?.label,
        data: data.dataSet[0].data,
        backgroundColor: "#365486",
      },
      {
        label: data.dataSet[1]?.label,
        data: data.dataSet[1].data,
        backgroundColor: "#E95655",
      },
    ],
  };

  return (
    <Bar
      options={option || options}
      data={bar_data}
      style={{ width: "100% !important", height: "100% !important" }}
    />
  );
}
export default BarChart;
