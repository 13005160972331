import React, { useState, useEffect, useRef } from "react";
import { ReactComponent as DropArrow } from "../../../Assets/common/droparrow.svg";

const TagDropdown = (props) => {
  const inputRef = useRef(null);
  const dropdownRef = useRef(null);
  const [clickDrop, setClickDrop] = useState(false);
  const [inputFocused, setInputFocused] = useState(false); // Track input focus
  const { callback, style, img, title, data, selectedTag } = props;
  const uniqueTagNames = [...new Set(data.map((item) => item.tag_name))];

  const handleClick = (res) => {
    callback(res);
  };

  const handleClickOutside = (event) => {
    if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
      setClickDrop(false);
    }
  };

  useEffect(() => {
    window.addEventListener("click", handleClickOutside);

    return () => {
      window.removeEventListener("click", handleClickOutside);
    };
  }, []);

  useEffect(() => {
    if (inputFocused && inputRef.current) {
      inputRef.current.focus();
    }
  }, [inputFocused]);

  return (
    <>
      <div className={"prLocation"} style={style} ref={dropdownRef}>
        <div
          style={{
            overflow: "hidden",
            display: "flex",
            alignItems: "center",
            width: "100%",
          }}
          onClick={() => {
            setInputFocused(true); // Focus the input on click
            setClickDrop(!clickDrop);
          }}
        >
          {img && (
            <img
              className="me-2"
              src={img}
              alt="selected-icon"
              style={{ width: "20px", objectFit: "contain" }}
            />
          )}
          <p className="heading-small" style={{ fontSize: style.fontSize }}>
            {title}
          </p>
        </div>

        {
          <div
            style={{ background: "transparent" }}
            onClick={() => {
              setInputFocused(true); // Focus the input on click
              setClickDrop(!clickDrop);
            }}
          >
            <DropArrow
              style={{
                width: "22px",
                height: "22px",
                color: "#000000",
                transform: clickDrop ? "rotateX(180deg)" : "rotateX(0deg)",
              }}
            />
          </div>
        }

        {clickDrop && (
          <div
            className="prloc_drop prloc_drop_scroll"
            style={{
              borderRadius: style?.borderRadius || "5px",
              padding: ".8rem",
            }}
          >
            <ul style={{ position: "relative" }}>
              {uniqueTagNames?.length === 0 ? (
                <li>
                  <span className="">No Tag name</span>
                </li>
              ) : (
                uniqueTagNames?.map((tag_name, index) => (
                  <li
                    key={`Dropdown-option-${index}`}
                    onClick={() => handleClick(tag_name)}
                    className={
                      selectedTag?.includes(tag_name)
                        ? "selected-tag tag-dropdown-list-item"
                        : "tag-dropdown-list-item"
                    }
                  >
                    <span className="">{tag_name}</span>
                  </li>
                ))
              )}
            </ul>
          </div>
        )}
      </div>
    </>
  );
};

export default TagDropdown;
