import { create } from "zustand";

export const ZustandStore = create((set) => ({
  user_profile: [],
  selected_domain: "",
  site_overview_data: [],
  backlink_data_hm: [],
  domain_auth_hm: [],
  site_audit_hm: [],
  plan_data_hm: [],
  site_audit_data: [],
  site_audit_domain: "",
  site_audit_overview: [],
  emptySiteAuditData: () => {
    set(() => ({
      site_audit_data: [],
      site_audit_domain: "",
      site_audit_overview: [],
    }));
  },
  plan_details: [],
  plan_status: null,
  plan_loaded: false,
  // rank tracking
  all_loc_data: [],
  keyword_latest_data: [],
  keyword_old_data: [],
  keyword_loc_data: "",
  emptyAllLocData: () => {
    set(() => ({
      all_loc_data: [],
    }));
  },
  emptyKeywordData: () => {
    set(() => ({
      keyword_old_data: [],
      keyword_latest_data: [],
    }));
  },
  rank_keyword: [],
  average_rank: null,
  keyword_move_up: null,
  keyword_move_down: null,
  active_tab_rank: "",
  improved: [],
  declined: [],
  alert: {
    show: false,
    msg: null,
    error: false,
  },
  gap_analyser_data: [],
  // country:
  country_Data: { data: [], error: false, loading: true },
  // country&languages:
  country_Language_Data: { data: [], error: false, loading: true },
  // backlink page data
  backlink_analysis: { data: [], type: "" },
  get_domain_authority: [],
  // keyword research page data
  keyword_research_data: [],
  keyword_ques_based_data: [],
  // site overview page data
  site_overview_page_data: [],
  site_overview_backlink_data: [],

  // keyword-tag
  keyword_tag_data: {
    loaded: false,
    error: false,
    data: [],
  },
  // filter-tag
  filter_tag: [],
  tag_keywords: [],
  emptyTagData: () => {
    set(() => ({
      tag_keywords: [],
      filter_tag: [],
      keyword_tag_data: {
        loaded: false,
        error: false,
        data: [],
      },
    }));
  },
  updateTag: (tag_name) =>
    set((state) => {
      const itemExists = state.filter_tag.includes(tag_name);

      return {
        filter_tag: itemExists
          ? state.filter_tag.filter((item) => item !== tag_name)
          : [...state.filter_tag, tag_name],
      };
    }),

  // google property name
  property_name: [],
  // project section
  project_section: {
    data: [],
    error: false,
    loading: true,
  },
}));
