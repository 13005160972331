import axios from "axios";
import React, { useState, useEffect } from "react";
import { AIPROJECT_GET } from "../../../services/constants";
import "../../css/ai-article.scss";
import ProjectDetails from "./projectDetails";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import AddIcon from "@mui/icons-material/Add";
import aifolder from "../../Assets/ai-article/add-project.png";
import { ScrollToView } from "../../share/helper-js/scroll-into-view";
import { DottLoader } from "../../share/loader";
import { PiFolderOpenLight } from "react-icons/pi";
import bgAIImage from "../../Assets/ai-article/bg-ai-article.png";
import addProjectImage from "../../Assets/ai-article/ai-add-project.png";

const AiProject = () => {
  const userallprojectname = useSelector((state) => state.userallprojectname);
  const admin_mail = localStorage.getItem("admin_mail");
  const [projectData, setProjectData] = useState([]);
  const [curePrData, setCurrPrData] = useState([]);
  const [Update, setUpdated] = useState(false);
  const [Loader, setLoading] = useState(false);

  const fetchAiData = async () => {
    if (admin_mail == null) return;
    return await axios
      .get(AIPROJECT_GET(admin_mail))
      .then((res) => {
        return res.data;
      })
      .catch(() => {
        return [];
      });
  };

  const handleFilterByProject = (data) => {
    let setdata = [];
    userallprojectname?.map((res) => {
      let obj = {
        websiteurl: res,
        data: [],
      };
      data?.map((prj) => {
        if (res === prj.user_proj) {
          obj.data.push({
            id: prj.id,
            title: prj.title,
            keyword: prj.keyword,
            content: prj.content,
            date: prj?.date,
            month: prj.month,
            year: prj.year,
          });
        }
      });
      setdata.push(obj);
    });
    return setdata;
  };

  const handleAiArticleData = async () => {
    const data = await fetchAiData();
    const filterData = handleFilterByProject(data);
    setProjectData(filterData);
  };

  useEffect(() => {
    if (userallprojectname?.length > 0 && projectData?.length === 0) {
      handleAiArticleData();
    }
  }, [userallprojectname]);

  const handleUpdateDeleteData = async (project) => {
    ScrollToView("ai-project-container");
    setLoading(true);
    setProjectData([]);
    setCurrPrData([]);
    const data = await fetchAiData();
    const filterData = handleFilterByProject(data);
    if (data?.length > 0) {
      const deletedData = filterData?.filter((item) => {
        return item.websiteurl === project ? item.data : null;
      });
      setCurrPrData(deletedData[0]);
    }
    setProjectData(filterData);
    setLoading(false);
  };

  return (
    <main className="main-pd" id="ai-project-container">
      {Loader ? (
        <DottLoader />
      ) : curePrData.length !== 0 ? (
        <ProjectDetails
          updateValue={Update}
          data={curePrData}
          Loader={Loader}
          EmptyData={setCurrPrData}
          UpdateDetails={setUpdated}
          UpdateData={handleUpdateDeleteData}
        />
      ) : (
        <>
          <h5 className="mb-3 heading-large ">My Projects</h5>
          <div className="add-project-box">
            <img src={bgAIImage} alt="" className="add-project-bg" />
            <div className="box-contain">
              <img src={addProjectImage} alt="" />
              <Link to="/addpr">
                <button
                  className="btn_new_bg CreateAi mt-3"
                  onClick={() => {
                    localStorage.removeItem("ProjectsDetail");
                    localStorage.removeItem("Locationdetails");
                    localStorage.removeItem("CompetitorsDetails");
                  }}
                >
                  <AddIcon className="me-1" />
                  Create New Project
                </button>
              </Link>
            </div>
          </div>
          <div className="row mt-4">
            {projectData?.map((res) => {
              return (
                <div className="col-lg-6 col-xl-4" key={res.websiteurl}>
                  <div
                    style={{ cursor: "pointer" }}
                    className="SubBox mt-3"
                    onClick={() => {
                      setCurrPrData(res);
                      ScrollToView("ai-project-container");
                    }}
                  >
                    <div className="cml">
                      <img
                        src={aifolder}
                        alt=""
                        className="project-aifolder"
                        style={{
                          maxWidth: "80px",
                          objectFit: "contain",
                        }}
                      />
                      <h4
                        style={{
                          whiteSpace: "nowrap",
                          overflow: "hidden",
                          textOverflow: "ellipsis",
                        }}
                        className="heading-medium font-light-bold mb-0 ms-3"
                      >
                        {res.websiteurl}
                      </h4>
                    </div>

                    <div
                      style={{
                        borderTop: "1px solid #dcdcdc",
                        padding: "1rem 0 0 0",
                        marginTop: "1rem",
                      }}
                    >
                      <div className="cmd">
                        <div
                          style={{ color: "black", lineHeight: "normal" }}
                          className="heading-small"
                        >
                          <PiFolderOpenLight />
                          {res.data.length} Files
                        </div>
                        {/* <div>{res.data.date || "N/A"}</div> */}
                      </div>
                    </div>
                  </div>
                </div>
              );
            })}
          </div>
        </>
      )}
    </main>
  );
};

export default AiProject;
