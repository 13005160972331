import React, { useEffect } from "react";
import Logo from "../../../assets/images/Global/logo.png";
import { Link } from "react-router-dom";

const Term_header = () => {

  useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  }, []);

  return (
    <div className="term-Header">
      <div className="tr_head_body">
        <div className="tr_left">
          <Link to={"/affiliate"}>
            <img src={Logo} alt="RanksPro.io Logo" />
          </Link>
        </div>
        <div className="tr_right">
          <Link to={`/affiliate/signup`}>
            <button>Register Now</button>
          </Link>
        </div>
      </div>
    </div>
  );
};

export default Term_header;
