import React from "react";
import Pagination from "@mui/material/Pagination";
import useMediaQuery from "@mui/material/useMediaQuery";

function CustomPagination({ page, onPageChange, totalPage }) {
  const md = useMediaQuery("(min-width:375px)");
  const lg = useMediaQuery("(min-width:600px)");
  const handlePageChange = (event, value) => {
    onPageChange(value);
  };

  return (
    <Pagination
      count={totalPage}
      page={page}
      onChange={handlePageChange}
      variant="outlined"
      shape="rounded"
      size={lg ? "large" : md ? "medium" : "small"}
      className="custom-pagination"
      sx={{
        "& .Mui-selected": {
          color: "#e95655",
          border: "1px solid #e95655",
        },
        "& .Mui-disabled": {
          color: "#CAC9EC !important",
          border: "1px solid rgba(0, 0, 0, 0.23)!important",
        },
        "& .MuiPaginationItem-page:hover": {
          backgroundColor: "#e95655 !important",
          color: "white",
          border: "1px solid #e95655!important",
        },
      }}
    />
  );
}

export default CustomPagination;
