import axios from "axios";
import { ZustandStore } from "../../../../store/zustand-store";
import {
  POST_KEYWORD_TAG,
  UPDATE_KEYWORD_TAG,
  DELETE_KEYWORD_TAG,
} from "../../../../services/constants";

const GET_KEYWORD_TAG = (tag) =>
  `https://app.rankspro.io/api/user/keytagget/?email=${tag.email}&tag_type=${tag.tag_type}&proj_name=${tag.proj_name}&location_name=${tag?.all_location_list}&language_name=${tag?.language_name}`;

const getKeywordsTag = (config) => {
  axios
    .get(GET_KEYWORD_TAG(config), {
      headers: {
        "Content-Type": "application/json",
      },
    })
    .then((response) => {
      ZustandStore.setState({
        keyword_tag_data: {
          loaded: true,
          error: false,
          data: response.data,
        },
      });
    })
    .catch(() => {});
};

export const updateKeywordsTag = (data) => {
  let config = {
    method: "put",
    maxBodyLength: Infinity,
    url: UPDATE_KEYWORD_TAG(data),
    headers: {
      "Content-Type": "application/json",
    },
  };
  axios
    .request(config)
    .then(() => {
      getKeywordsTag(data);
    })
    .catch((error) => {
      console.log(error);
    });
};

export const postKeywordsTag = async (data) => {
  let config = {
    method: "post",
    maxBodyLength: Infinity,
    url: POST_KEYWORD_TAG(),
    headers: {
      "Content-Type": "application/json",
    },
    data: JSON.stringify(data),
  };
  axios
    .request(config)
    .then(() => {
      getKeywordsTag(data);
    })
    .catch((error) => {
      console.log(error);
    });
};

export const deleteKeywordsTag = async (data) => {
  let config = {
    method: "delete",
    maxBodyLength: Infinity,
    url: DELETE_KEYWORD_TAG(data),
    headers: {
      "Content-Type": "application/json",
    },
  };
  axios
    .request(config)
    .then(() => {
      getKeywordsTag(data);
    })
    .catch((error) => {
      console.log(error);
    });
};

export const filterItems = (items, newArray) => {
  return newArray?.filter((item) => {
    const locationKeywords = items?.find(
      (kl) => kl.location_name === item.location
    );
    return (
      locationKeywords && locationKeywords.keyword_list.includes(item.keyword)
    );
  });
};

export const filterItemsWithKeyword = (type, items, newArray) => {
  // Flatten the keyword lists into a single array of keywords
  if (type === "qsb") {
    const allKeywords = items?.flatMap((item) => item.keyword_list);
    return newArray?.filter((str) =>
      allKeywords.some((keyword) => str.includes(keyword))
    );
  } else {
    const allKeywords = items?.flatMap((item) => item.keyword_list);
    return newArray?.filter((item) => allKeywords?.includes(item.keyword));
  }
};
