import axios from "axios";
import { useDispatch, useSelector } from "react-redux";
import {
  customer_Subscription,
  customer_Subs_Email,
  get_Plans_Details,
  CODEVALID_GET,
  get_AlphaPlan,
  UPDATE_affiliateGet,
  GET_USER_ADDON,
} from "../services/constants";
import { GET_AFF_CONVERSION } from "../Affiliate/utils/Api";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { stringify } from "qs";
import { ZustandStore } from "../store/zustand-store";
import useIpAddress from "../helperJs/useIpAddress";
import { referral_plan } from "../Mycomponent/dataFiles/referral-plan";
import { updateContact } from "../helperJs/hubspotFormSubmit";

const GetCustomer = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const ipAddress = useIpAddress();
  const { user_profile } = ZustandStore((state) => state);
  const isAdmin = localStorage.getItem("isAdmin");
  const affliate_id = localStorage.getItem("affliate_id");
  const name = useSelector((state) => state.name);
  const user_email = useSelector((state) => state.email);
  const email = localStorage.getItem("admin_mail");

  var currentDate = new Date();
  var currentYear = currentDate.getFullYear();
  var currentMonth = currentDate.toLocaleString("default", {
    month: "short",
  });

  const affConversionPlanUpdate = (data) => {
    axios
      .get(GET_AFF_CONVERSION(data.aff_id))
      .then((response) => {
        var user = response?.data?.[0]?.user;
        if (user?.length === 0) {
          axios.put(UPDATE_affiliateGet(data));
        } else {
          const index = user?.findIndex((entry) => entry.email === user_email);
          if (index !== -1) {
            // user[index] = data;
          } else {
            axios.put(UPDATE_affiliateGet(data));
          }
        }
      })
      .catch(() => {});
  };

  const inhouseEmails = [
    "akash.sahu@esearchlogix.in",
    "ashutosh@esearchlogix.com",
    "rajeev.rawat@esearchlogix.in",
    "kuldeep.dm@esearchlogix.in",
    "dipanshu@esearchlogix.in",
    "praveen.kumar@esearchlogix.in",
  ];

  const getAddOnPlans = async () => {
    try {
      const response = await axios.get(GET_USER_ADDON(email));
      return response.data;
    } catch (error) {
      console.error("GET request failed:", error);
      throw error;
    }
  };

  const handleLimitVlaue = (limit) => {
    if (limit) {
      return Number(limit);
    } else {
      return 0;
    }
  };

  const handleUserLimits = (plan, addon) => {
    const addon_plan = addon?.[0] || [];
    dispatch({
      type: "BACKLINKLIMIT",
      payload: {
        backlinks_rows_limit:
          Number(plan?.backlinks_rows_limit) +
          handleLimitVlaue(addon_plan?.backlinks),
        backlinks_domain_lmt: plan?.backlinks_domain_lmt,
      },
    });
    dispatch({
      type: "USERPROJECTLIMIT",
      payload: Number(plan.proj_len) + handleLimitVlaue(addon_plan?.project),
    });
    dispatch({
      type: "USERKEYWORDLIMIT",
      payload:
        Number(plan.keyword_len) + handleLimitVlaue(addon_plan?.track_key),
    });
    dispatch({
      type: "SUBUSERINVITELIMIT",
      payload: Number(plan.sub_users_limit),
    });
    dispatch({
      type: "COMPETITORSLIMIT",
      payload: plan.competitors_limit,
    });
    dispatch({
      type: "DOMAINVIEWLIMIT",
      payload:
        Number(plan.site_overview_limit) +
        handleLimitVlaue(addon_plan?.dom_over),
    });
    if (inhouseEmails.includes(user_email)) {
      dispatch({
        type: "CRAWLLIMIT",
        payload: 200 + handleLimitVlaue(addon_plan?.site_audit),
      });
    } else {
      dispatch({
        type: "CRAWLLIMIT",
        payload:
          Number(plan.site_audit_limit) +
          handleLimitVlaue(addon_plan?.site_audit),
      });
    }
    dispatch({
      type: "CRAWLDOMAINLIMIT",
      payload: plan.crawl_domain_limit,
    });
    dispatch({
      type: "KEYWORDVIEWLIMIT",
      payload: Number(plan.key_limit) + handleLimitVlaue(addon_plan?.key_res),
    });
    dispatch({ type: "ISPLAN", payload: true });
  };

  const fetchUserSubscriptions = async () => {
    const result = await getAddOnPlans();
    axios.get(CODEVALID_GET()).then((res) => {
      const referralUser = res.data.find((user) => user.email === email);
      const codeValid = referralUser?.code_valid;
      const codeValidJsonString = codeValid?.replace(/'/g, '"');
      const codeValidJsonStringParsedArray =
        (codeValidJsonString && JSON.parse(codeValidJsonString)) || [];
      if (referralUser) {
        const referralUserPlan =
          referral_plan?.[codeValidJsonStringParsedArray?.length - 1];
        if (referralUserPlan) {
          updateContact(user_profile, "Appsumo Lifetime Plan");
          localStorage.setItem("plan_name", "Appsumo Lifetime Plan");
          ZustandStore.setState({
            plan_status: "Active",
            plan_details: {
              plan_name: "Appsumo Lifetime Plan",
              plan_price: null,
              plan_validity_type: null,
              user_type: "referral",
              plan_status: "Active",
              referral_user: referralUser,
              limits: referralUserPlan,
              white_label: result?.[0]?.pdf || false,
            },
            plan_loaded: true,
          });
          handleUserLimits(referralUserPlan, result);
          if (affliate_id !== "0") {
            const data = [
              {
                email: email,
                name: name,
                plan: "Appsumo Lifetime Plan",
                price: "",
                year: currentYear,
                month: currentMonth,
              },
            ];
            const updateItem = {
              aff_id: affliate_id,
              data: JSON.stringify(data),
            };
            affConversionPlanUpdate(updateItem);
          }
        }
      } else {
        // debugger
        var Customerconfig = {
          method: "get",
          maxBodyLength: Infinity,
          url: customer_Subs_Email(email),
          headers: {
            Authorization:
              "Bearer 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",
            "Content-Type": "application/x-www-form-urlencoded",
          },
        };
        axios(Customerconfig)
          .then((response) => {
            if (isAdmin === "true") {
              // admin plan limits
              dispatch({
                type: "BACKLINKLIMIT",
                payload: {
                  backlinks_rows_limit: 1000000,
                  backlinks_domain_lmt: 1000000,
                },
              });
              dispatch({ type: "USERPROJECTLIMIT", payload: 100 });
              dispatch({ type: "USERKEYWORDLIMIT", payload: 100000 });
              dispatch({ type: "SUBUSERINVITELIMIT", payload: 100000 });
              dispatch({ type: "DOMAINVIEWLIMIT", payload: 100000 });
              dispatch({ type: "CRAWLLIMIT", payload: 20000 });
              dispatch({ type: "CRAWLDOMAINLIMIT", payload: 1000 });
              dispatch({ type: "KEYWORDVIEWLIMIT", payload: 500 });
              dispatch({
                type: "COMPETITORSLIMIT",
                payload: 20,
              });
              dispatch({ type: "ISPLAN", payload: true });
              ZustandStore.setState({
                plan_details: [],
                plan_loaded: true,
              });
            } else if (response.data?.data?.length !== 0) {
              const customerId = response?.data?.data?.[0]?.id;
              var SubscriptionConfig = {
                method: "get",
                maxBodyLength: Infinity,
                url: customer_Subscription(),
                headers: {
                  Authorization:
                    "Bearer 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",
                  "Content-Type": "application/x-www-form-urlencoded",
                },
                data: customerId,
              };
              axios(SubscriptionConfig).then((subcripData) => {
                const cusProduct = subcripData.data.data?.filter((item) => {
                  const cus_id = item?.customer;
                  return cus_id === customerId;
                });
                const cusProductId =
                  cusProduct?.[0]?.plan?.product ||
                  subcripData.data.data?.plan?.product;

                axios.get(get_Plans_Details()).then((res) => {
                  const data = res.data.data;
                  data?.filter((res) => {
                    if (res.prod_id == cusProductId) {
                      updateContact(user_profile, res.name);
                      handleUserLimits(res, result);
                      localStorage.setItem("plan_name", res.name);
                      localStorage.setItem("plan_validity_type", res.validity);
                      localStorage.setItem("plan_price", res.price);
                      ZustandStore.setState({
                        plan_status: subcripData?.data?.data?.[0]?.status,
                        plan_details: {
                          plan_name: res.name,
                          plan_price: res.price,
                          plan_validity_type: res.validity,
                          plan_status: subcripData?.data?.data?.[0]?.status,
                          user_type: "subscription",
                          white_label: result?.[0]?.pdf || false,
                        },
                        plan_loaded: true,
                      });

                      if (affliate_id !== "0") {
                        const data = [
                          {
                            email: email,
                            name: name,
                            plan: res.name,
                            price: stringify(res.price),
                            year: currentYear,
                            month: currentMonth,
                          },
                        ];
                        const updateItem = {
                          aff_id: affliate_id,
                          data: JSON.stringify(data),
                        };
                        affConversionPlanUpdate(updateItem);
                      }
                    }
                  });
                });
              });
            } else {
              axios.get(get_AlphaPlan(email)).then((res) => {
                if (res.data.length !== 0) {
                  axios.get(get_Plans_Details()).then((res) => {
                    const data = res.data.data;
                    data &&
                      data.filter((res) => {
                        if (res.name === "alpha") {
                          updateContact(user_profile, "Free");
                          handleUserLimits(res, result);
                          localStorage.setItem("plan_name", res.name);
                          localStorage.setItem(
                            "plan_validity_type",
                            res.validity
                          );
                          localStorage.setItem("plan_price", res.price);
                          ZustandStore.setState({
                            plan_status: "Free",
                            plan_details: {
                              plan_name: res.name,
                              plan_price: res.price,
                              plan_validity_type: res.validity,
                              user_type: "Free",
                              plan_status: "Free",
                              white_label: result?.[0]?.pdf || false,
                            },
                            plan_loaded: true,
                          });

                          if (affliate_id !== "0") {
                            const data = [
                              {
                                email: email,
                                name: name,
                                plan: "Free",
                                price: "0",
                                year: currentYear,
                                month: currentMonth,
                              },
                            ];
                            const updateItem = {
                              aff_id: affliate_id,
                              data: JSON.stringify(data),
                            };
                            affConversionPlanUpdate(updateItem);
                          }
                        }
                      });
                  });
                } else {
                  navigate("/upgrade");
                  localStorage.removeItem("plan_name");
                  localStorage.removeItem("plan_validity_type");
                  localStorage.removeItem("plan_price");
                  dispatch({ type: "ISPLAN", payload: false });
                  ZustandStore.setState({
                    plan_status: null,
                    plan_details: [],
                    plan_loaded: true,
                  });
                }
              });
            }
          })
          .catch((error) => console.error(error));
      }
    });
  };

  useEffect(() => {
    if (email != null) {
      fetchUserSubscriptions();
    }
  }, [email]);

  return null;
};

export default GetCustomer;
