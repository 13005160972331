import React, { useMemo, useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import countryList from "../../dataFiles/country";
import { ReactComponent as UnsortIcon } from "../../Assets/rank-tracking-assets/unshort.svg";
import { convertToObjects } from "../../../helperJs/helper";
import { USERPROJECT_GET } from "../../../services/constants";
import axios from "axios";
import OpenInNewIcon from "@mui/icons-material/OpenInNew";
import Tooltip from "../components/tooltip";
import Spinner from "../components/spinner";
import { useNavigate, useLocation } from "react-router-dom";
import { ReactComponent as AddIcon } from "../../Assets/rank-tracking-assets/add.svg";
import backIcon from "../../Assets/common/back.svg";

const CompetitorsTable = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const email = localStorage.getItem("admin_mail");
  const [allProject, SetAllProject] = useState([]);
  const [sortConfig, setSortConfig] = useState({ key: null, direction: "asc" });
  const topRankData = useSelector((state) => state.toprankdata);
  const competitorslimit = useSelector((state) => state.competitorslimit);

  const UserAllPendingResult = useSelector(
    (state) => state.userallpendingresult
  );
  const UserSelectedPrId = useSelector(
    (state) => state.userselectedprojectallid
  );
  const selectedProject = allProject?.filter((item) => {
    return item.id === UserSelectedPrId?.[0];
  });
  const competitor_list = selectedProject?.[0]?.Project?.[0]?.competitors;
  const websiteurl = useSelector((state) => state.websiteurl);
  const usercompatitors = useSelector((state) => state.usercompatitors);
  const userallkeywordresult = useSelector(
    (state) => state.userallkeywordresult
  );
  const allKeywordArray = [...userallkeywordresult, ...UserAllPendingResult];

  const getUserProject = () => {
    if (email == null) return;
    axios
      .get(USERPROJECT_GET(email))
      .then((response) => {
        SetAllProject(response.data?.data);
      })
      .catch((error) => console.log("error while getting data", error));
  };

  useEffect(() => {
    getUserProject();
  }, [0]);

  const getCurrentCountryDetails = (location) => {
    const country = countryList?.find(
      (item) => item.location_name === location
    );
    return country?.location_flag;
  };

  const convertedCompetitors = useMemo(
    () => convertToObjects(usercompatitors),
    [usercompatitors]
  );

  const compatotors_data = useMemo(() => {
    if (!usercompatitors || !competitor_list) return [];

    const convertedCompetitors = convertToObjects(usercompatitors);
    // Filter existing competitors and add missing domains with default object
    const finalCompetitors = competitor_list.map((domain) => {
      const existingCompetitor = convertedCompetitors.find(
        (comp) => comp.domain === domain
      );
      if (existingCompetitor) {
        return existingCompetitor;
      } else {
        // Add a default object if domain not found in convertedCompetitors
        return { domain, data: null }; // Customize the default object as needed
      }
    });

    return finalCompetitors;
  }, [usercompatitors, competitor_list]);

  const header = useMemo(() => {
    const dynamicHeader = competitor_list?.length > 0 ? competitor_list : [];
    return ["Keywords", websiteurl, ...dynamicHeader];
  }, [websiteurl, competitor_list]);

  const rows = useMemo(() => {
    return (
      allKeywordArray?.map((item, index) => {
        return compatotors_data?.reduce(
          (acc, domain) => {
            acc[domain.domain] = domain?.data?.[index]?.data?.[0]?.rank_group;
            return acc;
          },
          { ...item, rank_group: item?.data?.[0].rank_group || "Pending" }
        );
      }) || []
    );
  }, [allKeywordArray, compatotors_data]);

  const sortRows = (rows, config) => {
    if (!config.key) return rows;
    return [...rows]?.sort((a, b) => {
      const aValue = a[config.key];
      const bValue = b[config.key];

      const isANoRank = aValue === "No Rank" || aValue === undefined;
      const isBNoRank = bValue === "No Rank" || bValue === undefined;

      if (isANoRank && isBNoRank) return 0;
      if (isANoRank) return 1;
      if (isBNoRank) return -1;

      if (config.direction === "asc") {
        return aValue > bValue ? 1 : -1;
      } else {
        return aValue < bValue ? 1 : -1;
      }
    });
  };

  const sortedRows = useMemo(
    () => sortRows(rows, sortConfig),
    [rows, sortConfig]
  );

  const requestSort = (key) => {
    let direction = "asc";
    if (sortConfig.key === key && sortConfig.direction === "asc") {
      direction = "desc";
    }
    setSortConfig({ key, direction });
  };

  const handleCompetitorsTable = (show) => {
    dispatch({
      type: "CHARTIMPRDATA",
      payload: { data: [], chart_show: false },
    });
    dispatch({
      type: "TOPRANKDATA",
      payload: { data: [], top_rank: false, type: "", competitors: show },
    });
  };

  const handleAddCompetitors = () => {
    dispatch({ type: "NEWPROJECTURL", payload: websiteurl });
    navigate("/addpr/addcompetitors", {
      state: {
        project_id: UserSelectedPrId,
        competitor_list: convertedCompetitors,
      },
    });
  };

  const getSortIcon = () => {
    return <UnsortIcon />;
  };

  return (
    <>
      <div className="cmd mb-3">
        <div>
          <button
            className="icon-btn large-btn"
            onClick={() => handleCompetitorsTable(!topRankData?.competitors)}
          >
            <img src={backIcon} alt="" />
          </button>
        </div>
        <div>
          {location.pathname === "/rank-tracker" ? (
            <button
              className="add-competitor-btn"
              disabled={
                competitor_list?.length >= competitorslimit ||
                allProject?.length === 0
              }
              onClick={() => handleAddCompetitors()}
            >
              <AddIcon className="me-2" />
              Add Competitors
            </button>
          ) : null}{" "}
        </div>
      </div>

      <div className="custom-table-container">
        <table className="competitor-table-header" id="rank-tracking-table">
          <thead>
            <tr className="table-sticky">
              {header.map((header) => (
                <th
                  scope="col"
                  className={
                    header === "Keywords"
                      ? "text-start point"
                      : "text-center point"
                  }
                  key={`competitor-table-header-${header}`}
                  onClick={() =>
                    requestSort(
                      header === "Keywords"
                        ? "keyword"
                        : header === websiteurl
                        ? "rank_group"
                        : header === "Search Volume"
                        ? "search_volume"
                        : header
                    )
                  }
                  style={{
                    textWrap: "nowrap",
                    // width: header === "Keywords" ? "200px" : "",
                  }}
                >
                  {header} {getSortIcon()}
                  {header === "Keywords" && (
                    <Tooltip title="Words that people type into Google" />
                  )}
                </th>
              ))}
              <th
                className="text-center"
                scope="col"
                key={"Vol"}
                onClick={() => requestSort("search_volume")}
                style={{ textWrap: "nowrap" }}
              >
                Vol {getSortIcon()}
                <Tooltip
                  title="The numbers of searches this particular keyword has
                          during a month"
                />
              </th>
            </tr>
          </thead>
          <tbody>
            {allProject?.length === 0 ? (
              <tr>
                <td colSpan={header?.length + 1}>
                  <Spinner />
                </td>
              </tr>
            ) : (
              sortedRows.map((row, index) => (
                <React.Fragment key={`comp-table-row${index}`}>
                  <tr key={`comp-table-row${index}`}>
                    <td className="">
                      <div
                        style={{
                          textWrap: "nowrap",
                          display: "flex",
                          alignItems: "center",
                        }}
                      >
                        <span
                          className="competitor-table-cell competitor-table-keyword"
                          style={{
                            display: "block",
                            maxWidth: "80%",
                          }}
                        >
                          {row.keyword}
                        </span>
                        <img
                          src={getCurrentCountryDetails(row.location)}
                          className="ms-2"
                          alt=""
                          style={{
                            width: "20px",
                            objectFit: "contain",
                          }}
                        />
                      </div>
                    </td>
                    <td
                      className={
                        row.rank_group === "Pending"
                          ? "text-success text-center competitor-table-cell"
                          : "text-center competitor-table-cell"
                      }
                    >
                      {row.rank_group}
                      <span
                        style={{
                          textWrap: "nowrap",
                        }}
                        className="d-flex align-items-center justify-content-center"
                      >
                        {row?.data?.[0]?.url === "Not Found" ? null : (
                          <>
                            <a
                              style={{
                                display: "block",
                                maxWidth: "80%",
                              }}
                              href={row?.data?.[0]?.url}
                              target="_blank"
                              className="competitor-table-cell"
                            >
                              {row?.data?.[0]?.url}
                            </a>
                            <OpenInNewIcon
                              sx={{
                                fontSize: "13px",
                                marginLeft: "6px",
                              }}
                            />
                          </>
                        )}
                      </span>
                    </td>
                    {compatotors_data.map((domain) => (
                      <td
                        key={`comp-user-list${index}-${domain.domain}`}
                        className={
                          row[domain.domain]
                            ? "text-center competitor-table-cell"
                            : "text-success text-center competitor-table-cell"
                        }
                      >
                        {row[domain.domain] || "Pending"}
                        {domain?.data?.[index]?.data?.[0]?.url ? (
                          <span
                            className="d-flex align-items-center justify-content-center"
                            style={{
                              textWrap: "nowrap",
                            }}
                          >
                            {domain?.data?.[index]?.data?.[0]?.url ===
                            "Not Found" ? null : (
                              <>
                                <a
                                  style={{
                                    display: "block",
                                    maxWidth: "80%",
                                  }}
                                  href={domain?.data?.[index]?.data?.[0]?.url}
                                  target="_blank"
                                  className="competitor-table-cell"
                                >
                                  {domain?.data?.[index]?.data?.[0]?.url}
                                </a>
                                <OpenInNewIcon
                                  sx={{
                                    fontSize: "13px",
                                    marginLeft: "6px",
                                  }}
                                />
                              </>
                            )}
                          </span>
                        ) : null}
                      </td>
                    ))}
                    <td className="text-center competitor-table-cell">
                      {row.search_volume}
                    </td>
                  </tr>
                </React.Fragment>
              ))
            )}
          </tbody>
        </table>
      </div>
    </>
  );
};

export default CompetitorsTable;
