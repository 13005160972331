import React, { useEffect, useState } from "react";
import "../css/profile.scss";
import { Link, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import axios from "axios";
import {
  DELETE_REFERRALCODE,
  DELETE_SUBSCRIPTION,
  GET_USER_ACC_USAGE,
  Get_SUbSCRIBEDMAIL,
  INVITE_AUTH,
  InviteUserBy_Mail,
  POST_CODEVALID,
  POST_USEDCODES,
  Reffral_Get,
  SUBSCRIBE_MAIL,
  delete_User,
  get_Plans_Details,
  get_backlinktotallmtGet,
} from "../../services/constants";
import { Logout } from "../share/upDater/constant";
import noPlanImg from "../Assets/Profile/no_plan.png";
import AreYousure from "../share/card-layout/AreYousure";
import Imgprofile from "../Assets/Profile/Imgprofile.png";
import ImgAddUser from "../Assets/Profile/ImgAddUser.png";
import ImgContact from "../Assets/Profile/ImgContact.png";
import ImgEmaill from "../Assets/Profile/ImgEmaill.png";
import ImgPlans from "../Assets/Profile/ImgPlans.png";
import ImgReedem from "../Assets/Profile/ImgReedem.png";
import ToolTip from "../share/components/tooltip";

import backlinkImg from "../Assets/Profile/backlink.png";
import projectImg from "../Assets/Profile/project.png";
import userLimitImg from "../Assets/Profile/user-limit.png";
import siteAuditImg from "../Assets/Profile/site-audit-crawal.png";
import trackCompImg from "../Assets/Profile/track-comp.png";
import siteOverviweImg from "../Assets/Profile/site-overview.png";
import keywordLimitImg from "../Assets/Profile/keyword-limit.png";
import keywordImg from "../Assets/Profile/keyword.png";
import Switch from "react-switch";
import manageIcon from "../Assets/Profile/manage-member.png";
import addUserIcon from "../Assets/Profile/add-user.svg";
import noUserImg from "../Assets/Profile/no-Img.svg";
import WarningAmberIcon from "@mui/icons-material/WarningAmber";

import SubUserDetails from "../share/components/sub-user.component";
import planBgIcon from "../Assets/home/plan-bg.svg";
import Avtar from "../share/components/avtar.component";
import { ZustandStore } from "../../store/zustand-store";
import { IoMdCheckmark } from "react-icons/io";
import { codevalidupdate } from "../../services/constants";
import Tooltip from "../share/components/tooltip";
import { referral_plan } from "../dataFiles/referral-plan";

const Profile = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  // localStorage data
  const { plan_details, plan_status, plan_loaded } = ZustandStore(
    (state) => state
  );
  const adminMail = localStorage.getItem("admin_mail");
  const user_type = localStorage.getItem("user_type");
  const user_id = localStorage.getItem("user_id");

  const isPlan = useSelector((state) => state.isplan);
  const codeValid = plan_details?.referral_user?.code_valid;
  const codeValidJsonString = codeValid?.replace(/'/g, '"');
  const codeValidJsonStringParsedArray =
    (codeValidJsonString && JSON.parse(codeValidJsonString)) || [];

  // useSelector data
  const userName = useSelector((state) => state.name);
  const email = useSelector((state) => state.email);
  const subuserinvitelimit = useSelector((state) => state.subuserinvitelimit);
  const competitorslimit = useSelector((state) => state.competitorslimit);
  const crawllimit = useSelector((state) => state.crawllimit);
  const keywordviewlimit = useSelector((state) => state.keywordviewlimit);
  const userprojectlength = useSelector((state) => state.userprojectlength);
  const userprojectlimit = useSelector((state) => state.userprojectlimit);
  const userkeywordlimit = useSelector((state) => state.userkeywordlimit);
  const userkeywordlength = useSelector((state) => state.userkeywordlength);
  const domainviewlimit = useSelector((state) => state.domainviewlimit);
  const ProjectData = useSelector((state) => state);
  const backlinklimits = useSelector((state) => state.backlinkLimit);
  const userRowLmt = backlinklimits?.backlinks_rows_limit;

  // useState data variables
  const [userCurr_plan, setUserCurr_plan] = useState(false);
  const [deleteAlert, setdeleteAlert] = useState(false);

  // reral handler all states
  const [refAlert, setRefAlert] = useState(false);
  const [referal1Code, setReferal1Code] = useState("");
  const [referal2Code, setReferal2Code] = useState("");
  const [referal3Code, setReferal3Code] = useState("");
  const [refftalMatch, setReffralMatch] = useState([]);
  const [backlinkRowLmt, setBacklinkRowLmt] = useState([]);
  const [accUsage, setAccUsage] = useState([]);
  const [referralCode, setReferralCode] = useState("");

  // Subscribed Mail Alert
  const [checkMailSub, setCheckMail_Sub] = useState(false);
  // const invited_user = adminMail === email;
  const invited_user =
    adminMail?.toLocaleLowerCase() === email.toLocaleLowerCase();

  const fatch_reffralGet = () => {
    if (refftalMatch?.length === 0) {
      axios.get(Reffral_Get()).then((res) => {
        setReffralMatch(res.data.data);
      });
    }
  };

  const Fatch_SUbSCRIBEDMAIL = () =>
    axios.get(Get_SUbSCRIBEDMAIL()).then((res) => {
      const subCheck = res.data.find((user) => user.email === email);
      if (subCheck) {
        setCheckMail_Sub(true);
      } else {
        setCheckMail_Sub(false);
      }
    });

  const fatch_Plans_Details = () => {
    const plan_name = plan_details?.referral_user
      ? "Appsumo Lifetime Plan"
      : plan_details?.plan_name;
    if (plan_details?.referral_user) {
      const plan = referral_plan?.[codeValidJsonStringParsedArray?.length - 1];
      setUserCurr_plan(plan);
    } else {
      axios
        .get(get_Plans_Details())
        .then((planDetails) => {
          const checkPlanType = planDetails.data.data.find(
            (user) =>
              user.name === plan_name &&
              user.validity === plan_details?.plan_validity_type
          );
          if (checkPlanType) {
            setUserCurr_plan(checkPlanType);
          } else {
            setUserCurr_plan(false);
          }
        })
        .catch((error) => {
          console.error(error);
        });
    }
  };

  const getBacklinkLimit = (email) => {
    axios.get(get_backlinktotallmtGet(email)).then((response) => {
      if (response.data.length > 0) {
        setBacklinkRowLmt(
          response.data[response.data?.length - 1].backlinktotallmt
        );
      }
    });
  };

  const getUserAccountUsgae = (email) =>
    axios.get(GET_USER_ACC_USAGE(email)).then((res) => {
      const data = res.data;
      setAccUsage(data);
    });

  useEffect(() => {
    if (plan_loaded) {
      getUserAccountUsgae(email);
      getBacklinkLimit(email);
      fatch_reffralGet();
      fatch_Plans_Details();
      Fatch_SUbSCRIBEDMAIL();
    }
  }, [plan_loaded]);

  const User_Delete = (res) => {
    if (res) {
      axios.delete(delete_User(user_id)).then(() => {
        setdeleteAlert(false);
        Logout();
      });
    } else {
      setdeleteAlert(false);
    }
  };

  const ReferalHandler_Submit = (e) => {
    e.preventDefault();

    const foundPlan1 = refftalMatch.find((plan) =>
      plan.codes.includes(referal1Code)
    );
    const foundPlan2 = refftalMatch.find((plan) =>
      plan.codes.includes(referal2Code)
    );
    const foundPlan3 = refftalMatch.find((plan) =>
      plan.codes.includes(referal3Code)
    );

    // we can reset referral codes by using this func .
    const resetReferralCodes = () => {
      setReferal1Code("");
      setReferal2Code("");
      setReferal3Code("");
    };

    // this condition stops you , when ever you tring to submit with out filling any referral input .
    if (referal1Code === "" && referal2Code === "" && referal3Code === "") {
      setRefAlert("Please enter codes");
    }
    // same referral code each input box .
    else if (
      (referal1Code !== "" &&
        (referal1Code === referal2Code || referal1Code === referal3Code)) ||
      (referal2Code !== "" && referal2Code === referal3Code)
    ) {
      setRefAlert("You can not enter same code");
      resetReferralCodes();
    }
    // Non valid codes .
    else if (
      (!foundPlan1 && referal1Code !== "") ||
      (!foundPlan2 && referal2Code !== "") ||
      (!foundPlan3 && referal3Code !== "")
    ) {
      setRefAlert("Please enter valid Codes");
      resetReferralCodes();
    }
    // if you using deferrent validity codes .
    else if (
      (foundPlan1 &&
        foundPlan2 &&
        foundPlan1.validity !== foundPlan2?.validity) ||
      (foundPlan1 &&
        foundPlan3 &&
        foundPlan1.validity !== foundPlan3?.validity) ||
      (foundPlan2 && foundPlan3 && foundPlan2.validity !== foundPlan3?.validity)
    ) {
      setRefAlert("You can not use different validity codes.");
      resetReferralCodes();
    }
    // submit .
    else {
      // select a plan from all found plans .
      const foundPlan = foundPlan1 || foundPlan2 || foundPlan3;

      // here we push referral codes which have plan .
      const sendTrue_Codes = codeValidJsonStringParsedArray || [];
      if (foundPlan1) {
        sendTrue_Codes.push(referal1Code);
      }
      if (foundPlan2) {
        sendTrue_Codes.push(referal2Code);
      }
      if (foundPlan3) {
        sendTrue_Codes.push(referal3Code);
      }

      // here we set plan name by the referal codes length .
      const def_codeName =
        sendTrue_Codes?.length === 1
          ? "pro"
          : sendTrue_Codes?.length === 2
          ? "elite"
          : sendTrue_Codes?.length === 3
          ? "lifetime"
          : foundPlan.plan_name;

      // getting future date for plan expiry .
      const currentDate = new Date();
      const futureDate = new Date();
      futureDate.setDate(currentDate.getDate() + Number(foundPlan.validity));
      const futureDay = futureDate.getDate();

      // here we save all credentials. which have post in to the codeValid API .
      const reffralItems = {
        // code_id: foundPlan.id,
        email: email,
        code_valid: sendTrue_Codes,
        valid: foundPlan.validity,
        code_name: def_codeName,
        expiry_date: foundPlan.validity === "lifetime" ? 100 : futureDay,
      };

      // this code of json using for USEDCODE API .
      const usedCodes_Json = {
        plan_name: reffralItems.code_name,
        codes: reffralItems.code_valid,
        validity: reffralItems.valid,
      };

      const json_DELETE = JSON.stringify(reffralItems.code_valid);

      if (plan_details?.referral_user) {
        const user = plan_details?.referral_user;
        axios
          .put(codevalidupdate(user.id), reffralItems)
          .then(() => {
            axios.post(POST_USEDCODES(), usedCodes_Json);
            axios.put(DELETE_REFERRALCODE(json_DELETE)).then(() => {
              // this function reset the data of referal_get variable
              fatch_reffralGet();
              navigate("/dashboard");
              navigate(0);
            });
          })
          .catch(() => {
            console.log("error on code: plan update");
          });
      } else {
        axios
          .post(POST_CODEVALID(), reffralItems)
          .then(() => {
            setRefAlert("Matched");
            resetReferralCodes();

            axios.post(POST_USEDCODES(), usedCodes_Json);
            axios.put(DELETE_REFERRALCODE(json_DELETE)).then(() => {
              // this function reset the data of referal_get variable
              fatch_reffralGet();
              navigate("/dashboard");
              navigate(0);
            });
          })
          .catch(() => {
            setRefAlert("You already have a plan");
          });
        // this code of json using for DELETE CODE API .
      }
    }
  };

  const SubscribeHandler = () => {
    if (checkMailSub) {
      axios.delete(DELETE_SUBSCRIPTION(email)).then(() => {
        Fatch_SUbSCRIBEDMAIL();
      });
    } else {
      const today = new Date();
      var dd = today.getDate();

      const subscribeMail_Json = {
        email: email,
        subscribe: "daily",
        Date: dd,
      };

      axios.post(SUBSCRIBE_MAIL(), subscribeMail_Json).then(() => {
        Fatch_SUbSCRIBEDMAIL();
      });
    }
  };

  // Donut Chart Data

  const [percentageProejcts, setpercentageProejcts] = useState(
    (userprojectlength / userprojectlimit) * 100
  );
  const [percentageKeyWord, setpercentageKeyWord] = useState(
    (userkeywordlength / +userkeywordlimit) * 100
  );
  var circleWidth = 200;
  var radius = 85;
  const dashArray = radius * Math.PI * 2;
  const dashOffSetProject = dashArray - (dashArray * percentageProejcts) / 100;
  const dashOffSetKeyword = dashArray - (dashArray * percentageKeyWord) / 100;

  useEffect(() => {
    setpercentageProejcts((userprojectlength / userprojectlimit) * 100);
    setpercentageKeyWord((userkeywordlength / +userkeywordlimit) * 100);
  }, [userkeywordlimit, userkeywordlength]);

  const handleChange = (e) => {
    setRefAlert(false);
    const input = e.target.value;
    const newCodesArray = input
      .split(",")
      .map((code) => code.trim())
      .filter((code) => code !== "");
    const uniqueNewCodesArray = Array.from(new Set(newCodesArray));

    // Check for duplicates in the new codes
    if (newCodesArray.length !== uniqueNewCodesArray.length) {
      setRefAlert("Duplicate or used code entered.");
      setReferralCode(input);
      return;
    }

    const limit = 3;
    const combinedCodesArray = [
      ...codeValidJsonStringParsedArray,
      ...uniqueNewCodesArray,
    ];
    const combinedCodesSet = new Set(combinedCodesArray);
    if (combinedCodesSet.size !== combinedCodesArray?.length) {
      setRefAlert("Duplicate code entered");
      setReferralCode(input);
      return;
    }

    if (combinedCodesSet.size <= limit) {
      setReferralCode(input);
      const allCodesArray = Array.from(combinedCodesSet);
      if (codeValidJsonStringParsedArray?.length === 1) {
        setReferal2Code(allCodesArray[1] || "");
        setReferal3Code(allCodesArray[2] || "");
      } else if (codeValidJsonStringParsedArray?.length === 2) {
        setReferal3Code(allCodesArray[2] || "");
      } else if (codeValidJsonStringParsedArray?.length === 0) {
        setReferal1Code(allCodesArray[0] || "");
        setReferal2Code(allCodesArray[1] || "");
        setReferal3Code(allCodesArray[2] || "");
      }
    } else {
      setRefAlert(`You can only enter up to ${limit} referral codes.`);
    }
  };

  const plans = [
    {
      name: "Plan 1",
      features: [
        "25 domains/projects",
        "800 tracked keywords",
        "4 competitors per project",
        "2 users",
        "400 site overview",
        "10,000 backlinks credits",
        "1,000 site audit credits weekly",
        "100 keyword research credits",
      ],
    },
    {
      name: "Plan 2",
      features: [
        "50 domains/projects",
        "1,800 tracked keywords",
        "5 competitors per project",
        "4 users",
        "600 site overview",
        "20,000 backlinks credits",
        "5,000 site audit credits weekly",
        "400 keyword research credits",
      ],
    },
    {
      name: "Plan 3",
      features: [
        "100 domains/projects",
        "3,800 tracked keywords",
        "5 competitors per project",
        "10 users",
        "1,000 site overview",
        "40,000 backlinks credits",
        "10,000 site audit credits weekly",
        "1,000 keyword research credits",
      ],
    },
  ];

  return (
    <>
      <main className="profile-det profile-container">
        <div className="row g-4 account-details">
          <div className="col-12 col-md-5 col-xl-4">
            <div className="ProfileBox user-details">
              <div className="w-100">
                <div className="pr-ImgBox">
                  <div style={{ width: "80px", height: "80px" }}>
                    <Avtar user_name={userName} />
                  </div>
                  <h2 className="profile-heading">Profile</h2>
                </div>
                <div className="profile-next-line">
                  <ul className="pr-contact-de">
                    <li>
                      <div
                        className="Pr-InfoBox"
                        style={{ color: "#00000099" }}
                      >
                        {userName}
                      </div>
                    </li>
                    <li>
                      <div
                        className="Pr-InfoBox"
                        style={{ color: "#00000099" }}
                      >
                        {email}
                      </div>
                    </li>
                    <li>
                      <div
                        className="Pr-InfoBox"
                        style={{ color: "#00000099" }}
                      >
                        **********
                      </div>
                    </li>
                  </ul>
                  <button
                    className="Pr-btn"
                    style={{ minWidth: "120px" }}
                    onClick={() => setdeleteAlert(true)}
                  >
                    Delete
                  </button>
                </div>
              </div>
            </div>
          </div>
          <div className="col-12 col-md-7 col-xl-5">
            <div className="user-plan-details h-100">
              <div className="ProfileBox plan-details flex-column h-100">
                <div className="w-100">
                  {userCurr_plan ? null : (
                    <div className="no_plan">
                      <img src={noPlanImg} alt="no plan" />
                    </div>
                  )}
                  <h4 className="mb-0">Plan</h4>
                  {userCurr_plan ? (
                    <div className="plan-detail-card">
                      <div
                        className="curr-plan-name"
                        style={{
                          visibility: plan_details?.referral_user
                            ? "hidden"
                            : "visible",
                        }}
                      >
                        <span className="plan-name">{userCurr_plan.name}</span>
                      </div>
                      <div className="pan-det">
                        {plan_status && (
                          <div className="card-plan-over">
                            {plan_status || "current"}
                          </div>
                        )}
                        <div className="plan-price">
                          {plan_details?.referral_user ? (
                            <span className="heading-large">
                              AppSumo Lifetime Plan
                            </span>
                          ) : (
                            <>
                              <span>${userCurr_plan.price}</span> USD/month
                            </>
                          )}
                        </div>
                        {plan_details?.referral_user ? (
                          <ul className="referral-plan">
                            {plans?.[
                              codeValidJsonStringParsedArray?.length - 1
                            ]?.features?.map((feature) => {
                              return (
                                <li key={feature}>
                                  <IoMdCheckmark className="me-1" />
                                  {feature}
                                </li>
                              );
                            })}
                          </ul>
                        ) : (
                          <ul>
                            <li>
                              <IoMdCheckmark className="me-1" />
                              {`${userCurr_plan?.proj_len} domains/projects`}
                            </li>
                            <li>
                              <IoMdCheckmark className="me-1" />
                              {`${userCurr_plan?.keyword_len} tracked keywords`}
                            </li>
                            <li>
                              <IoMdCheckmark className="me-1" />
                              {`${userCurr_plan?.competitors_limit} competitors per project`}
                            </li>
                            <li>
                              <IoMdCheckmark className="me-1" />
                              {`${userCurr_plan?.sub_users_limit} users`}
                            </li>
                            <li>
                              <IoMdCheckmark className="me-1" />
                              {`${userCurr_plan?.site_overview_limit} site overview`}
                            </li>
                            <li>
                              <IoMdCheckmark className="me-1" />
                              {`${userCurr_plan?.backlinks_rows_limit} backlinks
                          credits`}
                            </li>
                            <li>
                              <IoMdCheckmark className="me-1" />
                              {`${userCurr_plan?.site_audit_limit} site audit credits
                          weekly`}
                            </li>
                            <li>
                              <IoMdCheckmark className="me-1" />
                              {`${userCurr_plan?.key_limit} keyword
                          research credits`}
                            </li>
                          </ul>
                        )}
                        {user_type === "individual" ? (
                          <div className="d-flex align-items-center w-100 my-1">
                            <Link to="/upgrade" className="w-100">
                              <button
                                style={{ height: "40px", borderRadius: "5px" }}
                                className={
                                  userCurr_plan.name === "ultimate"
                                    ? "upgrade-btn  m-0 px-2 py-1"
                                    : "upgrade-btn  m-0 px-2 py-1"
                                }
                              >
                                {userCurr_plan.name === "ultimate"
                                  ? "Selected"
                                  : "Upgrade"}
                              </button>
                            </Link>
                          </div>
                        ) : null}
                        <div className="profile-update-ope">
                          {plan_details?.referral_user ? null : (
                            <a href="https://billing.stripe.com/p/login/14k7us0IvchS8so6oo">
                              Manage Subscription
                            </a>
                          )}
                        </div>
                      </div>
                    </div>
                  ) : user_type === "individual" ? (
                    <div className=" w-100">
                      <div className="pt-3">
                        <p className="mb-0">You don't have any plan.</p>
                        <p>Please buy a plan</p>
                      </div>
                      <Link to="/upgrade">
                        <button
                          className="upgrade-plan-btn mt-2"
                          style={{ height: "40px", minWidth: "100px" }}
                        >
                          Upgrade
                        </button>
                      </Link>
                    </div>
                  ) : (
                    <div className=" w-100">
                      <div className="pt-3">
                        <h6 className="mb-0" style={{ color: "red" }}>
                          <p className="mb-0">If you want to upgrade plan</p>
                          <p>Please contact organization owner</p>
                        </h6>
                      </div>
                    </div>
                  )}
                </div>

                {!invited_user ? (
                  <div className="d-flex flex-column align-items-cnter w-100">
                    <p
                      className="msg-error text-start mb-0"
                      style={{ color: "red" }}
                    >
                      If you want to change Plan, contacy account owner -{" "}
                    </p>
                    <span style={{ color: "black" }}>{adminMail}</span>
                  </div>
                ) : null}
              </div>
            </div>
          </div>
          <div className="col-12 col-md-12 col-xl-3">
            <div className="ProfileBox redeem-codes">
              <div className="w-100">
                <div>
                  <h4 className="">
                    Redeem your Codes
                    <ToolTip title="Enter your appsumo redeemable codes here. " />
                  </h4>
                  <div className="profile-next-line">
                    <h6 className="">
                      Enter your Appsumo Redeemable Codes here{" "}
                    </h6>{" "}
                  </div>
                </div>

                <div>
                  <form>
                    <div className="prefix_Form inviteForm mt-4">
                      <div className="row mb-3 gy-2">
                        <div>
                          {codeValidJsonStringParsedArray?.length >= 3 ||
                          !invited_user ? (
                            <div className="col-12 col-md-6 col-lg-12 pe-0 d-flex align-items-center">
                              <input
                                type="text"
                                placeholder="Referral Code"
                                value={
                                  !invited_user
                                    ? "You cannot redeem codes"
                                    : "all code used"
                                }
                                disabled
                              />
                              <Tooltip
                                title={
                                  !invited_user
                                    ? "You cannot redeem any codes, Contact account owner"
                                    : "Disabled as you have used all redemption codes."
                                }
                                icon={
                                  <WarningAmberIcon
                                    sx={{ color: "#e95655" }}
                                    className="ms-0"
                                    fontSize="small"
                                  />
                                }
                              />
                            </div>
                          ) : (
                            <input
                              type="text"
                              placeholder="Enter Codes"
                              value={referralCode}
                              onChange={handleChange}
                            />
                          )}
                        </div>
                        {/* <div className="col-12 col-md-6 col-lg-12 pe-0 d-flex align-items-center">
                        <input
                          type="text"
                          placeholder="Referral Code"
                          value={referal1Code}
                          onChange={(e) => {
                            setReferal1Code(e.target.value);
                          }}
                        />
                      </div> */}
                      </div>
                      <p className="vl-msd-line" style={{ textAlign: "start" }}>
                        {refAlert === "Matched" ? (
                          <span className="text-success">{refAlert} </span>
                        ) : (
                          refAlert
                        )}{" "}
                      </p>
                      <button
                        className="Pr-btn"
                        style={{
                          height: "40px",
                          minWidth: "145px",
                          borderRadius: "4px",
                        }}
                        disabled={
                          referal1Code === "" &&
                          referal2Code === "" &&
                          referal3Code === ""
                        }
                        onClick={(e) => ReferalHandler_Submit(e)}
                      >
                        Submit
                      </button>
                      {!invited_user ? (
                        <div className="d-flex flex-column align-items-cnter mt-4">
                          <p className="msg-error text-start mb-0">
                            You cannot redeem any codes, Contact account owner -{" "}
                          </p>
                          <span style={{ color: "black" }}>{adminMail}</span>
                        </div>
                      ) : (
                        <div className="profile-next-line mt-2">
                          <h6 className="">*Instructions: </h6>{" "}
                          <h6 className="">
                            1. If you have multiple codes enter using commas (,)
                            between them. For ex:- sdfEW23fd, dWEBB43Dd,
                            dfcjWBC4d
                          </h6>
                          <h6 className="mb-0">
                            2. You can redeem upto 3 codes here.
                          </h6>
                        </div>
                      )}
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="row g-4 account-plan">
          <div className="col-12 col-xl-9 px-4">
            <div className="row ProfileBox account-use gy-2 gy-xl-0">
              <h1>Account Usage</h1>
              <div className="col-12 col-md-6 col-xl-3">
                <div className="ProfileBox p-4 flex-column align-items-start">
                  Projects:{" "}
                  <span className="acc-fea-val">
                    {userprojectlength}/{userprojectlimit}
                  </span>
                </div>
              </div>
              <div className="col-12 col-md-6 col-xl-3">
                <div className="ProfileBox p-4 flex-column align-items-start">
                  Keywords:{" "}
                  <span className="acc-fea-val">
                    {userkeywordlength}/{userkeywordlimit}
                  </span>
                </div>
              </div>
              <div className="col-12 col-md-6 col-xl-3">
                <div className="ProfileBox p-4 flex-column align-items-start">
                  Users Limits:{" "}
                  <span className="acc-fea-val">{subuserinvitelimit}</span>
                </div>
              </div>
              <div className="col-12 col-md-6 col-xl-3">
                <div className="ProfileBox p-4 flex-column align-items-start">
                  Track Competitors :
                  <span className="acc-fea-val">
                    {competitorslimit} Per Project
                  </span>
                </div>
              </div>
              <div className="col-12 col-md-6 col-xl-3">
                <div className="ProfileBox p-4 flex-column align-items-start">
                  Site Audit Crawls :{" "}
                  <span className="acc-fea-val">{crawllimit} Weekly</span>
                </div>
              </div>
              <div className="col-12 col-md-6 col-xl-3">
                <div className="ProfileBox p-4 flex-column align-items-start">
                  Keyword Research Limit:
                  <span className="acc-fea-val">
                    {accUsage?.total_keyword_limit || 0}
                    {"/"}
                    {keywordviewlimit} Limits
                  </span>
                </div>
              </div>
              <div className="col-12 col-md-6 col-xl-3">
                <div className="ProfileBox p-4 flex-column align-items-start">
                  Site Overview :
                  <span className="acc-fea-val">
                    {accUsage?.total_overview_limit || 0}
                    {"/"}
                    {domainviewlimit} Domains
                  </span>
                </div>
              </div>
              <div className="col-12 col-md-6 col-xl-3">
                <div className="ProfileBox p-4 flex-column align-items-start">
                  Backlinks :{" "}
                  <span className="acc-fea-val">
                    {backlinkRowLmt?.length === 0
                      ? 0
                      : backlinkRowLmt < 0
                      ? userRowLmt
                      : userRowLmt - backlinkRowLmt}
                    /{userRowLmt} Rows
                  </span>{" "}
                </div>
              </div>
            </div>
          </div>
          <div className="col-12 col-xl-3">
            <div className="ProfileBox email-notify">
              <div className="w-100">
                <h2 className="">Email Notifications</h2>
                <div className="d-flex align-items-center">
                  <h4 className="mb-0 me-3">Get Ranking Updates</h4>
                  <Switch
                    className="react-switch"
                    onColor="#3183FF"
                    onChange={(e) => SubscribeHandler()}
                    checked={checkMailSub}
                    aria-labelledby="neat-label"
                    uncheckedIcon={false}
                    checkedIcon={false}
                  />
                </div>
              </div>
            </div>

            <div className="ProfileBox add-user">
              <SubUserDetails />
            </div>
          </div>
        </div>

        {deleteAlert ? (
          <AreYousure
            callback={User_Delete}
            title={"You will not able to recover your Account !"}
          />
        ) : null}
      </main>
    </>
  );
};

export default Profile;
