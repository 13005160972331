import axios from "axios";
import React, { useEffect, useState } from "react";
import {
  GET_AFFLIATESLISTS,
  POST_AFFILIATE_APPROVE,
  POST_AFFILIATE_APPROVE_CLICK_COUNT,
  PUT_AFFILIATE_APPROVE,
  ALL_AFFILIATE_USER,
  POST_AFFILIATE_SEND_APPROVE_MAIL,
  GET_AFFILIATE_CLICK,
} from "../../services/constants";
import { CSVLink } from "react-csv";
import "../css/Affiliate.scss";
import { IconButton } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import APPROVE from "../assets/approve.png";
import { Box, Button } from "@mui/material";
import CustomTable from "../../Mycomponent/share/customTable";
import PopOver from "../../Mycomponent/share/components/popover.component";

export const AffilateApprove = () => {
  const [LISTS, setLISTS] = useState([]);
  const [AllLIST, setAllList] = useState([]);
  const [selectedUser, setSelectedUser] = useState("");
  const [Refetch, setRefetch] = useState(0);
  const [Show, setShow] = useState(false);
  const [SendData, setSendData] = useState({});
  const [affClick, setAffClick] = useState(0);
  const [affLoader, setAffLoader] = useState(true);
  const [page, setPage] = React.useState(1);

  const HandleFetchList = () => {
    axios.get(GET_AFFLIATESLISTS()).then(({ data }) => {
      setLISTS(data);
      setAffLoader(false);
    });
  };

  const HandleFetchAllList = () => {
    axios.get(ALL_AFFILIATE_USER()).then(({ data }) => {
      setAllList(data);
    });
  };

  async function getAffiliateClickResult(affiliateId) {
    if (affiliateId == undefined) return;
    try {
      const response = await axios.get(GET_AFFILIATE_CLICK(affiliateId));
      const data =
        response.data?.length === 0
          ? 0
          : response.data?.[0]?.click
          ? response.data?.[0]?.click
          : response.data;
      setAffClick(data);
    } catch (error) {
      console.error("Error fetching click data:", error);
    }
  }

  const handleOpen = (Elem) => {
    setSendData(Elem);
    setShow(true);
  };

  const HandleApprove = ({ aff_id, aff_link, email }) => {
    const config = {
      email: email,
      aff_id: aff_id,
      aff_link: aff_link,
    };
    axios.post(POST_AFFILIATE_APPROVE(email, aff_link, aff_id)).then((res) => {
      axios.put(PUT_AFFILIATE_APPROVE(email, aff_link, aff_id)).then((res) => {
        setRefetch(Refetch + 1);
        axios
          .post(POST_AFFILIATE_APPROVE_CLICK_COUNT(aff_id))
          .then((res) => {});
        axios.post(POST_AFFILIATE_SEND_APPROVE_MAIL(config)).then((res) => {});
      });
      setShow(false);
    });
  };

  const userDetail = AllLIST?.filter((user) => {
    return user?.email === selectedUser;
  });

  const userData = LISTS?.filter((user) => {
    return user?.email === selectedUser;
  });

  useEffect(() => {
    HandleFetchList();
    HandleFetchAllList();
  }, [Refetch]);

  useEffect(() => {
    if (userData?.[0]?.approved === "True") {
      const affiliate_id = userData?.[0]?.aff_id;
      getAffiliateClickResult(affiliate_id);
    } else {
      setAffClick(0);
    }
  }, [userData]);

  const loadCSVData = () => {
    if (LISTS?.length === 0) return [];
    return LISTS?.map((item) => {
      const obj = {
        email: item?.email,
        aff_link: item?.aff_link,
        aff_id: item?.aff_id,
        approved: item?.approved === "True" ? "true" : "false",
      };
      return obj;
    });
  };

  const tableHeader = [
    {
      label: "EMAIL",
      key: "email",
      minWidth: 120,
      align: "left",
      width: "25%",
    },
    {
      label: "AFFIILIATE LINK",
      key: "affiliate_link",
      minWidth: 120,
      width: "25%",
      align: "left",
    },
    {
      label: "AFFILIATE ID",
      key: "affiliate_id",
      minWidth: 120,
      align: "center",
      width: "25%",
    },
    {
      label: "",
      key: "space",
      minWidth: 120,
      align: "center",
      width: "25%",
    },
  ];

  function createData(email, affiliate_link, affiliate_id, space, action) {
    return {
      email,
      affiliate_link,
      affiliate_id,
      space,
      action,
    };
  }

  const rows = LISTS?.map((item) => {
    return createData(
      item?.email,
      item?.aff_link,
      item?.aff_id,
      <div className="BUTTONS">
        <button
          disabled={item.approved === "True"}
          onClick={() => handleOpen(item)}
          className={`${item.approved === "True" ? "green" : "normal"}`}
        >
          {item.approved === "True" ? "Approved" : "Approve"}
        </button>
      </div>,
      () => setSelectedUser(item.email)
    );
  });

  return (
    <>
      <div className="">
        {Show && (
          <PopOver anchorEl={true}>
            <div className="ApproveModal">
              <CloseIcon onClick={() => setShow(false)} />
              <div className="imgBoxApp">
                <img src={APPROVE} alt="Want To Approve This Affiliates" />
              </div>
              <h1>
                Are You Sure You Want To Approve <br />
                This Affiliates?
                <div class="buttons">
                  <button onClick={() => setShow(false)}>Cancel</button>
                  <button onClick={() => HandleApprove(SendData)}>
                    Approve
                  </button>
                </div>
              </h1>
            </div>
          </PopOver>
        )}
        {selectedUser ? (
          <>
            <h1 className="table_title heading-large font-bold mb-4">
              Affiliates
            </h1>
            <div className="Box UserDetails">
              <div>
                <div className="cmd">
                  <div>
                    {" "}
                    <h2 className="table_title">User Details</h2>
                  </div>
                  <button
                    className="cm-btn"
                    onClick={() => setSelectedUser("")}
                  >
                    Back
                  </button>
                </div>
                {userDetail?.map((res, index) => {
                  return (
                    <div
                      key={`user-deatils-${index}`}
                      className="userDetail_Admin"
                    >
                      <div className="userDetail_Admin">
                        <p>Name</p>
                        <h6>{res.name}</h6>
                      </div>
                      <div className="userDetail_Admin">
                        <p>Email</p>
                        <h6>{res.email}</h6>
                      </div>
                      <div className="userDetail_Admin">
                        <p>Contact</p>
                        <h6>{res.contact}</h6>
                      </div>
                      <div className="userDetail_Admin">
                        <p>Address</p>
                        <h6>{res.address}</h6>
                      </div>
                      <div className="userDetail_Admin">
                        <p>Country</p>
                        <h6>{res.country}</h6>
                      </div>
                      {userData?.length > 0 ? (
                        <>
                          <div className="userDetail_Admin">
                            <p>Rewards</p>
                            <h6>{userData?.[0]?.reward}</h6>
                          </div>
                          <div className="userDetail_Admin">
                            <p>Users</p>
                            <h6>{userData?.[0]?.user?.length}</h6>
                          </div>
                        </>
                      ) : null}
                      <div className="userDetail_Admin">
                        <p>Clicks</p>
                        <h6>{affClick}</h6>
                      </div>
                    </div>
                  );
                })}
              </div>
            </div>
          </>
        ) : (
          <CustomTable
            tableTitle="Affiliates"
            showSearchBar={true}
            downloadCSVFile={
              <CSVLink data={loadCSVData()} filename={"affiliate-approve.csv"}>
                <Button className="btn_1 ms-2">Download CSV</Button>
              </CSVLink>
            }
            rows={rows}
            type="user_list"
            dataLoader={affLoader}
            tableHeader={tableHeader}
            page={page}
            setPage={setPage}
          />
        )}
      </div>
    </>
  );
};
