import React from "react";
import IMG from "../../Assets/seo-impact/mod.svg";
import { useSelector } from "react-redux";
import HighlightOffIcon from "@mui/icons-material/HighlightOff";
import { Link } from "react-router-dom";
import ToolTip from "../../share/components/tooltip";
import { useMediaQuery } from "@mui/material";
import greenTickIcon from "../../Assets/seo-impact/green-tick.svg";
import redIcon from "../../Assets/seo-impact/red-excl.svg";
import { ReactComponent as CrossIcon } from "../../Assets/rank-tracking-assets/cross.svg";

export const ViewSeoImpact = ({
  seoImpactAlert,
  setseoImpactAlert,
  ImpactData,
  seoImpactLoader,
}) => {
  const MOBILE = useMediaQuery("(max-width:600px)");

  const URL = useSelector((state) => state.seoImpactUrl);
  const KEYWORD = useSelector((state) => state.seoImpactKeyWord);
  const NumberRangeCheck = (number, min, max) => {
    if (number >= min && number <= max) {
      return true;
    } else {
      return false;
    }
  };

  const lengthCheck = (title, value) => {
    const length = value?.length || 0;
    const lenValue =
      title === "Meta Description"
        ? NumberRangeCheck(length, 70, 155)
        : NumberRangeCheck(length, 50, 65);

    if (title === "Meta Description" || title === "Title") {
      return (
        <div className="seo-inspect-char-box">
          <div className="d-flex align-items-center">
            <span
              className="heading-xs font-regular"
              style={{ paddingRight: "5px" }}
            >
              {length} Characters
            </span>
          </div>
          <ToolTip
            title={
              title === "Meta Description"
                ? "Meta Description of URL. An ideal length for description would be 70-155 characters."
                : "Title of URL. An ideal length for Title would be 55-65 characters."
            }
          />
          {lenValue ? (
            <img src={greenTickIcon} alt="green-tick" className="mx-2" />
          ) : (
            <img src={redIcon} alt="red-tick" className="mx-2" />
          )}
        </div>
      );
    } else {
      return null;
    }
  };
  
  return (
    <>
      {seoImpactAlert ? (
        <div className="pop ">
          <div className="popBodySeoImpact">
            <div className="SeoImpactHead">
              <h4 className="heading-large font-bold">
                SEO Insights
                <ToolTip title="This data is based upon selected keyword URL." />
              </h4>

              <CrossIcon
                style={{
                  width: "30px",
                  height: "30px",
                  cursor: "pointer",
                }}
                onClick={() => setseoImpactAlert(false)}
              />
            </div>
            <div className="SeoHead">
              <div className="heading-small font-regular mb-1">
                SEO Insights Keyword : <span>{KEYWORD}</span>
              </div>
              <div className="heading-small font-regular mb-1">
                SEO Insights URL :{" "}
                <Link to={URL}>
                  <span className="font-light-bold">
                    {URL.substring(0, 50)}...
                  </span>
                </Link>
              </div>
            </div>

            <div className="row mt-4">
              {ImpactData?.map((item) => {
                return Object.entries(item).map(([key, value]) =>
                  key.split("_").join(" ") === "Meta Description" ||
                  key.split("_").join(" ") === "Title" ? (
                    <div className="col-12 col-md-6">
                      <div className="seo-inspect-cont">
                        <h1 className="font-light-bold heading-xs">
                          {key.split("_").join(" ")}
                        </h1>
                        {lengthCheck(key.split("_").join(" "), value)}
                        <p className="my-4 font-medium heading-xs">{value}</p>
                      </div>
                    </div>
                  ) : null
                );
              })}
            </div>

            {/* <form> */}
            <div className="pop-form">
              <div className="listModalHeight">
                <div className="list-scroll seo-impact-list">
                  {seoImpactLoader ? (
                    <div
                      className="d-flex align-items-center justify-content-center"
                      style={{ height: "130px" }}
                    >
                      <div className="spinner-border" role="status">
                        <span className="visually-hidden"></span>
                      </div>
                    </div>
                  ) : ImpactData.length > 0 ? (
                    ImpactData.map((item, index) => (
                      <div key={index} className="data-item">
                        {Object.entries(item).map(([key, value]) =>
                          key.split("_").join(" ") === "Meta Description" ||
                          key.split("_").join(" ") === "Title" ? null : (
                            <div
                              key={key}
                              className="row pb-1 pt-1 border-bottom  alterBG"
                              style={{ height: "50px" }}
                            >
                              <div
                                className="font-medium heading-xs d-flex flex-column flex-md-row justify-content-center justify-content-md-start justify-content-lg-start  align-items-start align-items-md-center align-items-lg-center   flex-lg-row col-6 col-lg-6 col-md-6 text-capitalize fw-normal p-1 ps-4 "
                                style={{
                                  textAlign: "left",
                                  alignItems: "center",
                                }}
                              >
                                {key.split("_").join(" ")}
                              </div>
                              <div className="col-6 col-lg-6 col-md-6 p-0 ps-3 m-auto font-medium heading-xs">
                                {value}
                              </div>
                            </div>
                          )
                        )}
                      </div>
                    ))
                  ) : (
                    <h1 className="heading-medium font-bold">No data</h1>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      ) : null}
    </>
  );
};
