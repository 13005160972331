export const reducerFnFour = (state = false, action) => {
  if (action.type === "LOADING") {
    return (state = true);
  } else if (action.type === "NOTLOADING") {
    return (state = false);
  } else {
    return state;
  }
};

export const reducerFnthree = (state = false, action) => {
  if (action.type === "USER") {
    return (state = action.payload);
  } else {
    return state;
  }
};

export const reducerEmail = (state = null, action) => {
  if (action.type === "EMAIL") {
    return (state = action.payload);
  } else {
    return state;
  }
};

export const reducerDevicetype = (state = "desktop", action) => {
  if (action.type === "DEVICETYPE") {
    return (state = action.payload);
  } else {
    return state;
  }
};

export const reducerWebsiteUrl = (state = null, action) => {
  if (action.type === "WEBSITEURL") {
    return (state = action.payload);
  } else {
    return state;
  }
};

export const reducerCurrentLocation = (state = null, action) => {
  if (action.type === "CURRENTLOCATION") {
    return (state = action.payload);
  } else {
    return state;
  }
};

export const reducerCurrentLocationCode = (state = null, action) => {
  if (action.type === "CURRENTLOCATIONCODE") {
    return (state = action.payload);
  } else {
    return state;
  }
};

export const reducerName = (state = null, action) => {
  if (action.type === "NAME") {
    return (state = action.payload);
  } else {
    return state;
  }
};

export const reducerIsProject = (state = null, action) => {
  if (action.type === "ISPROJECT") {
    return (state = action.payload);
  } else {
    return state;
  }
};

export const reducerFnEight = (state = 0, action) => {
  if (action.type === "GETCOUNTRY") {
    return (state = action.payload);
  } else {
    return state;
  }
};

export const reducerPlansdetails = (state = false, action) => {
  if (action.type === "PLANSDETAILS") {
    return (state = action.payload);
  } else {
    return state;
  }
};

export const reducerShowMenu = (state = false, action) => {
  if (action.type === "SHOWMENU") {
    return (state = action.payload);
  } else {
    return state;
  }
};

export const reducerSelectDate = (
  state = { title: "days", day: 30, meta_des: "30" },
  action
) => {
  if (action.type === "SELECTCUSTOMDATE") {
    return (state = action.payload);
  } else {
    return state;
  }
};

export const reducerFntwo = (state = 15, action) => {
  if (action.payload === undefined) {
    return state;
  } else if (action.type === "USERKEYWORDLIMIT") {
    return (state = action.payload);
  } else {
    return state;
  }
};

export const reducerFnthr = (state = 1, action) => {
  if (action.payload === undefined) {
    return state;
  } else if (action.type === "USERPROJECTLIMIT") {
    return (state = action.payload);
  } else {
    return state;
  }
};

export const reducerFnTwentyONE = (state = 0, action) => {
  if (action.type === "USERKEYWORDLENGTH") {
    return (state = action.payload);
  } else {
    return state;
  }
};

export const reducerFnTwentyTWO = (state = 0, action) => {
  if (action.type === "USERPROJECTLENGTH") {
    return (state = action.payload);
  } else {
    return state;
  }
};

export const reducerFnInviteLimit = (state = 0, action) => {
  if (action.payload === undefined) {
    return state;
  } else if (action.type === "SUBUSERINVITELIMIT") {
    return (state = action.payload);
  } else {
    return state;
  }
};
export const reducerFnInviteLength = (state = [], action) => {
  if (action.type === "SUBUSERINVITELENGTH") {
    return (state = action.payload);
  } else {
    return state;
  }
};

export const reducerDomainViewLimit = (state = 5, action) => {
  if (action.payload === undefined) {
    return state;
  } else if (action.type === "DOMAINVIEWLIMIT") {
    return (state = action.payload);
  } else {
    return state;
  }
};

export const reducerFnFive = (state = 0, action) => {
  if (action.type === "KEYWORDDATA") {
    return (state = action.payload);
  } else {
    return state;
  }
};

export const reducerFnthir = (state = 0, action) => {
  if (action.payload === undefined) {
    return state;
  } else if (action.type === "OLDKEYWORDDATA") {
    return (state = action.payload);
  } else {
    return state;
  }
};

export const reducerFnFor = (state = false, action) => {
  if (action.type === "USERALLKEYWORDRESULT") {
    return (state = action.payload);
  } else {
    return state;
  }
};

export const reducerFnFIV = (state = [], action) => {
  if (action.type === "USERALLPENDINGRESULT") {
    return (state = action.payload);
  } else {
    return state;
  }
};

export const reducerSelectedProjectNewdata = (state = false, action) => {
  if (action.type === "SELECTEDPROJECTNEWDATA") {
    return (state = action.payload);
  } else {
    return state;
  }
};

export const reducerFnSixteen = (state = 0, action) => {
  if (action.type === "ALLPROJECTDETAILS") {
    return (state = action.payload);
  } else {
    return state;
  }
};

export const reducerFnEle = (state = [], action) => {
  if (action.type === "USERALLPROJECTDETAILS") {
    return (state = action.payload);
  } else {
    return state;
  }
};

export const reducerFnTwe = (state = false, action) => {
  if (action.type === "PREVIOUSALLOLDDATA") {
    return (state = action.payload);
  } else {
    return state;
  }
};

export const reducerFnOne = (state = false, action) => {
  if (action.type === "USERALLPROJECTNAME") {
    return (state = action.payload);
  } else {
    return state;
  }
};

export const reducerFnEightteen = (state = false, action) => {
  if (action.type === "NEWPROJECTURL") {
    return (state = action.payload);
  } else {
    return state;
  }
};

export const reducerFnnNinteen = (state = 0, action) => {
  if (action.type === "RANKMOVEDUP") {
    return (state = action.payload);
  } else {
    return state;
  }
};

export const reducerFnTwenty = (state = 0, action) => {
  if (action.type === "RANKMOVEDDOWN") {
    return (state = action.payload);
  } else {
    return state;
  }
};

export const reducerAllOldKEYWORDDATAData = (state = 0, action) => {
  if (action.type === "ALLOLDKEYWORDDATA") {
    return (state = action.payload);
  } else {
    return state;
  }
};

export const reducerSelectedProjectAllId = (state = 0, action) => {
  if (action.type === "USERSELECTEDPROJECTALLID") {
    return (state = action.payload);
  } else {
    return state;
  }
};

export const reducerAddProjectLocation = (state = false, action) => {
  if (action.type === "ADDPROJECTLOCATION") {
    return (state = action.payload);
  } else {
    return state;
  }
};

export const reducerUserCurrentProjectLocation = (state = false, action) => {
  if (action.type === "USERCURRENTPROJECTLOCATION") {
    return (state = action.payload);
  } else {
    return state;
  }
};

export const reducerAddNewCompetitors = (state = false, action) => {
  if (action.type === "ADDNEWCOMPETITORS") {
    return (state = action.payload);
  } else {
    return state;
  }
};

export const reducerCompetitorsLimit = (state = 0, action) => {
  if (action.payload === undefined) {
    return state;
  } else if (action.type === "COMPETITORSLIMIT") {
    return (state = action.payload);
  } else {
    return state;
  }
};

export const reducerUserCompatitors = (state = [], action) => {
  if (action.payload === undefined) {
    return state;
  } else if (action.type === "USERCOMPATITORS") {
    return (state = action.payload);
  } else {
    return state;
  }
};

export const reducerDropDown = (state = false, action) => {
  if (action.payload === undefined) {
    return state;
  } else if (action.type === "DROPDOWN") {
    return (state = action.payload);
  } else {
    return state;
  }
};

export const reducerCrawlLimit = (state = false, action) => {
  if (action.payload === undefined) {
    return state;
  } else if (action.type === "CRAWLLIMIT") {
    return (state = action.payload);
  } else {
    return state;
  }
};

export const reducerCrawDoaminlLimit = (state = 0, action) => {
  if (action.payload === undefined) {
    return state;
  } else if (action.type === "CRAWLDOMAINLIMIT") {
    return (state = action.payload);
  } else {
    return state;
  }
};

export const reducerKeywordViewLimit = (state = null, action) => {
  if (action.payload === undefined) {
    return state;
  } else if (action.type === "KEYWORDVIEWLIMIT") {
    return (state = action.payload);
  } else {
    return state;
  }
};

export const reducerTopicKeyword = (state = null, action) => {
  if (action.payload === undefined) {
    return state;
  } else if (action.type === "TOPICKEYWORD") {
    return (state = action.payload);
  } else {
    return state;
  }
};

export const reducerSeoKeyword = (state = [], action) => {
  if (action.payload === undefined) {
    return state;
  } else if (action.type === "SEOKEYWORD") {
    return (state = action.payload);
  } else {
    return state;
  }
};
export const reducerIsplan = (state = true, action) => {
  if (action.payload === undefined) {
    return state;
  } else if (action.type === "ISPLAN") {
    return (state = action.payload);
  } else {
    return state;
  }
};

export const reducerBacklinkLimit = (state = [], action) => {
  if (action.type === "BACKLINKLIMIT") {
    return (state = action.payload);
  } else {
    return state;
  }
};
export const reducerBacklinkData = (state = [], action) => {
  if (action.type === "BACKLINKDATA") {
    return (state = action.payload);
  } else {
    return state;
  }
};
export const reducerBacklinkDomain = (state = null, action) => {
  if (action.type === "BACKLINKDOMAIN") {
    return (state = action.payload);
  } else {
    return state;
  }
};
export const reducerSiteDomain = (state = null, action) => {
  if (action.type === "SITEDOMAIN") {
    return (state = action.payload);
  } else {
    return state;
  }
};

export const reducerUserAnalyticData = (state = [], action) => {
  if (action.type === "USERANALYTICDATA") {
    return (state = action.payload);
  } else {
    return state;
  }
};

export const reducerSeoImpact = (state = false, action) => {
  if (action.type === "SEOIMPACTURL") {
    return (state = action.payload);
  } else {
    return state;
  }
};

export const reducerSeoImpactKeyWord = (state = false, action) => {
  if (action.type === "SEOIMPACTKEYWORD") {
    return (state = action.payload);
  } else {
    return state;
  }
};

export const reducerUserAnalyticLogin = (state = false, action) => {
  if (action.type === "USERANALYTICLOGIN") {
    return (state = action.payload);
  } else {
    return state;
  }
};
export const reducerSMTPUser = (state = {}, action) => {
  if (action.type === "SMTPUSER") {
    return (state = action.payload);
  } else {
    return state;
  }
};

//affilate
export const reducerProfile = (state = [], action) => {
  if (action.type === "PROFILE") {
    return (state = action.payload);
  } else {
    return state;
  }
};
export const reducerLogin = (state = false, action) => {
  if (action.type === "LOGIN") {
    return (state = action.payload);
  } else {
    return state;
  }
};

export const reducerClickConversion = (state = [], action) => {
  if (action.type === "CLICKCONVERSION") {
    return (state = action.payload);
  } else {
    return state;
  }
};

export const reducerPdfViewerData = (state = [], action) => {
  if (action.type === "PDFVIEWERDATA") {
    return (state = action.payload);
  } else {
    return state;
  }
};

export const reducerTopRankData = (
  state = { data: [], top_rank: false, type: "", competitors: false },
  action
) => {
  if (action.type === "TOPRANKDATA") {
    return (state = action.payload);
  } else {
    return state;
  }
};

export const reducerChartImprData = (
  state = { data: [], chart_show: false },
  action
) => {
  if (action.type === "CHARTIMPRDATA") {
    return (state = action.payload);
  } else {
    return state;
  }
};

export const reducerShowLoader = (state = false, action) => {
  if (action.type === "SHOWLOADER") {
    return (state = action.payload);
  } else {
    return state;
  }
};

export const reducerUserOldDataWithLocation = (state = [], action) => {
  if (action.type === "USER_OLD_DATA_WITH_LOCATION") {
    return (state = action.payload);
  } else {
    return state;
  }
};
export const reducerUserLatestDataWithLocation = (state = [], action) => {
  if (action.type === "USER_LATEST_DATA_WITH_LOCATION") {
    return (state = action.payload);
  } else {
    return state;
  }
};
export const reducerUserDataWithSelectedLocation = (state = "", action) => {
  if (action.type === "User_Data_With_Selected_Location") {
    return (state = action.payload);
  } else {
    return state;
  }
};
