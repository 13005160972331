// src/hooks/useQuery.js
import { useSearchParams } from "react-router-dom";

const useQuery = () => {
  const [searchParams, setSearchParams] = useSearchParams();

  const getQueryParams = () => {
    return Object.fromEntries([...searchParams]);
  };

  const setQueryParams = (params) => {
    const queryParams = new URLSearchParams(searchParams);
    Object.keys(params).forEach((key) => {
      if (params[key] === null) {
        queryParams.delete(key);
      } else {
        queryParams.set(key, params[key]);
      }
    });
    setSearchParams(queryParams);
  };

  const removeQueryParams = (keys) => {
    const queryParams = new URLSearchParams(searchParams);
    keys.forEach((key) => {
      queryParams.delete(key);
    });
    setSearchParams(queryParams);
  };

  return {
    queryParams: getQueryParams(),
    setQueryParams,
    removeQueryParams,
  };
};

export default useQuery;
