import React, { useState, useEffect, useRef } from "react";
import HeaderComp from "../HeadFoot/header-comp/header";
import { useSelector, useDispatch } from "react-redux";
import axios from "axios";
import "./dashboard.scss";
import { BsArrowUp, BsArrowDown } from "react-icons/bs";
import imgSrc1 from "../../assets/images/dashboard/dash-1.png";
import imgSrc2 from "../../assets/images/dashboard/dash-2.png";
import imgSrc3 from "../../assets/images/dashboard/dash-3.png";
import { GET_CONVERSION, GET_AFFILIATE_REWARD } from "../../utils/Api";
import { DottLoader } from "../../components/Loader";
import { useNavigate } from "react-router-dom";
import { MdOutlineContentCopy } from "react-icons/md";

function AffilateDashboard() {
  const Navigate = useNavigate();
  const dispatch = useDispatch();
  const [Data, setData] = useState([]);
  const [Loading, setLoading] = useState(true);
  const user_profile = useSelector((state) => state.profile);

  useEffect(() => {
    if (user_profile?.length != 0 && user_profile?.profile2?.length != 0) {
      var data = {
        click: 0,
        conversion: [],
        earning: 0,
      };
      axios
        .get(
          `https://app.rankspro.io/api/user/affclickget/?aff_id=${user_profile?.profile2.aff_id}`
        )
        .then((response) => {
          if (
            response?.data &&
            Array.isArray(response.data) &&
            response.data.length > 0
          ) {
            var totalClickSum = response.data?.reduce(function (sum, entry) {
              return sum + parseInt(entry.click);
            }, 0);
            data.click = totalClickSum;
          } else {
            data.click = 0;
          }
        });

      axios
        .get(GET_CONVERSION(user_profile?.profile2?.email))
        .then((response) => {
          data.conversion = response.data[0].user;
          axios.get(GET_AFFILIATE_REWARD()).then((resp) => {
            const user = response.data[0].user;
            const rewordArr = user?.map((plan) => {
              return (plan.price * Number(resp.data[0])) / 100;
            });
            let sum = 0;
            for (let i of rewordArr) {
              sum += i;
            }
            data.earning = sum;
            dispatch({ type: "CLICKCONVERSION", payload: data });
            setTimeout(() => {
              setData(data);
              setLoading(false);
            }, 200);
          });
        });
    }
  }, [user_profile]);

  const data = [
    {
      name: "Clicks",
      value: Data?.click,
      growth: "",
      imgSrc: imgSrc1,
      rank: true,
    },
    {
      name: "Conversions",
      value: Data?.conversion?.length,
      growth: "",
      imgSrc: imgSrc2,
      rank: false,
    },
    {
      name: "Earnings",
      value: `$${parseFloat(Data?.earning?.toFixed(2))}`,
      growth: "",
      imgSrc: imgSrc3,
      rank: true,
    },
  ];
  const textToCopyRef = useRef(null);
  const [Copy, setCopy] = useState("");
  const HandleRemove = () => {
    setTimeout(() => {
      setCopy("");
    }, 2000);
  };

  return (
    <>
      {/* <ToastContainer /> */}
      <div className="dashboard-container">
        <div className="section-1">
          <HeaderComp />

          <div className="AffiliateShort">
            <div className="Aff_Box">
              <span>Affiliate ID</span>
              <div ref={textToCopyRef} className="position-relative">
                <p> {user_profile?.profile2?.aff_id} </p>
                <MdOutlineContentCopy
                  onClick={() => {
                    setCopy("Copied");
                    navigator.clipboard.writeText(
                      user_profile?.profile2?.aff_id
                    );
                    HandleRemove();
                  }}
                  style={{ color: "green" }}
                />
              </div>
            </div>
            <div className="Aff_Box">
              <span>Affiliate Link</span>
              <div ref={textToCopyRef} className="position-relative">
                <p> {user_profile?.profile2?.aff_link} </p>
                <MdOutlineContentCopy
                  onClick={() => {
                    setCopy("Copied");
                    navigator.clipboard.writeText(
                      user_profile?.profile2?.aff_link
                    );
                    HandleRemove();
                  }}
                  style={{ color: "green" }}
                />
              </div>
            </div>
            <p className="text-end text-success mt-2 ">{Copy}</p>
          </div>
        </div>

        {user_profile?.profile2?.length === 0 ? (
          <div>
            <div className="affiliate-inprogress">
              <h1>Your Affiliate registration is in progress</h1>
              <p>Thank you for your patience....</p>
            </div>
          </div>
        ) : (
          <div className="section-2">
            {!Loading ? (
              <div className="row gy-3">
                {data?.map((item, index) => {
                  return (
                    <div
                      className="col-12 col-md-6 col-lg-4"
                      key={`clk-corsn-card-${index}`}
                    >
                      <div
                        className="Card point"
                        onClick={() =>
                          Navigate("/affiliate/performance-metrics")
                        }
                      >
                        <div>
                          <span>{item?.name}</span>
                          <h3>{item?.value}</h3>
                          <span className="indicator">
                            {item?.growth ? (
                              item?.rank ? (
                                <BsArrowUp color="#05E87B" />
                              ) : (
                                <BsArrowDown color="#E82E05" />
                              )
                            ) : null}
                            {item?.growth}
                          </span>
                        </div>
                        <div>
                          <img src={item?.imgSrc} alt={item?.name} />
                        </div>
                      </div>
                    </div>
                  );
                })}
              </div>
            ) : (
              <div className="content-loader">
                <DottLoader />
              </div>
            )}
          </div>
        )}
      </div>
    </>
  );
}

export default AffilateDashboard;
