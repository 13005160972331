import React from "react";

function PdfViewer({ file }) {
  return (
    <div>
      <object
        data={URL.createObjectURL(file)}
        type="application/pdf"
        width="100%"
        height="600"
      >
        <p>Alternative text - include a link to the PDF!</p>
      </object>
    </div>
  );
}

export default PdfViewer;
