import React from "react";
import { useEffect } from "react";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import Dropdown from "../../share/components/dropdown";
import { languages } from "../../dataFiles/language";
import ProjectStepper from "./project-stepper";
import backIcon from "../../Assets/common/back.svg";
import { ZustandStore } from "../../../store/zustand-store";
import LocationList from "../../share/searchBox/LocationList";
import countryList from "../../dataFiles/country";

export const AddCountry = () => {
  const { country_Data } = ZustandStore((state) => state);
  // usestate
  // console.log(country_Data, "country_Data");
  const [language, setLanguage] = useState({
    language_name: "English",
    language_code: "en",
  });
  const [countryName, setCountryName] = useState("Add Location");
  const [projectLocations, setProjectLocations] = useState([]);

  // useNaviagte
  const navigate = useNavigate();
  const dispatch = useDispatch();

  // Redux state
  const userkeywordlimit = useSelector((state) => state.userkeywordlimit);
  const UserKeywordLength = useSelector((state) => state.userkeywordlength);
  const NewProjectUrl = useSelector((state) => state.newprojecturl);
  const userProjectlimit = useSelector((state) => state.userprojectlimit);
  const USERALLPROJECTLength = useSelector((state) => state.userprojectlength);
  const competitorslimit = useSelector((state) => state.competitorslimit);

  // localStorage
  useEffect(() => {
    const Local = JSON.parse(localStorage.getItem("Locationdetails"));
    if (Local != null) {
      setProjectLocations(Local.Country);
      setLanguage(Local.Lang);
    }
  }, [0]);

  useEffect(() => {
    if (
      NewProjectUrl === false ||
      Number(UserKeywordLength) > Number(userkeywordlimit) ||
      USERALLPROJECTLength > Number(userProjectlimit)
    ) {
      navigate("/dashboard");
    }
  });

  const getCurrentCountryDetails = (curr_location_code) => {
    const data = countryList?.filter((item) => {
      return item.location_code === curr_location_code;
    });
    return data?.[0]?.location_flag;
  };

  const countryDetailHandler = (res) => {
    const curr_location_code = res?.location_code_parent
      ? res?.location_code_parent
      : res?.location_code;

    setProjectLocations((prdetails) => {
      const existingObj = prdetails.find(
        (o) => o.location_code === res.location_code
      );
      if (existingObj) {
        return prdetails;
      } else {
        return [
          ...prdetails,
          {
            location_code: res.location_code,
            location_name: res.location_name,
            location_flag: getCurrentCountryDetails(curr_location_code),
          },
        ];
      }
    });
  };

  const languageDetailHandler = (res) => {
    setLanguage({
      language_name: res.language,
      language_code: res.languagecode,
    });
    setCountryName("Add Location");
    setProjectLocations([]);
  };

  const Languages = (e) => {
    if (countryName === "Add Location") {
      setCountryName("Please add location");
    }
    if (projectLocations.length === 0) {
      setCountryName("Please add location");
    } else {
      localStorage.setItem("language", language?.language_code);
      dispatch({ type: "ADDPROJECTLOCATION", payload: projectLocations });
      if (competitorslimit !== 0) {
        navigate("/addpr/addcompetitors");
      } else {
        // i am sending blank array because user have not any plan
        dispatch({ type: "ADDNEWCOMPETITORS", payload: [] });
        navigate("/addpr/addkeyword?addproject");
      }
      const locationArray = [];
      projectLocations?.map((location) => {
        locationArray.push(location.location_name);
      });
      localStorage.setItem(
        "Locationdetails",
        JSON.stringify({ Lang: language, Country: projectLocations })
      );
    }
  };

  const removeLocations = (e) => {
    setProjectLocations((olditems) => {
      return olditems.filter((arrelm, index) => {
        return index !== e;
      });
    });
    setCountryName("Add Location");
  };

  const dropStyle = {
    width: "100%",
    boxShadow: "none",
    border: "1px solid #00000033",
    padding: "0.8rem",
    borderRadius: "5px",
  };

  return (
    <section className="">
      <div className="cmd">
        <Link to={-1}>
          <button className="icon-btn large-btn">
            <img src={backIcon} alt="back button" />
          </button>
        </Link>

        <ProjectStepper activeStep={1} />
        <button className="icon-btn large-btn" onClick={() => Languages()}>
          <img
            src={backIcon}
            alt="back button"
            style={{
              transform: "scaleX(-1)",
            }}
          />
        </button>
      </div>
      <div className="add-pr-url">
        <div>
          <h1 className="">Languages and Location</h1>
          <p className="proj-tag-line">
            Attract the right traffic by entering the languages and locations
            you do business in.
          </p>
          <div
            className="row gy-3 add-pro-web-input"
            style={{ alignItems: "baseline" }}
          >
            <div className="col-12 col-md-6 col-lg-6 add-sel">
              <Dropdown
                style={dropStyle}
                title={language?.language_name}
                data={languages}
                callback={languageDetailHandler}
              />
            </div>
            <div className="col-12 col-md-6 col-lg-6 add-sel">
              <LocationList
                style={dropStyle}
                title={countryName}
                callback={countryDetailHandler}
              />

              <div className="add-country-box">
                <ul>
                  {projectLocations &&
                    projectLocations.map((res, key) => {
                      return (
                        <li key={key}>
                          {" "}
                          {res.location_flag && (
                            <img
                              src={res.location_flag}
                              alt={res.location_code}
                            />
                          )}
                          {res.location_name}{" "}
                          <div
                            className="ms-2"
                            style={{ cursor: "pointer" }}
                            onClick={() => removeLocations(key)}
                          >
                            <i className="fa fa-solid fa-close"></i>{" "}
                          </div>
                        </li>
                      );
                    })}
                </ul>
              </div>
            </div>
          </div>

          <div className="add-pr-btn mt-4">
            <button
              className="btn_new_bg"
              style={{ borderRadius: "5px", minWidth: "130px", height: "50px" }}
              onClick={() => Languages()}
            >
              Next
            </button>
          </div>
        </div>
      </div>
    </section>
  );
};
