import React, { useEffect } from "react";
import { AnimatePresence, motion } from "framer-motion";
export const ProfilePop = ({ children, Open, setOpen }) => {
    useEffect(() => {
      if (Open) {
        document.body.style.overflow = "hidden";
      } else {
        document.body.style.overflow = "auto";
      }
    }, [Open]);

  return (
    <>
      <AnimatePresence mode="wait" initial={false}>
        {" "}
        {Open && (
          <div className="Pr_pop">
            <div className="overlay"></div>
            <motion.div
              className="pr_PopBody "
              {...framerSidebarBackground}
            >
              <div className="pr_content">{children}</div>
            </motion.div>
          </div>
        )}
      </AnimatePresence>
    </>
  );
};

const framerSidebarBackground = {
  initial: { transform: "scale(0) " ,transformOrigin: "top"},
  animate: { transform: "scale(1) " ,transformOrigin: "top",},
  exit: {
    transform: "scale(0) ",
    transition: { delay: 0.2 },
    transformOrigin: "top",
  },
  transition: { duration: 0.3 },
};
