import React, { useEffect } from "react";
import { Outlet } from "react-router-dom";
import axios from "axios";
import "./Global.scss";
import { GET_PROFILE, GET_PROFILE1 } from "./utils/Api";
import Loader from "./components/Loader";
import { useDispatch, useSelector } from "react-redux";
import { Header } from "./pages/HeadFoot/header/Header";
import { Footer } from "./pages/HeadFoot/footer/Footer";
import { AffiliateLogin } from "./pages/login/Login";
import { useNavigate } from "react-router-dom";

function Layout() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const TOKEN = localStorage.getItem("AFFTOKEN");
  const EMAIL = localStorage.getItem("EMAIL");
  const loading = useSelector((state) => state.loading);
  const login = useSelector((state) => state.login);

  const handleGetProfile = () => {
    let Headers = {
      headers: {
        Authorization: "Bearer " + TOKEN,
      },
    };
    axios
      .get(GET_PROFILE(EMAIL))
      .then(({ data }) => {
        if (data[0]?.email) {
          axios.get(GET_PROFILE1(data[0].email)).then((res) => {
            const user = {
              profile1: data[0],
              profile2: res?.data?.length === 0 ? res.data : res.data[0],
            };
            dispatch({ type: "LOGIN", payload: true });
            dispatch({ type: "PROFILE", payload: user });
            dispatch({ type: "NOTLOADING" });
          });
        } else {
          dispatch({ type: "LOGIN", payload: false });
          dispatch({ type: "NOTLOADING" });
          navigate("/affiliate/login");
        }
      })
      .catch(() => {
        dispatch({ type: "LOGIN", payload: false });
        dispatch({ type: "NOTLOADING" });
        navigate("/affiliate/login");
      });
  };

  useEffect(() => {
    if (!login) {
      handleGetProfile();
    }
  }, [login, EMAIL]);

  return (
    <div className="AffiliateFonts">
      {loading ? (
        <Loader />
      ) : !login ? (
        <AffiliateLogin />
      ) : (
        <div className="">
          <Header />
          <div style={{ minHeight: "80vh" }}>
            <Outlet />
          </div>
          <Footer />
        </div>
      )}
    </div>
  );
}

export default Layout;
