import axios from "axios";
import React, { useEffect, useState } from "react";
import {
  GET_AFFILIATE_REWARD,
  POST_AFFILIATE_REWARD,
  UPDATE_AFFILIATE_REWARD,
} from "../../services/constants";

const AffiliateReward = () => {
  const [reward, setReward] = useState(0);
  const [rewardData, setRewardData] = useState([]);
  const [rewardAlert, setRewardAlert] = useState(false);

  const fatch_REWAEDGET = () => {
    axios.get(GET_AFFILIATE_REWARD()).then((res) => {
      setRewardData(res.data);
      setReward(res?.data?.[0]);
    });
  };

  useEffect(() => {
    fatch_REWAEDGET();
  }, []);

  const rewardHandler = (e) => {
    e.preventDefault();
    if (reward === "") {
      fatch_REWAEDGET();
      setRewardAlert(false);
    } else if (rewardData.length == 0) {
      axios.post(POST_AFFILIATE_REWARD(reward)).then(() => {
        fatch_REWAEDGET();
        setRewardAlert("Added Successfully");
      });
    } else {
      axios.put(UPDATE_AFFILIATE_REWARD(reward)).then(() => {
        fatch_REWAEDGET();
        setRewardAlert("Updated Successfully");
      });
    }
  };

  return (
    <div>
      <div className="Box">
        <div className="cmd">
          <div>
            {" "}
            <h1 className="table_title heading-large font-bold mb-0">Rewards</h1>
          </div>
          <div> </div>
        </div>
        <div className="pop-form mt-3">
          <form
            onSubmit={(e) => {
              rewardHandler(e);
            }}
            onChange={() => {
              setRewardAlert(false);
            }}
          >
            <input
              type="number"
              placeholder="Enter Reward"
              value={reward}
              onChange={(e) => {
                setReward(e.target.value);
              }}
            ></input>
            <label id="lb">Reward</label>
            <p className="vl-msd-line" style={{ color: "green" }}>
              {" "}
              <span>{rewardAlert}</span>{" "}
            </p>
          </form>
          <button
            className="cm-btn_2"
            onClick={(e) => {
              rewardHandler(e);
            }}
          >
            Save
          </button>
        </div>
      </div>
    </div>
  );
};

export default AffiliateReward;
