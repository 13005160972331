import React, { useState, useEffect, useRef } from "react";
import "../../css/backlink-smtp.scss";
import { useSelector, useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import {
  SMTP_FIRST_MAIL,
  USER_SMTP_CHECK,
  SMTP_SEQUENCE_MAIL,
  GET_SMTP_DOMAIN_MAIL,
} from "../../../services/constants";
import axios from "axios";
import CustomizedSteppers from "../../share/components/stepper.component";
import { FaPlus, FaMinus } from "react-icons/fa6";
import Dropdown from "../../share/components/dropdown";
import * as DOMPurify from "dompurify";
import AlertPopUp from "../../share/components/alert.component";
import SendIcon from "@mui/icons-material/Send";
import DatePicker from "sassy-datepicker";
import "../../css/date-and-time-picker.scss";
import { isValidEmail } from "../../../helperJs/helper";
import {
  today,
  formatDateToYYYYMMDD,
  getDaysDifference,
  generateDate,
  convertTo24HourFormat,
  convertTo12HourFormat,
} from "../../../helperJs/date-and-time";
import CalendarMonthIcon from "@mui/icons-material/CalendarMonth";
import Tooltip from "../../share/components/tooltip";
import { useClickAway } from "react-use";

import { MdScheduleSend, MdCancel } from "react-icons/md";
import PopOver from "../../share/components/popover.component";
import SplitButton from "../../share/components/split-button";
import backIcon from "../../Assets/common/back.svg";
import { TextField } from "@mui/material";

// image
import SeqEmail from "../../Assets/common/email-seq.png";

function BacklinkSmtp() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const ref = useRef(null);
  const [email, setEmail] = useState("");
  const [error, setError] = useState({
    type: "",
    msg: "",
    show: false,
  });
  const [emailState, setEmailState] = useState({
    to: [],
    from: "",
    subject: "",
  });

  const [firSeqText, setFirSeqText] = useState("");

  const [body, setBody] = useState([]);
  const [seqMsg, setSeqMsg] = useState("");
  const [SMTPPassword, setSMTPPassword] = useState("");
  const [loadingButton, setLoadingButton] = useState(false);
  const [mailSend, setMailSend] = useState({
    first_send: false,
    sequence_send: false,
  });

  const [SMTPDomain, setSMTPDomain] = useState({
    domain: "",
    email: "",
  });

  const [Alert, setAlert] = useState({
    type: "",
    message: "",
    show: false,
  });

  const { to, from, subject } = emailState;
  const SMTPUSER = useSelector((state) => state?.smtpUser);
  const regex = /^(?:https?:\/\/)?(?:www\.)?([^/]+)/i;
  const domain = SMTPUSER?.url?.match(regex);
  const EMAIL = useSelector((state) => state?.email);

  const [smtpData, setSMTPDATA] = useState({
    data: [],
    loader: true,
    status: false,
  });

  const [mailStatus, setMailStatus] = useState({
    send: false,
    replied: false,
  });

  // Create minDate as today
  const minDate = new Date(
    today.getFullYear(),
    today.getMonth(),
    today.getDate()
  );
  const maxDate = new Date(
    today.getFullYear(),
    today.getMonth(),
    today.getDate() + 30
  );

  const [scheduleDate, setScheduleDate] = useState({
    date: null,
    show: false,
  });

  const [scheduleTime, setScheduleTime] = useState({
    time: 12,
    meridian: "PM",
  });

  useClickAway(ref, () => {
    setScheduleDate((previous) => ({
      ...previous,
      show: false,
    }));
  });

  function checkScheduleLaterDate(days) {
    var noToDate = days;
    if (scheduleDate?.date != null) {
      const selectDate = formatDateToYYYYMMDD(scheduleDate?.date);
      const afterDays = getDaysDifference(selectDate);
      noToDate += afterDays;
      return generateDate(noToDate);
    }
    return generateDate(noToDate);
  }

  const updateUserMailSeq = (data) => {
    const first = data?.[0]?.first;
    const sequence = data?.[1]?.sequence;

    setEmailState({
      to: first?.to_email,
      from: first?.email,
      subject: first?.subject,
    });

    setFirSeqText(first?.message);
    setSMTPPassword(first?.password);

    const body = sequence?.map((obj, index) => {
      return {
        text: obj?.message,
        date: getDaysDifference(obj?.days), //  date ?
        time: convertTo12HourFormat(obj.time),
        sequence: index + 1, /// seq ?
        seq_to: obj?.to_email,
        seq_subject: obj?.subject,
      };
    });

    setBody(body);
  };

  const getSMTPDATA = () => {
    if (SMTPUSER?.url == undefined || EMAIL == null) return;
    const config = {
      domain: domain?.[1],
      email: EMAIL,
    };
    axios
      .get(GET_SMTP_DOMAIN_MAIL(config))
      .then((response) => {
        if (response?.data?.length > 0) {
          updateUserMailSeq(response.data);
        }
        setSMTPDATA({ data: response.data, loader: false, status: true });
      })
      .catch(() => {
        setSMTPDATA({ data: [], loader: false, status: true });
      });
  };

  const getUSERSMTPDETAILS = () => {
    if (EMAIL != null) {
      axios.get(USER_SMTP_CHECK(EMAIL)).then((response) => {
        const data = response.data;
        if (data?.length > 0) {
          setSMTPPassword(data?.[0]?.smtp);
          setEmailState((prevState) => ({
            ...prevState,
            from: data?.[0]?.from_email,
          }));
        }
      });
    }
  };

  const updateSMTPEMAIL = () => {
    if (SMTPUSER?.url == undefined) {
      // navigate("/view-backlink-lists");
    } else if (SMTPUSER?.email?.length > 0) {
      setEmailState((prevState) => ({
        ...prevState,
        to: SMTPUSER?.email,
      }));
    }
  };

  useEffect(() => {
    getUSERSMTPDETAILS();
    updateSMTPEMAIL();
    getSMTPDATA();
  }, [EMAIL, SMTPUSER]);

  useEffect(() => {
    if (mailSend?.first_send && mailSend.sequence_send) {
      setTimeout(() => {
        setLoadingButton(false);
        navigate("/view-backlink-lists");
      }, 1000);
    }
  }, [mailSend]);

  useEffect(() => {
    const combinedArray = smtpData?.data?.length > 0 && [
      ...[smtpData?.data?.[0]?.first],
      ...smtpData?.data?.[1]?.sequence,
    ];
    if (Array.isArray(combinedArray) && combinedArray?.length > 0) {
      const hasSent = combinedArray?.some((email) => email.send === true);
      const hasReplied = combinedArray?.some((email) => email.replied === true);
      setMailStatus({
        send: hasSent,
        replied: hasReplied,
      });
    }
  }, [smtpData]);

  const handleEmailChange = (e) => {
    setEmail(e.target.value);
  };

  const sendUserFirstEmail = () => {
    if (to === "" || subject === "" || firSeqText === "" || from === "") {
      setError({
        type: "seq",
        msg: "Please enter mandatory fields",
        show: true,
      });
      return;
    } else {
      const config = {
        email: from,
        to_email: to,
        subject,
        app_email: EMAIL,
        replied: false,
        send: false,
        password: SMTPPassword,
        domain_name: domain?.[1],
        message: `
        ${DOMPurify.sanitize(firSeqText, {
          USE_PROFILES: { html: true },
        })}
        `,
      };

      const hasEmptySubject = body?.find(
        (item) => item.seq_subject === "" || item.text === ""
      );

      if (hasEmptySubject) {
        setError({
          type: `seq-${hasEmptySubject?.sequence}`,
          msg: "Please enter mandatory fields",
          show: true,
        });
        return;
      }

      const schedule = body?.map((obj) => {
        return {
          email: from,
          to_email: obj.seq_to,
          subject: obj.seq_subject,
          app_email: EMAIL,
          replied: false,
          send: false,
          password: SMTPPassword,
          message: `${DOMPurify.sanitize(obj.text, {
            USE_PROFILES: { html: true },
          })}`,
          time: obj?.time,
          days: checkScheduleLaterDate(obj.date),
        };
      });

      const config2 = {
        schedule,
        domain_name: domain?.[1],
        app_email: EMAIL,
      };

      const seqAllRight = body?.every(
        (item) => item.seq_subject != "" || item.text != ""
      );

      setError({
        type: "",
        msg: "Please enter mandatory fields",
        show: false,
      });
      setLoadingButton(true);

      axios
        .post(SMTP_FIRST_MAIL(), config)
        .then((response) => {
          setAlert({
            type: "success",
            message: response?.data?.msg || "Success",
            show: true,
          });
          setMailSend((prevState) => ({
            ...prevState,
            first_send: true,
          }));
        })
        .catch(() => {
          setLoadingButton(false);
          setAlert({
            type: "success",
            message: "Email send failed",
            show: true,
          });
        });

      if (!seqAllRight || schedule?.length === 0) return;
      axios
        .post(SMTP_SEQUENCE_MAIL(), config2)
        .then((response) => {
          setAlert({
            type: "success",
            message:
              response?.data?.msg || "Email sequence created successfully",
            show: true,
          });
          setMailSend((prevState) => ({
            ...prevState,
            sequence_send: true,
          }));
        })
        .catch(() => {
          setLoadingButton(false);
          setAlert({
            type: "success",
            message: "Email sequencing failed",
            show: true,
          });
        });
    }
  };

  // stepper
  const smtpType = SMTPUSER?.url === `${EMAIL}-1` || SMTPUSER?.url == undefined;
  const steps = smtpType ? ["Send", "Stop"] : ["Send", "Replied", "Stop"];
  const activeStep = mailStatus?.replied ? 3 : mailStatus?.send ? 1 : 0;

  const updateBodyBySequence = (sequence, newText) => {
    const index = body.findIndex((item) => item?.sequence === sequence);
    if (index !== -1) {
      const updatedBody = [...body];
      updatedBody[index] = { ...updatedBody[index], text: newText };
      setBody(updatedBody);
      setError({
        type: "",
        msg: "",
        show: false,
      });
    } else {
      const newItem = {
        text: newText,
        time: convertTo24HourFormat(scheduleTime?.time, scheduleTime?.meridian),
        date: 1,
        sequence: sequence,
        seq_to: to[0],
        seq_subject: "",
      };
      setBody((prevBody) => [...prevBody, newItem]);
    }
  };

  const updateMailToBySequence = (sequence, to) => {
    const index = body.findIndex((item) => item?.sequence === sequence);
    if (index !== -1) {
      const updatedBody = [...body];
      updatedBody[index] = { ...updatedBody[index], seq_to: to };
      setBody(updatedBody);
    }
  };

  const updateSubjBySequence = (sequence, sub) => {
    setError({
      type: "",
      msg: "",
      show: false,
    });
    const index = body.findIndex((item) => item?.sequence === sequence);
    if (index !== -1) {
      const updatedBody = [...body];
      updatedBody[index] = { ...updatedBody[index], seq_subject: sub };
      setBody(updatedBody);
    }
  };

  const scheduleSequencHandler = (type, sequence) => {
    setSeqMsg("");
    const curLen = body?.length;
    let defaultText = "";
    if (body?.length === 0 && type === "MINUS") return;
    if (type === "MINUS") {
      const newArr = body?.filter((item) => {
        return item.sequence != sequence;
      });
      setBody(newArr);
    } else {
      const curr = curLen + 1;
      if (curr > 5) {
        setSeqMsg("Limited to five sequence only");
      } else {
        updateBodyBySequence(curr, defaultText);
      }
    }
  };

  // Function to update 'to' emails
  const updateTo = (newTo) => {
    const new_email_arr =
      to?.length === 0
        ? to
        : to?.filter((email) => {
            return email != newTo;
          });
    setEmailState((prevState) => ({
      ...prevState,
      to: [...new_email_arr, newTo],
    }));
  };

  const removeTo = (newTo) => {
    if (to?.length === 1) return;
    const new_email_arr = to?.filter((email) => {
      return email != newTo;
    });
    setEmailState((prevState) => ({
      ...prevState,
      to: new_email_arr,
    }));
  };

  const handleEmailSubmit = (e) => {
    if (e.key === "Enter" && isValidEmail(email)) {
      updateTo(email);
      setEmail(""); // Clear the input field after submission
    } else if (e.key === "Enter") {
      alert("Please enter a valid email address.");
    }
  };

  // subject  change handler
  const subjectHandler = (text) => {
    setError({
      type: "",
      msg: "Please enter mandatory fields",
      show: false,
    });
    setEmailState((prevState) => ({
      ...prevState,
      subject: text,
    }));
  };

  const updateNextSequence = (sequence, date) => {
    const index = body.findIndex((item) => item.sequence === sequence);
    if (index !== -1) {
      const updatedBody = [...body];
      updatedBody[index] = { ...updatedBody[index], date: date };
      setBody(updatedBody);
    }
  };

  const domain_data = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15];

  const handleDateSchedular = (newDate) => {
    setScheduleDate((previous) => ({
      ...previous,
      date: newDate,
    }));
  };

  const handleTimeSchedular = (sequence, time) => {
    const index = body.findIndex((item) => item.sequence === sequence);
    if (index !== -1) {
      const updatedBody = [...body];
      updatedBody[index] = {
        ...updatedBody[index],
        time: convertTo24HourFormat(time?.time, time?.meridian),
      };
      setBody(updatedBody);
    }
    setScheduleTime(time);
  };

  const splitOption = [
    {
      name: "Schedule Later",
      action: () =>
        setScheduleDate((previous) => ({
          ...previous,
          show: true,
        })),
    },
  ];

  const submitSmtpPassword = () => {
    if (SMTPDomain?.domain === "") {
      setError({
        type: "smtp",
        msg: "Please enter SMTP domain",
        show: true,
      });
      return;
    } else {
      dispatch({
        type: "SMTPUSER",
        payload: { url: SMTPDomain?.domain, email: [] },
      });
    }
  };

  const handleCancelBtn = () => {
    setError({
      type: "smtp",
      msg: "",
      show: false,
    });
    dispatch({
      type: "SMTPUSER",
      payload: null,
    });
    navigate(-1);
  };

  return (
    <>
      <PopOver anchorEl={SMTPUSER?.url == undefined}>
        <div className="backlink-modal" style={{ minHeight: "auto" }}>
          <div
            className="heading-popup mb-2 d-flex align-ietms-center"
            style={{ justifyContent: "space-between" }}
          >
            <h1 className="heading-medium mb-0 font-bold">
              Contact Domain
              <Tooltip title="Enter the website domain which you want to contact(optional)." />
            </h1>
            <MdCancel
              className="point close-popup"
              style={{ height: "20px", width: "20px" }}
              onClick={() => {
                dispatch({
                  type: "SMTPUSER",
                  payload: { url: `${EMAIL}-1`, email: [] },
                });
              }}
            />
          </div>
          <TextField
            fullWidth
            id="outlined-basic"
            label="Domain"
            variant="outlined"
            placeholder="Enter Contact Domain"
            className="mt-3"
            value={SMTPDomain?.domain}
            onChange={(e) =>
              setSMTPDomain((pre) => ({ ...pre, domain: e.target.value }))
            }
          />

          {error.show && error.type === "smtp" && (
            <span className="msg-error mt-1">{error.msg}</span>
          )}
          <div className="backlink-modal-btn cmd mt-4">
            <button
              className="btn_2"
              style={{ height: "45px", width: "90px" }}
              onClick={() => handleCancelBtn()}
            >
              Cancel
            </button>
            <button
              className="btn_1"
              style={{ padding: "0.5rem 0.75rem !important" }}
              onClick={() => submitSmtpPassword()}
            >
              Submit
            </button>
          </div>
        </div>
      </PopOver>
      <PopOver anchorEl={scheduleDate.show}>
        <div className="calendar-popup">
          <div className="heading-popup mb-2">
            <h1 className="heading-medium mb-0">
              Select Date To Schedule
              <Tooltip title="Schedule a future date for this sequence mail to send. This feature allows you to plan and organize your sequence by specifying when you want to send sequence." />
            </h1>
            <MdCancel
              className="point close-popup"
              onClick={() => {
                setScheduleDate((previous) => ({
                  ...previous,
                  show: false,
                }));
              }}
            />
          </div>
          <DatePicker
            onChange={handleDateSchedular}
            weekStartsFrom="Monday"
            value={scheduleDate?.date || today}
            minDate={minDate}
            maxDate={maxDate}
          />
          <div className="d-flex align-items-center justify-content-center">
            <button className="btn_1 mt-3" onClick={() => sendUserFirstEmail()}>
              Schedule Later
            </button>
          </div>
        </div>
      </PopOver>
      <AlertPopUp config={Alert} handleClose={setAlert} />
      <div className="Domain_view smpt-container">
        <button
          className="icon-btn large-btn my-3"
          onClick={() => navigate(-1)}
        >
          <img src={backIcon} alt="back button" />
        </button>
        <div className="row smtp-email-contain">
          <div className="col-12 col-md-12 col-lg-4 smtp-section-1">
            <div className="smtp-domain-sec">
              <div className="p-1 my-4 ms-4">
                {SMTPUSER?.url === `${EMAIL}-1` ||
                SMTPUSER?.url == undefined ? (
                  <h1 className="heading-medium font-bold mb-0"></h1>
                ) : (
                  <h1 className="heading-medium font-bold mb-0">
                    Domain :
                    <span
                      className="heading-small ms-2"
                      style={{ color: "#365486" }}
                    >
                      {domain?.[1]}
                    </span>
                  </h1>
                )}
              </div>
              <CustomizedSteppers {...{ steps, activeStep }} />
            </div>
            <img
              src={SeqEmail}
              alt="sequemce-schedular"
              className="sequence-mail-img"
            />
          </div>
          <div className="col-12 col-md-12 col-lg-8 smtp-section-2">
            <div className="default-sequence smtp-scrollbar">
              <div className="smpt-box">
                <div className="d-flex smtp-elm my-2">
                  <input placeholder={from} value={from} readOnly />
                </div>
                <div className="d-flex smtp-elm my-2 receiver-address">
                  <div className="email-list">
                    {to?.map((email) => {
                      return (
                        <span className="email-item px-3 py-1" key={email}>
                          {email}
                          <MdCancel
                            className="ms-2"
                            onClick={() => removeTo(email)}
                          />
                        </span>
                      );
                    })}
                    <span className="px-3 py-1">
                      <input
                        placeholder={to?.length === 0 ? "To" : ""}
                        value={email}
                        onChange={handleEmailChange}
                        onKeyDown={handleEmailSubmit}
                        readOnly={smtpData?.data?.length > 0}
                      />
                    </span>
                  </div>
                </div>
                <div className="d-flex smtp-elm my-2">
                  <input
                    placeholder="Subject"
                    value={subject}
                    onChange={(e) => subjectHandler(e.target.value)}
                    readOnly={smtpData?.data?.length > 0}
                  />
                </div>

                <div className="mt-3">
                  <textarea
                    placeholder="Type here..."
                    value={firSeqText}
                    onChange={(e) => setFirSeqText(e.target.value)}
                    readOnly={smtpData?.data?.length > 0}
                  ></textarea>
                  {error?.show && error.type === "seq" && (
                    <span className="msg-error mt-2">{error?.msg}</span>
                  )}
                </div>
              </div>
              {body?.map(
                (
                  { sequence, text, date, time, seq_to, seq_subject },
                  index
                ) => {
                  return (
                    <React.Fragment>
                      <div className="smtp-section-2">
                        <div className="default-sequence">
                          <label className="heading-medium mb-2 ms-2">
                            Mail Sequence - <b>{sequence}</b>
                          </label>
                          <div className="smpt-box">
                            <div className="smtp-elm my-2">
                              <input placeholder={from} value={from} readOnly />
                            </div>
                            <div className="smtp-elm my-2 receiver-address">
                              <div className="mt-3">
                                {to?.map((email) => {
                                  return (
                                    <span
                                      style={{ cursor: "pointer" }}
                                      className={
                                        seq_to === email
                                          ? "sel-email-item px-3 py-1 my-2"
                                          : "email-item px-3 py-1 my-2"
                                      }
                                      key={email}
                                      onClick={() =>
                                        updateMailToBySequence(sequence, email)
                                      }
                                    >
                                      {email}
                                    </span>
                                  );
                                })}
                              </div>

                              <div className="email-list mt-3">
                                <span
                                  style={{ cursor: "pointer" }}
                                  className="email-item px-3 py-1"
                                  key={seq_to}
                                >
                                  {seq_to}
                                </span>
                              </div>
                            </div>
                            <div className="smtp-elm my-2">
                              <input
                                placeholder="Subject"
                                value={seq_subject}
                                readOnly={smtpData?.data?.length > 0}
                                onChange={(e) =>
                                  updateSubjBySequence(sequence, e.target.value)
                                }
                              />
                            </div>
                            <div className="mt-3">
                              <textarea
                                placeholder="Type here..."
                                value={text}
                                readOnly={smtpData?.data?.length > 0}
                                onChange={(e) =>
                                  updateBodyBySequence(sequence, e.target.value)
                                }
                              ></textarea>
                              {error?.show &&
                                error.type === `seq-${sequence}` && (
                                  <span className="msg-error mt-2">
                                    {error?.msg}
                                  </span>
                                )}
                            </div>
                            <div className="mt-3 schedule-day-time">
                              <div className="d-flex align-items-center">
                                {smtpData?.data?.length > 0 ? (
                                  <label className="heading-medium">
                                    This mail sequence is schedule in {date}{" "}
                                    days
                                  </label>
                                ) : (
                                  <>
                                    <label className="heading-medium me-3">
                                      Schedule mail in
                                    </label>
                                    <Dropdown
                                      data={domain_data}
                                      title={date || "Day"}
                                      searchType={true}
                                      style={{
                                        textTransform: "none",
                                        boxShadow: "none",
                                        border: "1px solid #80808042",
                                        padding: "0.75rem",
                                        width: "70px",
                                      }}
                                      callback={(e) =>
                                        updateNextSequence(sequence, e)
                                      }
                                    />
                                    <label className="heading-medium ms-2">
                                      days
                                    </label>
                                  </>
                                )}
                              </div>
                              {/* <div className="d-flex align-items-center">
                          {smtpData?.data?.length > 0 ? (
                            <label className="heading-medium me-3">
                              Schedule mail for {time}
                            </label>
                          ) : (
                            <>
                              <label className="heading-medium me-3">
                                Schedule mail for
                              </label>

                              <Dropdown
                                data={[1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12]}
                                title={scheduleTime?.time || "select Hours"}
                                searchType={true}
                                style={{
                                  textTransform: "none",
                                  boxShadow: "none",
                                  border: "1px solid #80808042",
                                  padding: "0.75rem",
                                  width: "70px",
                                }}
                                callback={(e) =>
                                  handleTimeSchedular(sequence, {
                                    time: e,
                                    meridian: scheduleTime?.meridian,
                                  })
                                }
                              />
                              <Dropdown
                                data={["AM", "PM"]}
                                title={
                                  scheduleTime?.meridian || "Select meridian"
                                }
                                searchType={true}
                                style={{
                                  textTransform: "none",
                                  boxShadow: "none",
                                  border: "1px solid #80808042",
                                  padding: "0.75rem",
                                  width: "70px",
                                }}
                                callback={(e) =>
                                  handleTimeSchedular(sequence, {
                                    time: scheduleTime?.time,
                                    meridian: e,
                                  })
                                }
                              />
                            </>
                          )}
                        </div> */}
                            </div>
                          </div>
                        </div>
                      </div>
                    </React.Fragment>
                  );
                }
              )}

              <div className="d-flex justify-content-between align-items-center">
                <div
                  className={
                    seqMsg && body?.length >= 5
                      ? "mb-0 pb-0 py-2 px-1 schedule-sequence h-100 d-flex align-items-center justify-content-between"
                      : "mb-0 py-2 px-1 schedule-sequence h-100 d-flex align-items-center justify-content-between"
                  }
                >
                  <span className="heading-medium me-2">Schedule Sequence</span>
                  <span
                    className={`heading-small font-bold px-2 py-1 schedule-sequence-btn ms-auto ${
                      body?.length === 5 ? "schedule-btn-disable" : ""
                    }`}
                  >
                    <FaMinus
                      onClick={() =>
                        scheduleSequencHandler("MINUS", body?.length)
                      }
                    />
                    <span className="px-2">{body?.length}</span>
                    <FaPlus
                      onClick={() =>
                        scheduleSequencHandler("PLUS", body?.length)
                      }
                    />
                  </span>
                </div>

                <div className="split-button my-3">
                  {smtpData?.data?.length > 0 ? null : (
                    <SplitButton
                      current={{
                        name: "Send",
                        action: () => sendUserFirstEmail(),
                      }}
                      options={splitOption}
                      loader={loadingButton}
                    />
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default BacklinkSmtp;
