import React, { useState, useEffect } from "react";
import { Paper } from "@mui/material";
import Divider from "@mui/material/Divider";
import Chip from "@mui/material/Chip";
import { useDispatch } from "react-redux";
import {
  delete_Project,
  GET_PROJECT_SEC_DATA,
} from "../../../services/constants";
import axios from "axios";
import AreYousure from "../../share/card-layout/AreYousure";
import ProjectTable from "./project-table";
import { ZustandStore } from "../../../store/zustand-store";

const suggestion = [
  {
    title: "Site Audit",
    description:
      "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum.",
  },
  {
    title: "Rank Tracking",
    description:
      "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum.",
  },
  {
    title: "Site Overview",
    description:
      "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum.",
  },
];

const Projects = () => {
  const dispatch = useDispatch();
  const [showDeleteAlert, setShowDeleteAlert] = useState({
    show: false,
    id: "",
  });
  const { emptyKeywordData, emptyAllLocData, project_section } = ZustandStore(
    (state) => state
  );

  useEffect(() => {
    const email = localStorage.getItem("admin_mail");
    if (
      email != null &&
      project_section?.data?.length === 0 &&
      !project_section?.error
    ) {
      axios
        .get(GET_PROJECT_SEC_DATA(email))
        .then((response) => {
          if (response?.data?.data) {
            ZustandStore.setState({
              project_section: {
                data: response.data?.data || [],
                error: false,
                loading: false,
              },
            });
          } else {
            ZustandStore.setState({
              project_section: {
                data: [],
                error: true,
                loading: false,
              },
            });
          }
        })
        .catch(() => {
          ZustandStore.setState({
            project_section: {
              data: [],
              error: true,
              loading: false,
            },
          });
        });
    }
  }, [project_section]);

  const deleteProject = () => {
    const id = showDeleteAlert?.id;
    axios.delete(delete_Project(id)).then(() => {
      sessionStorage.removeItem("LAST_PRPJECT_SELECTED");
      emptyAllLocData();
      emptyKeywordData();
      setShowDeleteAlert({ show: false, id: "" });
      dispatch({
        type: "USERALLPROJECTDETAILS",
        payload: [],
      });
      dispatch({ type: "WEBSITEURL", payload: null });
      dispatch({ type: "CURRENTLOCATIONCODE", payload: null });
      dispatch({ type: "CURRENTLOCATION", payload: null });
      dispatch({ type: "LOADING" });
    });
  };

  const handleProjectDelete = (res) => {
    if (res) {
      setShowDeleteAlert((pre) => ({
        ...pre,
        show: false,
      }));
      deleteProject();
    } else {
      setShowDeleteAlert({ show: false, id: "" });
    }
  };

  return (
    <main className="main-pd project-section">
      <h1 className="project-sec-heading">Project Overview</h1>
      {/* <p className="project-sec-sub-heading">
        Lorem Ipsum is simply dummy text of the printing
      </p> */}
      {/* <Paper className="project-suggest my-3">
        <h3 className="heading-medium mb-2">Suggestions</h3>
        <Divider />
        <ul>
          {suggestion?.map((item) => {
            return (
              <li key={`${item?.title}/${item?.description}`}>
                <Chip
                  label={item?.title}
                  sx={{ backgroundColor: "#E956551A" }}
                />
                <p className="mt-3">{item?.description}</p>
              </li>
            );
          })}
        </ul>
      </Paper> */}
      <ProjectTable {...{ setShowDeleteAlert }} />
      {/* // Alerts */}
      {showDeleteAlert?.show ? (
        <AreYousure
          callback={handleProjectDelete}
          title={"You will not able to recover this Project !"}
        />
      ) : null}
    </main>
  );
};

export default Projects;
