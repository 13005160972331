import React from "react";
import "./term.scss";
import { Footer } from "../HeadFoot/footer/Footer";
import { Header } from "../HeadFoot/header/Header";
import Term_header from "../HeadFoot/header/Term_header";

function TermAndCondition() {
  const data = [
    {
      content: [
        "We reserve the right to make changes to our affiliate program terms and conditions at any time we want, with no prior notice. These changes can apply to rules, program fees, commission payout, method of payment, and more. ",
      ],
    },
    {
      content: [
        "Once you register as an RanksPro.io affiliate program member, you will receive a unique URL that you can use for promotion, bring referral traffic, and earn a commission. ",
      ],
    },
    {
      content: [
        "You are prohibited to use the referral link in any variation in your URLs, and social media profile links.",
      ],
    },
    {
      content: [
        "You are forbidden to use the referral link in any paid advertising campaign that includes but is not limited to, Google Ads, Facebook Ads, and other sponsored advertisement programs. You are also prohibited from using the RanksPro.io brand name in Google PPC, or any other Ads program campaign. ",
      ],
    },
    {
      content: [
        "You are forbidden to advertise the referral link using the RanksPro.io brand name as a keyword. Such restricted keywords include RanksPro.io referral, RanksPro.io discounts, RanksPro.io coupon, RanksPro.io sale, etc. ",
      ],
    },
    {
      content: [
        "You are prohibited from registering yourself through your referral link, i.e. you cannot earn from the RanksPro.io affiliate program from your own payments. You can earn commission only by bringing new users who subscribe. ",
      ],
    },
    {
      content: [
        "Our SEO affiliate program is not valid and is not allowed for coupon aggregate and coupon distribution sites and platforms. ",
      ],
    },
    {
      content: [
        "You will earn 20% of the commission for each non-coupon affiliate referral subscription sale done through a properly placed affiliate link. ",
      ],
    },
    {
      content: [
        "The commission payout is done on the 15th date of every month, with a threshold of a minimum of U.S. $10 earnings for the member. The payment is done via PayPal, which can take up to 5 days for processing. ",
      ],
    },
    {
      content: [
        "We reserve the right to refuse your participation in the affiliate program if we deem that your site or platform violates our terms of service. ",
      ],
    },
    {
      content: [
        "We will also terminate the affiliate program membership if you use or list coupon codes or discount codes with the referral link, which is not generated from RanksPro.io. We will also terminate the membership if you promote or use ‘coupon stacking’ websites.",
      ],
    },
    {
      content: [
        "We are open to any partner proposal consideration when sent directly to us. ",
      ],
    },
    {
      content: [
        "The affiliate program agreement comes into effect when you accept all of our terms of service and conditions. This agreement will end when terminated or ended by either party. ",
      ],
    },
  ];

  return (
    <>
    <Term_header />
      <div className="privacy-container">
        <h1>Terms and Conditions</h1>
        {data?.map((item , index) => {
          return (
            <ul>
              <h2 className="list-heading">{item.name}</h2>
              {item?.content?.map((content  ) => {
                return <li className="list-item">{index + 1}. { content}</li>;
              })}
            </ul>
          );
        })}
      </div>
      <Footer />
    </>
  );
}

export default TermAndCondition;
