import React, { useEffect, useRef, useState, useCallback } from "react";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";

const Dropdown = ({ targetName, data, action, title, selectedData, icon }) => {
  const [show, setShow] = useState(false);

  const extraDataArray = selectedData?.[0]?.extraData || [];
  const selectedDataArray = selectedData?.[0]?.data || [];
  const selectedDataTrimmed = selectedDataArray?.map((word) =>
    word.trimStart()
  );
  const extraDataTrimmed = extraDataArray?.map((word) => word.trimStart());
  // Combine data and selectedData, removing duplicates
  const combinedData = Array.from(new Set([...data, ...extraDataTrimmed]));

  const handleToggle = useCallback(() => {
    setShow((prevShow) => !prevShow);
  }, []);

  const handleCheckboxChange = useCallback(
    (value) => {
      action(targetName, value);
    },
    [action, targetName]
  );

  const filterBoxRef = useRef(null);
  const filterInputRef = useRef(null);

  const handleOutsideClick = useCallback((event) => {
    if (
      filterBoxRef.current &&
      filterInputRef.current &&
      !filterBoxRef.current.contains(event.target) &&
      !filterInputRef.current.contains(event.target)
    ) {
      setShow(false);
    }
  }, []);

  useEffect(() => {
    document.addEventListener("click", handleOutsideClick);
    return () => {
      document.removeEventListener("click", handleOutsideClick);
    };
  }, [handleOutsideClick]);

  return (
    <div className="dropContainer">
      <div className="showInput">
        <div className="dropBox">
          {icon && icon}
          <input
            placeholder={
              selectedDataTrimmed.length > 0
                ? `${selectedDataTrimmed.length} Selected`
                : title
            }
            readOnly
            onClick={handleToggle}
            className="shadow-none border-none pe-1"
            ref={filterInputRef}
          />
          <ArrowDropDownIcon />
        </div>
        {show && (
          <div className="lists" ref={filterBoxRef}>
            <ul>
              {combinedData?.map((res, index) => (
                <li key={index} onClick={() => handleCheckboxChange(res)}>
                  <div className="listRap">
                    <span>
                      <input
                        type="checkbox"
                        readOnly
                        checked={selectedDataTrimmed?.includes(res)}
                      />
                    </span>
                    {res}
                  </div>
                </li>
              ))}
            </ul>
          </div>
        )}
      </div>
    </div>
  );
};

export default Dropdown;
