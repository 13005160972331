import React, { useState, useEffect } from "react";
import "../css/index.scss";
import "../css/rankTracking.css";
import "../css/loader.css";
import { useDispatch, useSelector } from "react-redux";
import ProjectList from "../share/searchBox/ProjectList";
import axios from "axios";
import {
  DOMAINOVERVIEW_GET,
  GET_AUDIT_LIMIT,
  GET_SITE_AUDIT,
  POST_SITEAUDIT_LMT,
  SITE_AUDIT_UPDATE,
  delete_Project,
  get_Plans_Details,
  get_backlink_data,
  get_backlinktotallmtGet,
  get_domainauthority,
  post_backlink_data,
  post_backlinktotallmt,
  post_user_backlinklimit,
  update_backlinktotallmtupdate,
} from "../../services/constants";
import AreYousure from "../share/card-layout/AreYousure";
import GoogleAuth from "../../confrigration/googleAuth";
import { useNavigate, Link } from "react-router-dom";
import SingleLineChart from "../share/charts/line-chart";
import { Store } from "../../Affiliate/utils/STORE";
import deleteIcon from "../Assets/rank-tracking-assets/delete.svg";
import addIcon from "../Assets/rank-tracking-assets/add.svg";
import bannerImg from "../Assets/home/banner-img-dash.png";
import comminSoon from "../Assets/common/comming-soon.png";

import SubUserDetails from "../share/components/sub-user.component";
import BarChart from "../share/charts/bar-chart";
import StackedBarChartMulti from "../share/charts/stackbarChatMulti";
import TrafficChart from "../share/charts/TrafficChart";
import { ReactComponent as RankIcon } from "../Assets/home/keyword-rank-icon.svg";
import ReactApexChart from "react-apexcharts";
import planBgIcon from "../Assets/home/plan-bg.svg";
import BlurCard from "../share/card-layout/blur-card";
import { Player } from "@lottiefiles/react-lottie-player";
import spinLoaderAnimation from "../Assets/spin-loader.json";

import googleImg from "../Assets/google-analytics/google.png";
import chart1 from "../Assets/home/chart-1.png";
import chart2 from "../Assets/home/chart-2.png";
import chart3 from "../Assets/home/chart-3.png";
import chart4 from "../Assets/home/chart-4.png";

import { ZustandStore } from "../../store/zustand-store";
import countryList from "../dataFiles/country";
import greenTick from "../Assets/home/green-tick.svg";
import useDebouncedFunction from "../../helperJs/debounce";
import {
  formatNumberWithSymbols,
  handleNaNAndUndefined,
} from "../../helperJs/helper";
import RankTable from "../../helperJs/rank-table";

export const Home = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  // *updating rank table and keyword data
  const table_data_update = RankTable();
  const RankMovedup = useSelector((state) => state.rankmovedup);
  const RankMovedDown = useSelector((state) => state.rankmoveddown);
  const crawllimit = useSelector((state) => state.crawllimit);
  const crawldomainlimit = useSelector((state) => state.crawldomainlimit);
  const websiteUrl = useSelector((state) => state.websiteurl);
  const userAnalyticLogin = useSelector((state) => state.userAnalyticLogin);
  const backlinklimits = useSelector((state) => state.backlinkLimit);
  const showLoader = useSelector((state) => state.showloader);
  const { RankUpPercent, RankDownPercent } = Store((state) => state);
  const {
    selected_domain,
    site_overview_data,
    backlink_data_hm,
    domain_auth_hm,
    site_audit_hm,
    plan_data_hm,
    plan_details,
    country_Language_Data,
    emptyKeywordData,
    emptyAllLocData,
  } = ZustandStore((state) => state);
  const email = useSelector((state) => state.email);
  const domainviewlimit = useSelector((state) => state.domainviewlimit);

  const chartData = {
    series: [
      RankDownPercent?.top3 || 0,
      RankDownPercent?.top10 || 0,
      RankDownPercent?.top100 || 0,
      RankDownPercent?.noRank || 0,
    ],
    options: {
      chart: {
        height: 390,
        type: "radialBar",
      },
      tooltip: {
        shared: true,
        hideEmptySeries: false,
      },
      plotOptions: {
        radialBar: {
          offsetY: 0,
          startAngle: 0,
          endAngle: 270,
          hollow: {
            margin: 5,
            size: "40%",
            background: "transparent",
            image: undefined,
          },
          dataLabels: {
            name: {
              show: true,
              fontSize: "18px",
              fontWeight: 600,
              color: undefined,
              offsetY: -10,
              formatter: function (seriesName) {
                const parts = seriesName.split(":");
                return parts[0].replace(/&nbsp;/g, "");
              },
            },
            value: {
              show: true,
              fontSize: "16px",
              fontWeight: 600,
              color: undefined,
              offsetY: -5,
              formatter: function (val) {
                return Math.round(val * RankDownPercent?.total_keyword) / 100;
              },
            },
            total: {
              show: true,
              label: "Total",
              formatter: function () {
                return RankDownPercent?.total_keyword;
              },
            },
          },
        },
      },
      colors: ["#F3A344", "#3BA1C5", "#365486", "#E95655"],
      labels: [
        `Top 3&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;: <span>${
          RankUpPercent?.top3?.length || 0
        }</span>`,
        `Top 10&nbsp;&nbsp;&nbsp;&nbsp;: <span>${
          RankUpPercent?.top10?.length || 0
        }</span>`,
        `Top 100&nbsp;&nbsp;: <span>${
          RankUpPercent?.top100?.length || 0
        }</span>`,
        `No Rank&nbsp;: <span>${RankUpPercent?.noRank?.length || 0}</span>`,
      ],
      legend: {
        show: true,
        floating: true,
        fontSize: "16px",
        position: "left",
        offsetX: 160,
        offsetY: 15,
        labels: {
          useSeriesColors: true,
        },
        markers: {
          size: 0,
        },
        formatter: function (val) {
          return val;
        },
        itemMargin: {
          vertical: 3,
        },
      },
      responsive: [
        {
          breakpoint: 480,
          options: {
            legend: {
              show: true,
            },
          },
        },
      ],
    },
  };

  const [hasCalledApi, setHasCalledApi] = useState(false);
  const [plansDetails, setPlansDetails] = useState([]);
  const [overviewLoader, setOverviewLoader] = useState(true);
  const [auditLoader, setAuditLoader] = useState(true);
  const [backlinkLoader, setBacklinkLoader] = useState(true);
  const [overviewData, setOverviewData] = useState([]);
  const [backlinkData, setBacklinkData] = useState([]);
  const [auditData, setAuditData] = useState([]);
  const [Seo_descovered, setSeo_Descovered] = useState();
  const [domainAuthority, setDomainAuthority] = useState();
  const [domainviewlmtget, setDomainviewlmtget] = useState([]);
  // date after 7 days
  const today = new Date();
  const sevenDaysLater = new Date(today.setDate(today.getDate() + 7));
  const sevenDaysLaterDate = sevenDaysLater.getDate();

  const [ETVData, setETVData] = useState({
    label: [],
    all_data: [],
    data: [],
  });
  const [barPosData, setBarPosData] = useState({
    p1to3: [],
    p4to10: [],
    p11to50: [],
    p51to100: [],
  });

  // usercurrentlocation get index 0 for siteoverview data
  const usercurrentprojectlocation = useSelector(
    (state) => state.usercurrentprojectlocation
  );

  const months = [
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "May",
    "Jun",
    "Jul",
    "Aug",
    "Sep",
    "Oct",
    "Nov",
    "Dec",
  ];

  const fatch_domainviewlmtget = () => {
    axios
      .get(`https://app.rankspro.io/api/user/domainviewlmtget/?email=${email}`)
      .then((res) => {
        setDomainviewlmtget(res.data);
      });
  };

  const postBacklinkLimit = (data) => {
    if (usercurrentprojectlocation?.length === 0) return;
    const curr_proj = usercurrentprojectlocation?.[0];
    const domain = curr_proj?.weburl;
    let post_config = {
      email: email,
      domain_name: domain,
      bakclinklmt: data?.length,
    };
    const rowLmt = backlinklimits?.backlinks_rows_limit;
    axios.post(post_user_backlinklimit(), post_config);
    axios.get(get_backlinktotallmtGet(email)).then((response) => {
      if (response.data?.length > 0) {
        axios.put(
          update_backlinktotallmtupdate(
            response?.data[response?.data?.length - 1].id
          ),
          {
            email: email,
            backlinktotallmt:
              response?.data[response?.data?.length - 1].backlinktotallmt -
              data?.length,
          }
        );
      } else if (response.data?.length === 0) {
        axios.post(post_backlinktotallmt(), {
          email: email,
          backlinktotallmt: rowLmt - data?.length,
        });
      }
    });
  };

  const fetchBacklinkData = () => {
    if (usercurrentprojectlocation?.length === 0) return;
    const curr_proj = usercurrentprojectlocation?.[0];
    const domain = curr_proj?.weburl;
    const config = {
      email: email,
      domain: domain,
      include_subdomains: true,
    };
    if (
      config?.domain === "" ||
      config?.domain === undefined ||
      config?.domain === null
    )
      return;
    if (
      selected_domain === domain &&
      backlink_data_hm?.length != 0 &&
      domain_auth_hm?.length != 0
    ) {
      setBacklinkData(backlink_data_hm);
      setDomainAuthority(domain_auth_hm);
      setBacklinkLoader(false);
    } else {
      setBacklinkLoader(true);
      axios.get(get_backlink_data(config)).then((response) => {
        if (response.data?.length > 0) {
          const data = response.data?.[0];
          setBacklinkLoader(false);
          setBacklinkData(data);
          ZustandStore.setState({
            backlink_data_hm: data,
          });
        } else {
          axios
            .get(get_backlinktotallmtGet(email))
            .then((response) => {
              const userLmt =
                response.data[response.data?.length - 1]?.backlinktotallmt;
              if (response.data?.length > 0) {
                if (userLmt <= 0) {
                  // setLimitAlert(
                  //   "Your limit for Backlinks search have been exceeded. Please Upgrade"
                  // );
                  setBacklinkLoader(false);
                  setBacklinkData([]);
                  ZustandStore.setState({
                    backlink_data_hm: [],
                  });
                  return;
                }
              }
              axios
                .post(post_backlink_data(config))
                .then((response) => {
                  if (response.data?.length === 0) {
                    setBacklinkLoader(false);
                    setBacklinkData([]);
                    ZustandStore.setState({
                      backlink_data_hm: [],
                    });
                    // setError(
                    //   "Sorry, No Data Found For This Domain. Please Check The Domain."
                    // );
                  } else {
                    setBacklinkData(response.data[0]);
                    const backlink_data = response.data[0]?.data;
                    postBacklinkLimit(backlink_data, response.data);
                    setBacklinkLoader(false);
                    ZustandStore.setState({
                      backlink_data_hm: response.data[0],
                    });
                  }
                })
                .catch(() => {
                  setBacklinkLoader(false);
                  setBacklinkData([]);
                  ZustandStore.setState({
                    backlink_data_hm: [],
                  });
                  // setError(
                  //   "Sorry, No Data Found For This Domain. Please Check The Domain."
                  // );
                });
            })
            .catch(() => {
              setBacklinkLoader(false);
              setBacklinkData([]);
              ZustandStore.setState({
                backlink_data_hm: [],
              });
            });
        }
        axios.get(get_domainauthority(config.domain)).then((response) => {
          setDomainAuthority(response.data);
          ZustandStore.setState({
            domain_auth_hm: response.data,
          });
          setBacklinkLoader(false);
        });
      });
    }
  };

  const handleSiteOverviewData = (data) => {
    const etvArray = [];
    const etvLabel = [];
    const etvAll = [];
    const P1to3 = [];
    const P4to10 = [];
    const P11to50 = [];
    const P51to100 = [];
    data?.metrics &&
      data?.metrics.map((res) => {
        etvLabel.push(months[res.month - 1] + " " + res.year);
        etvArray.push(res.metrics.organic?.etv);
        etvAll.push(res.metrics.paid?.etv);
        P1to3.push(res.metrics.organic?.pos_1 + res.metrics.organic?.pos_2_3);
        P4to10.push(res.metrics.organic?.pos_4_10);
        P11to50.push(
          res.metrics.organic?.pos_11_20 +
            res.metrics.organic?.pos_21_30 +
            res.metrics.organic?.pos_31_40 +
            res.metrics.organic?.pos_41_50
        );
        P51to100.push(
          res.metrics.organic?.pos_51_60 +
            res.metrics.organic?.pos_61_70 +
            res.metrics.organic?.pos_71_80 +
            res.metrics.organic?.pos_81_90 +
            res.metrics.organic?.pos_91_100
        );
      });
    setETVData({
      label: etvLabel,
      all_data: etvAll,
      data: etvArray,
    });
    setBarPosData({
      p1to3: P1to3,
      p4to10: P4to10,
      p11to50: P11to50,
      p51to100: P51to100,
    });
    setOverviewLoader(false);
  };

  const getCountry = () => {
    const country = country_Language_Data?.data?.filter((country) => {
      return (
        country.location_name ===
          usercurrentprojectlocation?.[0]?.location_name &&
        country?.location_type === "Country"
      );
    });
    return country?.[0]?.available_languages?.[0]?.language_name;
  };

  const fetchSiteOverviewData = (config) => {
    if (
      config?.domain === "" ||
      config?.domain === undefined ||
      config?.domain === null
    )
      return;
    const countryLang = getCountry();
    // if (overviewData?.length > 0) return;
    if (selected_domain === config?.domain && site_overview_data?.length != 0) {
      setOverviewData(site_overview_data);
      handleSiteOverviewData(site_overview_data);
    } else if (
      domainviewlmtget[0]?.limit >= domainviewlimit &&
      domainviewlmtget.length !== 0
    ) {
      // setValAlert(
      //   "Your credits are used, please subscribe to the plan for increased limit."
      // );
    } else {
      setOverviewLoader(true);
      const domainviewlimitHandler = () => {
        if (domainviewlmtget.length === 0) {
          const json_domainviewlmt = {
            email: email,
            domainoverviewlmt: 1,
          };
          axios
            .post(
              `https://app.rankspro.io/api/user/domainviewlmt/`,
              json_domainviewlmt
            )
            .then((res) => {
              fatch_domainviewlmtget();
            });
        } else {
          const json_updatedomainviewlmt = {
            email: email,
            domainoverviewlmt: domainviewlmtget[0].limit + 1,
          };
          axios
            .put(
              `https://app.rankspro.io/api/user/domainviewlmtupdate/${domainviewlmtget[0].id}/`,
              json_updatedomainviewlmt
            )
            .then((res) => {
              fatch_domainviewlmtget();
            });
        }
      };

      const json_getDomain = {
        domainName: config?.domain,
        domainCountry: config?.loc_name,
        domainLanuage: countryLang,
      };

      ZustandStore.setState({
        selected_domain: config?.domain,
      });

      axios(DOMAINOVERVIEW_GET(json_getDomain))
        .then((res) => {
          if (res.data.length !== 0) {
            domainviewlimitHandler();
            const data = res.data[0];
            setOverviewData(data);
            handleSiteOverviewData(data);
            ZustandStore.setState({
              site_overview_data: data,
            });
          } else {
            setOverviewLoader(false);
            setOverviewData([]);
            ZustandStore.setState({
              selected_domain: "",
              site_overview_data: [],
            });
          }
        })
        .catch(() => {
          setOverviewLoader(false);
          setOverviewData([]);
          ZustandStore.setState({
            selected_domain: "",
            site_overview_data: [],
          });
        });
    }
  };

  const debouncedProjectFunction = useDebouncedFunction(() => {
    if (usercurrentprojectlocation?.length > 0 && !hasCalledApi) {
      setHasCalledApi(true); // Set the state to true after the API calls
      const curr_proj = usercurrentprojectlocation?.[0];
      const config = {
        domain: curr_proj?.weburl,
        loc_name: curr_proj?.location_name,
        loc_code: curr_proj?.location_code,
      };
      fetchSiteOverviewData(config);
      fetchBacklinkData();
      siteAuditData();
    }
  }, 100); //500 ms debounce delay

  const siteAuditData = () => {
    if (usercurrentprojectlocation?.length === 0) return;
    const curr_proj = usercurrentprojectlocation?.[0];
    const config = {
      domain: curr_proj?.weburl || "",
      loc_name: curr_proj?.location_name || "",
      loc_code: curr_proj?.location_code || "",
    };
    if (
      config?.domain === "" ||
      config?.domain === undefined ||
      config?.domain === null
    )
      return;

    const item = {
      email: email,
      domain: curr_proj?.weburl,
      max_crawl_page: crawllimit,
    };

    ZustandStore.setState({
      selected_domain: item?.domain,
    });

    if (selected_domain === item?.domain && site_audit_hm?.length != 0) {
      setAuditData(site_audit_hm);
      setAuditLoader(false);
    } else {
      setAuditData([]);
      setAuditLoader(true);
      setSeo_Descovered();
      axios.get(GET_AUDIT_LIMIT(item)).then((res) => {
        axios
          .get(GET_SITE_AUDIT(item))
          .then((response) => {
            const audit_data = response.data;
            if (audit_data.length !== 0) {
              // setPostAlert(false);
              setAuditData(
                audit_data[audit_data.length - 1]?.data?.[0]?.result
              );
              setAuditLoader(false);
              const queueDataValue =
                audit_data[audit_data.length - 1]?.data[0]?.result[0]
                  ?.crawl_status.pages_in_queue || 0;

              if (queueDataValue === 0) {
                ZustandStore.setState({
                  site_audit_hm:
                    audit_data[audit_data.length - 1]?.data?.[0]?.result,
                });
              }
              let remainLimit = 0;
              audit_data.forEach((audit_res, index) => {
                remainLimit += audit_res.limit + audit_res.queue;
              });
              // updated crawl limit
              if (res.data[0].domain === false) {
                const POST_LMT = {
                  email: email,
                  domain: config?.domain,
                  siteauditlmt: crawllimit,
                  date: sevenDaysLaterDate,
                };
                axios.post(POST_SITEAUDIT_LMT(), POST_LMT);
              } else {
                const POST_LMT = {
                  email: email,
                  domain: config?.domain,
                  limit: crawllimit - remainLimit,
                  date: res.data[0]?.date,
                };
                axios.put(SITE_AUDIT_UPDATE(POST_LMT));
              }
            } else {
              if (res.data[0]?.domain_limit >= crawldomainlimit) {
                // setPostAlert("Your Domain limit is Exceedded");
              }
              setAuditData([]);
              setSeo_Descovered();
              setAuditLoader(false);
              ZustandStore.setState({
                site_audit_hm: [],
              });
            }
          })
          .catch(() => {
            setAuditData([]);
            setAuditLoader(false);
            setSeo_Descovered();
          });
      });
    }
  };

  useEffect(() => {
    // Reset the state if dependencies change to the initial state
    if (usercurrentprojectlocation?.length === 0) {
      setHasCalledApi(false);
    }
    debouncedProjectFunction();
  }, [usercurrentprojectlocation, hasCalledApi, debouncedProjectFunction]);

  // useselector variable
  const userselectedprojectallid = useSelector(
    (state) => state.userselectedprojectallid
  );

  // localStorage data
  const user_type = localStorage.getItem("user_type");
  const Is_organization = user_type === "organization" ? "none" : "";
  // hooks state variable
  const [deleteAlert, setdeleteAlert] = useState(false);

  const Do_Project_Delete = (res) => {
    if (res) {
      axios.delete(delete_Project(userselectedprojectallid[0])).then(() => {
        sessionStorage.removeItem("LAST_PRPJECT_SELECTED");
        emptyAllLocData();
        emptyKeywordData();
        setdeleteAlert(false);
        dispatch({
          type: "USERALLPROJECTDETAILS",
          payload: [],
        });
        dispatch({ type: "WEBSITEURL", payload: null });
        dispatch({ type: "CURRENTLOCATIONCODE", payload: null });
        dispatch({ type: "CURRENTLOCATION", payload: null });
        dispatch({ type: "LOADING" });
      });
    } else {
      setdeleteAlert(false);
    }
  };

  const fatch_plansDetails = () => {
    if (plan_data_hm?.length === 0) {
      axios.get(get_Plans_Details("USD")).then((res) => {
        const data = res?.data?.data;
        // const filter_plan = data?.filter((plan) => {
        //   if (plan?.validity === "month") {
        //     return plan;
        //   }
        // });
        setPlansDetails(data);
        ZustandStore.setState({
          plan_data_hm: data,
        });
      });
    } else {
      setPlansDetails(plan_data_hm);
    }
  };

  useEffect(() => {
    fatch_plansDetails();
  }, [websiteUrl, crawllimit, email]);

  useEffect(() => {
    if (auditData.length !== 0) {
      let resource_type_html = 0;
      let resource_type_redirect = 0;
      let resource_type_broken = 0;
      let resource_type_block = 0;
      let low_word_count = [];
      let dup_meta_desc = [];
      let dup_title_tag = [];
      let no_title_Tags = [];
      let with_h1 = [];
      let with_broken = [];
      let without_meta = [];
      let with_title_too = [];
      let with_title_short = [];
      let poorly_format = [];
      let doc_not_decl = [];
      let long_load_time = [];
      let url_long = [];
      let short_desc = [];
      let long_desc = [];
      let duplicate_h1_tags = [];
      let same_h1_and_title_tags = [];
      let conanical_issue = [];
      let no_favicon = [];
      let no_image_alt = [];
      let no_image_title = [];

      auditData[0]?.items &&
        auditData[0]?.items?.forEach((res) => {
          if (res.resource_type === "html") {
            resource_type_html += 1;

            // long description
            if (res?.meta?.description_length > 165) {
              long_desc.push({
                url: res?.url || "undefined",
                title: res?.meta?.title,
                desc: res?.meta?.description || "undefined",
                length: res?.meta?.description_length,
                status_code: res?.status_code,
              });
            }
            // short description
            if (res?.meta?.description_length < 150) {
              short_desc.push({
                url: res?.url || "undefined",
                title: res?.meta?.title,
                desc: res?.meta?.description || "undefined",
                length: res?.meta?.description_length,
                status_code: res?.status_code,
              });
            }

            // duplicate H1 tag
            if (res?.meta?.htags) {
              const h1Tags = res?.meta?.htags?.h1;
              // Check the number of h1 tags
              if (h1Tags?.length > 1) {
                duplicate_h1_tags.push({
                  url: res?.url || "undefined",
                  title: res?.meta?.title,
                  no_of_h1_tags: h1Tags?.length,
                  status_code: res?.status_code,
                });
              }
            }

            // same H1 and title tags
            if (res?.meta?.htags) {
              const h1Tags = res?.meta?.htags?.h1;
              const title = res?.meta?.title;
              // Check the number of h1 tags
              if (h1Tags?.includes(title)) {
                same_h1_and_title_tags.push({
                  url: res?.url || "undefined",
                  title: res?.meta?.title || "",
                  h1_tags: title || "",
                  status_code: res?.status_code,
                });
              }
            }

            // conanical issue
            if (!res?.checks?.canonical) {
              conanical_issue.push({
                url: res?.url || "undefined",
                title: res?.meta?.title,
                status_code: res?.status_code,
              });
            }

            // no favicons found
            if (res?.checks?.no_favicon) {
              no_favicon.push({
                url: res?.url || "undefined",
                title: res?.meta?.title,
                status_code: res?.status_code,
              });
            }

            // no favicons found
            if (res?.checks?.no_image_alt) {
              no_image_alt.push({
                url: res?.url || "undefined",
                title: res?.meta?.title,
                status_code: res?.status_code,
              });
            }
            // no image title found
            if (res?.checks?.no_image_title) {
              no_image_title.push({
                url: res?.url || "undefined",
                title: res?.meta?.title,
                status_code: res?.status_code,
              });
            }

            // set low word count
            if (res?.meta?.content?.plain_text_word_count < 200) {
              low_word_count.push({
                url: res.meta.canonical || "undefined",
                title: res.meta.title,
                count: res.meta.content.plain_text_word_count || "undefined",
                status_code: res?.status_code,
              });
            }

            if (res.duplicate_description === true) {
              dup_meta_desc.push({
                url: res.url || "undefined",
                title: res.meta.title,
                desc: res.meta.description || "undefined",
              });
            }

            if (res.checks.duplicate_title_tag === true) {
              dup_title_tag.push({
                url: res.url || "undefined",
                title: res.meta.title || "undefined",
              });
            }

            if (res.checks.no_title === true) {
              no_title_Tags.push({
                url: res.url || "undefined",
                title: res.meta.title,
              });
            }

            if (res.checks.no_h1_tag === true) {
              with_h1.push({
                url: res.url || "undefined",
                title: res.meta.title,
              });
            }

            if (res.checks.is_broken === true) {
              with_broken.push({
                url: res.url || "undefined",
                title: res.meta.title,
              });
            }

            if (res.checks.no_description === true) {
              without_meta.push({
                url: res.url || "undefined",
                title: res.meta.title,
              });
            }
            if (res.checks.title_too_long === true) {
              with_title_too.push({
                url: res.url || "undefined",
                title: res.meta.title || "undefined",
                length: res?.meta?.title_length,
                status_code: res?.status_code,
              });
            }
            if (res.checks.title_too_short === true) {
              with_title_short.push({
                url: res.url || "undefined",
                title: res.meta.title || "undefined",
                length: res?.meta?.title_length,
                status_code: res?.status_code,
              });
            }
            if (res.checks.seo_friendly_url === false) {
              poorly_format.push({
                url: res.url || "undefined",
                status_code: res?.status_code,
                characters:
                  res.checks.seo_friendly_url_characters_check || "undefined",
                dynamic:
                  res.checks.seo_friendly_url_dynamic_check || "undefined",
                keywords:
                  res.checks.seo_friendly_url_keywords_check || "undefined",
                title: res.meta.title,
              });
            }
            if (res.checks.no_doctype === true) {
              doc_not_decl.push({
                url: res.url || "undefined",
                title: res.meta.title,
              });
            }
            if (res?.checks?.high_loading_time === true) {
              const seconds = res?.page_timing?.duration_time / 1000;
              const time = seconds?.toFixed(1) + " sec";
              long_load_time.push({
                url: res.url || "undefined",
                time: time || "undefined",
                title: res.meta.title,
                status_code: res?.status_code,
              });
            }
            if (res.url_length > 100) {
              url_long.push({
                url: res.url || "undefined",
                title: res.meta.title,
                length: res?.url_length,
                status_code: res?.status_code,
              });
            }
          } else if (res.resource_type === "redirect") {
            resource_type_redirect += 1;
          } else if (res.resource_type === "broken") {
            resource_type_broken += 1;
          } else if (res.resource_type === "block") {
            resource_type_block += 1;
          }
        });

      setSeo_Descovered(
        low_word_count.length +
          dup_meta_desc.length +
          dup_title_tag.length +
          no_title_Tags.length +
          with_h1.length +
          with_broken.length +
          without_meta.length +
          with_title_too.length +
          with_title_short.length +
          poorly_format.length +
          doc_not_decl.length +
          long_load_time.length +
          url_long.length +
          short_desc?.length +
          long_desc?.length +
          duplicate_h1_tags?.length +
          same_h1_and_title_tags?.length +
          conanical_issue?.length +
          no_favicon?.length +
          no_image_alt?.length +
          no_image_title?.length
      );
      setAuditLoader(false);
    }
  }, [auditData]);

  const getCurrentCountryDetails = () => {
    if (usercurrentprojectlocation?.length === 0) return;
    const curr_proj = usercurrentprojectlocation?.[0];
    const loc_name = curr_proj?.location_name;
    const data = countryList?.filter((item) => {
      return item.location_name === loc_name;
    });
    return data?.[0]?.location_flag;
  };

  const history_list = backlinkData?.data?.[0]?.history_list;

  const domain_data_card = [
    {
      title: "Domain Rank",
      value: domainAuthority,
      path: "/backlinks",
      loader: backlinkLoader,
      status: domainAuthority != undefined,
    },
    {
      title: "Referring Domains",
      value: history_list?.[history_list?.length - 1]?.referring_domains,
      path: "/backlinks",
      loader: backlinkLoader,
      status: backlinkData?.length > 0,
    },
    {
      title: "Total Organic Keywords",
      value: Math.floor(overviewData?.metrics?.[0]?.metrics?.organic?.count),
      path: "/site-overview",
      loader: overviewLoader,
      status: overviewData?.length > 0,
    },
    {
      title: "Organic Monthly Traffic",
      value: Math.floor(overviewData?.metrics?.[0]?.metrics?.organic?.etv),
      path: "/site-overview",
      loader: overviewLoader,
      status: overviewData?.length > 0,
    },
    {
      title: "Backlinks",
      value: backlinkData?.data?.[0]?.total_backlinks,
      path: "/backlinks",
      loader: backlinkLoader,
      status: backlinkData?.length > 0,
    },
    {
      title: "On-Page SEO Score",
      value: auditData?.[0]?.items?.[0]?.onpage_score,
      path: "/site-audit",
      loader: auditLoader,
      status: auditData?.length > 0,
    },
    {
      title: "SEO Issues Discovered",
      value: Seo_descovered,
      path: "/site-audit",
      loader: auditLoader,
      status: Seo_descovered != undefined,
    },
  ];

  // New & Lost Referring Domains
  const bar_stacked_labels = history_list?.map((label) => {
    return label.month;
  });

  const bar_stacked_backlinks = history_list?.map((label) => {
    return label.backlinks;
  });
  const bar_stacked_referring_domains = history_list?.map((label) => {
    return label.referring_domains;
  });

  const backlink_chart_data = {
    label: websiteUrl,
    labels: bar_stacked_labels,
    title: [
      { left: "Backlinks", color: "#2E4161" },
      { right: "Referring Domains", color: "#B04656" },
    ],
    dataSet: [
      {
        label: "Backlinks",
        data: bar_stacked_backlinks,
        borderColor: "#2E4161",
        fill: "start",
        backgroundColor: "#365486B2",
        yAxisID: "y",
        pointRadius: 0,
        pointHoverRadius: 7,
        pointBorderColor: "#2E4161",
        pointBackgroundColor: "#2E4161",
      },
      {
        label: "Referring Domains",
        data: bar_stacked_referring_domains,
        borderColor: "#B04656",
        fill: "start",
        backgroundColor: "#E956558C",
        yAxisID: "y1",
        pointRadius: 0,
        pointHoverRadius: 7,
        pointBorderColor: "#E956558C",
        pointBackgroundColor: "#E956558C",
      },
    ],
  };

  const bar_stacked_new = history_list?.map((label) => {
    return label.new_refeering_domains;
  });

  const bar_stacked_lost = history_list?.map((label) => {
    return `-${label.lost_referring_domains}`;
  });

  const bar_stacked_chart_data = {
    label: websiteUrl,
    labels: bar_stacked_labels,
    dataSet: [
      {
        label: "New",
        data: bar_stacked_new,
      },
      {
        label: "Lost",
        data: bar_stacked_lost,
      },
    ],
  };

  const free_plan = plansDetails?.filter((plan) => {
    return plan?.price === "0";
  });

  const get_curr_plan =
    plansDetails?.length === 0 || plan_details?.length === 0
      ? []
      : plansDetails?.filter((plan) => {
          return plan?.name == plan_details?.plan_name;
        });

  const curr_plan = get_curr_plan?.length === 0 ? free_plan : get_curr_plan;

  const addQueryParam = (e, path) => {
    if (usercurrentprojectlocation?.length === 0) return;
    const curr_proj = usercurrentprojectlocation?.[0];
    const config = {
      domain: curr_proj?.weburl,
      loc_name: curr_proj?.location_name,
      loc_code: curr_proj?.location_code,
      loc_flag: getCurrentCountryDetails(),
    };
    sessionStorage.setItem("lastdomainname", config?.domain);

    if (path === "/backlinks") {
      dispatch({ type: "BACKLINKDOMAIN", payload: config?.domain });
      navigate("/backlinks");
    } else if (path === "/site-overview") {
      dispatch({
        type: "SITEDOMAIN",
        payload: {
          domain: config?.domain,
          loc_name: config?.loc_name,
          loc_code: config?.loc_code,
          loc_flag: config?.loc_flag,
        },
      });
      navigate(`/site-overview`);
    } else {
      navigate(path);
    }
  };

  const showGoogleAnalytics = true;

  return (
    <main className="main-pd rank-tracking-container dashboard-app-container">
      <section className="project-selection">
        <div className="row p-0">
          <div className="col-lg-12 col-md-12">
            <div className="project-container">
              <div className="project-header">
                <h1 className="">Show Ranking For</h1>
              </div>
              <p>Select to get the detailed Rank Tracking of your project</p>
              <div className="row">
                <div className="col-12 col-md-6">
                  <ProjectList
                    style={{
                      border: "1px solid rgba(0, 0, 0, 0.3)",
                      width: "100%",
                      textTransform: "none",
                      boxShadow: "none",
                      padding: "1.1em 1em",
                      borderRadius: "5px",
                    }}
                  />
                </div>
                <div className="col-12 col-md-6 d-flex align-items-center">
                  <div
                    style={{ display: Is_organization }}
                    className="d-flex justify-content-center align-items-center mt-2 mt-md-0 mt-lg-0 h-100"
                  >
                    <Link to="/addpr" className="">
                      <button
                        className="icon-btn large-btn"
                        onClick={() => {
                          localStorage.removeItem("ProjectsDetail");
                          localStorage.removeItem("Locationdetails");
                          localStorage.removeItem("CompetitorsDetails");
                        }}
                        title="Add Project"
                      >
                        <img src={addIcon} alt="" />
                      </button>
                    </Link>
                    <button
                      onClick={() => setdeleteAlert(true)}
                      className="icon-btn large-btn ms-3"
                      title="Delete Project"
                    >
                      <img src={deleteIcon} alt="" />
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="domian-data-card container-scroll">
        {domain_data_card?.map((item, index) => {
          return (
            <div
              key={index}
              className={
                !item?.loader
                  ? "px-3 py-2 domain-card position-relative"
                  : "domain-card"
              }
            >
              {!item?.loader ? (
                !item?.loader &&
                handleNaNAndUndefined(item?.value) &&
                !item?.status ? (
                  <>
                    {item?.path === "/backlinks" ||
                    item?.path === "/site-overview" ? (
                      <>
                        <h1 className="font-medium heading-xs">
                          {item?.title}
                        </h1>
                        <button
                          className="Pr-btn px-3 py-1 mt-0"
                          style={{ height: "35px" }}
                          onClick={(e) => addQueryParam(e, item?.path)}
                        >
                          check
                        </button>
                      </>
                    ) : (
                      <>
                        <h1 className="font-medium heading-xs">
                          {item?.title}
                        </h1>
                        <Link to={item?.path}>
                          <button
                            className="Pr-btn px-3 py-1 mt-0"
                            style={{ height: "35px" }}
                          >
                            check
                          </button>
                        </Link>
                      </>
                    )}
                  </>
                ) : (
                  <div
                    onClick={(e) => addQueryParam(e, item?.path)}
                    className="point"
                  >
                    <h1 className="font-medium heading-xs">{item?.title}</h1>
                    <h3 className="font-bolder heading-xlarge mb-0 d-flex align-items-center justify-content-center">
                      {item?.value == "undefined"
                        ? "0"
                        : formatNumberWithSymbols(item?.value) || "0"}
                      {item?.title === "Organic Monthly Traffic" ||
                      item?.title === "Total Organic Keywords" ? (
                        <img
                          src={getCurrentCountryDetails()}
                          alt="site-overview-data"
                          className="ms-3"
                          style={{ width: "20px", objectFit: "contain" }}
                        />
                      ) : null}
                    </h3>
                  </div>
                )
              ) : (
                <div className="rank-card-loader">
                  <Player
                    src={spinLoaderAnimation}
                    className="player"
                    loop
                    autoplay
                    speed={3}
                    style={{ width: "100px" }}
                  />
                </div>
              )}
            </div>
          );
        })}
      </section>

      <section className="keyword-ranking-data">
        <div
          className={
            RankDownPercent === 0
              ? "keyword-rank-chart blur-card"
              : "keyword-rank-chart"
          }
        >
          <div id="chart">
            <ReactApexChart
              options={chartData.options}
              series={chartData.series}
              type="radialBar"
              height={390}
            />
          </div>
          <div id="html-dist"></div>
        </div>
        <div className="key-rank-card-up-down">
          <div
            className={
              showLoader || RankMovedup === null
                ? "card-up blur-card"
                : "card-up"
            }
          >
            <h1 className="font-bold heading-small mb-0">Keywords</h1>
            <h1 className="font-bold heading-small">Moved Up</h1>
            <RankIcon />
            <h2 className="font-bold heading-xlarge">{RankMovedup}</h2>
          </div>
          <div
            className={
              showLoader || RankMovedup === null
                ? "card-down blur-card"
                : "card-down"
            }
          >
            <h1 className="font-bold heading-small mb-0">Keywords</h1>
            <h1 className="font-bold heading-small">Moved Down</h1>
            <RankIcon />
            <h2 className="font-bold heading-xlarge">{RankMovedDown}</h2>
          </div>
        </div>
        <div className="view-google-data">
          <div className="d-flex align-items-start">
            {showGoogleAnalytics ? null : (
              <img
                src={googleImg}
                alt="google-icon"
                style={{
                  width: "25px",
                  height: "25px",
                  marginRight: "10px",
                  marginTop: "4px",
                }}
              />
            )}
            <h1>
              Quickly Access Your Site’s Google Analytics and Search Console
              Data
            </h1>
          </div>
          <p className="mb-0">
            Let the tool fetch and display your SEO metrics data, which is
            presented in visual graphics, segmented separately for both Google
            Analytics and Search Console. Assess your organic traffic, for the
            last 1 week, up to last 3 months.{" "}
            {showGoogleAnalytics
              ? "Below, you can check the Google Console data of total queries and impressions related to your targeted keyword phrases and landing pages."
              : null}
          </p>
          {showGoogleAnalytics ? (
            <div className="analy-gol mt-4 d-flex justify-content-between">
              <GoogleAuth analyticShow={false} />
              {userAnalyticLogin && (
                <Link to="/google-data">
                  <button
                    className="ms-3"
                    title="View Google Data"
                    style={{ backgroundColor: "#e95655" }}
                  >
                    View
                  </button>
                </Link>
              )}
            </div>
          ) : (
            <img src={comminSoon} alt="google-icon" className="image-reflect" />
          )}
        </div>
        <div
          className={
            plansDetails?.length === 0
              ? "blur-card current-plan-card"
              : "current-plan-card"
          }
        >
          <span
            className={
              plan_details?.referral_user ? "plan-top-referral" : "plan-top"
            }
          >
            {plan_details?.referral_user
              ? plan_details?.plan_name
              : curr_plan?.[0]?.name}
          </span>
          {plan_details?.referral_user ? null : (
            <div className="plan-cost">
              <img src={planBgIcon} alt="" className="plan-cost-img" />
              <span className="plan-cost-value">${curr_plan?.[0]?.price}</span>
            </div>
          )}
          <ul
            className={
              plan_details?.referral_user
                ? "plan-feature plan-feature-referral"
                : "plan-feature"
            }
          >
            <li>
              <img src={greenTick} alt="plan-bullet-plan" />
              {plan_details?.referral_user
                ? plan_details?.limits?.proj_len
                : curr_plan?.[0]?.proj_len}{" "}
              Domain
            </li>
            <li>
              <img src={greenTick} alt="plan-bullet-plan" />
              {plan_details?.referral_user
                ? plan_details?.limits?.keyword_len
                : curr_plan?.[0]?.keyword_len}{" "}
              No of tracked keywords
            </li>
            <li>
              <img src={greenTick} alt="plan-bullet-plan" />
              {plan_details?.referral_user
                ? plan_details?.limits?.competitors_limit
                : curr_plan?.[0]?.competitors_limit}{" "}
              No. of competitors / domain
            </li>
            <li>
              <img src={greenTick} alt="plan-bullet-plan" />
              {plan_details?.referral_user
                ? plan_details?.limits?.sub_users_limit
                : curr_plan?.[0]?.sub_users_limit}{" "}
              No. of user
            </li>
            {plan_details?.referral_user ? (
              <>
                <li>
                  <img src={greenTick} alt="plan-bullet-plan" />
                  {plan_details?.referral_user
                    ? plan_details?.limits?.site_overview_limit
                    : curr_plan?.[0]?.sub_users_limit}{" "}
                  site overview
                </li>
                <li>
                  <img src={greenTick} alt="plan-bullet-plan" />
                  {plan_details?.referral_user
                    ? plan_details?.limits?.backlinks_rows_limit
                    : curr_plan?.[0]?.sub_users_limit}{" "}
                  backlinks credits
                </li>
                {/* <li>
                  <img src={greenTick} alt="plan-bullet-plan" />
                  {plan_details?.referral_user
                    ? plan_details?.limits?.site_audit_limit
                    : curr_plan?.[0]?.sub_users_limit}{" "}
                  site audit credits weekly
                </li>
                <li>
                  <img src={greenTick} alt="plan-bullet-plan" />
                  {plan_details?.referral_user
                    ? plan_details?.limits?.key_limit
                    : curr_plan?.[0]?.sub_users_limit}{" "}
                  keyword research credits
                </li> */}
              </>
            ) : null}
          </ul>
          <Link to="/upgrade">
            <button
              className={
                plan_details?.referral_user
                  ? "cm-btn plan-feature-referral-btn"
                  : "cm-btn"
              }
              style={{ width: "80%" }}
            >
              See More
            </button>
          </Link>
        </div>
      </section>

      <section className="banner-sub-user">
        <div className="row gy-3">
          <div className="col-12 col-lg-8">
            <div className="banner-card position-relative">
              <img src={bannerImg} alt="" />
              <div className="content">
                <h3>All-in-One Toolkit to Supercharge Your SEO</h3>
                <h1>
                  Perform Multiple Tasks, Identify Growth Opportunities, Monitor
                  & Improve Your Performance
                </h1>
              </div>
            </div>
          </div>
          <div className="col-12 col-lg-4">
            <div className="sub-user-card ProfileBox">
              <SubUserDetails />
            </div>
          </div>
        </div>
      </section>

      <section className="chart-container">
        <div className="row gy-4 mt-3">
          <div className="col-12 col-lg-6">
            {backlinkData?.length === 0 ? (
              <div className="position-relative blur-card-wth-btn chart-card-effect">
                <BlurCard
                  useBtn={true}
                  onActionCall={(e) => addQueryParam(e, "/backlinks")}
                />
                <img src={chart1} alt="blur-image" className="w-100 h-100" />
              </div>
            ) : (
              <div
                className="backlink-line-chart point"
                style={{
                  backgroundColor: "white",
                  border: "1px solid rgba(0, 0, 0, 0.1)",
                }}
                onClick={(e) => addQueryParam(e, "/backlinks")}
              >
                <div className="backlink-line-chart-contain cmd p-3 mb-3 pb-0">
                  <h1 className="line-chart-heading mb-0">
                    New & Lost Referring Domains
                  </h1>
                  <div className="d-flex">
                    <div className="d-flex" style={{ alignItems: "center" }}>
                      <span
                        className="line-chart-new-reffer-domain"
                        style={{ backgroundColor: "#365486" }}
                      ></span>
                      <label className="heading-xs font-light-bold">New</label>
                    </div>
                    <div
                      className="d-flex"
                      style={{
                        marginLeft: "20px",
                        alignItems: "center",
                      }}
                    >
                      <span
                        className="line-chart-old-backlinks"
                        style={{ backgroundColor: "#E95655" }}
                      ></span>
                      <label className="heading-xs font-light-bold">Lost</label>
                    </div>
                  </div>
                </div>
                <div className="overAuto w-100 app-scrollbar">
                  <div className="single-backlink-chart p-3 pt-0">
                    <BarChart data={bar_stacked_chart_data} />
                  </div>
                </div>
              </div>
            )}
          </div>
          <div className="col-12 col-lg-6">
            {ETVData?.label?.length === 0 ? (
              <div className="position-relative blur-card-wth-btn chart-card-effect">
                <BlurCard
                  useBtn={true}
                  onActionCall={(e) => addQueryParam(e, "/site-overview")}
                />
                <img src={chart2} alt="blur-image" className="w-100 h-100" />
              </div>
            ) : (
              <div
                className="overview-chart-card mt-0 point"
                onClick={(e) => addQueryParam(e, "/site-overview")}
              >
                <div className="w-100">
                  <div className="BarMultiLine">
                    <h6 className="mb-3">SEO Keywords Distribution</h6>
                    <div className="RankOverBar">
                      <div
                        className="rankLebelOverBar m-0"
                        style={{ top: "0px" }}
                      >
                        <span style={{ background: "#F3A344" }}></span> POS 1-3
                      </div>
                      <div
                        className="rankLebelOverBar"
                        style={{
                          top: "0px",
                        }}
                      >
                        <span
                          style={{
                            background: "#3BA1C5",
                          }}
                        ></span>{" "}
                        POS 4-10{" "}
                      </div>
                      <div
                        className="rankLebelOverBar"
                        style={{
                          top: "0px",
                        }}
                      >
                        <span
                          style={{
                            background: "#365486",
                          }}
                        ></span>{" "}
                        POS 11-50
                      </div>
                      <div
                        className="rankLebelOverBar"
                        style={{
                          top: "0px",
                        }}
                      >
                        <span
                          style={{
                            background: "#E95655",
                          }}
                        ></span>{" "}
                        POS 51-100
                      </div>
                    </div>
                  </div>
                  <div style={{ overflow: "auto" }} className="chart-scrollbar">
                    <div className="barMulti">
                      <StackedBarChartMulti
                        pos1to3={barPosData?.p1to3}
                        pos4to10={barPosData?.p4to10}
                        pos11to50={barPosData?.p11to50}
                        pos51to100={barPosData?.p51to100}
                        etvlabel={ETVData?.label}
                      />
                    </div>
                  </div>
                </div>
              </div>
            )}
          </div>
          <div className="col-12 col-lg-6">
            {backlinkData?.length === 0 ? (
              <div className="position-relative blur-card-wth-btn chart-card-effect">
                <BlurCard
                  useBtn={true}
                  onActionCall={(e) => addQueryParam(e, "/backlinks")}
                />
                <img src={chart3} alt="blur-image" className="w-100 h-100" />
              </div>
            ) : (
              <div
                className="backlink-line-chart h-100 point"
                style={{
                  backgroundColor: "white",
                  border: "1px solid rgba(0, 0, 0, 0.1)",
                }}
                onClick={(e) => addQueryParam(e, "/backlinks")}
              >
                <div className="backlink-line-chart-contain cmd p-3 pb-0">
                  <h1 className="mb-0 line-chart-heading">
                    Backlinks Over Time
                  </h1>
                  <div className="d-flex">
                    <div className="d-flex" style={{ alignItems: "center" }}>
                      <span
                        className="line-chart-new-reffer-domain"
                        style={{ backgroundColor: "#5C749C" }}
                      ></span>
                      <label className="heading-xs font-light-bold">
                        Backlinks
                      </label>
                    </div>
                    <div
                      className="d-flex"
                      style={{
                        marginLeft: "20px",
                        alignItems: "center",
                      }}
                    >
                      <span
                        className="line-chart-old-backlinks"
                        style={{ backgroundColor: "#B8616E" }}
                      ></span>
                      <label className="heading-xs font-light-bold">
                        Referring Domains
                      </label>
                    </div>
                  </div>
                </div>
                <div className="overAuto w-100 app-scrollbar">
                  <div className="single-backlink-chart p-3">
                    <SingleLineChart data={backlink_chart_data} />
                  </div>
                </div>
              </div>
            )}
          </div>
          <div className="col-12 col-lg-6">
            {ETVData?.all_data?.length === 0 ? (
              <div className="position-relative blur-card-wth-btn chart-card-effect">
                <BlurCard
                  useBtn={true}
                  onActionCall={(e) => addQueryParam(e, "/site-overview")}
                />
                <img src={chart4} alt="blur-image" className="w-100 h-100" />
              </div>
            ) : (
              <div
                className="overview-chart-card mt-0 h-100 point"
                onClick={(e) => addQueryParam(e, "/site-overview")}
              >
                <div className="w-100">
                  <div className="cmd">
                    <h6 className="chart-header">Total Traffic</h6>
                    <div>
                      {/* <div className="chart-updated">
                        <span className="">Last Updated :</span>
                        <span style={{ marginLeft: "5px" }}>
                          {overviewData?.last_updated +
                            " " +
                            overviewData?.Month +
                            " " +
                            overviewData?.Year}{" "}
                        </span>
                      </div> */}
                      <div className="d-flex chart-type">
                        <div
                          className="m-0 rankLebelOver"
                          style={{ top: "0px" }}
                        >
                          <span style={{ background: "#3BA1C5" }}></span>{" "}
                          Organic
                        </div>
                        <div
                          className="rankLebelOver"
                          style={{
                            marginLeft: "10px",
                          }}
                        >
                          <span
                            style={{
                              background: "#E95655",
                            }}
                          ></span>{" "}
                          Paid{" "}
                        </div>
                      </div>
                    </div>
                  </div>
                  <TrafficChart
                    etv={ETVData?.data}
                    websiteurl={websiteUrl}
                    etvlabel={ETVData?.label}
                    etvAllData={ETVData?.all_data}
                  />
                </div>
              </div>
            )}
          </div>
        </div>
      </section>

      {/* // Alerts */}
      {deleteAlert ? (
        <AreYousure
          callback={Do_Project_Delete}
          title={"You will not able to recover this Project !"}
        />
      ) : null}
    </main>
  );
};
export default Home;
