import React, { useState, useMemo } from "react";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import { Checkbox, Box, Typography, IconButton, Button } from "@mui/material";
import Collapse from "@mui/material/Collapse";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import { getCurrentCountryFlag } from "../../../helperJs/getCountryFlag";
import { ZustandStore } from "../../../store/zustand-store";
import { Link } from "react-router-dom";
import { CSVLink } from "react-csv";
import ControlPointIcon from "@mui/icons-material/ControlPoint";
import deleteIcon from "../../Assets/rank-tracking-assets/delete.svg";
import addIcon from "../../Assets/rank-tracking-assets/add.svg";
import { ReactComponent as ArrowUp } from "../../Assets/add-project/arrow-up.svg";
import { ReactComponent as ArrowDown } from "../../Assets/add-project/arrow-down.svg";
import {
  formatNumberWithSymbolsNoDecimal,
  formatNumberWithSymbols,
} from "../../../helperJs/helper";
import Spinner from "../../share/components/spinner";
import exportIcon from "../../Assets/rank-tracking-assets/export-file.svg";

const columns = [
  { id: "projects", label: "Projects", minWidth: 170 },
  { id: "avg_pos", label: "AVG Position", minWidth: 150, align: "center" },
  { id: "top_pos", label: "Top 3/10/100", minWidth: 150, align: "center" },
  { id: "domain_rank", label: "Domain Rank", minWidth: 120, align: "center" },
  {
    id: "referring_domains",
    label: "Referring Domains",
    minWidth: 155,
    align: "center",
  },
  {
    id: "organic_keyword",
    label: "Organic Keyword",
    minWidth: 200,
    align: "center",
  },
  {
    id: "organic_traffic",
    label: "Organic Traffic",
    minWidth: 170,
    align: "center",
  },
  {
    id: "backlinks",
    label: "Backlinks",
    minWidth: 170,
    align: "center",
  },
];

function createData(
  project_name,
  project_id,
  project_location,
  avg_pos,
  top_pos,
  domain_rank,
  referring_domains,
  organic_keyword,
  organic_traffic,
  backlinks,
  sub_project
) {
  return {
    project_name,
    project_id,
    project_location,
    avg_pos,
    top_pos,
    domain_rank,
    referring_domains,
    organic_keyword,
    organic_traffic,
    backlinks,
    sub_project,
  };
}

const ProjectTable = ({ setShowDeleteAlert }) => {
  const user_type = localStorage.getItem("user_type");
  const [open, setOpen] = React.useState(false);
  const [selectAll, setSelectAll] = useState(false);
  const [selectedProject, setSelectedProject] = useState([]);
  const { project_section } = ZustandStore((state) => state);

  const handleCollapse = (domain) => {
    if (domain === open) {
      setOpen("");
    } else {
      setOpen(domain);
    }
  };

  const project_data = useMemo(() => {
    const data = project_section.data;
    // Grouping objects by proj_name
    const row_data = data?.map((project) => {
      const history_list = project?.backlinks?.data?.[0]?.history_list;
      return {
        proj_name: project?.proj_name,
        id: project.id,
        location_name: project.location_name,
        avg_pos: project.avg_rank,
        top_3: project?.top_3,
        top_10: project?.top_10,
        top_100: project?.top_100,
        domain_rank: project?.domain_authority,
        reff_domain:
          project?.backlinks?.data?.[0]?.history_list?.[
            history_list?.length - 1
          ]?.referring_domains || 0,
        organic_keyword: {
          curr:
            project?.domain_overview?.metrics?.[0]?.metrics?.organic?.count ||
            0,
          last:
            project?.domain_overview?.metrics?.[1]?.metrics?.organic?.count ||
            0,
        },

        organic_traffic: {
          curr:
            project?.domain_overview?.metrics?.[0]?.metrics?.organic?.etv || 0,
          last:
            project?.domain_overview?.metrics?.[1]?.metrics?.organic?.etv || 0,
        },
        backlinks: {
          curr: project?.backlinks?.data?.[0]?.history_list?.[
            history_list?.length - 1
          ]?.backlinks,
          last: project?.backlinks?.data?.[0]?.history_list?.[
            history_list?.length - 2
          ]?.backlinks,
        },
      };
    });

    const groupedData = {};

    row_data.forEach((item) => {
      if (!groupedData[item.proj_name]) {
        groupedData[item.proj_name] = [];
      }
      groupedData[item.proj_name].push(item);
    });

    // Creating the new structure
    const result = [];

    Object.keys(groupedData).forEach((key) => {
      const [first, ...rest] = groupedData[key];
      if (rest.length > 0) {
        first.sub_list = rest;
      }
      result.push(first);
    });

    return result;
  }, [project_section]);

  function analyzeValues(curr, last) {
    const difference = curr - last;
    const increased = difference > 0;
    const diff = Math.abs(difference).toFixed(0);
    return (
      <div className="d-flex align-items-center justify-content-center">
        {formatNumberWithSymbols(curr)}
        {curr === last ? null : increased ? (
          <>
            <ArrowUp className="mx-2" />
            <span className="text-success">
              {formatNumberWithSymbols(diff)}
            </span>
          </>
        ) : (
          <>
            <ArrowDown className="mx-2" />
            <span className="text-danger">{formatNumberWithSymbols(diff)}</span>
          </>
        )}
      </div>
    );
  }

  const selectedProjectId = useMemo(() => {
    if (project_section?.data?.length === 0) return [];
    if (selectAll) {
      const id = project_section?.data?.map((item) => {
        return item.id;
      });
      let unique = [...new Set(id)];
      setSelectedProject(unique);
    } else {
      setSelectedProject([]);
    }
  }, [selectAll]);

  const rows = project_data?.map((project) => {
    return createData(
      project.proj_name,
      project.id,
      project.location_name,
      project.avg_pos ? Number(project.avg_pos).toFixed(2) : 0,
      `${project.top_3}/${project.top_10}/${project.top_100}`,
      project.domain_rank,
      formatNumberWithSymbolsNoDecimal(project.reff_domain),
      analyzeValues(project.organic_keyword.curr, project.organic_keyword.last),
      analyzeValues(project.organic_traffic.curr, project.organic_traffic.last),
      analyzeValues(project.backlinks.curr, project.backlinks.last),
      project.sub_list?.map((sub_project) => {
        return createData(
          sub_project.proj_name,
          sub_project.id,
          sub_project.location_name,
          sub_project.avg_pos ? Number(sub_project.avg_pos).toFixed(2) : 0,
          `${sub_project.top_3}/${sub_project.top_10}/${sub_project.top_100}`,
          sub_project.domain_rank,
          formatNumberWithSymbolsNoDecimal(sub_project.reff_domain),
          analyzeValues(
            sub_project.organic_keyword.curr,
            sub_project.organic_keyword.last
          ),
          analyzeValues(
            sub_project.organic_traffic.curr,
            sub_project.organic_traffic.last
          ),
          analyzeValues(sub_project.backlinks.curr, sub_project.backlinks.last)
        );
      })
    );
  });

  const handleProjectSelection = (value) => {
    setSelectedProject((prevSelectedId) => {
      if (prevSelectedId.includes(value)) {
        const newId = prevSelectedId.filter((id) => id !== value);
        return newId;
      } else {
        return [...prevSelectedId, value];
      }
    });
  };

  const loadCSVData = () => {
    if (project_section?.data?.length === 0) return [];
    const data = project_section.data?.map((project) => {
      const history_list = project?.backlinks?.data?.[0]?.history_list;
      return {
        project_name: project?.proj_name,
        project_id: project.id,
        project_location: project.location_name,
        avg_pos: formatNumberWithSymbols(project.avg_rank),
        top_3: project?.top_3,
        top_10: project?.top_10,
        top_100: project?.top_100,
        domain_rank: project?.domain_authority,
        reff_domain:
          formatNumberWithSymbols(
            project?.backlinks?.data?.[0]?.history_list?.[
              history_list?.length - 1
            ]?.referring_domains
          ) || 0,
        organic_keyword:
          formatNumberWithSymbols(
            project?.domain_overview?.metrics?.[0]?.metrics?.organic?.count
          ) || 0,

        organic_traffic:
          formatNumberWithSymbols(
            project?.domain_overview?.metrics?.[0]?.metrics?.organic?.etv
          ) || 0,

        backlinks: formatNumberWithSymbols(
          project?.backlinks?.data?.[0]?.history_list?.[
            history_list?.length - 1
          ]?.backlinks
        ),
      };
    });
    if (selectAll) {
      return data;
    } else {
      const data2 = data?.filter((item) => {
        return selectedProject?.includes(item?.project_id);
      });
      return data2;
    }
  };

  const handleDeleteProject = () => {
    const id = selectedProject?.[0];
    setShowDeleteAlert({
      show: true,
      id,
    });
  };

  const handleRetry = () => {
    ZustandStore.setState({
      project_section: {
        data: [],
        error: false,
        loading: true,
      },
    });
  };

  return (
    <div>
      {user_type === "organization" ? null : (
        <div
          className="d-flex justify-content-between
         align-items-center my-4"
        >
          <Link to="/addpr" className="">
            <button
              className="d-flex align-items-center"
              style={{ backgroundColor: "#e95655" }}
              onClick={() => {
                localStorage.removeItem("ProjectsDetail");
                localStorage.removeItem("Locationdetails");
                localStorage.removeItem("CompetitorsDetails");
              }}
              title="Add Project"
            >
              <ControlPointIcon className="me-1" />
              Add Project
            </button>
          </Link>
          <div>
            <CSVLink data={loadCSVData()} filename={"project.csv"}>
              <button
                className="icon-btn large-btn ms-2"
                disabled={selectedProject?.length === 0}
                title="export csv"
              >
                <img src={exportIcon} alt="exportIcon" />
              </button>
            </CSVLink>

            <button
              className="icon-btn large-btn ms-3"
              onClick={() => handleDeleteProject()}
              title="Delete Project"
              disabled={selectedProject?.length != 1}
            >
              <img src={deleteIcon} alt="deleteIcon" />
            </button>
          </div>
        </div>
      )}
      <section className="project-table">
        <Paper sx={{ width: "100%", overflow: "hidden" }}>
          <TableContainer sx={{ maxHeight: 440 }}>
            <Table stickyHeader aria-label="sticky table" className="vir-table">
              <TableHead>
                <TableRow>
                  <TableCell
                    padding="checkbox"
                    sx={{ backgroundColor: "#D6E1EA99", color: "#000000E5" }}
                  >
                    <Checkbox
                      color="primary"
                      onClick={() => setSelectAll((pre) => !pre)}
                    />
                  </TableCell>
                  {columns.map((column) => (
                    <TableCell
                      sx={{ backgroundColor: "#D6E1EA99", color: "#000000E5" }}
                      key={`table-header${column.id}-${column?.label}`}
                      align={column.align}
                      style={{ minWidth: column.minWidth }}
                    >
                      {column.label}
                    </TableCell>
                  ))}
                </TableRow>
              </TableHead>
              <TableBody>
                {project_section?.loading ||
                (rows?.length === 0 && project_section?.data?.length > 0) ? (
                  <TableRow>
                    <TableCell colSpan={columns?.length + 1}>
                      <Spinner />
                    </TableCell>
                  </TableRow>
                ) : project_section?.data?.length === 0 &&
                  project_section?.error ? (
                  <TableRow>
                    <TableCell colSpan={columns?.length + 1}>
                      <div className="d-flex align-items-center justify-content-center flex-column">
                        <p className="mb-1">
                          No Data
                          {/* No Data , want to <b>reload</b> */}
                        </p>
                        {/* <Button
                          className="btn_1"
                          variant="contained"
                          onClick={() => handleRetry()}
                        >
                          Retry
                        </Button> */}
                      </div>
                    </TableCell>
                  </TableRow>
                ) : (
                  rows?.map((row) => {
                    const flag = getCurrentCountryFlag(row?.project_location);
                    return (
                      <React.Fragment key={row?.project_name}>
                        <TableRow
                          hover
                          role="checkbox"
                          tabIndex={-1}
                          key={row.code}
                        >
                          <TableCell padding="checkbox">
                            <Checkbox
                              color="primary"
                              checked={selectedProject?.includes(
                                row?.project_id
                              )}
                              onClick={(e) =>
                                handleProjectSelection(row?.project_id)
                              }
                            />
                          </TableCell>
                          {columns.map((column, index) => {
                            const value = row[column.id];
                            return (
                              <React.Fragment
                                key={`table-row-cell-${row?.project_name}-${
                                  index + 4
                                }-${column.label}`}
                              >
                                {column.id === "projects" ? (
                                  <TableCell
                                    key={`${column.align}-${column.id}`}
                                    align={column.align}
                                    className="d-flex align-items-center"
                                    style={{
                                      color: "#000000B2",
                                    }}
                                  >
                                    {row?.sub_project?.length > 0 && (
                                      <IconButton
                                        aria-label="expand row"
                                        size="small"
                                        onClick={() =>
                                          handleCollapse(row.project_name)
                                        }
                                      >
                                        {open === row?.project_name ? (
                                          <KeyboardArrowUpIcon />
                                        ) : (
                                          <KeyboardArrowDownIcon />
                                        )}
                                      </IconButton>
                                    )}
                                    {row?.project_name}
                                    <img
                                      src={getCurrentCountryFlag(
                                        row?.project_location
                                      )}
                                      alt={row?.project_location}
                                      className="mx-2"
                                    />
                                  </TableCell>
                                ) : (
                                  <TableCell
                                    key={column.id}
                                    align={column.align}
                                    style={{
                                      color: "#000000B2",
                                    }}
                                  >
                                    {column.format && typeof value === "number"
                                      ? column.format(value)
                                      : value}
                                  </TableCell>
                                )}
                              </React.Fragment>
                            );
                          })}
                        </TableRow>

                        {open === row?.project_name
                          ? row?.sub_project?.map((item, index) => {
                              return (
                                <TableRow
                                  key={`collapse-row-${row?.project_name}-${
                                    index + 3
                                  }`}
                                  style={{ backgroundColor: "#D9D9D959" }}
                                >
                                  <TableCell padding="checkbox">
                                    <div style={{ width: "42px" }}></div>
                                    {/* <Checkbox color="primary" /> */}
                                  </TableCell>
                                  {columns.map((column, index) => {
                                    const value = item[column.id];
                                    return (
                                      <React.Fragment
                                        key={`collapse-table-cell-${
                                          item?.project_location
                                        }-${index + 4}`}
                                      >
                                        {column.id === "projects" ? (
                                          <TableCell
                                            key={`${column.id}-${
                                              column.align
                                            }-${column.minWidth || index}`}
                                            align={column.align}
                                            style={{
                                              minWidth: column.minWidth,
                                              color: "#000000B2",
                                            }}
                                          >
                                            {" "}
                                            <div style={{ marginLeft: "35px" }}>
                                              {item?.project_location}
                                              <img
                                                src={getCurrentCountryFlag(
                                                  item?.project_location
                                                )}
                                                alt={item?.project_location}
                                                className="mx-2"
                                              />
                                            </div>
                                          </TableCell>
                                        ) : (
                                          <TableCell
                                            key={column.id}
                                            align={column.align}
                                            style={{
                                              minWidth: column.minWidth,
                                              color: "#000000B2",
                                            }}
                                          >
                                            {column.format &&
                                            typeof value === "number"
                                              ? column.format(value)
                                              : value}
                                          </TableCell>
                                        )}
                                      </React.Fragment>
                                    );
                                  })}
                                </TableRow>
                              );
                            })
                          : null}
                      </React.Fragment>
                    );
                  })
                )}
              </TableBody>
            </Table>
          </TableContainer>
        </Paper>
      </section>
    </div>
  );
};

export default React.memo(ProjectTable);
