import React from "react";
import "../../Mycomponent/css/footer.css";
import { LuCopyright } from "react-icons/lu";
import { getCurrentYear } from "../../Mycomponent/share/helper-js/current-year";

const Footer = () => {
  return (
    <footer className="footer-container">
      <div className="container">
        <div style={{ color: "#5964A3" }} className="footer-element">
          <span className="footer-elem">
            <LuCopyright className="me-1 heading-medium" />
            Copyright {getCurrentYear}
          </span>
          <span className="mx-2"> {"|"}</span>
          <span className="footer-elem">
            Made by{" "}
            <a
              style={{ color: "white" }}
              href="https://www.esearchlogix.com/"
              target="_blank"
              rel="noopener noreferrer"
            >
              {" "}
              eSearch Logix{" "}
            </a>{" "}
          </span>
          <span className="mx-2"> {"|"}</span>
          <span className="footer-elem">All rights reserved</span>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
