import React, { useState, useEffect } from "react";
import { Outlet } from "react-router-dom";
import { useLocation } from "react-router-dom";
import { base64Decode } from "../helperJs/encoderanddecoder";
import GetGuestData from "../confrigration/getGuestData";
import { ZustandStore } from "../store/zustand-store";
import Footer from "./GuestLink/footer";
import "../Mycomponent/css/guestlayout.scss";
import Header from "./GuestLink/header";
import Loader from "../Mycomponent/share/loader";
import expiredLinkImg from "./GuestLink/assets/image/expired-link.png";
import axios from "axios";
import { GET_IS_GUEST_LINK_VALID } from "./GuestLink/assets/services";
import { useSelector } from "react-redux";
import Spinner from "../Mycomponent/share/components/spinner";

const GuestLayout = () => {
  const [urlConfig, setUrlConfig] = useState({
    loading: true,
    error: false,
  });
  const location = useLocation();
  const showLoader = useSelector((state) => state.showloader);
  const { keyword_latest_data, keyword_old_data } = ZustandStore(
    (state) => state
  );
  const queryParams = new URLSearchParams(location.search);
  const query_code = queryParams.get("code");
  const websiteurl = queryParams.get("domain");
  const email = base64Decode(query_code);

  useEffect(() => {
    const curr_url = window.location.href;
    if (curr_url) {
      const encodedLink = encodeURIComponent(curr_url);
      axios
        .get(GET_IS_GUEST_LINK_VALID(encodedLink))
        .then((response) => {
          if (response?.data?.msg === "True") {
            setUrlConfig({
              loading: false,
              error: false,
            });
          } else {
            setUrlConfig({
              loading: false,
              error: true,
            });
          }
        })
        .catch(() => {
          setUrlConfig({
            loading: false,
            error: true,
          });
        });
    }
  }, [0]);

  return (
    <section className="guest-layout-container">
      {email && websiteurl && <GetGuestData {...{ email, websiteurl }} />}
      <Header />
      <div className="container height-no-page">
        {urlConfig?.loading ? (
          <Loader />
        ) : urlConfig?.error ? (
          <img src={expiredLinkImg} alt="expired" className="expired-image" />
        ) : (
          <>
            {(keyword_latest_data?.length > 0 && !showLoader) ||
            (keyword_old_data?.length > 0 && !showLoader) ? (
              <Outlet />
            ) : (keyword_latest_data?.length === 0 && !showLoader) ||
              (keyword_old_data?.length === 0 && !showLoader) ? (
              <img
                src={expiredLinkImg}
                alt="expired"
                className="expired-image"
              />
            ) : (
              <Spinner />
            )}
          </>
        )}
      </div>
      <Footer />
    </section>
  );
};

export default GuestLayout;
