import React from "react";
import { View, Text, StyleSheet, Link, Image, Font } from "@react-pdf/renderer";
import upArrowIcon from "../../Assets/pdf/green.png";
import downArrowIcon from "../../Assets/pdf/red.png";
import noArrowIcon from "../../Assets/pdf/gray.png";

import fontSrc from "../../font/Roboto/Roboto-Regular.ttf";
import fontSrc2 from "../../font/Roboto/Roboto-Light.ttf";
import fontSrc3 from "../../font/Roboto/Roboto-Medium.ttf";
import fontSrc4 from "../../font/Roboto/Roboto-Bold.ttf";

Font.register({
  family: "Roboto",
  fonts: [
    { src: fontSrc, fontStyle: "normal", fontWeight: 400 },
    { src: fontSrc2, fontStyle: "normal", fontWeight: 300 },
    { src: fontSrc3, fontStyle: "normal", fontWeight: 500 },
    { src: fontSrc4, fontStyle: "normal", fontWeight: 700 },
  ],
});

const styles = StyleSheet.create({
  table: {
    width: "100%",
    borderStyle: "solid",
    borderWidth: 1,
    borderColor: "#eaeaea",
    borderRadius: 8, // Added border-radius to the entire table
    overflow: "hidden", // Ensure border-radius is applied correctly
  },
  tableRow: {
    display: "flex",
    flexDirection: "row",
    borderBottomWidth: 1,
    borderColor: "#eaeaea",
    alignItems: "center",
    // borderRadius: 8, // Apply border-radius to each row
  },
  tableCell: {
    flex: 1,
    // padding: 8,
    borderRightWidth: 1,
    borderColor: "#eaeaea",
    justifyContent: "center",
    minHeight: 40,
    alignItems: "center",
    flexDirection: "row",
    // borderRadius: 8, // Apply border-radius to each cell
  },
  tableHeader: {
    fontFamily: "Roboto",
    fontSize: 9,
    textAlign: "center",
    fontWeight: "bold",
    color: "#333",
    // backgroundColor: "#f8f8f8",
    borderTopLeftRadius: 8, // Added border-radius to top left corner
    borderTopRightRadius: 8, // Added border-radius to top right corner
  },
  tableCellText: {
    fontFamily: "Roboto",
    fontSize: 9,
    textAlign: "center",
    color: "#333",
  },
  linkText: {
    color: "#1a73e8",
    textDecoration: "none",
    fontSize: 9,
    fontFamily: "Roboto",
  },
  positionContainer: {
    flexDirection: "row",
    alignItems: "center",
  },
  positionText: {
    fontFamily: "Roboto",
    marginLeft: 4,
    fontSize: 9,
  },
});

function hideDomain(url) {
  // if (url === "Not Found") return;
  try {
    const urlObject = new URL(url);
    if (urlObject.pathname === "/" && !urlObject.search && !urlObject.hash) {
      return url; // Return the full URL if it's only the domain
    }
    const hidden = `...${urlObject.pathname}${urlObject.search}${urlObject.hash}`;
    return hidden;
  } catch (error) {
    console.error("Invalid URL:", error);
    return url; // Return the original URL if invalid
  }
}

const Table = ({ headers, rows }) => (
  <View style={styles.table}>
    <View style={styles.tableRow}>
      {headers.map((header, index) => (
        <View
          key={index}
          style={[styles.tableCell, { backgroundColor: "#f8f8f8" }]}
        >
          <Text style={styles.tableHeader}>{header}</Text>
        </View>
      ))}
    </View>
    {rows.map((row, rowIndex) => (
      <View key={rowIndex} style={styles.tableRow}>
        {row.map((cell, cellIndex) => (
          <View key={cellIndex} style={styles.tableCell}>
            {cellIndex === 1 ? (
              <View style={styles.positionContainer}>
                <Text style={styles.tableCellText}>{cell.text}</Text>
                {cell.icon && (
                  <Image
                    src={cell.icon}
                    style={{ width: 12, height: 12, marginLeft: 4 }}
                  />
                )}
              </View>
            ) : cellIndex === 4 ? (
              cell === "Not Found" ? (
                <Text style={styles.tableCellText}>{cell}</Text>
              ) : (
                <Link style={styles.linkText} src={cell}>
                  {hideDomain(cell)}
                </Link>
              )
            ) : (
              <Text style={styles.tableCellText}>{cell}</Text>
            )}
          </View>
        ))}
      </View>
    ))}
  </View>
);

export default Table;
