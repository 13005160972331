import {
  reducerAllOldKEYWORDDATAData,
  reducerFnEight,
  reducerFnEightteen,
  reducerFnEle,
  reducerFnFIV,
  reducerFnFive,
  reducerFnFor,
  reducerFnFour,
  reducerFnnNinteen,
  reducerFnOne,
  reducerFnSixteen,
  reducerFnthir,
  reducerFnthr,
  reducerFnthree,
  reducerFnTwe,
  reducerFnTwenty,
  reducerFnTwentyONE,
  reducerFnTwentyTWO,
  reducerFntwo,
  reducerPlansdetails,
  reducerSelectDate,
  reducerShowMenu,
  reducerSelectedProjectAllId,
  reducerIsProject,
  reducerAddProjectLocation,
  reducerUserCurrentProjectLocation,
  reducerName,
  reducerEmail,
  reducerSelectedProjectNewdata,
  reducerFnInviteLimit,
  reducerFnInviteLength,
  reducerAddNewCompetitors,
  reducerCompetitorsLimit,
  reducerUserCompatitors,
  reducerDevicetype,
  reducerWebsiteUrl,
  reducerCurrentLocation,
  reducerCurrentLocationCode,
  reducerDomainViewLimit,
  reducerDropDown,
  reducerCrawlLimit,
  reducerCrawDoaminlLimit,
  reducerKeywordViewLimit,
  reducerTopicKeyword,
  reducerSeoKeyword,
  reducerIsplan,
  reducerBacklinkLimit,
  reducerBacklinkData,
  reducerBacklinkDomain,
  reducerUserAnalyticData,
  reducerUserAnalyticLogin,
  reducerSeoImpact,
  reducerSeoImpactKeyWord,
  reducerSMTPUser,
  reducerLogin,
  reducerProfile,
  reducerPdfViewerData,
  reducerClickConversion,
  reducerTopRankData,
  reducerChartImprData,
  reducerShowLoader,
  reducerUserOldDataWithLocation,
  reducerUserLatestDataWithLocation,
  reducerUserDataWithSelectedLocation,
  reducerSiteDomain,
} from "./upDown";

import { combineReducers } from "redux";

const RootReducer = combineReducers({
  loginout: reducerFnthree, // authentication user login or not
  name: reducerName, // user name
  email: reducerEmail, // user email
  loading: reducerFnFour, // loading function for loading
  getcountry: reducerFnEight, // get country data from rest api
  keyworddata: reducerFnFive, // KeywordData for User All Matched Keyword
  allprojectdetails: reducerFnSixteen, //  All Project Details from  DB Project Api
  userallprojectdetails: reducerFnEle, // User All Project Details from DB Project Api
  userallkeywordresult: reducerFnFor, // User All filtred Keyword From keywordData Redux
  userallpendingresult: reducerFnFIV, // User All Pending Keyword && Data
  previousallolddata: reducerFnTwe, // Previous All OLD data from DB oldData APi
  oldkeyworddata: reducerFnthir, // Last Day OLD Data
  userallprojectname: reducerFnOne, //User All Project NAME ONLY from DB Project Api
  userkeywordlimit: reducerFntwo, // user subscribe limit of keyword
  userprojectlimit: reducerFnthr, // user subscribe limit of Project
  plansdetails: reducerPlansdetails, // upgrade plans
  newprojecturl: reducerFnEightteen, // add project url reducer
  rankmovedup: reducerFnnNinteen, // rankmovedup keyword number
  rankmoveddown: reducerFnTwenty, // rankmoveddown keyword number
  userkeywordlength: reducerFnTwentyONE, // user current used keywordlength
  userprojectlength: reducerFnTwentyTWO, // user current used project length
  showmenu: reducerShowMenu, // show side Menu bar
  selectcustomdate: reducerSelectDate, //custom data selector
  alloldkeyworddata: reducerAllOldKEYWORDDATAData, // getting user all day keyword data
  userselectedprojectallid: reducerSelectedProjectAllId, //  getting selected pr all id
  isproject: reducerIsProject, // get details for user isproject
  addprojectlocation: reducerAddProjectLocation, // here we save add project selected locations for the add keyword
  usercurrentprojectlocation: reducerUserCurrentProjectLocation, // here we see user current selected project locations
  selectedprojectnewdata: reducerSelectedProjectNewdata, // here we got seleted project newdata keyword data from rest api
  subuserinvitelimit: reducerFnInviteLimit, // here we get user invite limitation
  subuserinvitelength: reducerFnInviteLength, // here we get user invite lrngth
  addnewcompetitors: reducerAddNewCompetitors, // add new competitors for new Project
  competitorslimit: reducerCompetitorsLimit, // here we get competitors limit by the plan .
  usercompatitors: reducerUserCompatitors, // user seleceted project compatitors .
  devicetype: reducerDevicetype, // user current device type
  websiteurl: reducerWebsiteUrl, // user current url
  currentlocation: reducerCurrentLocation, // user current location
  currentlocationcode: reducerCurrentLocationCode, // user current locationCode
  domainviewlimit: reducerDomainViewLimit, // user site domain limit set
  dropdown: reducerDropDown, // its takes dropdown type for open specific dropdown data type
  crawllimit: reducerCrawlLimit, // set site audit crawl limit
  crawldomainlimit: reducerCrawDoaminlLimit, // set site audit crawldoman limit
  keywordviewlimit: reducerKeywordViewLimit, // set keyword page view limit
  topicKeyword: reducerTopicKeyword,
  seoKeyword: reducerSeoKeyword,
  isplan: reducerIsplan,
  backlinkLimit: reducerBacklinkLimit,
  backlinkData: reducerBacklinkData,
  backlinkDomain: reducerBacklinkDomain,
  userAnalyticData: reducerUserAnalyticData,
  userAnalyticLogin: reducerUserAnalyticLogin,
  seoImpactUrl: reducerSeoImpact, // in Rank Traking page > table > Seo Impact
  seoImpactKeyWord: reducerSeoImpactKeyWord, // in Rank Traking page > table > Seo Impact
  smtpUser: reducerSMTPUser,
  login: reducerLogin,
  profile: reducerProfile,
  clickconversion: reducerClickConversion,
  pdfviewerdata: reducerPdfViewerData,
  toprankdata: reducerTopRankData,
  chartimprdata: reducerChartImprData,
  showloader: reducerShowLoader,
  olddatawithlocation: reducerUserOldDataWithLocation,
  latestdatawithlocation: reducerUserLatestDataWithLocation,
  userselectedlocation: reducerUserDataWithSelectedLocation,
  siteDomain: reducerSiteDomain,
});
export default RootReducer;
