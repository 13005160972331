import React from "react";
import "./home.scss";
import { Footer } from "../HeadFoot/footer/Footer";
// images
import LOGO1 from "../../assets/images/Global/logo.png";
import card1 from "../../assets/images/home/home-sec-2-1.png";
import card2 from "../../assets/images/home/card-2.png";
import card3 from "../../assets/images/home/card-3.png";
import imgSrc4 from "../../assets/images/home/home-sec-4.png";
import one from "../../assets/images/home/one.png";
import two from "../../assets/images/home/two.png";
import three from "../../assets/images/home/three.png";
import four from "../../assets/images/home/four.png";
import five from "../../assets/images/home/five.png";
import six from "../../assets/images/home/six.png";
import headerCircle from "../../assets/images/home/sec-1-elli.png";

import headerGirl from "../../assets/images/home/header-girl.png";
import sec_4_bk from "../../assets/images/home/sec-4-bk.svg";
import HeadingDesign from "../../components/heading-design";
import { Link } from "react-router-dom";
import useDeviceSize from "../../hooks/device-size";
import Accordion from "@mui/material/Accordion";
import AccordionDetails from "@mui/material/AccordionDetails";
import AccordionSummary from "@mui/material/AccordionSummary";
import Typography from "@mui/material/Typography";
import { GoChevronDown } from "react-icons/go";

function Card({ data }) {
  return (
    <div className="home-card card">
      <img src={data?.imgSrc} alt={data?.alt} />
      <span className="card-index" style={{ color: data?.color }}>
        {data?.id}
      </span>
      <span className="card-heading">{data?.name}</span>
      <p>{data?.content}</p>
    </div>
  );
}
function Affiliate() {
  const { isTab } = useDeviceSize();

  const acc_data = [
    {
      title: "Do I Need to Subscribe to Your Tool to Become an Affiliate? ?",
      content:
        "No. Whether you are a subscriber or not to our tool, you can become an affiliate partner with us. All you need to refer users to our tool, and once they subscribe to any of our plans (not the free one), you get to earn a 20% commission. If they subscribe to our monthly plans, you earn the commission for each month they subscribe. ",
    },
    {
      title:
        "What if Someone Who has Subscribed Through My Referral Link, Also Visits Your Site from Another Affiliate Partner? ",
      content:
        "In that case, the ‘First Cookie Wins’ model applies. This means the first referral link they come from and subscribe to our plan gets the commission. ",
    },
    {
      title: "How Can I Promote Your Referral Link On My Channels? ",
      content:
        "Just like you endorse your product, you can promote the referral link on your website, social networks, and more online channels. However, we have strict guidelines for that, for which you can refer to our ‘Terms and Sections’ condition. You cannot promote the referral link through paid or sponsored advertising, or on coupon aggregators and discount sites and channels. ",
    },
    {
      title: "Is There a Threshold for Minimum Amount Payout? ",
      content:
        "Yes, you need to earn at least $10 for that particular month, before you get the payment. Suppose you have earned $5 in one month, and $5 next month, then you will receive the payment on the next payout date. The payout is done once every month, on the 15th date. There is no limitation or capping of maximum commission. ",
    },
    {
      title:
        "Can I Become an Affiliate Partner if My Website or Social Media Channel is Not Related to Your Industry?",
      content:
        "Yes, an individual or business having a legitimate web presence can become our affiliate partner. However, you should not be running a coupon aggregate platform, coupon distribution sites, and platforms, or coupon stacking websites, and must prohibit using paid advertisements for referral link promotion. ",
    },
    {
      title:
        "What Do I Need to Do to Become an Affiliate Partner with RanksPro.io? ",
      content:
        "First, you need to create an account with us. If you already have an account, log in with your credentials and then register as an affiliate to get started. We will process your application, and once your registration is approved, you can start referring and earning. ",
    },
    {
      title:
        "Can I Use RanksPro.io Tool Even When I am an Affiliate Partner?  ",
      content:
        "Yes, you can easily use our tool for your SEO efforts even when you are an affiliate partner with us. Both are separate aspects. However, you are forbidden to subscribe to our plan using your affiliate referral link.  ",
    },
    {
      title: "Will the Affiliate Commission be 20% Forever?  ",
      content:
        "At present, we are rolling out the affiliate program at a fixed 20% commission rate for referral subscriptions to our lifetime as well as monthly plans. However, in the future, this can be increased or decreased, the decision of which lies at our discretion.   ",
    },
  ];

  const sec_1_data = [
    {
      id: "01",
      color: "#FF9232",
      imgSrc: card1,
      alt: "Sign up Affiliate Program to Empower Your Earnings",
      name: "Sign Up",
      content:
        "Register as an affiliate on our site with minimal required information. Start referring and earning!",
    },
    {
      id: "02",
      color: "#007F61",
      imgSrc: card2,
      alt: "Get Extra Rewards with Our Referral Program",
      name: "Recommend",
      content:
        "Promote RanksPro.io with a referral link with your audience on your website, social accounts, etc. ",
    },
    {
      id: "03",
      color: "#365486",
      imgSrc: card3,
      alt: "Earn More with Every Recommendation for affiliate program",
      name: "Earn",
      content:
        "Once your referrals sign up on RanksPro.io as a user and subscribe to our tool, you become eligible to start earning a 20% commission for each sale. ",
    },
  ];

  const content = "Refer your audience to us and watch your revenue grow.";

  const Sec_6 = [
    {
      img: one,
      title: "Quick Account Setup",
      alt: "Easy Account Setup for Instant Affiliate Profits",
      meta: "It takes only a couple of minutes to register with us as an affiliate. Fill in the registration form, and you are all set. Already have an account? Log in with your credentials and become an affiliate even if your subscription has expired.",
    },
    {
      img: two,
      title: "Endorse the Referral Link",
      alt: "Share the Success - Endorse Your Referral Link and Start Earning",
      meta: "You can use and promote the referral link across your website, social channel accounts, emails, blogs, forums, etc. You become eligible to earn a commission when users come through your referral link and subscribe to any of our plans.",
    },
    {
      img: three,
      title: "Earn Commission on a Lifetime or Recurring Monthly Basis",
      alt: "Earn Commissions Monthly with Consistent Rewards and Lifetime Gains",
      meta: "Depending on whether your referrals sign up for our monthly plans or lifetime plans, you earn a commission specific to that. Suppose, a user subscribes to our monthly plan for 6 months continually, you will earn 20% commission each month of the monthly plan they have subscribed to.",
    },
    {
      img: four,
      title: "‘First Cookie Wins’ Model",
      alt: "First Cookie Wins and Secure Your Affiliate Success with Prime Clicks",
      meta: "When you bring a referral user to our site, and they visit us through you before any other affiliate, we store their cookies for 120 days. This means they are attached to you when they sign up. Whenever they subscribe to a plan within those 12 days, you are the one earning commission from that sale.",
    },
    {
      img: five,
      title: "Payout Once Every Month",
      alt: "Consistent Earnings at every Single Month",
      meta: "You will receive the commission payout once, on the 15th date of every month. The threshold for payout is a minimum of U.S. $10 for that particular month. Once your referral user subscribes to our plan, the payout is done after a minimum period of 15 days of the sale, on the subsequent 15th date of the next month.",
    },
    {
      img: six,
      title: "Be Updated with Your Referral Account Analytics",
      alt: "Referral Dashboard to Check Out for Earnings and Growth Meet",
      meta: "Our dashboard provides easy access to help you track your referral registrations, your earnings, and unique referrals each month, updated in real-time. Access our marketing materials, gain insights to promote our product to your audience network, and improve your referral earning potential.",
    },
  ];

  const [expanded, setExpanded] = React.useState(false);
  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

  return (
    <div>
      <div className="header-home">
        <div className="FirstBar">
          <div className="row">
            <div className="col-6">
              <div className="hd_left">
                <div className="hd_logo">
                  <img src={LOGO1} alt="RanksPro.io Logo" />
                </div>
              </div>
            </div>
            <div className="col-6 d-flex align-items-center justify-content-end">
              <div className="hd_RIght">
                <Link to={`/affiliate/signup`}>
                  <button>Register Now</button>
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="home-container">
        <div className="section-1">
          <img
            src={headerCircle}
            alt="Earn Big with RanksPro.io"
            className="header-circle"
          />
          <div className="aff-container">
            <div className="aff-content">
              <h1 className="heading">Earn 30% Commission with </h1>
              <h1 className="heading">
                RanksPro.io <HeadingDesign>Affiliate Program.</HeadingDesign>
              </h1>
              {content && <p className="seo-details">{content}</p>}
              <Link to={`/affiliate/signup`}>
                <button className="section-1-btn">Become An Affiliate</button>
              </Link>
            </div>
            <div className="content-2">
              <img
                src={headerGirl}
                alt="Get Profit Fluency with RanksPro.io Affiliate Program"
              />
            </div>
          </div>
        </div>
        <div className="section-2">
          <div className="header">
            <h1>
              Join Today –Start Earning{isTab && <br />}
              <HeadingDesign> Every Month! </HeadingDesign>
            </h1>
            <p>
              Earn from every monthly & lifetime subscription sale. Get paid
              every month and see your revenue grow!
            </p>
          </div>
          <div className="row g-3 g-lg-3 ">
            {sec_1_data?.map((item) => {
              return (
                <div className="col-lg-3 col-md-4 " key={item?.name}>
                  <Card data={item} />
                </div>
              );
            })}
          </div>
        </div>

        <div className="section-6">
          <div className="sec-6">
            <div className="header">
              <h1>
                RanksPro.io
                <HeadingDesign>
                  Affiliate Program Terms & Features
                </HeadingDesign>
              </h1>
            </div>
            <div className="Boxed_">
              <div className="row m-0">
                {Sec_6?.map((Elem) => (
                  <div className="col-lg-4 col-md-6 col-12" key={Elem.title}>
                    <div className="bx" style={{ height: "95%" }}>
                      <div className="img-bx">
                        <img src={Elem.img} alt={Elem.alt} />
                      </div>
                      <div className="cont_">
                        <div className="title">{Elem.title}</div>
                        <div className="metas">{Elem.meta}</div>
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>
        <div className="section-7">
          <div className="sec_7_body">
            <p>
              Ready to start earning a 20% referral commission with each
              subscription sale? Become a member of our SEO affiliate program
              today!
            </p>
          </div>
        </div>
        <div className="section-5">
          <div className="row">
            <div className="col-auto col-lg-6 cont-1 position-relative">
              <img src={imgSrc4} alt="FAQs for Quick Solutions" />
            </div>
            <div className="col-12 col-lg-6">
              <div className="content">
                <h1>
                  Frequently Asked
                  <HeadingDesign>Questions</HeadingDesign>
                </h1>
                <div className="accordion">
                  <div>
                    {acc_data?.map((item, index) => {
                      return (
                        <Accordion
                          expanded={expanded === `panel${index}`}
                          onChange={handleChange(`panel${index}`)}
                          className="accord"
                        >
                          <AccordionSummary
                            expandIcon={<GoChevronDown />}
                            aria-controls="panel1bh-content"
                            id="panel1bh-header"
                          >
                            <Typography sx={{ width: "100%", flexShrink: 0 }}>
                              {item?.title}
                            </Typography>
                          </AccordionSummary>
                          <AccordionDetails>
                            <Typography>{item?.content}</Typography>
                          </AccordionDetails>
                        </Accordion>
                      );
                    })}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
}
export default Affiliate;
