import React, { useEffect } from "react";
import GetCustomer from "./getCustomer";
import GetProfile from "./getProfile";
import { useSelector, useDispatch } from "react-redux";
import GetLatestData from "./getLatestData";
import GET_COUNTRY from "./getCountry";

const MANAGEDATA_CONFIGURE = ({ admin }) => {
  const dispatch = useDispatch();
  const email = useSelector((state) => state.email);
  GET_COUNTRY();

  useEffect(() => {
    setTimeout(() => {
      admin && dispatch({ type: "NOTLOADING" });
    }, 1000);
  }, []);

  return (
    <>
      <GetProfile />
      {email !== null ? (
        <>
          <GetCustomer />
          {!admin && <GetLatestData />}
        </>
      ) : null}
    </>
  );
};

export default MANAGEDATA_CONFIGURE;
