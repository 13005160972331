import React, { useRef } from "react";
import { styled } from "@mui/material/styles";
import Tooltip, { tooltipClasses } from "@mui/material/Tooltip";
import { NavLink } from "react-router-dom";
import { useClickAway } from "react-use";

const HtmlTooltip = styled(({ className, ...props }) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.arrow}`]: {
    color: "rgba(0, 0, 0, 0.8)",
    "&::before": {
      backgroundColor: "#4d4d4d",
      //   border: "1px solid #e95655",
    },
  },
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: "#4d4d4d",
    color: "rgba(0, 0, 0, 0.8)",
    maxWidth: 220,
    fontSize: theme.typography.pxToRem(14),
    marginLeft: "0px !important",
    // border: "1px solid #e95655",
  },
}));

export default function CustomizedTooltips({ data, handleAction, children }) {
  const tooltipRef = useRef(null);
  const handleClick = () => {
    handleAction();
  };

  useClickAway(tooltipRef, () => {
    handleAction();
  });

  return (
    <HtmlTooltip
      placement="right"
      PopperProps={{
        disablePortal: true,
      }}
      open={data.myArray?.includes(data.data.name)}
      disableFocusListener
      disableHoverListener
      disableTouchListener
      title={
        <React.Fragment>
          {data?.data?.sub_list?.length > 0 ? (
            <ul
              className="sidebar_menu m-0"
              style={{ width: "150px" }}
              ref={tooltipRef}
            >
              {data?.data?.sub_list?.map((item) => {
                return (
                  <li key={item.name} onClick={() => handleClick()}>
                    <NavLink
                      to={item.url}
                      className={
                        item?.path?.includes(data.pathname)
                          ? "active p-0 m-0"
                          : "p-0 m-0"
                      }
                    >
                      <span className="sidebar-list-item ms-3">
                        {item.name}
                      </span>
                    </NavLink>
                  </li>
                );
              })}
            </ul>
          ) : null}
        </React.Fragment>
      }
    >
      {children}
    </HtmlTooltip>
  );
}
