import React, { useState, useMemo, useRef } from "react";
import { ReactComponent as UnsortIcon } from "../../Assets/rank-tracking-assets/unshort.svg";
import Spinner from "../components/spinner";
import Tooltip from "../components/tooltip";

const CrawledPagesTable = ({data}) => {
  const [sortConfig, setSortConfig] = useState({ key: null, direction: "asc" });
  const [loading, setLoading] = useState(false);
  
  const rows = useMemo(() => {
    return data?.map((item) => {
      return {
        title: item?.meta?.title,
        url: item.url,
        inbound_links_count: item?.meta?.inbound_links_count,
        internal_links_count: item.meta?.internal_links_count,
        onpage_score: item?.onpage_score,
        external_links_count: item?.meta?.external_links_count,
        resource_type: item?.resource_type,
      };
    });
  }, [data]);

  const sortedTableData = useMemo(() => {
    if (!sortConfig.key) return rows;
    return [...rows].sort((a, b) => {
      const aValue = a[sortConfig.key];
      const bValue = b[sortConfig.key];

      const isANoRank = aValue === undefined;
      const isBNoRank = bValue === undefined;

      if (isANoRank && isBNoRank) return 0;
      if (isANoRank) return 1;
      if (isBNoRank) return -1;

      if (sortConfig.direction === "asc") {
        return aValue > bValue ? 1 : -1;
      } else {
        return aValue < bValue ? 1 : -1;
      }
    });
  }, [rows, sortConfig]);

  const requestSort = (key) => {
    setLoading(true);
    setTimeout(() => {
      setLoading(false);
    }, 10);
    let direction = "asc";
    if (sortConfig.key === key && sortConfig.direction === "asc") {
      direction = "desc";
    }
    setSortConfig({ key, direction });
  };

  return (
    <div className="custom-table-container mt-4">
      <table className="site-audit-table m-0" style={{minWidth: "1260px"}}>
        <thead>
          <tr className="table-sticky">
            <th
              scope="col"
              className="text-start"
              style={{ fontSize: "0.9em",width:"500px" }}
            >
              <span className="point" onClick={() => requestSort("title")}>
                Pages
                <span className="ms-2">
                  <UnsortIcon />
                </span>
              </span>
            </th>
            <th
              scope="col"
              className="text-center"
              style={{ padding: "12px", width: "240px", textWrap: "nowrap" }}
            >
              <span
                className="point"
                onClick={() => requestSort("inbound_links_count")}
              >
                Inbound Links Count
                <span className="ms-2">
                  <UnsortIcon />
                </span>
                <Tooltip title="Inbound links, also known as backlinks, occur when another website links back to your site." />
              </span>
            </th>
            <th
              scope="col"
              className="text-center"
              style={{ padding: "12px", width: "220px", textWrap: "nowrap" }}
            >
              <span
                className="point"
                onClick={() => requestSort("internal_links_count")}
              >
                Internal Links Count
                <span className="ms-2">
                  <UnsortIcon />
                </span>
                <Tooltip title="An internal link is any link from one page on your website to another page on your website" />
              </span>
            </th>
            <th
              className="text-center"
              scope="col"
              style={{ padding: "12px", width: "200px", textWrap: "nowrap" }}
            >
              <span
                className="point"
                onClick={() => requestSort("onpage_score")}
              >
                Onpage Score
                <span className="ms-2">
                  <UnsortIcon />
                </span>
                <Tooltip title="The on-page SEO score measures how well a page is optimized for search engines, including the right keyword density calculation." />
              </span>
            </th>
            <th
              scope="col"
              className="text-center"
              style={{ padding: "12px", width: "200px", textWrap: "nowrap" }}
            >
              <span
                className="point"
                onClick={() => requestSort("external_links_count")}
              >
                External Links Count
                <span className="ms-2">
                  <UnsortIcon />
                </span>
                <Tooltip title="An external link is a hyperlink that leads to a page or resource outside a particular website" />
              </span>
            </th>
            <th
              scope="col"
              className="text-center"
              style={{ width: "230px", textAlign: "center" }}
            >
              Status
            </th>
          </tr>
        </thead>
        <tbody>
          {data?.length !== 0 ? (
            loading ? (
              <tr style={{ height: "300px" }}>
                <td colSpan={6}>
                  <Spinner />
                </td>
              </tr>
            ) : (
              sortedTableData?.map((res, index) => {
                return (
                  <tr key={index}>
                    <td
                      colSpan={1}
                      style={{
                        maxWidth: "",
                        padding: "12px 30px",
                      }}
                    >
                      <div >
                      <span className="">{res?.title}</span>
                      <a
                        href={res.url === "undefined" ? "#" : res.url}
                        target="_blank"
                        rel="noreferrer"
                        style={{
                          maxWidth: "100%",
                          color: "rgb(54, 84, 134)",
                        }}
                        className=""
                      >
                        {res?.url}{" "}
                      </a>
                      </div>
                    </td>

                    <td
                      className="text-center"
                      style={{
                        fontWeight: res?.inbound_links_count ? "400" : "700",
                      }}
                    >
                      {res?.inbound_links_count || "-"}
                    </td>
                    <td
                      className="text-center"
                      style={{
                        fontWeight: res?.internal_links_count ? "400" : "700",
                      }}
                    >
                      {res?.internal_links_count || "-"}
                    </td>
                    <td
                      className="text-center"
                      style={{
                        fontWeight: res?.onpage_score ? "400" : "700",
                      }}
                    >
                      {res?.onpage_score || "-"}
                    </td>
                    <td
                      className="text-center"
                      style={{
                        fontWeight: res?.external_links_count ? "400" : "700",
                      }}
                    >
                      {res?.external_links_count || "-"}
                    </td>

                    <td colSpan={1} className="show-page-status">
                      <span
                        className=""
                        style={
                          res.resource_type === "html"
                            ? {
                                borderColor: "rgba(53, 114, 77, 1)",
                                backgroundColor: "rgba(95, 166, 128, 0.6)",
                              }
                            : res.resource_type === "redirect"
                            ? {
                                borderColor: "rgba(252, 135, 85, 1)",
                                backgroundColor: "rgba(255, 147, 47, 0.45)",
                              }
                            : {
                                borderColor: "rgba(129, 13, 13, 0.8)",
                                backgroundColor: "rgba(254, 54, 54, 0.6)",
                              }
                        }
                      >
                        {res.resource_type === "html"
                          ? "Successful"
                          : res.resource_type}
                      </span>
                    </td>
                  </tr>
                );
              })
            )
          ) : (
            <tr>
              <td colSpan={6}>You don't have any data !</td>
            </tr>
          )}
        </tbody>
      </table>
    </div>
  );
};

export default React.memo(CrawledPagesTable);
