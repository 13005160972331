import axios from "axios";
import React, { useState } from "react";
import { POST_FEEDBACK } from "../../../services/constants";
import { useSelector } from "react-redux";
import IconButton from "@mui/material/IconButton";
import HighlightOffIcon from "@mui/icons-material/HighlightOff";
import "../../../admin/css/feedback-modal.scss";
import { Button } from "@mui/material";

const FeedBackOverlay = ({ toggleOverlay }) => {
  const [feedText, setFeedText] = useState("");
  const [characterCount, setCharacterCount] = useState(0);
  const email = useSelector((state) => state.email);

  const MAX_CHARS = 2500;

  const feedBackChangeHandler = (e) => {
    if (e.target.value.length <= MAX_CHARS) {
      setFeedText(e.target.value);
      setCharacterCount(e.target.value.length);
    }
  };

  const FeedBackHandler = () => {
    if (feedText === "") {
      // setError("Please Enter feedback to submit");
    } else {
      const url = window.location.href;
      const item = {
        email: email,
        feedback: feedText,
        url: url,
      };
      axios.post(POST_FEEDBACK(), item).then(() => {
        toggleOverlay(false);
        setFeedText("");
      });
    }
  };

  return (
    <div className="modal-feedback">
      <h1 className="table_title heading-xlarge font-bold mb-4 feedback-modal">
        FEEDBACK
        <IconButton
          className="feedback-close-icon"
          onClick={() => toggleOverlay(false)}
          sx={{
            "&:hover": {
              backgroundColor: "rgba(240, 242, 255, 1)",
            },
          }}
        >
          <HighlightOffIcon sx={{ color: "black" }} fontSize="large" />
        </IconButton>
      </h1>
      <div class="quote mb-4">
        <span class="left">❝</span>
        <textarea
          type="text"
          placeholder="Your feedback will help us to improve our self ..."
          value={feedText}
          className="text-area-feedback"
          onChange={(e) => feedBackChangeHandler(e)}
        ></textarea>
        <span class="right">❞</span>
      </div>
      {/* {error && <span className="w-75 vl-msd-line">{error}</span>} */}
      <div
        className="d-flex align-items-center justify-content-between m-auto feedback-modal"
        style={{ width: "80%" }}
      >
        <span className="heading-small">
          <span className="font-bold">{characterCount}</span> /{" "}
          <span id="characterCounter">{MAX_CHARS} </span>{" "}
        </span>
        <button
          className="cm-btn px-4 py-2"
          onClick={() => FeedBackHandler()}
          disabled={feedText === ""}
        >
          Submit
        </button>
      </div>
    </div>
  );
};

export default FeedBackOverlay;
