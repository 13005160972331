import axios from "axios";
import React, { useMemo, useState, useEffect, useRef } from "react";
import Papa from "papaparse";
import { useSelector } from "react-redux";
import { Link, useLocation, useNavigate } from "react-router-dom";
import {
  KEYWORD_POST,
  PROJECT_POST,
  UPDATE_KEYWORD,
} from "../../../services/constants";
import { ListItem, Typography } from "@mui/material";
import ProjectStepper from "./project-stepper";
import backIcon from "../../Assets/common/back.svg";

export const AddKeyword = () => {
  const fileInputRef = useRef(null);
  // navigator
  const navigate = useNavigate();
  const Param = useLocation();
  const paramType = Param.search.replace("?", "");
  const userKeywordlimit = useSelector((state) => state.userkeywordlimit);
  const UserKeywordLength = useSelector((state) => state.userkeywordlength);
  const NewProjectUrl = useSelector((state) => state.newprojecturl);
  const userselectedprojectallid = useSelector(
    (state) => state.userselectedprojectallid
  );
  const addprojectlocation = useSelector((state) => state.addprojectlocation);
  const addnewcompetitors = useSelector((state) => state.addnewcompetitors);

  // UseSelector
  const CountryData = useSelector((state) => state.getcountry);
  const admin_mail = localStorage.getItem("admin_mail");
  const language = localStorage.getItem("language");
  const webURL = useSelector((state) => state.websiteurl);
  const ProjectDetails = JSON.parse(localStorage.getItem("ProjectsDetail"));
  const LocationDetails = JSON.parse(localStorage.getItem("Locationdetails"));
  const LocationNameArray = [];

  useEffect(() => {
    LocationDetails?.Country?.map((res) => {
      if (paramType === "addproject") {
        LocationNameArray.push(res.location_name);
      } else {
        LocationNameArray.push(res);
      }
    });
  }, [0]);

  // useState data hooks
  const [keyword, setKeyword] = useState("");
  const [item, setItem] = useState([]);
  const [itemAlert, setItemAlert] = useState(false);
  const [deviceAlert, setDeviceAlert] = useState(false);
  const [minLenth, setMinLengthAlert] = useState(false);
  const [sameKeyAlert, setSameKeyALert] = useState(false);
  const [Loading, setLoading] = useState(false);
  const [Data, setData] = useState([]);
  const [FinalData, setFinalData] = useState([]);
  const [deviceType, setDeviceType] = useState([]);

  // useRef data hooks
  const messagesEndRef = useRef(null);
  const sameKeyword = useRef(false);
  const ALLLocationCode = useRef([]);
  const inputRef = useRef(null);
  const desktop = useRef(null);
  const mobile = useRef(null);

  // TempArray
  const [Temp, setTemp] = useState([]);

  useEffect(() => {
    if (NewProjectUrl === false) {
      navigate("/dashboard");
    }
  });

  // this useEffect function for ctrl + / target input
  useEffect(() => {
    function handleKeyDown(event) {
      if (event.ctrlKey && event.key === "/") {
        event.preventDefault(); // prevent default behavior of the browser
        inputRef.current.focus();
      }
    }

    document.addEventListener("keydown", handleKeyDown);
    return () => {
      document.removeEventListener("keydown", handleKeyDown);
    };
  }, []);

  const FetchKeyWords = async () => {
    setLoading(true);
    try {
      // Free Api
      const api = `https://app.rankspro.io/api/user/relakeywordget/?domain_name=${
        ProjectDetails?.domain
      }&language_name=${LocationDetails?.Lang}&location_name=${JSON.stringify(
        LocationNameArray
      )}`;
      // Paid Api
      const apiPaid = `https://app.rankspro.io/api/user/relakeyword/?domain_name=${
        ProjectDetails?.domain
      }&language_name=${LocationDetails?.Lang}&location_name=${JSON.stringify(
        LocationNameArray
      )}`;
      await axios.get(api).then(({ data }) => {
        if (data.length !== 0) {
          setLoading(false);
          data?.map((val) => {
            return val?.data?.map((data) => {
              setData((old) => {
                return [...old, { ...data, location: val.location }];
              });
            });
          });

          return;
        } else {
          axios.post(apiPaid).then(({ data }) => {
            setLoading(false);
            data?.map((val) => {
              return val?.data?.map((data) => {
                setData((old) => {
                  return [...old, { ...data, location: val.location }];
                });
              });
            });
          });
        }
      });
    } catch (error) {
      console.log(error);
    }
  };

  const uniqueKeywords = new Set(); // Filter the array to keep only objects with unique keywords
  const uniqueLocations = useMemo(() => {
    const array =
      Data &&
      Data?.filter((location) => {
        const isUnique = !uniqueKeywords.has(location.keyword);
        if (isUnique) {
          uniqueKeywords.add(location.keyword);
        }
        return isUnique;
      });

    const locationFlagMap = {};

    if (CountryData?.length > 0) {
      for (const item of CountryData) {
        const locationName = item.location_name;
        const locationFlag = item.location_flag;
        locationFlagMap[locationName] = locationFlag;
      }

      // Update the "location_flag" key in the "myArray" objects based on the "getArray" data
      for (const item of array) {
        const locationName = item.location;
        if (locationFlagMap.hasOwnProperty(locationName)) {
          item.location_flag = locationFlagMap[locationName];
        }
      }
    }

    return array;
  }, [Data, CountryData]);

  useEffect(() => {
    FetchKeyWords();
  }, [0]);

  // add keyword input value functions
  const ItemEvent = (e) => {
    setKeyword(e.target.value);
    setItemAlert(false);
    setMinLengthAlert(false);
    setSameKeyALert(false);
    setDeviceAlert(false);
  };

  const arrayFinal = useMemo(() => {
    function makeArrayUnique(arr) {
      return [...new Set(arr)];
    }
    const NewArray = [];
    Temp?.map((res) => {
      NewArray.push(res.keyword);
    });
    const originalArray = [...NewArray, ...item];
    const uniqueArray = makeArrayUnique(originalArray);
    return uniqueArray;
  }, [item, Temp]);

  var curr_keyword = deviceType?.length * arrayFinal?.length;
  var total_keyword = curr_keyword + Number(UserKeywordLength);

  // keyword delete by id
  const DeleteKey = (key) => {
    const array = arrayFinal?.filter((keyword) => {
      return keyword !== key;
    });
    setTemp([]);
    setItem(array);
    if (total_keyword <= Number(userKeywordlimit)) {
      setItemAlert(false);
    }
  };

  // set keyword to the array form with conditions
  const Listofitems = (e) => {
    const NewArray = [];
    Temp?.map((res) => {
      NewArray.push(res.keyword);
    });
    const output = NewArray?.includes(keyword);
    e.preventDefault();

    item.filter((filtered) => {
      if (filtered === keyword) {
        sameKeyword.current = filtered;
      }
    });

    if (keyword.trim().length === 0) {
      e.preventDefault();
    } else if (deviceType?.length === 0) {
      setDeviceAlert(true);
    } else if (keyword === sameKeyword.current) {
      setSameKeyALert(true);
      localStorage.removeItem("filtered");
    } else if (
      arrayFinal?.length * deviceType?.length * addprojectlocation.length +
        Number(UserKeywordLength) >=
      Number(userKeywordlimit)
    ) {
      setItemAlert(true);
    } else if (output === true) {
      setSameKeyALert(true);
    } else {
      setItem((olditems) => {
        return [...olditems, keyword];
      });

      Temp.map((res) => {
        setFinalData([...FinalData, ...item, res.keyword]);
      });

      setKeyword("");
      setItemAlert(false);
      setMinLengthAlert(false);
      setSameKeyALert(false);
    }
  };

  const toggleDeviceType = (device, deviceName) => {
    if (device.current === null) {
      device.current = deviceName;
      setDeviceType((prevDeviceType) => [...prevDeviceType, deviceName]);
      setDeviceAlert(false);
    } else {
      device.current = null;
      setDeviceType((prevDeviceType) =>
        prevDeviceType.filter((type) => type !== deviceName)
      );
    }
  };

  const handleDesktopToggle = () => {
    if (
      arrayFinal.length * deviceType?.length * addprojectlocation.length +
        Number(UserKeywordLength) >=
      Number(userKeywordlimit)
    ) {
      setItemAlert(true);
    } else {
      toggleDeviceType(desktop, "desktop");
    }
  };

  const handleMobileToggle = () => {
    if (
      arrayFinal.length * deviceType?.length * addprojectlocation.length +
        Number(UserKeywordLength) >=
      Number(userKeywordlimit)
    ) {
      setItemAlert(true);
    } else {
      toggleDeviceType(mobile, "mobile");
    }
  };

  // on submit data send to the rest api
  const getproject = () => {
    if (deviceType?.length === 0) {
      setDeviceAlert(true);
    } else if (arrayFinal.length === 0) {
      setMinLengthAlert(true);
    } else if (
      arrayFinal.length * deviceType?.length * addprojectlocation.length +
        Number(UserKeywordLength) >
      Number(userKeywordlimit)
    ) {
      setItemAlert(true);
    } else if (webURL !== null && NewProjectUrl === webURL) {
      // that condition run when the user come from add keyword btn from ranktable

      const dataTwo =
        addprojectlocation &&
        addprojectlocation.map((loca) => {
          return (
            deviceType &&
            deviceType?.map((detype) => {
              return (
                arrayFinal &&
                arrayFinal.map((itemData) => {
                  return {
                    keyword: itemData,
                    language_code: language,
                    location_code: loca.location_code,
                    device: detype,
                  };
                })
              );
            })
          );
        });

      const datatwoHandler = { data: dataTwo.flat() };
      axios.post(KEYWORD_POST(), datatwoHandler);

      addprojectlocation &&
        addprojectlocation.filter((loca) => {
          return ALLLocationCode.current.push(loca.location_code);
        });

      const itemData = JSON.stringify(arrayFinal);
      const deviceTypeData = JSON.stringify(deviceType);
      const locations = JSON.stringify(ALLLocationCode.current);
      const updatekeyword_Json = {
        id: userselectedprojectallid[0],
        key: itemData,
        device: deviceTypeData,
        location: locations,
      };
      axios.put(UPDATE_KEYWORD(updatekeyword_Json));
      navigate("/addpr/gotraffic");
    } else {
      // posting keywords
      const dataTwo =
        addprojectlocation &&
        addprojectlocation.map((loca) => {
          return (
            deviceType &&
            deviceType?.map((detype) => {
              return (
                arrayFinal &&
                arrayFinal.map((itemData) => {
                  return {
                    keyword: itemData,
                    language_code: language,
                    location_code: loca.location_code,
                    device: detype,
                  };
                })
              );
            })
          );
        });

      const datatwoHandler = { data: dataTwo.flat() };
      axios.post(KEYWORD_POST(), datatwoHandler);

      const data =
        addprojectlocation &&
        addprojectlocation.map((loca) => {
          return (
            deviceType &&
            deviceType?.map((dataType) => {
              return {
                email: admin_mail,
                weburl: NewProjectUrl,
                deviceType: dataType,
                language_code: language,
                location_name: loca.location_name,
                location_code: loca.location_code,
                keyword: arrayFinal,
                competitors: addnewcompetitors,
              };
            })
          );
        });

      const dataHandler = {
        keyword: data.flat(),
      };
      axios.post(PROJECT_POST(), dataHandler);
      navigate("/addpr/gotraffic");
    }
  };

  const SampleCSV = [
    { keyword: "keyword-1" },
    { keyword: "keyword-2" },
    { keyword: "keyword-3" },
  ];

  const downloadCSV = () => {
    const csv = Papa.unparse(SampleCSV);
    const blob = new Blob([csv], { type: "text/csv;charset=utf-8;" });
    const url = URL.createObjectURL(blob);
    // Create a link to trigger the download
    const link = document.createElement("a");
    link.href = url;
    link.setAttribute("download", "keyword_sample_csv");
    // Append to the document to trigger the click event
    document.body.appendChild(link);
    link.click();
    // Clean up and remove the link
    document.body.removeChild(link);
  };

  const handleFileUpload = (event) => {
    const file = event.target.files[0];
    if (!file) return;
    Papa.parse(file, {
      header: true,
      complete: (results) => {
        const keywordSet = results?.data;
        if (keywordSet) {
          keywordSet?.map((res) => {
            setItem((olditems) => {
              return [...olditems, res.keyword || res.Keyword];
            });
          });
        }
        // Reset the file input value
        fileInputRef.current.value = "";
      },
    });
  };

  const handleMessagePaste = (event) => {
    event.preventDefault();
    if (deviceType?.length === 0) {
      setDeviceAlert(true);
    } else if (
      arrayFinal?.length * deviceType?.length * addprojectlocation.length +
        Number(UserKeywordLength) >=
      Number(userKeywordlimit)
    ) {
      setItemAlert(true);
    } else {
      const clipboardText = event.clipboardData.getData("text/plain");
      const messages = clipboardText.split(/[\n;,]/);
      setItem(item.concat(messages));
    }
  };

  function handleKeyDown(event) {
    if (event.key === ",") {
      Listofitems(event);
    }
  }

  const removeDupTrim = () => {
    const uniqueArray = item
      ?.map((str) => str.trim()) // Trim whitespace from each string
      .filter((str, index, arr) => arr.indexOf(str) === index); // Remove duplicates
    setItem(uniqueArray);
  };

  return (
    <section className="">
      <div className="cmd">
        <Link to={-1}>
          <button className="icon-btn large-btn">
            <img src={backIcon} alt="back button" />
          </button>
        </Link>
        <ProjectStepper activeStep={3} />
        <div></div>
      </div>
      <div className="add-pr-url w-100">
        <h1 className="">Add Keyword</h1>
        <p className="mb-0 proj-tag-line">
          Track keywords to get regular ranking updates and daily content
          opportunities
        </p>
        <div className="mt-3 setMobCmd cmd w-100">
          <div>
            <label htmlFor="typedesktop">
              <div className="dev-btn">
                <input
                  className="m-0"
                  id="typedesktop"
                  name="typedesktop"
                  value={desktop}
                  type="checkbox"
                  onChange={handleDesktopToggle}
                />
                <span className="ms-1 proj-tag-line">Desktop</span>
              </div>
            </label>
            <label htmlFor="typemobile">
              <div className="dev-btn">
                <input
                  className="m-0"
                  id="typemobile"
                  name="typemobile"
                  value={mobile}
                  type="checkbox"
                  onChange={handleMobileToggle}
                />
                <span className="ms-1 proj-tag-line"> Mobile</span>
              </div>
            </label>
          </div>
          <div className="proj-tag-line">
            Keyword : <span className="font-bold">{total_keyword}</span>
          </div>
        </div>
        <div className="setMobCmd cmd mb-2 mt-5">
          <h5 className="proj-tag-line">Suggested Keywords :</h5>
        </div>
        <div className="row m-0 p-0">
          <div className="col-md-6 mt-2 m-md-0 m-lg-0 p-0 pb-4 pe-md-1 pe-lg-1">
            <div className="SuggestionBox p-3 text-bold list-scroll">
              {Loading ? (
                <div>
                  <div
                    className="d-flex justify-content-center align-items-center "
                    style={{ height: "20vh" }}
                  >
                    <div className="spinner-border" role="status">
                      <span className="visually-hidden"></span>
                    </div>
                  </div>
                </div>
              ) : uniqueLocations?.length != 0 ? (
                uniqueLocations?.slice(2)?.map((result, index) => {
                  return (
                    <ListItem
                      className={
                        arrayFinal?.includes(result.keyword)
                          ? "initialHide initialShow"
                          : "initialShow"
                      }
                      style={{ width: "43%" }}
                      onClick={() => {
                        if (deviceType?.length === 0) {
                          setDeviceAlert(true);
                          return;
                        }
                        if (total_keyword >= Number(userKeywordlimit)) {
                          setItemAlert(true);
                        } else {
                          setTemp((old) => {
                            return [
                              ...old,
                              { ...result, id: index, type: "suggestion" },
                            ];
                          });
                        }
                      }}
                      key={index}
                      role="listitem"
                    >
                      {" "}
                      <div>
                        <Typography
                          className="heading-medium font-medium"
                          id={0}
                        >
                          {result.keyword}
                        </Typography>
                        <div className="d-flex align-items-center">
                          <div className="heading-small font-medium">
                            <span className="font-bold">Your Ranking : </span>
                            {result.rank_group}
                          </div>
                          &nbsp;&nbsp;| &nbsp;&nbsp;
                          <div className="heading-small font-medium">
                            <span className="font-bold">Volume : </span>
                            {result.search_volume}
                          </div>
                        </div>
                        <div className="d-flex align-items-center">
                          <div className="SubImg">
                            <img src={result?.location_flag} alt="" />
                          </div>
                          <div className="heading-small ps-2 font-medium">
                            {result?.location}
                          </div>
                        </div>
                      </div>
                    </ListItem>
                  );
                })
              ) : (
                <div
                  className="d-flex justify-content-center align-items-center "
                  style={{ height: "30vh" }}
                >
                  <h5 className="heading-small font-bold">
                    No Suggested Keywords found{" "}
                  </h5>
                </div>
              )}
            </div>
          </div>
          <div className="col-md-6 p-0 ps-md-1 ps-lg-1 m-0 pb-4">
            {/* <div className="SuggestionBox p-3 text-bold"> */}
            <form onSubmit={Listofitems} className="FormsIn h-100 selc-key-box">
              <div className="setMinHfomr">
                <ul className="key-fm-ul list-scroll">
                  {arrayFinal?.map((keyword, index) => {
                    return (
                      <li
                        key={`keyword-list-${index}`}
                        className="font-regular heading-small"
                      >
                        {keyword}
                        <b onClick={() => DeleteKey(keyword)}>
                          <i className="fa-regular fa-circle-xmark"></i>
                        </b>
                      </li>
                    );
                  })}
                  <div ref={messagesEndRef}></div>
                </ul>
              </div>
              <input
                style={itemAlert ? { borderColor: "red" } : {}}
                type="text"
                placeholder="Type Keyword..."
                className="add-inp-keyword heading-small"
                value={keyword}
                onKeyDown={handleKeyDown}
                onPaste={handleMessagePaste}
                onChange={ItemEvent}
                ref={inputRef}
              />
              <p className="vl-msd-line mt-4 mb-0 w-100">
                <span className="importCsv font-bold">
                  Paste or{" "}
                  <label
                    htmlFor="file-input"
                    style={{
                      color: "#365486",
                      textDecoration: "none",
                    }}
                  >
                    import from CSV{" "}
                  </label>{" "}
                  /
                  <span
                    className="importCsv font-bold point"
                    onClick={() => downloadCSV()}
                  >
                    {" "}
                    Sample CSV
                  </span>
                </span>
                <span className="heading-medium">
                  {itemAlert
                    ? " You have reached your maximum limit of " +
                      userKeywordlimit +
                      " keywords"
                    : deviceAlert
                    ? "Please select device type"
                    : minLenth
                    ? " Please enter atleast one keyword"
                    : sameKeyAlert
                    ? "This keyword is already exits"
                    : false}{" "}
                </span>
              </p>
              <div>
                <div className="d-none">
                  <input
                    id="file-input"
                    name="file-input"
                    type="file"
                    accept=".csv"
                    ref={fileInputRef}
                    placeholder="dfd"
                    onChange={handleFileUpload}
                  />
                </div>
              </div>
            </form>
          </div>
        </div>
        <div className="add-pr-btn mt-4">
          <button
            className="btn_new_bg"
            style={{ borderRadius: "5px", minWidth: "100px" }}
            type="button"
            onClick={getproject}
            onMouseOver={removeDupTrim}
          >
            Submit
          </button>
        </div>
      </div>
    </section>
  );
};
