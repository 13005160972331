import React, { useEffect } from "react";
import { Helmet } from "react-helmet";

function SeoManager({ data, children }) {
  const canonicalUrl = window.location.href;
  const defaultTitle = "RanksPro.io: Rank Tracking & SEO Analysis";

  function addOrUpdateMetaTag(attribute, value, content) {
    let metaTag = document.querySelector(`meta[${attribute}="${value}"]`);
    if (!metaTag) {
      metaTag = document.createElement("meta");
      metaTag.setAttribute(attribute, value);
      document.head.appendChild(metaTag);
    }
    metaTag.setAttribute("content", content);
  }

  useEffect(() => {
    data?.meta?.forEach((elm) => {
      addOrUpdateMetaTag(elm.attribute, elm.value, elm.content);
    });
  }, [data?.meta]);

  return (
    <React.Fragment>
      <Helmet>
        <title>{data?.title || defaultTitle}</title>
        <link rel="canonical" href={canonicalUrl} />
        <meta property="og:url" content={canonicalUrl} />
      </Helmet>
      {children}
    </React.Fragment>
  );
}

export default SeoManager;
