import React, { useEffect, useState, useMemo } from "react";
import { useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import "../../css/domainOverview.scss";
import backIcons from "../../Assets/common/back.svg";
import axios from "axios";
import { POST_LISTKEYWORD } from "../../../services/constants";
import { GET_allList } from "../../../services/constants";
import { AddNewListModal } from "../../pages/Modals/addNewListModal";
import { AddListModal } from "../../pages/Modals/addListModal";
import { PUT_AddKeyWord } from "../../../services/constants";
import addIcon from "../../Assets/rank-tracking-assets/add.svg";
import exportFileIcon from "../../Assets/rank-tracking-assets/export-file.svg";
import { CSVLink } from "react-csv";
import { ViewAllList } from "../../../Navigation/constant";
import { CiCircleList } from "react-icons/ci";
import AlertPopUp from "./alert.component";
import { dynamicSort } from "../../../helperJs/helper";
import { ReactComponent as UnsortIcon } from "../../Assets/rank-tracking-assets/unshort.svg";
import ToolTip from "./tooltip";
import { ZustandStore } from "../../../store/zustand-store";
import useKeywordTags from "./keyword-tag/getkeywordlist";
import FilterTag from "./keyword-tag/tag-filter";
import TagComponent from "./keyword-tag/tag.component";
import CustomPagination from "./pagination.component";
import Spinner from "./spinner";
import { FiDownload } from "react-icons/fi";
import { filterItemsWithKeyword } from "./keyword-tag/tag-operations";
import AddKeywordResearch from "./add-keyword-research/AddKeywordDialog";
import RanksProLogo from "../../../Mycomponent/Assets/site-audit/RanksProLogo.png";
import OrganicSearchPdf from "../../pages/Modals/PdfModals/OrganicSearchPdf";
function SEOKeyword() {
  const navigate = useNavigate();
  const keywords = useSelector((state) => state.seoKeyword);
  const email = useSelector((state) => state.email);
  const { tag_keywords } = ZustandStore((state) => state);
  const country = JSON.parse(sessionStorage.getItem("domainCountry"));
  const domainName = sessionStorage.getItem("lastdomainname");
  const [tableLoader, setTableLoader] = useState(false);
  const [showAddKeyword, setShowAddKeyword] = useState(false);

  // form alert
  const [loading, setLoading] = useState(false);

  // Modals
  const [addListModal, setaddlistModal] = useState(false);
  const [addNewListModal, setaddNewlistModal] = useState(false);

  // Lists
  const [listValue, setListValue] = useState("");
  const [AllLists, setAllLists] = useState([]);
  const [ViewAllLists, setViewAllLists] = useState([]);
  const [alertMsg, setAlertMsg] = useState(false);
  const [ListError, setListError] = useState(false);
  const [inputEmptyErr, setinputEmptyErr] = useState(false);
  const [ListLimitError, setListLimitError] = useState(false);
  const [listName, setListName] = useState("");
  const [keyWordLenErr, setKeyWordLenErr] = useState(false);
  const [queBasedKeyword, setQueBasedKeyword] = useState([]);
  const [selectAll, setSelectAll] = useState({
    select: false,
    page: 1,
  });
  const [Alert, setAlert] = useState({
    type: "",
    message: "",
    show: false,
  });

  const [tableData, setTableData] = useState([]);
  const [direction, setDirection] = useState(false);
  const [tablePage, setTablePage] = useState(1);
  const itemsPerPage = 50;
  const startIndex = (tablePage - 1) * itemsPerPage;
  const endIndex = startIndex + itemsPerPage;

  const displayedItems = useMemo(() => {
    if (tableData?.length === 0) return [];
    return tableData?.slice(startIndex, endIndex);
  }, [tableData, startIndex, endIndex]);

  const totalPages = Math.ceil(tableData?.length / itemsPerPage);
  // for input check
  const [selectedKeywords, setSelectedKeywords] = useState([]); // State to hold selected keyword strings
  const [selectedResObjects, setSelectedResObjects] = useState([]); // State to hold selected res objects

  const [quesBasedSelect, setQueBasedSelect] = useState(false);
  const [selectQuesBasedKey, setSelectQuesBasedKey] = useState([]);
  // UseSelector
  const EMAIL = useSelector((state) => state?.email);
  const LISTLIMIT = useSelector((state) => state?.createListLimit);

  const handleFetchListData = () => {
    axios.get(GET_allList(EMAIL)).then(({ data }) => {
      const list = data?.filter((item) => {
        return item.list_type === "site";
      });
      setViewAllLists(list);
      setAllLists(list);
    });
  };

  const config = useMemo(() => {
    return {
      email,
      proj_name: domainName,
      tag_type: "site",
      location_name: country?.location_name,
      language_name: country?.language_name,
    };
  }, [email, domainName, country]);

  const { loaded, error, data } = useKeywordTags(config);

  useEffect(() => {
    if (keywords?.keyword?.length > 0 && loaded) {
      const tag_keyword_data =
        tag_keywords?.length > 0
          ? filterItemsWithKeyword("keyword", tag_keywords, keywords?.keyword)
          : keywords?.keyword;
      setTableData(tag_keyword_data);
    }
  }, [keywords, tag_keywords, loaded]);

  const addAllKeyword = () => {
    if (keywords?.keyword?.length === 0) return;
    const allKeywordArray = keywords?.keyword?.map((keyword) => {
      return keyword.keyword;
    });
    setSelectedKeywords(allKeywordArray);
    setSelectedResObjects(keywords?.keyword);
  };

  useEffect(() => {
    if (selectAll?.select) {
      addAllKeyword();
    } else {
      setSelectedKeywords([]);
      setSelectedResObjects([]);
    }
  }, [selectAll]);

  useEffect(() => {
    handleFetchListData();
  }, [EMAIL]);

  useEffect(() => {
    if (quesBasedSelect) {
      setSelectQuesBasedKey(queBasedKeyword);
    } else {
      setSelectQuesBasedKey([]);
    }
  }, [quesBasedSelect]);

  const selectedComData = [
    ["Keyword Ideas"],

    [],

    ["Keyword", "Search Volume", "Position"],
    ...(selectedResObjects && Array.isArray(selectedResObjects)
      ? selectedResObjects.map((res) => [
          res.keyword,
          res?.search_volume,
          res?.rank_group,
        ])
      : []),
  ];
  // create object for sendind data in api create list
  const OtherData = useMemo(() => {
    const array = selectedResObjects?.map((result) => {
      return {
        keyword: result.keyword,
        volume: result.search_volume,
        position: result.rank_group,
      };
    });

    return array;
  }, [selectedResObjects]);

  const payLoad = {
    email: EMAIL,
    list_name: listValue,
    keyword_list: OtherData,
    list_type: "site",
  };

  const handleListCreate = () => {
    if (EMAIL && listValue && selectedKeywords) {
      axios.post(POST_LISTKEYWORD(), payLoad).then((result) => {
        // Add List Api
        if (result?.data)
          setAlert({
            type: "success",
            message: "Success",
            show: true,
          });
        setListError(false);
        handleFetchListData();
        setaddlistModal(false);
        setListLimitError(false);
        setSelectedResObjects([]);
        setSelectedKeywords([]);
        setListLimitError(false);
        setAlertMsg(false);
        setListValue("");
        setaddNewlistModal(false);
        setQueBasedSelect(false);
        setSelectQuesBasedKey([]);
      });
    }
  };

  const [DeleteLoading, setDeleteLoading] = useState(false);

  const HandleDeleteList = (listedname) => {
    setDeleteLoading(true);
    const api = `https://app.rankspro.io/api/user/keywordlistdelete/?email=${EMAIL}&list_name=${listedname.list_name}&list_type=site`;
    axios.delete(api).then((res) => {
      setTimeout(() => {
        setDeleteLoading(false);
        if (res?.data?.msg === "List Deleted Successfully") {
          handleFetchListData();
        }
      }, 500);
    });
  };

  const HandleListAdd = () => {
    setAlertMsg(false);
    if (listValue.length === 0) {
      setinputEmptyErr(true);
      return;
    } else {
      const Output = AllLists.map((result) => {
        return result.list_name === listValue;
      });
      const ReturnData = Output.includes(true);
      if (ReturnData) {
        setAlertMsg(true);
        setTimeout(() => {
          setAlertMsg(false);
        }, 3000);
      } else {
        handleListCreate();
      }
    }
  };

  const handleGetListData = (listData) => {
    setKeyWordLenErr(false);
    const TOTAL = listData.keyword_list.length + selectedKeywords.length;
    if (TOTAL >= 100) {
      setKeyWordLenErr(true);
      return;
    } else {
      setKeyWordLenErr(false);
    }
    setListError(false);
    setListName(listData.list_name);
  };

  const handleStateData = () => {
    setaddlistModal(false);
    setSelectedResObjects([]);
    setSelectedKeywords([]);
    setQueBasedSelect(false);
    setSelectQuesBasedKey([]);
    handleFetchListData();
    setListError(false);
    setListName("");
  };

  const HandleAddKeywordList = () => {
    const list_type = "site";
    setListLimitError(false);
    setListError(false);
    let obj = {
      data: [],
    };
    ViewAllLists?.map((all) => {
      if (all.list_name === listName) {
        const keyword_list = all.keyword_list;
        const namesInArray1 = new Set(keyword_list.map((item) => item.keyword));
        const array = OtherData?.filter(
          (item) => !namesInArray1.has(item.keyword)
        );
        obj.data.push(array);
      }
    });
    const encodedArray = encodeURIComponent(JSON.stringify(...obj.data));
    if (listName) {
      axios
        .put(PUT_AddKeyWord(EMAIL, listName, encodedArray, list_type))
        .then((result) => {
          if (result?.data)
            setAlert({
              type: "success",
              message: "Successfully Added",
              show: true,
            });
          handleStateData();
        })
        .catch(() => {
          setAlert({
            type: "error",
            message: "Keyword Add Failed",
            show: true,
          });
          handleStateData();
        });
    } else {
      setListError(true);
    }
  };

  useEffect(() => {
    handleFetchListData();
  }, [0]);

  const HandleOpenModalAddList = () => {
    if (AllLists.length === LISTLIMIT) {
      setListLimitError(true);
      setTimeout(() => {
        setListLimitError(false);
      }, 3000);
    } else {
      setListLimitError(false);
      setaddNewlistModal(true);
    }
  };

  useEffect(() => {
    if (keywords?.length == 0) {
      navigate(-1);
    } else {
      const element = document.getElementById("home_layout");

      if (element) {
        element.scrollTop = 0;
      } else {
        console.error(`Element with ID home_layout not found.`);
      }
    }
  }, [keywords]);

  const scrollIntoView = (id) => {
    navigate(-1);
    setTimeout(() => {
      const element = document.getElementById(id);
      if (element) {
        element.scrollIntoView({
          block: "start",
          behavior: "smooth",
        });
      }
    }, 200);
  };

  const handleCheckboxChange = (keyword, res) => {
    setSelectedKeywords((prevSelectedKeywords) => {
      if (prevSelectedKeywords.includes(keyword)) {
        // If the keyword is already in the array, remove it
        const updatedKeywords = prevSelectedKeywords.filter(
          (selectedKeyword) => selectedKeyword !== keyword
        );
        setSelectedResObjects((prevSelectedResObjects) =>
          prevSelectedResObjects.filter((resObj) => resObj.keyword !== keyword)
        );
        return updatedKeywords;
      } else {
        // If the keyword is not in the array, add it
        setSelectedResObjects((prevSelectedResObjects) => [
          ...prevSelectedResObjects,
          res,
        ]);
        return [...prevSelectedKeywords, keyword];
      }
    });
  };

  const handleSorting = (field) => {
    const data = tableData;
    // Ascending Order
    const sortedByAsc = dynamicSort([...data], field, "asc");
    // Descending Order
    const sortedByDesc = dynamicSort([...data], field, "desc");
    const data2 = direction ? sortedByAsc : sortedByDesc;
    setTableData(data2);
    setDirection((pre) => !pre);
  };

  const headerInfo = {
    keyword: "Words that people type on Google",
    volume: "The number of searches this particular keyword has during a month",
    cpc: "Cost per Click: Average cost per click if you want to pay google to be seen as an ad. if someone is paying a high CPC the keyword is usually a more valuable.",
    difficulty:
      "SEO Difficulty: Estimated competition in organic search, the higher the number the more competitive",
    position: "The Position this keyword is ranked in Google search",
  };

  const handlePaginationChange = (value) => {
    setTablePage(value);
    // scrollIntoView("keywords-ideas");
  };

  return (
    <>
      <AlertPopUp config={Alert} handleClose={setAlert} />
      <div className="d-flex align-items-center mt-4">
        <button
          onClick={() => scrollIntoView("seo-keyword-table")}
          className="icon-btn large-btn"
        >
          <img src={backIcons} alt="" />
        </button>
        <h6
          className="ms-4 mb-0 heading-large font-bold"
          style={{
            fontSize: "2em",
            fontWeight: "700",
            fontFamily: "Lato",
          }}
        >
          Organic Search Results Positions
        </h6>
        <div className="d-flex ms-auto">
          <FilterTag data={data} />

          <button
            className="cm-btn ms-3"
            disabled={selectedKeywords?.length === 0}
            onClick={() => {
              setShowAddKeyword(true);
            }}
          >
            <img
              src={addIcon}
              alt="add keyword"
              className="me-2"
              style={{ width: "15px" }}
            />
            Rank Tracking
          </button>
          <button
            className="icon-btn ms-3"
            title="Add Keyword"
            disabled={selectedResObjects?.length === 0}
            onClick={() => {
              setaddlistModal(true);
            }}
          >
            <img src={addIcon} alt="add keyword" />
          </button>

          <CSVLink data={selectedComData} filename={"seo_keyword.csv"}>
            <button
              disabled={selectedResObjects?.length === 0}
              className="icon-btn ms-3"
              title="Export File"
            >
              <img src={exportFileIcon} alt="export-file" />
            </button>
          </CSVLink>

          <Link to={`../${ViewAllList}?list_type=site`}>
            <button className="icon-btn ms-3" title="View Keyword List">
              <CiCircleList />
            </button>
          </Link>

          <FiDownload
            class="down"
            data-bs-toggle="modal"
            data-bs-target="#staticBackdrop"
            title="download 
                            long description pdf"
          />
          <div
            class="modal fade"
            id="staticBackdrop"
            data-bs-backdrop="static"
            data-bs-keyboard="false"
            tabindex="-1"
            aria-labelledby="staticBackdropLabel"
            aria-hidden="true"
          >
            <div class="modal-dialog">
              <div class="modal-content">
                <div class="modal-header" style={{
                                    zIndex:"100",
                                    borderBottom: "none",
    position: "absolute",
    right: 0,
    padding: "36px",
}}>
                  
                  <button
                    type="button"
                    class="btn-close"
                    data-bs-dismiss="modal"
                    aria-label="Close"
                  ></button>
                </div>
                <div class="modal-body">
                  <OrganicSearchPdf
                    displayedItems={displayedItems}
                    domainName={domainName}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="custom-table-container mt-4 mb-4">
        <table className="">
          <thead>
            <tr className="table-sticky">
              <th
                scope="col"
                className="check-box-input"
                onClick={() => {
                  setSelectAll({
                    select: !selectAll?.select,
                    page: 1,
                  });
                }}
              >
                <input
                  type="checkbox"
                  checked={
                    selectedKeywords?.length === keywords?.keyword?.length
                  }
                  readOnly
                />
              </th>
              <th scope="col" className="t_left ps-5 p-2">
                <div className="d-flex justify-content-start">
                  <div
                    className="d-flex align-items-center point"
                    onClick={() => handleSorting("keyword")}
                  >
                    Keywords
                    <span className="ms-2">
                      <UnsortIcon />
                    </span>
                  </div>
                  <ToolTip title={headerInfo?.keyword} />
                </div>
              </th>
              <th scope="col" className="text-center">
                <div className="d-flex justify-content-center">
                  <div
                    className="d-flex align-items-center point"
                    onClick={() => handleSorting("search_volume")}
                  >
                    Volume
                    <span className="ms-2">
                      <UnsortIcon />
                    </span>
                  </div>
                  <ToolTip title={headerInfo?.volume} />
                </div>
              </th>
              <th scope="col" className="text-center">
                <div className="d-flex justify-content-center">
                  <div
                    className="d-flex align-items-center point"
                    onClick={() => handleSorting("rank_group")}
                  >
                    Position
                    <span className="ms-2">
                      <UnsortIcon />
                    </span>
                  </div>
                  <ToolTip title={headerInfo?.position} />
                </div>
              </th>
            </tr>
          </thead>
          <tbody>
            {!loaded ? (
              <tr>
                <td colSpan="4" className="text-center">
                  <Spinner />
                </td>
              </tr>
            ) : (
              displayedItems?.map((res, index) => {
                return (
                  <tr key={`${index}-${res.rank_group}-${res.keyword}`}>
                    <td
                      className="check-box-input"
                      onClick={(e) => handleCheckboxChange(res.keyword, res)}
                    >
                      <input
                        type="checkbox"
                        checked={selectedKeywords.includes(res.keyword)}
                        readOnly
                      />
                    </td>
                    <td className="t_left ps-5">
                      {res.keyword}
                      {loaded && !error && (
                        <span>
                          <TagComponent
                            config={config}
                            list={data}
                            keyword={res.keyword}
                            location={country?.location_name}
                            key={res.keyword}
                          />
                        </span>
                      )}
                    </td>
                    <td className="text-center">{res.search_volume}</td>
                    <td className="text-center">{res.rank_group}</td>
                  </tr>
                );
              })
            )}
          </tbody>
        </table>
      </div>
      {totalPages === 1 ? null : (
        <div className="my-4 d-flex justify-content-start align-items-center">
          <CustomPagination
            page={tablePage}
            onPageChange={handlePaginationChange}
            totalPage={totalPages}
          />
        </div>
      )}
      <>
        <AddListModal
          {...{
            DeleteLoading,
            setDeleteLoading,
            HandleDeleteList, // Delete
            handleGetListData, // getList Data on click
            HandleOpenModalAddList, // For Open Modal
            HandleAddKeywordList,
            ViewAllLists,
            setaddlistModal,
            addListModal,
            setaddNewlistModal,
            addNewListModal,
            ListError,
            ListLimitError,
            keyWordLenErr,
            setKeyWordLenErr,
          }}
        />
      </>
      <>
        <AddNewListModal
          {...{
            setinputEmptyErr,

            inputEmptyErr,
            ListLimitError,
            setAlertMsg,
            alertMsg,
            HandleListAdd,
            setListValue,
            setaddNewlistModal,
            addNewListModal,
          }}
        />
      </>
      {showAddKeyword && (
        <AddKeywordResearch
          {...{
            showAddKeyword,
            setShowAddKeyword,
            selectedKeywords,
          }}
        />
      )}
    </>
  );
}

export default SEOKeyword;
