import React, { useState, useEffect } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import RippleButton from "../../share/components/rippleButton";
import { useDispatch, useSelector } from "react-redux";
import ProjectStepper from "./project-stepper";
import backIcon from "../../Assets/common/back.svg";
import {
  PUT_UPDATE_COMPETOTORS,
  DELETE_COMPETOTORS,
  USERPROJECT_GET,
} from "../../../services/constants";
import axios from "axios";
import AlertPopUp from "../../share/components/alert.component";
import CancelIcon from "@mui/icons-material/Cancel";

const AddCompetitors = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const location = useLocation();
  const [allProject, SetAllProject] = useState([]);
  const email = localStorage.getItem("admin_mail");
  const project_id = location?.state?.project_id?.[0];
  const selectedProject = allProject?.filter((item) => {
    return item.id === project_id;
  });
  const websiteurl = useSelector((state) => state.websiteurl);
  const competitor_list = selectedProject?.[0]?.Project?.[0]?.competitors || [];
  const userkeywordlimit = useSelector((state) => state.userkeywordlimit);
  const UserKeywordLength = useSelector((state) => state.userkeywordlength);
  const NewProjectUrl = useSelector((state) => state.newprojecturl);
  const userProjectlimit = useSelector((state) => state.userprojectlimit);
  const USERALLPROJECTLength = useSelector((state) => state.userprojectlength);
  const competitorslimit = useSelector((state) => state.competitorslimit);

  const [CompetitorsList, setCompetitorsList] = useState([]);
  const [deleteCompetitors, setDeleteCompetitors] = useState([]);
  const [CompetitorsName, setCompetitorsName] = useState("");
  const [CompetitorsAlert, setCompetitorsAlert] = useState({
    type: "",
    msg: "",
    show: false,
  });
  const [Alert, setAlert] = useState({
    type: "",
    message: "",
    show: false,
  });
  const total_competitors = CompetitorsList?.length + competitor_list?.length;

  const getUserProject = () => {
    if (email == null) return;
    axios
      .get(USERPROJECT_GET(email))
      .then((response) => {
        SetAllProject(response.data?.data);
      })
      .catch((error) => console.log("error while getting data", error));
  };

  useEffect(() => {
    getUserProject();
  }, [0]);

  // localStorage
  useEffect(() => {
    const Local = JSON.parse(localStorage.getItem("CompetitorsDetails"));
    if (Local != null && !location?.state) {
      setCompetitorsList(Local.CompetitorsList);
    }
  }, [location]);

  useEffect(() => {
    if (
      NewProjectUrl === false ||
      Number(UserKeywordLength) > Number(userkeywordlimit) ||
      USERALLPROJECTLength > Number(userProjectlimit)
    ) {
      navigate("/dashboard");
    }
  });

  const addCompetitorsHandler = (e) => {
    e.preventDefault();
    const competitor = CompetitorsName.toLowerCase();
    const domainPattern = /^[a-zA-Z0-9-]+(?:\.[a-zA-Z]{2,})+$/i;
    const domain = domainPattern.test(competitor);
    if (!domain) {
      setCompetitorsAlert({
        type: "error",
        msg: "please enter valid competitor domain",
        show: true,
      });
      setCompetitorsName("");
    } else if (websiteurl === competitor) {
      setCompetitorsAlert({
        type: "error",
        msg: "Your project and competitor domains cannot be the same. Please use a different domain.",
        show: true,
      });
      setCompetitorsName("");
    } else if (competitor_list?.includes(competitor)) {
      setCompetitorsAlert({
        type: "error",
        msg: "competitor already added",
        show: true,
      });
      setCompetitorsName("");
    } else if (total_competitors >= competitorslimit) {
      setCompetitorsAlert({
        type: "error",
        msg: "your competitor limit is " + competitorslimit,
        show: true,
      });
      setCompetitorsName("");
    } else {
      setCompetitorsAlert({
        type: "",
        msg: "",
        show: false,
      });
      setCompetitorsList((obj) => {
        const existObj = obj.find((o, i) => o === competitor);
        if (existObj) {
          return obj;
        } else {
          return [...obj, competitor];
        }
      });
      setCompetitorsName("");
    }
  };

  const removeCompetitors = (e) => {
    setCompetitorsList(CompetitorsList.filter((item, index) => index !== e));
  };

  const sendCompetitors = () => {
    dispatch({ type: "ADDNEWCOMPETITORS", payload: CompetitorsList });
    navigate(`/addpr/addkeyword?addproject`);

    localStorage.setItem(
      "CompetitorsDetails",
      JSON.stringify({ CompetitorsList })
    );
  };

  const handleAddCompetitors = () => {
    const encodedArray = encodeURIComponent(JSON.stringify(CompetitorsList));
    axios
      .put(PUT_UPDATE_COMPETOTORS(project_id, encodedArray))
      .then((response) => {
        const data = response?.data?.status;
        if (data === "200") {
          navigate("/rank-tracker");
          navigate(0);
        }
      })
      .catch((error) => {});
  };

  const handleDeleteCompetitors = () => {
    const encodedArray = encodeURIComponent(JSON.stringify(deleteCompetitors));

    axios
      .put(DELETE_COMPETOTORS(project_id, encodedArray))
      .then((response) => {
        const data = response?.data?.status;
        if (data === "200") {
          navigate("/rank-tracker");
          navigate(0);
        }
      })
      .catch((error) => {});
  };

  const handleDelayAddCompetitor = () => {
    setTimeout(() => {
      CompetitorsList?.length > 0 && handleAddCompetitors();
    }, 1000);
  };

  const handleSubmitCompetitor = () => {
    if (deleteCompetitors?.length > 0 && CompetitorsList?.length > 0) {
      const encodedArray = encodeURIComponent(
        JSON.stringify(deleteCompetitors)
      );

      axios
        .put(DELETE_COMPETOTORS(project_id, encodedArray))
        .then((response) => {
          const data = response?.data?.status;
          if (data === "200") {
            getUserProject();
            setAlert({
              type: "success",
              message: "Competitor deleted successfully",
              show: true,
            });
            handleDelayAddCompetitor();
          } else {
            handleDelayAddCompetitor();
            setAlert({
              type: "error",
              message: "Competitor deletion failed",
              show: true,
            });
          }
        })
        .catch((error) => {
          handleDelayAddCompetitor();
          setAlert({
            type: "error",
            message: "some error occurred",
            show: true,
          });
        });
    } else if (deleteCompetitors?.length > 0) {
      handleDeleteCompetitors();
    } else if (CompetitorsList?.length > 0) {
      handleAddCompetitors();
    }
  };

  const toggleDeleteCompetitor = (domain) => {
    setDeleteCompetitors((prevState) => {
      if (prevState.includes(domain)) {
        // Remove the domain if it's already in the array
        return prevState.filter((item) => item !== domain);
      } else {
        // Add the domain if it's not in the array
        return [...prevState, domain];
      }
    });
  };

  const onChangeCompetitorsName = (e) => {
    // e.preventDefault();
    const competitor = e.target.value.toLowerCase();
    const domainPattern = /^[a-zA-Z0-9-]+(?:\.[a-zA-Z]{2,})+$/i;
    const isDomainValid = domainPattern.test(competitor);

    if (e.target.value === "") {
      setCompetitorsAlert({
        type: "",
        msg: "",
        show: false,
      });
    } else if (!isDomainValid) {
      setCompetitorsAlert({
        type: "error",
        msg: "Please enter a valid competitor domain.",
        show: true,
      });
    } else if (competitor_list?.includes(competitor)) {
      setCompetitorsAlert({
        type: "error",
        msg: "Competitor already added.",
        show: true,
      });
    } else {
      setCompetitorsAlert({
        type: "success",
        msg: "Competitor Domain is Valid. Press Enter to add",
        show: true,
      });
    }

    setCompetitorsName(competitor);
  };

  return (
    <>
      <AlertPopUp config={Alert} handleClose={setAlert} />
      <div className="">
        <div className="cmd">
          <Link to={-1}>
            <button className="icon-btn large-btn">
              <img src={backIcon} alt="back button" />
            </button>
          </Link>

          {location?.state ? (
            <div></div>
          ) : (
            <>
              <ProjectStepper activeStep={2} />
              <button
                className="icon-btn large-btn"
                onClick={() => sendCompetitors()}
              >
                <img
                  src={backIcon}
                  alt="back button"
                  style={{
                    transform: "scaleX(-1)",
                  }}
                />
              </button>
            </>
          )}
        </div>
        <div className="add-pr-url">
          <div>
            <h1 className="">Add Competitors</h1>
            <div className="cmd add-comp-wth" style={{ flexWrap: "wrap" }}>
              <p className="mb-0 proj-tag-line">
                Start tracking and improving your website’s SEO traffic.
              </p>
              <p className="mb-0">
                Competitor:{" "}
                <span>
                  <b>
                    {total_competitors}
                    {""}/{""}
                    {competitorslimit}
                  </b>
                </span>
              </p>
            </div>
            <div className="add-pro-web-input add-comp-wth">
              <form
                onSubmit={(e) => {
                  addCompetitorsHandler(e);
                }}
              >
                <input
                  className="heading-small"
                  type="text"
                  value={CompetitorsName}
                  placeholder={
                    location?.state
                      ? "Add Competitors"
                      : "Add Competitors (OPTIONAL)"
                  }
                  onChange={onChangeCompetitorsName}
                ></input>
              </form>
              {CompetitorsAlert?.show && (
                <p
                  className={`heading-small m-0 ${
                    CompetitorsAlert?.type === "error"
                      ? "vl-msd-line-login"
                      : "msg-success"
                  }`}
                >
                  {CompetitorsAlert?.msg}
                </p>
              )}

              <div className="compe_List">
                <ul>
                  {CompetitorsList?.map((competitor, i) => {
                    return (
                      <li key={i}>
                        {competitor}{" "}
                        <span
                          onClick={() => {
                            removeCompetitors(i);
                          }}
                        >
                          {" "}
                          <i className="fa-solid fa-xmark"></i>{" "}
                        </span>
                      </li>
                    );
                  })}
                  {competitor_list?.map((competitor, i) => {
                    return (
                      <li
                        title="click to delete"
                        key={i}
                        style={{
                          backgroundColor: deleteCompetitors?.includes(
                            competitor
                          )
                            ? "#e95655"
                            : "#dcdcdc",
                        }}
                        onClick={() => {
                          toggleDeleteCompetitor(competitor);
                        }}
                      >
                        {competitor}{" "}
                        <span>
                          {" "}
                          <i className="fa-solid fa-xmark"></i>{" "}
                        </span>
                      </li>
                    );
                  })}
                </ul>
              </div>
            </div>
            <div className="add-pr-btn mt-4">
              {location?.state ? (
                <button
                  className="btn_new_bg"
                  style={{
                    borderRadius: "5px",
                    minWidth: "130px",
                    height: "50px",
                  }}
                  disabled={
                    CompetitorsList?.length === 0 &&
                    deleteCompetitors?.length === 0
                  }
                  onClick={() => {
                    handleSubmitCompetitor();
                  }}
                >
                  Submit
                </button>
              ) : (
                <button
                  className="btn_new_bg"
                  style={{
                    borderRadius: "5px",
                    minWidth: "130px",
                    height: "50px",
                  }}
                  onClick={() => {
                    sendCompetitors();
                  }}
                >
                  Next
                </button>
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default AddCompetitors;
