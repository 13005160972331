import "./App.css";
import React from "react";
import RouterConfrigration from "./Navigation/routerConfigration";
import { useState } from "react";
import { useEffect } from "react";
import Loader from "./Mycomponent/share/loader";
import { useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import "react-toastify/dist/ReactToastify.css";

function App() {
  const [defLoading, setDefLoading] = useState(true);
  const loading = useSelector((state) => state.loading);
  const location = useLocation();

  const handleClick = () => {
    const anchor = document.querySelector("#home_layout");

    if (anchor) {
      anchor.scrollIntoView({
        block: "start",
        behavior: "smooth",
      });
    }
  };

  useEffect(() => {
    handleClick();
  }, [location]);

  useEffect(() => {
    handleClick();
    setDefLoading(true);

    const timerId = setTimeout(() => {
      setDefLoading(loading);
    }, 500);

    return () => {
      clearTimeout(timerId);
    };
  }, [loading]);

  return (
    <React.Fragment>
      <div
        className={defLoading || loading ? "loading-skeleton App" : "App"}
        style={{ overflow: "hidden" }}
      >
        <RouterConfrigration />
      </div>
      {defLoading || loading ? <Loader /> : null}
    </React.Fragment>
  );
}

export default App;
