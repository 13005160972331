import React, { useState, useEffect } from "react";
import { Box, Button, Paper, TextField } from "@mui/material";
import { useNavigate, useLocation } from "react-router-dom";
import emailSrc from "../../../Mycomponent/Assets/common/email-seq.png";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import {
  GET_SMTP_DOMAIN_MAIL,
  SMTP_FIRST_MAIL,
  USER_SMTP_CHECK,
} from "../../../services/constants";
import * as DOMPurify from "dompurify";
import axios from "axios";
import { useSelector } from "react-redux";
import AlertPopUp from "../../../Mycomponent/share/components/alert.component";

const Contact = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const agency = location?.state;
  const regex = /^(?:https?:\/\/)?(?:www\.)?([^/]+)/i;
  const domain = agency?.website?.match(regex);
  const admin_email = localStorage.getItem("admin_mail");
  const EMAIL = useSelector((state) => state?.email);
  const [smtp, setSmtp] = useState({ from: "", password: "" });
  const [smtpData, setSMTPDATA] = useState({
    data: [],
    loader: true,
    status: false,
  });

  const [Alert, setAlert] = useState({
    type: "",
    message: "",
    show: false,
  });

  const handleBack = () => {
    navigate(-1);
  };

  const getUSERSMTPDETAILS = () => {
    if (EMAIL != null) {
      axios.get(USER_SMTP_CHECK(EMAIL)).then((response) => {
        const data = response.data;
        if (data?.length > 0) {
          setSmtp({ from: data?.[0]?.from_email, password: data?.[0]?.smtp });
        }
      });
    }
  };

  const getSMTPDATA = () => {
    if (domain?.[1] == undefined || EMAIL == null) return;
    const config = {
      domain: domain?.[1],
      email: EMAIL,
    };
    axios
      .get(GET_SMTP_DOMAIN_MAIL(config))
      .then((response) => {
        if (response?.data?.length > 0) {
          setSMTPDATA({ data: response.data, loader: false, status: true });
        }
      })
      .catch(() => {
        setSMTPDATA({ data: [], loader: false, status: true });
      });
  };

  useEffect(() => {
    getUSERSMTPDETAILS();
    getSMTPDATA();
  }, []);

  const initialValues = {
    from: admin_email || "",
    to: agency.email || "",
    subject: "",
  };

  const validationSchema = Yup.object({
    from: Yup.string().email("Invalid email format").required("Required"),
    to: Yup.string().email("Invalid email format").required("Required"),
    subject: Yup.string()
      .required("Required")
      .max(100, "Subject cannot exceed 100 characters"),
  });

  const onSubmit = (values, { resetForm }) => {
    if (!smtp?.password) return;
    const config = {
      email: values.from,
      to_email: values.to,
      subject: values.subject,
      app_email: EMAIL,
      replied: false,
      send: false,
      password: smtp?.password,
      domain_name: domain?.[1],
      message: `
      ${DOMPurify.sanitize(values.subject, {
        USE_PROFILES: { html: true },
      })}
      `,
    };

    axios
      .post(SMTP_FIRST_MAIL(), config)
      .then((response) => {
        setAlert({
          type: "success",
          message: response?.data?.msg || "Success",
          show: true,
        });
      })
      .catch(() => {
        setAlert({
          type: "success",
          message: "Unfortunately, the email failed to send. Please try again.",
          show: true,
        });
      });
    resetForm();
  };

  return (
    <>
      <AlertPopUp config={Alert} handleClose={setAlert} />
      <div className="d-flex align-items-center">
        <Button className="btn_1 mb-3" onClick={() => handleBack()}>
          Back
        </Button>
      </div>
      <main className="contact-agency-container">
        <div className="row w-100 h-100">
          <div className="col-lg-5 d-none d-lg-flex">
            <div className="first-con">
              <div className="mt-4 mx-4">
                Domain: <span>{domain?.[1]}</span>
              </div>
              <div className="image-contain">
                <img src={emailSrc} alt="email-sender" />
              </div>
            </div>
          </div>
          <div className="col-12 col-lg-7">
            <div className="second-con h-100">
              <div className="d-lg-none mb-4">
                Domain: <span>{domain?.[1]}</span>
              </div>
              <Formik
                initialValues={initialValues}
                validationSchema={validationSchema}
                onSubmit={onSubmit}
              >
                {({ isSubmitting }) => (
                  <Form className="h-100 w-100">
                    <div className="d-flex align-items-center mb-2">
                      <label className="mail-from">From: </label>
                      <div className="w-100">
                        <Field
                          type="email"
                          name="from"
                          placeholder="enter your email"
                          className="form-control"
                          autoComplete="false"
                        />
                        <ErrorMessage
                          name="from"
                          component="div"
                          className="text-danger"
                        />
                      </div>
                    </div>

                    <div className="d-flex align-items-center mb-2">
                      <label className="mail-to">To: </label>
                      <div className="w-100">
                        <Field
                          type="email"
                          name="to"
                          placeholder="enter recipient email"
                          className="form-control"
                          autoComplete="false"
                        />
                        <ErrorMessage
                          name="to"
                          component="div"
                          className="text-danger"
                        />
                      </div>
                    </div>

                    <div
                      className="d-flex align-items-start d-col mb-2"
                      style={{ height: "70%" }}
                    >
                      <label className="mail-subject mt-3">Subject: </label>
                      <div className="w-100" style={{ height: "95%" }}>
                        <Field
                          as="textarea"
                          name="subject"
                          placeholder="enter subject"
                          className="form-control"
                          autoComplete="false"
                        />
                        <ErrorMessage
                          name="subject"
                          component="div"
                          className="text-danger"
                        />
                      </div>
                    </div>

                    <button
                      type="submit"
                      // disabled={isSubmitting}
                      className="mail-btn-submit"
                    >
                      Submit
                    </button>
                  </Form>
                )}
              </Formik>
            </div>
          </div>
        </div>
      </main>
    </>
  );
};

export default Contact;
