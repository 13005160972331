import React, { useEffect, useMemo, useRef } from "react";
import { useState } from "react";
import { CSVLink } from "react-csv";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import KeywordAllRanksChart from "../charts/constant";
import { curday } from "../upDater/constant";
import axios from "axios";
import { DottLoader1 } from "../loader";
import LineAlert from "../components/lineAlert";
import CompetitorsTable from "./CompetitorsTable";
import { DELETE_KEYWORD } from "../../../services/constants";
import { Link } from "react-router-dom";
import countryList from "../../dataFiles/country";
import ToolTip from "../components/tooltip";
import MenuListDropdown from "../components/menulist.component";
import { ViewSeoImpact } from "../../pages/Modals/ViewImpact";
import { Store } from "../../../Affiliate/utils/STORE";
import OpenInNewIcon from "@mui/icons-material/OpenInNew";
import Spinner from "../../share/components/spinner";
import TextField from "@mui/material/TextField";
import InputAdornment from "@mui/material/InputAdornment";
import { GoSearch } from "react-icons/go";
import { GrFormSubtract } from "react-icons/gr";
import AreYousure from "../../share/card-layout/AreYousure";
import {
  convertToObjects,
  stringToBoolean,
  sortedNoRankArray,
} from "../../../helperJs/helper";
import { ReactComponent as CompetitorIcon } from "../../Assets/rank-tracking-assets/competitor.svg";
import { ReactComponent as DeleteIcon } from "../../Assets/rank-tracking-assets/delete.svg";
import { ReactComponent as AddIcon } from "../../Assets/rank-tracking-assets/add.svg";
import searchIcon from "../../Assets/rank-tracking-assets/search.svg";
import { ReactComponent as ComputerIcon } from "../../Assets/rank-tracking-assets/computer.svg";
import { ReactComponent as UnsortIcon } from "../../Assets/rank-tracking-assets/unshort.svg";
import { ReactComponent as MobileIcon } from "../../Assets/rank-tracking-assets/mobile.svg";
import insightSvg from "../../Assets/rank-tracking-assets/insight.svg";
import trendSvg from "../../Assets/rank-tracking-assets/trend.svg";
import aIWriterSvg from "../../Assets/rank-tracking-assets/ai-writer.svg";
import SwitchComponent from "../components/switch.component";
import CancelIcon from "@mui/icons-material/Cancel";

import { useClickAway } from "react-use";
import { AnimatePresence, motion } from "framer-motion";
import crossIcon from "../../Assets/rank-tracking-assets/cross.svg";
import RankTable from "../../../helperJs/rank-table";
import { ZustandStore } from "../../../store/zustand-store";
import { getCurrentCountryDetails } from "../../../helperJs/getCountryFlag";
import TagComponent from "../components/keyword-tag/tag.component";
import useKeywordTags from "../components/keyword-tag/getkeywordlist";
import FilterTag from "../components/keyword-tag/tag-filter";
import { filterItems } from "../components/keyword-tag/tag-operations";
import { useLocation } from "react-router-dom";
import { base64Decode } from "../../../helperJs/encoderanddecoder";

const framerSidebarBackground = {
  initial: { transform: "scale(0) ", transformOrigin: "left" },
  animate: { transform: "scale(1) ", transformOrigin: "left" },
  exit: {
    transform: "scale(0) ",
    transition: { delay: 0.2 },
    transformOrigin: "left",
  },
  transition: { duration: 0.3 },
};

const RankTablewithSorting = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const location_path = useLocation();
  const searchRef = useRef(null);
  const table_data_update = RankTable();
  const { rank_keyword, emptyKeywordData, tag_keywords, emptyAllLocData } =
    ZustandStore((state) => state);
  const queryParams = new URLSearchParams(location_path.search);
  const query_competitors = queryParams.get("competitors");
  const competitors = base64Decode(query_competitors);
  const isCompetitorShow = stringToBoolean(competitors);
  // Redux Data
  const { RankUpPercent } = Store((state) => state);
  const RankMovedup = useSelector((state) => state.rankmovedup);
  const RankMovedDown = useSelector((state) => state.rankmoveddown);
  const currentlocation = useSelector((state) => state.currentlocation);
  const websiteurl = useSelector((state) => state.websiteurl);
  const deviceType = useSelector((state) => state.devicetype);
  const email = useSelector((state) => state.email);
  const isGuestLink = location_path.pathname === "/guest/rank-tracker";

  const UserAllKeywordResult = useSelector(
    (state) => state.userallkeywordresult
  );

  const UserAllPendingResult = useSelector(
    (state) => state.userallpendingresult
  );

  function checkIfAdmin() {
    if (isGuestLink) return false;
    const email = localStorage.getItem("admin_mail");
    const isAdmin = ["info@esearchlogix.com", "singhashish1.sh@gmail.com"];
    return isAdmin.includes(email);
  }

  const handleColSpan = () => {
    if (isGuestLink) return 6;
    return !checkIfAdmin() ? 7 : 8;
  };

  const oldKeywordData = useSelector((state) => state.oldkeyworddata);
  const usercompatitors = useSelector((state) => state.usercompatitors);
  const UserSelectedPrId = useSelector(
    (state) => state.userselectedprojectallid
  );
  const usercurrentprojectlocation = useSelector(
    (state) => state.usercurrentprojectlocation
  );
  const topRankData = useSelector((state) => state.toprankdata);
  const chartImprData = useSelector((state) => state.chartimprdata);

  // useStates
  const [detailsCSV, setDetailsCSV] = useState([]);
  const [CurrentKeyword, setCurrentKeyword] = useState(null);
  const [selectedKeyword, setSelectedKeyword] = useState([]);
  const [locationViewer, setLocationViewer] = useState([]);
  const CurrentLocation = useRef([]);
  const [selectAll, setSelectAll] = useState(false);

  const [searchQuery, setSearchQuery] = React.useState("");
  const [Loader, setLoader] = React.useState(true);
  // alert
  const [filterReset, setFilterReset] = useState(false);
  const [addkeywordAlert, setAddKeywordAlert] = useState(false);
  const [selectLoactionAlert, setLocationAlert] = useState(false);
  const [DeleteAlert, setDeleteAlert] = useState(false);

  const [sortField, setSortField] = useState("rank");
  const [sortDirection, setSortDirection] = useState("asc");
  const [isOpen, setIsOpen] = useState(false);
  const [Empty, setEmpty] = useState(true);
  const [tableData, setTableData] = useState({
    alert_show: true,
    sorting: [],
    no_sorting: [],
    pending: UserAllPendingResult,
    search: false,
  });

  const combinedArray = [...tableData?.sorting, ...tableData?.no_sorting];
  // Extract unique 'location' values using Set
  const uniqueLocations = [
    ...new Set(combinedArray.map((obj) => obj.location)),
  ];

  // useClickAway hook handles click-away functionality
  useClickAway(searchRef, () => {
    isOpen && setIsOpen(false);
  });

  const handleToggle = (event) => {
    event.stopPropagation();
    setIsOpen(!isOpen);
  };

  // Seo impact  function
  const [seoImpactAlert, setseoImpactAlert] = useState(false);
  const [seoImpactLoader, setseoImpactLoader] = useState(false);
  const [ImpactData, setImpactData] = useState([]);

  const filteredAllKeywordData = useMemo(() => {
    if (!topRankData?.top_rank) return UserAllKeywordResult;
    const filtered =
      Array.isArray(UserAllKeywordResult) &&
      UserAllKeywordResult?.map((item) => {
        const data = item.data;
        const filteredData = data.filter((item) =>
          topRankData?.data?.includes(item?.rank_group)
        );
        if (filteredData.length > 0) {
          return {
            data: filteredData,
            keyword: item?.keyword,
            search_volume: item?.search_volume,
          };
        } else {
          return null;
        }
      });

    return (
      Array.isArray(filtered) &&
      filtered?.filter((item) => item?.data?.length > 0 && item)
    );
  }, [UserAllKeywordResult, topRankData]);

  useEffect(() => {
    CurrentLocation.current = usercurrentprojectlocation;
    CurrentLocation.current =
      CurrentLocation.current &&
      CurrentLocation?.current?.filter((obj, index, self) => {
        return (
          index === self.findIndex((t) => t.location_code === obj.location_code)
        );
      });
    const matchingLocations = [];
    CurrentLocation.current.forEach((item) => {
      const matchingLocation = countryList.find((location) => {
        return location.location_name === item.location_name;
      });
      if (matchingLocation) {
        matchingLocations.push(matchingLocation);
      }
    });

    if (matchingLocations.length > 1) {
      const languageCodeCount = {};
      // Iterate through the objects in the Arrays array and count the occurrences of each language_code
      matchingLocations.forEach((item) => {
        item.language.forEach((lang) => {
          const { language_code } = lang;
          if (languageCodeCount[language_code]) {
            languageCodeCount[language_code]++;
          } else {
            languageCodeCount[language_code] = 1;
          }
        });
      });
      // Filter for language_code values that occur more than once
      const sameLanguageCodes = Object.keys(languageCodeCount).filter(
        (language_code) => languageCodeCount[language_code] > 1
      );
      if (sameLanguageCodes) {
        localStorage.setItem("language", sameLanguageCodes[0]);
      }
    } else {
      localStorage.setItem(
        "language",
        matchingLocations[0]?.language[0].language_code
      );
    }
  }, [usercurrentprojectlocation]);

  const tableDataUpdate = (filteredData, noRankData, pendingData) => {
    const improvedData = filteredData?.filter(
      (item) => item.backgroundColor === "#B3FECC"
    );
    const decliendData = filteredData?.filter(
      (item) => item.backgroundColor === "#FCCACA"
    );
    ZustandStore.setState({
      declined: decliendData,
      improved: improvedData,
    });
    const selectedData =
      topRankData?.type === "Improved"
        ? improvedData
        : topRankData?.type === "Declined"
        ? decliendData
        : filteredData;
    var sorted_data = [...selectedData].sort((a, b) => a.rank - b.rank);
    const rankgroups = selectedData?.map((item) => {
      return item?.rank;
    });

    if (chartImprData?.chart_show && rankgroups?.length > 0) {
      dispatch({
        type: "CHARTIMPRDATA",
        payload: { data: rankgroups, chart_show: false },
      });
    }
    if (RankMovedDown != null && RankMovedup != null) {
      if (!topRankData?.top_rank) {
        setTableData({
          sorting: sorted_data,
          search: true,
          no_sorting: topRankData?.type === "" ? noRankData : [],
          alert_show: true,
          pending:
            topRankData?.type === "" ? pendingData || UserAllPendingResult : [],
        });
        setTimeout(() => {
          setLoader(false);
        }, 500);
      } else {
        updateTopRankData(filteredData, noRankData, pendingData);
      }
    } else {
      setTimeout(() => {
        setLoader(false);
      }, 500);
    }
  };

  const updateTopRankData = (filteredData, noRankData, pendingData) => {
    const filteredRankData = filteredData?.filter((keyword) => {
      return topRankData?.data?.includes(keyword?.rank);
    });

    const filterNoRankData = noRankData?.filter((keyword) => {
      return topRankData?.data?.includes(keyword?.rank);
    });

    const sorted_data = [...filteredRankData].sort((a, b) => a.rank - b.rank);

    setTableData((previous) => ({
      ...previous,
      alert_show: true,
      sorting: sorted_data,
      search: true,
      no_sorting: filterNoRankData,
      pending: [],
    }));
    setTimeout(() => {
      setLoader(false);
    }, 500);
  };

  useEffect(() => {
    if (tag_keywords?.length > 0 && rank_keyword?.length > 0) {
      const data = filterItems(tag_keywords, rank_keyword);
      const pending_data_for_tag = filterItems(
        tag_keywords,
        UserAllPendingResult
      );
      setLoader(true);
      setFilterReset(true);
      const filteredData = data?.filter((item) => item.rank !== "No Rank");
      const noRankData = data?.filter((item) => item.rank === "No Rank");
      tableDataUpdate(filteredData, noRankData, pending_data_for_tag);
    } else if (filterReset && rank_keyword?.length > 0) {
      setFilterReset(false);
      setLoader(true);
      const filteredData = rank_keyword?.filter(
        (item) => item.rank !== "No Rank"
      );
      const noRankData = rank_keyword?.filter(
        (item) => item.rank === "No Rank"
      );
      tableDataUpdate(filteredData, noRankData, UserAllPendingResult);
    }
  }, [rank_keyword, tag_keywords, filterReset]);

  useEffect(() => {
    if (rank_keyword?.length > 0) {
      setLoader(true);
      const filteredData = rank_keyword?.filter(
        (item) => item.rank !== "No Rank"
      );
      const noRankData = rank_keyword?.filter(
        (item) => item.rank === "No Rank"
      );
      tableDataUpdate(filteredData, noRankData, UserAllPendingResult);
    } else {
      setTableData({
        alert_show: true,
        sorting: [],
        no_sorting: [],
        pending: UserAllPendingResult,
        search: false,
      });
      setLoader(false);
    }
  }, [
    rank_keyword,
    RankMovedup,
    RankMovedDown,
    topRankData,
    chartImprData,
    UserAllPendingResult,
  ]);

  const handleKeywordSearch = (searchText) => {
    if (rank_keyword?.length === 0 && UserAllPendingResult?.length === 0)
      return;
    setLoader(true);
    const filteredData = rank_keyword?.filter(
      (item) => item.rank !== "No Rank"
    );
    const noRankData = rank_keyword?.filter((item) => item.rank === "No Rank");
    const sorted_data = [...filteredData].sort((a, b) => a.rank - b.rank);

    if (searchText === "") {
      tableDataUpdate(filteredData, noRankData, UserAllPendingResult);
      return;
    }

    const new_sorted_data = sorted_data?.filter((item) => {
      const keyword = item?.keyword;
      return keyword?.toLowerCase().includes(searchText.toLowerCase())
        ? item
        : null;
    });

    const newNoRank_data = noRankData?.filter((item) => {
      const keyword = item?.keyword;
      return keyword?.toLowerCase().includes(searchText.toLowerCase())
        ? item
        : null;
    });

    const newpendingData = UserAllPendingResult?.filter((item) => {
      return item?.keyword?.toLowerCase().includes(searchText.toLowerCase())
        ? item
        : null;
    });

    tableDataUpdate(new_sorted_data, newNoRank_data, newpendingData);
  };

  // search keywords
  useEffect(() => {
    const debounce = setTimeout(() => {
      handleKeywordSearch(searchQuery);
    }, 500);

    return () => {
      clearTimeout(debounce);
    };
  }, [searchQuery]);

  const handleSortDiffClick = (field) => {
    if (rank_keyword?.length === 0) return;
    const { sorting, no_sorting } = tableData;
    setSortField(field);
    if (field === sortField) {
      setSortDirection("desc");
      setTableData((previous) => ({
        ...previous,
        sorting: [...tableData?.sorting].reverse(),
        no_sorting: [...tableData?.no_sorting].reverse(),
        alert_show: false,
      }));
    } else {
      setSortDirection("asc");
      const sorted = sorting.sort((a, b) => {
        const diffA =
          isNaN(a.oldrank) || isNaN(a.rank) ? 0 : a.oldrank - a.rank;
        const diffB =
          isNaN(b.oldrank) || isNaN(b.rank) ? 0 : b.oldrank - b.rank;
        return diffA - diffB; // Sorting in ascending order of the difference
      });
      const no_sorted = sortedNoRankArray(no_sorting, field);
      setTableData((previous) => ({
        ...previous,
        sorting: sorted,
        no_sorting: no_sorted,
        alert_show: false,
      }));
    }
  };

  const handleSortClick = (field) => {
    if (rank_keyword?.length === 0) return;
    const { sorting, no_sorting } = tableData;
    setSortField(field);
    if (field === sortField) {
      setSortDirection("desc");
      setTableData((previous) => ({
        ...previous,
        sorting: [...sorting].reverse(),
        no_sorting: [...no_sorting].reverse(),
        alert_show: false,
      }));
    } else {
      const sorted = [...sorting]?.sort((a, b) =>
        a[field] > b[field] ? 1 : -1
      );
      const no_sorted = sortedNoRankArray(no_sorting, field);
      setSortDirection("asc");
      setTableData((previous) => ({
        ...previous,
        sorting: sorted,
        no_sorting: no_sorted,
        alert_show: false,
      }));
    }
  };

  useEffect(() => {
    const CSV_Array = [];
    if (oldKeywordData !== 0) {
      filteredAllKeywordData &&
        filteredAllKeywordData?.map((res, key) => {
          CSV_Array.push({
            Keyword: res?.keyword,
            Location: res?.location,
            SearchVolume: res?.search_volume,
            Positions: rank_keyword?.[key]?.rank,
            Previous:
              oldKeywordData.length > key
                ? oldKeywordData?.[key]?.rank_group
                : "No Rank",
            URL: rank_keyword?.[key]?.url,
          });
        });
      setDetailsCSV(CSV_Array);
    }
  }, [filteredAllKeywordData]);

  // alerts function
  const Chartalert = (res) => {
    if (res.oldrank === "No Rank" && res.rank === "No Rank") return;
    if (CurrentKeyword === null) {
      setCurrentKeyword(res);
    } else if (
      CurrentKeyword?.keyword === res.keyword &&
      CurrentKeyword?.location === res?.location
    ) {
      setCurrentKeyword(null);
    } else {
      setCurrentKeyword(res);
    }
  };

  // OnClick functions
  const AddKeywordHandler = () => {
    if (websiteurl === null) {
      navigate("/addpr");
    } else {
      dispatch({ type: "NEWPROJECTURL", payload: websiteurl });
      setAddKeywordAlert(true);
    }
  };

  // onClick function for device type change handler
  const ChangeDesktopType = () => {
    if (deviceType !== "desktop") {
      emptyAllLocData();
      emptyKeywordData();
      dispatch({ type: "ALLOLDKEYWORDDATA", payload: [] });
      dispatch({
        type: "CHARTIMPRDATA",
        payload: { data: [], chart_show: false },
      });
      dispatch({
        type: "TOPRANKDATA",
        payload: { data: [], top_rank: false, type: "", competitors: false },
      });
      dispatch({ type: "DEVICETYPE", payload: "desktop" });
    } else {
      return false;
    }
  };

  const ChangeMobileType = () => {
    if (deviceType !== "mobile") {
      emptyAllLocData();
      emptyKeywordData();
      dispatch({ type: "ALLOLDKEYWORDDATA", payload: [] });
      dispatch({
        type: "CHARTIMPRDATA",
        payload: { data: [], chart_show: false },
      });
      dispatch({
        type: "TOPRANKDATA",
        payload: { data: [], top_rank: false, type: "", competitors: false },
      });
      dispatch({ type: "DEVICETYPE", payload: "mobile" });
    } else {
      return false;
    }
  };

  const groupedData = selectedKeyword?.reduce((acc, currentValue) => {
    const location = currentValue?.location;
    if (!acc[location]) {
      acc[location] = [];
    }
    acc[location].push(currentValue);
    return acc;
  }, {});

  // Creating an array containing objects with location and data
  const result = Object.entries(groupedData)?.map(([location, data]) => ({
    location,
    data,
  }));

  // table Keyword Delete
  const ChooseKeywordDelete = (e) => {
    const index = selectedKeyword.indexOf(e);
    if (index === -1) {
      setSelectedKeyword((prevKeywords) => [...prevKeywords, e]);
    } else {
      setSelectedKeyword((prevKeywords) =>
        prevKeywords.filter((keyword) => keyword !== e)
      );
    }
  };

  useEffect(() => {
    if (selectedKeyword.length > 0) {
      setEmpty(false);
    } else {
      setEmpty(true);
    }
  }, [ChooseKeywordDelete]);

  const allKeywordArray = useMemo(() => {
    setSelectAll(false);
    const array = filteredAllKeywordData || [];
    const user_keyword = array?.map((keyword) => {
      return { keyword: keyword.keyword, location: keyword.location };
    });
    const Array = [...user_keyword, ...tableData?.pending];
    return Array;
  }, [filteredAllKeywordData, tableData]);

  useEffect(() => {
    if (allKeywordArray?.length === 0) {
      setSelectAll(false);
    } else if (selectAll) {
      setSelectedKeyword(allKeywordArray);
    } else {
      setSelectedKeyword([]);
    }
  }, [selectAll, allKeywordArray]);

  const isKeywordSelected = (obj) => {
    const data = selectedKeyword?.filter(
      (item) => item.keyword === obj?.keyword && item.location === obj?.location
    );
    return data?.length > 0;
  };

  const delay = (ms) => new Promise((resolve) => setTimeout(resolve, ms));

  const KeywordDelete = async (res) => {
    if (!res) {
      setDeleteAlert(false);
    } else if (selectedKeyword?.length > 0 && res) {
      setEmpty(false);
      try {
        const promises = result?.map(async (item, index) => {
          const keyword = item.data?.map((item) => {
            return item.keyword;
          });
          const selectedKeywordarray = encodeURIComponent(
            JSON.stringify(keyword)
          );

          const location_code = getCurrentCountryDetails(item.location)?.[0]
            ?.location_code;
          const deleteKeyword_json = {
            id: Number(UserSelectedPrId[0]),
            key: selectedKeywordarray,
            device: deviceType,
            location: location_code,
          };
          if (index !== 0) {
            await delay(500); // Adjust the delay time (in milliseconds) as needed
          }
          return axios.put(DELETE_KEYWORD(deleteKeyword_json)).then(() => {});
        });
        // Wait for all promises to resolve
        await Promise.all(promises).then(() => {
          setSelectAll(false);
          setDeleteAlert(false);
          setSelectedKeyword([]);
          emptyAllLocData();
          emptyKeywordData();
          dispatch({
            type: "USERALLPROJECTDETAILS",
            payload: [],
          });
          dispatch({ type: "LOADING" });
        });
      } catch (error) {
        setSelectAll(false);
        setDeleteAlert(false);
        setSelectedKeyword([]);
      }
    } else {
      setEmpty(true);
    }
  };

  const selectKeyViewerHand = (e) => {
    setLocationAlert(false);
    setLocationViewer((obj) => {
      // Check if an object with the same location_code already exists in the array
      const existingObj = obj.find((o) => o.location_code === e.location_code);
      // If an object already exists, return the original array without adding a new object
      if (existingObj) {
        return obj;
      }
      // If an object doesn't exist, add a new object to the array and return the updated array
      else {
        return [
          ...obj,
          { location_code: e.location_code, location_name: e.location_name },
        ];
      }
    });
  };

  const SelectKeyViewerDelete = (key) => {
    setLocationViewer(
      locationViewer.filter((loc) => loc?.location_name !== key)
    );
  };

  const LocationSender = () => {
    if (locationViewer.length === 0) {
      setLocationAlert(true);
    } else {
      const Locations = [];
      locationViewer?.map((res) => {
        Locations.push(res.location_name);
      });
      localStorage.setItem(
        "ProjectsDetail",
        JSON.stringify({ domain: websiteurl, ProjectName: "" })
      );
      localStorage.setItem(
        "Locationdetails",
        JSON.stringify({ Lang: "English", Country: Locations })
      );
      setLocationAlert(false);
      dispatch({ type: "ADDPROJECTLOCATION", payload: locationViewer });
      navigate("/addpr/addkeyword");
    }
  };

  const SeoImpact = (keyword, URL, TYPE) => {
    setseoImpactLoader(true);
    setImpactData([]);
    setseoImpactAlert(true);
    dispatch({ type: "SEOIMPACTURL", payload: URL });
    dispatch({ type: "SEOIMPACTKEYWORD", payload: keyword });
    if (TYPE === "RANK") {
      const API = `https://app.rankspro.io/api/user/seoimpact/?url=${URL}&target=${keyword}`;
      try {
        axios.post(API).then((res) => {
          setImpactData(res?.data);
          setseoImpactLoader(false);
        });
      } catch (error) {
        setseoImpactLoader(false);
        setImpactData([]);
      }
    } else {
      setseoImpactLoader(false);
    }
  };

  const table_data = {
    sorted: tableData?.sorting,
    norank: tableData?.no_sorting,
    project: websiteurl,
    location: uniqueLocations,
    deviceType: deviceType,
    src: null,
  };

  const openPdfViewer = () => {
    const result = RankUpPercent;
    const tableData = [
      result?.top3?.length,
      result?.top10?.length,
      result?.top100?.length,
      result?.noRank?.length,
    ];
    sessionStorage.setItem("PieChtDtArr", JSON.stringify(tableData));
    sessionStorage.setItem(
      "PieChtLblArr",
      JSON.stringify(["Top 3", "Top 10", "Top 100", "No Rank"])
    );
    const pdf_data = {
      type: "rank",
      data: table_data,
    };
    dispatch({ type: "PDFVIEWERDATA", payload: pdf_data });
    dispatch({ type: "LOADING" });
    setTimeout(() => {
      navigate("/pdf-editor");
    }, 500);
  };

  const dataList = {
    list: [
      <button
        className="btn_new_tr_bg rounded-5 px-3 py-2"
        onClick={openPdfViewer}
      >
        <i
          className="fa-regular fa-file-lines"
          style={{ paddingRight: "10px" }}
        ></i>
        PDF
      </button>,
      <CSVLink
        filename={curday() + " " + websiteurl + ".csv"}
        data={detailsCSV ? detailsCSV : "null"}
      >
        <button className="btn_new_tr_bg rounded-5 px-3 py-2">
          <i
            className="fa-regular fa-file-lines"
            style={{ paddingRight: "10px" }}
          ></i>
          CSV
        </button>
      </CSVLink>,
    ],
  };

  const handleChange = (event) => {
    if (deviceType === "mobile") {
      ChangeDesktopType();
    } else {
      ChangeMobileType();
    }
  };

  const handleCompetitorsTable = (show) => {
    dispatch({
      type: "CHARTIMPRDATA",
      payload: { data: [], chart_show: false },
    });
    dispatch({
      type: "TOPRANKDATA",
      payload: { data: [], top_rank: false, type: "", competitors: show },
    });
  };

  const keywordLocation = locationViewer?.map((loc) => {
    return loc?.location_name;
  });

  const handleClear = () => {
    setSearchQuery("");
  };

  const config = useMemo(() => {
    return {
      email,
      proj_name: websiteurl,
      tag_type: deviceType === "mobile" ? "rank mobile" : "rank desktop",
      location_name: currentlocation,
      language_name: "",
    };
  }, [email, websiteurl, currentlocation, deviceType]);

  const { loaded, error, data } = useKeywordTags(config);

  return (
    <div className="rank-table">
      {DeleteAlert && (
        <AreYousure
          callback={KeywordDelete}
          title={"You will not able to recover this Keyword!"}
        />
      )}
      <ViewSeoImpact
        {...{ seoImpactAlert, setseoImpactAlert, ImpactData, seoImpactLoader }}
      />
      {tableData?.pending?.length !== 0 &&
      tableData?.pending !== false &&
      tableData?.alert_show ? (
        <LineAlert data="Pending Data will be updated  in 12 to 24 hours" />
      ) : null}

      {filteredAllKeywordData?.length !== 0 &&
      tableData?.sorting?.length === 0 &&
      tableData?.no_sorting?.length === 0 &&
      !tableData?.search ? (
        <div className="w-100 h-100 cad">
          <div className="spinner-border" role="status">
            <span className="sr-only"></span>
          </div>
        </div>
      ) : (
        <>
          <div
            className={
              filteredAllKeywordData?.length !== 0 &&
              tableData?.sorting?.length === 0 &&
              tableData?.no_sorting?.length === 0 &&
              !tableData?.search
                ? "loading-skeleton"
                : ""
            }
          >
            {topRankData?.competitors ? null : (
              <div className="row gy-2 table-header-contain">
                <div
                  className="col-12 col-md-4 col-lg-6 d-flex align-items-center"
                  style={{ flexWrap: "wrap" }}
                >
                  <div className="rank-switch">
                    <span>
                      <ComputerIcon
                        className={
                          deviceType === "mobile"
                            ? "disable-switch"
                            : "selected-switch"
                        }
                      />
                    </span>
                    <SwitchComponent
                      checked={deviceType === "mobile"}
                      onChange={(e) => handleChange(e)}
                      className={
                        deviceType === "mobile" ? "thumb-mobile" : "thumb-comp"
                      }
                    />
                    <span>
                      <MobileIcon
                        className={
                          deviceType === "mobile"
                            ? "selected-switch"
                            : "disable-switch"
                        }
                      />
                    </span>
                  </div>
                  {isOpen ? (
                    <AnimatePresence>
                      <motion.div
                        {...framerSidebarBackground}
                        className="search-box ms-3"
                        ref={searchRef}
                      >
                        <TextField
                          className="keyword-search"
                          id="outlined-start-adornment"
                          InputProps={{
                            startAdornment: (
                              <InputAdornment position="start">
                                <GoSearch />
                              </InputAdornment>
                            ),
                            endAdornment: searchQuery && (
                              <InputAdornment position="end">
                                <CancelIcon
                                  onClick={handleClear}
                                  style={{ cursor: "pointer" }}
                                />
                              </InputAdornment>
                            ),
                          }}
                          sx={{
                            "& .MuiInputBase-root": {
                              maxHeight: "38px",
                            },
                            "& .MuiOutlinedInput-notchedOutline": {
                              border: "none !important",
                            },
                          }}
                          placeholder="search keyword"
                          value={searchQuery}
                          autoComplete="off"
                          onChange={(e) => setSearchQuery(e.target.value)}
                        />
                      </motion.div>
                    </AnimatePresence>
                  ) : (
                    <button
                      className="icon-btn ms-3"
                      onClick={handleToggle}
                      title="Search Keyword"
                    >
                      <img src={searchIcon} alt="searchIcon" />
                    </button>
                  )}
                  {!isGuestLink && <FilterTag data={data} />}
                </div>
                <div className="col-12 col-md-8 col-lg-6">
                  <div className="table-btn-col">
                    {usercompatitors.length > 0 &&
                    isCompetitorShow &&
                    isGuestLink ? (
                      <button
                        className={"add-competitor-btn"}
                        onClick={() =>
                          handleCompetitorsTable(!topRankData?.competitors)
                        }
                        title="Competitor Table"
                      >
                        <CompetitorIcon className="me-2" />
                        Competitors
                      </button>
                    ) : usercompatitors.length > 0 && !isGuestLink ? (
                      <button
                        className={"add-competitor-btn"}
                        onClick={() =>
                          handleCompetitorsTable(!topRankData?.competitors)
                        }
                        title="Competitor Table"
                      >
                        <CompetitorIcon className="me-2" />
                        Competitors
                      </button>
                    ) : null}

                    {!isGuestLink ? (
                      <>
                        <button
                          className="add-competitor-btn"
                          title="Add Keyword"
                          onClick={() => AddKeywordHandler()}
                        >
                          <AddIcon className="me-2" /> Add Keyword
                        </button>
                        <MenuListDropdown data={dataList} />
                        <button
                          disabled={Empty}
                          className="icon-btn"
                          title="Delete Keyword"
                          onClick={() => setDeleteAlert(true)}
                        >
                          <DeleteIcon className="" />
                        </button>
                      </>
                    ) : (
                      <CSVLink
                        filename={curday() + " " + websiteurl + ".csv"}
                        data={detailsCSV ? detailsCSV : "null"}
                      >
                        <button className="icon-btn" title="download CSV">
                          <i className="fa-regular fa-file-lines" />
                        </button>
                      </CSVLink>
                    )}
                  </div>
                </div>
              </div>
            )}

            {topRankData?.competitors ? (
              <CompetitorsTable />
            ) : (
              <div
                className="custom-table-container  mt-4"
                id="rank-tracking-table"
              >
                <table>
                  <thead>
                    <tr className="table-sticky">
                      {!isGuestLink && (
                        <th
                          scope="col"
                          onClick={() => setSelectAll(!selectAll)}
                          className="check-box-input"
                        >
                          <input type="checkbox" checked={selectAll} readOnly />
                        </th>
                      )}

                      <th scope="col">
                        <div className="d-flex align-items-center">
                          <div
                            className="d-flex point"
                            onClick={() => handleSortClick("keyword")}
                          >
                            KEYWORDS
                            <span className="ms-2">
                              <UnsortIcon />
                            </span>
                          </div>
                          <ToolTip title="Words that people type into Google" />
                        </div>
                      </th>

                      <th
                        scope="col"
                        style={{
                          width: "150px",
                        }}
                      >
                        <div className="d-flex d-flex justify-content-center">
                          <div
                            className="d-flex align-items-center point"
                            onClick={() => handleSortClick("rank")}
                          >
                            POSITIONS
                            <span className="ms-2">
                              <UnsortIcon />
                            </span>
                          </div>
                          <ToolTip title="The Position this URL is ranked in Google search" />
                        </div>
                      </th>

                      <th
                        scope="col"
                        style={{
                          width: "150px",
                        }}
                      >
                        <div className="d-flex d-flex justify-content-center">
                          <div
                            className="d-flex align-items-center point"
                            onClick={() => handleSortDiffClick("diff")}
                          >
                            Diff
                            <span className="ms-2">
                              <UnsortIcon />
                            </span>
                          </div>
                          <ToolTip title="This value shows the change in ranking position for this URL on Google search" />
                        </div>
                      </th>

                      <th
                        scope="col"
                        style={{
                          width: "150px",
                        }}
                      >
                        <div className="d-flex justify-content-center">
                          <div
                            className="d-flex align-items-center point"
                            onClick={() => handleSortClick("oldrank")}
                          >
                            PREVIOUS
                            <span className="ms-2">
                              <UnsortIcon />
                            </span>
                          </div>
                          <ToolTip title="The Position this URL is ranked in Google search" />
                        </div>
                      </th>

                      <th scope="col" className="fixed-width">
                        <div className="d-flex">
                          <div
                            className="d-flex align-items-center point"
                            onClick={() => handleSortClick("serachvolume")}
                          >
                            VOL
                            <span className="ms-2">
                              <UnsortIcon />
                            </span>
                          </div>
                          <ToolTip
                            title="The numbers of searches this particular keyword has
                          during a month"
                          />
                        </div>
                      </th>

                      {!checkIfAdmin() ? null : (
                        <th scope="col" className="fixed-width">
                          <div>
                            AW
                            <ToolTip title="Ai Article Writer" />
                          </div>
                        </th>
                      )}

                      <th scope="col" className="fixed-width">
                        <div>
                          INSIGHT
                          <ToolTip title="This data is based upon selected keyword URL." />
                        </div>
                      </th>

                      <th scope="col" className="fixed-width">
                        <div>TREND</div>
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {Loader ? (
                      // || !loaded
                      <tr>
                        <td colSpan={handleColSpan()}>
                          <div
                            style={{ minHeight: "350px" }}
                            className="d-flex align-items-center"
                          >
                            <Spinner />
                          </div>
                        </td>
                      </tr>
                    ) : filteredAllKeywordData?.length === 0 &&
                      tableData?.pending?.length === 0 ? (
                      topRankData?.top_rank ? (
                        <tr>
                          <td colSpan={handleColSpan()} className="text-center">
                            <b>NO KEYWORD !</b>
                          </td>
                        </tr>
                      ) : (
                        <tr>
                          <td colSpan={handleColSpan()} className="text-center">
                            Please add keyword for this device type -----
                          </td>
                        </tr>
                      )
                    ) : filteredAllKeywordData?.length !== 0 &&
                      tableData?.sorting?.length === 0 &&
                      tableData?.no_sorting?.length === 0 &&
                      !tableData?.search ? (
                      <tr>
                        <td colSpan={handleColSpan()}>
                          <DottLoader1 />
                        </td>
                      </tr>
                    ) : (
                      tableData?.sorting?.map((res, index) => {
                        return (
                          <React.Fragment
                            key={`ranktrackingtable-${index}-${deviceType}`}
                          >
                            <tr
                              key={`ranktrackingtable-sortedData-${index}-${deviceType}`}
                            >
                              {!isGuestLink && (
                                <td
                                  className="check-box-input"
                                  onClick={() => ChooseKeywordDelete(res)}
                                >
                                  <input
                                    type="checkbox"
                                    checked={isKeywordSelected(res)}
                                    readOnly
                                  />
                                </td>
                              )}

                              <td className="table-keyword">
                                <div>
                                  <span className="w-100 d-flex align-items-center">
                                    <span className="keyword-name">
                                      {res.keyword}
                                    </span>
                                    <img
                                      src={
                                        getCurrentCountryDetails(
                                          res?.location
                                        )?.[0]?.location_flag
                                      }
                                      className="ms-2"
                                      alt=""
                                      style={{
                                        width: "20px",
                                        objectFit: "contain",
                                      }}
                                    />
                                  </span>
                                  {res?.url === "Not Found" ||
                                  res?.url === "not data found" ? null : (
                                    <a
                                      href={res?.url}
                                      target="_blank"
                                      className="d-flex mt-1"
                                      rel="noopener noreferrer"
                                    >
                                      <span
                                        style={{ color: "#365486" }}
                                        className="ps-0"
                                      >
                                        {res?.url}
                                      </span>
                                      <OpenInNewIcon
                                        sx={{
                                          fontSize: "13px",
                                          marginLeft: "2px",
                                          color: "#365486 !important",
                                        }}
                                      />
                                    </a>
                                  )}
                                  {loaded && !error && !isGuestLink && (
                                    <span>
                                      <TagComponent
                                        config={config}
                                        list={data}
                                        keyword={res.keyword}
                                        location={res?.location}
                                        key={`ranktrackingtable-sorting-${res.keyword}-${deviceType}`}
                                      />
                                    </span>
                                  )}
                                </div>
                              </td>

                              <td
                                onClick={() => Chartalert(res)}
                                style={{ textAlign: "center" }}
                              >
                                <div
                                  className="d-flex justify-content-center"
                                  style={{
                                    justifyContent: "center",
                                    alignItems: "center",
                                  }}
                                >
                                  <span
                                    style={{
                                      minWidth: "20px",
                                      fontWeight: "600",
                                    }}
                                  >
                                    {res.rank}
                                  </span>
                                </div>
                              </td>

                              <td
                                onClick={() => Chartalert(res)}
                                style={{ textAlign: "center" }}
                              >
                                <div
                                  className="d-flex justify-content-center"
                                  style={{
                                    justifyContent: "center",
                                    alignItems: "center",
                                  }}
                                >
                                  <div
                                    className="growArrow"
                                    style={{
                                      marginLeft: "10px",
                                      backgroundColor: res?.backgroundColor,
                                      borderRadius: "5px",
                                    }}
                                  >
                                    {res?.result === 0 ? (
                                      <GrFormSubtract />
                                    ) : (
                                      <>
                                        {res.growth}
                                        <span
                                          style={{
                                            color: res?.color,
                                            marginLeft: "2px",
                                          }}
                                        >
                                          {res.result}
                                        </span>
                                      </>
                                    )}
                                  </div>
                                </div>
                              </td>

                              <td
                                onClick={() => Chartalert(res)}
                                style={{
                                  textAlign: "center",
                                  fontWeight: "600",
                                }}
                              >
                                {res.oldrank}
                              </td>

                              <td
                                onClick={() => Chartalert(res)}
                                className="fixed-width"
                                style={{ fontWeight: "600" }}
                              >
                                {res.serachvolume}
                              </td>

                              {!checkIfAdmin() ? null : (
                                <td className="fixed-width">
                                  <Link
                                    to="ai/content-writer"
                                    onClick={() => {
                                      dispatch({
                                        type: "TOPICKEYWORD",
                                        payload: res.keyword,
                                      });
                                    }}
                                  >
                                    <img
                                      src={aIWriterSvg}
                                      alt="ai-writer-pen-icon"
                                    />
                                  </Link>
                                </td>
                              )}

                              <td className="fixed-width">
                                <img
                                  src={insightSvg}
                                  alt="insightSvg"
                                  onClick={() =>
                                    SeoImpact(res.keyword, res.url, "RANK")
                                  }
                                />
                              </td>

                              <td className="fixed-width">
                                <img
                                  src={trendSvg}
                                  alt="trendSvg"
                                  onClick={() => Chartalert(res)}
                                />
                              </td>
                            </tr>
                            {res?.keyword === CurrentKeyword?.keyword &&
                            res?.location === CurrentKeyword?.location ? (
                              <tr>
                                <td colSpan={handleColSpan()}>
                                  <KeywordAllRanksChart
                                    Keyword={CurrentKeyword}
                                  />
                                </td>
                              </tr>
                            ) : (
                              false
                            )}
                          </React.Fragment>
                        );
                      })
                    )}

                    {/* No Rank data */}
                    {!Loader &&
                      tableData?.no_sorting?.map((res, index) => {
                        return (
                          <React.Fragment
                            key={`ranktrackingtable-nosortdata-${index}-${deviceType}`}
                          >
                            <tr
                              key={`ranktrackingtable-nosortdata-${index}-${deviceType}`}
                            >
                              {!isGuestLink && (
                                <td
                                  className="check-box-input"
                                  onClick={() => ChooseKeywordDelete(res)}
                                >
                                  <input
                                    type="checkbox"
                                    checked={isKeywordSelected(res)}
                                    readOnly
                                  />
                                </td>
                              )}

                              <td className="table-keyword">
                                {" "}
                                <div className="d-flex align-items-center">
                                  <div className="ms-2 w-100">
                                    <span className="w-100 d-flex align-items-center">
                                      <span className="keyword-name">
                                        {res.keyword}{" "}
                                      </span>

                                      <img
                                        src={
                                          getCurrentCountryDetails(
                                            res?.location
                                          )?.[0]?.location_flag
                                        }
                                        alt=""
                                        className="ms-2"
                                        style={{
                                          width: "20px",
                                          objectFit: "contain",
                                        }}
                                      />
                                    </span>
                                    {res?.url === "Not Found" ||
                                    res?.url === "not data found" ? null : (
                                      <a
                                        href={res?.url}
                                        target="_blank"
                                        className="d-flex mt-1"
                                        rel="noopener noreferrer"
                                      >
                                        <span
                                          style={{ color: "#365486" }}
                                          className="ps-0"
                                        >
                                          {res?.url}
                                        </span>
                                        <OpenInNewIcon
                                          sx={{
                                            fontSize: "13px",
                                            marginLeft: "2px",
                                            color: "#365486",
                                          }}
                                        />
                                      </a>
                                    )}
                                    {loaded && !error && !isGuestLink && (
                                      <span>
                                        <TagComponent
                                          config={config}
                                          list={data}
                                          keyword={res.keyword}
                                          location={res?.location}
                                          key={`ranktrackingtable-no_sorting-${res.keyword}-${deviceType}`}
                                        />
                                      </span>
                                    )}
                                  </div>
                                </div>
                              </td>

                              <td onClick={() => Chartalert(res)}>
                                <div
                                  className="d-flex justify-content-center"
                                  style={{
                                    alignItems: "center",
                                    fontWeight: "600",
                                  }}
                                >
                                  {res.rank}
                                </div>
                              </td>

                              <td onClick={() => Chartalert(res)}>
                                <div
                                  className="d-flex justify-content-center"
                                  style={{
                                    alignItems: "center",
                                    fontWeight: "600",
                                  }}
                                >
                                  <div
                                    className="growArrow "
                                    style={{
                                      marginLeft: "10px",
                                      backgroundColor: res?.backgroundColor,
                                      borderRadius: "5px",
                                    }}
                                  >
                                    {res?.result === 0 ? (
                                      <GrFormSubtract />
                                    ) : (
                                      <>
                                        {res.growth}
                                        <span
                                          style={{
                                            color: res?.color,
                                            marginLeft: "2px",
                                          }}
                                        >
                                          {res.result}
                                        </span>
                                      </>
                                    )}
                                  </div>
                                </div>
                              </td>

                              <td
                                style={{
                                  textAlign: "center",
                                  fontWeight: "600",
                                }}
                                onClick={() => Chartalert(res)}
                              >
                                {res.oldrank}
                              </td>

                              <td
                                onClick={() => Chartalert(res)}
                                className="fixed-width"
                                style={{ fontWeight: "600" }}
                              >
                                {res.serachvolume}
                              </td>

                              {!checkIfAdmin() ? null : (
                                <td className="fixed-width">
                                  <Link
                                    to="ai/content-writer"
                                    onClick={() => {
                                      dispatch({
                                        type: "TOPICKEYWORD",
                                        payload: res.keyword,
                                      });
                                    }}
                                  >
                                    <img
                                      src={aIWriterSvg}
                                      loading="lazy"
                                      alt="ai-writer-pen-icon"
                                    />
                                  </Link>
                                </td>
                              )}

                              <td className="fixed-width">
                                <img
                                  src={insightSvg}
                                  alt="insightSvg"
                                  style={{ opacity: 0.5 }}
                                />
                              </td>

                              <td className="fixed-width">
                                <img
                                  src={trendSvg}
                                  alt="trendSvg"
                                  style={{
                                    opacity:
                                      res.oldrank === "No Rank" &&
                                      res.rank === "No Rank"
                                        ? 0.5
                                        : 1,
                                  }}
                                  onClick={() => Chartalert(res)}
                                />
                              </td>
                            </tr>
                            {res?.keyword === CurrentKeyword?.keyword &&
                            res?.location === CurrentKeyword?.location ? (
                              <tr className="fixed-width">
                                <td colSpan={handleColSpan()}>
                                  <KeywordAllRanksChart
                                    Keyword={CurrentKeyword}
                                  />
                                </td>
                              </tr>
                            ) : (
                              false
                            )}
                          </React.Fragment>
                        );
                      })}

                    {/* Pending data  */}
                    {!Loader &&
                      tableData?.pending?.map((pendingKey, index) => {
                        return (
                          <tr
                            key={`ranktrackingtable-userallpendingresult-${index}-${deviceType}`}
                          >
                            {!isGuestLink && (
                              <td
                                className="check-box-input"
                                onClick={() => ChooseKeywordDelete(pendingKey)}
                              >
                                <input
                                  type="checkbox"
                                  readOnly
                                  checked={isKeywordSelected(pendingKey)}
                                />
                              </td>
                            )}
                            <td className="t_left">
                              <div className="w-100 d-flex align-items-center">
                                <span className="keyword-name">
                                  {pendingKey?.keyword}
                                </span>
                                <img
                                  src={
                                    getCurrentCountryDetails(
                                      pendingKey?.location
                                    )?.[0]?.location_flag
                                  }
                                  alt=""
                                  className="ms-2"
                                  style={{
                                    width: "20px",
                                    objectFit: "contain",
                                  }}
                                />
                              </div>
                              {loaded && !error && !isGuestLink && (
                                <span>
                                  <TagComponent
                                    config={config}
                                    list={data}
                                    keyword={pendingKey?.keyword}
                                    location={pendingKey?.location}
                                    key={`ranktrackingtable-pendingKey-${pendingKey.keyword}-${deviceType}`}
                                  />
                                </span>
                              )}
                            </td>
                            <td className="text-success">Pending</td>
                            <td className="text-success">Pending</td>
                            {!checkIfAdmin() ? null : (
                              <td className="text-success">Pending</td>
                            )}
                            <td className="text-success">Pending</td>
                            <td className="text-success">Pending</td>
                            <td className="text-success">Pending</td>
                          </tr>
                        );
                      })}

                    {!Loader &&
                    filteredAllKeywordData?.length !== 0 &&
                    tableData?.sorting?.length === 0 &&
                    tableData?.no_sorting?.length === 0 &&
                    tableData?.pending?.length === 0 &&
                    tableData?.search ? (
                      <tr>
                        <td colSpan={handleColSpan()} className="text-center">
                          <b>NO KEYWORD!</b>
                        </td>
                      </tr>
                    ) : null}
                  </tbody>
                </table>
              </div>
            )}
          </div>
          <div>
            {addkeywordAlert ? (
              <div className="pop">
                <div
                  className="popBody p-0 rounded-5"
                  style={{ textAlign: "end" }}
                >
                  <img
                    src={crossIcon}
                    alt="close popup"
                    style={{
                      width: "30px",
                      height: "30px",
                      margin: "10px 20px",
                      cursor: "pointer",
                    }}
                    onClick={() => {
                      setLocationViewer([]);
                      setAddKeywordAlert(false);
                    }}
                  />
                  <h4 className="heading-medium font-light-bold text-center">
                    Please Select Location for Add Keyword{" "}
                  </h4>
                  <div className="pop-form p-3">
                    <div style={{ minWidth: "30vw" }}>
                      <div className="container-fluid">
                        <div className="ranTab_countryChoose list-scroll">
                          <ul>
                            {CurrentLocation.current &&
                              CurrentLocation.current.map((res, index) => {
                                return (
                                  <li
                                    key={`currentlocation-${index}-${deviceType}`}
                                    className={
                                      keywordLocation?.includes(
                                        res.location_name
                                      )
                                        ? "keyword-loc keyword-loc-sel"
                                        : "keyword-loc"
                                    }
                                    onClick={() => {
                                      keywordLocation?.includes(
                                        res.location_name
                                      )
                                        ? SelectKeyViewerDelete(
                                            res.location_name
                                          )
                                        : selectKeyViewerHand(res);
                                    }}
                                  >
                                    {res.location_name}
                                  </li>
                                );
                              })}
                          </ul>
                        </div>
                      </div>
                    </div>
                    <div className="mt-3 px-2 text-center">
                      <button
                        style={{ borderRadius: "8px", height: "40px" }}
                        className="btn_new_bg px-4 py-2  ms-3"
                        onClick={() => LocationSender()}
                        disabled={keywordLocation?.length === 0}
                      >
                        Submit
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            ) : null}
          </div>
        </>
      )}
    </div>
  );
};

export default React.memo(RankTablewithSorting);
