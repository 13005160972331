import React, { useState } from "react";
import Dropdown from "../../share/components/dropdown";
import { Grid, Button } from "@mui/material";
import "../../css/backlink-checker.scss";
import KeywordImg from "../../Assets/keyword-subfiles/7591474-removebg.png";
import { ReCaptcha } from "../../share/components/googleRecaptacha";
import { useNavigate } from "react-router-dom";
import ToolNav from "../../share/components/tool-nav";
import countryList from "../../dataFiles/country";

function SiteOverView() {
  const [Error, setError] = useState();
  const [keyword, setKeyword] = useState("");
  const [domain, setDomain] = useState("Domain");
  const [toolSelected, setToolSelected] = useState(0);

  const tool = [
    {
      title: "Backlink Checker",
      desc: " Try the free version of Backlink Checker. Get a glimpse into the power of our premium tool.",
      api: "",
      searchOption: true,
      image: KeywordImg,
    },
    {
      title: "Website “Authority” Checker",
      desc: "Check the “authority” of your domain.",
      api: "",
      searchOption: false,
      image: KeywordImg,
    },
    {
      title: "Broken Link Checker",
      desc: "Check your site for broken inbound and outbound links in seconds.",
      api: "",
      searchOption: false,
      image: KeywordImg,
    },
  ];

  const handleCountrySlection = (e) => {};

  return (
    <div className="backlink-checker-container">
      <ToolNav />
      <div className="Tool-App">
        <div className="backlink-container">
          <div>
            <h4 className="heading-medium font-regular">Free SEO Tools /</h4>
            <h1 className="heading-xlarge font-bold">Site Overview Checker</h1>
            <p className="heading-medium font-regular">
              {tool[toolSelected]?.desc}
            </p>
            {/* <div>
    <ActiveLastBreadcrumb
      selectTool={setToolSelected}
      activeTool={toolSelected}
    />
  </div> */}
            {tool[toolSelected]?.searchOption ? (
              <div className="search-container">
                <Grid container spacing={2}>
                  <Grid item xs={8}>
                    <form
                    // onSubmit={(e) => keywordClickHandler(e)}
                    >
                      <input
                        type="text"
                        className="input-search"
                        placeholder="Enter domain name"
                        value={keyword}
                        style={{ height: "50px" }}
                        // onChange={(e) => keywordChangeHandler(e.target)}
                      />
                      {Error && (
                        <span className="msg-error heading-xs font-regular">
                          {Error}
                        </span>
                      )}
                    </form>
                  </Grid>
                  <Grid item xs={4}>
                    {/* <Dropdown
                    title={domain}
                    callback={handleCountrySlection}
                    data={countryList}
                    style={{
                      width: "100%",
                      height: "50px",
                      textTransform: "none",
                      backgroundColor: "transparent",
                      boxShadow: "none",
                      borderRadius: "10px",
                      border: "2px solid #365486",
                    }}
                  /> */}
                  </Grid>
                </Grid>
                <Button
                  variant="outlined"
                  className="btn-backlink"
                  //   onClick={(e) => keywordClickHandler(e)}
                >
                  Search
                </Button>
              </div>
            ) : (
              <div style={{ width: "60%" }} className="search-container">
                <p className="heading-xs font-bold">coming soon...</p>
              </div>
            )}
          </div>
          <img src={KeywordImg} alt="" className="backlink-img flex-none-sm" />
        </div>
      </div>
      <ReCaptcha />
    </div>
  );
}

export default SiteOverView;
