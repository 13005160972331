export const domainRegex =
  /^(https?:\/\/)?([a-zA-Z0-9-]+\.)+[a-zA-Z]{2,}(:\d+)?(\/\S*)?$/;

export const isValidEmail = (input) => {
  const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
  return emailRegex.test(input);
};

export const checkIfAdmin = (email) => {
  const isAdmin = ["info@esearchlogix.com", "singhashish1.sh@gmail.com"];
  return isAdmin.includes(email);
};

export function makeUnique(array) {
  if (array?.length === 0) return [];
  return array?.filter(
    (value, index, self) =>
      index ===
      self.findIndex((obj) => obj.location_name === value.location_name)
  );
}

export const addLocationToData = (arrayA, location) => {
  if (arrayA?.length === 0) return [];
  return arrayA?.map((item) => {
    return {
      ...item,
      location,
    };
  });
};

export const addLocationToCompetitors = (arrayA, location) => {
  if (arrayA?.length === 0) return [];
  return {
    competitor: arrayA?.[0]?.competitor,
    comp_data: arrayA?.[0]?.comp_data?.map((item) => {
      return {
        ...item,
        location,
      };
    }),
  };
};

export const combineArraysForSameCompetitor = (data) => {
  const combinedData = [];
  data.forEach((entry) => {
    const existingEntry = combinedData.find(
      (combinedEntry) => combinedEntry.competitor === entry.competitor
    );

    if (existingEntry) {
      // Combine the comp_data arrays for the same competitor
      existingEntry.comp_data = existingEntry.comp_data.concat(entry.comp_data);
    } else {
      // Add a new entry if the competitor is not found
      combinedData.push({
        competitor: entry.competitor,
        comp_data: entry.comp_data,
      });
    }
  });

  return combinedData;
};

export const roundSpeedData = (value, precision) => {
  var multiplier = Math.pow(10, precision || 0);
  return Math.round(value * multiplier) / multiplier;
};

export const millisToSeconds = (milliseconds) => {
  const number = milliseconds / 1000;
  return parseFloat(number.toFixed(2));
};

export const calculatePercentage = (milliseconds) => {
  const number = millisToSeconds(milliseconds);
  if (number <= 0) {
    return 0;
  } else if (number <= 3) {
    return 18;
  } else if (number <= 5) {
    return 45;
  } else {
    return 80;
  }
};

export const sumQuesBasedKeyword = (array) => {
  if (array?.length === 0) return 0;
  let sum = 0;
  array.forEach((obj) => {
    if (obj?.searches) {
      const num = parseFloat(obj?.searches?.replace(/,/g, ""));
      sum += Number(num);
    }
  });
  if (sum >= 1000) {
    return (sum / 1000).toFixed(2) + "k"; // Use toFixed to round to 2 decimal places
  }
  return sum;
};

export function kFormatter(num) {
  return Math.abs(num) > 999
    ? Math.sign(num) * (Math.abs(num) / 1000).toFixed(1) + "K"
    : Math.sign(num) * Math.abs(num);
}

export const getDayAfter7Days = () => {
  const today = new Date();
  today.setDate(today.getDate() + 7); // Add 7 days
  return today.getDate().toString(); // Get the day of the month (1-31)
};

export function formatNumberWithSymbols(number) {
  number = Number(number);
  if (isNaN(number) || number === undefined) {
    return "0";
  } else if (number < 1000) {
    return Number(number.toFixed(2)); // No need for symbols if number is less than 1000
  }

  const symbols = ["", "K", "M", "B", "T"]; // Define symbols for thousands, millions, billions, trillions, etc.
  const magnitude = Math.floor(Math.log10(number) / 3); // Calculate the magnitude of the number
  let value = (number / Math.pow(10, magnitude * 3)).toFixed(2); // Calculate the value to display
  // Remove trailing zeros and unnecessary decimal point
  value = parseFloat(value).toString();
  return value + symbols[magnitude]; // Append the symbol
}

export function formatNumberWithSymbolsNoDecimal(number) {
  number = Number(number);
  if (isNaN(number) || number === undefined) {
    return "0";
  }

  const absNumber = Math.abs(number); // Work with the absolute value of the number
  if (absNumber < 1000) {
    return Number(number.toFixed(2)); // No need for symbols if the absolute value is less than 1000
  }

  const symbols = ["", "K", "M", "B", "T"]; // Define symbols for thousands, millions, billions, trillions, etc.
  const magnitude = Math.floor(Math.log10(absNumber) / 3); // Calculate the magnitude of the number
  // Calculate the value to display and append the symbol
  const value = (absNumber / Math.pow(10, magnitude * 3)).toFixed(0);
  const symbol = symbols[magnitude];
  const formattedValue = value + symbol;

  // Return the formatted value with the correct sign
  return number < 0 ? `-${formattedValue}` : formattedValue;
}

export function removeUndefinedAndNaN(arr) {
  return arr.filter((item) => typeof item !== "undefined" && !isNaN(item));
}

export function getOldestObject(objects) {
  // Initialize variables to hold the oldest date
  let oldestDate = new Date();
  let oldestObject = null;

  // Loop through each object
  for (let i = 0; i < objects.length; i++) {
    const object = objects[i];

    // Extract date, month, and year from the object
    const date = parseInt(object.date);
    const month = object.month;
    const year = parseInt(object.year);

    // Create a Date object from the extracted values
    const currentDate = new Date(year, monthToIndex(month), date);

    // Compare with the oldest date found so far
    if (currentDate < oldestDate) {
      oldestDate = currentDate;
      oldestObject = object;
    }
  }

  return oldestObject;
}

export const stringToBoolean = (str) => {
  if (typeof str === "string") {
    return str.toLowerCase() === "true";
  }
  return Boolean(str);
};

// Helper function to convert month name to index
function monthToIndex(month) {
  const months = [
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "May",
    "Jun",
    "Jul",
    "Aug",
    "Sep",
    "Oct",
    "Nov",
    "Dec",
  ];
  return months.indexOf(month);
}

export function addCommasToNumber(number) {
  return number?.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
}

export function handleNaNAndUndefined(value) {
  if (isNaN(value)) {
    return true;
  } else if (value === undefined) {
    return true;
  } else {
    return false;
  }
}

export const validatePassword = (password) => {
  const minLength = 8;
  const maxLength = 16;
  const uppercasePattern = /[A-Z]/;
  const lowercasePattern = /[a-z]/;
  const numberPattern = /\d/;
  const specialCharPattern = /[!@#$%^&*(),.?":{}|<>]/;

  if (password.length < minLength || password.length > maxLength) {
    return `Password must be between ${minLength} and ${maxLength} characters long.`;
  }
  if (!uppercasePattern.test(password)) {
    return "Password must contain at least one uppercase letter.";
  }
  if (!lowercasePattern.test(password)) {
    return "Password must contain at least one lowercase letter.";
  }
  if (!numberPattern.test(password)) {
    return "Password must contain at least one number.";
  }
  if (!specialCharPattern.test(password)) {
    return "Password must contain at least one special character.";
  }
  return "";
};

export const getStatusCodeColor = (code) => {
  if (code >= 200 && code < 300) {
    return { color: "#075E45", backgroundColor: "#EBF7ED" };
  } else if ((code >= 100 && code < 200) || (code >= 300 && code < 400)) {
    return { color: "white", backgroundColor: "#F3A344" };
  } else if (code >= 400 && code < 600) {
    return { color: "#FF0000E5", backgroundColor: "#F9D1D199" };
  } else {
    return { color: "#961574", backgroundColor: "#FCF0F8" }; // Default color for unexpected codes
  }
};

export function dynamicSort(array, key, order = "asc") {
  return array?.sort((a, b) => {
    if (a[key] < b[key]) {
      return order === "asc" ? -1 : 1;
    }
    if (a[key] > b[key]) {
      return order === "asc" ? 1 : -1;
    }
    return 0;
  });
}

export const convertToObjects = (data) => {
  return (
    data?.flatMap((item) =>
      Object.entries(item).map(([key, value]) => ({
        domain: key,
        data: value,
      }))
    ) || []
  );
};

const removeDuplicateKeywords = (arr) => {
  return [...new Set(arr)];
};

export const uniqueByTextAndKeyword = (arr) => {
  const uniqueMap = new Map();

  arr?.forEach((item) => {
    const cleanedKeywords = removeDuplicateKeywords(item.keyword)
      .sort()
      .join(",");
    const key = `${item.text}-${cleanedKeywords}`;
    if (!uniqueMap.has(key)) {
      uniqueMap.set(key, {
        ...item,
        keyword: removeDuplicateKeywords(item.keyword),
      });
    }
  });

  return Array.from(uniqueMap.values());
};

export function incrementNumber() {
  return new Promise((resolve) => {
    let number = 0;
    const interval = setInterval(() => {
      number += 1;
      if (number > 5) {
        clearInterval(interval); // Stop the interval once number exceeds 5
        resolve(number); // Resolve the promise with the final value
      }
    }, 20000); // 20000 milliseconds = 20 seconds
  });
}

export const sortedNoRankArray = (array, field) => {
  return array?.sort((a, b) => {
    const valA = a[field];
    const valB = b[field];

    // Check if 'valA' is a number and 'valB' is a string
    if (!isNaN(valA) && isNaN(valB)) {
      return -1;
    }
    // Check if 'valA' is a string and 'valB' is a number
    if (isNaN(valA) && !isNaN(valB)) {
      return 1;
    }
    // Both are numbers, sort them in ascending order
    if (!isNaN(valA) && !isNaN(valB)) {
      return Number(valA) - Number(valB);
    }
    // Both are strings, keep original order
    return 0;
  });
};

export function categorizeByLocationType(data) {
  return data?.reduce((acc, item) => {
    const type = item?.location_type;

    if (!acc[type]) {
      acc[type] = [];
    }

    acc[type].push(item);
    return acc;
  }, {});
}
