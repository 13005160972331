import { useSelector } from "react-redux";
import KeywordRanksChart from "./keywordAllRanksChart";

const KeywordAllRanksChart = ({ Keyword }) => {
  // redux useselector
  const previous_all_old_data = useSelector(
    (state) => state.previousallolddata
  );
  const selectedprojectnewdata = useSelector(
    (state) => state.selectedprojectnewdata
  );
  const userallkeywordresult = useSelector(
    (state) => state.userallkeywordresult
  );
  const selectCustomDate = useSelector((state) => state.selectcustomdate);

  const selected_userallkeywordresult = userallkeywordresult?.filter((item) => {
    return item.location === Keyword?.location;
  });
  const selected_data_previous_data = previous_all_old_data?.filter((item) => {
    return item?.location === Keyword?.location;
  });
  const previous_data = selected_data_previous_data?.[0]?.data;
  const last_data_date =
    selectedprojectnewdata[0]?.date + " " + selectedprojectnewdata[0]?.month;

  // Function to process previous data
  function processPreviousData(previousData, keyword, selectCustomDate) {
    const lastDate =
      previousData.length < selectCustomDate.day
        ? previousData.length
        : selectCustomDate.day;

    const targetedKeywordRanks = [];
    const keywordDetailsTime = [];

    if (!keyword) {
      return { targetedKeywordRanks, keywordDetailsTime };
    }

    previousData
      .slice(previousData.length - lastDate, previousData.length)
      .forEach((res) => {
        const keywordDetails = res.date + " " + res.month;
        const result = res.data.find(
          (item) => item.keyword === keyword.keyword
        );
        const rankGroup = result ? result.data[0].rank_group : null;
        if (result && rankGroup != "No Rank") {
          keywordDetailsTime.push(keywordDetails);
          targetedKeywordRanks.push(rankGroup === "No Rank" ? null : rankGroup);
        }
      });

    return { targetedKeywordRanks, keywordDetailsTime };
  }

  // Function to process today's data
  function processTodaysData(userAllKeywordResult, keyword) {
    const todaysData =
      userAllKeywordResult &&
      userAllKeywordResult.find((item) => item.keyword === keyword?.keyword);
    const todayRank = todaysData ? todaysData.data[0].rank_group : null;
    return todayRank;
  }

  // Main function
  function processKeywordData(
    previousAllOldData,
    userAllKeywordResult,
    keyword,
    selectCustomDate
  ) {
    const { targetedKeywordRanks, keywordDetailsTime } = processPreviousData(
      previousAllOldData || [],
      keyword,
      selectCustomDate
    );
    const todayRank = processTodaysData(userAllKeywordResult, keyword);
    return {
      targetedKeywordRanks: [...targetedKeywordRanks, todayRank],
      keywordDetailsTime: [...keywordDetailsTime, last_data_date],
    };
  }

  const { keywordDetailsTime, targetedKeywordRanks } = processKeywordData(
    previous_data,
    selected_userallkeywordresult,
    Keyword,
    selectCustomDate
  );

  return (
    <div className="m-3">
      <KeywordRanksChart
        dataset={targetedKeywordRanks}
        labels={keywordDetailsTime}
        Keyword={Keyword?.keyword}
      />
    </div>
  );
};
export default KeywordAllRanksChart;
