import React from "react";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Filler,
  Legend,
} from "chart.js";
import { Line } from "react-chartjs-2";
import { formatNumberWithSymbols } from "../../../helperJs/helper";

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Filler,
  Legend
);

function SingleLineChart({ Ref, data, Options }) {

  if (document.getElementById("single-line-chart") !== null) {
    var ctx = document.getElementById("single-line-chart").getContext("2d");
    var gradient = ctx.createLinearGradient(100, 80, 100, 200);
    gradient.addColorStop(0, "rgba(120, 66, 232, 0.7)");
    gradient.addColorStop(1, "rgba(250, 250, 250, 0.99)");
  }

  const options = {
    maintainAspectRatio: false,
    responsive: true,
    interaction: { mode: "index", intersect: false },
    plugins: {
      legend: {
        position: "top",
        display: false,
      },
      title: {
        display: true,
      },
      axisY: {
        gridDashType: "dash",
      },
      tooltip: {
        callbacks: {
          label: function (context) {
            let label = context.dataset.label || "";

            if (label) {
              label += ": ";
            }
            if (context.parsed.y !== null) {
              label += formatNumberWithSymbols(context.parsed.y?.toFixed(0));
            }
            return label;
          },
        },
        boxPadding: 5,
        cornerRadius: 10,
        caretSize: 0,
        padding: 10,
        margin: 10,
        boxHeight: 15,
        borderWidth: 4,
        boxWidth: 4,
        titleColor: "black",
        bodyColor: "black",
        bodyFont: {
          size: 14,
          weight: 500,
          font: "Lato",
        },
        bodySpacing: 10,
        usePointStyle: true,
        backgroundColor: "#F0F0F0",
        titleFont: {
          size: 14,
          weight: 600,
          font: "Lato",
        },
      },
    },
    scales: {
      y: {
        type: "linear",
        display: true,
        position: "left",
        reverse: false,
        beginAtZero: true,
        color: "black",
        ticks: {
          maxTicksLimit: 7,
          stepSize: 6000,
          autoSkip: false,
          beginAtZero: true,
          size: 16,
          weight: 500,
          callback: function (value) {
            const number = formatNumberWithSymbols(value);
            return number;
          },
        },
        grid: {
          display: true,
          tickBorderDash: [2, 15], // also for the tick, if long enough
          tickLength: 10, // just to see the dotted line
          tickWidth: 2,
        },
        title: {
          display: data?.title?.[0]?.left ? true : false,
          text: data?.title?.[0]?.left,
          color: data?.title?.[0]?.color,
          font: {
            size: 14,
            weight: 600,
            family: "Lato",
          },
        },
      },
      y1: {
        type: "linear",
        display: true,
        reverse: false,
        position: "right",
        color: "black",
        ticks: {
          maxTicksLimit: 7,
          stepSize: 6000,
          autoSkip: false,
          beginAtZero: true,
          size: 16,
          weight: 500,
          callback: function (value) {
            const number = formatNumberWithSymbols(value);
            return number;
          },
        },
        grid: {
          drawOnChartArea: false,
        },
        title: {
          display: data?.title?.[1]?.right ? true : false,
          text: data?.title?.[1]?.right,
          color: data?.title?.[1]?.color,
          font: {
            size: 14,
            weight: 600,
            family: "Lato",
          },
        },
      },
      x: {
        ticks: {
          display: false,
        },
        grid: {
          display: false,
        },
      },
    },
    cubicInterpolationMode: "monotone",
  };

  const labels = data?.labels;

  const line_data = {
    labels,
    datasets: data?.dataSet,
  };

  return (
    <Line
      ref={Ref}
      options={Options || options}
      data={line_data}
      id="single-line-chart"
    />
  );
}

export default React.memo(SingleLineChart);
